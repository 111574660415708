import React from "react"
import { useParams } from "react-router"
import PecaUpdate from "./PecaUpdate"
import PecaCreate from "./PecaCreate"

function PecaView() {
  let {id} = useParams()
  const numericID = parseInt(id, 10)
  return (
    <React.Fragment>
      {(!!numericID)
        ? <PecaUpdate id={numericID}/>
        : <PecaCreate/>
      }
    </React.Fragment>
  )
}

export default PecaView
