import { api, apiCUD, getRequest } from "../../../../api/api"

const tecMarcasUrl = 'tec'

function readLocaisTec({id}) {
  const request = getRequest({URLname: tecMarcasUrl, id}).GET(`locs/list`)
  return api(request).then(r => r.data)
}

function updateLocaisTec(data) {
  const request = getRequest({URLname: tecMarcasUrl}).POST(`${data.idTec}/loc/${data.cp4}/onoff`)
  return apiCUD(request, data).then(r => r.data)
}

export {
  readLocaisTec,
  updateLocaisTec,
}
