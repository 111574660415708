import React, { useState } from "react"
import msgsUsers from "../msgsUsers"
import { useAsync } from "react-async"
import Typography from "@material-ui/core/Typography"
import makeStyles from "@material-ui/core/styles/makeStyles"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import { CircularProgress } from "@material-ui/core"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import Checkbox from "@material-ui/core/Checkbox"
import ListItemText from "@material-ui/core/ListItemText"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Box from "@material-ui/core/Box"
import { createSortedMap } from "./createSortedMap"
import { updateUserPerm } from "../apiUsers"


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  permsTitle: {
    margin: '1rem 0 .5rem',
    fontSize: theme.typography.pxToRem(22),
    fontWeight: theme.typography.fontWeightLight,
  },
  primaryText: {
    fontSize: theme.typography.pxToRem(12),
  },
  secondaryText: {
    fontSize: theme.typography.pxToRem(10),
    fontWeight: theme.typography.fontWeightLight,
  },
  checkbox: {
    minWidth: '32px',
  },
}))

function UserPermsVTabs({userID, data, setData}) {
  const [inputData, setInputData] = useState(data)
  const [selectedTab, setSelectedTab] = useState(0)
  const [selectedPerm, setSelectedPerm] = useState(null)

  const classes = useStyles()

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue)
  }

  const {isPending, run} = useAsync({
    deferFn: updateUserPerm,
    onResolve: () => {
      setData(inputData)
      setSelectedPerm(null)
    },
    onReject: () => {
      setInputData(data)
      setSelectedPerm(null)
    }
  })

  const handleToggle = (perm) => {
    setSelectedPerm({...perm, checked: !perm.checked})

    setInputData(inputData.map(item => {
      if (item.id === perm.id) {
        run({permID: perm.id, activate: !perm.checked, userID: userID})
        return {...item, checked: !item.checked}
      }
      return item
    }))
  }

  const permsKVArray = Array.from(createSortedMap(inputData, 'name', msgsUsers.PERM_TABS))

  function getTabProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    }
  }

  function TabPanel(props) {
    const {children, value, index, ...other} = props

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    )
  }

  return (
    <React.Fragment>
      <Typography variant="body1" className={classes.permsTitle}>{msgsUsers.CHECK_PERMS}</Typography>
      <div className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={selectedTab}
          onChange={handleChangeTab}
          aria-label="Vertical tabs"
          className={classes.tabs}
        >
          {permsKVArray.map(([key, value], index) => (
            <Tab key={'tab' + index} style={{alignText: 'right'}} label={value.trans} {...getTabProps(index)} />))}
        </Tabs>
        {permsKVArray.map(([key, value], index) => (
          <TabPanel key={'tabPanel' + index} value={selectedTab} index={index}>
            <List>
              {value.perms.map((item, i) => {
                const labelId = `checkbox-list-label-${item.id}`
                const loading = isPending && (item.id === selectedPerm.id)
                return (
                  <ListItem key={i} disabled={isPending} dense button onClick={() => handleToggle(item)}>
                    {
                      loading ?
                        <div style={{minWidth: '56px'}}>
                          <CircularProgress size={20}/>
                        </div> :
                        <ListItemIcon className={classes.checkbox}>
                          <Checkbox
                            edge="start"
                            checked={item.checked}
                            disableRipple
                            inputProps={{'aria-labelledby': labelId}}
                          />
                        </ListItemIcon>
                    }
                    <ListItemText id={labelId}
                                  primary={item.desc}
                                  primaryTypographyProps={{className: classes.primaryText}}
                                  secondary={item.name}
                                  secondaryTypographyProps={{className: classes.secondaryText}}/>
                  </ListItem>
                )
              })}
            </List>
          </TabPanel>
        ))}
      </div>
    </React.Fragment>
  )
}

export default UserPermsVTabs
