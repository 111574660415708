import makeStyles from "@material-ui/core/styles/makeStyles"
import useTheme from "@material-ui/core/styles/useTheme"
import IconButton from "@material-ui/core/IconButton"
import LastPageIcon from "../Svg-Icons/LastPageIcon"
import { textHint, textPrimary } from "../../constants/appTheme"
import FirstPageIcon from "../Svg-Icons/FirstPageIcon"
import KeyArrowRightIcon from "../Svg-Icons/KeyArrowRightIcon"
import KeyArrowLeftIcon from "../Svg-Icons/KeyArrowLeftIcon"
import React from "react"
import PropTypes from "prop-types"

// Styles
const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}))

function LFTablePagination(props) {
  const classes = useStyles()
  const theme = useTheme()
  const {count, page, rowsPerPage, onChangePage} = props

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0)
  }

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1)
  }

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1)
  }

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  // Disable Next Page e Last Page
  const disableNLPage = page >= Math.ceil(count / rowsPerPage) - 1

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon color={page === 0 ? textHint : textPrimary}/> :
          <FirstPageIcon color={page === 0 ? textHint : textPrimary}/>}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyArrowRightIcon color={page === 0 ? textHint : textPrimary}/> :
          <KeyArrowLeftIcon color={page === 0 ? textHint : textPrimary}/>}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={disableNLPage}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyArrowLeftIcon color={disableNLPage ? textHint : textPrimary}/> :
          <KeyArrowRightIcon color={disableNLPage ? textHint : textPrimary}/>}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={disableNLPage}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon color={disableNLPage ? textHint : textPrimary}/> :
          <LastPageIcon color={disableNLPage ? textHint : textPrimary}/>}
      </IconButton>
    </div>
  )
}

LFTablePagination.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired
}

export default LFTablePagination
