import React from "react"
import { StateFlowTabs } from "../../../EstadosPat_InfoStates/InfoStates/StateFlowPatTabs"


const StateFlowVisitaPatTabs = ({visita, reload}) => {

  const pat = visita.pat && visita.pat.data
  const patInfoStates = (pat && pat.infostates) || {
    mayIniciarPAT: false,
    mayConcluirVisita: false
  }

  // <div style={{background: '#07F', width: '100%', minHeight: '50px'}}>
  // </div>
  return ((!!visita)
    ? (
      <StateFlowTabs repo={visita} infoStatesToCheck={{
        mayIniciarVisita: patInfoStates.mayIniciarPAT,
        mayConcluirVisita: patInfoStates.mayConcluirVisita
      }} reload={reload}/>
    )
    : null)
}

export default StateFlowVisitaPatTabs
