import Grid from "@material-ui/core/Grid"
import React from "react"
import TableTaxas from "../../../../components/SharedComponents/Shared/Tables/TblsAcaoTaxasDescontos/TableTaxas"
import TableTaxasCliente from "../../../../components/SharedComponents/Shared/Tables/TblsAcaoTaxasDescontos/TableTaxasCliente"
import TablePeriodicidades from "../../../../components/SharedComponents/Shared/Tables/TablePeriodicidades"
import TableDescontos from "../../../../components/SharedComponents/Shared/Tables/TblsAcaoTaxasDescontos/TableDescontos"
import TableDuracao from "../../../../components/SharedComponents/Shared/Tables/TableDuracao"
import { H2Slim } from "../../../../components/SharedStyles/SharedStyles"

const DetailPanel = ({rowData}) => {
  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12}>
        <H2Slim>{`${rowData.refAN} - ${rowData.designacao} (Rev. ${rowData.rev}) `}</H2Slim>
      </Grid>
      <Grid item sm={12} md={6}>
        <TableTaxas {...rowData}/>
        <TableTaxasCliente {...rowData}/>
      </Grid>
      <Grid item sm={12} md={6}>
        <TablePeriodicidades {...rowData}/>
        <TableDuracao {...rowData}/>
        <TableDescontos  {...rowData}/>
      </Grid>
    </Grid>
  )
}

export default DetailPanel
