import React from "react"
import { ShowDateTime } from "../../../../components/SharedComponents/Shared/Show/ShowDateTime"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

const patInfoStatesMsgs = {
  TOGGLE_WEEKENDS_LBL: "Ver Sáb./Dom.?",
  DH_AGENDAMENTO: 'Data/Hora Agendamento',
  CHECK_TEC_CLASSIFIER: 'Verificar disponibilidade de CAT (próx. 5 dias)',

  TIPO_TEC: 'CAT',
  NOME_TEC: 'Designação',
  AVAL_IMM: "Avaliação IMM",
  COBERTURA: "Cobertura",
  ZONA: "Zona",
  MARCA: "Marca",
  LAST_CONTACT: "Último PAT",
  ASSOC_TEC: "Assoc. CAT",

  TESTAR_NOVA_DATA: 'Verificar disponibilidade para nova Data/Hora',
  CAL_TITLE: (refCode, nome, dh) => {
    return (
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
      >
        <Grid item><strong>{nome}</strong><br/>{refCode}</Grid>
        <Grid item><ShowDateTime dateTime={dh}/></Grid>
      </Grid>)
  },

  CONFIRM_DIALOG_TITLE: (refCode, nome, dh) => (
    <>
      <Typography>Confirma a associação e agendamento seguinte?</Typography>
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
      >
        <Grid item xs><strong>{nome}</strong><br/>{refCode}</Grid>
        <Grid item xs><ShowDateTime dateTime={dh}/></Grid>
      </Grid>
    </>
  ),

  BT_REAGENDAR: 'Reagendar o PAT',
  CAUSA_REAGENDAMENTO: 'Causa do reagendamento',
  OBS: 'Observações',

  BT_ANULAR: 'Anular o PAT',
  CAUSA_ANULACAO: 'Causa da anulação',

  CONFIRM_ANULACAO: (refCode) => (
    <>
      <Typography>Confirma a anulação do PAT seguinte?</Typography>
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
      >
        <Grid item xs={12}><p style={{padding: '1em 0', margin: 0}}>PAT: <strong>{refCode}</strong></p></Grid>
        <Grid item xs={12}>

          <p>Esta operação é irreversível.<br/>
            Depois de anulado o PAT não poderá mais ser modificado.</p>
          <p>Será enviada notificação da anulação por email ao Técnico caso já esteja associado.</p>
        </Grid>
      </Grid>
    </>
  ),

  BT_REMOVER: 'Remover o PAT',

  CONFIRM_REMOCAO: (refCode) => (
    <>
      <Typography>Confirma a remoção do PAT seguinte?</Typography>
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
      >
        <Grid item xs={12}><p style={{padding: '1em 0', margin: 0}}>PAT: <strong>{refCode}</strong></p></Grid>
        <Grid item xs={12}>

          <p>Esta operação é definitiva e irreversível.</p>
          <p><strong>Todos</strong> os dados associados ao PAT serão totalmente removidos.</p>
          <p>Será enviada notificação da anulação por email ao Técnico caso já esteja associado.</p>
        </Grid>
      </Grid>
    </>
  ),

  BT_INICIAR_PAT: 'Iniciar o atendimento local do PAT',
  BT_CONC_VISITA_PAT_RESOLVED: <span>Concluir visita com <u><strong>resolução</strong> do PAT</u></span>,
  BT_CONC_VISITA_PAT_NOT_RESOLVED: <span>Terminar a visita em curso e <u><strong>agendar</strong> nova visita</u></span>,

  AVAL_SLIDER_LBL: 'Avaliação do Técnico pelo Cliente',

  SWITCH_PAGO_TITLE: 'PAT pago?',
  PAGO_SIM: 'Sim',
  PAGO_NAO: 'Não',
  PAGO: 'pago',

  ASSINATURA_CLI: 'Assinatura do Cliente',
  ASSINATURA_TEC: 'Assinatura do CAT',
  ERRO_ASSINATURA_CLI: 'Assinatura do Cliente não indicada',
  ERRO_ASSINATURA_TEC: 'Assinatura do CAT não indicada',

  RELATORIO_TEC: 'Relatório Técnico',
  BT_RELATAR_PAT: 'Registar o Relatório Técnico',

  TBL_TOTAIS: 'Totais (c/ IVA)',
  TOT_PECAS: 'Peças/Componentes',
  TOT_PAT: 'PAT (c/ Peças/Componentes)',
  TBL_TOTAIS_COM_DESCONTOS: 'Totais com descontos finais (c/ IVA)',
  TOT_DESLOCACAO: "Deslocação",
  TOT_MO: "Mão de Obra",

  BT_CONFERIR_PAT: 'Conferir PAT',
  DESCONTO_GLOBAL: '% Desconto Global (*)',
  DESCONTO_GLOBAL_MUTEX: '(*) Mutualmente exclusivo relativamente aos restantes descontos.',
  DESCONTO_PECAS: '% Desconto Peças/Componentes',
  DESCONTO_DESLOC: '% Desconto Deslocação',
  DESCONTO_MO: '% Desconto sobre a Mão de Obra',
  OBS_IMM: 'Observações Internas (IMMERSAT)',
  SWITCH_A_FATURAR_TITLE: 'A faturar?',

  BT_FECHAR_PAT: 'Fechar o processo do PAT',
  OBS_CLI: 'Observações a incluir no documento a remeter ao cliente (caso tenha e-mail).',

  CONFIRM_PAT_TITLE: (refCode) => (
    <>
      <Typography>Confirma o fecho do processo do PAT seguinte?</Typography>
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
      >
        <Grid item xs={12}><p style={{padding: '1em 0', margin: 0}}>PAT: <strong>{refCode}</strong></p></Grid>
        <Grid item xs={12}>

          <p>Depois de fechado o PAT não poderá mais ser modificado.
            <br/>Apenas haverá lugar a um passo final de confirmação de faturação caso tenha sido assinalado o facto ao conferir os valores do PAT.
          </p>
          <p>Serão gerados os seguintes documentos:</p>
          <ul>
            <li><strong>Relatório interno do PAT</strong>, remetido ao sistema de gestão documental;</li>
            <li><strong>Relatório do Cliente</strong>, remetido ao Cliente caso este tenha e-mail registado;</li>
          </ul>
        </Grid>
      </Grid>
    </>
  ),

  BT_CONF_FAT_ENCERRAR_PAT: 'Confirmar o fecho da faturação e encerrar o PAT',

  CONFIRM_ENCERRAR_TITLE: (refCode) => (
    <>
      <Typography>Confirma o encerramento do processo do PAT seguinte?</Typography>
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
      >
        <Grid item xs={12}><p style={{padding: '1em 0', margin: 0}}>PAT: <strong>{refCode}</strong></p></Grid>
        <Grid item xs={12}>
          <p>Esta operação dará o PAT como definitivamente faturado e encerrado.</p>
        </Grid>
      </Grid>
    </>
  ),

  CONFIRM_ENVIO_PDF: (refCode, who) => (
    <>
      <Typography>Confirma o envio do relatório (PDF) do PAT seguinte ao
        respetivo <strong>{who === 'tec' ? 'Técnico' : 'Cliente'}</strong>?</Typography>
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
      >
        <Grid item xs={12}><p style={{padding: '1em 0', margin: 0}}>PAT: <strong>{refCode}</strong></p></Grid>
      </Grid>
    </>
  ),

}

export default patInfoStatesMsgs
