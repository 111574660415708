// GRANDE decisão Rui! Mesmo à discípulo!!
const getValAsMoney = val => val.toFixed(2) + ' €'
const getValAsFloat = val => parseFloat(val) || 0
const getValAsPercent = (val) => val * 100 + ' %'

// sugestão
const parseToMoney = (val, asStr = false) => {
  const resp = getValAsFloat(val).toFixed(2)
  return (asStr ? resp + ' €' : resp)
}

const _parseTo0_100Percent = (val, asStr = false) => {
  // 0.23 => 23.00
  // 0.05 =>  5.00
  let resp = getValAsFloat(val).toFixed(2)
  if (resp <= 1) {
    resp *= 100
  } else {
    if (resp >= 100) {
      resp = 100
    }
  }
  return parseInt(resp, 10)
}

const parseToPercent = (val, asStr = false) => {
  const resp = _parseTo0_100Percent(val, asStr)
  return (asStr ? `${resp} %` : resp / 100)
}

const parseToIntegerPercent = (val, asStr = false) => {
  const resp = _parseTo0_100Percent(val, asStr)
  return (asStr ? `${resp} %` : resp)
}

export { parseToMoney, parseToIntegerPercent, parseToPercent, getValAsFloat, getValAsPercent, getValAsMoney }
