import React, { useContext } from "react"
import pecasMsgs from "../../Equips/Pecas/msgs"
import { handleDeletePecaPat, PecasPatState } from "./PecasPatGest"
import pecasPatMsgs from "./msgs"
import LFRenderTable from "../../../components/LFTable/LFRenderTable"
import { parseToMoney, parseToPercent } from "../../../constants/taxasConst"


const columns = [{
  Header: () => null,
  id: 'PecasPat',
  columns: [{
    Header: pecasMsgs.TABLE.HEADER.REF,
    accessor: "ref",
    disableSortBy: true
  }, {
    Header: pecasMsgs.TABLE.HEADER.DESC,
    accessor: "desc",
    disableSortBy: true
  }, {
    Header: pecasPatMsgs.TABLE.HEADER.QTD,
    accessor: "qtd",
    disableSortBy: true
  }, {
    Header: pecasPatMsgs.TABLE.HEADER.DESCONTO,
    accessor: "percDesc",
    Cell: ({row}) => {
      // return <span>{row.original.percDesc}</span>
      return <span>{parseToPercent(row.original.percDesc, true)}</span>
    },
    disableSortBy: true
  }, {
    Header: pecasPatMsgs.TABLE.HEADER.IVA,
    accessor: "percIVA",
    Cell: ({row}) => {
      return <span>{parseToPercent(row.original.percIVA, true)}</span>
    },
    disableSortBy: true
  }, {
    Header: pecasMsgs.TABLE.HEADER.UNIT_PRICE,
    accessor: "unitPrice",
    Cell: ({row}) => {
      return <span>{parseToMoney(row.original.unitPrice, true)}</span>
    },
    disableSortBy: true
  }, {
    Header: pecasPatMsgs.TABLE.HEADER.PVP_SEM_IVA,
    accessor: "pvpFinalSemIVA",
    Cell: ({row}) => {
      return <span>{parseToMoney(row.original.pvpFinalSemIVA, true)}</span>
    },
    disableSortBy: true
  }
  ]
}]

const ListPecasPat = (props) => {
  const {showEdit, pecasObj, setPecasObj, disable} = useContext(PecasPatState)
  const handlerShowCreate = () => showEdit(null)
  const handlerShowUpdate = (row) => {
    showEdit(row)
  }
  const mayEditPat = (
    !pecasObj.pat.anulado_at
    && !pecasObj.pat.isEncerrada
    && !pecasObj.pat.pago
    && !disable
  )

  return (
    <LFRenderTable
      data={pecasObj.pecas ? pecasObj.pecas.data : []}
      columns={columns}
      getDataFn={() => {
      }}
      showSearchBox={false}
      showPagination={false}
      isFulfilled={true}
      loading={false}
      pageCount={pecasObj.pecas ? pecasObj.pecas.meta.pagination.total : 0}
      deleteFn={(id) => handleDeletePecaPat({...pecasObj, setPecasObj: setPecasObj, pecaPatID: id})}
      addButtonDesc={pecasPatMsgs.NEW_LABEL}
      showAddButton={mayEditPat}
      showEditButton={mayEditPat}
      showDeleteButton={mayEditPat}
      handlerCreate={handlerShowCreate}
      handlerUpdate={handlerShowUpdate}
      {...props}
    />
  )
}
export default ListPecasPat
