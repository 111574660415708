import React from "react"
import { useAsync } from "react-async"
import { FormContext, useForm } from "react-hook-form"
import { Form } from "../../components/SharedStyles/SharedStyles"
import Grid from "@material-ui/core/Grid"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import LFSubmitButton from "../../components/SharedComponents/Shared/LFButtons/LFSubmitButton"
import * as Yup from "yup"
import { requiredDateValidation, requiredValidation } from "../../constants/appValidation"
import patMsgs from "./patMsgs"
import LFDateTimePickerInput from "../../components/LFInputs/LFDateTimePickerInput"
import { LFFormatDateTime } from "../../constants/appConst"
import LFTextEditor from "../../components/LFInputs/LFTextEditor"
import FormHelperText from "@material-ui/core/FormHelperText"
import moment from "moment"
import MomentUtils from "@date-io/moment"
import "moment/locale/pt"
import { secondaryColor } from "../../constants/appTheme"
import { makeStyles } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import LFAsyncSelectInput from "../../components/LFInputs/LFAsyncSelectInput"
import { readProcedimentosForEquip } from "../Equips/Procedimentos/apiProcedimento"
import { createPat } from "./apiPats"


const useStyles = makeStyles(theme => ({
  formRoot: {
    width: '100%',
  },
  title: {
    color: secondaryColor,
    width: '100%',
    textAlign: 'right',
    fontSize: 40,
    padding: '0 1.5rem'
  },

}))

const validationSchema = Yup.object().shape({
  dhContactoCliente: requiredDateValidation(patMsgs.INFO_CONTACTO_CLIENTE),
  obsInicialCliente: requiredValidation(patMsgs.OBS_INIC),
})

const msgsPatForm = {
  DH_CONTACTO_CLI: "Data e Hora Contacto do Cliente",
  OBS_INIC: 'Observações iniciais',
  ADD_PAT: 'Adicionar PAT',
  PROCEDIMENTO: "Procedimento",
}

function NewPatForm({equipID, patType, setPat}) {
  const classes = useStyles()

  const {isPending, run} = useAsync({
    deferFn: createPat,
    onResolve: (newPAT) => {
      setPat(newPAT)
    }
  })

  const methods = useForm({
    validationSchema: validationSchema,
    defaultValues: {
      dhContactoCliente: new Date(),
      obsInicialCliente: '',
      procedimento_id: ''
    }
  })

  const {handleSubmit, formState, control} = methods

  const onSubmit = ({...values}) => {
    const convertValues = {
      ...values, ...{
        equip_id: equipID,
        tipo: patType,
        dhContactoCliente: LFFormatDateTime(values.dhContactoCliente),
        procedimento_id: values.procedimento_id ? values.procedimento_id.index : null // facultativo
      }
    }
    run({...convertValues})
  }

  const getProcedimentos = () => {
    return readProcedimentosForEquip(equipID)
  }

  return (
    <FormContext {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)} className={classes.formRoot}>
        <Grid spacing={2}
              className={classes.formRoot}
              container
              justify="center"
              alignItems="center"
        >
          {/*---------------------------------- DATA_CONTACTO_CLIENTE ----------------------------------*/}
          <Grid item xs={6}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <LFDateTimePickerInput name='dhContactoCliente' control={control}
                                     label={msgsPatForm.DH_CONTACTO_CLI}/>
            </MuiPickersUtilsProvider>
          </Grid>
          {/*---------------------------------- PROCEDIMENTO ----------------------------------*/}
          <Grid item xs={6}>
            <LFAsyncSelectInput request={getProcedimentos} name="procedimento_id" control={control}
                                label={msgsPatForm.PROCEDIMENTO}/>
          </Grid>
          {/*---------------------------------- Observações iniciais ----------------------------------*/}
          <Grid item xs={12}>
            <FormHelperText>{msgsPatForm.OBS_INIC}</FormHelperText>
            <LFTextEditor name='obsInicialCliente' control={control}/>
          </Grid>
          <Grid item xs={12}>
            <LFSubmitButton isLoading={isPending} dirty={!formState.dirty}>
              {msgsPatForm.ADD_PAT}
            </LFSubmitButton>
          </Grid>
        </Grid>
      </Form>
    </FormContext>
  )
}


const PatForm = ({equipID, patType, setPat}) => {
  const classes = useStyles()
  return (
    <Grid spacing={2} container
          justify="center"
          alignItems="flex-end">
      <Grid item xs={12}>
        <Typography className={classes.title}>{patType}</Typography>
      </Grid>
      <Grid item xs={12}>
        <NewPatForm equipID={equipID} patType={patType} setPat={setPat}/>
      </Grid>
    </Grid>
  )
}


export default PatForm
