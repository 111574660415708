import { api, apiCUD, getRequest } from "../../../api/api"

const _cleanData = ({data, meta}, pat) => {
  let pecas = []
  data.forEach(item => {
    pecas.push({
      id: item.id,
      pat_id: item.pat_id,
      peca_id: item.peca_id,
      qtd: item.qtd,
      unitPrice: item.unitPrice,
      percDesc: item.percDesc,
      mayBeDeleted: (!pat.anulado_at && !pat.isEncerrada && !pat.pago),
      percIVA: item.percIVA,
      pvpFinalSemIVA: item.pvpFinalSemIVA,
      ref: item.peca && item.peca.data ? item.peca.data.ref : '',
      desc: item.peca && item.peca.data ? item.peca.data.desc : '',
      pecaUnitPrice: item.peca && item.peca.data ? item.peca.data.unitPrice : ''
    })
  })
  return {meta: meta, data: pecas}
}

async function readPecasPatAndAllPecas({pat}) {
  const requestPecasPat = getRequest({URLname: 'pats', id: pat.id, query: {com: 'peca'}}).GET('pecas')
  const requestAllPecas = getRequest({URLname: 'pecas', query: {pageSize: 10000, pageIndex: 0}}).ALL()
  return Promise.all([
    api(requestPecasPat),
    api(requestAllPecas),
  ]).then(([reqPecasPat, reqAllPecas]) => ({
    pecasPat: _cleanData(reqPecasPat.data, pat),
    allPecas: reqAllPecas.data.data.map((peca) => {
      return {index: peca.id, title: `${peca.desc} (${peca.ref})`, ref: peca.ref, desc: peca.desc, unitPrice: peca.unitPrice}
    })
  }))
}

async function readPecasPat({pat}) {
  const requestPecasPat = getRequest({URLname: 'pats', id: pat.id, query: {com: 'peca'}}).GET('pecas')
  return api(requestPecasPat).then((reqPecasPat) => (_cleanData(reqPecasPat.data, pat)))
}

const createPecaPat = (data) => {
  const request = getRequest({URLname: 'pats'}).POST(`${data.pat_id}/pecas`)
  return apiCUD(request, data)
}

const updatePecaPat = (data) => {
  const request = getRequest({URLname: 'pats', id: data.pat_id}).PUT(`pecas/${data.id}`)
  return apiCUD(request, data)
}

const deletePecaPat = ({pat_id, peca_id}) => {
  const request = getRequest({URLname: 'pats', id: pat_id}).DELETE(`pecas/${peca_id}`)
  return apiCUD(request)
}

export {
  deletePecaPat,
  createPecaPat,
  updatePecaPat,
  readPecasPat,
  readPecasPatAndAllPecas
}
