import React from "react"
import Async from "react-async"
import LinearProgress from "@material-ui/core/LinearProgress"
import ErrorMsg from "../../../components/SharedComponents/Shared/Error/ErrorMsg"
import { readCliente, updateCliente } from "./apiClientes"
import ClienteForm from "./ClienteForm"

function ClienteUpdate({id}) {
  // const dispatch = useSaveItemDispatch()
  return (
    <Async promiseFn={readCliente} id={id}>
      <Async.Pending><LinearProgress color="secondary"/></Async.Pending>
      <Async.Fulfilled>
        {(data, {setData}) => {
          // dispatch({type: 'C', data})
          return (
            <React.Fragment>
              <ClienteForm cliente={data} method={updateCliente} setCliente={setData}/>
            </React.Fragment>
          )
        }}
      </Async.Fulfilled>
      <Async.Rejected>{error => <ErrorMsg>{error}</ErrorMsg>}</Async.Rejected>
    </Async>
  )
}


export default ClienteUpdate
