import React, { useEffect, useState } from "react"
import { Form } from "../../../components/SharedStyles/SharedStyles"
import { errorColor, successColor, whiteColor } from "../../../constants/appTheme"
import armazemMsgs from "../armazemMsgs"
import CheckIcon from "../../../components/Svg-Icons/CheckIcon"
import IconButton from "@material-ui/core/IconButton"
import CircularProgress from "@material-ui/core/CircularProgress"
import { useAsync } from "react-async"
import { armazINDefaultValues, toSlug } from "./partials/armazemUtils"
import Grid from "@material-ui/core/Grid"
import { FormContext, useForm } from "react-hook-form"
import LFInput from "../../../components/LFInputs/LFInput"
import * as Yup from "yup"
import { requiredDateValidation } from "../../../constants/appValidation"
import { readMarcasSelect } from "../../Marcas/apiMarcas"
import LFSelect from "../../../components/LFInputs/LFSelect"
import { readModelosOfMarcaTipoEquipSelect } from "../../Marcas/Modelos/apiMarcaModelos"
import FormHelperText from "@material-ui/core/FormHelperText"
import ListMenuIcon from "../../../components/Svg-Icons/ListMenuIcon"
import Fab from "@material-ui/core/Fab"
import Drawer from "@material-ui/core/Drawer"
import LFAsyncSelectInputOptionsOut from "../../../components/LFInputs/LFAsyncSelectInputOptionsOut"
import { entradaArmazemStyles } from "./partials/entradaArmazemStyles"
import { prometeLog } from "../../Pats/VisitasPat/apiVisitaPat"
import { MarcaDrawer, ModeloDrawer, TEDrawer, TransportadorDrawer } from "./partials/DrawersArmazem"


const validationSchema = Yup.object().shape({
  numserie: requiredDateValidation(armazemMsgs.NUM_SERIE),
})

function EntradasForm({teList, transpList, reload, ...otherProps}) {
  const [isRegValid, setIsRegValid] = useState(false)
  const [drawerState, setDrawerState] = useState({
    marca_id: false,
    modelo_id: false,
    transportador_id: false,
  })
  const [marcasTEListBooted, setMarcasTEListBooted] = useState(false)
  const [marcasTEList, setMarcasTEList] = useState([])
  const [modelosMTEList, setModelosMTEList] = useState([])
  const [modelosMTEListBooted, setModelosMTEListBooted] = useState(false)
  const [haIMM, setHaIMM] = useState(false)
  const [IMM, setIMM] = useState(undefined)

//----------------------------------------
  const classes = entradaArmazemStyles()

  const {isPending, run} = useAsync({
    deferFn: (props) => prometeLog({msg: 'defer', ...props}),
    onResolve: (data) => {
      prometeLog({msg: 'resolved', ...data})
    }
  })

  const methods = useForm({
    validationSchema: validationSchema,
    defaultValues: armazINDefaultValues()
  })

//----------------------------------------
//----------------------------------------
  const {handleSubmit, formState, watch, control, setValue} = methods
  const watchTE = watch('te_id')
  const watchMarca = watch('marca_id')
  const watchModelo = watch('modelo_id')
  const watchTransp = watch('transportador_id')
//----------------------------------------
  const handleChangeTE = (val = 0) => setValue('te_id', val || '')
  const handleChangeMarca = (val = 0) => setValue('marca_id', val || '')
  const handleChangeModelo = (val = 0) => setValue('modelo_id', val || '')
  const handleChangeTransp = (val = 0) => setValue('transportador_id', val || '')
//----------------------------------------
  const onSubmit = (values) => {
    setIsRegValid(true)
    run(values)
  }
//----------------------------------------
  console.log(watchTransp)
//----------------------------------------

  useEffect(() => {
    setMarcasTEListBooted(false)
    setMarcasTEList([])
    setModelosMTEListBooted(false)
    setModelosMTEList([])
    handleChangeMarca()
    handleChangeModelo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchTE])

  useEffect(() => {
    setModelosMTEListBooted(false)
    setModelosMTEList([])
    handleChangeModelo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchMarca])

  useEffect(() => {
    const IMM = marcasTEList.find(t => toSlug(t.title) === 'immergas')
    setHaIMM(!!IMM)
    setIMM(IMM)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marcasTEList])

//----------------------------------------
//----------------------------------------
  const toggleDrawer = (field, openState) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }

    setDrawerState({...drawerState, [field]: openState})
  }
  const DrawerOpenBt = ({drawerField}) => {
    return (
      <Fab className={classes.fabClass} color="secondary" size="small" aria-label="open"
           onClick={toggleDrawer(drawerField, true)}
           disabled={drawerState[drawerField]}
      >
        <ListMenuIcon color={whiteColor} size={16}/>
      </Fab>
    )
  }
//----------------------------------------
  const getDataForSelect = (witchOne) => {
    if (witchOne === 'marca_id' && watchTE) {
      return readMarcasSelect({idTipoEquip: watchTE.index})
    }
    if (witchOne === 'modelo_id' && watchTE && watchMarca) {
      return readModelosOfMarcaTipoEquipSelect({marcaID: watchMarca.index, teID: watchTE.index})
    }
    return prometeLog({msg: 'data for ' + witchOne, ...{data: []}})
  }
//----------------------------------------
  return (
    <FormContext {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)} className={classes.formRoot}>
        {/* estranho!? --> para que o outoComple="off" funcione no chrome */}
        <input type="hidden" value="something"/>
        <Grid spacing={2}
              className={classes.gridRoot}
              container
              justify="flex-start"
              alignItems="center"
        >
          {/*---------------------------------- numserie -------------------------------*/}
          <Grid item xs={12}>
            <LFInput label={armazemMsgs.NUM_SERIE}
                     className={classes.inputBox} autoComplete="off"
                     name='numserie'/>
          </Grid>
          {/*---------------------------------- te_id ----------------------------------*/}
          <Grid item xs={12}>
            <Grid spacing={1}
                  className={classes.innerGrid}
                  container
                  justify="flex-start"
                  alignItems="flex-start"
            >
              <Grid item xs>
                <LFSelect control={control} options={teList}
                          label={armazemMsgs.TE}
                          className={classes.tipoEquipSelectBox}
                          autoComplete={false}
                          name='te_id'
                />
              </Grid>
              <Grid item xs={false}>
                <DrawerOpenBt drawerField={'te_id'}/>
              </Grid>
            </Grid>
          </Grid>
          {/*---------------------------------- marca_id & Modelo_id & faturaCompra ---------*/}
          <Grid item xs={6}>
            <Grid spacing={1}
                  className={classes.innerGrid}
                  container
                  justify="flex-start"
                  alignItems="flex-start"
            >
              <Grid item xs>
                <LFInput label={armazemMsgs.MARCA}
                         disabled={!(watchTE && watchTE.index)}
                         className={classes.inputBox} autoComplete="off"
                         name='marca'/>
                {!(watchTE && watchTE.index) &&
                <FormHelperText>{armazemMsgs.MUST_HAVE.TE}</FormHelperText>}
                <LFAsyncSelectInputOptionsOut control={control}
                                              options={marcasTEList}
                                              setOptions={setMarcasTEList}
                                              booted={marcasTEListBooted}
                                              setBooted={setMarcasTEListBooted}
                                              request={() => getDataForSelect("marca_id")}
                                              label={armazemMsgs.MARCA}
                                              className={classes.readOnlySelectBox}
                                              autoComplete={false}
                                              disabled={!(watchTE && watchTE.index)}
                                              name="marca_id"
                />
                {!(watchTE && watchTE.index) &&
                <FormHelperText>{armazemMsgs.MUST_HAVE.TE}</FormHelperText>}
              </Grid>
              <Grid item xs={false}>
                <DrawerOpenBt drawerField={'marca_id'}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid spacing={1}
                  className={classes.innerGrid}
                  container
                  justify="flex-start"
                  alignItems="flex-start"
            >
              <Grid item xs>
                <LFAsyncSelectInputOptionsOut control={control}
                                              options={modelosMTEList}
                                              setOptions={setModelosMTEList}
                                              booted={modelosMTEListBooted}
                                              setBooted={setModelosMTEListBooted}
                                              request={() => getDataForSelect("modelo_id")}
                                              label={armazemMsgs.EQUIP}
                                              className={classes.readOnlySelectBox}
                                              autoComplete={false}
                                              disabled={!(watchTE && watchTE.index && watchMarca && watchMarca.index)}
                                              name="modelo_id"
                />
                {!(watchTE && watchTE.index && watchMarca && watchMarca.index) &&
                <FormHelperText>{armazemMsgs.MUST_HAVE.MARCA}</FormHelperText>}
              </Grid>
              <Grid item xs={false}>
                <DrawerOpenBt drawerField={'modelo_id'}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <LFInput label={armazemMsgs.FAT_COMPRA}
                     className={classes.inputBox} autoComplete="off"
                     name='faturaCompra'/>
          </Grid>
          {/*---------------------------------- Transportador & fat. Frete -------------*/}
          <Grid spacing={2}
                className={classes.transpGrid}
                container
                justify="flex-start"
                alignItems="flex-start"
          >
            <Grid item xs={6}>
              <Grid spacing={1}
                    className={classes.innerGrid}
                    container
                    justify="flex-start"
                    alignItems="flex-start"
              >
                <Grid item xs>
                  <LFSelect control={control} options={transpList}
                            label={armazemMsgs.TRANSPORT}
                            className={classes.selectBox}
                            autoComplete={false}
                            name='transportador_id'
                  />
                </Grid>
                <Grid item xs={false}>
                  <DrawerOpenBt drawerField={'transportador_id'}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <LFInput label={armazemMsgs.FAT_FRETE}
                       className={classes.inputBox} autoComplete="off"
                       name='faturaFrete'/>
            </Grid>
          </Grid>
          {/*---------------------------------- onSubmit -------------------------------*/}
          <Grid item xs={12}>
            <IconButton
              onClick={onSubmit}
              variant="contained"
              className={classes.submitBt}
              size="small"
              disabled={isPending || !isRegValid || !formState.dirty}>
              {(isPending)
                ? <CircularProgress color="secondary" size={20}/>
                : <CheckIcon color={isRegValid ? successColor : errorColor}/>}
            </IconButton>
          </Grid>
        </Grid>
        {[{
          field: 'te_id',
          Component: React.createElement(TEDrawer, {
            defaultValue: watchTE.index,
            indexTitleOptList: teList,
            handleChange: (val) => handleChangeTE(val),
          })
        }, {
          field: 'marca_id',
          Component: React.createElement(MarcaDrawer, {
            defaultValue: watchMarca.index,
            haIMM: haIMM,
            IMM: IMM,
            indexTitleOptList: marcasTEList,
            handleChange: (val) => handleChangeMarca(val),
          })
        }, {
          field: 'modelo_id',
          Component: React.createElement(ModeloDrawer, {
            defaultValue: watchModelo.index,
            indexTitleOptList: modelosMTEList,
            handleChange: (val) => handleChangeModelo(val),
          })
        }, {
          field: 'transportador_id',
          Component: React.createElement(TransportadorDrawer, {
            defaultValue: watchTransp.index,
            indexTitleOptList: transpList,
            handleChange: (val) => handleChangeTransp(val),
          })

        }].map((drawer, index) => (
          <Drawer key={drawer.field + index} anchor={'right'} open={drawerState[drawer.field]} onClose={toggleDrawer(drawer.field, false)}>
            <div className={classes.drawerWrapper}>
              {drawer.Component}
            </div>
          </Drawer>))}
      </Form>
    </FormContext>
  )
}

export default EntradasForm
