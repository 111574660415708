import { useAsync } from "react-async"
import { ativaPlano, desativaPlano } from "./apiPlanos"
import Box from "@material-ui/core/Box"
import React from "react"
import ADButton from "../../../components/SharedComponents/Shared/LFButtons/ADButton"

const ativaDesativamsgs = {
  BUTTONS: {
    ATIVA: "Ativar Plano",
    DESATIVA: "Desativar Plano"
  }
}

const AtivaDesativaPlanoButtons = ({plano, setPlano, reset, defaultValues}) => {
  const {isLoading: isLoadingAP, run: aPlanoFn} = useAsync({
    deferFn: ativaPlano,
    onResolve: () => {
      setPlano({...plano, ativo: !plano.ativo, isAtivavel: !plano.isAtivavel, isDesativavel: !plano.isDesativavel})
      reset(defaultValues({
        ...plano,
        ativo: !plano.ativo,
        isAtivavel: !plano.isAtivavel,
        isDesativavel: !plano.isDesativavel
      }))
    }
  })
  const {isLoading: isLoadingDP, run: dPlanoFn} = useAsync({
    deferFn: desativaPlano,
    onResolve: () => {
      setPlano({...plano, ativo: !plano.ativo, isAtivavel: !plano.isAtivavel, isDesativavel: !plano.isDesativavel})
      reset(defaultValues({
        ...plano,
        ativo: !plano.ativo,
        isAtivavel: !plano.isAtivavel,
        isDesativavel: !plano.isDesativavel
      }))
    }
  })

  return (<Box component="span">
    {(!plano.ativo && plano.isAtivavel) ?
      <ADButton isLoading={isLoadingAP} disabled={!plano.isAtivavel} ativo={plano.ativo}
                onClick={() => aPlanoFn(plano.id)}>{ativaDesativamsgs.BUTTONS.ATIVA}</ADButton>
      : (plano.ativo && plano.isDesativavel) ?
        <ADButton isLoading={isLoadingDP} disabled={!plano.isDesativavel} ativo={plano.ativo}
                  onClick={() => dPlanoFn(plano.id)}>{ativaDesativamsgs.BUTTONS.DESATIVA}</ADButton> :
        null
    }
  </Box>)
}

export default AtivaDesativaPlanoButtons
