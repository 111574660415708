import React from "react"
import SvgIcon from "./SvgIcon"

const CancelIcon = (others) => {
  return (
    <SvgIcon viewBox="0 0 512 512"  {...others}>
      <path
        d="m505.92 476.57-220.57-220.57 220.56-220.56c8.106-8.105 8.106-21.248 0-29.354-8.105-8.106-21.248-8.106-29.354 0l-220.56 220.56-220.57-220.56c-8.105-8.106-21.248-8.106-29.354 0-8.106 8.105-8.106 21.248 0 29.354l220.57 220.56-220.57 220.57c-8.106 8.106-8.106 21.248 0 29.354 8.105 8.105 21.248 8.106 29.354 0l220.57-220.57 220.57 220.57c8.105 8.105 21.248 8.106 29.354 0s8.106-21.248 0-29.354z"/>
    </SvgIcon>
  )
}

export default CancelIcon
