import React from "react"
import { Link as RouterLink } from 'react-router-dom'
import Typography from "@material-ui/core/Typography"
import Fab from "@material-ui/core/Fab"
import Grid from "@material-ui/core/Grid"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import { Badge, StyledTableCell } from "../../../SharedStyles/SharedStyles"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import msgsClientes from "../../../../pages/Entidades/Clientes/msgsClientes"
import { getRequest } from "../../../../api/api"
import msgsTecnicos from "../../../../pages/Entidades/Tecnicos/msgsTecnicos"
import { immBgUltraLight, tableStyles } from "../../../../constants/appTheme"
import { patsURLname } from "../../../../pages/Pats/apiPats"
import patMsgs from "../../../../pages/Pats/patMsgs"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { TableInfoPatAnulado } from "../../../../pages/Pats/ShowPat/Partials/TableInfoPatAnulado"
import LeftMarginSpan from "../../../SharedStyles/LeftMarginSpan"
import StyledLabel from "../../../SharedStyles/StyledLabel"
import { ShowDate } from "../Show/ShowDateTime"
import BoolColoredCircleIcon from "../BoolColoredCircleIcon"
import { EntityOpenDialogButton } from "../Dialog/EntityOpenDialogButton"
import { TableCliente } from "./TableCliente"
import { TableTecnico } from "./TableTecnico"
import { TableEquipamento } from "./TableEquipamento"
import msgsEquips from "../../../../pages/Equips/ListEquips/msgsEquips"


const useStyles = makeStyles((theme) => ({
  btRow: {
    padding: '1em 0 .5em',
  },
  anulado: {
    background: immBgUltraLight,
  },
  bail: {},
  bt: {
    minHeight: '60px',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightLight,
  },
}))

function TableDashboardPat({data: pat, showLink}) {
  const classes = tableStyles()
  const localClasses = useStyles()

  const cliente = (pat.cliente && pat.cliente.data) || false
  const tecnico = (pat.tecnico && pat.tecnico.data) || false
  const equipCli = (pat.equipCli && pat.equipCli.data) || false
  const patIsAnulado = (!!pat.anulado_at)

  return ((!!pat)
    ? (
      <React.Fragment>

        {showLink && <Grid container spacing={2} justify="center">
          <Grid item xs={4}>
            <Fab component={RouterLink} target="_blank" size="small"
                 to={getRequest({URLname: patsURLname, id: pat.id}).gotoItemPath()} color="secondary"
                 variant="extended" style={{boxShadow: 'none'}}>
              <Typography variant="body2" style={{paddingLeft: '5px', fontSize: '12px'}}
                          display={'inline'}>{patMsgs.OPEN_BUTTON}</Typography>
            </Fab>
          </Grid>
        </Grid>}

        <Grid container spacing={1} justify="space-between"
              className={patIsAnulado ? localClasses.anulado : localClasses.bail}>

          {patIsAnulado ? <Grid item xs={12} sm={12} md={12}>
            <TableInfoPatAnulado pat={pat}/>
          </Grid> : null}

          <Grid item xs={12} sm={12} md={12}>
            <TableContainer>
              <Table className={classes.table} size="small" aria-label="TablePatProps">
                {/* ----------------------------------------------------------------- */}
                <>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell colSpan="3">{pat.refCode}</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key="patCausaType">
                      <TableCell colSpan="3">
                        <Badge>{pat.causaPAT}</Badge>
                        <LeftMarginSpan>{pat.tipoPat}</LeftMarginSpan>
                      </TableCell>
                    </TableRow>
                    <TableRow key="paCliType">
                      <TableCell colSpan="3">
                        <Badge props={pat.tipocli}>{pat.tipocliChar}</Badge>
                        <LeftMarginSpan>{pat.tipoText}</LeftMarginSpan>
                      </TableCell>
                    </TableRow>
                    <TableRow key="patDataLimite">
                      <TableCell colSpan="2">
                        <StyledLabel>{patMsgs.DATA_LIMITE}</StyledLabel>
                      </TableCell>
                      <TableCell colSpan="1" style={{textAlign: "right"}}>
                        <ShowDate date={pat.dataLimite} offLimit={pat.offLimit}/>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </>
              </Table>
            </TableContainer>

            <TableContainer>
              <Table className={classes.table} size="small" aria-label="TablePatProps">
                {/* ----------------------------------------------------------------- */}
                <>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>
                        {patMsgs.ISPAGO}
                        <LeftMarginSpan><BoolColoredCircleIcon flagTF={!!pat.pago} size={12}/></LeftMarginSpan>
                      </StyledTableCell>
                      <StyledTableCell>
                        {patMsgs.ISFATURADO}
                        <LeftMarginSpan><BoolColoredCircleIcon flagTF={!!pat.dhFaturada} size={12}/></LeftMarginSpan>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                </>
              </Table>
            </TableContainer>
          </Grid>
          {
            patIsAnulado ? null :

              <Grid container spacing={2} justify="space-between"
                    className={patIsAnulado ? localClasses.anulado : localClasses.bail}>

                {/*------------ BTs cliente, Técnico e Equipamento ------------*/}
                <Grid container spacing={2} justify="space-between" className={localClasses.btRow}>

                  <Grid item xs>
                    {(!!cliente)
                      ? <EntityOpenDialogButton component={<TableCliente data={cliente} showLink/>}
                                                btTitle={msgsClientes.DIALOG_BUTTON}
                                                dialogTitle={cliente.nome}
                                                disabled={!cliente} fullWidth={true}
                                                classNameProp={localClasses.bt}/>
                      : null}
                  </Grid>
                  <Grid item xs>
                    {(!!tecnico)
                      ? <EntityOpenDialogButton component={<TableTecnico data={tecnico} showLink/>}
                                                btTitle={msgsTecnicos.DIALOG_BUTTON}
                                                dialogTitle={tecnico.nome}
                                                disabled={!tecnico} fullWidth={true}
                                                classNameProp={localClasses.bt}/>
                      : null}
                  </Grid>
                  <Grid item xs>
                    {(!!equipCli)
                      ? <EntityOpenDialogButton component={<TableEquipamento data={equipCli}/>}
                                                btTitle={msgsEquips.DIALOG_BUTTON}
                                                dialogTitle={equipCli.numserie || '#Ref. - ND#'}
                                                disabled={!equipCli} fullWidth={true}
                                                classNameProp={localClasses.bt}/>
                      : null}
                  </Grid>
                </Grid>

              </Grid>}
        </Grid>
      </React.Fragment>)
    : null)
}

export { TableDashboardPat }
