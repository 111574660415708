import React from "react"
import { useParams } from "react-router"
import MarcaUpdate from "./MarcaUpdate"
import MarcaCreate from "./MarcaCreate"
import Modelos from "./Modelos/Modelos"
import TabsComponent from "../../components/SharedComponents/Shared/TabsComponent"
import marcasMsgs from "./msgs"

const marcaTabs = (id) => {
  return ([
    {
      label: marcasMsgs.TAB_LABEL,
      index: 0,
      component: <MarcaUpdate id={id}/>,
    },
    {
      label: marcasMsgs.MODELOS_LABEL,
      index: 1,
      component: <Modelos id={id}/>,
    }
  ])
}

function MarcaView() {

  let {id} = useParams()

  return (
    <React.Fragment>
      {(!!parseInt(id, 10)) ?
        <TabsComponent renderTabs={marcaTabs(id)}/> :
        <MarcaCreate/>
      }
    </React.Fragment>
  )
}

export default MarcaView
