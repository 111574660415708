import React from "react"
import { Link as RouterLink } from 'react-router-dom'
import { AppMsgs } from "../../../../constants/appMsgs"
import Typography from "@material-ui/core/Typography"
import Fab from "@material-ui/core/Fab"
import Grid from "@material-ui/core/Grid"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import { StyledTableCell } from "../../../SharedStyles/SharedStyles"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import PropTypes from "prop-types"
import msgsClientes from "../../../../pages/Entidades/Clientes/msgsClientes"
import { getRequest } from "../../../../api/api"
import { tecnicosUrl } from "../../../../pages/Entidades/Tecnicos/apiTecnicos"
import msgsTecnicos from "../../../../pages/Entidades/Tecnicos/msgsTecnicos"
import { tableStyles } from "../../../../constants/appTheme"
import ShowRating from "../Show/ShowRating"

function TableTecnico({data, showLink}) {
  const classes = tableStyles()
  return ((!!data)
    ? (
      <React.Fragment>

        {showLink && <Grid container spacing={2} justify="center">
          <Grid item xs={3}>
            <Fab component={RouterLink} target="_blank" size="small"
                 to={getRequest({URLname: tecnicosUrl, id: data.id}).gotoItemPath()} color="secondary"
                 variant="extended" style={{boxShadow: 'none'}}>
              <Typography variant="body2" style={{paddingLeft: '5px', fontSize: '12px'}}
                          display={'inline'}>{msgsTecnicos.OPEN_BUTTON}</Typography>
            </Fab>
          </Grid>
          <Grid item xs={9}>
            <img style={{
              width: '100%', height: '140px', maxWidth: '140px',
              display: 'block',
              objectFit: 'scale-down', margin: '0 auto', borderRadius: '10px'
            }} src={data.logo} alt={data.nome}/>
          </Grid>
        </Grid>}

        <TableContainer>
          <Table className={classes.table} size="small" aria-label="TableTecnico">
            {/* ---------------------------- NOME & USERNAME -------------------------------------------------- */}
            <>
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan="3">{AppMsgs.ENTIDADES.EDIT.LABEL.NOME}</StyledTableCell>
                  <StyledTableCell colSpan="1">{AppMsgs.ENTIDADES.EDIT.LABEL.USER}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="nome">
                  <TableCell colSpan="3">{data.nome}</TableCell>
                  <TableCell colSpan="1">{data.username}</TableCell>
                </TableRow>
              </TableBody>
            </>
            {/* ---------------------------- TIPO_CAT & AVAL_IMM & NIF ---------------------------------------------- */}
            <>
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan="1">{msgsTecnicos.EDIT.LABEL.TIPO_CAT}</StyledTableCell>
                  <StyledTableCell colSpan="2">{msgsTecnicos.EDIT.LABEL.AVAL_IMM}</StyledTableCell>
                  <StyledTableCell colSpan="2">{AppMsgs.ENTIDADES.EDIT.LABEL.NIF}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan="1">{data.tipoCAT}</TableCell>
                  <TableCell colSpan="2"><ShowRating qtdStars={data.avalImm}/></TableCell>
                  <TableCell colSpan="2">{data.nif}</TableCell>
                </TableRow>
              </TableBody>
            </>
            {/* ---------------------------- EMAIL & TLM & TLF ------------------------------------------------ */}
            <>
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan="2">{AppMsgs.ENTIDADES.EDIT.LABEL.EMAIL}</StyledTableCell>
                  <StyledTableCell colSpan="1">{AppMsgs.ENTIDADES.EDIT.LABEL.TLM}</StyledTableCell>
                  <StyledTableCell colSpan="1">{AppMsgs.ENTIDADES.EDIT.LABEL.TLF}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan="2">{data.email}</TableCell>
                  <TableCell colSpan="1">{data.tlm}</TableCell>
                  <TableCell colSpan="1">{data.tlf}</TableCell>
                </TableRow>
              </TableBody>
            </>
            {/* ---------------------------- MORADA & ZIP_CODE ------------------------------------------------ */}
            <>
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan="3">{AppMsgs.ENTIDADES.EDIT.LABEL.MORADA}</StyledTableCell>
                  <StyledTableCell colSpan="1">{msgsClientes.EDIT.LABEL.ZIP_CODE}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan="3">{data.morada}</TableCell>
                  <TableCell colSpan="1">{data.cp4}-{data.cp3}</TableCell>
                </TableRow>
              </TableBody>
            </>
            {/* ------------------------------------ OBS ------------------------------------------------------ */}
            <>
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan="4">{AppMsgs.ENTIDADES.EDIT.LABEL.OBS}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan="4">{data.obs}</TableCell>
                </TableRow>
              </TableBody>
            </>
          </Table>
        </TableContainer>
      </React.Fragment>)
    : null)
}

TableTecnico.defaultProps = {
  data: null,
  showLink: false
}

TableTecnico.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    user_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    nome: PropTypes.string,
    nif: PropTypes.string,
    tipoCAT: PropTypes.string,
    avalImm: PropTypes.number,
    email: PropTypes.string,
    tlm: PropTypes.string,
    tlf: PropTypes.string,
    morada: PropTypes.string,
    cp4: PropTypes.string,
    cp3: PropTypes.string,
    obs: PropTypes.string,
  }),
  showLink: PropTypes.bool
}


export { TableTecnico }
