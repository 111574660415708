const armazemMsgs = {
  LABEL: "Armazém",
  LABEL_IN: "Entradas",
  LABEL_OUT: "Saídas",

  NUM_SERIE: "Núm. de Série",
  TE: "Tipos de Equipamento",
  MARCA: "Fabricante",
  EQUIP: "Equipamento",
  TRANSPORT: "Transportador",
  FAT_FRETE: "Fat. de Frete",
  DATA_HORA: "Data Hora",
  FAT_COMPRA: "Fat. de Compra",

  MUST_HAVE: {
    TE: "Deve indicar o Tipos de Equipamento",
    MARCA: "Deve indicar o Fabricante",
  },

  FAT_VENDA: "Fatura Venda",
}

export default armazemMsgs
