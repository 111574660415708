import Typography from "@material-ui/core/Typography"
import React from "react"
import { ShowDateTime } from "../../../../../components/SharedComponents/Shared/Show/ShowDateTime"
import Grid from "@material-ui/core/Grid"
import { Paper } from "@material-ui/core"

const obsMsgs = {

  NEW_LABEL: "Associar Comentário",
  BACK_LABEL_UPDATE: `Cancelar a edição`, //  usado mas nunca apresentado pois não existe a funcionalidade update em ListRepo
  BACK_LABEL_CREATE: `Cancelar o registo de um novo Comentário`,

  CONFIRM_DIALOG_CONTENT: (obs) => {
    return (
      <React.Fragment>
        <Grid key={obs.id} container spacing={2}
              direction="row"
              justify="space-between"
              alignItems="center">
          {/*---------------------------------------------------------------*/}
          <Grid item xs={12}>
            <Typography color='primary'>{obs.origemObs}</Typography>
            <ShowDateTime dateTime={obs.dhRegisto}/>
          </Grid>
          {/*---------------------------------------------------------------*/}
          <Grid item xs={12}>
            <Paper dangerouslySetInnerHTML={{__html: obs.obs}}/>
          </Grid>
        </Grid>
        <hr/>
        <p>
        </p>
        <Typography> Confirma a remoção deste comentário? </Typography>
      </React.Fragment>
    )
  },

  COMMENT: "Comentário",
  COMMENT_ORIG: "Origem do comentário",
}

export { obsMsgs }

