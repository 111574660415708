import React from "react"
import { MakeComponent } from "../../../../components/SharedComponents/Shared/Utils/ComponentGen/MakeComponent"
import TabsComponent from "../../../../components/SharedComponents/Shared/TabsComponent"
import AssocTecEAgendaPAT from "./AssocTecEAgendaPAT/AssocTecEAgendaPAT"
import { ReagendaPATForm } from "./ReAgendarPAT/ReagendaPATForm"
import { IniciarPATForm, IniciarVisitaForm } from "./IniciarPAT/IniciarPATForm"
import { ConcluirVisitaForm, ConcluirVisitaPATForm } from "./ConcluirVisita/ConcluirVisitaForm"
import { RelatarPATForm } from "./RelatarPAT/RelatarPATForm"
import { ConferirPATForm } from "./ConferirPAT/ConferirPATForm"
import { FecharPATForm } from "./FecharPAT/FecharPATForm"
import { ConfirmarFaturaEncerrarForm } from "./ConfirmarFaturaEncerrar/ConfirmarFaturaEncerrarForm"
import { AnularPATForm } from "./Remover_AnularPAT/AnularPATForm"
import { RemoverPATForm } from "./Remover_AnularPAT/RemoverPATForm"

const InfostatesComponents = {
  ChangeTecPAT: AssocTecEAgendaPAT,
  // AgendarPAT: AgendarPAT, // Já não faz sentido já que a Associação de TEC já envolve agendamento
  ReAgendarPAT: ReagendaPATForm,
  // infoStates no PAT
  IniciarPAT: IniciarPATForm,     // para adaptar ao contexto PAT / Visita
  ConcluirVisitaPAT: ConcluirVisitaPATForm,

  // infoStates na Visita
  IniciarVisita: IniciarVisitaForm,  // para adaptar ao contexto PAT / Visita
  ConcluirVisita: ConcluirVisitaForm,

  RelatarPAT: RelatarPATForm,
  ConferirPAT: ConferirPATForm,
  FecharPAT: FecharPATForm,
  FaturarPAT: ConfirmarFaturaEncerrarForm,

  AnularPAT: AnularPATForm,
  RemoverPAT: RemoverPATForm,
}

const infostatesMsgs = {
  UnKown: {
    tabTitle: '-UnKown title Devel INFO-'
  },
  ChangeTecPAT: {
    tabTitle: '(Re)Associar Técnico'
  },
  // AgendarPAT: { tabTitle: 'Agendar PAT' },  // Já não faz sentido já que a Associação de TEC já envolve agendamento
  ReAgendarPAT: {
    tabTitle: 'Reagendar PAT'
  },
  IniciarPAT: {  // para adaptar ao contexto PAT / Visita
    tabTitle: 'Iniciar Visita do PAT (@PAT)'
  },
  IniciarVisita: {  // para adaptar ao contexto PAT / Visita
    tabTitle: 'Iniciar Visita (@Visita)'
  },
  ConcluirVisitaPAT: { // para adaptar ao contexto PAT / Visita
    tabTitle: 'Concluir Visita (@PAT)'
  },
  ConcluirVisita: { // para adaptar ao contexto PAT / Visita
    tabTitle: 'Concluir Visita (@Visita)'
  },
  AnularPAT: {
    tabTitle: 'Anular PAT'
  },
  RelatarPAT: {
    tabTitle: 'Registar o Relatório Técnico'
  },
  ConferirPAT: {
    tabTitle: 'Conferir PAT'
  },
  FecharPAT: {
    tabTitle: 'Fechar PAT'
  },
  FaturarPAT: {
    tabTitle: 'Faturar PAT'
  },
  RemoverPAT: {
    tabTitle: 'Remover PAT'
  },
  // ReenviarPDFPATaoCliente: {
  //   tabTitle: 'Reenviar PDF ao Cliente'
  // }
}
// const getInfoStateTitle = (componentKeyName) => {
//   return (infostatesMsgs[componentKeyName] || infostatesMsgs['UnKown']).tabTitle
// }

const StateFlowTabs = ({repo, infoStatesToCheck, reload, handleBack}) => {
  let index = 0
  let tabs = []

  Object.getOwnPropertyNames(infoStatesToCheck).forEach((val) => {
    const componentKeyName = val.replace('may', '')
    // flag ativada (true) e estado existe (ainda... :) )
    if (infoStatesToCheck[val] && infostatesMsgs[componentKeyName])
      tabs.push({
        label: infostatesMsgs[componentKeyName].tabTitle,
        hasMany: false,
        qtd: 1,
        index: index++,
        component: MakeComponent({
          componentsObj: InfostatesComponents,
          componentKeyName: componentKeyName,
          repo, // PAT ou Visita
          reload, handleBack
        })
      })
  })

  return (
    <TabsComponent renderTabs={tabs}/>
  )
}

const StateFlowPatTabs = ({pat, reload, handleBack}) => {

  const patInfoStates = (pat && pat.infostates) || {}

  return (
    <StateFlowTabs repo={pat}
                   infoStatesToCheck={{
                     ...patInfoStates,
                     // permitir diferenciar entre PAT e Visita
                     // ver tb StateFlowVisitaPatTabs
                     mayConcluirVisita: false,
                     mayConcluirVisitaPAT: patInfoStates.mayConcluirVisita
                   }}
                   reload={reload}
                   handleBack={handleBack}/>
  )
}


// const StateFlowPatTabs = ({pat, reload}) => {
//   let index = 0
//   let tabs = []
//   const patInfoStates = (pat && pat.infostates) || {}
//
//   console.log(pat, patInfoStates)
//
//   Object.getOwnPropertyNames(patInfoStates).forEach((val) => {
//     const componentKeyName = val.replace('may', '')
//     // flag ativada (true) e estado existe (ainda... :) )
//     if (patInfoStates[val] && infostatesMsgs[componentKeyName])
//       tabs.push({
//         label: infostatesMsgs[componentKeyName].tabTitle,
//         hasMany: false,
//         qtd: 1,
//         index: index++,
//         component: MakeComponent({
//           componentsObj: InfostatesComponents,
//           componentKeyName: componentKeyName,
//           // flagVal: patInfoStates[val],
//           pat,
//           reload
//         })
//       })
//   })
//
//   return (
//     <TabsComponent renderTabs={tabs}/>
//   )
// }

export {
  StateFlowPatTabs,
  StateFlowTabs,
}
