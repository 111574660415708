import React from "react"
import Divider from "@material-ui/core/Divider"
import { TableAcoesAvisos } from "../../../SharedStyles/SharedStyles"


function TableAvisos({avisos}) {
  const classes = TableAcoesAvisos()
  return (
    <React.Fragment>
      <h3 className={classes.header}>Avisos</h3>
      {
        avisos.map((aviso, key) => {
          const desc = aviso.text.split(' - ')
          return (
            <React.Fragment key={key}>
              <div className={classes.item}>
                <span>Data do Aviso: {aviso.dataLimite}</span>
                <span>{desc[0]}</span>
                <span>{desc[1]}</span>
                <Divider/>
              </div>
            </React.Fragment>
          )
        })
      }
    </React.Fragment>
  )
}


export default TableAvisos
