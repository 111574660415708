import React from "react"
import * as Yup from "yup"
import { requiredDateValidation, selectValidation } from "../../../../../constants/appValidation"
import patInfoStatesMsgs from "../patInfoStatesMsgs"
import { Form } from "../../../../../components/SharedStyles/SharedStyles"
import Grid from "@material-ui/core/Grid"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import LFSubmitButton from "../../../../../components/SharedComponents/Shared/LFButtons/LFSubmitButton"
import { FormContext, useForm } from "react-hook-form"
import { LFFormatDateTime } from "../../../../../constants/appConst"
import { useAsync } from "react-async"
import { reagendaPat } from "../apiInfoStates"
import LFDateTimePickerInput from "../../../../../components/LFInputs/LFDateTimePickerInput"
import LFSelect from "../../../../../components/LFInputs/LFSelect"
import FormHelperText from "@material-ui/core/FormHelperText"
import LFTextEditor from "../../../../../components/LFInputs/LFTextEditor"
import makeStyles from "@material-ui/core/styles/makeStyles"
import moment from "moment"
import MomentUtils from "@date-io/moment"
import "moment/locale/pt"

const useStyles = makeStyles(theme => ({
  quillEditor: {
    minHeight: '8em'
  }
}))

const options = [
  {index: "PRC", title: "Remarcação a pedido do cliente"},
  {index: "PRT", title: "Remarcação solicitada pelo CAT (Técnico)"},
  {index: "PRI", title: "Remarcação por iniciativa da IMMERSAT"},
]

const validationSchema = Yup.object().shape({
  dhAgendamentoComCliente: requiredDateValidation(patInfoStatesMsgs.DH_AGENDAMENTO),
  causaEncerrouVisita: selectValidation(patInfoStatesMsgs.CAUSA_REAGENDAMENTO),
})

const ReagendaPATForm = ({repo, reload}) => {
  const classes = useStyles()

  const {isPending, run} = useAsync({
    deferFn: reagendaPat,
    onResolve: (dadosAgendamento) => {
      reload()
    }
  })

  const methods = useForm({
    validationSchema: validationSchema,
    defaultValues: {
      dhAgendamentoComCliente: new Date(),
      // ------------------------------------------
      // Causa do reagendamento?
      // ------------------------------------------
      // PRC  - Pedido Remarcação pelo cliente
      // PRT  - Pedido Remarcação pelo CAT (Técnico)
      // PRI  - Pedido Remarcação pela IMMERSAT
      causaEncerrouVisita: '',
      obsFinal: '',
    }
  })

  const {handleSubmit, formState, control} = methods

  const onSubmit = ({...values}) => {
    const convertValues = {
      ...values, ...{
        pat_id: repo.id,
        dhAgendamentoComCliente: values.dhAgendamentoComCliente && LFFormatDateTime(values.dhAgendamentoComCliente),
        causaEncerrouVisita: values.causaEncerrouVisita.index,
      }
    }
    run({...convertValues})
  }

  return (
    <FormContext {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} justify="center">
          {/*---------------------------------------------------------------*/}
          <Grid item xs={6}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <LFDateTimePickerInput name='dhAgendamentoComCliente'
                                     control={control}
                                     label={patInfoStatesMsgs.DH_AGENDAMENTO}/>
            </MuiPickersUtilsProvider>
          </Grid>
          {/*---------------------------------------------------------------*/}
          <Grid item xs={6}>
            <LFSelect name="causaEncerrouVisita" control={control} options={options} label={patInfoStatesMsgs.CAUSA_REAGENDAMENTO}/>
          </Grid>
          {/*---------------------------------------------------------------*/}
          <Grid item xs={12}>
            <FormHelperText>{patInfoStatesMsgs.OBS}</FormHelperText>
            <LFTextEditor name='obsFinal' control={control} className={classes.quillEditor}/>
          </Grid>
          {/*---------------------------------------------------------------*/}
          <Grid item xs={12}>
            <LFSubmitButton size="large" noStyle={true} style={{padding: '1rem', fontSize: '0.875rem', lineHeight: '1.2rem'}}
                            isLoading={isPending}
                            dirty={!formState.dirty}>
              {patInfoStatesMsgs.BT_REAGENDAR}
            </LFSubmitButton>
          </Grid>
        </Grid>
      </Form>
    </FormContext>
  )
}

export {
  ReagendaPATForm,
}
