import React from "react"
import { AppMsgs } from "../../../constants/appMsgs"
import { deleteTecnico, readTecnicosTable, tecnicosUrl, undeleteTecnico } from "./apiTecnicos"
import LFTable from "../../../components/LFTable/LFTable"
import BaseTableListWithRoutes from "../../../components/SharedComponents/Shared/BaseTableListWithRoutes"
import TabsComponent from "../../../components/SharedComponents/Shared/TabsComponent"
import StarIcon from "../../../components/Svg-Icons/StarIcon"
import { primaryColor, secondaryColor } from "../../../constants/appTheme"
import Badge from "@material-ui/core/Badge"
import { Badge as InsideBadge } from "../../../components/SharedStyles/SharedStyles"
import LinkWithIcon from "../../../components/SharedComponents/Shared/LinkWithIcon"
import EmailIcon from "../../../components/Svg-Icons/EmailIcon"
import SmartPhoneIcon from "../../../components/Svg-Icons/SmartPhoneIcon"
import PhoneIcon from "../../../components/Svg-Icons/PhoneIcon"
import expandColumn from "../../../components/LFTable/ExpandColumn"
import { TableTecnico } from "../../../components/SharedComponents/Shared/Tables/TableTecnico"
import msgsTecnicos from "./msgsTecnicos"

const columns = {
  Header: () => null,
  id: 'Tecnico',
  columns: [
    {
      Header: AppMsgs.ENTIDADES.TABLE.HEADER.AVATAR,
      accessor: "logo",
      Cell: ({row}) => (
        <>
          <img height={40} src={row.original.logo} alt={row.original.nome}/>
        </>
      ),
    },
    {
      Header: msgsTecnicos.TABLE.HEADER.TIPO_CAT,
      accessor: "tipoCAT",
      Cell: ({row}) => (
        <>
          <InsideBadge props={row.original.tipoCAT === 'I' ? 1 : 0}>{row.original.tipoCAT}</InsideBadge>
        </>
      ),
    },
    {
      Header: AppMsgs.ENTIDADES.TABLE.HEADER.NOME,
      accessor: "nome"
    },
    {
      Header: AppMsgs.ENTIDADES.TABLE.HEADER.CONTACTO,
      accessor: "contacto",
      Cell: ({row}) => (
        <React.Fragment>
          {row.original.email &&
          <LinkWithIcon icon={<EmailIcon size={12} style={{marginRight: '5px'}} color={secondaryColor}/>}
                        href={"mailto:" + row.original.email}
                        desc={row.original.email}
          />}
          {row.original.tlm &&
          <LinkWithIcon icon={<SmartPhoneIcon size={12} style={{marginRight: '5px'}} color={secondaryColor}/>}
                        href={"tel:" + row.original.tlm}
                        desc={row.original.tlm}/>}
          {row.original.tlf &&
          <LinkWithIcon icon={<PhoneIcon size={12} style={{marginRight: '5px'}} color={secondaryColor}/>}
                        href={"tel:" + row.original.tlf}
                        desc={row.original.tlf}
          />}
        </React.Fragment>
      ),
    },
    {
      Header: msgsTecnicos.TABLE.HEADER.AVAL_IMM,
      accessor: "avalImm",
      Cell: ({row}) => (
        <>
          <Badge badgeContent={row.original.avalImm} color="secondary">
            <StarIcon size={20} color={primaryColor}/>
          </Badge>
        </>
      ),
    },
    {
      Header: msgsTecnicos.TABLE.HEADER.PATS_COUNT,
      accessor: "pats_count",
      disableSortBy: true
    },
    {
      Header: msgsTecnicos.TABLE.HEADER.PATS_NAO_CONCLUIDAS_COUNT,
      accessor: "pats_nao_concluidas_count",
      disableSortBy: true
    },
    {
      Header: msgsTecnicos.TABLE.HEADER.MARCAS_TECNICO_COUNT,
      accessor: "marcas_tecnico_count",
      disableSortBy: true
    },
    {
      Header: msgsTecnicos.TABLE.HEADER.LOCS_TECNICO_COUNT,
      accessor: "locs_tecnico_count",
      disableSortBy: true
    }
  ]
}

/**
 * Label = Title Tab
 * Index = Position
 * Component = Render Component
 * @type {function(*, *): *[]}
 */
const tecnicoTabs = (renderRowSubComponent, columnsWithExpand) => [
  {
    label: AppMsgs.DEFAULT_VALUES.ATIVOS_LABEL,
    index: 0,
    component: <BaseTableListWithRoutes
      route={tecnicosUrl}
      columns={[columns]}
      deleteFn={deleteTecnico}
      readFn={readTecnicosTable}
      addButtonDesc={msgsTecnicos.TABLE.ADD_BUTTON}/>,
  },
  {
    label: AppMsgs.DEFAULT_VALUES.HISTORICO_LABEL,
    index: 1,
    component: <LFTable
      columns={columnsWithExpand}
      requestFn={([props]) => readTecnicosTable([{trashed: true, ...props}])}
      showAddButton={false}
      renderRowSubComponent={renderRowSubComponent}
      showEditButton={false}
      showDeleteButton={false}
      showUndeleteButton={true}
      unDeleteFn={undeleteTecnico}
    />,
  }
]

const Tecnicos = () => {

  const renderRowSubComponent = React.useCallback(
    ({row}) => (<TableTecnico data={row.original}/>
    ), [])

  const columnsWithExpand = React.useMemo(() => [{...expandColumn}, {...columns}], [])

  return (
    <TabsComponent renderTabs={tecnicoTabs(renderRowSubComponent, columnsWithExpand)}/>
  )
}

export default Tecnicos
