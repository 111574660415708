import React, { useState } from "react"
import EquipForm from "./EquipForm"
import { updateEquip } from "./apiEquips"
import { EntityOpenDialogButton } from "../../../components/SharedComponents/Shared/Dialog/EntityOpenDialogButton"
import { TableCliente } from "../../../components/SharedComponents/Shared/Tables/TableCliente"
import msgsClientes from "../../Entidades/Clientes/msgsClientes"
import Button from "@material-ui/core/Button"
import AddIcon from "../../../components/Svg-Icons/AddIcon"
import { secondaryColor, textHint } from "../../../constants/appTheme"
import Box from "@material-ui/core/Box"
import msgsEquips from "./msgsEquips"
import { makeStyles } from "@material-ui/core"
import PatCreate from "../../Pats/PatCreate"


const useStyles = makeStyles(theme => ({
  formRoot: {
    marginTop: '2rem',
  },
  boxRoot: {
    margin: 0,
  },
  boxCells: {
    marginRight: '3rem',
  },

  bt: {
    width: '5rem',
    margin: '.5rem 0 .5rem 2rem'
  },

}))

const EquipUpdate = ({equip, cliente, setEquip}) => {
  const classes = useStyles()
  const [patType, setPatType] = useState('')

  const handlerCreate_PAT = () => {
    setPatType('PMCE')
  }
  const handlerCreate_PA = () => {
    setPatType('PA')
  }
  const handlerCancelEdit = () => {
    setPatType('')
  }

  return (
    <React.Fragment>

      {(patType === '')
        ? (
          <React.Fragment>
            <Box display="flex"
                 flexDirection="row"
                 alignItems="center"
                 justifyContent="center"
                 className={classes.boxRoot}
                 p={1} m={1}>
              <Box p={1} flexGrow={1}>
                <EntityOpenDialogButton component={<TableCliente data={cliente} showLink/>}
                                        btTitle={msgsClientes.DIALOG_BUTTON}
                                        dialogTitle={cliente.nome}/>
              </Box>
              <Box display="flex"
                   flexDirection="row"
                   alignItems="center"
                   justifyContent="center"
                   className={classes.boxRoot}
                   flexWrap="wrap"
                   p={1} m={1}>
                <Box p={1} flexWrap="wrap"
                     className={classes.boxCell}>
                  <Button color="secondary" variant="outlined"
                          disableElevation
                          className={classes.bt}
                          onClick={handlerCreate_PAT}
                          startIcon={<AddIcon color={equip.has_pat_pmcesp ? textHint : secondaryColor}/>}
                          disabled={equip.has_pat_pmcesp}>
                    {msgsEquips.TABS.PMCE}
                  </Button>
                </Box>
                <Box p={1} flexWrap="wrap">
                  <Button color="secondary" variant="outlined"
                          disableElevation
                          className={classes.bt}
                          onClick={handlerCreate_PA}
                          startIcon={<AddIcon color={equip.has_pat_pa ? textHint : secondaryColor}/>}
                          disabled={equip.has_pat_pa}>
                    {msgsEquips.TABS.PA}
                  </Button>
                </Box>
              </Box>
            </Box>
            <EquipForm className={classes.formRoot}
                       equip={equip} cliente={cliente} method={updateEquip} setEquip={setEquip}/>
          </React.Fragment>)
        : (<PatCreate equip={equip} patType={patType} onCancelEdit={handlerCancelEdit}/>)
      }

    </React.Fragment>
  )
}

export default EquipUpdate
