import React from "react"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import { StyledTableCell } from "../../../../components/SharedStyles/SharedStyles"
import { ShowDateTime } from "../../../../components/SharedComponents/Shared/Show/ShowDateTime"
import { tableStyles } from "../../../../constants/appTheme"
import patMsgs from "../../patMsgs"

function TableContactoCLI({pat}) {
  const classes = tableStyles()
  const styleDisable = (!pat.dhContactoCliente) ? {pointerEvents: 'none', opacity: '0.3'} : {}
  return (
    <React.Fragment>
      <TableContainer>
        <Table className={classes.table}
               style={styleDisable}
               size="small" aria-label="TableContactoCliente">
          {/* ----------------------------------------------------------------- */}
          <>
            <TableHead>
              <TableRow>
                <StyledTableCell colSpan="3">{patMsgs.INFO_CONTACTO_CLIENTE}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key="ccCLI">
                {/*<TableCell colSpan="1"><DateTimeCalendarIcon/></TableCell>*/}
                <TableCell colSpan="3"><ShowDateTime dateTime={pat.dhContactoCliente}/></TableCell>
              </TableRow>
            </TableBody>
          </>
          {/* ----------------------------------------------------------------- */}
          <>
            <TableHead>
              <TableRow>
                <StyledTableCell colSpan="3">{patMsgs.OBS_INIC}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.fadedTableBody}>
              <TableRow key="mensagem">
                <TableCell colSpan="3" className={classes.obsTableCell}>
                  <span className={classes.obsBox}
                        dangerouslySetInnerHTML={{__html: pat.obsInicialCliente}}/>
                </TableCell>
              </TableRow>
            </TableBody>
          </>

        </Table>
      </TableContainer>
    </React.Fragment>
  )
}

export { TableContactoCLI }
