import { getUser } from "../api/auth-user"


async function bootstrapAppData() {
  const data = await getUser()
  // console.log('Quem tá cá?', data)
  if (!data) {
    return null
  }
  return data.data.data
}

export { bootstrapAppData }
