import React, { useState } from "react"
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import makeStyles from "@material-ui/core/styles/makeStyles"
import { ShowDateTime } from "../../Shared/Show/ShowDateTime"
import { errorColorDark, textSecondary, whiteColor } from "../../../../constants/appTheme"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "../../../Svg-Icons/DeleteIcon"
import { useAsync } from "react-async"
import CustomDialog from "../../Shared/Dialog/CustomDialog"
import { AppMsgs } from "../../../../constants/appMsgs"
import Typography from "@material-ui/core/Typography"
import { imagemMsgs } from "./imagemMsgs"
import { repoMsgs } from "../repoMsgs"
import PropTypes from "prop-types"

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '500px',
    height: '450px',
  },
  gridTitleBar: {
    backgroundColor: textSecondary
  },
  gridDateTime: {
    color: whiteColor,
    fontSize: '11px',
    marginLeft: theme.spacing(1),
    lineHeight: '10px',
    padding: '0',
  },
  icon: {
    color: 'rgba(255,76,0,0.5)',
  },
}))

const ImageGridList = ({repo, mayEdit, repoList, handleDeleteFn, showList}) => {
  const [openImgDialog, setOpenImgDialog] = React.useState(false)
  const [openedImg, setOpenedImg] = React.useState({})
  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false)
  const [confirmationItem, setConfirmationItem] = useState({})
  const classes = useStyles()

  const {run} = useAsync({
    deferFn: handleDeleteFn,
    onResolve: (deletedData) => {
      showList()
    }
  })

  const imageStyle = (img) => ({
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    background: `no-repeat url(${img}) 50%`,
    backgroundSize: 'contain',
    resize: 'both',
  })

  const handleImgDelete = (img) => {
    setConfirmationDialogVisible(true)
    setConfirmationItem(img)
  }

  const handleCancel = () => {
    // feito no CustomDialog
    // setConfirmationDialogVisible(false)
    setConfirmationItem({})
  }
  const handleOpenImageDialog = (img) => {
    setOpenedImg(img)
    setOpenImgDialog(true)
  }

  return (!!repoList.length)
    ? (
      <div className={classes.root}>
        <GridList cellHeight={180} className={classes.gridList}>
          {repoList.map((img) => (
            <GridListTile key={img.id}>
              {/*<img className={classes.gridImg} src={img.image} alt={img.text} onClick={() => handleOpenImageDialog(img)}/>*/}
              <div style={imageStyle(img.image)}
                   onClick={() => handleOpenImageDialog(img)}>
              </div>
              <GridListTileBar
                className={classes.gridTitleBar}
                title={img.text}
                subtitle={<ShowDateTime
                  classForDate={classes.gridDateTime} iconsColor={'#EEE'} dateTime={img.created_at}/>}
                actionIcon={mayEdit && confirmationItem && confirmationItem.id !== img.id &&
                <IconButton
                  onClick={() => handleImgDelete(img)}>
                  <DeleteIcon color={errorColorDark}/>
                </IconButton>}
              />
            </GridListTile>
          ))}
        </GridList>
        <CustomDialog open={openImgDialog} fullScreen setOpen={setOpenImgDialog} title={''}>
          <img src={openedImg.image} alt={openedImg.text} width="100%"/>
        </CustomDialog>
        <CustomDialog open={confirmationDialogVisible}
                      setOpen={setConfirmationDialogVisible}
                      title={repoMsgs.CONFIRM_DIALOG_TITLE}
                      onConfirm={() => run(confirmationItem.id)}
                      onCancel={() => handleCancel()}
                      msgsConfirm={repoMsgs.YES}
                      msgsCancel={repoMsgs.NO}>
          {imagemMsgs.CONFIRM_DIALOG_CONTENT(confirmationItem)}
        </CustomDialog>
      </div>
    )
    : (
      <Typography component="h1" variant="h6" color="inherit" noWrap>
        {AppMsgs.DEFAULT_VALUES.TABLE.EMPTY_TABLE_MSG}
      </Typography>
    )
}

// ImageGridList.defaultValues = {
//   mayEdit: false,
//   repoList: [],
//   handleDeleteFn: ()=>{},
// }

ImageGridList.propTypes = {
  repo: PropTypes.object.isRequired,
  mayEdit: PropTypes.bool.isRequired,
  repoList: PropTypes.array.isRequired,
  handleDeleteFn: PropTypes.func.isRequired,
}

export { ImageGridList }
