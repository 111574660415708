import React from "react"
import SvgIcon from "./SvgIcon"

const KeyArrowLeftIcon = (others) => {
  return (
    <SvgIcon  {...others}>
      <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"/>
      <path d="M0 0h24v24H0V0z" fill="none"/>
    </SvgIcon>
  )
}

export default KeyArrowLeftIcon
