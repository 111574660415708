import React from 'react'
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { themeIMM } from "../constants/appTheme"
import { ThemeProvider } from "@material-ui/styles"
import AppRouter from "./AppRouter/AppRouter"

export default function App() {
  return (
    <React.Fragment>
      <ThemeProvider theme={themeIMM}>
        <ToastContainer style={{whiteSpace: 'pre-line'}}/>
        <AppRouter/>
      </ThemeProvider>
    </React.Fragment>
  )
}



