import { AppMsgs } from "./appMsgs"
import PlanoIcon from "../components/Svg-Icons/PlanoIcon"
import EquipamentoIcon from "../components/Svg-Icons/EquipamentoIcon"
import TeamIcon from "../components/Svg-Icons/TeamIcon"
import { patsPaginateRouterProps, patViewRouterProps } from "../pages/Pats/apiPats"
import { pecasPaginateRouterProps, pecaViewRouterProps } from "../pages/Equips/Pecas/apiPecas"
import { procedimentosPaginateRouterProps, procedimentoViewRouterProps } from "../pages/Equips/Procedimentos/apiProcedimento"
import { marcasPaginateRouterProps, marcaViewRouterProps } from "../pages/Marcas/apiMarcas"
import { modelosMarcasPaginateRouterProps } from "../pages/Marcas/Modelos/apiMarcaModelos"
import { tiposEquipPaginateRouterProps } from "../pages/Equips/TiposEquip/apiTiposEquip"
import { tiposPlanoRouteProps } from "../pages/Planos/TiposPlano/apiTiposPlano"
import { instaladoresRouteProps, instaladorViewRouterProps } from "../pages/Entidades/Instaladores/apiInstaladores"
import { clientesRouteProps, clienteViewRouteProps } from "../pages/Entidades/Clientes/apiClientes"
import { tecnicosRouterProps, tecnicoViewRouterProps } from "../pages/Entidades/Tecnicos/apiTecnicos"
import { planosListRouterProps, planoViewRouterProps } from "../pages/Planos/ListPlanos/apiPlanos"
import msgsPlanos from "../pages/Planos/ListPlanos/msgsPlanos"
import { equipCliRouteProps, equipsListRouterProps, equipViewRouteProps } from "../pages/Equips/ListEquips/apiEquips"
import msgsEquips from "../pages/Equips/ListEquips/msgsEquips"
import { usersRouterProps, userViewRouterProps } from "../pages/Entidades/Users/apiUsers"
import { alllogsRouterProps, configRouterProps } from "../pages/Config/apiConfig"
import { dashboardRouterProps } from "../pages/DashBoard/apiDashboard"
import { loginRouteProps } from "../pages/Login/apiLogin"
import { homeProps } from "../pages/Home/apiHome"
import { visitaPatViewRouterProps } from "../pages/Pats/VisitasPat/apiVisitaPat"
import { avisosAlertasRouterProps } from "../pages/AvisosAlertas/apiAvisosAlertas"
import { planoAtivadoViewRouterProps, planosAtivadosListRouterProps } from "../pages/Planos/ListPlanosAtivados/apiPlanosAtivados"
import { armazem_IN_RouterProps, armazem_OUT_RouterProps } from "../pages/Armazem/apiArmazem"

const layoutRouteProps = homeProps
const loginRoute = loginRouteProps

// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

const mainRoutes = {
  CLIENTES: clientesRouteProps,
  CLIENTE_VIEW: clienteViewRouteProps,
  INSTALADORES: instaladoresRouteProps,
  INSTALADOR_VIEW: instaladorViewRouterProps,
  TECNICOS: tecnicosRouterProps,
  TECNICO_VIEW: tecnicoViewRouterProps,
  USERS: usersRouterProps,
  USER_VIEW: userViewRouterProps,
  DASHBOARD: dashboardRouterProps,
  CONFIG: configRouterProps,
  ALL_LOGS: alllogsRouterProps,
  MARCAS: marcasPaginateRouterProps,
  MARCA_VIEW: marcaViewRouterProps,
  MARCA_MODELOS: modelosMarcasPaginateRouterProps,
  TIPOS_PLANO: tiposPlanoRouteProps,
  PLANOS_LIST: planosListRouterProps,
  PLANO_VIEW: planoViewRouterProps,
  PLANOS_ATIVADOS_LIST: planosAtivadosListRouterProps,
  PLANOS_ATIVADOS_VIEW: planoAtivadoViewRouterProps,
  TIPOS_EQUIP: tiposEquipPaginateRouterProps,
  EQUIPS_LIST: equipsListRouterProps,
  EQUIP_VIEW: equipViewRouteProps,
  PECAS: pecasPaginateRouterProps,
  PECA_VIEW: pecaViewRouterProps,
  PROCEDIMENTOS: procedimentosPaginateRouterProps,
  PROCEDIMENTO_VIEW: procedimentoViewRouterProps,
  PATS: patsPaginateRouterProps,
  PAT_VIEW: patViewRouterProps,
  EQUIP_CLI: equipCliRouteProps,
  VISITA_PAT_VIEW: visitaPatViewRouterProps,
  AVISOS_ALERTAS: avisosAlertasRouterProps,
  ARMAZEM_IN: armazem_IN_RouterProps,
  ARMAZEM_OUT: armazem_OUT_RouterProps
}

const leftMenu = [
  dashboardRouterProps,
  configRouterProps,
  // {
  //   name: armazemMsgs.LABEL,
  //   Icon: ArmazemIcon,
  //   reqRole: 'R',
  //   items: [
  //     armazem_IN_RouterProps,
  //     armazem_OUT_RouterProps
  //   ]
  // },
  marcasPaginateRouterProps,
  {
    name: AppMsgs.ENTIDADES.LABEL,
    Icon: TeamIcon,
    reqRole: 'R',
    items: [
      usersRouterProps,
      instaladoresRouteProps,
      clientesRouteProps,
      tecnicosRouterProps
    ]
  },
  {
    name: msgsPlanos.MENU_LABEL,
    Icon: PlanoIcon,
    reqRole: 'R',
    items: [
      tiposPlanoRouteProps,
      planosListRouterProps,
      planosAtivadosListRouterProps,
    ]
  },
  {
    name: msgsEquips.LABEL,
    Icon: EquipamentoIcon,
    reqRole: 'R',
    items: [
      pecasPaginateRouterProps,
      procedimentosPaginateRouterProps,
      tiposEquipPaginateRouterProps,
      equipsListRouterProps
    ]
  },
  patsPaginateRouterProps
]

export { leftMenu, layoutRouteProps, loginRoute, mainRoutes }
