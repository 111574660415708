import { StyledTableCell, TableMarginTop } from "../../../SharedStyles/SharedStyles"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import React from "react"
import CircleIcon from "../../../Svg-Icons/CircleIcon"
import { errorColor, successColor } from "../../../../constants/appTheme"
import { ShowDate, ShowDateTime } from "../Show/ShowDateTime"

const planoActivomsgs = {
  TABLE: {
    LABEL: {
      INFO: "Plano Ativado",
      ATIVADO: "Data de Ativação",
      DATA_INIC: "Data Início",
      DATA_FIM: "Data Fim",
      ALL_ACOES_CONC: "Todas as Ações Concluídas"
    }
  }
}

function TablePlanoActivo({refAN, ativado_at, dataInic, dataFim, allAcConcluidas}) {
  const classes = TableMarginTop()
  return (
    <TableContainer>
      <Table className={classes.table} size="small" aria-label="TablePA">
        <TableHead>
          <TableRow>
            <StyledTableCell colSpan="2">{planoActivomsgs.TABLE.LABEL.INFO}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key="ativado_at">
            <TableCell>{planoActivomsgs.TABLE.LABEL.ATIVADO}</TableCell>
            <TableCell align="right"><ShowDateTime dateTime={ativado_at}/></TableCell>
          </TableRow>
          <TableRow key="dataInic">
            <TableCell>{planoActivomsgs.TABLE.LABEL.DATA_INIC}</TableCell>
            <TableCell align="right"><ShowDate date={dataInic}/></TableCell>
          </TableRow>
          <TableRow key="dataFim">
            <TableCell>{planoActivomsgs.TABLE.LABEL.DATA_FIM}</TableCell>
            <TableCell align="right"><ShowDate date={dataFim}/></TableCell>
          </TableRow>
          <TableRow key="allAcConcluidas">
            <TableCell>{planoActivomsgs.TABLE.LABEL.ALL_ACOES_CONC}</TableCell>
            <TableCell align="right">{!!allAcConcluidas ?
              <CircleIcon color={successColor}/> :
              <CircleIcon color={errorColor}/>}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TablePlanoActivo
