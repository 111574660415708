import { createMuiTheme } from '@material-ui/core/styles'
import makeStyles from "@material-ui/core/styles/makeStyles"
import { createStyles, fade } from "@material-ui/core"

const themeIMM = createMuiTheme({
  "palette": {
    "common": {"black": "#212529", "white": "#fff"},
    "background": {"paper": "#fff", "default": "#fafafa"},
    "primary": {
      "light": "#ffe082",
      "main": "#ffb300",
      "dark": "#ff6f00",
      "contrastText": "#212529"
    },
    "secondary": {
      "light": "#f79633",
      "main": "#f57c00",
      "dark": "#ab5600",
      "contrastText": "#ffffff"
    },
    "error": {"light": "#e57373", "main": "#f44336", "dark": "#d32f2f", "contrastText": "#fff"},
    "sucess": {"light": "#6fbf73", "main": "#00C851", "dark": "#007E33"},
    "text": {
      "primary": "#212529",
      "secondary": "rgba(0, 0, 0, 0.6)",
      "disabled": "rgba(0, 0, 0, 0.4)",
      "hint": "rgba(0, 0, 0, 0.3)"
    }
  }
})

const drawerWidth = 240

const primaryColor = themeIMM.palette.primary.main
const primaryColorLight = themeIMM.palette.primary.light
const primaryColorDark = themeIMM.palette.primary.dark
const secondaryColor = themeIMM.palette.secondary.main
const secondaryColorLight = themeIMM.palette.secondary.light
const secondaryColorDark = themeIMM.palette.secondary.dark
const successColor = themeIMM.palette.success.main
const successColorLight = themeIMM.palette.success.light
const whiteColor = themeIMM.palette.common.white
const errorColor = themeIMM.palette.error.main
const errorColorLight = themeIMM.palette.error.light
const errorColorDark = themeIMM.palette.error.dark
const errorColorContrast = themeIMM.palette.error.contrastText
const textPrimary = themeIMM.palette.text.primary
const textSecondary = themeIMM.palette.text.secondary
const textDisabled = themeIMM.palette.text.disabled
const textHint = themeIMM.palette.text.hint
const immBgUltraLight = "rgba(250, 150, 50, 0.1)"
const immBgLight = "rgba(250, 150, 50, 0.4)"
const immBgDark = "rgba(250, 150, 50, 0.75)"

const tableStyles = makeStyles((theme) =>
  createStyles({
    table: {
      marginTop: theme.spacing(1)
    },
    fadedTableBody: {
      background: fade(primaryColorLight, 0.5),
    },
    comRelatorioTableBody: {
      background: fade(successColorLight, 0.1),
    },
    obsTableCell: {
      padding: '5px'
    },
    rootBox: {
      padding: 0,
      margin: 0
    },
    obsBox: {
      fontSize: '85%',
    }
  })
)

const avatarStyles = makeStyles((theme) => ({
  img: {
    objectFit: 'contain',
  }
}))

export {
  avatarStyles,
  tableStyles,
  immBgUltraLight,
  immBgLight,
  immBgDark,
  themeIMM,
  drawerWidth,
  primaryColorDark,
  primaryColorLight,
  primaryColor,
  secondaryColorDark,
  secondaryColorLight,
  secondaryColor,
  successColor,
  successColorLight,
  errorColor,
  errorColorLight,
  errorColorDark,
  errorColorContrast,
  textPrimary,
  textSecondary,
  textDisabled,
  textHint,
  whiteColor
}
