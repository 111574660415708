import React, { useContext } from "react"
import Title from "../../../components/SharedComponents/Shared/Title"
import PecaPatForm from "./PecaPatForm"
import { PecasPatState } from "./PecasPatGest"
import pecasPatMsgs from "./msgs"

function PecaPatEdit() {

  const {showState, showList} = useContext(PecasPatState)
  const handleBack = () => showList()

  const backMsg = (showState.createMode)
    ? pecasPatMsgs.BACK_LABEL_CREATE
    : pecasPatMsgs.BACK_LABEL_UPDATE

  return (
    <React.Fragment>
      <Title handleBack={handleBack}>{backMsg}</Title>
      <PecaPatForm/>
    </React.Fragment>
  )
}

export default PecaPatEdit
