import { api, apiCUD, apiReadTable, getRequest } from "../../../api/api"
import marcaModelosMsgs from "./msgs"
import ModeloList from "./ModeloList"

const marcasURLname = 'marcas'
const marcasSelectURLname = 'select/marcas'

// --------------  MODELOS_MARCAS   --------------
const modelosMarcasPaginateRouterProps = {
  // MARCA_MODELOS: "/marcas/:marcaID/modelos/:modeloID",
  path: getRequest({URLname: marcasURLname}).paginatePath(`/:marcaID/modelos/:modeloID`),
  name: marcaModelosMsgs.EDIT_LABEL,
  component: ModeloList,
  breadcrumb: (props) => (!!parseInt(props.match.params.marcaID, 10)
    && !!parseInt(props.match.params.modeloID, 10))
    ? marcaModelosMsgs.EDIT_LABEL
    : marcaModelosMsgs.NEW_LABEL,
  reqRole: 'R'
}

// --------------  MODELOS_MARCAS   --------------

/**
 * Modelos de uma dada Marca (id) com paginação para tabela
 * @param query
 * @param id
 * @returns {Promise<*|void>}
 */
async function readMarcaModelosTable([query], id) {
  const request = getRequest({URLname: marcasURLname, id, query}).GET(`modelos`)
  return apiReadTable(request)
}

/**
 * Todos os Modelos de uma dada Marca (id) e tipo de equip --> para o Select
 * @returns {Promise<AxiosResponse<any>>}
 */
async function readModelosOfMarcaTipoEquipSelect({marcaID, teID}) {
  //select/marcas/{marcaID}/modelos/ofte/{teID}
  const request = getRequest({URLname: marcasSelectURLname, id: marcaID}).GET(`modelos/ofte/${teID}`)
  return api(request).then(r => {
    return r.data.data.map((modelo) => {
      return {index: modelo.id, title: modelo.modelo}
    })
  })
}

async function readProcedimentosSelect() {
  const request = getRequest({URLname: 'procedimentos', query: {pageSize: 10000, pageIndex: 0}}).ALL()
  return api(request).then(r => r.data.data.map((procedimento) => {
    return {index: procedimento.id, title: `${procedimento.desc} (${procedimento.tipo})`}
  }))
}

function createMarcaModelo([data]) {
  const request = getRequest({URLname: marcasURLname}).POST(`${data.marca_id}/modelos`)
  return apiCUD(request, data)
}

function updateMarcaModelo([data]) {
  const request = getRequest({URLname: marcasURLname, id: data.marca_id}).PUT(`modelos/${data.id}`)
  return apiCUD(request, data)
}

function deleteMarcaModelo(id, marcaID) {
  const request = getRequest({URLname: marcasURLname, id: marcaID}).DELETE(`modelos/${id}`)
  return apiCUD(request)
}

export {
  modelosMarcasPaginateRouterProps,
  readMarcaModelosTable,
  readModelosOfMarcaTipoEquipSelect, readProcedimentosSelect,
  createMarcaModelo,
  updateMarcaModelo, deleteMarcaModelo
}
