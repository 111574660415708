import React from "react"
import { useUser } from "../../context/user-context"
import DateTimeCalendarIcon from "../../components/Svg-Icons/DateTimeCalendarIcon"
import { ShowDateTime } from "../../components/SharedComponents/Shared/Show/ShowDateTime"
import avisosAlertasMsgs from "./avisosAlertasMsgs"
import IconButton from "@material-ui/core/IconButton"
import { primaryColorDark, secondaryColorLight, successColor, textHint } from "../../constants/appTheme"
import { Link as RouterLink } from "react-router-dom"
import { getRequest } from "../../api/api"
import { equipUrl } from "../Equips/ListEquips/apiEquips"
import EquipamentoIcon from "../../components/Svg-Icons/EquipamentoIcon"
import PatIcon from "../../components/Svg-Icons/PatIcon"
import Box from "@material-ui/core/Box"
import leftMenubarStyles from "../../components/MainLayout/StructureLayout/LeftMenubar/LeftMenubarStyles"
import ReadCheckIcon from "../../components/Svg-Icons/ReadCheckIcon"
import { patsURLname } from "../Pats/apiPats"
import TabsComponent from "../../components/SharedComponents/Shared/TabsComponent"
import { readAvisosPeriodoTable, updateAvisosPeriodoAsDesligado, updateAvisosPeriodoAsLido } from "./apiAvisosAlertas"
import CheckIcon from "../../components/Svg-Icons/CheckIcon"
import LFTable from "../../components/LFTable/LFTable"
import PlanoAtivadoIcon from "../../components/Svg-Icons/PlanoAtivadoIcon"
import { planosAtivadosUrl } from "../Planos/ListPlanosAtivados/apiPlanosAtivados"

const columnsAvisosPeriodo = [{
  Header: () => null,
  id: 'columnsAvisosPeriodo',
  columns: [
    {
      Header: <ReadCheckIcon color={successColor}/>,
      accessor: "datalido",
      Cell: ({row}) => {
        const classes = leftMenubarStyles()
        const isLido = !!row.original.lido
        return (
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" p={1} m={1} className={classes.noMarginBox}
               width={'80px'}>
            {isLido
              ? (
                <Box p={1} className={classes.noMarginBox}>
                  <ShowDateTime dateTime={row.original.lido_at} offLimit={false}/>
                </Box>)
              : (
                <Box p={1} className={classes.noMarginBox}>
                  <ReadCheckIcon color={textHint} style={{width: '14px', height: '14px',}}/>
                </Box>)}
          </Box>
        )
      }
    },
    {
      Header: <DateTimeCalendarIcon/>,
      accessor: "dataLimite",
      Cell: ({row}) => {
        const classes = leftMenubarStyles()
        return (
          <Box display="flex" alignItems="center" flexDirection="row" p={1} m={1} className={classes.noMarginBox} width={'80px'}>
            <Box p={1} className={classes.noMarginBox}>
              <ShowDateTime dateTime={row.original.dataLimite}/>
            </Box>
          </Box>
        )
      }
    },
    {
      Header: avisosAlertasMsgs.MSG,
      accessor: "text",
      Cell: ({row}) => {
        return (<span style={{fontSize: '85%', color: '#444'}}>{row.original.text}</span>)
      }
    },
    {
      Header: avisosAlertasMsgs.RELATED,
      accessor: "related",
      Cell: ({row}) => {
        const classes = leftMenubarStyles()
        const hasEquip = !!row.original.equipcli_id
        const hasPlanoAtiv = !!row.original.plano_activ_id
        const hasPat = !!row.original.pat_id
        return (
          <Box display="flex" alignItems="center" flexDirection="row" p={1} m={1} className={classes.noMarginBox}>
            {hasEquip && <Box p={1} className={classes.noMarginBox}>
              <IconButton
                component={RouterLink}
                to={getRequest({URLname: equipUrl, id: row.original.equipcli_id}).gotoItemPath()}
                disabled={!hasEquip}>
                <EquipamentoIcon color={primaryColorDark}/>
              </IconButton>
            </Box>}
            {hasPlanoAtiv && <Box p={1} className={classes.noMarginBox}>
              <IconButton
                component={RouterLink}
                to={getRequest({URLname: planosAtivadosUrl, id: row.original.plano_activ_id}).gotoItemPath()}
                disabled={!hasPlanoAtiv}>
                <PlanoAtivadoIcon color={primaryColorDark}/>
              </IconButton>
            </Box>}
            {hasPat && <Box p={1} className={classes.noMarginBox}>
              <IconButton
                component={RouterLink}
                to={getRequest({URLname: patsURLname, id: row.original.pat_id}).gotoItemPath()}
                disabled={!hasPat}>
                <PatIcon color={primaryColorDark}/>
              </IconButton>
            </Box>}
          </Box>
        )
      }
    },
  ]
}]

const AvisosPeriodo = () => {
  return (
    <LFTable
      columns={columnsAvisosPeriodo}
      requestFn={readAvisosPeriodoTable}
      deleteFn={updateAvisosPeriodoAsDesligado}
      showEditButton={true}
      EditIconComponent={<CheckIcon color={secondaryColorLight} style={{width: '16px', height: '18px',}}/>}
      isEditableFlagRowFieldName={'lido'}
      reverseEditableFlag={true}
      reloadOnEdit={true}
      showDeleteButton={true}
      handlerUpdate={updateAvisosPeriodoAsLido}
    />
  )
}

const AvisosAlertas = () => {
  const {u} = useUser()
  let index = 0
  const tabs = []

  if (u.mayActAs.admin) tabs.push(
    {
      label: avisosAlertasMsgs.AVISOS_PA,
      index: index++,
      component: <AvisosPeriodo/>
    }
  )
  // Apenas para o e-sagoGC (Sist. Apoio à Gestão Operacional de Gabinetes de Contabilidade)
  // tabs.push(
  //   {
  //     label: avisosAlertasMsgs.ALERTAS_PESS,
  //     index: index++,
  //     component: <AlertasDoUser userID={user.id}/>
  //   }
  // )

  return (<TabsComponent renderTabs={tabs}/>)
}

export { AvisosAlertas }
