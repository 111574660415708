import React, { useState } from "react"
import { updateLocaisTec } from "./apiLocaisTec"
import Grid from "@material-ui/core/Grid"
import Title from "../../../../components/SharedComponents/Shared/Title"
import msgsLocaisTec from "./msgsLocaisTec"
import FormControl from "@material-ui/core/FormControl"
import FormLabel from "@material-ui/core/FormLabel"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"
import Typography from "@material-ui/core/Typography"
import { textSecondary } from "../../../../constants/appTheme"
import LFListItem from "../../../../components/SharedComponents/Shared/LFListItem"
import makeStyles from "@material-ui/core/styles/makeStyles"

const useStyles = makeStyles(theme => ({
  grid: {
    margin: '10px',
  },
  textColor: {
    color: textSecondary,
  },
}))

function LocaisTecUpdate({idTec, locais, setState}) {
  const classes = useStyles()
  const [locData, setLocData] = useState(locais)

  const [ddValue, setDDValue] = useState('')
  const [ccValue, setCCValue] = useState('')

  const [selectCp4, setSelectCp4] = useState(null)

  const [ccObj, setCCObj] = useState(null)
  const [cp4Obj, setCp4Obj] = useState(null)

  const handleChange = (e) => {
    setDDValue(e.target.value)
    setCCObj(locData[e.target.value]['concelhos'])
    setCCValue('')
  }

  const handleChangeCC = (e) => {
    setCCValue(e.target.value)
    setCp4Obj(ccObj[e.target.value]['cp4s'])
  }

  const handleToggleCP4 = (cp4) => {
    setSelectCp4(cp4)
    updateLocaisTec({cp4: cp4.cp4, onoff: !cp4.checked, idTec: idTec})
      .then(r => {
        setLocData((prevState) => {
          let newObj = {...prevState}
          newObj[ddValue]['concelhos'][ccValue]['cp4s'][cp4.cp4] = {...cp4, checked: !cp4.checked}
          return newObj
        })
        setSelectCp4(null)
      })
      .catch(r => setSelectCp4(null))
  }

  return (
    <React.Fragment>
      <Grid container spacing={2} justify="center" className={classes.grid}>
        <Title handleBack={() => setState('L')}>{msgsLocaisTec.BACK_LABEL}</Title>
        <Grid item sm={4}>
          <FormControl component="fieldset">
            <FormLabel component="legend">{msgsLocaisTec.DISTRITOS}</FormLabel>
            <RadioGroup aria-label="distritos" name="distritos" value={ddValue} onChange={handleChange}>
              {Object.keys(locData).map((item) => {
                return <FormControlLabel key={locData[item].dd_id} value={`${locData[item].dd_id}`}
                                         control={<Radio/>}
                                         label={locData[item].dd}/>
              })}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item sm={4}>
          {ccObj &&
          <FormControl component="fieldset">
            <FormLabel component="legend">{msgsLocaisTec.CONSELHOS}</FormLabel>
            <RadioGroup aria-label="conselhos" name="conselhos" value={ccValue} onChange={handleChangeCC}>
              {Object.keys(ccObj).map((item) => {
                return <FormControlLabel key={ccObj[item].cc_id} value={`${ccObj[item].cc_id}`} control={<Radio/>}
                                         label={ccObj[item].cc}/>
              })}
            </RadioGroup>
          </FormControl>
          }
        </Grid>
        <Grid item sm={4}>
          {cp4Obj &&
          <div>
            <Typography variant="body1" style={{color: textSecondary}}>{msgsLocaisTec.CP4S}</Typography>

            {Object.keys(cp4Obj).map((item) => {
              return (
                <LFListItem key={cp4Obj[item].cp4}
                            item={{
                              id: parseInt(cp4Obj[item].cp4, 10),
                              text: `${cp4Obj[item].cp4} - ${cp4Obj[item].cpalf}`,
                              checked: cp4Obj[item].checked
                            }}
                            handleToggle={() => handleToggleCP4(cp4Obj[item])}
                            disabled={!!selectCp4}
                            loading={cp4Obj[item].cp4 === (selectCp4 && selectCp4.cp4)}
                />
              )
            })}
          </div>
          }
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default LocaisTecUpdate
