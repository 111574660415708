import React from "react"
import { ListRepoGest } from "../../../../components/SharedComponents/ListRepo/ListRepoGest"
import { createOBSVisitaPat, deleteOBSVisitaPat, readOBSsVisitaPat, visitasPatURL } from "../apiVisitaPat"
import { baseURLPDF } from "../../../../api/api"
import { OBSGridList } from "./ListOBS/OBSGridList"
import { OBSEdit } from "./ListOBS/OBSEdit"
import { obsMsgs } from "./ListOBS/obsMsgs"

function ObsVisitaPat({visita, reload}) {
  const getRepoHrefFn = (visitaID, obsID) => (`${baseURLPDF}/gpm/${visitasPatURL}/${visitaID}/obs/${obsID}`)

  return (
    <ListRepoGest
      repo={visita}
      repoKey={'obs_id'}
      repoItems={visita.obs ? visita.obs.data : []}
      FKfieldname={'visita_id'}
      createRepoFn={createOBSVisitaPat}
      deleteRepoFn={deleteOBSVisitaPat}
      readReposFn={readOBSsVisitaPat}
      ListComponent={OBSGridList}
      getRepoHrefFn={getRepoHrefFn}
      EditComponent={OBSEdit}
      mayEdit={visita.mayEdit}
      btAddTitle={obsMsgs.NEW_LABEL}
      reload={reload}
    />
  )
}

export { ObsVisitaPat }
