import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import MainFooter from "../../components/MainLayout/StructureLayout/MainFooter/MainFooter"
import LockIcon from "../../components/Svg-Icons/LockIcon"
import { useAuth } from "../../context/auth-context"
import styled from "@material-ui/core/styles/styled"
import { secondaryColor } from "../../constants/appTheme"
import LoginForm from "./LoginForm"
import msgsLogin from "./msgsLogin"


const Paper = styled('div')(({theme}) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))
const AvatarTyle = styled(Avatar)(({theme}) => ({
  margin: theme.spacing(1),
  backgroundColor: secondaryColor,
}))


export default function Login() {
  const {login} = useAuth()

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline/>
      <Paper>
        <AvatarTyle>
          <LockIcon/>
        </AvatarTyle>
        <Typography component="h1" variant="h5">
          {msgsLogin.SIGN_IN_TIT}
        </Typography>
        <LoginForm onSubmit={login}/>
      </Paper>
      <MainFooter/>
    </Container>
  )
}
