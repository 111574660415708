import React from "react"
import { useAuth } from "./auth-context"

const UserContext = React.createContext()

const defaultUserInfo = {
  is: {
    root: false,
    admin: false,
    gest: false,
    tec: false,
    inst: false,
    cliFinal: false,
  },
  mayActAs: {
    root: false,
    admin: false,
    gest: false,
    tec: false,
    inst: false,
    cliFinal: false,
  }
}

function UserProvider(props) {
  const {data: user} = useAuth()

  const isUser = !!(user && user.roleCheck)

  const u = {}
  u['is'] = {
    root: isUser && user.roleCheck.isRoot && user.id === 1,
    admin: isUser && user.roleCheck.isAdmin,
    gest: isUser && user.roleCheck.isGest,
    tec: isUser && user.roleCheck.isTec,
    inst: isUser && user.roleCheck.isInst,
    cliFinal: isUser && user.roleCheck.isCliFinal,
  }
  u['mayActAs'] = {
    root: u.is.root,
    admin: u.is.root || u.is.admin,
    gest: u.is.root || u.is.admin || u.is.gest,
    tec: u.is.root || u.is.admin || u.is.gest || u.is.tec,
    inst: u.is.root || u.is.admin || u.is.inst,
    cliFinal: u.is.root || u.is.admin || u.is.cliFinal,
  }

  return <UserContext.Provider value={{user, u}} {...props} />
}


function useUser() {
  const context = React.useContext(UserContext)
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`)
  }
  return context
}

export { UserProvider, useUser, defaultUserInfo }

