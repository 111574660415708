import React from "react"
import SvgIcon from "./SvgIcon"

const MarcaIcon = (others) => {
  return (
    <SvgIcon viewBox="0 0 512 512"  {...others}>
      <g>
        <g>
          <path d="M407,0H105C47.103,0,0,47.103,0,105v302c0,57.897,47.103,105,105,105h302c57.897,0,105-47.103,105-105V105
			C512,47.103,464.897,0,407,0z M482,407c0,41.355-33.645,75-75,75H105c-41.355,0-75-33.645-75-75V105c0-41.355,33.645-75,75-75h302
			c41.355,0,75,33.645,75,75V407z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M305.646,123.531c-1.729-6.45-5.865-11.842-11.648-15.18c-11.936-6.892-27.256-2.789-34.15,9.151L256,124.166
			l-3.848-6.665c-6.893-11.937-22.212-16.042-34.15-9.151h-0.001c-11.938,6.893-16.042,22.212-9.15,34.151l18.281,31.664
			L159.678,291H110.5c-13.785,0-25,11.215-25,25c0,13.785,11.215,25,25,25h189.86l-28.868-50h-54.079l85.735-148.498
			C306.487,136.719,307.375,129.981,305.646,123.531z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M401.5,291h-49.178l-55.907-96.834l-28.867,50l86.804,150.348c3.339,5.784,8.729,9.921,15.181,11.65
			c2.154,0.577,4.339,0.863,6.511,0.863c4.332,0,8.608-1.136,12.461-3.361c11.938-6.893,16.042-22.213,9.149-34.15L381.189,341
			H401.5c13.785,0,25-11.215,25-25C426.5,302.215,415.285,291,401.5,291z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M119.264,361l-4.917,8.516c-6.892,11.938-2.787,27.258,9.151,34.15c3.927,2.267,8.219,3.345,12.458,3.344
			c8.646,0,17.067-4.484,21.693-12.495L176.999,361H119.264z"/>
        </g>
      </g>
    </SvgIcon>
  )
}

export default MarcaIcon
