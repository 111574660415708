import React from "react"
import { ListRepoGest } from "../../../../components/SharedComponents/ListRepo/ListRepoGest"
import { PDFGridList } from "../../../../components/SharedComponents/ListRepo/ListPDF/PDFGridList"
import { PDFEdit } from "../../../../components/SharedComponents/ListRepo/ListPDF/PDFEdit"
import { pdfMsgs } from "../../../../components/SharedComponents/ListRepo/ListPDF/pdfMsgs"
import { createPDFVisitaPat, deletePDFVisitaPat, readPDFsVisitaPat, visitasPatURL } from "../apiVisitaPat"
import { baseURLPDF } from "../../../../api/api"

function PDFsVisitaPat({visita, reload}) {
  const getRepoHrefFn = (visitaID, pdfID) => (`${baseURLPDF}/gpm/${visitasPatURL}/${visitaID}/pdfs/${pdfID}`)

  return (
    <ListRepoGest
      repo={visita}
      repoKey={'pdf_id'}
      repoItems={visita.pdfs ? visita.pdfs.data : []}
      FKfieldname={'visita_id'}
      createRepoFn={createPDFVisitaPat}
      deleteRepoFn={deletePDFVisitaPat}
      readReposFn={readPDFsVisitaPat}
      ListComponent={PDFGridList}
      getRepoHrefFn={getRepoHrefFn}
      EditComponent={PDFEdit}
      mayEdit={visita.mayEdit}
      btAddTitle={pdfMsgs.NEW_LABEL}
      reload={reload}
    />
  )
}

export { PDFsVisitaPat }
