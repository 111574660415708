import { api, apiCUD, apiReadTable, getRequest } from "../../../api/api"
import TiposEquip from "./TiposEquip"
import tiposequipMsgs from "./msgs"

const tiposequipURLname = 'tipos-equip'
const tiposequipSelectURLname = 'select/tiposequip'

// -------------- TIPOS_EQUIPAMENTOS --------------
const tiposEquipPaginateRouterProps = {
  path: getRequest({URLname: tiposequipURLname}).paginatePath(),
  exact: true,
  name: tiposequipMsgs.LABEL,
  component: TiposEquip,
  IconText: 'TE',
  breadcrumb: () => tiposequipMsgs.LABEL,
  reqRole: 'R'
}

// -------------- TIPOS_EQUIPAMENTOS --------------

/**
 * Read Tipos de Equipamento com paginação para tabela
 * @param query
 * @returns {Promise<*|void>}
 */
async function readTiposEquipTable([query]) {
  const request = getRequest({URLname: tiposequipURLname, query}).ALL()
  return apiReadTable(request)
}

/**
 * Read Tipos de Equipamento para o Select
 * @returns {Promise<AxiosResponse<any>>}
 */
async function readTiposEquipSelect() {
  const request = getRequest({URLname: tiposequipSelectURLname}).ALL()
  return api(request).then(r => r.data.data.map((tipo) => {
    return {index: tipo.id, title: tipo.refAN + ` - ` + tipo.tipo}
  }))
}

async function createTipoEquip([newData]) {
  const request = getRequest({URLname: tiposequipURLname}).POST()
  return apiCUD(request, newData).then(r => r.data)
}

async function updateTipoEquip([newData]) {
  const request = getRequest({URLname: tiposequipURLname, id: newData.id}).PUT()
  return apiCUD(request, newData).then(r => r.data)
}

async function deleteTipoEquip(id) {
  const request = getRequest({URLname: tiposequipURLname, id}).DELETE()
  return apiCUD(request)
}

export {
  tiposequipSelectURLname,
  tiposequipURLname,
  tiposEquipPaginateRouterProps,
  readTiposEquipTable,
  readTiposEquipSelect,
  createTipoEquip, updateTipoEquip, deleteTipoEquip
}
