import React from "react"
import { Redirect, useHistory, useParams } from "react-router"
import { AppMsgs } from "../../../constants/appMsgs"
import TabsComponent from "../../../components/SharedComponents/Shared/TabsComponent"
import Title from "../../../components/SharedComponents/Shared/Title"
import Async from "react-async"
import { readEquip } from "./apiEquips"
import LinearProgress from "@material-ui/core/LinearProgress"
import ErrorMsg from "../../../components/SharedComponents/Shared/Error/ErrorMsg"
import PlanosAplicaveis from "../../PlanosAplicaveis/PlanosAplicaveis"
import EquipUpdate from "./EquipUpdate"
import { patsURLname } from "../../Pats/apiPats"
import PlanoAtivadoTab from "../../PlanoAtivo/PlanoAtivadoTab"
import PatsEquip from "../../Pats/X_PatsPorEntidades/PatsEquip"
import msgsEquips from "./msgsEquips"
import { ListPlanosAtivados } from "../../Planos/ListPlanosAtivados/ListPlanosAtivados"


function equipTabsGen({
                        hasPlanoActivEmCurso, planos_activ_count,
                        has_pat_pmcesp, pat_pmcesp_id,
                        has_pat_pa, pat_pa_id,
                        pats_count
                      }) {
  return [
    {
      title: msgsEquips.TABS.PLANO_APLI,
      showTab: !hasPlanoActivEmCurso,
      component: ({equip, setEquip}) => <PlanosAplicaveis equip={equip} setEquip={setEquip}/>
    },
    {
      title: msgsEquips.TABS.PLANO_ATIV,
      showTab: !!hasPlanoActivEmCurso,
      component: ({equip, setEquip}) => <PlanoAtivadoTab planoAtivID={equip.plano_em_curso_id} setEquip={setEquip}
                                                         equip={equip}/>
    },
    {
      title: msgsEquips.TABS.HIST_PLANO_ATIV,
      showTab: !!planos_activ_count,
      // component: ({equip}) => <HistoricoAtivados equipID={equip.id}/>,
      component: ({equip}) => <ListPlanosAtivados equipID={equip.id}/>,
      hasMany: !!planos_activ_count,
      qtd: planos_activ_count
    },
    {
      title: msgsEquips.TABS.PMCE,
      showTab: !!has_pat_pmcesp,
      component: () => <Redirect push to={`/${patsURLname}/${pat_pmcesp_id}`}/>
    },
    {
      title: msgsEquips.TABS.PA,
      showTab: !!has_pat_pa,
      component: () => <Redirect push to={`/${patsURLname}/${pat_pa_id}`}/>
    },
    {
      title: msgsEquips.TABS.PAT,
      showTab: !!pats_count,
      component: ({equip}) => <PatsEquip equip={equip}/>,
      hasMany: !!pats_count,
      qtd: pats_count
    }
  ]
}

const EquipCustomTabs = ({equip, setEquip}) => {
  const cliente = equip.cliente && equip.cliente.data
  let index = 0
  let tabs = [{
    label: msgsEquips.TABS.EQUIP,
    index: index++,
    component: <EquipUpdate equip={equip} cliente={cliente} setEquip={setEquip}/>
  }]

  equipTabsGen(equip).forEach(item => {

    if (item.showTab) {
      tabs.push({
        label: item.title,
        index: index++,
        component: item.component({equip: equip, setEquip: setEquip}),
        hasMany: item.hasMany,
        qtd: item.qtd
      })
    }
  })
  return (
    <TabsComponent renderTabs={tabs}/>
  )
}

function EquipView() {

  let {id} = useParams()

  const history = useHistory()
  /** ATENÇÃO!! não ligar o goBack a components que já tem o goBack irá criar um ciclo entre os dois**/
  const handleBack = () => history.goBack() /*history.push(getRequest({URLname:equipUrl}).paginatePath())*/

  const equipRel = "tipoequip, marca, modelo, instalador, cliente"

  return (
    <React.Fragment>
      <Title handleBack={handleBack}>{AppMsgs.DEFAULT_VALUES.BACK_LABEL}</Title>
      <Async promiseFn={readEquip} id={id} com={{com: equipRel}}>
        <Async.Pending><LinearProgress color="secondary"/></Async.Pending>
        <Async.Fulfilled>
          {(equip, {setData: setEquip}) => {
            return (
              <React.Fragment>
                <EquipCustomTabs equip={equip} setEquip={setEquip}/>
              </React.Fragment>
            )
          }}
        </Async.Fulfilled>
        <Async.Rejected>{error => <ErrorMsg>{error}</ErrorMsg>}</Async.Rejected>
      </Async>
    </React.Fragment>
  )

}

export default EquipView
