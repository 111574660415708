import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { createStyles } from "@material-ui/core"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { StyledTableCell } from "../../../SharedStyles/SharedStyles"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import { Link as RouterLink } from 'react-router-dom'
import { AppMsgs } from "../../../../constants/appMsgs"
import Typography from "@material-ui/core/Typography"
import Fab from "@material-ui/core/Fab"
import { ShowDate } from "../Show/ShowDateTime"
import CircleIcon from "../../../Svg-Icons/CircleIcon"
import { errorColor, successColor } from "../../../../constants/appTheme"
import msgsClientes from "../../../../pages/Entidades/Clientes/msgsClientes"
import { getRequest } from "../../../../api/api"
import { equipUrl } from "../../../../pages/Equips/ListEquips/apiEquips"
import msgsEquips from "../../../../pages/Equips/ListEquips/msgsEquips"
import PropTypes from "prop-types"


const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      marginTop: theme.spacing(3)
    }
  })
)

function getTipoEquip(data) {
  if (typeof data.tipo === 'string')
    return `${data.tipo}`
  else if (data.tipoequip && data.tipoequip.data && data.tipoequip.data.tipo && data.tipoequip.data.refAN) {
    return `${data.tipoequip.data.tipo} (${data.tipoequip.data.refAN})`
  }
  return '#ND'
}

function getMarca(data) {
  if (typeof data.marca === 'string')
    return `${data.marca}`
  else if (data.marca && data.marca.data && data.marca.data.marca) {
    return `${data.marca.data.marca}`
  }
  return '#ND'
}

function getModelo(data) {
  if (typeof data.modelo === 'string')
    return `${data.modelo}`
  else if (data.modelo && data.modelo.data && data.modelo.data.modelo) {
    return `${data.modelo.data.modelo}`
  }
  return '#ND'
}

function TableEquipamento({data, showLink}) {
  const classes = useStyles()
  const tipoEquip = getTipoEquip(data)
  const marca = getMarca(data)
  const modelo = getModelo(data)
  return ((!!data)
    ? (
      <React.Fragment>

        {showLink && <Fab component={RouterLink} target="_blank" size="small"
                          to={getRequest({URLname: equipUrl, id: data.id}).gotoItemPath()} color="secondary"
                          variant="extended" style={{boxShadow: 'none'}}>
          <Typography variant="body2" style={{paddingLeft: '5px'}}
                      display={'inline'}>{msgsEquips.OPEN_BUTTON}</Typography>
        </Fab>}

        <TableContainer>
          <Table className={classes.table} size="small" aria-label="TableEquipamento">

            {/* ------------------------------------------------------------------- */}
            <>
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan="3">{msgsEquips.EDIT.LABEL.TIPO_EQUIP}</StyledTableCell>
                  <StyledTableCell colSpan="2">{msgsEquips.EDIT.LABEL.MARCA}</StyledTableCell>
                  <StyledTableCell colSpan="3">{msgsEquips.EDIT.LABEL.MODELO}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan="3">{tipoEquip}</TableCell>
                  <TableCell colSpan="2">{marca}</TableCell>
                  <TableCell colSpan="3">{modelo}</TableCell>
                </TableRow>
              </TableBody>
            </>

            {/* -------------------------------------------------------------------------- */}
            <>
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan="3">{msgsEquips.EDIT.LABEL.DATA_INSTALACAO}</StyledTableCell>
                  <StyledTableCell colSpan="2">{msgsEquips.EDIT.LABEL.IS_NOVO}</StyledTableCell>
                  <StyledTableCell colSpan="3">{msgsEquips.EDIT.LABEL.DATA_FIMGARANTIA}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="nome">
                  <TableCell colSpan="3"><ShowDate date={data.dataInstalacao}/></TableCell>
                  <TableCell colSpan="2">
                    {!!data.isNovo ?
                      <CircleIcon color={successColor}/> :
                      <CircleIcon color={errorColor}/>}
                  </TableCell>
                  <TableCell colSpan="3"><ShowDate date={data.dataFimGarantia}/></TableCell>
                </TableRow>
              </TableBody>
            </>

            {/* ------------------------------------------------------------------- */}
            <>
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan="2">{msgsEquips.EDIT.LABEL.IS_MESMA_MORADA_CLI}</StyledTableCell>
                  <StyledTableCell colSpan="3">{AppMsgs.ENTIDADES.EDIT.LABEL.MORADA}</StyledTableCell>
                  <StyledTableCell colSpan="3">{msgsClientes.EDIT.LABEL.ZIP_CODE}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan="2">{!!data.isMesmaMoradaCliente ?
                    <CircleIcon color={successColor}/> :
                    <CircleIcon color={errorColor}/>}</TableCell>
                  <TableCell colSpan="3">{data.morada}</TableCell>
                  <TableCell colSpan="3">{data.cp4}-{data.cp3}</TableCell>
                </TableRow>
              </TableBody>
            </>

            {/* --------------------------------------------------------------------- */}
            <>
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan="4">{AppMsgs.ENTIDADES.EDIT.LABEL.LATITUDE}</StyledTableCell>
                  <StyledTableCell colSpan="4">{AppMsgs.ENTIDADES.EDIT.LABEL.LONGITUDE}</StyledTableCell>
                  {/*<StyledTableCell colSpan="1"> </StyledTableCell>*/}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan="4">{data.latitude}</TableCell>
                  <TableCell colSpan="4">{data.longitude}</TableCell>
                  {/*<TableCell colSpan="1">*/}
                  {/*  <a target={'_blank'} href={`https://www.google.pt/maps/dir///@${data.latitude},${data.longitude}`}><ArrowRightIcon/></a>*/}
                  {/*</TableCell>*/}
                </TableRow>
              </TableBody>
            </>
            {/* ----------------------------------------------------------------------- */}
            <>
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan="8">{AppMsgs.ENTIDADES.EDIT.LABEL.OBS}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan="7">{data.obs}</TableCell>
                </TableRow>
              </TableBody>
            </>
          </Table>
        </TableContainer>
      </React.Fragment>)
    : null)
}

TableEquipamento.defaultProps = {
  showLink: false
}

TableEquipamento.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    tipoEquip: PropTypes.object,
    tipo: PropTypes.string,
    marca: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    modelo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    dataInstalacao: PropTypes.string,
    dataFimGarantia: PropTypes.string,
    isNovo: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    isMesmaMoradaCliente: PropTypes.bool,
    morada: PropTypes.string,
    cp4: PropTypes.string,
    cp3: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    obs: PropTypes.string,
  }).isRequired,
  showLink: PropTypes.bool
}


export { TableEquipamento }
