import React from "react"
import SvgIcon from "./SvgIcon"

const MobileFotoIcon = (others) => {
  return (
    <SvgIcon viewBox="0 0 512 512" {...others}>
      <g>
        <g>
          <path d="M237.864,32h-50c-5.523,0-10,4.478-10,10s4.477,10,10,10h50c5.523,0,10-4.478,10-10S243.387,32,237.864,32z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M157.19,34.93c-1.86-1.861-4.44-2.93-7.07-2.93s-5.21,1.07-7.07,2.93s-2.93,4.44-2.93,7.07s1.07,5.21,2.93,7.069
			c1.86,1.861,4.44,2.931,7.07,2.931s5.21-1.07,7.07-2.931c1.86-1.859,2.93-4.439,2.93-7.069S159.05,36.791,157.19,34.93z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M361.992,399c-5.523,0-10,4.478-10,10v53c0,16.542-13.458,30-30,30h-256c-16.542,0-30-13.458-30-30V291
			c0-5.522-4.477-10-10-10s-10,4.478-10,10v171c0,27.57,22.43,50,50,50h256c27.57,0,50-22.43,50-50v-53
			C371.992,403.478,367.515,399,361.992,399z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M321.992,0h-256c-27.57,0-50,22.43-50,50v164.333c0,5.522,4.477,10,10,10s10-4.478,10-10V50c0-16.542,13.458-30,30-30h256
			c16.542,0,30,13.458,30,30v52.333c0,5.522,4.477,10,10,10s10-4.478,10-10V50C371.992,22.43,349.562,0,321.992,0z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M25.992,246.127c-5.523,0-10,4.478-10,10v0.311c0,5.522,4.477,10,10,10s10-4.478,10-10v-0.311
			C35.992,250.605,31.515,246.127,25.992,246.127z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M194.042,455.986h-0.101c-5.523,0-10,4.478-10,10c0,5.522,4.477,10,10,10h0.101c5.523,0,10-4.478,10-10
			C204.042,460.464,199.565,455.986,194.042,455.986z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M322.992,112.333c5.523,0,10-4.478,10-10V74c0-5.522-4.477-10-10-10h-258c-5.523,0-10,4.478-10,10v356
			c0,5.522,4.477,10,10,10h258c5.523,0,10-4.478,10-10v-21c0-5.522-4.477-10-10-10s-10,4.478-10,10v11h-238V84h238v18.333
			C312.992,107.855,317.469,112.333,322.992,112.333z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M402.78,260.046c-0.334-3.441-2.421-6.465-5.52-7.998c-3.099-1.531-6.77-1.355-9.707,0.467
			c-1.607,0.997-3.462,1.524-5.365,1.524c-5.631,0-10.213-4.581-10.213-10.212c0-1.902,0.527-3.758,1.525-5.366
			c1.822-2.936,1.999-6.607,0.466-9.705c-1.533-3.099-4.557-5.186-7.998-5.52c-1.341-0.13-2.685-0.196-3.993-0.196
			c-22.607,0-41,18.393-41,40.999c0,22.607,18.393,41,41,41c22.608,0,41-18.393,41.001-41
			C402.976,262.731,402.91,261.387,402.78,260.046z M361.975,285.039c-11.58,0-21-9.421-21-21c0-7.981,4.474-14.935,11.048-18.488
			c0.865,15.29,13.151,27.575,28.441,28.439C376.912,280.563,369.956,285.039,361.975,285.039z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M361.992,190.022c-40.804,0-74,33.196-74,74c0,40.804,33.196,74,74,74s74-33.196,74-74
			C435.992,223.218,402.796,190.022,361.992,190.022z M361.992,318.022c-29.776,0-54-24.225-54-54c0-29.775,24.224-54,54-54
			s54,24.225,54,54C415.992,293.797,391.768,318.022,361.992,318.022z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M460.323,207.151c-3.953-3.854-10.303-3.755-14.16,0.198c-3.856,3.955-3.776,10.287,0.179,14.142
			c1.944,1.896,4.463,2.84,6.98,2.84c2.601,0,5.201-1.009,7.161-3.019l0.038-0.039C464.377,217.318,464.278,211.006,460.323,207.151
			z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M456.008,172h-35.049l-10.416-26.642c-1.499-3.835-5.195-6.358-9.313-6.358h-78.475c-4.117,0-7.813,2.523-9.313,6.358
			L303.026,172h-35.049c-22.056,0-40,17.944-40,40v121c0,22.056,17.944,40,40,40h188.031c22.056,0,40-17.944,40-40V212
			C496.008,189.944,478.064,172,456.008,172z M476.008,333c0,11.028-8.972,20-20,20H267.977c-11.028,0-20-8.972-20-20V212
			c0-11.028,8.972-20,20-20h41.877c4.117,0,7.814-2.523,9.313-6.358L329.583,159h64.819l10.417,26.642
			c1.499,3.835,5.195,6.358,9.313,6.358h41.876c11.028,0,20,8.972,20,20V333z"/>
        </g>
      </g>
    </SvgIcon>
  )
}

export default MobileFotoIcon
