import { api, apiCUD, apiReadTable, getRequest } from "../../../api/api"
import { instaladoresUrl } from "../../Entidades/Instaladores/apiInstaladores"
import ListEquips from "./ListEquips"
import EquipView from "./EquipView"
import ClienteIcon from "../../../components/Svg-Icons/ClienteIcon"
import EquipCreate from "./EquipCreate"
import msgsEquips from "./msgsEquips"

const equipUrl = 'equipamentos'
const equipCliUrl = 'equipCli'

// ----------------------- EQUIPAMENTOS ROUTES -----------------------

const equipsListRouterProps = {
  path: getRequest({URLname: equipUrl}).paginatePath(),
  exact: true,
  name: msgsEquips.LIST_LABEL,
  component: ListEquips,
  IconText: 'LE',
  breadcrumb: () => msgsEquips.LIST_LABEL,
  reqRole: 'R'
}

const equipViewRouteProps = {
  path: getRequest({URLname: equipUrl}).viewPath(),
  exact: true,
  name: msgsEquips.UPDATE_LABEL,
  component: EquipView,
  Icon: ClienteIcon,
  breadcrumb: (props) => !!parseInt(props.match.params.id, 10) ? msgsEquips.UPDATE_LABEL : msgsEquips.CREATE_LABEL,
  reqRole: 'R'
}

const equipCliRouteProps = {
  path: getRequest({URLname: equipCliUrl}).viewPath(),
  exact: true,
  name: msgsEquips.CREATE_LABEL,
  component: EquipCreate,
  Icon: ClienteIcon,
  breadcrumb: () => msgsEquips.CREATE_LABEL,
  reqRole: 'R'
}

// ----------------------- EQUIPAMENTOS ROUTES -----------------------


/**
 * Read Equipamentos com paginação para tabela
 * @param query
 * @returns {Promise<*|void>}
 */
async function readEquipsTable([query]) {
  const request = getRequest({URLname: equipUrl, query: {...query, com: "marca, modelo, tipoequip"}}).GET()
  return apiReadTable(request)
}

/**
 * Read Instaladores para o Select
 * @returns {Promise<AxiosResponse<any>>}
 */
async function readInstaladoresSelect() {
  const query = {pageSize: 10000, pageIndex: 0}
  const request = getRequest({URLname: instaladoresUrl, query}).GET()
  return api(request).then(r => r.data.data.map((instador) => {
    return {index: instador.id, title: instador.nome}
  }))
}

/**
 * Read Equip
 * @param id
 * @param query
 * @returns {Promise<AxiosResponse<any>>}
 */
function readEquip({id, com: query}) {
  const request = getRequest({URLname: equipUrl, id, query: {...query}}).GET()
  return api(request).then(r => r.data.data)
}

function createEquip([data]) {
  const request = getRequest({URLname: equipUrl}).POST()
  return apiCUD(request, data).then(r => r.data)
}

function updateEquip([data]) {
  const request = getRequest({
    URLname: equipUrl, id: data.id,
    query: {com: "marca, modelo, tipoequip, instalador, cliente"}
  }).PUT()

  return apiCUD(request, data).then(r => r.data)
}

async function deleteEquip(id) {
  const request = getRequest({URLname: equipUrl, id}).DELETE()
  return apiCUD(request)
}

async function undeleteEquip(id) {
  const request = getRequest({URLname: equipUrl, id}).PUT('undelete')
  return apiCUD(request)
}

export {
  equipUrl,
  equipCliUrl,
  equipsListRouterProps,
  equipViewRouteProps,
  equipCliRouteProps,
  readEquipsTable,
  readInstaladoresSelect,
  readEquip,
  createEquip,
  updateEquip,
  deleteEquip,
  undeleteEquip
}
