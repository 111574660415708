import React, { useState } from "react"
import { Redirect } from "react-router"
import { getRequest } from "../../api/api"
import Title from "../../components/SharedComponents/Shared/Title"
import { patsURLname } from "./apiPats"
import patMsgs from "./patMsgs"
import PatForm from "./PatForm"

function PatCreate({equip, patType, onCancelEdit}) {
  const [pat, setPat] = useState(null)

  if (pat)
    return <Redirect to={getRequest({URLname: patsURLname, id: pat.pat_id}).gotoItemPath()}/>

  return (
    <React.Fragment>
      <Title handleBack={onCancelEdit}>{patMsgs.CANCEL_EDIT}</Title>
      <PatForm equipID={equip.id} patType={patType} setPat={setPat}/>
    </React.Fragment>
  )
}

export default PatCreate
