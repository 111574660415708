import React from "react"
import { FormContext, useForm } from "react-hook-form"
import * as Yup from "yup"
import { betweenNumberValidation, emailReqValidation, minNumberValidation, refANValidation } from "../../constants/appValidation"
import { AppMsgs } from "../../constants/appMsgs"
import Grid from "@material-ui/core/Grid"
import LFSubmitButton from "../../components/SharedComponents/Shared/LFButtons/LFSubmitButton"
import { LFNumberInput } from "../../components/LFInputs/LFNumberInput"
import { LFMoneyInput } from "../../components/LFInputs/LFMoneyInput"
import { useAsync } from "react-async"
import { updateConfig } from "./apiConfig"
import { Form } from "../../components/SharedStyles/SharedStyles"
import { LFSwitchInput } from "../../components/LFInputs/LFSwitchInput"
import PropTypes from "prop-types"
import FormHelperText from "@material-ui/core/FormHelperText"
import { LFEmailInput } from "../../components/LFInputs/LFEmailInput"
import msgsConfig from "./msgsConfig"


/**
 * Validação Config
 *
 */
const validationSchema = Yup.object().shape({
  gpm_txDesloc_CAT: minNumberValidation(0, msgsConfig.EDIT.LABEL.TXDESLOCCAT),
  gpm_txDesloc_IMM: minNumberValidation(0, msgsConfig.EDIT.LABEL.TXDESLOCIMM),
  gpm_txMO_CAT: minNumberValidation(0, msgsConfig.EDIT.LABEL.TXMOCAT),
  gpm_txMO_IMM: minNumberValidation(0, msgsConfig.EDIT.LABEL.TXMOIMM),
  gpm_DiasAntecedencia_Avisos: betweenNumberValidation(0, 20, msgsConfig.EDIT.LABEL.DIASANTAVISOS),
  gpm_DiasAntecedencia_PMP: betweenNumberValidation(0, 10, msgsConfig.EDIT.LABEL.DIASANTPMP),
  gpm_DiasAntecedencia_PA: betweenNumberValidation(0, 10, msgsConfig.EDIT.LABEL.DIASANTPA),
  gpm_refAN_Deslocacao: refANValidation(5, msgsConfig.EDIT.LABEL.REFANDESLOC),
  gpm_refAN_MaoObra: refANValidation(5, msgsConfig.EDIT.LABEL.REFANMO),
  gpm_refAN_MCE: refANValidation(5, msgsConfig.EDIT.LABEL.REFANMCE),
  gpm_txIVA: betweenNumberValidation(0, 25, msgsConfig.EDIT.LABEL.TXIVA),
  gpm_horizonteAvisos: betweenNumberValidation(6, 24, msgsConfig.EDIT.LABEL.HORIZONTEAVISOS),
  gpm_maxMesesAssocPlanoNoPassado: betweenNumberValidation(6, 36, msgsConfig.EDIT.LABEL.MAXMESESASSOCPLANOPASSADO),
  gpm_PGB_durCliDomest: betweenNumberValidation(3, 48, msgsConfig.EDIT.LABEL.DUR_CLI_DOM),
  gpm_PGB_durCliInstit: betweenNumberValidation(3, 24, msgsConfig.EDIT.LABEL.DUR_CLI_INST),
  gpm_mailIPDOc: emailReqValidation(msgsConfig.EDIT.LABEL.MAIL_IPDOC),
  gpm_patDefaultDurMn: betweenNumberValidation(15, 240, msgsConfig.EDIT.LABEL.PAT_DEFAULT_DUR_MN)
})

/**
 * Form Config
 * @param defaultValues
 * @param setConfig
 * @returns {*}
 * @constructor
 */
function ConfigForm({defaultValues, setConfig}) {
  const {isPending, run} = useAsync({deferFn: updateConfig})
  const methods = useForm({validationSchema, defaultValues})

  const {handleSubmit, formState, control, reset} = methods

  const onSubmit = (values) => {
    run(values)
    setConfig({...defaultValues, ...values})
    reset({...defaultValues, ...values})
  }

  return (
    <FormContext {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>

          {/*----------------------------------------- PAT_DEFAULT_DUR_MN -----------------------------------------*/}
          <Grid item xs={12} md={6} style={{display: 'flex', alignItems: 'flex-end', paddingBottom: '20px'}}>
            <LFNumberInput name='gpm_patDefaultDurMn' label={msgsConfig.EDIT.LABEL.PAT_DEFAULT_DUR_MN}/>
          </Grid>


          <Grid item xs={12} sm={6}>
            <FormHelperText>{AppMsgs.DEFAULT_VALUES.SWITCH_TITLE}</FormHelperText>
            {/*----------------------------------------- SEND_LOGIN_EMAIL -----------------------------------------*/}
            <Grid item xs={12}>
              <LFSwitchInput control={control}
                             name='app_licence_sendLoginEmails'
                             label={msgsConfig.EDIT.LABEL.SEND_EMAIL_LOGIN}/>
            </Grid>
            {/*  -------------------------------------- CONTA ACESSO ATIVA ------------------------------------------*/}
            <Grid item xs={12}>
              <LFSwitchInput control={control}
                             name='app_licence_isDemoMode'
                             label={msgsConfig.EDIT.LABEL.IS_DEMO_MODE}/>
            </Grid>
          </Grid>


          {/*----------------------------------------- SEND_LOGIN_EMAIL -----------------------------------------*/}
          <Grid item xs={12}>
            <LFEmailInput name='gpm_mailIPDOc' label={msgsConfig.EDIT.LABEL.MAIL_IPDOC}/>
          </Grid>

          {/* -------------------------------------- HORIZONTE AVISOS -----------------------------------------*/}
          <Grid item xs={12}>
            <LFNumberInput label={msgsConfig.EDIT.LABEL.HORIZONTEAVISOS} name='gpm_horizonteAvisos'/>
          </Grid>
          {/*  -------------------------------------- MAX MESES PASSADO ----------------------------------------*/}
          <Grid item xs={12}>
            <LFNumberInput label={msgsConfig.EDIT.LABEL.MAXMESESASSOCPLANOPASSADO}
                           name='gpm_maxMesesAssocPlanoNoPassado'/>
          </Grid>
          {/*------------------------------------------ TAXA IVA ---------------------------------------------*/}
          <Grid item xs={12}>
            <LFNumberInput label={msgsConfig.EDIT.LABEL.TXIVA} name='gpm_txIVA'/>
          </Grid>
          {/* ------------------------------------- DUR_CLI_DOM ---------------------------------------*/}
          <Grid item xs={12} md={6}>
            <LFNumberInput label={msgsConfig.EDIT.LABEL.DUR_CLI_DOM} name='gpm_PGB_durCliDomest'/>
          </Grid>
          {/*------------------------------------- DUR_CLI_INST ---------------------------------------*/}
          <Grid item xs={12} md={6}>
            <LFNumberInput label={msgsConfig.EDIT.LABEL.DUR_CLI_INST} name='gpm_PGB_durCliInstit'/>
          </Grid>
          {/* ------------------------------------- TAXA DESLOCAÇÃO CAT ---------------------------------------*/}
          <Grid item xs={12} md={6}>
            <LFMoneyInput label={msgsConfig.EDIT.LABEL.TXDESLOCCAT} name='gpm_txDesloc_CAT'/>
          </Grid>
          {/*------------------------------------- TAXA DESLOCAÇÃO IMM ---------------------------------------*/}
          <Grid item xs={12} md={6}>
            <LFMoneyInput label={msgsConfig.EDIT.LABEL.TXDESLOCIMM} name='gpm_txDesloc_IMM'/>
          </Grid>
          {/*--------------------------------------- TAXA MÃO OBRA CAT ---------------------------------------*/}
          <Grid item xs={12} md={6}>
            <LFMoneyInput label={msgsConfig.EDIT.LABEL.TXMOCAT} name='gpm_txMO_CAT'/>
          </Grid>
          {/*--------------------------------------- TAXA MÃO OBRA IMM ---------------------------------------*/}
          <Grid item xs={12} md={6}>
            <LFMoneyInput label={msgsConfig.EDIT.LABEL.TXMOIMM} name='gpm_txMO_IMM'/>
          </Grid>
          {/*----------------------------------------- DIAS ANT AVISOS ---------------------------------------*/}
          <Grid item xs={12} md={4}>
            <LFNumberInput label={msgsConfig.EDIT.LABEL.DIASANTAVISOS} name='gpm_DiasAntecedencia_Avisos'/>
          </Grid>
          {/*------------------------------------------- DIAS ANT PMP ----------------------------------------*/}
          <Grid item xs={12} md={4}>
            <LFNumberInput label={msgsConfig.EDIT.LABEL.DIASANTPMP} name='gpm_DiasAntecedencia_PMP'/>
          </Grid>
          {/*------------------------------------------- DIAS ANT PA -----------------------------------------*/}
          <Grid item xs={12} md={4}>
            <LFNumberInput label={msgsConfig.EDIT.LABEL.DIASANTPA} name='gpm_DiasAntecedencia_PA'/>
          </Grid>
          {/*------------------------------------------ REFAN DESLOC -----------------------------------------*/}
          <Grid item xs={12} md={4}>
            <LFNumberInput label={msgsConfig.EDIT.LABEL.REFANDESLOC} name='gpm_refAN_Deslocacao'/>
          </Grid>
          {/*---------------------------------------- REFAN MÃO OBRA -----------------------------------------*/}
          <Grid item xs={12} md={4}>
            <LFNumberInput label={msgsConfig.EDIT.LABEL.REFANMO} name='gpm_refAN_MaoObra'/>
          </Grid>
          {/*----------------------------------------- REFAN MCE -----------------------------------------*/}
          <Grid item xs={12} md={4}>
            <LFNumberInput label={msgsConfig.EDIT.LABEL.REFANMCE} name='gpm_refAN_MCE'/>
          </Grid>

        </Grid>
        <LFSubmitButton isLoading={isPending} dirty={!formState.dirty}>
          {msgsConfig.BUTTON.SUBMIT}
        </LFSubmitButton>
      </Form>
    </FormContext>
  )
}

ConfigForm.propTypes = {
  defaultValues: PropTypes.shape({
    app_licence_sendLoginEmails: PropTypes.bool.isRequired,
    gpm_txDesloc_CAT: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    gpm_txDesloc_IMM: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    gpm_txMO_CAT: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    gpm_txMO_IMM: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    gpm_DiasAntecedencia_Avisos: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    gpm_DiasAntecedencia_PMP: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    gpm_DiasAntecedencia_PA: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    gpm_refAN_Deslocacao: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    gpm_refAN_MaoObra: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    gpm_refAN_MCE: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    gpm_txIVA: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    gpm_horizonteAvisos: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    gpm_maxMesesAssocPlanoNoPassado: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    gpm_PGB_durCliDomest: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    gpm_PGB_durCliInstit: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
  }),
  setConfig: PropTypes.func.isRequired
}

export default ConfigForm
