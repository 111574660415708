import React, { useState } from "react"
import { Redirect } from "react-router"
import UserForm from "./UserForm"
import { createUser } from "./apiUsers"
import { getRequest } from "../../../api/api"
import { usersUrl } from "./userConst"

function UserCreate() {
  const [user, setUser] = useState(null)


  if (user)
    return <Redirect to={getRequest({URLname: usersUrl, id: user.id}).gotoItemPath()}/>

  return (
    <React.Fragment>
      <UserForm user={user} method={createUser} setUser={setUser}/>
    </React.Fragment>
  )
}

export default UserCreate
