import { primaryColor, primaryColorDark } from "../../../../constants/appTheme"
import { makeStyles } from "@material-ui/core"

const armazemINStyles = makeStyles(theme => ({
  info: {
    margin: '2rem 0 5rem',
    width: '100%',
    fontSize: '185%',
    padding: '2rem',
  },
  titInput: {
    color: primaryColorDark,
  },
  cellRegHead: {
    fontSize: '.9rem',
    lineHeight: '1rem',
    padding: '3rem .3rem .3rem .3rem',
    textAlign: 'center',
  },
  cellReg: {
    fontSize: '.85rem',
    lineHeight: '.9rem',
    padding: '.3rem',
  },
  onFocus: {
    color: primaryColorDark,
    fontSize: '1rem',
    padding: '.5rem',
    fontWeight: 'bold',
    "& :focus": {backgroundColor: '#f3f5f8', padding: '.3rem',}
  },
  cellRegSelect: {
    backgroundColor: '#ddd',
  },
  regBt: {
    backgroundColor: '#eee',
  },

  cellHead: {
    backgroundColor: '#555',
    color: '#FFF',
    textAlign: 'center',
  },
  cell: {
    fontSize: '.8rem',
    lineHeight: '.9rem',
    padding: '.3rem',
  },
  cellAction: {
    width: 40,
    backgroundColor: primaryColor,
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
  },
  invalidcellAction: {
    backgroundColor: primaryColorDark,
  },
  marcasWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "flex-start",
  },
  imergasLogo: {
    flexGrow: 1,
    marginBottom: '3rem',
    cursor: 'pointer', maxWidth: 300, width: '100%', margin: '0 auto'
  },
  transpGrp: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: "flex-start",
  },
  transpOpt: {
    padding: '1rem',
    minWidth: '25%'
  },
}))

export default armazemINStyles
