import React from "react"
import Grid from "@material-ui/core/Grid"
import LeftMarginSpan from "../../../SharedStyles/LeftMarginSpan"
import StyledLabel from "../../../SharedStyles/StyledLabel"
import clienteMsgs from "../../../../pages/Entidades/Clientes/msgs"
import ShowJson from "./ShowJson"

function ShowCliente({cli}) {
  return (
    <Grid container spacing={2} justify="center">

      <Grid item xs={12} sm={6}>
        <div>
          <StyledLabel>{clienteMsgs.LABELS.NOME}</StyledLabel>
          <LeftMarginSpan>{cli.nome}</LeftMarginSpan>
        </div>
      </Grid>

      <Grid item xs={12} sm={6}>
        <ShowJson args={cli}/>
      </Grid>

    </Grid>
  )
}

export default ShowCliente
