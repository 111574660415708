import React, { useState } from "react"
import { Redirect, useHistory, useParams } from "react-router"
import { getRequest } from "../../../api/api"
import Title from "../Shared/Title"
import Async from "react-async"
import LinearProgress from "@material-ui/core/LinearProgress"
import ErrorMsg from "../Shared/Error/ErrorMsg"
import TabsComponent from "../Shared/TabsComponent"
import ProcedimentoForm from "../../../pages/Equips/Procedimentos/ProcedimentoForm"

const RepoWithTabsRender = ({repo, firstTabTitleFld, FirstTabComponent, paramComFn, reload}) => {
  let index = 0

  let tabs = [{
    label: repo[firstTabTitleFld],
    hasMany: false,
    qtd: 1,
    index: index++,
    component: FirstTabComponent
  }]
  paramComFn().forEach(item => {

    const editable = !!item.editable
    const itemsCount = (item.counterField && repo[item.counterField]) || 0
    const forceHideTab = !!item.forceHideTab || (item.hasMany && !editable && !itemsCount)
    const showTab = ((!!repo[item.rel] || !item.addCom) && !forceHideTab)

    const qtd = (
      (!showTab)
        ? 0
        : (
          (!item.hasMany)
            ? 1
            : itemsCount
        )
    )

    if (showTab) {
      tabs.push({
        label: item.title,
        hasMany: item.hasMany,
        qtd: qtd,
        index: index++,
        component: item.component({repo, rel: item.rel, reload})
      })
    }

  })
  return (
    <TabsComponent renderTabs={tabs}/>
  )
}

function CreateRepoComponent({URLname, createRepoFn}) {
  const [repo, setRepo] = useState(null)

  if (repo)
    return <Redirect to={getRequest({URLname: URLname, id: repo.id}).gotoItemPath()}/>

  return (
    <React.Fragment>
      <ProcedimentoForm repo={repo} method={createRepoFn} setRepo={setRepo}/>
    </React.Fragment>
  )
}

const TabbedCRUDRepo = ({
                          URLname, handleBackTitle,
                          readRepoFn, updateRepoFn, createRepoFn,
                          paramComFn, firstTabTitleFld,
                          FormComponent
                        }) => {
  const {id} = useParams()
  const numericID = parseInt(id, 10)

  const history = useHistory()

  const handleBack = () => history.push(getRequest({URLname: URLname}).paginatePath())

  return (
    <React.Fragment>
      {(!numericID)
        ? (
          <React.Fragment>
            <Title handleBack={handleBack}>{handleBackTitle}</Title>
            <CreateRepoComponent URLname={URLname} createRepoFn={createRepoFn}/>
          </React.Fragment>
        )
        : (
          <Async promiseFn={readRepoFn} id={id} com={paramComFn(true)}>
            <Async.Pending><LinearProgress color="secondary"/></Async.Pending>
            <Async.Fulfilled>
              {(data, {setData, reload}) => {
                return (
                  <React.Fragment>
                    <Title handleBack={handleBack}>{handleBackTitle}</Title>
                    <RepoWithTabsRender repo={data}
                                        firstTabTitleFld={firstTabTitleFld}
                                        FirstTabComponent={
                                          React.createElement(FormComponent, {
                                            key: (new Date()).getTime(),
                                            repo: data,
                                            method: updateRepoFn,
                                            setRepo: setData
                                          })
                                        }
                                        paramComFn={paramComFn}
                                        reload={reload}/>
                  </React.Fragment>
                )
              }}
            </Async.Fulfilled>
            <Async.Rejected>{error => <ErrorMsg>{error}</ErrorMsg>}</Async.Rejected>
          </Async>
        )}
    </React.Fragment>
  )
}

export { TabbedCRUDRepo, RepoWithTabsRender }
