import React from "react"
import Box from "@material-ui/core/Box"
import styled from "@material-ui/core/styles/styled"
import Link from "@material-ui/core/Link"
import lfLogo from "../../../../img/logotipo-lf-header.png"
import makeStyles from "@material-ui/core/styles/makeStyles"

const useStyles = makeStyles((theme) => ({
  copyWrapper: {
    color: '#999',
    textAlign: 'right',
    fontSize: '11px',
  },
}))

const Footer = styled(Box)({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  padding: '5px'
})
const version = 'v5.3.2'

const Copyright = () => {
  const classes = useStyles()
  return (
    <div className={classes.copyWrapper} align="center">
      <span>{`${version} ©`}</span>
      <Link color="inherit" href="https://labfactus.com/">
        Labfactus
      </Link>
      <span className="year">{' ' + (new Date()).getFullYear() + '.'}</span>
      <Link color="inherit" href="https://labfactus.com/">
        <img src={lfLogo} alt="lf header" style={{
          width: 'auto',
          height: '16px',
          padding: '0 5px'
        }}/>
      </Link>
    </div>)
}

const MainFooter = () => {

  return (
    <Footer>
      <Copyright/>
    </Footer>
  )
}

export default MainFooter
