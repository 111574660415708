import React, { useState } from "react"
import { Redirect } from "react-router"
import { clientesUrl, createCliente } from "./apiClientes"
import ClienteForm from "./ClienteForm"
import { getRequest } from "../../../api/api"

function ClienteCreate() {
  const [cliente, setCliente] = useState(null)

  if (cliente)
    return <Redirect to={getRequest({URLname: clientesUrl, id: cliente.id}).gotoItemPath()}/>

  return (
    <React.Fragment>
      <ClienteForm cliente={cliente} method={createCliente} setCliente={setCliente}/>
    </React.Fragment>
  )
}

export default ClienteCreate
