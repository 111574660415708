import React, { useEffect, useState } from "react"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import AddIcon from "../../../../components/Svg-Icons/AddIcon"
import { secondaryColor } from "../../../../constants/appTheme"
import msgsLocaisTec from "./msgsLocaisTec"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { StyledTableCell, TableMarginTop } from "../../../../components/SharedStyles/SharedStyles"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import Typography from "@material-ui/core/Typography"
import { AppMsgs } from "../../../../constants/appMsgs"

function LocaisTecList({locais, setState}) {
  const [checkedLocs, setCheckedLoc] = useState(null)
  const classes = TableMarginTop()

  useEffect(() => {
    let arrayLocs = []
    Object.keys(locais).forEach((ddID) => {
      Object.keys(locais[ddID]['concelhos']).forEach((ccID) => {
        Object.keys(locais[ddID]['concelhos'][ccID]['cp4s']).forEach((cp4ID) => {
          const cp4 = locais[ddID]['concelhos'][ccID]['cp4s'][cp4ID]
          if (cp4.checked) {
            return arrayLocs.push({
              dd: locais[ddID].dd,
              cc: locais[ddID]['concelhos'][ccID].cc,
              cp4: cp4.cp4,
              cpalf: cp4.cpalf
            })
          }
        })
      })
    })
    setCheckedLoc(arrayLocs)
  }, [locais])

  return (
    <React.Fragment>
      <Grid item xs={12} md={12}>
        <Box display="flex" justifyContent="flex-end">
          <Button color="secondary" variant="outlined" onClick={() => setState('U')} disableElevation
                  startIcon={<AddIcon color={secondaryColor}/>}>
            {msgsLocaisTec.UPDATE_BUTTON}
          </Button>
        </Box>
      </Grid>
      <TableContainer>
        <Table className={classes.table} size="small" aria-label="TableLocais">
          <TableHead>
            <TableRow>
              <StyledTableCell>{msgsLocaisTec.DISTRITOS}</StyledTableCell>
              <StyledTableCell>{msgsLocaisTec.CONSELHOS}</StyledTableCell>
              <StyledTableCell>{msgsLocaisTec.CP4S}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(checkedLocs && checkedLocs.length) ? checkedLocs.map((item, key) => {
                return (
                  <TableRow key={key}>
                    <TableCell>{item.dd}</TableCell>
                    <TableCell>{item.cc}</TableCell>
                    <TableCell>{item.cp4} - {item.cpalf}</TableCell>
                  </TableRow>
                )
              }) :
              <TableRow>
                <TableCell colSpan="4">
                  <Typography component="h1" variant="h6" color="inherit" noWrap>
                    {AppMsgs.DEFAULT_VALUES.TABLE.EMPTY_TABLE_MSG}
                  </Typography>
                </TableCell>
              </TableRow>}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}

export default LocaisTecList
