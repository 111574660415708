import React from "react"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import { parseToIntegerPercent } from "../../../../../constants/taxasConst"
import PropTypes from "prop-types"
import { StyledTableCell, TableMarginTop } from "../../../../SharedStyles/SharedStyles"

const msgsTableDescontos = {
  TITLE: {
    DESCONTOS: "Descontos",
  },
  LABEL: {
    DESLOCACAO: "Deslocação",
    MO: "Mão de Obra",
    PECAS: "Peças/Componentes",
  }
}

const TableDescontos = ({percDescPecas, percDescDesloc, percDescMO}) => {
  const classes = TableMarginTop()
  return (
    <TableContainer>
      <Table className={classes.table} size="small" aria-label="TableDescontos">
        <TableHead>
          <TableRow>
            <StyledTableCell colSpan="2">{msgsTableDescontos.TITLE.DESCONTOS}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key="pecas">
            <TableCell>{msgsTableDescontos.LABEL.PECAS}</TableCell>
            <TableCell align="right">{parseToIntegerPercent(percDescPecas, true)}</TableCell>
          </TableRow>
          <TableRow key="desloc">
            <TableCell>{msgsTableDescontos.LABEL.DESLOCACAO}</TableCell>
            <TableCell align="right">{parseToIntegerPercent(percDescDesloc, true)}</TableCell>
          </TableRow>
          <TableRow key="maoObra">
            <TableCell>{msgsTableDescontos.LABEL.MO}</TableCell>
            <TableCell align="right">{parseToIntegerPercent(percDescMO, true)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

TableDescontos.defaultValues = {
  percDescPecas: "No value",
  percDescDesloc: "No value",
  percDescMO: "No value",
}

TableDescontos.propTypes = {
  percDescPecas: PropTypes.string,
  percDescDesloc: PropTypes.string,
  percDescMO: PropTypes.string
}

export default TableDescontos
