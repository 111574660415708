import React, { createContext, useCallback, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { ListRepoWrapper } from "./ListRepoWrapper"
import { baseURLPDF } from "../../../api/api"

const ListRepoState = createContext(null)

function ListRepoGest({
                        repo, repoKey, repoItems, FKfieldname, mayEdit, btAddTitle,
                        createRepoFn, deleteRepoFn, readReposFn,
                        ListComponent, getRepoHrefFn, EditComponent, reload
                      }) {
  const [showState, setShowState] = useState({disable: false, editMode: false, createMode: false})
  const [repoList, setRepoList] = useState(repoItems)

  const showList = useCallback(() => {
    setShowState({disable: false, editMode: false, createMode: false})
    reload()
  }, [reload])

  const showEdit = useCallback((itemData) => {
    setShowState({disable: true, editMode: !!itemData, createMode: !itemData, data: itemData})
  }, [])

  const getState = useMemo(() => ({
    showList, showEdit, showState, repoList, setRepoList, getRepoHrefFn,
    repo, repoKey, FKfieldname, mayEdit,
    createRepoFn, readReposFn, deleteRepoFn
  }), [
    showList, showEdit, showState, repoList, setRepoList, getRepoHrefFn,
    repo, repoKey, FKfieldname, mayEdit,
    createRepoFn, readReposFn, deleteRepoFn
  ])

  return (
    <ListRepoState.Provider value={getState}>
      <p>
        {/*separador de alta tecnologia :) */}
      </p>
      {(showState.editMode || showState.createMode) ? <EditComponent/> : null}
      <p>
        {/*separador de alta tecnologia :) */}
      </p>
      <ListRepoWrapper ListComponent={ListComponent} btAddTitle={btAddTitle}/>
    </ListRepoState.Provider>
  )
}

ListRepoGest.defaultProps = {
  getRepoHrefFn: (repoID, pdfID) => (`${baseURLPDF}/get/repo/${repoID}/pdfs/${pdfID}`),
}

ListRepoGest.propTypes = {
  repo: PropTypes.object.isRequired,
  repoKey: PropTypes.string.isRequired,
  repoItems: PropTypes.array.isRequired,
  FKfieldname: PropTypes.string.isRequired,
  createRepoFn: PropTypes.func.isRequired,
  deleteRepoFn: PropTypes.func.isRequired,
  readReposFn: PropTypes.func.isRequired,
  ListComponent: PropTypes.func.isRequired,
  EditComponent: PropTypes.func.isRequired,
  btAddTitle: PropTypes.string.isRequired,
  mayEdit: PropTypes.bool.isRequired,
  reload: PropTypes.func.isRequired,
  getRepoHrefFn: PropTypes.func.isRequired,
}

export {
  ListRepoState,
  ListRepoGest
}
