import React, { useState } from "react"
import MarcaForm from "./MarcaForm"
import { createMarca, marcasURLname } from "./apiMarcas"
import { Redirect, useHistory } from "react-router"
import Title from "../../components/SharedComponents/Shared/Title"
import { getRequest } from "../../api/api"
import marcasMsgs from "./msgs"

function MarcaCreate() {
  const [marca, setMarca] = useState(null)

  const history = useHistory()
  const handleBack = () => history.push(getRequest({URLname: marcasURLname}).paginatePath())

  if (marca)
    return <Redirect to={getRequest({URLname: marcasURLname, id: marca.id}).gotoItemPath()}/>

  return (
    <React.Fragment>
      <Title handleBack={handleBack}>{marcasMsgs.BACK_LABEL}</Title>
      <MarcaForm marca={marca} method={createMarca} setMarca={setMarca}/>
    </React.Fragment>
  )
}


export default MarcaCreate
