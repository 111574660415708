import { api, apiCUD, apiReadTable, getRequest } from "../../../api/api"
import Users from "./Users"
import UserIcon from "../../../components/Svg-Icons/UserIcon"
import UserView from "./UserView"
import msgsUsers from "./msgsUsers"
import { usersUrl } from "./userConst"


const URLprefix = 'admin'
// ----------------------- USERS ROUTES -----------------------
const usersRouterProps = {
  path: getRequest({URLname: usersUrl}).paginatePath(),
  exact: true,
  name: msgsUsers.LABEL,
  component: Users,
  Icon: UserIcon,
  breadcrumb: () => msgsUsers.LABEL,
  reqRole: 'R'
}

const userViewRouterProps = {
  path: getRequest({URLname: usersUrl}).viewPath(),
  exact: true,
  name: msgsUsers.UPDATE_LABEL,
  component: UserView,
  breadcrumb: (props) => !!parseInt(props.match.params.id, 10) ? msgsUsers.UPDATE_LABEL : msgsUsers.CREATE_LABEL,
  reqRole: 'R'
}

// -------------------- USERS PERMS ROUTES --------------------

// async function readUserAndPerms({id}) {
//   const requestUser = getRequest({URLname: usersUrl, id, URLprefix: URLprefix}).GET()
//   const query = {pageSize: 10000, pageIndex: 0}
//   const requestPerms = getRequest({URLname: usersUrl, id, URLprefix: '', query}).GET('perms')
//
//   return Promise.all([
//     api(requestUser),
//     apiReadTable(requestPerms),
//   ]).then(([reqUser, reqPerms]) => ({
//     user: reqUser.data.data,
//     perms: reqPerms
//   }))
// }

async function readUserPerms({id}) {
  const requestPerms = getRequest({URLname: usersUrl, id, URLprefix: ''}).GET('perms')
  return api(requestPerms).then(r => r.data.data)
}

function updateUserPerm([data]) {
  const request = getRequest({URLname: usersUrl, id: data.userID, URLprefix: ''}).PUT(`perms/${data.permID}`)
  return apiCUD(request, data).then(r => r.data)
}

// ----------------------- USERS ROUTES -----------------------
/**
 * Read Users com paginação para tabela
 * @param query
 * @returns {Promise<*|void>}
 */
async function readUsersTable([query]) {
  const request = getRequest({URLname: usersUrl, query, URLprefix: URLprefix}).GET()
  return apiReadTable(request)
}

/**
 * Read Users No Role para o Select
 * @returns {Promise<AxiosResponse<any>>}
 */
async function readUserSelect() {
  const query = {pageSize: 10000, pageIndex: 0}
  const request = getRequest({URLname: usersUrl, query, URLprefix: URLprefix}).GET()
  return api(request).then(r => r.data.data.map((user) => {
    return {index: user.id, title: user.name}
  }))
}

/**
 * Read User
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
function readUser({id}) {
  const request = getRequest({URLname: usersUrl, id, URLprefix: URLprefix}).GET()
  return api(request).then(r => r.data.data)
}

function createUser([data]) {
  const request = getRequest({URLname: usersUrl, URLprefix: URLprefix}).POST()
  return apiCUD(request, data).then(r => r.data)
}

function updateUser([data]) {
  const request = getRequest({URLname: usersUrl, id: data.id, URLprefix: URLprefix}).PUT()
  return apiCUD(request, data).then(r => r.data)
}


async function deleteUser(id) {
  const request = getRequest({URLname: usersUrl, id, URLprefix: URLprefix}).DELETE()
  return apiCUD(request)
}

export {
  usersRouterProps,
  userViewRouterProps,
  readUsersTable,
  readUserSelect,
  readUser,
  createUser,
  updateUser,
  deleteUser,
  readUserPerms,
  updateUserPerm,
}
