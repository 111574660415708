import React from "react"
import Grid from "@material-ui/core/Grid"
import TablePlano from "../Tables/TablePlano"
import TablePlanoActivo from "../Tables/TablePlanoAtivo"
import Fab from "@material-ui/core/Fab"
import { Link as RouterLink } from "react-router-dom"
import { getRequest } from "../../../../api/api"
import { planosAtivadosUrl } from "../../../../pages/Planos/ListPlanosAtivados/apiPlanosAtivados"
import Typography from "@material-ui/core/Typography"
import msgsPlanosAtivados from "../../../../pages/Planos/ListPlanosAtivados/msgsPlanosAtivados"

function ShowPlanoActivo({planoActiv}) {
  const plano = (planoActiv && planoActiv.plano && planoActiv.plano.data) || false

  return (
    <Grid container spacing={2} justify="space-between">
      <Grid item xs={12}>
        <Fab component={RouterLink} size="small"
             to={getRequest({URLname: planosAtivadosUrl, id: planoActiv.id}).gotoItemPath()} color="secondary"
             variant="extended" style={{boxShadow: 'none'}}>
          <Typography variant="body2" style={{paddingLeft: '5px'}}
                      display={'inline'}>{msgsPlanosAtivados.OPEN_BUTTON}</Typography>
        </Fab>
      </Grid>
      {plano &&
      <Grid container spacing={2} justify="space-between">
        <Grid item xs={12} sm={12} md={6}>
          <TablePlano {...plano} />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TablePlanoActivo {...planoActiv} />
        </Grid>
      </Grid>
      }
    </Grid>
  )
}

export { ShowPlanoActivo }
