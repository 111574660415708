import { format, now } from "../../../../components/SharedComponents/Shared/Calendar/momentTZ"

const armazINDefaultState = () => {
  return {
    numserie: false,
    te: false,
    marca: false,
    modelo: false,
    transportador: false,
    faturaCompra: false,
    faturaFrete: false,
  }
}


const armazINDefaultValues = (transpList, qtdTransp,
                              teList, qtdTE
) => {

  const vals = {
    numserie: '',
    te_id: '',
    teRadioGrp_id: '',
    marca_id: '',
    modelo_id: '',
    transportador_id: '',
    te: '',
    marca: '',
    modelo: '',
    transportador: '',
    faturaCompra: '',
    faturaFrete: '',
    dataRegEntrada: now().format(format),
    tipo_cli: '',
    tipo_cli_id: undefined,
    cliente: '',
    faturaVenda: '',
    dataRegSaida: now().format(format),
  }
  if (qtdTransp) {
    vals.transportador_id = transpList[0].index
    vals.transportador = transpList[0].title
  }
  if (qtdTE) {

    let elem = teList[0]
    // caldeiras (id=2) são os mais frequentes...
    const item = teList.find(t => t.index * 1 === 2)
    if (item) {
      elem = item
    }
    vals.te_id = elem.index
    vals.te = elem.title
  }

  return vals
}

function toSlug(str) {
  str = str.replace(/^\s+|\s+$/g, '') // trim
  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;"
  const to = "aaaaeeeeiiiioooouuuunc------"
  const l = from.length
  for (let i = 0; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-')        // collapse whitespace and replace by -
    .replace(/-+/g, '-')         // collapse dashes

  return str
}


const prometeLog = ({logIt = true, msg, ...data}) => {
  return new Promise(
    (resolve, reject) => {
      logIt && console.log(msg || 'prometeLog', data)
      resolve(data[0])
    })
}

export {
  armazINDefaultState,
  armazINDefaultValues,
  toSlug,
  prometeLog
}
