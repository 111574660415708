import React, { useState } from 'react'
import './foto-component.css'
import makeStyles from "@material-ui/core/styles/makeStyles"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import Fab from "@material-ui/core/Fab"
import { errorColor, successColor } from "../../../../../constants/appTheme"
import CardMedia from "@material-ui/core/CardMedia"
import CircularProgress from "@material-ui/core/CircularProgress"
import CancelIcon from "../../../../../components/Svg-Icons/CancelIcon"
import CheckIcon from "../../../../../components/Svg-Icons/CheckIcon"
import Camera from "react-html5-camera-photo"
import FACING_MODES from "react-html5-camera-photo"
import IMAGE_TYPES from "react-html5-camera-photo"
import { createIMGVisitaPat } from "../../apiVisitaPat"
import visitaPatMsgs from "../../visitaPatMsgs"

const background = '#555'
const useStyles = makeStyles((theme) => ({
  cardRoot: {
    display: 'flex',
    background: background,
    padding: theme.spacing(2),
  },
  cover: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    background: background,
  },
  fotoCam: {
    width: '100%',
    background: background,
    maxHeight: 700
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3, 1, 1),
    width: 150,
  },
  fabIcon: {
    color: 'rgba(255,255,255,.3)',
  },
  acceptCancel: {
    position: 'absolute',
    left: 20,
    right: 20,
    bottom: 20,
    zIndex: 100,
  },
  fabIconAccept: {
    float: 'right',
  },
  fabIconCancel: {
    float: 'left',
  },
  camError: {
    background: '#333',
    color: '#FFF',
    padding: theme.spacing(3),
    fontSize: '150%'
  },
}))

const FotoComponent = ({visita, reload}) => {
  const [isPending, setIsPending] = useState(false)
  const [camError, setCamError] = useState(false)
  const [dataUri, setDataUri] = useState('')
  const classes = useStyles()

  const handleTakePhotoAnimationDone = (dataUri) => {
    setDataUri(dataUri)
  }

  const acceptPhoto = () => {
    setIsPending(true)
    createIMGVisitaPat({visita_id: visita.id, image: dataUri})
      .then(_ => {
        setIsPending(false)
        reload()
      })
  }

  const cancelPhoto = () => {
    setDataUri('')
  }

  function handleCameraError(error) {
    setCamError(true)
  }

  return (
    <Grid container spacing={2} justify="center" alignItems="center">
      {/*-------------------------- Camera -----------------------------*/}
      <Grid item xs={12}>
        <Card className={classes.cardRoot}>
          <CardMedia className={classes.cover}>
            <Grid container spacing={1}
                  direction="row"
                  justify="center" alignItems="center">
              {(!dataUri)
                ? (
                  <Grid item xs={12} align="center">
                    {(camError)
                      ? (
                        <div>
                          <typography className={classes.camError}>{visitaPatMsgs.CAM_ERROR}</typography>
                        </div>)
                      : (
                        <Camera
                          className={classes.fotoCam}
                          onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
                          onCameraError={(error) => {
                            handleCameraError(error)
                          }}
                          idealFacingMode={FACING_MODES.ENVIRONMENT}
                          idealResolution={{width: 1024, height: 768}}
                          imageType={IMAGE_TYPES.JPG}
                          imageCompression={0.90}
                          isMaxResolution={true}
                          isImageMirror={false}
                          isSilentMode={false}
                          isDisplayStartCameraError={true}
                          isFullscreen={false}
                          sizeFactor={1}
                        />)}
                  </Grid>)
                : (
                  <Grid item xs={12} align="center" style={{position: 'relative',}}>
                    {(isPending)
                      ? <CircularProgress color="secondary"/>
                      : (<>
                        <div className={classes.acceptCancel}>
                          <Fab className={classes.fabIconCancel} size={'small'} aria-label="cancelPhoto" onClick={() => cancelPhoto()}>
                            <CancelIcon color={errorColor} size={16}/>
                          </Fab>
                          <Fab className={classes.fabIconAccept} size={'small'} aria-label="acceptPhoto" onClick={() => acceptPhoto()}>
                            <CheckIcon color={successColor} size={16}/>
                          </Fab>
                        </div>
                        <img className={classes.fotoCam} alt="imgCamera" src={dataUri}/>
                      </>)}
                  </Grid>)}
            </Grid>
          </CardMedia>
        </Card>
      </Grid>
    </Grid>
  )
}

export default FotoComponent
