import React from "react"
import { useAsync } from "react-async"
import { FormContext, useForm } from "react-hook-form"
import { Form } from "../../../components/SharedStyles/SharedStyles"
import Grid from "@material-ui/core/Grid"
import LFInput from "../../../components/LFInputs/LFInput"
import { AppMsgs } from "../../../constants/appMsgs"
import LFSelect from "../../../components/LFInputs/LFSelect"
import { LFNumberInput } from "../../../components/LFInputs/LFNumberInput"
import LFContactForm from "../../../components/LFComponentsForm/LFContactForm"
import LFZipCodeForm from "../../../components/LFComponentsForm/LFZipCodeForm"
import LFTextAreaInput from "../../../components/LFInputs/LFTextAreaInput"
import LFSubmitButton from "../../../components/SharedComponents/Shared/LFButtons/LFSubmitButton"
import * as Yup from "yup"
import {
  avalImmValidation,
  cp3Validation,
  cp4Validation,
  emailValidation,
  nifValidation,
  requiredValidation,
  selectValidation,
  tlfValidation,
  tlmValidation
} from "../../../constants/appValidation"
import Typography from "@material-ui/core/Typography"
import LFRatingInput from "../../../components/LFInputs/LFRatingInput"
import LFAsyncSelectInput from "../../../components/LFInputs/LFAsyncSelectInput"
import { readUserSelect } from "../Users/apiUsers"
import LFImageInput from "../../../components/LFInputs/ImageUploader/LFImageInput"
import { NO_IMAGE } from "../../../constants/appConst"
import msgsTecnicos from "./msgsTecnicos"


const validationSchema = Yup.object().shape({
  nome: requiredValidation(AppMsgs.ENTIDADES.EDIT.LABEL.NOME),
  nif: nifValidation(AppMsgs.ENTIDADES.EDIT.LABEL.NIF),
  cp4: cp4Validation(AppMsgs.ENTIDADES.EDIT.LABEL.CP4),
  cp3: cp3Validation(AppMsgs.ENTIDADES.EDIT.LABEL.CP3),
  email: emailValidation(AppMsgs.ENTIDADES.EDIT.LABEL.EMAIL),
  tlm: tlmValidation(AppMsgs.ENTIDADES.EDIT.LABEL.TLM),
  tlf: tlfValidation(AppMsgs.ENTIDADES.EDIT.LABEL.TLF),
  tipoCAT: selectValidation(msgsTecnicos.EDIT.LABEL.TIPO_CAT),
  avalImm: avalImmValidation(1, 5, msgsTecnicos.EDIT.LABEL.AVAL_IMM),
  contactRequired: Yup.bool().when(['email', 'tlm', 'tlf'], {
    is: (email, tlm, tlf) => (!email && !tlm && !tlf) /*|| (!!email && !!tlm && !!tlf)*/,
    then: Yup.bool().required(AppMsgs.ERROR.CONTACT_REQUIRED),
    otherwise: Yup.bool()
  }),
  zipCodeRequired: Yup.bool().when(['cp4', 'cp3'], {
    is: (cp4, cp3) => (!cp4 && cp3) || (cp4 && !cp3),
    then: Yup.bool().required(AppMsgs.ERROR.ZIPCODE_REQUIRED),
    otherwise: Yup.bool()
  })
})

const tipoDesc = {
  'I': 'Interno',
  'E': 'Externo',
}

const defaultValues = (tecnico) => {
  return {
    logo: tecnico ? tecnico.logo : NO_IMAGE,
    user_id: (tecnico && tecnico.user_id) ? {index: tecnico.user_id, title: tecnico.username} : "",
    tipoCAT: tecnico ? {index: tecnico.tipoCAT, title: tipoDesc[tecnico.tipoCAT]} : '',
    avalImm: tecnico ? tecnico.avalImm : 0,
    nome: tecnico ? tecnico.nome : '',
    nif: tecnico ? tecnico.nif : '',
    email: tecnico ? tecnico.email : '',
    tlm: tecnico ? tecnico.tlm : '',
    tlf: tecnico ? tecnico.tlf : '',
    obs: tecnico ? tecnico.obs : '',
    morada: tecnico ? tecnico.morada : '',
    cp4: tecnico ? tecnico.cp4 : '',
    cp3: tecnico ? tecnico.cp3 : ''
  }
}

const options = [
  {index: "I", title: "Interno"},
  {index: "E", title: "Externo"},
]

function TecnicoForm({tecnico, method, setTecnico}) {

  const {isPending, run} = useAsync({
    deferFn: method,
    onResolve: (newTecnico) => {
      setTecnico(newTecnico)
      // console.log('onResolve', newTecnico)
      reset(defaultValues(newTecnico))
    }
  })

  const methods = useForm({
    validationSchema: validationSchema,
    defaultValues: defaultValues(tecnico)
  })

  const {handleSubmit, formState, control, reset} = methods

  const onSubmit = (values) => {
    const convertValues = {...values, tipoCAT: values.tipoCAT.index, user_id: values.user_id && values.user_id.index}
    run({...tecnico, ...convertValues})
  }

  return (
    <FormContext {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} justify="center">
          {/*  ------------------------------------------- LOGO -----------------------------------------------------*/}
          <Grid item xs={12}>
            <LFImageInput image={tecnico ? tecnico.logo : NO_IMAGE} control={control} name="logo"/>
          </Grid>
          {/*------------------------------------------- NOME -------------------------------------------------------*/}
          <Grid item xs={12} sm={8}>
            <LFInput label={AppMsgs.ENTIDADES.EDIT.LABEL.NOME} name='nome'/>
          </Grid>
          <Grid item xs={12} sm={4}>
            <LFAsyncSelectInput request={readUserSelect} control={control} name='user_id'
                                label={AppMsgs.ENTIDADES.EDIT.LABEL.USER}/>
          </Grid>
          {/*------------------------------------------ TIPO_CAT ----------------------------------------------------*/}
          <Grid item xs={12} sm={4}>
            <LFSelect name="tipoCAT" control={control} options={options} label={msgsTecnicos.EDIT.LABEL.TIPO_CAT}/>
          </Grid>
          {/*-------------------------------------------- NIF -------------------------------------------------------*/}
          <Grid item xs={12} sm={4}>
            <LFNumberInput label={AppMsgs.ENTIDADES.EDIT.LABEL.NIF} name='nif'/>
          </Grid>
          {/*------------------------------------------ AVALIMM ----------------------------------------------------*/}
          <Grid item xs={12} sm={4}>
            <Typography component="legend"
                        style={{paddingBottom: '5px'}}>{msgsTecnicos.EDIT.LABEL.AVAL_IMM}</Typography>
            <LFRatingInput name="avalImm" control={control} initValue={tecnico && tecnico.avalImm}/>
          </Grid>
          {/*------------------------------------------ EMAIL -------------------------------------------------------*/}
          <LFContactForm/>
          {/*------------------------------------- ZIPCODE_REQUIRED MSG ---------------------------------------------*/}
          <LFZipCodeForm/>
          {/*----------------------------------------- MORADA -------------------------------------------------------*/}
          <Grid item xs={12}>
            <LFTextAreaInput label={AppMsgs.ENTIDADES.EDIT.LABEL.OBS} name='obs'/>
          </Grid>
        </Grid>
        <LFSubmitButton isLoading={isPending} dirty={!formState.dirty}>
          {tecnico ? msgsTecnicos.BUTTON.EDIT_TECNICO : msgsTecnicos.BUTTON.NEW_TECNICO}
        </LFSubmitButton>
      </Form>
    </FormContext>
  )
}


export default TecnicoForm
