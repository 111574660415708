import React from "react"
import Grid from "@material-ui/core/Grid"
import LeftMarginSpan from "../../../SharedStyles/LeftMarginSpan"
import StyledLabel from "../../../SharedStyles/StyledLabel"
import equipsMsgs from "../../../../pages/Equips/msgs"
import ShowJson from "./ShowJson"

function ShowEquipamento({equip}) {
  return (
    <Grid container spacing={2} justify="center">

      <Grid item xs={12} sm={6}>
        <div>
          <StyledLabel>{equipsMsgs.LABELS.NUM_SERIE}</StyledLabel>
          <LeftMarginSpan>{equip.numserie}</LeftMarginSpan>
        </div>
      </Grid>

      <Grid item xs={12} sm={6}>
        <ShowJson args={equip}/>
      </Grid>

    </Grid>
  )
}

export default ShowEquipamento
