import React from "react"
import SvgIcon from "./SvgIcon"

const CheckIcon = (others) => {
  return (
    <SvgIcon viewBox="0 0 512 512"  {...others}>
      <path
        d="m506.23 75.508c-7.689-7.69-20.158-7.69-27.849 0l-319.21 319.21-125.56-125.56c-7.689-7.691-20.158-7.691-27.849 0-7.69 7.69-7.69 20.158 0 27.849l139.48 139.48c7.687 7.687 20.16 7.689 27.849 0l333.13-333.14c7.69-7.691 7.69-20.159 0-27.849z"/>
    </SvgIcon>
  )
}

export default CheckIcon
