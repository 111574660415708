import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { createStyles } from "@material-ui/core"
import ClockIcon from "../../../Svg-Icons/ClockIcon"
import CalendarIcon from "../../../Svg-Icons/CalendarIcon"
import { errorColor, successColor, textSecondary } from "../../../../constants/appTheme"

const useStyles = makeStyles((theme) =>
  createStyles({
    icon: {
      width: '11px',
      height: '11px',
    },
    spanIcon: {
      display: 'inline-block',
      lineHeight: '12px',
      paddingTop: '2px'
    },
    dataHora: {
      display: 'inline-block',
      fontSize: '12px',
      marginLeft: '3px',
      lineHeight: '10px',
      padding: '0',
    }
  })
)

const cor = (offLimit) => (offLimit === true ? errorColor : (offLimit === false ? successColor : textSecondary))

function ShowDateTime({dateTime, offLimit, classForDate, iconsColor}) {
  const classes = useStyles()
  const [data, hora] = (dateTime || '--- ---').split(' ')
  const classDate = classForDate ? classForDate : classes.dataHora
  return dateTime ? (
    <React.Fragment>
      <span style={{color: cor(offLimit), display: 'block'}}>
        <span className={classes.spanIcon}><CalendarIcon className={classes.icon} color={iconsColor ? iconsColor : '#333'}/></span>
        <span className={classDate}>{data}</span>
      </span>
      <span style={{color: cor(offLimit), display: 'block'}}>
        <span className={classes.spanIcon}><ClockIcon className={classes.icon} color={iconsColor ? iconsColor : '#333'}/></span>
        <span className={classDate}>{hora}</span>
      </span>
    </React.Fragment>
  ) : null
}

function ShowDate({date, offLimit}) {
  const classes = useStyles()
  return (
    <React.Fragment>
      <div style={{color: cor(offLimit)}}>
        <CalendarIcon className={classes.icon}/>
        <span className={classes.dataHora}>{date}</span>
      </div>
    </React.Fragment>
  )
}

export { ShowDate, ShowDateTime }
