import React from "react"
import { useHistory, useParams } from "react-router"
import Title from "../../../components/SharedComponents/Shared/Title"
import UserUpdate from "./UserUpdate"
import UserCreate from "./UserCreate"
import { getRequest } from "../../../api/api"
import msgsUsers from "./msgsUsers"
import { usersUrl } from "./userConst"

function UserView() {
  let {id} = useParams()

  const history = useHistory()
  const handleBack = () => history.push(getRequest({URLname: usersUrl}).paginatePath())

  return (
    <React.Fragment>
      <Title handleBack={handleBack}>{msgsUsers.BACK_LABEL}</Title>
      {(!!parseInt(id, 10)) ?
        <UserUpdate id={id}/> :
        <UserCreate/>
      }
    </React.Fragment>
  )
}

export default UserView
