import React from "react"
import Typography from "@material-ui/core/Typography"

const planosAplicaveisMsgs = {
  BACK_LABEL: "Voltar aos Planos Aplicáveis",
  EDIT: {
    LABEL: {
      DATA_INIC: "Início",
    }
  },
  BUTTON: {
    SIMULA: "Iniciar Associação",
    ASSOC: "Associar Plano"
  },

  PERIODO_LABEL: "Período",
  ASSOC_CONFIRM_LABEL: (plano, equip, data) => {
    return (
      <Typography style={{fontSize: '12px'}}>
        Confirmo que pretendo associar o plano <strong> {plano} </strong> ao equipamento <strong> {equip} </strong>, com
        início em: <strong> {data} </strong>
      </Typography>
    )
  },
  TABLE: {
    HEADER: {
      REF_CODE: "Referência",
      DESIGNACAO: "Designacao",
      SIMULACAO: "Associar",
      PDF: "PDF",
      DIAS_ANT: "Dias de Antecedência",
    },
    LABEL: {
      AVISOS: "Avisos",
      PMP: "PMP (PAT de manutenção preventiva)",
      PA: "PA (PAT de Arranque",
      DIAS: " (Dias)"
    }
  },
}

export default planosAplicaveisMsgs
