import React from "react"
import LFInput from "./LFInput"
import { AppMsgs } from "../../constants/appMsgs"
import PropTypes from "prop-types"

function LFNumberInput(props) {
  return <LFInput
    type="number"
    {...props}
  />
}

LFNumberInput.defaultProps = {
  label: AppMsgs.DEFAULT_VALUES.INPUT.NUMBER_LBL,
}

LFNumberInput.propTypes = {
  label: PropTypes.string
}

export { LFNumberInput }
