import React, { useContext, useState } from "react"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import Badge from "@material-ui/core/Badge"
import { Badge as InsideBadge, BadgeVariable, StyledTableCell } from "../../../../../components/SharedStyles/SharedStyles"
import { primaryColor, secondaryColor, secondaryColorLight, tableStyles } from "../../../../../constants/appTheme"
import patInfoStatesMsgs from "../patInfoStatesMsgs"
import StarIcon from "../../../../../components/Svg-Icons/StarIcon"
import { ShowDateTime } from "../../../../../components/SharedComponents/Shared/Show/ShowDateTime"
import Button from "@material-ui/core/Button"
import { AssocTecAgendaPATState } from "./AssocTecEAgendaPAT"
import IconButton from "@material-ui/core/IconButton"
import BuildIcon from "../../../../../components/Svg-Icons/BuildIcon"
import CheckIcon from "../../../../../components/Svg-Icons/CheckIcon"
import CalendarIcon from "../../../../../components/Svg-Icons/CalendarIcon"
import Grid from "@material-ui/core/Grid"
import { Paper } from "@material-ui/core"
import { repoMsgs } from "../../../../../components/SharedComponents/ListRepo/repoMsgs"
import CustomDialog from "../../../../../components/SharedComponents/Shared/Dialog/CustomDialog"
import { CalendarComponent, parseMarcacao } from "../../../../../components/SharedComponents/Shared/Calendar/CalendarComponent"
import CircularProgress from "@material-ui/core/CircularProgress"
import { useAsync } from "react-async"
import { assocTecEAgendaPat } from "../apiInfoStates"
import makeStyles from "@material-ui/core/styles/makeStyles"


const localStyles = makeStyles({
  rowNome: {
    maxWidth: '5em',
  },
  propsStyles: props => ({
    ...props
  }),
})

const AssocTecPat = ({pat, state}) => {
  const tecClassifier = (state && state.tecClassifier) || false
  const allTecs = (tecClassifier && tecClassifier.tecsAll && tecClassifier.tecsAll.data) || []
  const dhAgendamento = (tecClassifier && tecClassifier.dhAgendamento) || false

  const [tblState, setTblState] = useState({
    selectedTec: {id: 0, nome: ''},
    calendarItems: [],
    qtdCalItems: 0,
    calTec: 0,
    strChosenDateTime: dhAgendamento,
  })

  const [openCalDialog, setOpenCalDialog] = React.useState(false)
  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false)
  const classes = tableStyles()
  const myClasses = localStyles()

  const {handleReadDataAgendamento, done} = useContext(AssocTecAgendaPATState)

  const {isPending, run} = useAsync({
    deferFn: assocTecEAgendaPat,
    onResolve: (data) => done()
  })

  const setSelectedTec = (tec) => {
    return {
      ...tblState, ...{
        selectedTec: tec,
        calendarItems: [],
        qtdCalItems: 0,
      }
    }
  }

  const handleAssocTecClick = (tec) => {
    setTblState(setSelectedTec(tec))
    setConfirmationDialogVisible(true)
  }

  const handleConfirmedDateTime = () => {
    run({
      dhAgendamentoComCliente: tblState.strChosenDateTime,
      pat_id: pat.id,
      tec_id: tblState.selectedTec.id
    })
  }

  const handleCancel = () => {
    // feito no CustomDialog
    // setConfirmationDialogVisible(false)
    // initial State
    setTblState({
      ...setSelectedTec({id: 0, nome: ''}),
      strChosenDateTime: dhAgendamento
    })
  }

  const handleShowTecCalendar = (tec) => {
    const items = []
    const marcacoes = (tec.marcacoes && tec.marcacoes.data) || []
    marcacoes.forEach((m) => {
      items.push(parseMarcacao(m))
    })
    const qtdItems = items.length
    setTblState({
      ...tblState, ...{
        selectedTec: tec,
        calendarItems: items,
        qtdCalItems: qtdItems,
        calTec: tec.id,
      }
    })

    setOpenCalDialog(true)
  }

  const handleApprovedDateTime = (strDatetime) => {
    setTblState({...tblState, strChosenDateTime: strDatetime})
    setOpenCalDialog(false)
  }

  return (
    <React.Fragment>
      <TableContainer>
        <Table className={classes.table} size="small" aria-label="TableAllTecsClassifier">
          {/* ----------------------------------------------------------------- */}
          <>
            <TableHead>
              <TableRow>
                <StyledTableCell colSpan={3}>
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    onClick={handleReadDataAgendamento}
                    color="primary">
                    {patInfoStatesMsgs.TESTAR_NOVA_DATA}
                  </Button>
                </StyledTableCell>
                <StyledTableCell align={'center'}>{patInfoStatesMsgs.COBERTURA}</StyledTableCell>
                <StyledTableCell colSpan={2}>
                  <Paper elevation={3} style={{padding: '.5rem'}}>
                    <ShowDateTime dateTime={tblState.strChosenDateTime}/>
                  </Paper>
                </StyledTableCell>
                <StyledTableCell> </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell align={'center'}>{patInfoStatesMsgs.TIPO_TEC}</StyledTableCell>
                <StyledTableCell>{patInfoStatesMsgs.NOME_TEC}</StyledTableCell>
                <StyledTableCell align={'center'}>{patInfoStatesMsgs.AVAL_IMM}</StyledTableCell>

                <StyledTableCell align={'center'}>{patInfoStatesMsgs.ZONA} / {patInfoStatesMsgs.MARCA}</StyledTableCell>
                <StyledTableCell align={'center'}><CalendarIcon/></StyledTableCell>
                <StyledTableCell align={'center'}>{patInfoStatesMsgs.LAST_CONTACT}</StyledTableCell>
                <StyledTableCell align={'center'}>{patInfoStatesMsgs.ASSOC_TEC}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allTecs.map((tec, key) => (
                <TableRow key={'tcRow-' + key} hover>
                  <TableCell align={'center'}>
                    <InsideBadge props={tec.tipoCAT === 'I' ? 1 : 0}>{tec.tipoCAT}</InsideBadge>
                  </TableCell>
                  <TableCell className={myClasses.rowNome}>{tec.nome}</TableCell>
                  <TableCell align={'center'}>
                    <Badge badgeContent={tec.avalImm} color="secondary">
                      <StarIcon size={20} color={primaryColor}/>
                    </Badge>
                  </TableCell>

                  <TableCell>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                      <Grid item xs>{tec.cobreRegiao ? <BadgeVariable props={1}>{'Z'}</BadgeVariable> : ''}</Grid>
                      <Grid item xs> {tec.cobreMarca ? <BadgeVariable props={7}>{'M'}</BadgeVariable> : ''}</Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align={'center'}>
                    {/*{(!!(tec.marcacoes && tec.marcacoes.data && tec.marcacoes.data.length)) &&*/}
                    {/*}*/}
                    <IconButton onClick={() => handleShowTecCalendar(tec)}>
                      <CalendarIcon color={secondaryColor} style={{width: '12px', height: '12px'}}/>
                    </IconButton>
                  </TableCell>

                  <TableCell>{tec.lastTecVisit
                    ? <ShowDateTime dateTime={tec.lastTecVisit}/>
                    : ''}
                  </TableCell>
                  <TableCell align={'center'}>
                    {/*{(tec.id === tblState.calTec) &&*/}
                    {/*}*/}
                    <IconButton style={{padding: "0px 10px"}}
                                onClick={() => handleAssocTecClick(tec)}>
                      {isPending && <CircularProgress color="secondary" size={20}/>}
                      <BuildIcon color={secondaryColorLight}/>
                      <CheckIcon color={secondaryColorLight} style={{width: '11px', height: '11px'}}/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </>
        </Table>
      </TableContainer>
      <CustomDialog open={openCalDialog} setOpen={setOpenCalDialog}
                    title={patInfoStatesMsgs.CAL_TITLE(pat.refCode, tblState.selectedTec.nome, dhAgendamento)}>
        <CalendarComponent calendarItems={tblState.calendarItems} handleApprovedDateTime={handleApprovedDateTime}/>
      </CustomDialog>
      <CustomDialog open={confirmationDialogVisible}
                    setOpen={setConfirmationDialogVisible}
                    title={repoMsgs.CONFIRM_DIALOG_TITLE}
                    onConfirm={() => handleConfirmedDateTime()}
                    onCancel={() => handleCancel()}
                    msgsConfirm={repoMsgs.YES}
                    msgsCancel={repoMsgs.NO}>
        {patInfoStatesMsgs.CONFIRM_DIALOG_TITLE(pat.refCode, tblState.selectedTec.nome, tblState.strChosenDateTime)}
      </CustomDialog>
    </React.Fragment>
  )
}

export default AssocTecPat

