import React from "react"
import { useAsync } from "react-async"
import { FormContext, useForm } from "react-hook-form"
import { Form } from "../../../components/SharedStyles/SharedStyles"
import Grid from "@material-ui/core/Grid"
import LFInput from "../../../components/LFInputs/LFInput"
import LFSubmitButton from "../../../components/SharedComponents/Shared/LFButtons/LFSubmitButton"
import * as Yup from "yup"
import { requiredValidation, selectValidation } from "../../../constants/appValidation"
import procedimentosMsgs from "./procedimentosMsgs"
import LFTextAreaInput from "../../../components/LFInputs/LFTextAreaInput"
import LFSelect from "../../../components/LFInputs/LFSelect"

const validationSchema = Yup.object().shape({
  tipo: selectValidation(procedimentosMsgs.EDIT.LABEL.TIPO),
  desc: requiredValidation(procedimentosMsgs.EDIT.LABEL.DESC)
})

const options = [
  {index: "PMP", title: "PMP"},
  {index: "PA", title: "PA"},
]

const defaultValues = (procedimento) => {
  return {
    tipo: procedimento
      ? {index: procedimento.tipo, title: procedimento.tipo}
      : '',
    desc: procedimento ? procedimento.desc : '',
    obs: procedimento ? procedimento.obs : ''
  }
}

function ProcedimentoForm({repo, method, setRepo}) {

  const {isPending, run} = useAsync({
    deferFn: method,
    onResolve: (newProcedimento) => {
      setRepo(newProcedimento)
      reset(defaultValues(newProcedimento))
    }
    //Reject não é necessário porque foi capturado no apiCUD
  })

  const methods = useForm({
    validationSchema: validationSchema,
    defaultValues: defaultValues(repo)
  })
  const {handleSubmit, formState, control, reset} = methods

  const onSubmit = (values) => {
    const convertValues = {...values, tipo: values.tipo.index}
    run({...repo, ...convertValues})
  }

  return (
    <FormContext {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} justify="center">

          {/*-------------------------------------- tipo -------------------------------------*/}
          <Grid item xs={12} sm={4}>
            <LFSelect name="tipo" control={control} options={options} label={procedimentosMsgs.EDIT.LABEL.TIPO}/>
          </Grid>
          {/* -------------------------------------- desc ----------------------------------------*/}
          <Grid item xs={12} sm={8}>
            <LFInput label={procedimentosMsgs.EDIT.LABEL.DESC} name='desc'/>
          </Grid>
          {/* -------------------------------------- obs -----------------------------------------*/}
          <Grid item xs={12}>
            <LFTextAreaInput label={procedimentosMsgs.EDIT.LABEL.OBS} name='obs'/>
          </Grid>

        </Grid>
        <LFSubmitButton isLoading={isPending} dirty={!formState.dirty}>
          {repo ? procedimentosMsgs.BUTTON.EDIT : procedimentosMsgs.BUTTON.NEW}
        </LFSubmitButton>
      </Form>
    </FormContext>
  )
}

export default ProcedimentoForm
