import React from "react"
import { Controller } from "react-hook-form"
import FileUploader from "./FileUploader"
import PropTypes from "prop-types"

function LFFileInput(props) {
  const {name, control, setFilename} = props
  return (
    <Controller
      as={<FileUploader name={name} setFilename={setFilename}/>}
      control={control}
      name={name}
    />
  )
}

LFFileInput.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired
}

export default LFFileInput
