import { api, apiCUD, apiReadTable, getRequest } from "../../../api/api"
import procedimentosMsgs from "./procedimentosMsgs"
import ProcedimentoIcon from "../../../components/Svg-Icons/ProcedimentoIcon"
import Procedimentos from "./Procedimentos"
import ProcedimentoView from "./ProcedimentoView"

const procedimentosURL = 'procedimentos'

// ----------------------- PROCEDIMENTOS ROUTES -----------------------
const procedimentosPaginateRouterProps = {
  path: getRequest({URLname: procedimentosURL}).paginatePath(),
  exact: true,
  name: procedimentosMsgs.LABEL,
  component: Procedimentos,
  Icon: ProcedimentoIcon,
  breadcrumb: () => procedimentosMsgs.LABEL,
  reqRole: 'R'
}
const procedimentoViewRouterProps = {
  path: getRequest({URLname: procedimentosURL}).viewPath(),
  name: procedimentosMsgs.UPDATE_LABEL,
  exact: true,
  component: ProcedimentoView,
  breadcrumb: (props) => !!parseInt(props.match.params.id, 10) ? procedimentosMsgs.UPDATE_LABEL : procedimentosMsgs.CREATE_LABEL,
  reqRole: 'R'
}

// ----------------------- PROCEDIMENTOS ROUTES -----------------------

/**
 * Read Marcas com paginação para tabela
 * @param query
 * @returns {Promise<*|void>}
 */
async function readProcedimentosTable([query]) {
  const request = getRequest({URLname: procedimentosURL, query}).GET()
  return apiReadTable(request)
}

async function deleteProcedimento(id) {
  const request = getRequest({URLname: procedimentosURL, id}).DELETE()
  return apiCUD(request)
}

/**
 * @param id
 * @param com
 * @returns {Promise<AxiosResponse<any>>}
 */
function readProcedimento({id, com}) {
  const request = getRequest({URLname: procedimentosURL, id, query: {com: com}}).GET()
  return api(request).then(r => r.data.data)
}

function updateProcedimento([data]) {
  const request = getRequest({URLname: procedimentosURL, id: data.id}).PUT()
  return apiCUD(request, data).then(r => r.data)
}

function createProcedimento([data]) {
  const request = getRequest({URLname: procedimentosURL}).POST()
  return apiCUD(request, data).then(r => r.data)
}

async function readProcedimentosForEquip(equipID) {
  const request = getRequest({URLname: procedimentosURL, paginateOFF: true}).GET(`equip/${equipID}`)
  return api(request).then(r => r.data.map((procedimento) => {
    return {index: procedimento.id, title: `${procedimento.desc} (${procedimento.tipo})`}
  }))
}

export {
  procedimentosURL,
  procedimentosPaginateRouterProps,
  procedimentoViewRouterProps,
  readProcedimentosTable,
  deleteProcedimento,
  readProcedimento,
  updateProcedimento,
  createProcedimento,
  readProcedimentosForEquip
}
