import React, { useContext } from "react"
import { FormContext, useForm } from "react-hook-form"
import Grid from "@material-ui/core/Grid"
import PropTypes from "prop-types"
import { ListRepoState } from "../../../../../components/SharedComponents/ListRepo/ListRepoGest"
import { handleSaveRepoItem } from "../../../../../components/SharedComponents/ListRepo/handleSaveRepoItem"
import { Form } from "../../../../../components/SharedStyles/SharedStyles"
import { repoMsgs } from "../../../../../components/SharedComponents/ListRepo/repoMsgs"
import LFSubmitButton from "../../../../../components/SharedComponents/Shared/LFButtons/LFSubmitButton"
import makeStyles from "@material-ui/core/styles/makeStyles"
import * as Yup from "yup"
import { requiredValidation, selectValidation } from "../../../../../constants/appValidation"
import patInfoStatesMsgs from "../../../EstadosPat_InfoStates/InfoStates/patInfoStatesMsgs"
import LFSelect from "../../../../../components/LFInputs/LFSelect"
import { obsMsgs } from "./obsMsgs"
import FormHelperText from "@material-ui/core/FormHelperText"
import LFTextEditor from "../../../../../components/LFInputs/LFTextEditor"


const useStyles = makeStyles(theme => ({
  quillEditor: {
    minHeight: '8em'
  }
}))

// ------------------------------------------
// Como surge o registo desta obs?
// ------------------------------------------
// CAT   - Centro de Assistência Técnica
// IMM   - IMMERSAT
// CLI   - Cliente     ---  para já fica desativado...
const options = [
  {index: "CAT", title: "Centro de Assistência Técnica"},
  {index: "IMM", title: "IMMERSAT"},
  // {index: "CLI", title: "Cliente"},
]

const validationSchema = Yup.object().shape({
  origemObs: selectValidation(patInfoStatesMsgs.COMMENT_ORIG),
  obs: requiredValidation(patInfoStatesMsgs.OBS),
})

function OBSForm({FKfieldname}) {
  const classes = useStyles()
  const {showList, showState, setRepoList, repo, createRepoFn, readReposFn} = useContext(ListRepoState)
  const method = (data) => handleSaveRepoItem({
    showList: showList,
    showState: showState,
    repo: repo,
    setRepoList: setRepoList,
    data: data,
    createRepoFn: createRepoFn,
    readReposFn: readReposFn,
  })

  const methods = useForm({
    validationSchema: validationSchema,
    defaultValues: {
      obs: '',
      origemObs: '',
    }
  })
  const {handleSubmit, formState, control} = methods

  const onSubmit = (values) => {
    const convertValues = {
      ...values, ...{
        origemObs: values.origemObs.index,
      }
    }
    convertValues[FKfieldname] = repo.id
    method({...values, ...convertValues})
  }

  return (
    <FormContext {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} justify="center">
          {/*---------------------------------------------------------------*/}
          <Grid item xs>
            <LFSelect name="origemObs" control={control} options={options} label={obsMsgs.COMMENT_ORIG}/>
          </Grid>
          {/*---------------------------------------------------------------*/}
          <Grid item xs={12}>
            <FormHelperText>{obsMsgs.COMMENT}</FormHelperText>
            <LFTextEditor name='obs' control={control} className={classes.quillEditor}/>
          </Grid>
        </Grid>
        <LFSubmitButton dirty={!formState.dirty}>
          {repoMsgs.BUTTON.NEW}
        </LFSubmitButton>
      </Form>
    </FormContext>
  )
}

OBSForm.propTypes = {
  FKfieldname: PropTypes.string.isRequired,
}

export { OBSForm }
