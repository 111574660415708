import React, { useContext } from "react"
import { useAsync } from "react-async"
import { FormContext, useForm } from "react-hook-form"
import { Form } from "../../../components/SharedStyles/SharedStyles"
import Grid from "@material-ui/core/Grid"
import LFSubmitButton from "../../../components/SharedComponents/Shared/LFButtons/LFSubmitButton"
import * as Yup from "yup"
import { requiredDateValidation } from "../../../constants/appValidation"
import LFDatePickerInput from "../../../components/LFInputs/LFDatePickerInput"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import planosAplicaveisMsgs from "../msgsPlanosAplicaveis"
import { RedirectState } from "../PlanosAplicaveis"
import { LFFormatDateTime } from "../../../constants/appConst"
import MomentUtils from "@date-io/moment"
import moment from "moment"
import "moment/locale/pt"


const validationSchema = Yup.object().shape({
  dataInic: requiredDateValidation(planosAplicaveisMsgs.EDIT.LABEL.DATA_INIC),
})


function PlanosAplicaveisForm({equipID, planoID, method}) {

  const {assoc} = useContext(RedirectState)

  const {isPending, run} = useAsync({
    deferFn: method,
    onResolve: (planoAplicavel) => {
      assoc({planoAplicavel: planoAplicavel})
    }
  })

  const methods = useForm({
    validationSchema: validationSchema,
    defaultValues: {
      dataInic: new Date()
    }
  })

  const {handleSubmit, control} = methods

  const onSubmit = ({...values}) => {
    const convertValues = {
      ...values, ...{
        dataInic: values.dataInic && LFFormatDateTime(values.dataInic),
        plano_id: planoID,
        equip_id: equipID
      }
    }
    run({...convertValues})
  }

  return (
    <div>
      <FormContext {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} justify="center">
            {/*---------------------------------- DATA_INIC ----------------------------------*/}
            <Grid item xs={6}>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <LFDatePickerInput name='dataInic' control={control} label={planosAplicaveisMsgs.EDIT.LABEL.DATA_INIC}/>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}>
              <LFSubmitButton size="large" noStyle={true} style={{padding: '1rem', fontSize: '0.875rem', lineHeight: '1.2rem'}}
                              isLoading={isPending}>
                {planosAplicaveisMsgs.BUTTON.SIMULA}
              </LFSubmitButton>
            </Grid>
          </Grid>
        </Form>
      </FormContext>
    </div>
  )
}

export default PlanosAplicaveisForm
