import React, { useState } from "react"
import { ShowDateTime } from "../../Shared/Show/ShowDateTime"
import { errorColorDark } from "../../../../constants/appTheme"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "../../../Svg-Icons/DeleteIcon"
import { useAsync } from "react-async"
import CustomDialog from "../../Shared/Dialog/CustomDialog"
import { AppMsgs } from "../../../../constants/appMsgs"
import Typography from "@material-ui/core/Typography"
import PDFIcon from "../../../Svg-Icons/PDFIcon"
import List from "@material-ui/core/List"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import { repoMsgs } from "../repoMsgs"
import { pdfMsgs } from "./pdfMsgs"
import PropTypes from "prop-types"
import Button from "@material-ui/core/Button"

const PDFGridList = ({getRepoHrefFn, repo, mayEdit, repoList, handleDeleteFn, showList}) => {
  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false)
  const [confirmationItem, setConfirmationItem] = useState({})

  const {run} = useAsync({
    deferFn: handleDeleteFn,
    onResolve: (deletedData) => {
      showList()
    }
  })

  const handleRepoItemDelete = (pdf) => {
    setConfirmationDialogVisible(true)
    setConfirmationItem(pdf)
  }

  const handleCancel = () => {
    // feito no CustomDialog
    // setConfirmationDialogVisible(false)
    setConfirmationItem({})
  }

  return (!!repoList.length)
    ? (
      <div>
        <List dense={true}>
          {repoList.map((pdf) => {
            return (
              <ListItem key={pdf.id}>
                <ListItemIcon style={{cursor: 'pointer'}}>
                  <Button target="_blank" href={getRepoHrefFn(repo.id, pdf.id)}>
                    <PDFIcon size={25}/>
                  </Button>
                </ListItemIcon>
                <ListItemText
                  primary={pdf.filename}
                  secondary={<ShowDateTime dateTime={pdf.created_at}/>}
                />
                {mayEdit ? <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete"
                              onClick={() => handleRepoItemDelete(pdf)}>
                    <DeleteIcon color={errorColorDark}/>
                  </IconButton>
                </ListItemSecondaryAction> : null}
              </ListItem>
            )
          })}
        </List>
        <CustomDialog open={confirmationDialogVisible}
                      setOpen={setConfirmationDialogVisible}
                      title={repoMsgs.CONFIRM_DIALOG_TITLE}
                      onConfirm={() => run(confirmationItem.id)}
                      onCancel={() => handleCancel()}
                      msgsConfirm={repoMsgs.YES}
                      msgsCancel={repoMsgs.NO}>
          {pdfMsgs.CONFIRM_DIALOG_CONTENT(confirmationItem)}
        </CustomDialog>
      </div>
    )
    : (
      <Typography component="h1" variant="h6" color="inherit" noWrap>
        {AppMsgs.DEFAULT_VALUES.TABLE.EMPTY_TABLE_MSG}
      </Typography>
    )
}

PDFGridList.propTypes = {
  getRepoHrefFn: PropTypes.func.isRequired,
  repo: PropTypes.object.isRequired,
  mayEdit: PropTypes.bool.isRequired,
  repoList: PropTypes.array.isRequired,
  handleDeleteFn: PropTypes.func.isRequired,
}

export { PDFGridList }
