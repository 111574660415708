import { api, apiCUD, getRequest } from "../../api/api"

const planoAplicaveUrl = 'planosAplicaveis'

async function readPlanosAplicaveis({id}) {
  const request = getRequest({URLname: planoAplicaveUrl, id}).GET()
  return api(request).then(resp => resp.data.data)
}

async function simulaPlanoToEquip([data]) {
  const request = getRequest({URLname: 'plano'}).POST(`${data.plano_id}/equipamento/${data.equip_id}/simula`)
  return apiCUD(request, data)
}

async function assocPlanoToEquip([data]) {
  const request = getRequest({URLname: 'plano'}).POST(`${data.plano_id}/equipamento/${data.equip_id}/associa`)
  return apiCUD(request, data)
}


export { readPlanosAplicaveis, simulaPlanoToEquip, assocPlanoToEquip }
