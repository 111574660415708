import React from "react"
import { deleteMarca, marcasURLname, readMarcasTable } from "./apiMarcas"
import BaseTableListWithRoutes from "../../components/SharedComponents/Shared/BaseTableListWithRoutes"
import marcasMsgs from "./msgs"
import Avatar from "@material-ui/core/Avatar"
import { avatarStyles } from "../../constants/appTheme"

const columns = [{
  Header: () => null,
  id: 'Marcas',
  columns: [
    {
      Header: marcasMsgs.TABLE.HEADER.MARCA,
      accessor: "marca"
    },
    {
      Header: marcasMsgs.TABLE.HEADER.LOGO,
      accessor: "logo",
      Cell: ({row}) => {
        const classes = avatarStyles()
        return <Avatar src={row.original.logo}
                       variant="square"
                       classes={{img: classes.img}}
                       alt={row.original.marca}/>
      },
      disableSortBy: true
    },
    {
      Header: marcasMsgs.TABLE.HEADER.MODELOS_COUNT,
      accessor: "modelos_count",
      disableSortBy: true
    },
    {
      Header: marcasMsgs.TABLE.HEADER.TECNICOS_MARCA_COUNT,
      accessor: "tecnicos_marca_count",
      disableSortBy: true
    },
    {
      Header: marcasMsgs.TABLE.HEADER.EQUIPAMENTOS_CLI_COUNT,
      accessor: "equipamentos_cli_count",
      disableSortBy: true
    }
  ]
}]

function Marcas() {
  return (
    <BaseTableListWithRoutes
      addButtonDesc={marcasMsgs.CREATE_LABEL}
      route={marcasURLname}
      columns={columns}
      deleteFn={deleteMarca}
      readFn={readMarcasTable}
    />
  )
}

export default Marcas
