import React from "react"
import { useAsync } from "react-async"
import { FormContext, useForm } from "react-hook-form"
import { Form } from "../../../components/SharedStyles/SharedStyles"
import Grid from "@material-ui/core/Grid"
import LFInput from "../../../components/LFInputs/LFInput"
import { AppMsgs } from "../../../constants/appMsgs"
import LFSubmitButton from "../../../components/SharedComponents/Shared/LFButtons/LFSubmitButton"
import * as Yup from "yup"
import { emailReqValidation, requiredValidation } from "../../../constants/appValidation"
import { LFEmailInput } from "../../../components/LFInputs/LFEmailInput"
import { LFPasswordInput } from "../../../components/LFInputs/LFPasswordInput"
import { LFSwitchInput } from "../../../components/LFInputs/LFSwitchInput"
import LFImageInput from "../../../components/LFInputs/ImageUploader/LFImageInput"
import { NO_IMAGE } from "../../../constants/appConst"
import FormHelperText from "@material-ui/core/FormHelperText"
import msgsUsers from "./msgsUsers"


const validationSchema = Yup.object().shape({
  name: requiredValidation(AppMsgs.ENTIDADES.EDIT.LABEL.NOME),
  email: emailReqValidation(AppMsgs.ENTIDADES.EDIT.LABEL.EMAIL),
  password: requiredValidation(AppMsgs.DEFAULT_VALUES.INPUT.PASS_LBL)
})

function UserForm({user, method, setUser}) {
  const {isPending, run} = useAsync({
    deferFn: method,
    onResolve: (newUser) => {
      setUser(newUser)
      reset(newUser)
    }
  })

  const methods = useForm({
    validationSchema: validationSchema,
  })

  const {handleSubmit, formState, control, reset} = methods

  const onSubmit = (values) => {
    run({...user, ...values, ...{isContaAcessoActiva: false}})
  }

  return (
    <FormContext {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} justify="center">
          {/*  ----------------------------------------- AVATAR -----------------------------------------------------*/}
          <Grid item xs={12} sm={6}>
            <LFImageInput image={user ? user.avatar : NO_IMAGE} control={control} name="avatar"/>
          </Grid>
          {/*  -------------------------------------- MAIL_ON_LOGIN -------------------------------------------------*/}
          <Grid item xs={12} sm={6}>
            <FormHelperText>{AppMsgs.DEFAULT_VALUES.SWITCH_TITLE}</FormHelperText>
            <LFSwitchInput control={control} name='mustReceiveMailOnLogin'
                           label={msgsUsers.EDIT.LABEL.MUST_RECEIVE_EMAIL}/>
          </Grid>
          {/*------------------------------------------- NOME -------------------------------------------------------*/}
          <Grid item xs={12} sm={6}>
            <LFInput label={AppMsgs.ENTIDADES.EDIT.LABEL.NOME} name='name'/>
          </Grid>
          {/*------------------------------------------ EMAIL -------------------------------------------------------*/}
          <Grid item xs={12} sm={6}>
            <LFEmailInput label={AppMsgs.ENTIDADES.EDIT.LABEL.EMAIL} name='email'/>
          </Grid>
          {/*-------------------------------------- PASSWORD --------------------------------------------------------*/}
          <Grid item xs={12}>
            <LFPasswordInput name='password' label={AppMsgs.DEFAULT_VALUES.INPUT.PASS_LBL}/>
          </Grid>
        </Grid>
        <LFSubmitButton isLoading={isPending} dirty={!formState.dirty}>
          {user ? msgsUsers.BUTTON.EDIT_USER : msgsUsers.BUTTON.NEW_USER}
        </LFSubmitButton>
      </Form>
    </FormContext>
  )
}


export default UserForm
