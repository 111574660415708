import React, { useContext } from "react"
import LFTable from "../../../components/LFTable/LFTable"
import { deleteMarcaModelo, readMarcaModelosTable } from "./apiMarcaModelos"
import { RedirectState } from "./Modelos"
import marcaModelosMsgs from "./msgs"

const columns = [{
  Header: () => null,
  id: 'Modelos',
  columns: [
    {
      Header: marcaModelosMsgs.TABLE.HEADER.MODELO,
      accessor: "modelo"
    },
    {
      Header: marcaModelosMsgs.TABLE.HEADER.TIPOS_EQUIP,
      accessor: "tipo_equip_nome",
    },
    {
      Header: marcaModelosMsgs.TABLE.HEADER.PROCEDIMENTO,
      accessor: "procedimento_nome",
      Cell: ({row}) => {
        return row.original.procedimento_id
          ? <span>{row.original.procedimento_nome}</span>
          : null
      },
    },
    {
      Header: marcaModelosMsgs.TABLE.HEADER.EQUIPAMENTOS_CLI_COUNT,
      accessor: "equipamentos_cli_count",
    }
  ]

}]

function ModeloList({marcaID}) {
  const {create, update} = useContext(RedirectState)

  const handlerCreate = () => create()

  const handlerUpdate = (row) => update({data: row})

  return (
    <LFTable
      columns={columns}
      deleteFn={(id) => deleteMarcaModelo(id, marcaID)}
      requestFn={(props) => readMarcaModelosTable(props, marcaID)}
      addButtonDesc={marcaModelosMsgs.NEW_LABEL}
      showAddButton={true}
      showEditButton={true}
      showDeleteButton={true}
      handlerCreate={handlerCreate}
      handlerUpdate={handlerUpdate}
    />
  )
}

export default ModeloList
