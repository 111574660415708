import React from "react"
import { ListRepoGest } from "../../../../components/SharedComponents/ListRepo/ListRepoGest"
import { createPDFProcedimento, deletePDFProcedimento, readPDFsProcedimento } from "../apiPDFsImgsProcedimento"
import { PDFGridList } from "../../../../components/SharedComponents/ListRepo/ListPDF/PDFGridList"
import { PDFEdit } from "../../../../components/SharedComponents/ListRepo/ListPDF/PDFEdit"
import { pdfMsgs } from "../../../../components/SharedComponents/ListRepo/ListPDF/pdfMsgs"
import { baseURLPDF } from "../../../../api/api"
import { procedimentosURL } from "../apiProcedimento"

function PDFsProcedimento({proc, reload}) {
  const getRepoHrefFn = (procID, pdfID) => (`${baseURLPDF}/gpm/${procedimentosURL}/${procID}/pdfs/${pdfID}`)

  /*<div style={{background: '#FC0', width: '100%', minHeight: '50px'}}></div>*/
  return (
    <ListRepoGest
      repo={proc}
      repoKey={'pdf_id'}
      repoItems={proc.pdfs ? proc.pdfs.data : []}
      FKfieldname={'proc_id'}
      createRepoFn={createPDFProcedimento}
      deleteRepoFn={deletePDFProcedimento}
      readReposFn={readPDFsProcedimento}
      ListComponent={PDFGridList}
      getRepoHrefFn={getRepoHrefFn}
      EditComponent={PDFEdit}
      mayEdit={true}
      btAddTitle={pdfMsgs.NEW_LABEL}
      reload={reload}
    />
  )
}

export { PDFsProcedimento }
