import React, { useState } from "react"
import { Redirect, useHistory, useParams } from "react-router"
import EquipForm from "./EquipForm"
import { createEquip, equipUrl } from "./apiEquips"
import Title from "../../../components/SharedComponents/Shared/Title"
import Async from "react-async"
import LinearProgress from "@material-ui/core/LinearProgress"
import ErrorMsg from "../../../components/SharedComponents/Shared/Error/ErrorMsg"
import { readCliente } from "../../Entidades/Clientes/apiClientes"
import { EntityOpenDialogButton } from "../../../components/SharedComponents/Shared/Dialog/EntityOpenDialogButton"
import { TableCliente } from "../../../components/SharedComponents/Shared/Tables/TableCliente"
import msgsClientes from "../../Entidades/Clientes/msgsClientes"
import { getRequest } from "../../../api/api"
import msgsEquips from "./msgsEquips"

function EquipCreate() {

  let {id} = useParams()

  const [equip, setEquip] = useState(null)

  const history = useHistory()
  const handleBack = () => history.push(getRequest({URLname: equipUrl}).paginatePath())

  if (equip)
    return <Redirect to={getRequest({URLname: equipUrl, id: equip.id}).gotoItemPath()}/>

  return (
    <React.Fragment>
      <Title handleBack={handleBack}>{msgsEquips.BACK_LABEL}</Title>
      <Async promiseFn={readCliente} id={id}>
        <Async.Pending><LinearProgress color="secondary"/></Async.Pending>
        <Async.Fulfilled>
          {(cliente) => {
            return (
              <div>
                <EntityOpenDialogButton component={<TableCliente data={cliente} showLink/>}
                                        btTitle={msgsClientes.DIALOG_BUTTON} dialogTitle={cliente.nome}/>
                <EquipForm equip={equip} cliente={cliente} method={createEquip} setEquip={setEquip}/>
              </div>
            )
          }}
        </Async.Fulfilled>
        <Async.Rejected>{error => <ErrorMsg>{error}</ErrorMsg>}</Async.Rejected>
      </Async>
    </React.Fragment>
  )
}

export default EquipCreate
