const msgsTecnicos = {
  LABEL: "Técnicos",
  UPDATE_LABEL: "Editar Técnico",
  CREATE_LABEL: "Criar Técnico",
  BACK_LABEL: "Voltar aos Técnicos",
  DIALOG_BUTTON: "Ver Técnico",
  OPEN_BUTTON: "Abrir Técnico",
  TAB_LABEL: "Técnico",
  TABLE: {
    ADD_BUTTON: "Novo Técnico",
    HEADER: {
      TIPO_CAT: "Tipo Técnico",
      AVAL_IMM: "Avaliação IMM",
      PATS_COUNT: "Pats",
      PATS_NAO_CONCLUIDAS_COUNT: "Pats NC",
      MARCAS_TECNICO_COUNT: "Marcas",
      LOCS_TECNICO_COUNT: "Locais",
    }
  },
  EDIT: {
    LABEL: {
      AVATAR: "Avatar",
      TIPO_CAT: "Tipo de Técnico",
      AVAL_IMM: "Avaliação IMM",
      USER: "Utilizador"
    }
  },
  BUTTON: {
    EDIT_TECNICO: "Editar Técnico",
    NEW_TECNICO: "Adicionar Técnico",
  }
}
export default msgsTecnicos
