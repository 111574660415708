import React, { useContext } from "react"
import planosAplicaveisMsgs from "./msgsPlanosAplicaveis"
import Title from "../../components/SharedComponents/Shared/Title"
import { RedirectState } from "./PlanosAplicaveis"
import PlanosAplicaveisForm from "./partials/PlanosAplicaveisForm"
import { simulaPlanoToEquip } from "./apiPlanosAplicaveis"


function PlanosAplicaveisSimula({equipID, state}) {
  const {list} = useContext(RedirectState)
  const handleBack = () => list()

  return (
    <React.Fragment>
      <Title handleBack={handleBack}>{planosAplicaveisMsgs.BACK_LABEL}</Title>
      <PlanosAplicaveisForm method={simulaPlanoToEquip} equipID={equipID} planoID={state.planoID}/>
    </React.Fragment>
  )
}

export default PlanosAplicaveisSimula
