import React from "react"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import PropTypes from "prop-types"
import { StyledTableCell } from "../../../SharedStyles/SharedStyles"

const msgsTablePeriodicidades = {
  PERIODICIDADES: {
    NA: "Não Aplicar",
    D: "Por defeito (derivado de PMP)",
    M: "Mensal",
    T: "Trimestral",
    S: "Semestral",
    A: "Anual"
  },
  TITLE: {
    PERIODICIDADES: "Periodicidades"
  },
  LABEL: {
    MANUTENCAO: "Manutenção",
    AVISOS: "Avisos",
    TEMP_RESPOSTA: "Tempo Resposta",
    HORAS: "Horas",
  }
}

const TablePeriodicidades = ({horasResposta, periodPMP, periodAviso}) => {
  return (
    <TableContainer>
      <Table size="small" aria-label="TablePeriodicidades">
        <TableHead>
          <TableRow>
            <StyledTableCell colSpan="2">{msgsTablePeriodicidades.TITLE.PERIODICIDADES}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key="horasResposta">
            <TableCell>{msgsTablePeriodicidades.LABEL.TEMP_RESPOSTA}</TableCell>
            <TableCell align="right">{`${horasResposta} ${msgsTablePeriodicidades.LABEL.HORAS}`}</TableCell>
          </TableRow>
          <TableRow key="manutenção">
            <TableCell>{msgsTablePeriodicidades.LABEL.MANUTENCAO}</TableCell>
            <TableCell align="right">{msgsTablePeriodicidades.PERIODICIDADES[periodPMP]}</TableCell>
          </TableRow>
          <TableRow key="avisos">
            <TableCell>{msgsTablePeriodicidades.LABEL.AVISOS}</TableCell>
            <TableCell align="right">{msgsTablePeriodicidades.PERIODICIDADES[periodAviso]}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

TablePeriodicidades.defaultValues = {
  horasResposta: "No value",
  periodPMP: "No value",
  periodAviso: "No value"
}

TablePeriodicidades.propTypes = {
  horasResposta: PropTypes.number,
  periodPMP: PropTypes.string,
  periodAviso: PropTypes.string
}

export default TablePeriodicidades
