import React from "react"
import TextField from "@material-ui/core/TextField"
import { AppMsgs } from "../../constants/appMsgs"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from "../Svg-Icons/SearchIcons"
import { textHint } from "../../constants/appTheme"
import PropTypes from "prop-types"
import makeStyles from "@material-ui/core/styles/makeStyles"

const useStyles = makeStyles(theme => ({
  outlinedInput: {fontSize: '14px', color: '#777'}
}))


function LFTableSearchInput({gotoPage, globalFilter, setGlobalFilter}) {
  const classes = useStyles()
  return (
    <TextField
      label={AppMsgs.DEFAULT_VALUES.TABLE.SEARCH}
      variant="outlined"
      size="small"
      value={globalFilter || ''}
      onChange={e => {
        gotoPage(0)
        setGlobalFilter(e.target.value || undefined)
      }}
      InputProps={{
        className: classes.outlinedInput,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color={textHint}/>
          </InputAdornment>
        ),
      }}
    />
  )
}

LFTableSearchInput.propTypes = {
  gotoPage: PropTypes.func.isRequired,
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func.isRequired
}


export default LFTableSearchInput
