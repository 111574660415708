import React from "react"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { StyledTableCell } from "../../../SharedStyles/SharedStyles"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import { Link as RouterLink } from 'react-router-dom'
import { AppMsgs } from "../../../../constants/appMsgs"
import Typography from "@material-ui/core/Typography"
import Fab from "@material-ui/core/Fab"
import PropTypes from "prop-types"
import { getRequest } from "../../../../api/api"
import { clientesUrl } from "../../../../pages/Entidades/Clientes/apiClientes"
import msgsClientes from "../../../../pages/Entidades/Clientes/msgsClientes"
import { tableStyles } from "../../../../constants/appTheme"

function TableCliente({data, showLink}) {
  const classes = tableStyles()
  return ((!!data)
    ? (
      <React.Fragment>
        {showLink &&
        <Fab component={RouterLink} target="_blank" size="small"
             to={getRequest({URLname: clientesUrl, id: data.id}).gotoItemPath()} color="secondary"
             variant="extended" style={{boxShadow: 'none'}}>
          <Typography variant="body2" style={{paddingLeft: '5px'}}
                      display={'inline'}>{msgsClientes.OPEN_BUTTON}</Typography>
        </Fab>}
        <TableContainer>
          <Table className={classes.table} size="small" aria-label="TableCliente">
            {/* ---------------------------- NOME & USERNAME -------------------------------------------------- */}
            <>
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan="3">{AppMsgs.ENTIDADES.EDIT.LABEL.NOME}</StyledTableCell>
                  <StyledTableCell colSpan="1">{AppMsgs.ENTIDADES.EDIT.LABEL.USER}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="nome">
                  <TableCell colSpan="3">{data.nome}</TableCell>
                  <TableCell colSpan="1">{data.username}</TableCell>
                </TableRow>
              </TableBody>
            </>
            {/* ---------------------------- TIPO_CLI & NIF --------------------------------------------------- */}
            <>
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan="2">{msgsClientes.EDIT.LABEL.TIPO_CLI}</StyledTableCell>
                  <StyledTableCell colSpan="2">{AppMsgs.ENTIDADES.EDIT.LABEL.NIF}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan="2">{data.tipoText}</TableCell>
                  <TableCell colSpan="2">{data.nif}</TableCell>
                </TableRow>
              </TableBody>
            </>
            {/* ---------------------------- EMAIL & TLM & TLF ------------------------------------------------ */}
            <>
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan="2">{AppMsgs.ENTIDADES.EDIT.LABEL.EMAIL}</StyledTableCell>
                  <StyledTableCell colSpan="1">{AppMsgs.ENTIDADES.EDIT.LABEL.TLM}</StyledTableCell>
                  <StyledTableCell colSpan="1">{AppMsgs.ENTIDADES.EDIT.LABEL.TLF}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan="2">{data.email}</TableCell>
                  <TableCell colSpan="1">{data.tlm}</TableCell>
                  <TableCell colSpan="1">{data.tlf}</TableCell>
                </TableRow>
              </TableBody>
            </>
            {/* ---------------------------- MORADA & ZIP_CODE ------------------------------------------------ */}
            <>
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan="3">{AppMsgs.ENTIDADES.EDIT.LABEL.MORADA}</StyledTableCell>
                  <StyledTableCell colSpan="1">{msgsClientes.EDIT.LABEL.ZIP_CODE}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan="3">{data.morada}</TableCell>
                  <TableCell colSpan="1">{data.cp4}-{data.cp3}</TableCell>
                </TableRow>
              </TableBody>
            </>
            {/* ------------------------------------ OBS ------------------------------------------------------ */}
            <>
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan="4">{AppMsgs.ENTIDADES.EDIT.LABEL.OBS}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan="4">{data.obs}</TableCell>
                </TableRow>
              </TableBody>
            </>
          </Table>
        </TableContainer>
      </React.Fragment>)
    : null)
}

TableCliente.defaultProps = {
  data: null,
  showLink: false
}

TableCliente.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    user_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    nome: PropTypes.string,
    email: PropTypes.string,
    tlm: PropTypes.string,
    nif: PropTypes.string,
    tipoText: PropTypes.string,
    tlf: PropTypes.string,
    morada: PropTypes.string,
    cp4: PropTypes.string,
    cp3: PropTypes.string,
    obs: PropTypes.string,
  }),
  showLink: PropTypes.bool
}

export { TableCliente }
