import React, { forwardRef } from "react"
import ListItem from "@material-ui/core/ListItem"
import { NavLink, NavLinkProps } from "react-router-dom"
import leftMenubarStyles from "./LeftMenubarStyles"

const LeftMenubarItemComponent = (props) => {
  const {className, onClick, path, children} = props
  const classes = leftMenubarStyles()

  if (!path || typeof path !== 'string') {
    return (
      <ListItem
        button
        className={className}
        children={children}
        onClick={onClick}
      />
    )
  }

  return (
    <ListItem
      button
      className={className}
      children={children}
      component={forwardRef((props: NavLinkProps, ref: any) => <NavLink exact {...props} innerRef={ref}
                                                                        activeClassName={classes.navLinkActive}/>)}
      to={path}
    />
  )
}

export default LeftMenubarItemComponent
