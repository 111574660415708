import moment from "moment-timezone/builds/moment-timezone-with-data"

const format = "DD-MM-YYYY HH:mm:ss"
const formatDay = "DD-MM-YYYY"
const locale = 'pt'
const timezone = 'Europe/Lisbon'

const momentTZ = (strDate) => {
  moment.locale(locale)
  return moment.tz(strDate, format, timezone)
}

const now = () => {
  moment.locale(locale)
  return moment.tz(moment(), format, timezone)
}

const today = () => {
  moment.locale(locale)
  return moment.tz(moment(), formatDay, timezone)
}

export {
  format,
  formatDay,
  now,
  today,
  locale,
  timezone,
  momentTZ,
}
