import React from "react"
import { TableAcoesAvisos } from "../../../../SharedStyles/SharedStyles"
import TableTaxas from "./TableTaxas"
import TableDescontos from "./TableDescontos"
import TableTaxasCliente from "./TableTaxasCliente"
import TableAcao from "./TableAcao"

function ListaAcoes({acoes}) {
  const classes = TableAcoesAvisos()
  return (
    <React.Fragment>
      <h3 className={classes.header}>Ações</h3>
      {
        acoes.map((acao, key) => {
          return (
            <React.Fragment key={key}>
              <TableAcao {...acao.acaoPA}/>
              <TableTaxas {...acao.acaoPA}/>
              <TableTaxasCliente {...acao.acaoPA}/>
              <TableDescontos {...acao.acaoPA}/>
            </React.Fragment>
          )
        })
      }
    </React.Fragment>
  )
}

export default ListaAcoes
