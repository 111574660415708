import React from "react"
import SvgIcon from "./SvgIcon"

const PatStartIcon = (others) => {
  return (
    <SvgIcon viewBox="0 0 512 512" width="512" height="512" {...others}>
      <g>
        <g>
          <path d="M101,253.09c-55.691,0-101,45.309-101,101c0,23.061,7.973,45.596,22.458,63.495c0.026,0.035,0.053,0.07,0.08,0.104
			l7.69,9.856c3.396,4.353,9.681,5.13,14.035,1.732c4.354-3.397,5.13-9.681,1.732-14.036l-7.484-9.592
			c-0.106-0.146-0.217-0.289-0.331-0.43C26.457,390.836,20,372.677,20,354.09c0-44.664,36.337-81,81-81s81,36.336,81,81
			c0,18.788-6.577,37.096-18.521,51.552c-0.255,0.308-0.488,0.628-0.7,0.958l-61.777,79.146l-4.504-5.774
			c-3.398-4.354-9.681-5.131-14.036-1.733c-4.354,3.397-5.13,9.681-1.733,14.036l12.387,15.877c1.896,2.428,4.804,3.848,7.884,3.849
			c0,0,0,0,0.001,0c3.08,0,5.988-1.419,7.883-3.847l70.241-89.989c0.141-0.179,0.273-0.363,0.399-0.549
			c14.5-17.911,22.476-40.43,22.476-63.526C202,298.398,156.691,253.09,101,253.09z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M72.18,448.802l-0.326-0.417c-3.399-4.352-9.685-5.124-14.036-1.723c-4.353,3.4-5.124,9.685-1.724,14.037l0.326,0.417
			c1.972,2.524,4.914,3.844,7.887,3.844c2.152,0,4.322-0.692,6.149-2.121C74.809,459.439,75.58,453.154,72.18,448.802z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M101,297.542c-31.981,0-58,26.019-58,58c0,31.981,26.019,58,58,58s58-26.019,58-58
			C159,323.561,132.981,297.542,101,297.542z M101,393.542c-20.953,0-38-17.047-38-38s17.047-38,38-38s38,17.047,38,38
			S121.953,393.542,101,393.542z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M507.001,56.766l-96-55.426c-3.094-1.787-6.906-1.786-10,0c-3.094,1.786-5,5.087-5,8.66v171H379.44
			c-5.522,0-10,4.477-10,10s4.478,10,10,10h53.121c5.522,0,10-4.477,10-10s-4.478-10-10-10H416v-54.375l91-52.539
			c3.094-1.786,5-5.087,5-8.66C512,61.853,510.095,58.552,507.001,56.766z M416.001,103.531V88.52V27.321l66,38.105L416.001,103.531
			z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M318.335,92.45h-2.5c-5.522,0-10,4.477-10,10s4.478,10,10,10h2.5c5.522,0,10-4.477,10-10S323.856,92.45,318.335,92.45z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M211.023,243.152c-1.399-0.423-2.802-0.899-4.17-1.414c-5.173-1.948-10.937,0.666-12.883,5.834
			c-1.946,5.168,0.665,10.936,5.834,12.883c1.78,0.67,3.607,1.29,5.428,1.841c0.966,0.292,1.94,0.431,2.899,0.431
			c4.293,0,8.262-2.787,9.568-7.108C219.299,250.333,216.309,244.751,211.023,243.152z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M229.465,101.81c-0.771-5.468-5.832-9.276-11.3-8.504c-1.881,0.266-3.779,0.598-5.642,0.987
			c-5.405,1.132-8.87,6.431-7.739,11.837c0.987,4.715,5.145,7.953,9.778,7.953c0.679,0,1.368-0.069,2.059-0.214
			c1.433-0.3,2.893-0.556,4.339-0.76C226.429,112.338,230.236,107.278,229.465,101.81z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M175.901,217.838c-0.85-1.196-1.669-2.434-2.434-3.678c-2.893-4.703-9.056-6.169-13.756-3.277
			c-4.704,2.894-6.172,9.053-3.278,13.757c0.995,1.617,2.059,3.225,3.162,4.778c1.95,2.746,5.032,4.211,8.162,4.211
			c2.002,0,4.024-0.6,5.781-1.848C178.041,228.583,179.099,222.341,175.901,217.838z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M182.192,118.632c-3.966-3.841-10.297-3.742-14.14,0.226c-1.329,1.372-2.624,2.8-3.848,4.243
			c-3.571,4.211-3.052,10.522,1.16,14.094c1.88,1.594,4.177,2.373,6.463,2.373c2.836,0,5.653-1.2,7.632-3.532
			c0.939-1.108,1.936-2.206,2.959-3.263C186.261,128.806,186.16,122.475,182.192,118.632z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M155.142,160.901c-5.469-0.588-10.423,3.375-11.018,8.865c-0.205,1.891-0.35,3.809-0.431,5.703
			c-0.234,5.519,4.048,10.182,9.567,10.417c0.145,0.006,0.289,0.009,0.433,0.009c5.326,0,9.755-4.202,9.983-9.575
			c0.062-1.46,0.174-2.941,0.332-4.4C164.602,166.429,160.633,161.496,155.142,160.901z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M270.213,92.45h-5.069c-5.522,0-10,4.477-10,10s4.478,10,10,10h5.069c5.522,0,10-4.477,10-10S275.735,92.45,270.213,92.45
			z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M256,246.001h-2.5c-5.522,0-10,4.477-10,10c0,5.523,4.478,10,10,10h2.5c5.522,0,10-4.478,10-10
			C266,250.478,261.522,246.001,256,246.001z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M219.5,399.551H217c-5.522,0-10,4.477-10,10s4.478,10,10,10h2.5c5.522,0,10-4.477,10-10S225.022,399.551,219.5,399.551z"
          />
        </g>
      </g>
      <g>
        <g>
          <path d="M318.403,399.551h-4.945c-5.522,0-10,4.477-10,10s4.478,10,10,10h4.945c5.522,0,10-4.477,10-10
			S323.926,399.551,318.403,399.551z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M406.727,360.02c-4.875-2.602-10.931-0.757-13.529,4.115c-0.674,1.263-1.396,2.516-2.144,3.726
			c-2.907,4.696-1.456,10.859,3.24,13.766c1.64,1.015,3.457,1.499,5.253,1.499c3.349,0,6.621-1.682,8.513-4.739
			c0.973-1.571,1.908-3.198,2.782-4.836C413.442,368.678,411.6,362.62,406.727,360.02z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M268.952,399.551h-4.945c-5.522,0-10,4.477-10,10s4.478,10,10,10h4.945c5.522,0,10-4.477,10-10
			S274.475,399.551,268.952,399.551z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M420.651,324.948c-0.165-1.848-0.392-3.715-0.676-5.548c-0.845-5.458-5.963-9.196-11.413-8.351
			c-5.458,0.846-9.196,5.956-8.351,11.413c0.218,1.41,0.394,2.844,0.521,4.265c0.464,5.198,4.827,9.111,9.948,9.111
			c0.298,0,0.599-0.013,0.901-0.04C417.082,335.306,421.144,330.449,420.651,324.948z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M398.633,274.624c-1.244-1.377-2.547-2.729-3.871-4.019c-3.958-3.854-10.289-3.77-14.142,0.187
			c-3.854,3.957-3.77,10.288,0.187,14.141c1.021,0.995,2.027,2.039,2.988,3.102c1.975,2.185,4.692,3.295,7.422,3.295
			c2.39-0.001,4.789-0.853,6.702-2.582C402.017,285.045,402.336,278.721,398.633,274.624z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M350.825,247.588c-1.818-0.353-3.675-0.65-5.516-0.886c-5.483-0.704-10.487,3.174-11.187,8.652
			c-0.7,5.478,3.174,10.487,8.651,11.187c1.418,0.181,2.846,0.41,4.244,0.682c0.643,0.125,1.283,0.185,1.914,0.185
			c4.694-0.001,8.882-3.321,9.808-8.099C359.79,253.887,356.247,248.64,350.825,247.588z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M375.848,398.071c-2.348-5-8.302-7.152-13.303-4.802c-1.286,0.604-2.614,1.175-3.947,1.698
			c-5.142,2.017-7.675,7.819-5.658,12.961c1.546,3.943,5.318,6.351,9.313,6.351c1.214,0,2.449-0.223,3.648-0.693
			c1.735-0.681,3.467-1.425,5.145-2.213C376.044,409.026,378.194,403.071,375.848,398.071z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M299.488,246.001h-2.5c-5.522,0-10,4.477-10,10c0,5.523,4.478,10,10,10h2.5c5.523,0,10-4.478,10-10
			C309.488,250.478,305.01,246.001,299.488,246.001z"/>
        </g>
      </g>
    </SvgIcon>
  )
}

export default PatStartIcon
