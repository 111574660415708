import React from "react"
import SvgIcon from "./SvgIcon"

const EquipamentoIcon = (others) => {
  return (
    <SvgIcon viewBox="0 0 512 512" {...others}>
      <g transform="translate(1 1)">
        <g>
          <g>
            <path d="M417.133-1H92.867c-14.114,0-25.6,11.486-25.6,25.6v426.667c0,14.114,11.486,25.6,25.6,25.6v17.067
				c0,9.412,7.654,17.067,17.067,17.067H127c9.412,0,17.067-7.654,17.067-17.067v-17.067h221.867v17.067
				c0,9.412,7.654,17.067,17.067,17.067h17.067c9.412,0,17.067-7.654,17.067-17.067v-17.067c14.114,0,25.6-11.486,25.6-25.6V24.6
				C442.733,10.486,431.247-1,417.133-1z M92.867,16.067h324.267c4.71,0,8.533,3.831,8.533,8.533v256H84.333v-256
				C84.333,19.898,88.165,16.067,92.867,16.067z M135.533,459.8h-7.322l13.807-103.535c0.563-4.215,4.198-7.398,8.457-7.398h209.05
				c4.258,0,7.893,3.183,8.457,7.398L381.789,459.8h-7.322H135.533z M127,493.933h-17.067v-17.067H127V493.933z M400.067,493.933
				H383v-17.067h17.067V493.933z M417.133,459.8H408.6h-9.59l-14.107-105.788c-1.698-12.663-12.604-22.212-25.378-22.212h-209.05
				c-12.783,0-23.689,9.549-25.378,22.212L110.989,459.8H101.4h-8.533c-4.702,0-8.533-3.823-8.533-8.533v-153.6h341.333v153.6
				C425.667,455.977,421.844,459.8,417.133,459.8z"/>
            <path d="M203.8,375.546v-1.08c0-4.719-3.823-8.533-8.533-8.533s-8.533,3.814-8.533,8.533v1.08
				c-14.707,3.798-25.6,17.175-25.6,33.054c0,18.825,15.309,34.133,34.133,34.133S229.4,427.425,229.4,408.6
				C229.4,392.721,218.507,379.344,203.8,375.546z M195.267,425.667c-9.412,0-17.067-7.654-17.067-17.067
				c0-6.419,3.564-12.015,8.814-14.929c0.95,3.68,4.281,6.396,8.253,6.396c3.972,0,7.303-2.716,8.253-6.396
				c5.25,2.913,8.814,8.51,8.814,14.929C212.333,418.012,204.679,425.667,195.267,425.667z"/>
            <path d="M323.267,375.546v-1.08c0-4.719-3.814-8.533-8.533-8.533s-8.533,3.814-8.533,8.533v1.08
				c-14.707,3.798-25.6,17.175-25.6,33.054c0,18.825,15.309,34.133,34.133,34.133c18.825,0,34.133-15.309,34.133-34.133
				C348.867,392.721,337.974,379.344,323.267,375.546z M314.733,425.667c-9.412,0-17.067-7.654-17.067-17.067
				c0-6.419,3.564-12.015,8.813-14.929c0.948,3.679,4.274,6.395,8.253,6.395s7.305-2.716,8.253-6.395
				c5.25,2.914,8.813,8.51,8.813,14.929C331.8,418.012,324.146,425.667,314.733,425.667z"/>
            <path d="M251.134,246.198c1.27,0.172,2.558,0.269,3.866,0.269c0.064,0,0.128-0.005,0.192-0.006
				c0.033,0.001,0.065,0.005,0.098,0.006c0.099-0.003,0.195-0.014,0.294-0.018c0.773-0.016,1.54-0.061,2.301-0.137
				c37.439-2.654,66.558-34.57,65.382-72.123c0-34.082-55.287-104.346-61.602-112.239c-3.243-4.053-10.095-4.045-13.338,0
				c-6.306,7.902-61.594,78.165-61.594,111.983C185.581,211.238,213.901,242.817,251.134,246.198z M237.908,206.479
				c0.324-3.695,5.222-16.23,17.067-37.043c11.204,19.721,16.725,32.853,17.092,36.736c0,12.156-7.299,22.495-16.124,23.188
				c-0.078,0.004-0.156,0.011-0.234,0.014c-0.175,0.01-0.351,0.016-0.528,0.019c-0.6-0.022-1.194-0.065-1.789-0.107
				C244.862,228.122,237.932,217.847,237.908,206.479z M203.8,174.181c0-21.018,32.486-68.284,51.2-92.996
				c18.714,24.713,51.2,71.987,51.2,93.269c0.518,16.637-6.709,31.765-18.305,41.924c0.784-3.474,1.208-7.138,1.204-10.939
				c-1.186-13.67-19.157-44.595-26.829-57.259c-1.536-2.551-4.301-4.113-7.287-4.113c-2.987,0-5.751,1.553-7.296,4.105
				c-7.654,12.604-25.591,43.383-26.82,57.549c0,3.618,0.402,7.124,1.146,10.466C210.426,205.998,203.285,190.874,203.8,174.181z"/>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

export default EquipamentoIcon
