import React from "react"
import { useHistory, useParams } from "react-router"
import Title from "../../../components/SharedComponents/Shared/Title"
import PlanoCreate from "./PlanoCreate"
import PlanoUpdate from "./PlanoUpdate"
import { getRequest } from "../../../api/api"
import { planosUrl } from "./apiPlanos"
import msgsPlanos from "./msgsPlanos"


function PlanoView() {
  let {id} = useParams()

  const history = useHistory()
  const handleBack = () => history.push(getRequest({URLname: planosUrl}).paginatePath())

  return (
    <React.Fragment>
      <Title handleBack={handleBack}>{msgsPlanos.BACK_LABEL}</Title>
      {(!!parseInt(id, 10)) ?
        <PlanoUpdate id={id}/> :
        <PlanoCreate/>
      }
    </React.Fragment>
  )
}

export default PlanoView
