import { Badge, StyledTableCell, TableMarginTop } from "../../../SharedStyles/SharedStyles"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import React from "react"
import Typography from "@material-ui/core/Typography"
import Fab from "@material-ui/core/Fab"

const planomsgs = {
  TABLE: {
    LABEL: {
      INFO: "Plano base",
      DESIGNACAO: "Designação",
      AN: "Área de Negócio",
      HORAS_RESP: "Resposta (horas)"
    }
  }
}

function TablePlano({refAN, designacao, rev, horasResposta}) {
  const classes = TableMarginTop()
  return (
    <TableContainer>
      <Table className={classes.table} size="small" aria-label="TablePlanoBase">
        <TableHead>
          <TableRow>
            <StyledTableCell colSpan="2">{planomsgs.TABLE.LABEL.INFO}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key="designacao">
            <TableCell>
              {planomsgs.TABLE.LABEL.DESIGNACAO}
              <Fab size="small" color="secondary" variant="extended" style={{boxShadow: 'none', marginLeft: '5px'}}>
                <Typography variant="body2" style={{fontSize: '80%'}}
                            display={'inline'}>{rev}</Typography>
              </Fab>
            </TableCell>
            <TableCell align="right">{designacao}</TableCell>
          </TableRow>
          <TableRow key="refAN">
            <TableCell>{planomsgs.TABLE.LABEL.AN}</TableCell>
            <TableCell align="right">{refAN}</TableCell>
          </TableRow>
          <TableRow key="dataFim">
            <TableCell>{planomsgs.TABLE.LABEL.HORAS_RESP}</TableCell>
            <TableCell align="right"><Badge>{horasResposta}</Badge></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TablePlano
