import React from "react"
import styled from "@material-ui/core/styles/styled"
import Avatar from "@material-ui/core/Avatar"
import { primaryColor, primaryColorLight, secondaryColor } from "../../constants/appTheme"
import Button from "@material-ui/core/Button"
import withStyles from "@material-ui/core/styles/withStyles"
import { createStyles, fade } from "@material-ui/core"
import TableCell from "@material-ui/core/TableCell"
import makeStyles from "@material-ui/core/styles/makeStyles"

const Form = styled('form')(({theme}) => ({
  width: '100%', // Fix IE 11 issue.
  marginTop: theme.spacing(2),
}))

const H2Slim = styled('h2')(({theme}) => ({
  fontWeight: 300, // Fix IE 11 issue.
  margin: 0,
  display: 'inline'
}))

const Badge = styled(Avatar)(({theme, props}) => ({
  fontSize: theme.typography.fontSize,
  backgroundColor: (!!props ? theme.palette.primary.main : theme.palette.secondary.main),
  width: theme.spacing(3),
  height: theme.spacing(3),
  display: 'inline-flex'
}))

const BadgeVariable = styled(Avatar)(({theme, props}) => ({
  fontSize: theme.typography.fontSize,
  backgroundColor:
    (props > 0 && props < 5) ?
      primaryColorLight :
      (props > 5 && props < 10) ?
        primaryColor :
        props > 10 ?
          secondaryColor :
          'none',
  color: 'black',
  width: theme.spacing(3),
  height: theme.spacing(3),
}))

const LinkButton = styled(Button)(({theme, props}) => ({
  textTransform: 'none',
  margin: theme.spacing(1),
}))

const StyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      backgroundColor: fade(theme.palette.common.black, 0.1),
    },
  }),
)(props => <TableCell {...props}/>)

const TableAcoesAvisos = makeStyles(theme => ({
  header: {
    background: fade(theme.palette.common.black, 0.1),
    margin: '0',
    padding: '14px',
    textAlign: 'center',
    lineHeight: '16px',
    fontSize: '22px',
    fontWeight: '500'
  },
  item: {
    marginTop: '10px',
    fontSize: '14px',
    fontWeight: '300',
    '& span': {display: 'block', margin: '1px 0'},
  }
}))

const TableMarginTop = makeStyles((theme) =>
  createStyles({
    table: {
      marginTop: theme.spacing(3),
    }
  })
)

const Label = styled('label')({
  cursor: 'pointer',
  color: primaryColor,
  fontSize: '1rem',
  fontWeight: '400',
})
const Text = styled('div')({
  textAlign: 'center',
  marginTop: '20px',
  marginBottom: '20px',
})


export {
  Form,
  Badge,
  BadgeVariable,
  LinkButton,
  StyledTableCell,
  H2Slim,
  TableAcoesAvisos,
  TableMarginTop,
  Text,
  Label
}
