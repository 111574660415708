import React, { useState } from "react"
import { Redirect } from "react-router"
import { createPlano, planosUrl } from "./apiPlanos"
import PlanoForm from "./PlanoForm"
import { getRequest } from "../../../api/api"


function PlanoCreate({id}) {
  const [plano, setPlano] = useState(null)

  if (plano)
    return <Redirect to={getRequest({URLname: planosUrl, id: plano.id}).gotoItemPath()}/>

  return (
    <React.Fragment>
      <PlanoForm plano={plano} method={createPlano} setPlano={setPlano}/>
    </React.Fragment>
  )
}

export default PlanoCreate
