import React from "react"
import Async from "react-async"
import LinearProgress from "@material-ui/core/LinearProgress"
import ErrorMsg from "../../../components/SharedComponents/Shared/Error/ErrorMsg"
import { readPlano, updatePlano } from "./apiPlanos"
import PlanoForm from "./PlanoForm"

function PlanoUpdate({id}) {
  return (
    <Async promiseFn={readPlano} id={id}>
      <Async.Pending><LinearProgress color="secondary"/></Async.Pending>
      <Async.Fulfilled>
        {(data, {setData}) => {
          // dispatch({type: 'C', data})
          return (
            <React.Fragment>
              <PlanoForm plano={data} method={updatePlano} setPlano={setData}/>
            </React.Fragment>
          )
        }}
      </Async.Fulfilled>
      <Async.Rejected>{error => <ErrorMsg>{error}</ErrorMsg>}</Async.Rejected>
    </Async>
  )
}

export default PlanoUpdate
