import React, { useState } from "react"
import patInfoStatesMsgs from "../patInfoStatesMsgs"
import { Form } from "../../../../../components/SharedStyles/SharedStyles"
import Grid from "@material-ui/core/Grid"
import { FormContext, useForm } from "react-hook-form"
import { useAsync } from "react-async"
import CircularProgress from "@material-ui/core/CircularProgress"
import Button from "@material-ui/core/Button"
import LeftMarginSpan from "../../../../../components/SharedStyles/LeftMarginSpan"
import "moment/locale/pt"
import { removerPat } from "../apiInfoStates"
import CustomDialog from "../../../../../components/SharedComponents/Shared/Dialog/CustomDialog"
import { repoMsgs } from "../../../../../components/SharedComponents/ListRepo/repoMsgs"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { errorColor, whiteColor } from "../../../../../constants/appTheme"


const useStyles = makeStyles(theme => ({
  btRem: {
    color: whiteColor,
    background: errorColor,
    fontWeight: 'bold',
    padding: '1rem', fontSize: '0.875rem', lineHeight: '1.2rem'
  },
}))

const RemoverPATForm = ({repo, handleBack}) => {
  const classes = useStyles()
  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false)
  const [formData, setFormData] = useState({})

  const {isPending, run} = useAsync({
    deferFn: removerPat,
    onResolve: (resp) => {
      handleBack()
    }
  })

  const methods = useForm({})

  const {handleSubmit} = methods

  const onSubmit = ({...values}) => {
    const convertValues = {
      ...values, ...{
        pat_id: repo.id,
      }
    }
    setFormData({...convertValues})
    setConfirmationDialogVisible(true)
  }

  const handleConfirmedDateTime = () => {
    setConfirmationDialogVisible(false)
    run(formData)
  }

  const handleCancel = () => {
    // feito no CustomDialog
    // setConfirmationDialogVisible(false)
  }

  return (
    <FormContext {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} justify="center">
          {/*---------------------------------------------------------------*/}
          <Grid item xs={12}>
            <Button
              onClick={onSubmit}
              style={{padding: '1rem', fontSize: '0.875rem', lineHeight: '1.2rem'}}
              startIcon={isPending && <CircularProgress color="secondary" size={20}/>}
              className={classes.btRem}
              type="submit"
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              disabled={isPending}>
              <React.Fragment>
                <LeftMarginSpan>{patInfoStatesMsgs.BT_REMOVER}</LeftMarginSpan>
              </React.Fragment>
            </Button>
          </Grid>
        </Grid>
      </Form>
      <CustomDialog open={confirmationDialogVisible}
                    setOpen={setConfirmationDialogVisible}
                    title={repoMsgs.CONFIRM_DIALOG_TITLE}
                    onConfirm={() => handleConfirmedDateTime()}
                    onCancel={() => handleCancel()}
                    msgsConfirm={repoMsgs.YES}
                    msgsCancel={repoMsgs.NO}>
        {patInfoStatesMsgs.CONFIRM_REMOCAO(repo.refCode)}
      </CustomDialog>
    </FormContext>
  )
}

export {
  RemoverPATForm,
}
