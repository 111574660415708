import React from "react"
import SvgIcon from "./SvgIcon"

const LastPageIcon = (others) => {
  return (
    <SvgIcon  {...others}>
      <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"/>
      <path d="M0 0h24v24H0V0z" fill="none"/>
    </SvgIcon>
  )
}

export default LastPageIcon
