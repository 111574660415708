import { api, getRequest } from "../../api/api"
import armazemMsgs from "./armazemMsgs"
import EntradasArmazem from "./entradas/EntradasArmazem"
import SaidasArmazem from "./saidas/SaidasArmazem"
import { tiposequipSelectURLname } from "../Equips/TiposEquip/apiTiposEquip"

const armazem_IN_URLname = 'armazem/in'
const armazem_OUT_URLname = 'armazem/out'

// --------------  Entradas e Saidas Armazem   --------------
const armazem_IN_RouterProps = {
  path: getRequest({URLname: armazem_IN_URLname}).paginatePath(),
  exact: true,
  name: armazemMsgs.LABEL_IN,
  component: EntradasArmazem,
  IconText: '+',
  breadcrumb: () => armazemMsgs.LABEL_IN,
  reqRole: 'R'
}

const armazem_OUT_RouterProps = {
  path: getRequest({URLname: armazem_OUT_URLname}).paginatePath(),
  exact: true,
  name: armazemMsgs.LABEL_OUT,
  component: SaidasArmazem,
  IconText: '-',
  breadcrumb: () => armazemMsgs.LABEL_OUT,
  reqRole: 'R'
}

// --------------  Entradas e Saidas Armazem   --------------

async function read50EntradasArmazem() {
  const request50entradas = getRequest({URLname: armazem_IN_URLname}).GET()
  return api(request50entradas).then(r => r.data.data)
}

async function readAll_TE_Transportadores() {

  const requestTE = getRequest({URLname: tiposequipSelectURLname}).ALL()
  //const requestMarcas = getRequest({URLname: marcasURLname}).REQUEST(`ofte/0?logo=0`)
  const requestTransp = getRequest({URLname: 'transportadores'}).GET()
  return Promise.all([
    api(requestTE),
    //api(requestMarcas),
    api(requestTransp),
  ]).then(([rTE, rTransp]) => ({
    teList: rTE.data.data.map((tipo) => ({index: tipo.id, title: tipo.refAN + ` - ` + tipo.tipo})),
    //marcas: rMarcas.data.data.map((marca) => ({index: marca.id, title: marca.marca})),
    transpList: rTransp.data.data.map((t) => ({index: t.id, title: t.nome})),
  }))
}

export {
  armazem_IN_URLname, armazem_OUT_URLname,
  armazem_IN_RouterProps, armazem_OUT_RouterProps,
  readAll_TE_Transportadores, read50EntradasArmazem,
}
