import React from "react"
import { AppMsgs } from "../../../constants/appMsgs"
import TabsComponent from "../../../components/SharedComponents/Shared/TabsComponent"
import BaseTableListWithRoutes from "../../../components/SharedComponents/Shared/BaseTableListWithRoutes"
import { clientesUrl, deleteCliente, readClientesTable, undeleteCliente } from "./apiClientes"
import LFTable from "../../../components/LFTable/LFTable"
import { Badge } from "../../../components/SharedStyles/SharedStyles"
import EmailIcon from "../../../components/Svg-Icons/EmailIcon"
import SmartPhoneIcon from "../../../components/Svg-Icons/SmartPhoneIcon"
import PhoneIcon from "../../../components/Svg-Icons/PhoneIcon"
import { secondaryColor } from "../../../constants/appTheme"
import LinkWithIcon from "../../../components/SharedComponents/Shared/LinkWithIcon"
import expandColumn from "../../../components/LFTable/ExpandColumn"
import { TableCliente } from "../../../components/SharedComponents/Shared/Tables/TableCliente"
import msgsClientes from "./msgsClientes"


const columns = {
  Header: () => null,
  id: 'Clientes',
  columns: [
    {
      Header: msgsClientes.TABLE.HEADER.TIPO_CLI,
      accessor: "tipocli",
      Cell: ({row}) => (
        <Badge props={row.original.tipocli}>{row.original.tipocliChar}</Badge>
      ),
    },
    {
      Header: AppMsgs.ENTIDADES.TABLE.HEADER.NOME,
      accessor: "nome"
    },
    {
      Header: AppMsgs.ENTIDADES.TABLE.HEADER.CONTACTO,
      accessor: "contacto",
      Cell: ({row}) => (
        <React.Fragment>
          {row.original.email &&
          <LinkWithIcon icon={<EmailIcon size={12} style={{marginRight: '5px'}} color={secondaryColor}/>}
                        href={"mailto:" + row.original.email}
                        desc={row.original.email}
          />}
          {row.original.tlm &&
          <LinkWithIcon icon={<SmartPhoneIcon size={12} style={{marginRight: '5px'}} color={secondaryColor}/>}
                        href={"tel:" + row.original.tlm}
                        desc={row.original.tlm}/>}
          {row.original.tlf &&
          <LinkWithIcon icon={<PhoneIcon size={12} style={{marginRight: '5px'}} color={secondaryColor}/>}
                        href={"tel:" + row.original.tlf}
                        desc={row.original.tlf}
          />}
        </React.Fragment>
      ),
    },
    {
      Header: AppMsgs.ENTIDADES.TABLE.HEADER.EQUIP_COUNT,
      accessor: "equipamentos_cli_count",
      disableSortBy: true
    },
    {
      Header: msgsClientes.TABLE.HEADER.PLANOS_ACTIV_CURSO_COUNT,
      accessor: "planos_ativos_em_curso_count",
      disableSortBy: true
    },
    {
      Header: msgsClientes.TABLE.HEADER.PATS_CURSO_COUNT,
      accessor: "pats_em_curso_count",
      disableSortBy: true
    }
  ]
}


const clientesTabs = (columnsWithExpand, renderRowSubComponent) => [
  {
    label: AppMsgs.DEFAULT_VALUES.ATIVOS_LABEL,
    index: 0,
    component: <BaseTableListWithRoutes
      route={clientesUrl}
      columns={[columns]}
      deleteFn={deleteCliente}
      readFn={readClientesTable}
      addButtonDesc={msgsClientes.TABLE.ADD_BUTTON}/>
  },
  {
    label: AppMsgs.DEFAULT_VALUES.HISTORICO_LABEL,
    index: 1,
    component: <LFTable
      columns={columnsWithExpand}
      requestFn={([props]) => readClientesTable([{trashed: true, ...props}])}
      showAddButton={false}
      showEditButton={false}
      showDeleteButton={false}
      renderRowSubComponent={renderRowSubComponent}
      showUndeleteButton={true}
      unDeleteFn={undeleteCliente}
    />,
  }
]

export const Clientes = () => {

  const renderRowSubComponent = React.useCallback(
    ({row}) => (<TableCliente data={row.original}/>
    ), [])

  const columnsWithExpand = React.useMemo(() => [{...expandColumn}, {...columns}], [])

  return (
    <React.Fragment>
      <TabsComponent renderTabs={clientesTabs(columnsWithExpand, renderRowSubComponent)}/>
    </React.Fragment>
  )
}

export default Clientes
