const avisosAlertasMsgs = {
  LABEL: "Avisos", // e Alertas" no futuro e-sagoGC,
  AVISOS_PA: "Avisos gerados pelo SAT",
  ALERTAS_PESS: "Alertas Pessoais",
  MSG: "Mensagem",
  RELATED: "Relacionados",
  ACTIONS: "Ações",

}

export default avisosAlertasMsgs
