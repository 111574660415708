import React from "react"
import BaseTableListWithRoutes from "../../../components/SharedComponents/Shared/BaseTableListWithRoutes"
import procedimentosMsgs from "./procedimentosMsgs"
import { deleteProcedimento, procedimentosURL, readProcedimentosTable } from "./apiProcedimento"
import { ShowDateTime } from "../../../components/SharedComponents/Shared/Show/ShowDateTime"
import PhotoIcon from "../../../components/Svg-Icons/PhotoIcon"
import PDFIcon from "../../../components/Svg-Icons/PDFIcon"
import DateTimeCalendarIcon from "../../../components/Svg-Icons/DateTimeCalendarIcon"

const columns = [{
  Header: () => null,
  id: 'Procedimentos',
  columns: [
    {
      Header: procedimentosMsgs.TABLE.HEADER.TIPO,
      accessor: "tipo"
    }, {
      Header: procedimentosMsgs.TABLE.HEADER.DESC,
      accessor: "desc"
    }, {
      Header: <DateTimeCalendarIcon/>,
      accessor: "created_at",
      Cell: ({row}) => {
        return <ShowDateTime dateTime={row.original.created_at}/>
      },
      disableSortBy: true
    }, {
      Header: <PhotoIcon/>,
      accessor: "images_count",
      disableSortBy: true
    }, {
      Header: <PDFIcon/>,
      accessor: "pdfs_count",
      disableSortBy: true
    }, {
      Header: procedimentosMsgs.COUNTS.TIPO_EQUIPS,
      accessor: "tipos_equipamento_count",
      disableSortBy: true
    }, {
      Header: procedimentosMsgs.COUNTS.MODELOS,
      accessor: "modelos_count",
      disableSortBy: true
    }
  ]
}]

function Procedimentos() {
  return (
    <BaseTableListWithRoutes
      addButtonDesc={procedimentosMsgs.CREATE_LABEL}
      route={procedimentosURL}
      columns={columns}
      deleteFn={deleteProcedimento}
      readFn={readProcedimentosTable}
    />
  )
}

export default Procedimentos
