import React from "react"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import PropTypes from "prop-types"
import { StyledTableCell } from "../../../SharedStyles/SharedStyles"
import planosAplicaveisMsgs from "../../../../pages/PlanosAplicaveis/msgsPlanosAplicaveis"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { createStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      marginBottom: theme.spacing(3),
    }
  })
)

const TableDiasAntecedencia = ({diasAntecedenciaAvisos, diasAntecedenciaPMP, diasAntecedenciaPA}) => {
  const classes = useStyles()

  return (
    <TableContainer>
      <Table className={classes.table} size="small" aria-label="TableDiasAntecedencia">
        <TableHead>
          <TableRow>
            <StyledTableCell colSpan="2">{planosAplicaveisMsgs.TABLE.HEADER.DIAS_ANT}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key="Avisos">
            <TableCell>{planosAplicaveisMsgs.TABLE.LABEL.AVISOS}</TableCell>
            <TableCell align="right">{diasAntecedenciaAvisos + planosAplicaveisMsgs.TABLE.LABEL.DIAS}</TableCell>
          </TableRow>
          <TableRow key="PMP">
            <TableCell>{planosAplicaveisMsgs.TABLE.LABEL.PMP}</TableCell>
            <TableCell align="right">{diasAntecedenciaPMP + planosAplicaveisMsgs.TABLE.LABEL.DIAS}</TableCell>
          </TableRow>
          <TableRow key="PA">
            <TableCell>{planosAplicaveisMsgs.TABLE.LABEL.PA}</TableCell>
            <TableCell align="right">{diasAntecedenciaPA + planosAplicaveisMsgs.TABLE.LABEL.DIAS}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

TableDiasAntecedencia.TableDiasAntecedencia = {
  diasAntecedenciaAvisos: "No value",
  diasAntecedenciaPMP: "No value",
  diasAntecedenciaPA: "No value",
}

TableDiasAntecedencia.TableDiasAntecedencia = {
  diasAntecedenciaAvisos: PropTypes.string,
  diasAntecedenciaPMP: PropTypes.string,
  diasAntecedenciaPA: PropTypes.string
}

export default TableDiasAntecedencia
