import React, { useContext } from "react"
import LFTable from "../../../components/LFTable/LFTable"
import { deleteTipoEquip, readTiposEquipTable } from "./apiTiposEquip"
import { RedirectContext } from "./TiposEquip"
import tiposequipMsgs from "./msgs"


const columns = [{
  Header: () => null,
  id: 'TiposEquip',
  columns: [
    {Header: tiposequipMsgs.TABLE.HEADER.REF_AN, accessor: "refAN"},
    {Header: tiposequipMsgs.TABLE.HEADER.TIPO, accessor: "tipo"},
    {
      Header: tiposequipMsgs.TABLE.HEADER.PLANOS_BASE,
      accessor: "planos_count",
      disableSortBy: true
    },
    {
      Header: tiposequipMsgs.TABLE.HEADER.PLANOS_ATIVOS,
      accessor: "planos_ativos_count",
      disableSortBy: true
    },
    {
      Header: tiposequipMsgs.TABLE.HEADER.PROCEDIMENTO,
      accessor: "procedimento_nome",
      Cell: ({row}) => {
        return row.original.procedimento_id
          ? <span>{row.original.procedimento_nome}</span>
          : null
      },
    },
    {
      Header: tiposequipMsgs.TABLE.HEADER.MODELOS,
      accessor: "modelos_count",
      disableSortBy: true
    },
    {
      Header: tiposequipMsgs.TABLE.HEADER.EQUIPAMENTOS,
      accessor: "equipamentos_cli_count",
      disableSortBy: true
    },
  ]
}]

function TiposEquipList() {
  const {create, update} = useContext(RedirectContext)

  const handlerCreate = () => create()

  const handlerUpdate = (row) => update({data: row})

  return (
    <LFTable
      columns={columns}
      requestFn={readTiposEquipTable}
      deleteFn={deleteTipoEquip}
      addButtonDesc={tiposequipMsgs.TABLE.ADD_BUTTON}
      showAddButton={true}
      showEditButton={true}
      showDeleteButton={true}
      handlerCreate={handlerCreate}
      handlerUpdate={handlerUpdate}
    />
  )
}

export default TiposEquipList
