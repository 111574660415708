import React, { useState } from "react"
import ErrorMsg from "../../../../components/SharedComponents/Shared/Error/ErrorMsg"
import msgsLocaisTec from "./msgsLocaisTec"
import LocaisTecUpdate from "./LocaisTecUpdate"
import LocaisTecList from "./LocaisTecList"

function LocaisTecLU({locais, setData, idTec}) {
  const [state, setState] = useState('L')

  return (
    <React.Fragment>
      {(state === 'L') ?
        <LocaisTecList locais={locais} setState={setState}/> :
        (state === 'U') ?
          <LocaisTecUpdate locais={locais} setData={setData} idTec={idTec} setState={setState}/> :
          <ErrorMsg>{msgsLocaisTec.ERRO_LU}</ErrorMsg>
      }
    </React.Fragment>
  )
}

export default LocaisTecLU
