import React from "react"
import IconButton from "@material-ui/core/IconButton"
import KeyArrowDownIcon from "../Svg-Icons/KeyArrowDownIcon"
import KeyArrowRightIcon from "../Svg-Icons/KeyArrowRightIcon"


const expandColumn = {
  Header: () => null, // No header
  accessor: 'expander', // It needs an ID
  Cell: ({row}) => (
    <IconButton size="small"  {...row.getExpandedToggleProps()}>
      {row.isExpanded ? <KeyArrowDownIcon/> : <KeyArrowRightIcon/>}
    </IconButton>
  ),
}

export default expandColumn
