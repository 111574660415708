import { Controller, useFormContext } from "react-hook-form"
import { KeyboardDatePicker } from "@material-ui/pickers"
import React from "react"

const LFDatePickerInput = ({name, control, label, disabled}) => {
  const {errors} = useFormContext()
  return (
    <Controller
      as={
        <KeyboardDatePicker
          autoOk
          variant="inline"
          inputVariant="outlined"
          format="L"
          fullWidth
          disabled={disabled}
          label={label}
          error={!!errors[name]}
          helperText={errors[name] ? errors[name].message : ''}
          validationError={errors[name] ? errors[name].message : ''}
        />
      }
      onChange={([e, data]) => {
        return e
      }}
      name={name}
      id={name}
      control={control}
      autoOk
    />
  )
}

export default LFDatePickerInput
