import React from "react"
import { getRequest } from "../../../api/api"
import { allPatStatesByPlanoAtivadoID, patsURLname, readPatsTable } from "../apiPats"
import { useHistory } from "react-router"
import { patsColumns } from "../Pats"
import LFTable from "../../../components/LFTable/LFTable"
// import { mergeColumnWithIndex } from "../../../constants/appConst"
// import patMsgs from "../patMsgs"
import Async from "react-async"
import LinearProgress from "@material-ui/core/LinearProgress"
import ErrorMsg from "../../../components/SharedComponents/Shared/Error/ErrorMsg"
import TabsComponent from "../../../components/SharedComponents/Shared/TabsComponent"
import Typography from "@material-ui/core/Typography"
import { AppMsgs } from "../../../constants/appMsgs"

// @am  comentado em 29/3/2021 --> já não é usado!!
// const extraColumns = {
//   Header: patMsgs.TABLE.HEADER.ESTADO,
//   accessor: "estadoPATdesc",
//   disableSortBy: true
// }

function CustomPatsList({requestFn}) {

  // const columns = mergeColumnWithIndex(3, extraColumns, patsColumns)

  const history = useHistory()
  const handlerUpdate = (row) => history.push(getRequest({URLname: patsURLname, id: row.id}).gotoItemPath())

  return (
    <LFTable
      columns={[patsColumns]}
      requestFn={requestFn}
      showEditButton={true}
      handlerUpdate={handlerUpdate}
    />
  )
}

const PatStatesRender = ({data, paID}) => {
  let tabs = []
  let index = 0
  const qtd = data.length || 0
  data.forEach(item => {
    tabs.push({
      label: item.desc,
      hasMany: true,
      qtd: item.qtd,
      value: item.value,
      index: index++,
      component: <CustomPatsList
        requestFn={([props]) => readPatsTable([{
          state: item.value,
          com: 'visitas',
          relCom: 'planoActiv',
          relComID: paID,
          sortBy: [{id: "id", desc: true}],
          ...props,
        }])}/>,
    })
  })
  return (qtd > 0)
    ? (<TabsComponent renderTabs={tabs}/>)
    : (<Typography component="h1" variant="h6" color="inherit" noWrap>
      {AppMsgs.DEFAULT_VALUES.TABLE.EMPTY_TABLE_MSG}
    </Typography>)
}

function PatsPlanoAtivado({paID}) {
  return (
    <Async promiseFn={allPatStatesByPlanoAtivadoID} paID={paID}>
      <Async.Pending><LinearProgress color="secondary"/></Async.Pending>
      <Async.Fulfilled>
        {(data) => <PatStatesRender
          data={data}
          paID={paID}/>}
      </Async.Fulfilled>
      <Async.Rejected>{error => <ErrorMsg>{error}</ErrorMsg>}</Async.Rejected>
    </Async>
  )
}

export default PatsPlanoAtivado
