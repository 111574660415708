import React, { useEffect, useState } from "react"
import { CalendarComponent, parseMarcacao } from "../../../../components/SharedComponents/Shared/Calendar/CalendarComponent"


function MarcacoesTecList({data}) {
  const [items, setItems] = useState([])

  useEffect(() => {
    data.forEach((m) => {
      setItems(items => items.concat(parseMarcacao(m)))
    })
  }, [data])
  return (
    <React.Fragment>
      <CalendarComponent calendarItems={items}
                         cldrWkds={true}
                         showHeader={true}
                         showCldrWkdsToggle={false}
                         showDay={true}
                         showMonth={true}
                         showWeek={true}
                         defaultView="listDay"/>
    </React.Fragment>
  )
}

export default MarcacoesTecList
