import React from "react"
import Async from "react-async"
import LinearProgress from "@material-ui/core/LinearProgress"
import ErrorMsg from "../../../components/SharedComponents/Shared/Error/ErrorMsg"
import { read50EntradasArmazem, readAll_TE_Transportadores } from "../apiArmazem"
import EntradasList from "./EntradasList"
import EntradasForm from "./EntradasForm"


const WrapperEntradasArmazem = (props) => {
  return (
    <Async promiseFn={read50EntradasArmazem}>
      <Async.Pending><LinearProgress color="secondary"/></Async.Pending>
      <Async.Fulfilled>
        {(data, {reload}) => {
          return (
            <>
              <EntradasForm
                {...{...props, reload}}
              />
              <EntradasList entradas={data} reload={reload}/>
            </>
          )
        }}
      </Async.Fulfilled>
      <Async.Rejected>{error => <ErrorMsg>{error}</ErrorMsg>}</Async.Rejected>
    </Async>
  )
}

function EntradasArmazem() {
  return (
    <Async promiseFn={readAll_TE_Transportadores}>
      <Async.Pending><LinearProgress color="secondary"/></Async.Pending>
      <Async.Fulfilled>
        {({teList, transpList}) => {

          return (
            <WrapperEntradasArmazem
              {...{teList, transpList}}
            />
          )
        }}
      </Async.Fulfilled>
      <Async.Rejected>{error => <ErrorMsg>{error}</ErrorMsg>}</Async.Rejected>
    </Async>
  )
}

export default EntradasArmazem
