import React from "react"
import SvgIcon from "./SvgIcon"

const ReadCheckIcon = (others) => {
  return (
    <SvgIcon viewBox="0 0 512 512" {...others}>
      <g>
        <g>
          <path d="M497,0H111.4c-8.284,0-15,6.716-15,15v190.86C39.744,230.39,0,286.837,0,352.4C0,440.404,71.596,512,159.6,512
			s159.601-71.596,159.601-159.6c0-11.383-1.207-22.487-3.483-33.201H497c8.284,0,15-6.716,15-15V15C512,6.716,505.284,0,497,0z
			 M453.352,30L304.201,140.907L155.049,30H453.352z M159.6,482C88.138,482,30,423.862,30,352.4s58.138-129.6,129.6-129.6
			s129.601,58.138,129.601,129.6S231.062,482,159.6,482z M482,289.199H306.141c-24.53-56.656-80.977-96.398-146.54-96.398
			c-11.382,0-22.486,1.207-33.2,3.483V46.082l168.85,125.555c2.657,1.976,5.804,2.963,8.951,2.963c3.147,0,6.294-0.987,8.951-2.963
			L482,46.083V289.199z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M218.407,309.66c-5.857-5.858-15.355-5.858-21.213-0.001l-53.661,53.66l-21.526-21.527
			c-5.857-5.859-15.355-5.857-21.213,0c-5.858,5.857-5.858,15.355-0.001,21.213l32.133,32.135c2.813,2.814,6.628,4.394,10.607,4.394
			c3.978,0,7.793-1.58,10.606-4.394l64.268-64.267C224.265,325.015,224.265,315.518,218.407,309.66z"/>
        </g>
      </g>
    </SvgIcon>
  )
}

export default ReadCheckIcon
