import { makeStyles } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import React from "react"
import PropTypes from "prop-types"
import { AppMsgs } from "../../../../constants/appMsgs"

const useStyles = makeStyles(theme => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

function LFSubmitButton({isLoading, dirty, children, disabled, noStyle, ...other}) {
  const classes = useStyles()
  return (
    <Button
      startIcon={isLoading && <CircularProgress color="secondary" size={20}/>}
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      className={!noStyle ? classes.submit : ''}
      disabled={isLoading || dirty || disabled}
      {...other}>
      {children}
    </Button>
  )
}

LFSubmitButton.defaultProps = {
  isLoading: false,
  dirty: false,
  disabled: false,
  children: AppMsgs.DEFAULT_VALUES.BUTTONS.SUBMIT,
  noStyle: false
}

LFSubmitButton.propTypes = {
  isLoading: PropTypes.bool,
  dirty: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.string,
  noStyle: PropTypes.bool
}

export default LFSubmitButton
