import { api, apiCUD, apiReadTable, getRequest } from "../../api/api"
import patMsgs from "./patMsgs"
import Pats from "./Pats"
import PatIcon from "../../components/Svg-Icons/PatIcon"
import PatView from "./PatView"

const patsURLname = 'pats'

// ----------------------- PATS ROUTES -----------------------
const patsPaginateRouterProps = {
  path: getRequest({URLname: patsURLname}).paginatePath(),
  exact: true,
  name: patMsgs.LABEL,
  component: Pats,
  Icon: PatIcon,
  breadcrumb: () => patMsgs.LABEL,
  reqRole: 'R'
}


const patViewRouterProps = {
  path: getRequest({URLname: patsURLname}).viewPath(),
  exact: true,
  name: patMsgs.LABEL,
  component: PatView,
  Icon: PatIcon,
  breadcrumb: () => patMsgs.LABEL,
  reqRole: 'R'
}

// ----------------------- PATS ROUTES -----------------------

// ----------------------- Requests para PATs -----------------------

async function readPatsTable([query]) {
  const request = getRequest({URLname: patsURLname, query}).GET()
  return apiReadTable(request)
}

async function readPat({id, com, query}) {
  const request = getRequest({URLname: patsURLname, id, query: {...query, com: com}}).GET()
  return api(request).then(resp => resp.data.data)
}

async function allPatStates() {
  const request = getRequest({URLname: patsURLname, paginateOFF: true}).GET('states')
  return apiReadTable(request)
}

function createPat([data]) {
  const request = getRequest({URLname: patsURLname}).POST(`new/for/${data.equip_id}`)
  return apiCUD(request, data)
}

// ----------------------- Requests para equipamentos -----------------------

async function allPatStatesByEquipID({equipID}) {
  const request = getRequest({URLname: patsURLname, paginateOFF: true}).GET(`states/equip/${equipID}`)
  return apiReadTable(request)
}

async function allPatStatesByTecnicoID({tecID}) {
  const request = getRequest({URLname: patsURLname, paginateOFF: true}).GET(`states/tec/${tecID}`)
  return apiReadTable(request)
}

async function allPatStatesByClienteID({cliID}) {
  const request = getRequest({URLname: patsURLname, paginateOFF: true}).GET(`states/cli/${cliID}`)
  return apiReadTable(request)
}

async function allPatStatesByPlanoAtivadoID({paID}) {
  const request = getRequest({URLname: patsURLname, paginateOFF: true}).GET(`states/plano-ativ/${paID}`)
  return apiReadTable(request)
}

export {
  patsURLname,
  patsPaginateRouterProps, patViewRouterProps,
  allPatStates, readPatsTable, readPat, createPat,
  allPatStatesByEquipID, allPatStatesByTecnicoID, allPatStatesByClienteID, allPatStatesByPlanoAtivadoID
}
