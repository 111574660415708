import React, { useState } from "react"
import { ListRepoGest } from "../../../../components/SharedComponents/ListRepo/ListRepoGest"
import { ImageGridList } from "../../../../components/SharedComponents/ListRepo/ListImagens/ImageGridList"
import { ImagemEdit } from "../../../../components/SharedComponents/ListRepo/ListImagens/ImagemEdit"
import { imagemMsgs } from "../../../../components/SharedComponents/ListRepo/ListImagens/imagemMsgs"
import { createIMGVisitaPat, deleteIMGVisitaPat, readIMGsVisitaPat } from "../apiVisitaPat"
import Grid from "@material-ui/core/Grid"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { useUser } from "../../../../context/user-context"
import PhotoIcon from "../../../../components/Svg-Icons/PhotoIcon"
import MobileFotoIcon from "../../../../components/Svg-Icons/MobileFotoIcon"
import ToggleButton from "@material-ui/lab/ToggleButton"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"
import FotoComponent from "./FotoComponent/FotoComponent"


const photoMsgs = {
  FROM_CAMERA: "Câmera",
  FROM_FILE: `Ficheiro local`,
}

const useStyles = makeStyles((theme) => ({
  toggleContainer: {
    margin: theme.spacing(2),
  },
}))

function ImgsVisitaPatFromFile({visita, reload}) {

  /*<div style={{background: '#07F', width: '100%', minHeight: '50px'}}></div>*/
  return (
    <ListRepoGest
      repo={visita}
      repoKey={'img_id'}
      repoItems={visita.images ? visita.images.data : []}
      FKfieldname={'visita_id'}
      createRepoFn={createIMGVisitaPat}
      deleteRepoFn={deleteIMGVisitaPat}
      readReposFn={readIMGsVisitaPat}
      ListComponent={ImageGridList}
      EditComponent={ImagemEdit}
      mayEdit={visita.mayEdit}
      btAddTitle={imagemMsgs.NEW_LABEL}
      reload={reload}
    />
  )
}

const ImgsVisitaPat = ({visita, reload}) => {
  const {u: {is: {tec: isTec}}} = useUser()
  const [isFromFile, setIsFromFile] = useState(!isTec)
  const classes = useStyles()

  const ComponentToShow = () => {
    return isFromFile
      ? (<ImgsVisitaPatFromFile visita={visita} reload={reload}/>)
      : (<FotoComponent visita={visita} reload={reload}/>)
  }

  const onSwitchChange = () => {
    setIsFromFile(!isFromFile)
  }

  const text = (!!isFromFile
    ? <span style={{fontSize: '80%'}}>{photoMsgs.FROM_FILE}</span>
    : <span style={{fontSize: '80%'}}>{photoMsgs.FROM_CAMERA}</span>)

  return (
    <Grid container spacing={2} justify="center" alignItems="center">
      {/*---------------------------------------------------------------*/}
      <Grid item xs={12}>
        <div className={classes.toggleContainer}>
          <ToggleButtonGroup className={classes.toggleContainer}
                             value={isFromFile ? ['file'] : ['mob']}
                             onChange={onSwitchChange} aria-label="device">
            <ToggleButton value="file" aria-label="file">
              <PhotoIcon/>
            </ToggleButton>
            <ToggleButton value="mob" aria-label="mobile">
              <MobileFotoIcon/>
            </ToggleButton>
          </ToggleButtonGroup>
          {text}
        </div>
      </Grid>
      {/*-------------------------- Form -------------------------------------*/}
      <Grid item xs={12}>
        <ComponentToShow/>
      </Grid>
    </Grid>
  )
}


export { ImgsVisitaPat }
