import React from "react"
import SvgIcon from "./SvgIcon"

const PhotoIcon = (others) => {
  return (
    <SvgIcon viewBox="0 0 512 512" {...others}>
      <g>
        <g>
          <path d="M402,166c-5.52,0-10,4.48-10,10s4.48,10,10,10c5.52,0,10-4.48,10-10S407.52,166,402,166z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M482,126H372.18l-31.706-63.413C335.357,52.356,325.075,46,313.64,46H198.36c-11.437,0-21.719,6.356-26.834,16.588
			L139.82,126H120v-10c0-16.542-13.458-30-30-30H70c-16.542,0-30,13.458-30,30v10H30c-16.542,0-30,13.458-30,30
			c0,27.175,0,269.429,0,280c0,16.542,13.458,30,30,30h452c16.542,0,30-13.458,30-30c0-27.175,0-269.429,0-280
			C512,139.458,498.542,126,482,126z M189.415,71.532C191.121,68.12,194.548,66,198.36,66h115.28c3.812,0,7.24,2.12,8.946,5.532
			L349.82,126H162.18L189.415,71.532z M60,116c0-5.514,4.486-10,10-10h20c5.514,0,10,4.486,10,10v10H60V116z M20,225.9h126.451
			C133.161,246.661,126,270.992,126,296c0,25.01,7.162,49.243,20.45,70H20V225.9z M256,186c60.133,0,110,48.719,110,110
			c0,61.212-49.797,110-110,110c-60.257,0-110-48.842-110-110C146,234.795,195.79,186,256,186z M492,436c0,5.514-4.486,10-10,10H30
			c-5.514,0-10-4.486-10-10v-50h142.226c24.435,25.472,58.349,40,93.774,40c35.431,0,69.348-14.532,93.775-40H492V436z M492,366
			H365.549C378.839,345.239,386,321.009,386,296c0-25.009-7.161-49.239-20.451-70H492V366z M492,206H349.774
			c-24.434-25.472-58.348-40-93.774-40c-35.43,0-69.347,14.532-93.775,40H20v-50c0-5.514,4.486-10,10-10c9.081,0,437.942,0,452,0
			c5.514,0,10,4.486,10,10V206z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M286,86h-60c-5.523,0-10,4.477-10,10s4.477,10,10,10h60c5.522,0,10-4.477,10-10S291.522,86,286,86z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M256,206c-49.626,0-90,40.374-90,90c0,49.626,40.374,90,90,90c49.626,0,90-40.374,90-90C346,246.374,305.626,206,256,206z
			 M256,366c-38.598,0-70-31.402-70-70c0-38.598,31.402-70,70-70s70,31.402,70,70C326,334.598,294.598,366,256,366z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M256,246c-27.57,0-50,22.43-50,50c0,5.523,4.477,10,10,10s10-4.477,10-10c0-16.542,13.458-30,30-30
			c5.522,0,10-4.477,10-10S261.522,246,256,246z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M462,166h-20c-5.522,0-10,4.477-10,10s4.478,10,10,10h20c5.522,0,10-4.477,10-10S467.522,166,462,166z"/>
        </g>
      </g>
    </SvgIcon>
  )
}

export default PhotoIcon
