const pecasPatMsgs = {

  NEW_LABEL: "Associar Peça/Material",
  BACK_LABEL_UPDATE: `Cancelar a edição`,
  BACK_LABEL_CREATE: `Cancelar o registo de nova peça`,

  TABLE: {
    TITLE: "Peças/Materiais usados",
    HEADER: {
      TOTAIS: "Totais",
      TOTAL: "Total",
      TOTAL_SEM_DESC: "Total s/ Desc.",
      TOTAL_SEM_IVA: "Total s/ IVA",
      VALOR: "Valor",
      REF_IVA: "Ref./taxa IVA",
      QTD: "Qtd.",
      IVA: "IVA",
      DESCONTO: "Desconto",
      PVP_SEM_IVA: "PVP s/ IVA",
    }
  },

  EDIT: {
    LABEL: {
      PECA: "Peça/Material",
      QTD: "Qtd.",
      UNIT_PRICE: "€/unid.",
      DESCONTO: "Desconto",
      IVA: "IVA"
    }
  },

  BUTTON: {
    NEW: "Adicionar",
    EDIT: "Guardar"
  },
}

export default pecasPatMsgs

