import { api, apiCUD, apiReadTable, getRequest } from "../../../api/api"
import Tecnicos from "./Tecnicos"
import BuildIcon from "../../../components/Svg-Icons/BuildIcon"
import { TecnicoView } from "./TecnicoView"
import msgsTecnicos from "./msgsTecnicos"

const tecnicosUrl = 'tecnicos'

// ----------------------- TECNICOS ROUTES -----------------------

const tecnicosRouterProps = {
  path: getRequest({URLname: tecnicosUrl}).paginatePath(),
  exact: true,
  name: msgsTecnicos.LABEL,
  component: Tecnicos,
  Icon: BuildIcon,
  breadcrumb: () => msgsTecnicos.LABEL,
  reqRole: 'R'
}

const tecnicoViewRouterProps = {
  path: getRequest({URLname: tecnicosUrl}).viewPath(),
  exact: true,
  name: msgsTecnicos.UPDATE_LABEL,
  component: TecnicoView,
  Icon: BuildIcon,
  breadcrumb: (props) => !!parseInt(props.match.params.id, 10) ? msgsTecnicos.UPDATE_LABEL : msgsTecnicos.CREATE_LABEL,
  reqRole: 'R'
}

// ----------------------- TECNICOS ROUTES -----------------------

/**
 * Read Tecnicos com paginação para tabela
 * @param query
 * @returns {Promise<*|void>}
 */
async function readTecnicosTable([query]) {
  const request = getRequest({URLname: tecnicosUrl, query}).GET()
  return apiReadTable(request)
}

/**
 * Read Tecnico
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
function readTecnico({id}) {
  const request = getRequest({URLname: tecnicosUrl, id}).GET()
  return api(request).then(r => r.data.data)
}

function createTecnico([data]) {
  const request = getRequest({URLname: tecnicosUrl}).POST()
  return apiCUD(request, data).then(r => r.data)
}

function updateTecnico([data]) {
  const request = getRequest({URLname: tecnicosUrl, id: data.id}).PUT()
  return apiCUD(request, data).then(r => r.data)
}


async function deleteTecnico(id) {
  const request = getRequest({URLname: tecnicosUrl, id}).DELETE()
  return apiCUD(request)
}

async function undeleteTecnico(id) {
  const request = getRequest({URLname: tecnicosUrl, id}).PUT('undelete')
  return apiCUD(request)
}

export {
  tecnicosUrl,
  tecnicosRouterProps,
  tecnicoViewRouterProps,
  readTecnicosTable,
  readTecnico,
  createTecnico,
  updateTecnico,
  deleteTecnico,
  undeleteTecnico
}
