import React from "react"
import { BrowserRouter, Switch } from "react-router-dom"

import { PrivateRoute, PublicRoute } from "./RouterComponents"
import { layoutRouteProps, loginRoute } from "../../constants/appRoutes"

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute {...loginRoute}/>
        <PrivateRoute {...layoutRouteProps}/>
      </Switch>
    </BrowserRouter>
  )
}

export default AppRouter
