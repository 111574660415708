const msgsEquips = {
  LABEL: "Equipamentos",
  LIST_LABEL: "Lista Equipamentos",
  UPDATE_LABEL: "Editar Equipamento",
  CREATE_LABEL: "Novo Equipamento",
  BACK_LABEL: "Voltar aos Equipamentos",
  DIALOG_BUTTON: "Ver Equipamento",
  OPEN_BUTTON: "Abrir Equipamento",
  TABS: {
    EQUIP: "Equipamento",
    PLANO_ATIV: "Plano Ativado",
    PLANO_APLI: "Planos Aplicáveis",
    HIST_PLANO_ATIV: "Histórico Planos Ativados",
    PMCE: "PMCE",
    PA: "PA",
    PAT: "PAT's"
  },
  TABLE: {
    ADD_BUTTON: "Adicionar Equipamento",
    HEADER: {
      NUM_SERIE: "Nº Série",
      BASE_INFO: "Informações",
      HAS_PLANO: String.fromCharCode(8707) + " Plano", // &exist;
      HAS_PMCESP: String.fromCharCode(8707) + " PMCE",
      HAS_PA: String.fromCharCode(8707) + " PA",
      PATS_COUNT: "Qtd. PAT"
    }
  },
  EDIT: {
    LABEL: {
      TIPO_EQUIP: "Tipo de Equipamento",
      MARCA: "Marca",
      MODELO: "Modelo",
      INSTALADOR: "Instalador",
      IS_MESMA_MORADA_CLI: "Mesma Morada do Cliente?",
      IS_NOVO: "Novo?",
      DATA_INSTALACAO: "Data da fatura de compra do Equipamento",
      DATA_FIMGARANTIA: "Fim da Garantia do Equipamento",
      NUM_SERIE: "Núm. Série",
      VALIDATION: {
        TIPO_EQUIP_MARCA: "! Para alterar o tipo equip, retire a marca associada",
        MARCA_TIPO_EQUIP: "! Para escolher uma marca, escolha um tipode equipamento primeiro",
        MARCA_MODELO: "! Para alterar uma marca, retire o modelo associado",
        MODELO_MARCA: "! Para escolher um modelo, escolha uma marca primeiro",
      }
    }
  },
  BUTTON: {
    EDIT_EQUIP: "Editar Equipamento",
    NEW_EQUIP: "Novo Equipamento"
  }
}

export default msgsEquips
