import { api, apiCUD, getRequest } from "../../../../api/api"

const tecMarcasUrl = 'tec'

function readMarcasTec({id}) {
  const request = getRequest({URLname: tecMarcasUrl, id}).GET(`marcas/list`)
  return api(request).then(r => r.data.data)
}

function updateMarcasTec([data]) {
  const request = getRequest({URLname: tecMarcasUrl}).POST(`${data.idTec}/marca/${data.idMarca}/onoff`)
  return apiCUD(request, data).then(r => r.data)
}

export {
  readMarcasTec,
  updateMarcasTec,
}
