import React, { useState } from "react"
import * as Yup from "yup"
import { requiredHTMLEditorValidation, selectValidation } from "../../../../../constants/appValidation"
import patInfoStatesMsgs from "../patInfoStatesMsgs"
import { Form } from "../../../../../components/SharedStyles/SharedStyles"
import Grid from "@material-ui/core/Grid"
import { FormContext, useForm } from "react-hook-form"
import { useAsync } from "react-async"
import FormHelperText from "@material-ui/core/FormHelperText"
import LFTextEditor from "../../../../../components/LFInputs/LFTextEditor"
import makeStyles from "@material-ui/core/styles/makeStyles"
import CircularProgress from "@material-ui/core/CircularProgress"
import Button from "@material-ui/core/Button"
import LeftMarginSpan from "../../../../../components/SharedStyles/LeftMarginSpan"
import "moment/locale/pt"
import { anularPat } from "../apiInfoStates"
import LFSelect from "../../../../../components/LFInputs/LFSelect"
import CustomDialog from "../../../../../components/SharedComponents/Shared/Dialog/CustomDialog"
import { repoMsgs } from "../../../../../components/SharedComponents/ListRepo/repoMsgs"

const useStyles = makeStyles(theme => ({
  quillEditor: {
    minHeight: '8em'
  }
}))

const options = [
  {index: "AC", title: "Anulada pelo Cliente"},
  {index: "AI", title: "Anulada pela IMMERSAT"},
]

const validationSchema = Yup.object().shape({
  causaAnulacao: selectValidation(patInfoStatesMsgs.CAUSA_ANULACAO),
  obsFinal: requiredHTMLEditorValidation(patInfoStatesMsgs.OBS),
})

const AnularPATForm = ({repo, reload}) => {
  const classes = useStyles()
  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false)
  const [formData, setFormData] = useState({})

  const {isPending, run} = useAsync({
    deferFn: anularPat,
    onResolve: (resp) => {
      reload()
    }
  })

  const methods = useForm({
    validationSchema: validationSchema,
    defaultValues: {
      causaAnulacao: '',
      obsFinal: '',
    }
  })

  const {handleSubmit, formState, control} = methods

  const onSubmit = ({...values}) => {
    const convertValues = {
      ...values, ...{
        pat_id: repo.id,
        // Contrariamente a outros casos em que funciona perfeitamente,
        // se não for efetuado o teste values.causaAnulacao && ...
        // dá erro (index on udefined)
        causaAnulacao: (values.causaAnulacao && values.causaAnulacao.index),
        obsFinal: values.obsFinal,
      }
    }
    setFormData({...convertValues})
    setConfirmationDialogVisible(true)
  }

  const handleConfirmedDateTime = () => {
    setConfirmationDialogVisible(false)
    run(formData)
  }

  const handleCancel = () => {
    // feito no CustomDialog
    // setConfirmationDialogVisible(false)
  }

  return (
    <FormContext {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} justify="center">
          {/*---------------------------------------------------------------*/}
          <Grid item xs={12}>
            <LFSelect name="causaAnulacao" control={control} options={options} label={patInfoStatesMsgs.CAUSA_ANULACAO}/>
          </Grid>
          {/*---------------------------------------------------------------*/}
          <Grid item xs={12}>
            <FormHelperText>{patInfoStatesMsgs.OBS}</FormHelperText>
            <LFTextEditor name='obsFinal' control={control} className={classes.quillEditor}/>
          </Grid>
          {/*---------------------------------------------------------------*/}
          <Grid item xs={12}>
            <Button
              onClick={onSubmit}
              style={{padding: '1rem', fontSize: '0.875rem', lineHeight: '1.2rem'}}
              startIcon={isPending && <CircularProgress color="secondary" size={20}/>}
              type="submit"
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              disabled={isPending || !formState.dirty}>
              <React.Fragment>
                <LeftMarginSpan>{patInfoStatesMsgs.BT_ANULAR}</LeftMarginSpan>
              </React.Fragment>
            </Button>
          </Grid>
        </Grid>
      </Form>
      <CustomDialog open={confirmationDialogVisible}
                    setOpen={setConfirmationDialogVisible}
                    title={repoMsgs.CONFIRM_DIALOG_TITLE}
                    onConfirm={() => handleConfirmedDateTime()}
                    onCancel={() => handleCancel()}
                    msgsConfirm={repoMsgs.YES}
                    msgsCancel={repoMsgs.NO}>
        {patInfoStatesMsgs.CONFIRM_ANULACAO(repo.refCode)}
      </CustomDialog>
    </FormContext>
  )
}

export {
  AnularPATForm,
}
