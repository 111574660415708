import { api, apiCUD, getRequest } from "../../../api/api"
import { VisitaPatView } from "./VisitaPatView"
import visitaPatMsgs from "./visitaPatMsgs"

const visitasPatURL = 'visitas'

// ----------------------- VISITA_PAT_VIEW ROUTE -----------------------
const visitaPatViewRouterProps = {
  path: getRequest({URLname: visitasPatURL}).viewPath(),
  name: visitaPatMsgs.UPDATE_LABEL,
  exact: true,
  component: VisitaPatView,
  breadcrumb: (props) => !!parseInt(props.match.params.id, 10) ? visitaPatMsgs.UPDATE_LABEL : 'Create "VisitaPat" is unavailable',
  reqRole: 'R'
}

// ----------------------- GET /gpm/visitas/{id} -----------------------
/**
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
function readVisitaPat({id}) {
  const request = getRequest({URLname: visitasPatURL, id}).GET()
  return api(request).then(r => r.data.data)
}

// -------------------- PUT /gpm/visitas/{id}/remarcarConclusao --------------------
const remarcarConclusaoVisitaPat = ([data]) => {
  const request = getRequest({URLname: visitasPatURL, id: data.visita_id}).PUT(`remarcarConclusao`)
  return apiCUD(request, data)
}

// -------------------- PUT /gpm/visitas/{id}/resolvida --------------------
const resolvidaVisitaPat = ([data]) => {
  const request = getRequest({URLname: visitasPatURL, id: data.visita_id}).PUT(`resolvida`)
  return apiCUD(request, data)
}

const prometeLog = (data) => {
  return new Promise(
    (resolve, reject) => {
      console.log('prometeLog', data)
      resolve(data[0])
    })
}

// ---------------------------------------------------------------------
// ---------------------------------------------------------------------
// ------------------------------ PDFs ---------------------------------
// ---------------------------------------------------------------------
// -------------------- GET /gpm/visitas/{id}/pdfs ---------------------
async function readPDFsVisitaPat({id, query}) {
  const request = getRequest({URLname: visitasPatURL, id, paginateOFF: true, query: {...query, com: 'pdfs'}}).GET('pdfs')
  return api(request).then(resp => resp.data.data)
}

// -------------------- POST /gpm/visitas/{id}/pdfs --------------------
const createPDFVisitaPat = (data) => {
  const request = getRequest({URLname: visitasPatURL,}).POST(`${data.visita_id}/pdfs`)
  return apiCUD(request, data)
}

// ---------------- DELETE /gpm/visitas/{id}/pdfs/{id} ----------------
const deletePDFVisitaPat = ({visita_id, pdf_id}) => {
  const request = getRequest({URLname: visitasPatURL, id: visita_id}).DELETE(`pdfs/${pdf_id}`)
  return apiCUD(request)
}
// ---------------------------------------------------------------------
// ------------------------------ IMGs ---------------------------------
// ---------------------------------------------------------------------
// -------------------- GET /gpm/visitas/{id}/imgs ---------------------
async function readIMGsVisitaPat({id, query}) {
  const request = getRequest({URLname: visitasPatURL, id, paginateOFF: true, query: {...query, com: 'images'}}).GET('imgs')
  return api(request).then(resp => resp.data.data)
}

// -------------------- POST /gpm/visitas/{id}/imgs --------------------
const createIMGVisitaPat = (data) => {
  const request = getRequest({URLname: visitasPatURL,}).POST(`${data.visita_id}/imgs`)
  return apiCUD(request, data)
}

// ---------------- DELETE /gpm/visitas/{id}/imgs/{id} ----------------
const deleteIMGVisitaPat = ({visita_id, img_id}) => {
  const request = getRequest({URLname: visitasPatURL, id: visita_id}).DELETE(`imgs/${img_id}`)
  return apiCUD(request)
}

// ---------------------------------------------------------------------
// ------------------------------ OBSs ---------------------------------
// ---------------------------------------------------------------------
// -------------------- GET /gpm/visitas/{id}/imgs ---------------------
async function readOBSsVisitaPat({id, query}) {
  const request = getRequest({URLname: visitasPatURL, id, paginateOFF: true, query: {...query, com: 'obs'}}).GET('obs')
  return api(request).then(resp => resp.data.data)
}

// -------------------- POST /gpm/visitas/{id}/imgs --------------------
const createOBSVisitaPat = (data) => {
  const request = getRequest({URLname: visitasPatURL,}).POST(`${data.visita_id}/obs`)
  return apiCUD(request, data)
}

// ---------------- DELETE /gpm/visitas/{id}/imgs/{id} ----------------
const deleteOBSVisitaPat = ({visita_id, obs_id}) => {
  const request = getRequest({URLname: visitasPatURL, id: visita_id}).DELETE(`obs/${obs_id}`)
  return apiCUD(request)
}

export {
  visitasPatURL,
  visitaPatViewRouterProps,
  readVisitaPat,
  readPDFsVisitaPat,
  createPDFVisitaPat,
  deletePDFVisitaPat,
  readIMGsVisitaPat,
  createIMGVisitaPat,
  deleteIMGVisitaPat,
  readOBSsVisitaPat,
  createOBSVisitaPat,
  deleteOBSVisitaPat,
  remarcarConclusaoVisitaPat,
  resolvidaVisitaPat,
  prometeLog,
}
