import React from "react"
import SvgIcon from "./SvgIcon"

const DateTimeCalendarIcon = (others) => {
  return (
    <SvgIcon viewBox="0 0 512 512" {...others}>
      <path
        d="m384 48.496094c0-13.253906-10.746094-24-24-24h-24c0-13.253906-10.746094-24-24-24s-24 10.746094-24 24h-32c0-13.253906-10.746094-24-24-24s-24 10.746094-24 24h-32c0-13.253906-10.746094-24-24-24s-24 10.746094-24 24h-32c0-13.253906-10.746094-24-24-24s-24 10.746094-24 24h-24c-13.253906 0-24 10.746094-24 24v336c0 4.417968 3.582031 8 8 8h24v24c0 4.417968 3.582031 8 8 8h210.664062c.105469 0 .183594-.054688.28125-.054688 42.371094 29.222656 97.617188 32 142.707032 7.167969 45.089844-24.828125 72.277344-73.003906 70.234375-124.433594-2.042969-51.433593-32.96875-97.296875-79.886719-118.472656zm-80-24c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v24h-16zm-80 0c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v24h-16zm-80 0c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v24h-16zm-80 0c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v24h-16zm-48 24c0-4.417969 3.582031-8 8-8h24v16c0 4.417968 3.582031 8 8 8h32c4.417969 0 8-3.582032 8-8v-16h32v16c0 4.417968 3.582031 8 8 8h32c4.417969 0 8-3.582032 8-8v-16h32v16c0 4.417968 3.582031 8 8 8h32c4.417969 0 8-3.582032 8-8v-16h32v16c0 4.417968 3.582031 8 8 8h32c4.417969 0 8-3.582032 8-8v-16h24c4.417969 0 8 3.582031 8 8v40h-352zm32 344h170.265625c4.082031 5.671875 8.597656 11.019531 13.503906 16h-183.769531zm400-80c0 66.273437-53.726562 120-120 120s-120-53.726563-120-120c0-66.273438 53.726562-120 120-120 66.242188.074218 119.925781 53.757812 120 120zm-120-136c-47.785156-.03125-92.074219 25.03125-116.648438 66.011718-24.574218 40.976563-25.824218 91.855469-3.289062 133.988282h-192.0625v-272h352v78.023437c-12.957031-3.996093-26.441406-6.027343-40-6.023437zm0 0"/>
      <path
        d="m72 136.496094c-13.253906 0-24 10.746094-24 24s10.746094 24 24 24 24-10.746094 24-24-10.746094-24-24-24zm0 32c-4.417969 0-8-3.582032-8-8 0-4.417969 3.582031-8 8-8s8 3.582031 8 8c0 4.417968-3.582031 8-8 8zm0 0"/>
      <path
        d="m152 136.496094c-13.253906 0-24 10.746094-24 24s10.746094 24 24 24 24-10.746094 24-24-10.746094-24-24-24zm0 32c-4.417969 0-8-3.582032-8-8 0-4.417969 3.582031-8 8-8s8 3.582031 8 8c0 4.417968-3.582031 8-8 8zm0 0"/>
      <path
        d="m232 136.496094c-13.253906 0-24 10.746094-24 24s10.746094 24 24 24 24-10.746094 24-24-10.746094-24-24-24zm0 32c-4.417969 0-8-3.582032-8-8 0-4.417969 3.582031-8 8-8s8 3.582031 8 8c0 4.417968-3.582031 8-8 8zm0 0"/>
      <path
        d="m72 200.496094c-13.253906 0-24 10.746094-24 24s10.746094 24 24 24 24-10.746094 24-24-10.746094-24-24-24zm0 32c-4.417969 0-8-3.582032-8-8 0-4.417969 3.582031-8 8-8s8 3.582031 8 8c0 4.417968-3.582031 8-8 8zm0 0"/>
      <path
        d="m152 200.496094c-13.253906 0-24 10.746094-24 24s10.746094 24 24 24 24-10.746094 24-24-10.746094-24-24-24zm0 32c-4.417969 0-8-3.582032-8-8 0-4.417969 3.582031-8 8-8s8 3.582031 8 8c0 4.417968-3.582031 8-8 8zm0 0"/>
      <path
        d="m72 264.496094c-13.253906 0-24 10.746094-24 24s10.746094 24 24 24 24-10.746094 24-24-10.746094-24-24-24zm0 32c-4.417969 0-8-3.582032-8-8 0-4.417969 3.582031-8 8-8s8 3.582031 8 8c0 4.417968-3.582031 8-8 8zm0 0"/>
      <path
        d="m152 264.496094c-13.253906 0-24 10.746094-24 24s10.746094 24 24 24 24-10.746094 24-24-10.746094-24-24-24zm0 32c-4.417969 0-8-3.582032-8-8 0-4.417969 3.582031-8 8-8s8 3.582031 8 8c0 4.417968-3.582031 8-8 8zm0 0"/>
      <path
        d="m224 312.496094c0 57.4375 46.5625 104 104 104s104-46.5625 104-104-46.5625-104-104-104c-57.410156.066406-103.933594 46.589844-104 104zm192 0h-16c-4.417969 0-8 3.582031-8 8 0 4.417968 3.582031 8 8 8h14.472656c-7.214844 38.722656-39.253906 67.953125-78.472656 71.59375v-15.59375c0-4.417969-3.582031-8-8-8s-8 3.582031-8 8v15.59375c-39.21875-3.640625-71.257812-32.871094-78.472656-71.59375h14.472656c4.417969 0 8-3.582032 8-8 0-4.417969-3.582031-8-8-8h-16c.058594-45.46875 34.722656-83.421875 80-87.589844v15.589844c0 4.417968 3.582031 8 8 8s8-3.582032 8-8v-15.589844c45.277344 4.167969 79.941406 42.121094 80 87.589844zm0 0"/>
      <path
        d="m329.71875 308.65625-11.0625-16.597656c-2.453125-3.675782-7.421875-4.667969-11.097656-2.21875-3.675782 2.453125-4.667969 7.421875-2.214844 11.097656l16 24c1.261719 1.882812 3.261719 3.144531 5.503906 3.472656.382813.058594.765625.089844 1.152344.085938 1.871094.003906 3.683594-.652344 5.121094-1.847656l48-40c3.398437-2.828126 3.859375-7.875 1.03125-11.269532-2.828125-3.398437-7.875-3.863281-11.273438-1.035156zm0 0"/>
    </SvgIcon>
  )
}

export default DateTimeCalendarIcon
