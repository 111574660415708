import { api, apiCUD, getRequest } from "../../../api/api"
import { procedimentosURL } from "./apiProcedimento"

async function readImagensProcedimento({id, query}) {
  const request = getRequest({URLname: procedimentosURL, id, paginateOFF: true, query: {...query, com: 'images'}}).GET('imgs')
  return api(request).then(resp => resp.data.data)
}

const createImagemProcedimento = (data) => {
  const request = getRequest({URLname: procedimentosURL}).POST(`${data.proc_id}/imgs`)
  return apiCUD(request, data)
}

const deleteImagemProcedimento = ({proc_id, img_id}) => {
  const request = getRequest({URLname: procedimentosURL, id: proc_id}).DELETE(`imgs/${img_id}`)
  return apiCUD(request)
}

async function readPDFsProcedimento({id, query}) {
  const request = getRequest({URLname: procedimentosURL, id, paginateOFF: true, query: {...query, com: 'pdfs'}}).GET('pdfs')
  return api(request).then(resp => resp.data.data)
}

const createPDFProcedimento = (data) => {
  const request = getRequest({
    URLname: procedimentosURL,
    // headers: {
    //   'content-type': 'multipart/form-data',
    //   'boundary':Math.random().toString().substr(2)
    // }
  }).POST(`${data.proc_id}/pdfs`)
  return apiCUD(request, data)
}

const deletePDFProcedimento = ({proc_id, pdf_id}) => {
  const request = getRequest({URLname: procedimentosURL, id: proc_id}).DELETE(`pdfs/${pdf_id}`)
  return apiCUD(request)
}

export {
  readImagensProcedimento,
  createImagemProcedimento,
  deleteImagemProcedimento,
  readPDFsProcedimento,
  createPDFProcedimento,
  deletePDFProcedimento,
}
