import React from "react"
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from "prop-types"
import { immBgUltraLight, tableStyles, textSecondary, whiteColor } from "../../../../constants/appTheme"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { Badge, StyledTableCell } from "../../../SharedStyles/SharedStyles"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import BadgeMat from "@material-ui/core/Badge"
import CommentIcon from "../../../Svg-Icons/CommentIcon"
import PDFIcon from "../../../Svg-Icons/PDFIcon"
import visitaPatMsgs from "../../../../pages/Pats/VisitasPat/visitaPatMsgs"
import SimpleFlowIcon from "../../../Svg-Icons/SimpleFlowIcon"
import { ShowDateTime } from "../Show/ShowDateTime"
import Box from "@material-ui/core/Box"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Typography from "@material-ui/core/Typography"
import BoolColoredCircleIcon from "../BoolColoredCircleIcon"
import LeftMarginSpan from "../../../SharedStyles/LeftMarginSpan"
import { createStyles, Paper } from "@material-ui/core"
import ShowRating from "../Show/ShowRating"
import Grid from "@material-ui/core/Grid"
import Fab from "@material-ui/core/Fab"
import { getRequest } from "../../../../api/api"
import { visitasPatURL } from "../../../../pages/Pats/VisitasPat/apiVisitaPat"
import PhotoIcon from "../../../Svg-Icons/PhotoIcon"


const myStyles = makeStyles((theme) =>
  createStyles({
    anulado: {
      background: immBgUltraLight,
    },
    mayEdit: {
      background: 'rgba(216,255,0,0.06)',
    },
    bail: {},
    paperBox: {padding: '.5rem', background: immBgUltraLight, fontWeight: 'bold'},
    rootBox: {
      padding: 0,
      margin: 0
    },
    badgeBox: {
      padding: '5px',
      minWidth: '2rem',
      marginRight: '3px'
    },
    visitaTopHeader: {
      background: textSecondary,
      color: whiteColor,
      width: '200px',
    },
    sectionTopHeader: {
      background: immBgUltraLight,
    },
    tableHeaderTopTitle: {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: theme.typography.fontWeightLight,
    }
  }))

function TableVisitaPat({data: visita, showLink}) {
  const classes = tableStyles()
  const myClasses = myStyles()
  const imgSize = (url) => ((!!url) ? '140px' : '0')

  // ------------------------------------------
  // Como é encerrada esta visita?
  // ------------------------------------------
  // NE   - Não Encerrada
  // PRC  - Pedido Remarcação pelo cliente
  // PRI  - Pedido Remarcação pela IMMERSAT
  // AC   - Anulada pelo Cliente
  // AI   - Anulada pela IMMERSAT
  //
  // PR   - PAT Resolvida
  //        =>  conclusão da PAT após relatório técnico (na PAT)
  //        => 'assinaturaCliente' e 'assinaturaTecnico',
  // VC   - visita concluída
  //        =>  PAT não concluída
  const states = visita.states
  // ------------------------------------------

  return ((!!visita)
    ? (
      <React.Fragment>
        <p>
        </p>
        {showLink && <Grid container spacing={2} justify="flex-start" alignItems="center">
          <Grid item xs={12}>
            <Fab component={RouterLink} // target="_blank"
                 size="small"
                 to={getRequest({URLname: visitasPatURL, id: visita.id}).gotoItemPath()} color="secondary"
                 variant="extended" style={{boxShadow: 'none'}}>
              <Typography variant="body2" style={{paddingLeft: '5px', fontSize: '12px'}}
                          display={'inline'}>{visitaPatMsgs.OPEN_BUTTON}</Typography>
            </Fab>
          </Grid>
        </Grid>}
        <TableContainer className={states.isAnulada ? myClasses.anulado : visita.mayEdit ? myClasses.mayEdit : myClasses.bail}>
          <Table className={classes.table} size="small" aria-label="TableVisitaPat">
            {/* -------------------- VISITA, Registo e agendamento -------------------- */}
            <>
              <TableHead>
                <TableRow>
                  <TableCell className={myClasses.visitaTopHeader} colSpan={2} align={'left'}>
                    {visita.estadoVisitaDesc}
                  </TableCell>
                  <StyledTableCell colSpan={2} align={'left'}>{visitaPatMsgs.REGISTO}</StyledTableCell>
                  <StyledTableCell colSpan={2} align={'left'}>{visitaPatMsgs.AGENDAMENTO_CLI}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="visitaRegAgend">

                  <TableCell colSpan={2} align={'left'}>
                    <Box className={myClasses.rootBox}
                         display="flex" alignItems="center" justifyContent="flex-start" flexDirection="row" p={1} m={1}>
                      <Box className={myClasses.rootBox}
                           display="flex" alignItems="flex-start" flexDirection="column" p={1} m={1}>
                        <Box>
                          {visitaPatMsgs.IS_ANULADA}
                          <LeftMarginSpan><BoolColoredCircleIcon flagTF={states.isAnulada} size={12}/></LeftMarginSpan>
                        </Box>
                        <Box>
                          {visitaPatMsgs.IS_ENCERRADA}
                          <LeftMarginSpan><BoolColoredCircleIcon flagTF={states.isEncerrada} size={12}/></LeftMarginSpan>
                        </Box>
                        <Box>
                          {visitaPatMsgs.IS_PAT_RESOLVIDO}
                          <LeftMarginSpan><BoolColoredCircleIcon flagTF={states.isPatResolvido} size={12}/></LeftMarginSpan>
                        </Box>
                      </Box>
                      {(states.isEncerrada) && <Box p={1}>
                        <Paper className={myClasses.paperBox}>
                          <ShowDateTime dateTime={visita.dhRegEncerrouVisita}/>
                        </Paper>
                      </Box>}
                    </Box>
                  </TableCell>

                  <TableCell colSpan={2} align={'left'}>
                    <Box className={myClasses.rootBox}
                         display="flex" alignItems="center" flexDirection="row" p={1} m={1}>
                      <Box><Badge className={myClasses.badgeBox}>{visita.origemVisita}</Badge></Box>
                      <Box><ShowDateTime dateTime={visita.dhRegVisita}/></Box>
                    </Box>
                  </TableCell>

                  <TableCell colSpan={2} align={'left'}>
                    <ShowDateTime dateTime={visita.dhAgendamentoComCliente} offLimit={visita.offAgendamento}/>
                  </TableCell>

                </TableRow>
              </TableBody>
            </>

            {/* -------------------- CONTAGENS -------------------- */}
            <>
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan={2} align={'left'}>
                  </StyledTableCell>
                  <StyledTableCell align={'center'}>
                    <BadgeMat
                      showZero={true}
                      badgeContent={visita.obs_count} color="secondary">
                      <CommentIcon/>
                    </BadgeMat>
                  </StyledTableCell>
                  <StyledTableCell align={'center'}>
                    <BadgeMat
                      showZero={true}
                      badgeContent={visita.images_count} color="secondary">
                      <PhotoIcon/>
                    </BadgeMat>
                  </StyledTableCell>
                  <StyledTableCell align={'center'}>
                    <BadgeMat
                      showZero={true}
                      badgeContent={visita.pdfs_count} color="secondary">
                      <PDFIcon/>
                    </BadgeMat>
                  </StyledTableCell>
                  <StyledTableCell align={'center'}>
                    <BadgeMat
                      showZero={true}
                      badgeContent={visita.estados_count} color="secondary">
                      <SimpleFlowIcon/>
                    </BadgeMat>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
            </>

            {/* -------------------- Chegada / saída do TEC -------------------- */}
            <TableBody key={'tableSep01'} style={{marginTop: '1.5rem'}}/>
            {(states.isResolvidaOuConcluida) && <>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={6} align={'center'} className={myClasses.tableHeaderTop}>
                    <Typography className={myClasses.tableHeaderTopTitle}>{visitaPatMsgs.TECNICO}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell colSpan={2} align={'left'}>
                    {visitaPatMsgs.CHEGADA_TEC}
                    <LeftMarginSpan><BoolColoredCircleIcon flagTF={!!visita.dhChegadaTecnico} size={12}/></LeftMarginSpan>
                  </StyledTableCell>
                  <StyledTableCell colSpan={2} align={'left'}>
                    {visitaPatMsgs.SAIDA_TEC}
                    <LeftMarginSpan><BoolColoredCircleIcon flagTF={!!visita.dhSaidaTecnico} size={12}/></LeftMarginSpan>
                  </StyledTableCell>
                  <StyledTableCell colSpan={2} align={'left'}>
                    {(states.isResolvidaOuConcluida) &&
                    <React.Fragment>
                      {visitaPatMsgs.AVALIACAO_CLI}
                      <LeftMarginSpan><BoolColoredCircleIcon flagTF={states.isEncerrada} size={12}/></LeftMarginSpan>
                    </React.Fragment>}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="chegadaSaidaAval">

                  <TableCell colSpan={2} align={'left'}>
                    <ShowDateTime dateTime={visita.dhChegadaTecnico}/>
                  </TableCell>

                  <TableCell colSpan={2} align={'left'}>
                    <ShowDateTime dateTime={visita.dhSaidaTecnico}/>
                  </TableCell>

                  <TableCell align={'center'}>
                    {(states.isResolvidaOuConcluida) && <ShowRating qtdStars={visita.avalVisita}/>}
                  </TableCell>

                </TableRow>
              </TableBody>
            </>}

            {/* -------------------- assinaturas -------------------- */}
            <TableBody key={'tableSep02'} style={{marginTop: '1.5rem'}}/>
            {(states.isResolvidaOuConcluida) && <>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={6} align={'center'} className={myClasses.tableHeaderTop}>
                    <Typography className={myClasses.tableHeaderTopTitle}>{visitaPatMsgs.ASSINATURA}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell colSpan={3} align={'center'}>
                    {visitaPatMsgs.ASSINA_CLI}
                    <LeftMarginSpan><BoolColoredCircleIcon flagTF={!!visita.assinaturaCliente} size={12}/></LeftMarginSpan>
                  </StyledTableCell>
                  <StyledTableCell colSpan={3} align={'center'}>
                    {visitaPatMsgs.ASSINA_TEC}
                    <LeftMarginSpan><BoolColoredCircleIcon flagTF={!!visita.assinaturaTecnico} size={12}/></LeftMarginSpan>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="chegadaSaidaAval">

                  <TableCell colSpan={3} align={'center'}>
                    <div style={{
                      background: `no-repeat url(${visita.assinaturaCliente}) 50%`,
                      backgroundSize: 'contain',
                      resize: 'both',
                      width: '100%',
                      height: imgSize(visita.assinaturaCliente),
                    }}/>
                  </TableCell>

                  <TableCell colSpan={3} align={'center'}>
                    <div style={{
                      background: `no-repeat url(${visita.assinaturaTecnico}) 50%`,
                      backgroundSize: 'contain',
                      resize: 'both',
                      width: '100%',
                      height: imgSize(visita.assinaturaTecnico),
                    }}/>
                  </TableCell>

                </TableRow>
              </TableBody>
            </>}

            {/* ----------------------------------------------------------------- */}
            {(states.isResolvidaOuConcluida) && <>
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan={6}>{visitaPatMsgs.OBS_FINAIS}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.fadedTableBody}>
                <TableRow key="mensagem">
                  <TableCell colSpan={6} className={classes.obsTableCell}>
                  <span className={classes.obsBox}
                        dangerouslySetInnerHTML={{__html: visita.obsFinal}}/>
                  </TableCell>
                </TableRow>
              </TableBody>
            </>}

          </Table>
        </TableContainer>
      </React.Fragment>)
    : null)
}

TableVisitaPat.defaultProps = {
  data: null,
  showLink: false
}

TableVisitaPat.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    origemVisita: PropTypes.string,
    dhRegVisita: PropTypes.string,
    dhAgendamentoComCliente: PropTypes.string,
    offAgendamento: PropTypes.bool,
    dhChegadaTecnico: PropTypes.string,
    dhSaidaTecnico: PropTypes.string,
    causaEncerrouVisita: PropTypes.string,
    avalVisita: PropTypes.number,
    obsFinal: PropTypes.string,
    assinaturaCliente: PropTypes.string,
    assinaturaTecnico: PropTypes.string,
    estadoVisitaDesc: PropTypes.string,
    obs_count: PropTypes.number,
    images_count: PropTypes.number,
    pdfs_count: PropTypes.number,
  }),
  showLink: PropTypes.bool
}

export { TableVisitaPat }
