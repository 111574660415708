import React from "react"
import Grid from "@material-ui/core/Grid"
import TableAvisos from "../TableAvisos"
import ListaAcoes from "./ListaAcoes"

function GridAvisosAcoes({avisos, acoes}) {
  return (
    <React.Fragment>
      <Grid container spacing={2} justify="center">
        {!!avisos.length && <Grid item sm={12} md={5}>
          <TableAvisos avisos={avisos}/>
        </Grid>}
        {!!acoes.length && <Grid item sm={12} md={7}>
          <ListaAcoes acoes={acoes}/>
        </Grid>}
      </Grid>
    </React.Fragment>
  )
}

export default GridAvisosAcoes
