import React from "react"
import { useAsync } from "react-async"
import { FormContext, useForm } from "react-hook-form"
import { Form } from "../../../components/SharedStyles/SharedStyles"
import Grid from "@material-ui/core/Grid"
import LFSubmitButton from "../../../components/SharedComponents/Shared/LFButtons/LFSubmitButton"
import { LFCheckBoxInput } from "../../../components/LFInputs/LFCheckBoxInput"
import planosAplicaveisMsgs from "../msgsPlanosAplicaveis"

/**
 *
 * Este Formulário apenas serve de confirmação para a associação do Plano ao Equipamento
 * @param method
 * @param planoID
 * @param equipID
 * @param dataInic
 * @returns {*}
 * @constructor
 */
function AssocPlanoToEquipForm({method, plano, numserie, equipID, dataInic, setEquip}) {
  const {isPending, run} = useAsync({
    deferFn: method,
    onResolve: (planoAplicavel) => {
      setEquip(planoAplicavel.equipCli.data)
    }
  })

  const methods = useForm({
    defaultValues: {confirm: false}
  })

  const {handleSubmit, control, formState} = methods

  const onSubmit = () => {
    run({plano_id: plano.id, equip_id: equipID, dataInic: dataInic})
  }

  return (
    <div>
      <FormContext {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} justify="center">
            {/*---------------------------------- confirm ----------------------------------*/}
            <Grid item xs={12}>
              <LFCheckBoxInput control={control} name='confirm'
                               label={planosAplicaveisMsgs.ASSOC_CONFIRM_LABEL(plano.designacao, numserie, dataInic)}/>
            </Grid>
            <Grid item xs={12}>
              <LFSubmitButton isLoading={isPending} dirty={!formState.dirty}>
                {planosAplicaveisMsgs.BUTTON.ASSOC}
              </LFSubmitButton>
            </Grid>
          </Grid>
        </Form>
      </FormContext>
    </div>
  )
}

export default AssocPlanoToEquipForm
