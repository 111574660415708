import React, { useContext, useState } from "react"
import { FormContext, useForm } from "react-hook-form"
import Grid from "@material-ui/core/Grid"
import * as Yup from "yup"
import { requiredValidation } from "../../../../constants/appValidation"
import { NO_IMAGE } from "../../../../constants/appConst"
import { Form } from "../../../SharedStyles/SharedStyles"
import LFImageInput from "../../../LFInputs/ImageUploader/LFImageInput"
import LFTextAreaInput from "../../../LFInputs/LFTextAreaInput"
import LFSubmitButton from "../../Shared/LFButtons/LFSubmitButton"
import { imagemMsgs } from "./imagemMsgs"
import PropTypes from "prop-types"
import { ListRepoState } from "../ListRepoGest"
import { handleSaveRepoItem } from "../handleSaveRepoItem"
import { repoMsgs } from "../repoMsgs"

const validationSchema = Yup.object().shape({
  image: requiredValidation(imagemMsgs.EDIT.LABEL.IMAGEM)
})

const defaultValues = imagem => {
  return {
    text: imagem ? imagem.text : '',
    image: imagem ? imagem.image : NO_IMAGE
  }
}

function ImagemForm({FKfieldname}) {
  const [isPending, setIsPending] = useState(false)

  const {showList, showState, setRepoList, repo, createRepoFn, readReposFn} = useContext(ListRepoState)
  const imagem = showState.data
  const method = (data) => handleSaveRepoItem({
    showList: showList,
    showState: showState,
    repo: repo,
    setRepoList: setRepoList,
    data: data,
    createRepoFn: createRepoFn,
    readReposFn: readReposFn,
  }).then(_ => {
    setIsPending(false)
  })

  const methods = useForm({
    validationSchema: validationSchema,
    defaultValues: defaultValues(imagem)
  })

  const {handleSubmit, formState, control} = methods

  const onSubmit = ({...values}) => {
    const convertValues = {
      ...values,
    }
    convertValues[FKfieldname] = repo.id
    setIsPending(true)
    method({...imagem, ...convertValues})
  }

  return (
    <FormContext {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} justify="center">
          {/*  -------------------------------------- IMAGE ----------------------------------------*/}
          <Grid item xs={12}>
            <LFImageInput image={imagem ? imagem.image : NO_IMAGE} control={control} name="image"
                          label={imagemMsgs.EDIT.LABEL.IMAGEM}
                          maxSize={2048 * 2048}/>
          </Grid>
          {/* -------------------------------------- TEXT -----------------------------------------*/}
          <Grid item xs={12}>
            <LFTextAreaInput label={imagemMsgs.EDIT.LABEL.TEXT} name='text'/>
          </Grid>
        </Grid>
        <LFSubmitButton isLoading={isPending} dirty={!formState.dirty}>
          {imagem ? repoMsgs.BUTTON.EDIT : repoMsgs.BUTTON.NEW}
        </LFSubmitButton>
      </Form>
    </FormContext>
  )
}

ImagemForm.propTypes = {
  FKfieldname: PropTypes.string.isRequired,
}

export { ImagemForm }
