import React from "react"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import PropTypes from "prop-types"
import { StyledTableCell, TableMarginTop } from "../../../SharedStyles/SharedStyles"

const msgsTableDuracao = {
  TITLE: {
    DURACAO: "Duração para Clientes",
  },
  LABEL: {
    MESES: " (Meses)",
    NO_DUR: "Sem Duração",
    DOMEST: "Domésticos",
    INSTIT: "Institucionais",
  }
}

const TableDuracao = ({durCliDomest, durCliInstit}) => {
  const classes = TableMarginTop()
  return (
    <TableContainer>
      <Table className={classes.table} size="small" aria-label="TableDescontos">
        <TableHead>
          <TableRow>
            <StyledTableCell colSpan="2">{msgsTableDuracao.TITLE.DURACAO}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key="dom">
            <TableCell>{msgsTableDuracao.LABEL.DOMEST}</TableCell>
            <TableCell
              align="right">{durCliDomest === 0 ? msgsTableDuracao.LABEL.NO_DUR :
              durCliDomest + msgsTableDuracao.LABEL.MESES}</TableCell>
          </TableRow>
          <TableRow key="inst">
            <TableCell>{msgsTableDuracao.LABEL.INSTIT}</TableCell>
            <TableCell
              align="right">{durCliInstit === 0 ? msgsTableDuracao.LABEL.NO_DUR :
              durCliInstit + msgsTableDuracao.LABEL.MESES}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

TableDuracao.defaultValues = {
  durCliDomest: "No value",
  durCliInstit: "No value",
}

TableDuracao.propTypes = {
  durCliDomest: PropTypes.number,
  durCliInstit: PropTypes.number,
}

export default TableDuracao
