import React from "react"
import Grid from "@material-ui/core/Grid"
import { TableVisitaPat } from "../../../components/SharedComponents/Shared/Tables/TableVisitaPat"

const VisitasPat = ({visitas}) => {

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12}>
        {visitas && visitas.map((item, index) => <TableVisitaPat key={index} data={item} showLink={true}/>)}
      </Grid>
    </Grid>
  )
}

export default VisitasPat
