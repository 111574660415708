import { AppMsgs } from "../../constants/appMsgs"
import IconButton from "@material-ui/core/IconButton"
import CheckIcon from "../Svg-Icons/CheckIcon"
import React from "react"
import { errorColor, successColor } from "../../constants/appTheme"
import makeStyles from "@material-ui/core/styles/makeStyles"
import TableCell from "@material-ui/core/TableCell"
import { CircularProgress, lighten } from "@material-ui/core"
import CancelIcon from "../Svg-Icons/CancelIcon"
import PropTypes from "prop-types"

const useToolbarStyles = makeStyles({
  highlight: {
    color: styles => styles.color,
    backgroundColor: styles => lighten(styles.background, 0.85),
  },
})

function ConfirmationRow({row, confirmationHandle, cancelConfirmationHandle, confirmationLoading, styles, action}) {
  const classes = useToolbarStyles(styles)
  return (
    <React.Fragment>
      <TableCell colSpan={(row.cells.length - 1)} className={classes.highlight}>
        {AppMsgs.DEFAULT_VALUES.TABLE.CORFIRMATION_ROW(action)}
      </TableCell>
      <TableCell className={classes.highlight}>
        {confirmationLoading ?
          <CircularProgress size={16}/> :
          (
            <React.Fragment>
              <IconButton style={{padding: "0px 10px"}} onClick={confirmationHandle}>
                <CheckIcon color={successColor}/>
              </IconButton>
              <IconButton style={{padding: "0px 10px"}} onClick={cancelConfirmationHandle}>
                <CancelIcon color={errorColor} size={14}/>
              </IconButton>
            </React.Fragment>
          )}
      </TableCell>
    </React.Fragment>
  )
}

ConfirmationRow.propTypes = {
  row: PropTypes.object.isRequired,
  confirmationHandle: PropTypes.func.isRequired,
  cancelConfirmationHandle: PropTypes.func.isRequired,
  confirmationLoading: PropTypes.bool.isRequired,
  styles: PropTypes.shape({
    color: PropTypes.string,
    background: PropTypes.string
  }).isRequired,
  action: PropTypes.string.isRequired
}

export default ConfirmationRow
