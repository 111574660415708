import { FormContext, useForm } from "react-hook-form"
import React, { useContext } from "react"
import * as Yup from "yup"
import { betweenNumberValidation, minNumberValidation, selectValidation } from "../../../constants/appValidation"
import LFInput from "../../../components/LFInputs/LFInput"
import LFSubmitButton from "../../../components/SharedComponents/Shared/LFButtons/LFSubmitButton"
import Grid from "@material-ui/core/Grid"
import { Form } from "../../../components/SharedStyles/SharedStyles"
import pecasPatMsgs from "./msgs"
import LFSelect from "../../../components/LFInputs/LFSelect"
import { handleSavePecaPat, PecasPatState } from "./PecasPatGest"


const validationSchema = Yup.object().shape({
  peca_id: selectValidation(pecasPatMsgs.EDIT.LABEL.PECA),
  qtd: minNumberValidation(1, pecasPatMsgs.EDIT.LABEL.QTD),
  unitPrice: minNumberValidation(0, pecasPatMsgs.EDIT.LABEL.UNIT_PRICE),
  percDesc: betweenNumberValidation(0, 100, pecasPatMsgs.EDIT.LABEL.DESCONTO),
  percIVA: betweenNumberValidation(0, 100, pecasPatMsgs.EDIT.LABEL.IVA)
})

const defaultValues = pecaPat => {
  return {
    peca_id: pecaPat ? {index: pecaPat.peca_id, title: pecaPat.desc} : '',
    qtd: pecaPat ? pecaPat.qtd : '1',
    unitPrice: pecaPat ? pecaPat.unitPrice : '0',
    percDesc: pecaPat ? pecaPat.percDesc : '0',
    percIVA: pecaPat ? pecaPat.percIVA : '23',
  }
}

function PecaPatForm() {

  const {showList, pecasObj, showState, setPecasObj} = useContext(PecasPatState)
  const pecaPat = showState.data
  const method = (data) => {
    handleSavePecaPat({showList: showList, showState: showState, ...pecasObj, data: data, setPecasObj: setPecasObj})
  }
  // const {isPending, run} = useAsync({
  //   deferFn: method,
  //   onResolve: data => pecaPat ? reset(defaultValues(data.data)) : reset({qtd: 1, peca_id: null})
  // })

  const methods = useForm({
    validationSchema: validationSchema,
    defaultValues: defaultValues(pecaPat)
  })

  const {
    handleSubmit, formState, control
    //reset
  } = methods

  const onSubmit = ({...values}) => {
    const convertValues = {
      ...values,
      ...{
        peca_id: values.peca_id.index,
        pat_id: pecasObj.pat.id,
      },
    }
    method({...pecaPat, ...convertValues})
    // run({...pecaPat, ...convertValues})
  }

  return (
    <div>
      <FormContext {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} justify="center">
            {/*---------------------------------- PEÇA ----------------------------------*/}
            <Grid item xs={4}>
              <LFSelect options={pecasObj.allPecas} name="peca_id" control={control}
                        label={pecasPatMsgs.EDIT.LABEL.PECA}/>
            </Grid>
            {/*-------------------------------------- QTD ----------------------------------------*/}
            <Grid item xs={2}>
              <LFInput label={pecasPatMsgs.EDIT.LABEL.QTD} name='qtd'/>
            </Grid>
            {/*-------------------------------------- UNIT_PRICE ----------------------------------------*/}
            <Grid item xs={2}>
              <LFInput label={pecasPatMsgs.EDIT.LABEL.UNIT_PRICE} name='unitPrice'/>
            </Grid>
            {/*-------------------------------------- Desconto ----------------------------------------*/}
            <Grid item xs={2}>
              <LFInput label={pecasPatMsgs.EDIT.LABEL.DESCONTO} name='percDesc'/>
            </Grid>
            {/*-------------------------------------- IVA ----------------------------------------*/}
            <Grid item xs={2}>
              <LFInput label={pecasPatMsgs.EDIT.LABEL.IVA} name='percIVA'/>
            </Grid>
          </Grid>
          <LFSubmitButton
            // isLoading={isPending}
            dirty={!formState.dirty}>
            {pecaPat ? pecasPatMsgs.BUTTON.EDIT : pecasPatMsgs.BUTTON.NEW}
          </LFSubmitButton>
        </Form>
      </FormContext>
    </div>
  )
}

export default PecaPatForm
