import React, { useState } from "react"
import { Redirect, useHistory } from "react-router"
import Title from "../../../components/SharedComponents/Shared/Title"
import { createPeca, pecasURLname } from "./apiPecas"
import pecasMsgs from "./msgs"
import PecaForm from "./PecaForm"
import { getRequest } from "../../../api/api"

function PecaCreate() {
  const [peca, setPeca] = useState(null)

  const history = useHistory()
  const handleBack = () => history.push(getRequest({URLname: pecasURLname}).paginatePath())

  if (peca)
    // eslint-disable-next-line no-undef
    return <Redirect to={getRequest({URLname: pecasURLname, id: peca.id}).gotoItemPath()}/>

  return (
    <React.Fragment>
      <Title handleBack={handleBack}>{pecasMsgs.BACK_LABEL}</Title>
      <PecaForm peca={peca} method={createPeca} setPeca={setPeca}/>
    </React.Fragment>
  )
}

export default PecaCreate
