import styled from "@material-ui/core/styles/styled"
import { textSecondary } from "../../constants/appTheme"

const StyledLabel = styled('span')(() => ({
  color: textSecondary,
  marginRight: '5px',
  fontSize: '90%'
}))

export default StyledLabel
