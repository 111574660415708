import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App'
import AppProviders from "./context"

ReactDOM.render(
  <AppProviders>
    {/*<DevTools />*/}
    <App/>
  </AppProviders>,
  document.getElementById('root'))

