import React from "react"
import { Redirect, Route } from "react-router-dom"
import { useUser } from "../../context/user-context"

const PrivateRoute = ({component: Component, ...rest}) => {
  const {user} = useUser()
  return (

    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /login page
    <Route {...rest} render={props => (
      user ?
        <Component {...props} />
        : <Redirect to="/login"/>
    )}/>
  )
}

const PublicRoute = ({component: Component, restrictIfAuth, ...rest}) => {
  const {user} = useUser()
  return (
    // restrictIfAuth = false meaning public route
    // restrictIfAuth = true meaning restricted route
    <Route {...rest} render={props => (
      user && restrictIfAuth ?
        <Redirect to="/"/>
        : <Component {...props} />
    )}/>
  )
}

const RestrictedRoute = ({component: Component, ...rest}) => {
  return (
    <Route {...rest} render={props => (
      <Component {...props} />
    )}/>
  )
}

export { PublicRoute, PrivateRoute, RestrictedRoute }

