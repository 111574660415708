import React from 'react'
import Grid from "@material-ui/core/Grid"
import PropTypes from "prop-types"
import { whiteColor } from "../../../constants/appTheme"
import Fab from "@material-ui/core/Fab"
import Typography from "@material-ui/core/Typography"
import KeyArrowLeftIcon from "../../Svg-Icons/KeyArrowLeftIcon"


export default function Title({handleBack, ...props}) {

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} style={{marginBottom: '15px'}}>
        <Fab onClick={handleBack} size="small" color="secondary" variant="extended" style={{boxShadow: 'none'}}>
          <KeyArrowLeftIcon size={22} color={whiteColor}/>
          <Typography variant="body2" style={{paddingLeft: '5px'}}
                      display={'inline'}> {props.children}</Typography>
        </Fab>
      </Grid>
    </Grid>
  )
}

Title.propTypes = {
  handleBack: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired,
}
