import { loginRequest, readLogged } from "../pages/Login/apiLogin"

const localStorageKey = '__imm_token__'

function handleUserResponse({token, ...user}) {
  window.localStorage.setItem(localStorageKey, token)
}

function getUser() {
  const token = getToken()
  if (!token) {
    return Promise.resolve(null)
  }
  return readLogged().catch(error => {
    logout()
    return Promise.reject(error)
  })
}

function login({...values}) {
  return loginRequest(values).then(handleUserResponse)
}

function logout() {
  window.localStorage.removeItem(localStorageKey)
  return Promise.resolve()
}

function getToken() {
  return window.localStorage.getItem(localStorageKey)
}

export { login, logout, getToken, getUser }

