import React from "react"
import Grid from "@material-ui/core/Grid"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { TableContactoCLI } from "./TableContactoCLI"
import { TablePatMainProps } from "./TablePatMainProps"
import { immBgUltraLight } from "../../../../constants/appTheme"
import { TableInfoPatAnulado } from "./TableInfoPatAnulado"
import { TableInfoRelatorioTec } from "./TableInfoRelatorioTec"

const useStyles = makeStyles((theme) => ({
  anulado: {
    background: immBgUltraLight,
  },
  bail: {},
}))

function RenderPATTables({pat, lastEstado}) {
  const classes = useStyles()
  const patIsAnulado = (!!pat.anulado_at)
  const patIsRegRelatorioTecnico = (!!pat.dhRegRelatorioTecnico)

  return (
    <Grid container spacing={1} justify="space-between"
          className={patIsAnulado ? classes.anulado : classes.bail}>

      {patIsAnulado ? <Grid item xs={12} sm={12} md={12}>
        <TableInfoPatAnulado pat={pat}/>
      </Grid> : null}

      <Grid item xs={12} sm={8} md={6}>
        <TablePatMainProps pat={pat} lastEstado={lastEstado}/>
      </Grid>

      <Grid item xs={12} sm={4} md={6}>
        <TableContactoCLI pat={pat}/>
      </Grid>

      {patIsRegRelatorioTecnico ? <Grid item xs={12} sm={12} md={12}>
        <TableInfoRelatorioTec pat={pat}/>
      </Grid> : null}

    </Grid>
  )
}

export default RenderPATTables
