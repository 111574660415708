import { api, apiCUD, apiReadTable, getRequest } from "../../../api/api"
import Instaladores from "./Instaladores"
import InstaladorIcon from "../../../components/Svg-Icons/InstaladorIcon"
import InstaladorView from "./InstaladorView"
import msgsInstaladores from "./msgsInstaladores"

const instaladoresUrl = 'instaladores'

// ----------------------- INSTALADORES ROUTES -----------------------
const instaladoresRouteProps = {
  path: getRequest({URLname: instaladoresUrl}).paginatePath(),
  exact: true,
  name: msgsInstaladores.LABEL,
  component: Instaladores,
  Icon: InstaladorIcon,
  breadcrumb: () => msgsInstaladores.LABEL,
  reqRole: 'R'
}

const instaladorViewRouterProps = {
  path: getRequest({URLname: instaladoresUrl}).viewPath(),
  name: msgsInstaladores.UPDATE_LABEL,
  exact: true,
  component: InstaladorView,
  breadcrumb: (props) =>
    !!parseInt(props.match.params.id, 10) ?
      msgsInstaladores.UPDATE_LABEL :
      msgsInstaladores.CREATE_LABEL,
  reqRole: 'R'
}

// ----------------------- INSTALADORES ROUTES -----------------------

/**
 * Read Instaladores com paginação para tabela
 * @param query
 * @returns {Promise<*|void>}
 */
async function readInstaladoresTable([query]) {
  const request = getRequest({URLname: instaladoresUrl, query}).GET()
  return apiReadTable(request)
}

/**
 * Read Instalador
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
function readInstalador({id}) {
  const request = getRequest({URLname: instaladoresUrl, id}).GET()
  return api(request).then(r => r.data.data)
}

function createInstalador([data]) {
  const request = getRequest({URLname: instaladoresUrl}).POST()
  return apiCUD(request, data).then(r => r.data)
}

function updateInstalador([data]) {
  const request = getRequest({URLname: instaladoresUrl, id: data.id}).PUT()
  return apiCUD(request, data).then(r => r.data)
}

async function deleteInstalador(id) {
  const request = getRequest({URLname: instaladoresUrl, id}).DELETE()
  return apiCUD(request)
}

async function undeleteInstalador(id) {
  const request = getRequest({URLname: instaladoresUrl, id}).PUT('undelete')
  return apiCUD(request)
}

export {
  instaladoresUrl,
  instaladoresRouteProps,
  instaladorViewRouterProps,
  readInstaladoresTable,
  readInstalador,
  createInstalador,
  updateInstalador,
  deleteInstalador,
  undeleteInstalador
}
