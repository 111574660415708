import { Controller, useFormContext } from "react-hook-form"
import { KeyboardDateTimePicker } from "@material-ui/pickers"
import React from "react"

const LFDateTimePickerInput = ({name, control, label, disabled}) => {
  const {errors} = useFormContext()
  return (
    <Controller
      as={
        <KeyboardDateTimePicker
          autoOk
          variant="inline"
          inputVariant="outlined"
          ampm={false}
          format="L LT"
          fullWidth
          disabled={disabled}
          label={label}
          error={!!errors[name]}
          helperText={errors[name] ? errors[name].message : ''}
          validationError={errors[name] ? errors[name].message : ''}
        />
      }
      onChange={([e, data]) => {
        return e
      }}
      name={name}
      id={name}
      control={control}
      autoOk
    />
  )
}

export default LFDateTimePickerInput
