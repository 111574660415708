import React from "react"
import SvgIcon from "./SvgIcon"

const ClienteIcon = (others) => {
  return (
    <SvgIcon viewBox="0 0 512 512"  {...others}>
      <g>
        <g>
          <path d="M495.984,252.588c-17.119-14.109-44.177-15.319-61.936,3.74l-44.087,47.327c-5.7-18.319-22.809-31.658-42.977-31.658
			h-78.675c-5.97,0-7.969-2.28-18.339-10.269c-39.538-34.468-98.924-34.358-138.342,0.33l-28.918,25.458
			c-12.999-6.88-28.178-7.05-41.248-0.52L8.294,303.575c-7.41,3.71-10.409,12.719-6.71,20.129l89.995,179.989
			c3.71,7.41,12.719,10.409,20.129,6.71l33.168-16.589c16.349-8.169,25.448-24.849,24.858-41.827h177.249
			c32.868,0,64.276-15.699,83.995-41.997l72.006-96.014C516.953,295.366,514.743,268.077,495.984,252.588z M131.456,466.985
			l-19.749,9.879L35.122,323.704l19.759-9.879c7.41-3.7,16.409-0.71,20.119,6.71l63.166,126.332
			C141.866,454.276,138.866,463.275,131.456,466.985z M478.985,295.976L406.98,391.99c-14.089,18.789-36.518,29.998-59.996,29.998
			H159.265l-56.207-112.423l28.388-24.988c28.248-24.849,70.846-24.849,99.094,0c16.639,14.649,26.988,17.419,37.768,17.419h78.675
			c8.27,0,14.999,6.73,14.999,14.999s-6.73,14.999-14.999,14.999h-76.605c-8.28,0-14.999,6.72-14.999,14.999
			s6.72,14.999,14.999,14.999h86.655c12.449,0,24.449-5.22,32.928-14.329l66.036-70.886c6.04-6.48,15.299-5.94,20.979-0.97
			C482.915,281.006,483.556,289.896,478.985,295.976z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M315.385,102.367c10.269-10.769,16.599-25.328,16.599-41.358c0-33.018-26.678-60.996-59.996-60.996
			c-33.068,0-60.996,27.928-60.996,60.996c0,15.539,6.09,30.208,17.149,41.478c-27.428,15.379-47.147,44.897-47.147,79.515v14.999
			c0,8.279,6.72,14.999,14.999,14.999h150.991c8.279,0,14.999-6.72,14.999-14.999v-14.999
			C361.982,148.064,343.315,118.086,315.385,102.367z M271.988,30.012c16.259,0,29.998,14.199,29.998,30.998
			c0,16.539-13.459,29.998-29.998,29.998c-16.799,0-30.998-13.739-30.998-29.998C240.99,44.501,255.479,30.012,271.988,30.012z
			 M210.992,182.002c0-33.068,27.928-60.996,60.996-60.996c33.078,0,59.996,27.358,59.996,60.996H210.992z"/>
        </g>
      </g>
    </SvgIcon>
  )
}

export default ClienteIcon
