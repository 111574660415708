export const createSortedMap = (data, keyName, keyTrans) => {

  const auxMap = new Map()

  // para as chaves não é necessário testar a igualdade
  // pois chaves com o mesmo valor são substituidas
  const sortStringOrNumber = (a, b) => a[0] > b[0] ? 1 : -1

  data.forEach((perm, index) => {
    const parts = perm[keyName].split('.')
    if (parts[0]) {
      let val = {trans: '', perms: []}
      if (auxMap.has(parts[0])) {
        val = auxMap.get(parts[0])
        val.perms.push(perm)
      } else {
        val = {trans: (keyTrans[parts[0]] || ''), perms: [perm]}
      }
      auxMap.set(parts[0], val)
    }
  })

  return new Map([...auxMap].sort(sortStringOrNumber))
}
