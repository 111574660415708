import React from "react"
import styled from "@material-ui/core/styles/styled"
import Grid from "@material-ui/core/Grid"
import PropTypes from "prop-types"

const Text = styled('span')(({theme}) => ({
  color: theme.palette.error.main,
  fontSize: '12px',
}))

export default function InputError({...props}) {
  return (
    <Grid item xs={12} style={{display: !props.children && 'none'}}>
      <Text>
        {props.children}
      </Text>
    </Grid>
  )
}
InputError.propTypes = {
  children: PropTypes.string
}


