import React from "react"
import SvgIcon from "./SvgIcon"

const KeyArrowDownIcon = (others) => {
  return (
    <SvgIcon  {...others}>
      <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/>
      <path d="M0 0h24v24H0V0z" fill="none"/>
    </SvgIcon>
  )
}

export default KeyArrowDownIcon
