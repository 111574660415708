import { fade, makeStyles } from "@material-ui/core"
import { drawerWidth, primaryColorLight } from "../../../../constants/appTheme"


const leftMenubarStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxShadow: '0 2px 10px 0px rgba(0, 0, 0, .3)',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(6) + 1,
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '5px',
    maxHeight: '64px',
    ...theme.mixins.toolbar,
  },
  imgHeader: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imgHeaderLogo: {
    height: '55px',
  },
  menuItem: {
    borderTop: '1px solid rgb(224, 224, 224)',
    '&:hover': {
      backgroundColor: fade(primaryColorLight, 0.2)
    }
  },
  textIcon: {
    fontSize: '20px',
    minWidth: '51px',
    flexShrink: '0',
    fontWeight: '300'
  },
  expandable: {
    backgroundColor: fade(theme.palette.divider, 0.04),
    '& a': {
      borderTop: 'none'
    },
  },
  listItemText: {
    paddingLeft: '0px'
  },
  noMarginBox: {
    padding: 0,
    margin: 0,
  },
  navLinkActive: {
    backgroundColor: primaryColorLight,
    transition: 'border-bottom .5s ease-in-out',
    pointerEvents: 'none'
  },
}))

export default leftMenubarStyles
