const patMsgs = {
    LABEL: "PAT",
    NEW_LABEL: "Nova PAT",
    EQUIP_LABEL: "PATS DO EQUIPAMENTO",
    BACK_LABEL: "Voltar aos PAT",
    CANCEL_EDIT: "Cancelar o registo",
    OPEN_BUTTON: "Abrir PAT",
    DIALOG_BUTTON: "Ver PAT",
    TABLE: {
        HEADER: {
            REF_CODE: "Referência",
            TIPO: "Tipo",
            TIPO_PAT: "Tipo PAT",
            DH_CONTACTO_CLI: "contacto",
            DH_VISITA: "visita",
            ESTADO: "Estado"
        },
  },
  TECNICO: 'Técnico',
  ESTADOS: 'Estados',
  VISITAS: 'Visitas',
  EQUICLI: 'Equipamento',
  CLIENTE: 'Cliente',
  ACAOPERIODO: 'Ação',
  PAT_REPORT: 'Report',

  PECASPAT: 'Peças',
  IMAGES: 'Imagens',
  LASTVISITA: 'Última visita',
  LASTESTADO: 'Último estado',

  ANULADO: 'Anulado',
  ISPAGO: 'Pago?',
  ISFATURADO: 'Faturado?',

  PLANOACTIV: 'Plano ativado',

  INFO_CONTACTO_CLIENTE: 'Contacto do Cliente',
  OBS_INIC: 'Observações iniciais',

  DATA_LIMITE: 'Data Limite',

  EXPAND_STATES: 'Expandir...',
  CONTRACT_STATES: 'Contrair...',

  RELATORIO_REC: 'Relatório Técnico',

}

export default patMsgs
