import React from "react"
import ClienteUpdate from "./ClienteUpdate"
import ClienteCreate from "./ClienteCreate"
import TabsComponent from "../../../components/SharedComponents/Shared/TabsComponent"
import { deleteEquip } from "../../Equips/ListEquips/apiEquips"
import { useHistory, useParams } from "react-router"
import Title from "../../../components/SharedComponents/Shared/Title"
import { clientesUrl, readClienteEquips } from "./apiClientes"
import EquipsTableCustom from "../../Equips/ListEquips/EquipsTableCustom"
import { getRequest } from "../../../api/api"
import msgsClientes from "./msgsClientes"
import msgsEquips from "../../Equips/ListEquips/msgsEquips"
import PatsCli from "../../Pats/X_PatsPorEntidades/PatsCli"


const cliTabs = (id) => {
  return ([
    {
      label: msgsClientes.TAB_LABEL,
      index: 0,
      component: <ClienteUpdate id={id}/>,
    },
    {
      label: msgsEquips.LABEL,
      index: 1,
      component: <EquipsTableCustom
        id={id}
        requestFn={(props) => readClienteEquips(props, id)}
        deleteFn={deleteEquip}
        showAddButton={true}
        showEditButton={true}
        showDeleteButton={true}
        addButtonDesc={msgsEquips.TABLE.ADD_BUTTON}
      />
    },
    {
      label: msgsEquips.TABS.PAT,
      index: 2,
      component: <PatsCli cliID={id}/>,
    }
  ])
}

function ClienteView() {
  let {id} = useParams()

  const history = useHistory()
  const handleBack = () => history.push(getRequest({URLname: clientesUrl}).paginatePath())

  return (
    <React.Fragment>
      <Title handleBack={handleBack}>{msgsClientes.BACK_LABEL}</Title>
      {(!!parseInt(id, 10))
        ? <TabsComponent renderTabs={cliTabs(id)}/>
        : <ClienteCreate/>
      }
    </React.Fragment>
  )
}

export default ClienteView
