const repoMsgs = {

  CONFIRM_DIALOG_TITLE: "Confirmação...",
  YES: "Sim",
  NO: "Não",

  BUTTON: {
    NEW: "Adicionar",
    EDIT: "Guardar"
  },
}

export { repoMsgs }

