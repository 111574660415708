import React from "react"
import { useAsync } from "react-async"
import { FormContext, useForm } from "react-hook-form"
import { Form } from "../../../components/SharedStyles/SharedStyles"
import Grid from "@material-ui/core/Grid"
import LFInput from "../../../components/LFInputs/LFInput"
import LFSubmitButton from "../../../components/SharedComponents/Shared/LFButtons/LFSubmitButton"
import * as Yup from "yup"
import { minNumberValidation, requiredValidation } from "../../../constants/appValidation"
import pecasMsgs from "./msgs"
import { LFMoneyInput } from "../../../components/LFInputs/LFMoneyInput"

const validationSchema = Yup.object().shape({
  ref: requiredValidation(pecasMsgs.EDIT.LABEL.REF),
  desc: requiredValidation(pecasMsgs.EDIT.LABEL.DESC),
  unitPrice: minNumberValidation(0, pecasMsgs.EDIT.LABEL.UNIT_PRICE)
})

const defaultValues = (peca) => {
  return {
    ref: peca ? peca.ref : '',
    desc: peca ? peca.desc : '',
    unitPrice: peca ? peca.unitPrice : '0'
  }
}

function PecaForm({peca, method, setPeca}) {
  const {isPending, run} = useAsync({
    deferFn: method,
    onResolve: (newPeca) => {
      setPeca(newPeca)
      reset(newPeca)
    }
    //Reject não é necessário porque foi capturado no apiCUD
  })

  const methods = useForm({
    validationSchema: validationSchema,
    defaultValues: defaultValues(peca)
  })
  const {handleSubmit, formState, reset} = methods

  const onSubmit = (values) => {
    run({...peca, ...values})
  }

  return (
    <FormContext {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} justify="center">
          {/* -------------------------------------- ref -----------------------------------------*/}
          <Grid item xs={12} sm={6}>
            <LFInput label={pecasMsgs.EDIT.LABEL.REF} name='ref'/>
          </Grid>
          {/* -------------------------------------- unitPrice ------------------------------------*/}
          <Grid item xs={12} sm={6}>
            <LFMoneyInput label={pecasMsgs.EDIT.LABEL.UNIT_PRICE} name='unitPrice'/>
          </Grid>
          {/* -------------------------------------- desc -----------------------------------------*/}
          <Grid item xs={12}>
            <LFInput label={pecasMsgs.EDIT.LABEL.DESC} name='desc'/>
          </Grid>
        </Grid>
        <LFSubmitButton isLoading={isPending} dirty={!formState.dirty}>
          {peca ? pecasMsgs.BUTTON.EDIT : pecasMsgs.BUTTON.NEW}
        </LFSubmitButton>
      </Form>
    </FormContext>
  )
}

export default PecaForm
