import React from "react"
import SvgIcon from "./SvgIcon"

const SimulaIcon = (others) => {
  return (
    <SvgIcon viewBox="0 0 480 480"  {...others}>
      <path d="m288 360h16v16h-16zm0 0"/>
      <path
        d="m248 432h-17.222656l-5.335938-32h86.558594c22.082031-.027344 39.972656-17.917969 40-40v-192c-.027344-22.082031-17.917969-39.972656-40-40h-272c-22.082031.027344-39.9726562 17.917969-40 40v192c.0273438 22.082031 17.917969 39.972656 40 40h86.558594l-5.335938 32h-17.222656c-8.835938 0-16 7.164062-16 16v16c0 8.835938 7.164062 16 16 16h144c8.835938 0 16-7.164062 16-16v-16c0-8.835938-7.164062-16-16-16zm-208-288h272c13.253906 0 24 10.746094 24 24v168h-72.398438c27.847657-43.519531 19.523438-100.886719-19.546874-134.699219-39.070313-33.808593-97.039063-33.808593-136.109376 0-39.070312 33.8125-47.394531 91.179688-19.546874 134.699219h-72.398438v-168c0-13.253906 10.746094-24 24-24zm68.183594 192c-11.304688-13.628906-18.214844-30.363281-19.816406-48h7.632812v-16h-7.59375c.921875-9.976562 3.554688-19.71875 7.785156-28.800781l6.527344 3.769531 8-13.855469-6.527344-3.769531c5.796875-8.179688 12.933594-15.316406 21.113282-21.113281l3.765624 6.53125 13.859376-8-3.769532-6.53125c9.089844-4.246094 18.847656-6.894531 28.839844-7.824219v7.59375h16v-7.59375c9.988281.917969 19.746094 3.550781 28.839844 7.785156l-3.769532 6.527344 13.859376 8 3.765624-6.527344c8.179688 5.796875 15.316407 12.933594 21.113282 21.113282l-6.527344 3.765624 8 13.859376 6.527344-3.769532c4.230468 9.082032 6.863281 18.824219 7.785156 28.800782h-7.59375v16h7.632812c-1.601562 17.632812-8.511718 34.371093-19.816406 48zm-92.183594 24v-8h320v8c0 13.253906-10.746094 24-24 24h-272c-13.253906 0-24-10.746094-24-24zm126.777344 40h66.445312l5.335938 32h-77.117188zm105.222656 64h-144v-16h144zm0 0"/>
      <path
        d="m210.34375 234.34375-24.0625 24.054688c-3.203125-1.5625-6.71875-2.382813-10.28125-2.398438-13.253906 0-24 10.746094-24 24s10.746094 24 24 24 24-10.746094 24-24c-.015625-3.5625-.835938-7.078125-2.398438-10.28125l24.0625-24.0625zm-34.34375 53.65625c-4.417969 0-8-3.582031-8-8s3.582031-8 8-8 8 3.582031 8 8-3.582031 8-8 8zm0 0"/>
      <path d="m176 216c-35.328125.039062-63.960938 28.671875-64 64h16c.027344-26.5 21.5-47.972656 48-48zm0 0"/>
      <path d="m368 312h16v32h-16zm0 0"/>
      <path d="m400 312h16v32h-16zm0 0"/>
      <path d="m432 312h16v32h-16zm0 0"/>
      <path d="m368 216h16v32h-16zm0 0"/>
      <path d="m400 216h16v32h-16zm0 0"/>
      <path d="m432 216h16v32h-16zm0 0"/>
      <path d="m368 120h16v32h-16zm0 0"/>
      <path d="m400 120h16v32h-16zm0 0"/>
      <path d="m432 120h16v32h-16zm0 0"/>
      <path
        d="m464 80c8.835938 0 16-7.164062 16-16v-48c0-8.835938-7.164062-16-16-16h-240c-8.835938 0-16 7.164062-16 16v48c0 8.835938 7.164062 16 16 16h8v16h-8c-8.835938 0-16 7.164062-16 16h256v48h-96v16h40v16h-40v16h96v48h-96v16h40v16h-40v16h96v48h-96v16h40v16c0 4.417969 3.582031 8 8 8h32c4.417969 0 8-3.582031 8-8v-16h8c8.835938 0 16-7.164062 16-16v-48c0-8.835938-7.164062-16-16-16h-8v-16h8c8.835938 0 16-7.164062 16-16v-48c0-8.835938-7.164062-16-16-16h-8v-16h8c8.835938 0 16-7.164062 16-16v-48c0-8.835938-7.164062-16-16-16h-8v-16zm-24 296h-16v-8h16zm0-88h-16v-16h16zm0-96h-16v-16h16zm-216-176h240v48h-240zm184 64v16h-128v-16zm-160 0h16v16h-16zm192 16h-16v-16h16zm0 0"/>
      <path d="m240 32h16v16h-16zm0 0"/>
      <path d="m272 32h16v16h-16zm0 0"/>
      <path d="m304 32h16v16h-16zm0 0"/>
      <path d="m368 24h16v32h-16zm0 0"/>
      <path d="m400 24h16v32h-16zm0 0"/>
      <path d="m432 24h16v32h-16zm0 0"/>
      <path d="m368 448h-88v16h96c4.417969 0 8-3.582031 8-8v-48h-16zm0 0"/>
      <path d="m400 408h16v16h-16zm0 0"/>
      <path d="m400 440h16v16h-16zm0 0"/>
      <path d="m56 56h136v-16h-144c-4.417969 0-8 3.582031-8 8v64h16zm0 0"/>
      <path d="m48 8h16v16h-16zm0 0"/>
      <path d="m80 8h16v16h-16zm0 0"/>
      <path d="m112 8h16v16h-16zm0 0"/>
      <path d="m144 8h16v16h-16zm0 0"/>
    </SvgIcon>
  )
}

export default SimulaIcon
