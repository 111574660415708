import React, { createContext, useCallback, useMemo, useState } from "react"
import AssocTecPat from "./AssocTecPat"
import { AgendaPATForm } from "./AgendaPATForm"

export const AssocTecAgendaPATState = createContext(null)

function AssocTecEAgendaPAT({repo, reload, ...otherProps}) {
  const [state, setState] = useState({type: 'RDA', dataAg: new Date()}) // Read Data Agendamento

  const handleReadDataAgendamento = useCallback(() => setState({type: 'RDA', dataAg: new Date()}), [])
  const handleAssocTec = useCallback((newState) => setState({...newState, type: 'AT'}), [])
  const done = useCallback(() => reload(), [reload])

  const getState = useMemo(() => ({handleReadDataAgendamento, handleAssocTec, done}),
    [handleReadDataAgendamento, handleAssocTec, done])

  return (
    <AssocTecAgendaPATState.Provider value={getState}>
      {((state.type === 'AT')
        ? <AssocTecPat pat={repo} state={state}/>
        : <AgendaPATForm pat={repo}/>)
      }
    </AssocTecAgendaPATState.Provider>
  )
}

export default AssocTecEAgendaPAT
