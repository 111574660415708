import React, { useState } from "react"
import IconButton from "@material-ui/core/IconButton"
import { useAsync } from "react-async"
import Typography from "@material-ui/core/Typography"
import PropTypes from "prop-types"
import { obsMsgs } from "./obsMsgs"
import { repoMsgs } from "../../../../../components/SharedComponents/ListRepo/repoMsgs"
import { AppMsgs } from "../../../../../constants/appMsgs"
import DeleteIcon from "../../../../../components/Svg-Icons/DeleteIcon"
import { errorColorDark, immBgUltraLight } from "../../../../../constants/appTheme"
import { ShowDateTime } from "../../../../../components/SharedComponents/Shared/Show/ShowDateTime"
import CustomDialog from "../../../../../components/SharedComponents/Shared/Dialog/CustomDialog"
import { makeStyles, Paper } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"

const styles = makeStyles(theme => ({
  oddRow: {
    background: immBgUltraLight
  },
  evenRow: {
    background: '#f9f9f9'
  },
  comment: {
    padding: '.5rem',
    minHeight: '1rem'
  }
}))

const OBSGridList = ({getRepoHrefFn, repo, mayEdit, repoList, handleDeleteFn, showList}) => {
  const classes = styles()
  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false)
  const [confirmationItem, setConfirmationItem] = useState({})

  const {run} = useAsync({
    deferFn: handleDeleteFn,
    onResolve: (deletedData) => {
      showList()
    }
  })

  const handleRepoItemDelete = (obs) => {
    setConfirmationDialogVisible(true)
    setConfirmationItem(obs)
  }

  const handleCancel = () => {
    // feito no CustomDialog
    // setConfirmationDialogVisible(false)
    setConfirmationItem({})
  }
  let index = 0
  return (!!repoList.length)
    ? (
      <React.Fragment>

        {repoList.map((obs) => {

          return (
            <Grid key={index} container spacing={2}
                  className={(index++ % 2) ? classes.evenRow : classes.oddRow}
                  direction="row"
                  justify="space-between"
                  alignItems="center">
              {/*---------------------------------------------------------------*/}
              <Grid item xs={2}>
                <Typography color='primary'>{obs.origemObs}</Typography>
                <ShowDateTime dateTime={obs.dhRegisto}/>
              </Grid>
              {/*---------------------------------------------------------------*/}
              <Grid item xs={9}>
                <Paper className={classes.comment}
                       dangerouslySetInnerHTML={{__html: obs.obs}}/>
              </Grid>
              {/*---------------------------------------------------------------*/}
              <Grid item xs={1}>
                {mayEdit ? <IconButton edge="end" aria-label="delete"
                                       onClick={() => handleRepoItemDelete(obs)}>
                  <DeleteIcon color={errorColorDark}/>
                </IconButton> : null}
              </Grid>
            </Grid>
          )
        })}

        <CustomDialog open={confirmationDialogVisible}
                      setOpen={setConfirmationDialogVisible}
                      title={repoMsgs.CONFIRM_DIALOG_TITLE}
                      onConfirm={() => run(confirmationItem.id)}
                      onCancel={() => handleCancel()}
                      msgsConfirm={repoMsgs.YES}
                      msgsCancel={repoMsgs.NO}>
          {obsMsgs.CONFIRM_DIALOG_CONTENT(confirmationItem)}
        </CustomDialog>
      </React.Fragment>
    )
    : (
      <Typography component="h1" variant="h6" color="inherit" noWrap>
        {AppMsgs.DEFAULT_VALUES.TABLE.EMPTY_TABLE_MSG}
      </Typography>
    )
}

OBSGridList.propTypes = {
  getRepoHrefFn: PropTypes.func.isRequired,
  repo: PropTypes.object.isRequired,
  mayEdit: PropTypes.bool.isRequired,
  repoList: PropTypes.array.isRequired,
  handleDeleteFn: PropTypes.func.isRequired,
}

export { OBSGridList }
