import React from "react"
import { H2Slim } from "../../../../components/SharedStyles/SharedStyles"
import armazemMsgs from "../../armazemMsgs"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"
import Typography from "@material-ui/core/Typography"
import immergasLogo from "./immergasLogo.jpg"
import { entradaArmazemStyles } from "./entradaArmazemStyles"

//----------------------------------------
const TEDrawer = ({defaultValue, indexTitleOptList, handleChange}) => {
  const classes = entradaArmazemStyles()
  return (
    <>
      <H2Slim className={classes.titDrawer}>{armazemMsgs.TE}</H2Slim>
      <RadioGroup aria-label="tipoEquipamento"
                  className={classes.optionsGrp}
                  defaultValue={defaultValue}
                  onChange={e => handleChange(indexTitleOptList.find(t => t.index * 1 === e.target.value * 1))}
      >
        {indexTitleOptList.map((item) =>
          <FormControlLabel key={'opt-te' + item.index}
                            className={classes.optionsItem}
                            value={item.index} control={<Radio/>}
                            label={<Typography className={classes.optionsItemLabel}>{item.title}</Typography>}
          />
        )}
      </RadioGroup>
    </>
  )
}

//----------------------------------------

const MarcaDrawer = ({defaultValue, indexTitleOptList, haIMM, IMM, handleChange}) => {
  const classes = entradaArmazemStyles()

  return (
    <>
      <H2Slim className={classes.titDrawer}>{armazemMsgs.MARCA}</H2Slim>

      {haIMM && <img src={immergasLogo}
                     alt="IMMERGAS"
                     onChange={_ => handleChange(IMM)}
                     className={classes.imergasLogo}/>}
    </>
  )
}

//----------------------------------------

const ModeloDrawer = ({defaultValue, indexTitleOptList, handleChange}) => {
  const classes = entradaArmazemStyles()
  return (
    <>
      <H2Slim className={classes.titDrawer}>{armazemMsgs.EQUIP}</H2Slim>
    </>
  )
}

//----------------------------------------

const TransportadorDrawer = ({defaultValue, indexTitleOptList, handleChange}) => {
  const classes = entradaArmazemStyles()
  return (
    <>
      <H2Slim className={classes.titDrawer}>{armazemMsgs.TRANSPORT}</H2Slim>
    </>
  )
}

//----------------------------------------
export {
  TEDrawer,
  MarcaDrawer,
  ModeloDrawer,
  TransportadorDrawer,
}
