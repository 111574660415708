import { CircularProgress } from "@material-ui/core"
import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"


const useStyles = makeStyles({
  root: {
    display: 'relative'
  },
  spinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }
})

export function FullPageSpinner() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <CircularProgress size={50} className={classes.spinner}/>
    </div>
  )
}
