import React from "react"
import { FormContext, useForm } from "react-hook-form"
import { Form } from "../../../components/SharedStyles/SharedStyles"
import Grid from "@material-ui/core/Grid"
import LFSubmitButton from "../../../components/SharedComponents/Shared/LFButtons/LFSubmitButton"
import { AppMsgs } from "../../../constants/appMsgs"
import { useAsync } from "react-async"
import LFInput from "../../../components/LFInputs/LFInput"
import { LFNumberInput } from "../../../components/LFInputs/LFNumberInput"
import LFTextAreaInput from "../../../components/LFInputs/LFTextAreaInput"
import * as Yup from "yup"
import {
  cp3Validation,
  cp4Validation,
  emailValidation,
  nifValidation,
  requiredValidation,
  tlfValidation,
  tlmValidation
} from "../../../constants/appValidation"
import LFZipCodeForm from "../../../components/LFComponentsForm/LFZipCodeForm"
import LFContactForm from "../../../components/LFComponentsForm/LFContactForm"
import LFAsyncSelectInput from "../../../components/LFInputs/LFAsyncSelectInput"
import { readUserSelect } from "../Users/apiUsers"
import LFImageInput from "../../../components/LFInputs/ImageUploader/LFImageInput"
import { NO_IMAGE } from "../../../constants/appConst"
import msgsInstaladores from "./msgsInstaladores"


const validationSchema = Yup.object().shape({
  nome: requiredValidation(AppMsgs.ENTIDADES.EDIT.LABEL.NOME),
  nif: nifValidation(AppMsgs.ENTIDADES.EDIT.LABEL.NIF),
  cp4: cp4Validation(AppMsgs.ENTIDADES.EDIT.LABEL.CP4),
  cp3: cp3Validation(AppMsgs.ENTIDADES.EDIT.LABEL.CP3),
  email: emailValidation(AppMsgs.ENTIDADES.EDIT.LABEL.EMAIL),
  tlm: tlmValidation(AppMsgs.ENTIDADES.EDIT.LABEL.TLM),
  tlf: tlfValidation(AppMsgs.ENTIDADES.EDIT.LABEL.TLF),
  contactRequired: Yup.bool().when(['email', 'tlm', 'tlf'], {
    is: (email, tlm, tlf) => (!email && !tlm && !tlf) /*|| (!!email && !!tlm && !!tlf)*/,
    then: Yup.bool().required(AppMsgs.ERROR.CONTACT_REQUIRED),
    otherwise: Yup.bool()
  }),
  zipCodeRequired: Yup.bool().when(['cp4', 'cp3'], {
    is: (cp4, cp3) => (!cp4 && cp3) || (cp4 && !cp3),
    then: Yup.bool().required(AppMsgs.ERROR.ZIPCODE_REQUIRED),
    otherwise: Yup.bool()
  })
})

const defaultValues = (instalador) => {
  return {
    nome: instalador ? instalador.nome : '',
    // isContaAcessoActiva: instalador ? instalador.isContaAcessoActiva : '',
    logo: instalador ? instalador.logo : NO_IMAGE,
    user_id: (instalador && instalador.user_id) ? {index: instalador.user_id, title: instalador.username} : "",
    nif: instalador ? instalador.nif : '',
    email: instalador ? instalador.email : '',
    tlm: instalador ? instalador.tlm : '',
    tlf: instalador ? instalador.tlf : '',
    obs: instalador ? instalador.obs : '',
    morada: instalador ? instalador.morada : '',
    cp4: instalador ? instalador.cp4 : '',
    cp3: instalador ? instalador.cp3 : ''
  }
}

function InstaladorForm({instalador, method, setInstalador}) {
  const {isPending, run} = useAsync({
    deferFn: method,
    onResolve: (newInstalador) => {
      setInstalador(newInstalador)
      reset(defaultValues(newInstalador)) // forçar default value para o user_id field ficar com o username
    }
  })

  const methods = useForm({
    validationSchema: validationSchema,
    defaultValues: defaultValues(instalador),
  })

  const {handleSubmit, formState, control, reset} = methods

  const onSubmit = (values) => {
    const convertValues = {...values, user_id: values.user_id && values.user_id.index}
    run({...instalador, ...convertValues})
  }

  return (
    <FormContext {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} justify="center">
          {/*  ------------------------------------------- LOGO -----------------------------------------------------*/}
          <Grid item xs={12}>
            <LFImageInput image={instalador ? instalador.logo : NO_IMAGE} control={control} name="logo"/>
          </Grid>
          {/*------------------------------------------- NOME -------------------------------------------------------*/}
          <Grid item xs={12} sm={8}>
            <LFInput label={AppMsgs.ENTIDADES.EDIT.LABEL.NOME} name='nome'/>
          </Grid>
          <Grid item xs={12} sm={4}>
            <LFAsyncSelectInput request={readUserSelect} control={control} name='user_id'
                                label={AppMsgs.ENTIDADES.EDIT.LABEL.USER}/>
          </Grid>
          {/*-------------------------------------------- NIF -------------------------------------------------------*/}
          <Grid item xs={12}>
            <LFNumberInput label={AppMsgs.ENTIDADES.EDIT.LABEL.NIF} name='nif'/>
          </Grid>
          {/*--------------------------------------- CONTACT_FORM ---------------------------------------------------*/}
          <LFContactForm/>
          {/*------------------------------------- ZIPCODE_REQUIRED MSG ---------------------------------------------*/}
          <LFZipCodeForm/>
          {/*----------------------------------------- MORADA -------------------------------------------------------*/}
          <Grid item xs={12}>
            <LFTextAreaInput label={AppMsgs.ENTIDADES.EDIT.LABEL.OBS} name='obs'/>
          </Grid>
        </Grid>
        <LFSubmitButton isLoading={isPending} dirty={!formState.dirty}>
          {instalador ? msgsInstaladores.BUTTON.EDIT_INSTALADOR : msgsInstaladores.BUTTON.NEW_INSTALADOR}
        </LFSubmitButton>
      </Form>
    </FormContext>
  )
}


export default InstaladorForm
