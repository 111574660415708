import React from "react"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import { Controller } from "react-hook-form"
import PropTypes from "prop-types"


function LFSwitchInput(props) {
  const {control, name, label, disabled, ...other} = props

  return (
    <React.Fragment>
      <Controller
        as={<FormControlLabel
          style={{marginLeft: '0px'}}
          control={<Switch value={name} {...other} />}
          labelPlacement="start"
          label={label}
          disabled={disabled}
        />}
        type="checkbox"
        name={name}
        control={control}
      />
    </React.Fragment>)
}

LFSwitchInput.defaultProps = {
  label: "",
}

LFSwitchInput.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
}


export { LFSwitchInput }
