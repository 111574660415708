import PDFIcon from "../../../components/Svg-Icons/PDFIcon"
import React from "react"
import PhotoIcon from "../../../components/Svg-Icons/PhotoIcon"

const procedimentosMsgs = {
  LABEL: "Procedimentos",
  UPDATE_LABEL: "Editar Procedimento",
  CREATE_LABEL: "Novo Procedimento",
  BACK_LABEL: "Voltar à lista de Procedimentos",
  TAB_LABEL: "Procedimento",
  TAB_IMGS: <PhotoIcon/>,
  TAB_PDFS: <PDFIcon/>,
  TAB_REFS: "Referências",
  TABLE: {
    HEADER: {
      TIPO: "Tipo",
      DESC: "Descrição",
      OBS: "Observações",
    }
  },
  COUNTS: {
    TITLE: 'Referido em:',
    TAB_IMGS: <PhotoIcon/>,
    TAB_PDFS: <PDFIcon/>,
    TIPO_EQUIPS: 'Tipos de Equipamento',
    MODELOS: "Modelos",
  },
  BUTTON: {
    NEW: "Adicionar",
    EDIT: "Guardar"
  },
  EDIT: {
    LABEL: {
      TIPO: "Tipo:",
      DESC: "Descrição:",
      OBS: "Observações:",
    }
  }
}

export default procedimentosMsgs
