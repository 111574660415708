import styled from "@material-ui/core/styles/styled"

const StyledBoldSpan = styled('span')(({theme, style}) => {
  return {
    ...style,
    fontWeight: 'bold'
  }
})

export default StyledBoldSpan
