import React from "react"
import { getRequest } from "../../../api/api"
import { allPatStatesByTecnicoID, patsURLname, readPatsTable } from "../apiPats"
import { useHistory } from "react-router"
import { patsColumns } from "../Pats"
import LFTable from "../../../components/LFTable/LFTable"
import Async from "react-async"
import LinearProgress from "@material-ui/core/LinearProgress"
import ErrorMsg from "../../../components/SharedComponents/Shared/Error/ErrorMsg"
import TabsComponent from "../../../components/SharedComponents/Shared/TabsComponent"
import Typography from "@material-ui/core/Typography"
import { AppMsgs } from "../../../constants/appMsgs"


// const extraColumns = {
//   Header: patMsgs.TABLE.HEADER.ESTADO,
//   accessor: "estadoPATdesc",
//   disableSortBy: true
// }


function CustomPatsList({ requestFn }) {
  // const columns = mergeColumnWithIndex(3, extraColumns, patsColumns)

  const history = useHistory()
  const handlerUpdate = (row) => history.push(getRequest({ URLname: patsURLname, id: row.id }).gotoItemPath())

  return (
    <LFTable
      columns={[patsColumns]}
      requestFn={requestFn}
      showEditButton={true}
      handlerUpdate={handlerUpdate}
    />
  )
}

const PatStatesRender = ({ data, tecID }) => {
  let tabs = []
  let index = 0
  const qtd = data.length || 0
  data.forEach(item => {
    return tabs.push({
      label: item.desc,
      hasMany: true,
      qtd: item.qtd,
      value: item.value,
      index: index++,
      component: <CustomPatsList
        requestFn={([props]) => readPatsTable([{
          state: item.value,
          com: 'visitas',
          relCom: 'tecnicos',
          relComID: tecID,
          sortBy: [{ id: "id", desc: true }],
          ...props
        }])} />,
    })
  })
  return (qtd > 0)
    ? (<TabsComponent renderTabs={tabs} />)
    : (<Typography component="h1" variant="h6" color="inherit" noWrap>
      {AppMsgs.DEFAULT_VALUES.TABLE.EMPTY_TABLE_MSG}
    </Typography>)
}

function PatsTec({ tecID }) {
  return (
    <Async promiseFn={allPatStatesByTecnicoID} tecID={tecID}>
      <Async.Pending><LinearProgress color="secondary" /></Async.Pending>
      <Async.Fulfilled>
        {(data) => <PatStatesRender
          data={data}
          tecID={tecID} />}
      </Async.Fulfilled>
      <Async.Rejected>{error => <ErrorMsg>{error}</ErrorMsg>}</Async.Rejected>
    </Async>
  )
}

// function OLD_PatsTec({tecnico}) {
//   const history = useHistory()
//
//   const handlerUpdate = (row) => history.push(getRequest({URLname: patsURLname, id: row.original.id}).gotoItemPath())
//
//   const columns = mergeColumnWithIndex(3, extraColumns, patsColumns)
//   return (
//     <React.Fragment>
//       <LFTable
//         columns={[columns]}
//         requestFn={(props) => readPatsTecTable(props, id)}
//         showEditButton={true}
//         handlerUpdate={handlerUpdate}
//       />
//     </React.Fragment>
//   )
// }

export default PatsTec
