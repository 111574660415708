import React from "react"
import LFInput from "./LFInput"
import { AppMsgs } from "../../constants/appMsgs"
import InputAdornment from "@material-ui/core/InputAdornment"
import PropTypes from "prop-types"

function LFMoneyInput({symb, ...props}) {
  return <LFInput
    startAdornment={<InputAdornment position="start">{symb}</InputAdornment>}
    type="text"
    {...props}
  />
}

LFMoneyInput.defaultProps = {
  label: AppMsgs.DEFAULT_VALUES.INPUT.MONEY_LBL,
  name: "number",
  symb: "€"
}

LFMoneyInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  symb: PropTypes.string
}

export { LFMoneyInput }
