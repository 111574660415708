import Dashboard from "./Dashboard"
import DashboardIcon from "../../components/Svg-Icons/DashboardIcon"
import { api, getRequest } from "../../api/api"
import msgsDashboard from "./msgsDashboard"

const dashboardUrl = 'dashboard'

// ----------------------- DASHBOARD ROUTES -----------------------
const dashboardRouterProps = {
  path: getRequest({URLname: dashboardUrl}).paginatePath(),
  exact: true,
  name: msgsDashboard.LABEL,
  component: Dashboard,
  Icon: DashboardIcon,
  breadcrumb: () => msgsDashboard.LABEL,
  reqRole: 'U'
}

// ----------------------- DASHBOARD ROUTES -----------------------

async function readTopEstadosPat() {
  const request = getRequest({URLname: 'estadosPat?limit=5', paginateOFF: true,}).GET()
  return api(request).then(resp => resp.data.data)
}

export {
  dashboardRouterProps,
  dashboardUrl,
  readTopEstadosPat,
}
