import React, { useState } from "react"
import Grid from "@material-ui/core/Grid"
import TableEstadoPat from "../../../../components/SharedComponents/Shared/Tables/TableEstadoPat"
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import './timelineStyles.css'
import GearIcon from "../../../../components/Svg-Icons/GearIcon"
import CircleIcon from "../../../../components/Svg-Icons/CircleIcon"
import StarIcon from "../../../../components/Svg-Icons/StarIcon"
import { secondaryColorDark, secondaryColorLight, successColor, textHint, whiteColor } from "../../../../constants/appTheme"
import { fade } from "@material-ui/core"
import { StateFlowPatTabs } from "../InfoStates/StateFlowPatTabs"
import patMsgs from "../../patMsgs"
import Fab from "@material-ui/core/Fab"
import ExpandMoreIcon from "../../../../components/Svg-Icons/ExpandMoreIcon"
import KeyArrowRightIcon from "../../../../components/Svg-Icons/KeyArrowRightIcon"


const EstadosPat = ({pat, reload}) => {
  const [expanded, setExpanded] = useState(false)
  const estados = pat.estados.data

  let key = 0

  let elems = []
  estados.forEach(item => {
    elems.push({
      key: key++,
      estado: item,
      // date: item.dhRegisto,
    })
  })
  elems.push({key: key++}) // arranque
  const qtdElems = elems.length

  const handleToggleExpand = (toggle) => {
    setExpanded(!toggle)
  }

  const ToggleExpandExpandFab = () => {
    return (
      <Fab
        onClick={() => handleToggleExpand(expanded)}
        variant="extended"
        size="small"
        color="primary"
        aria-label="expand"
      >
        {expanded ? <ExpandMoreIcon/> : <KeyArrowRightIcon/>}
        {expanded ? patMsgs.CONTRACT_STATES : patMsgs.EXPAND_STATES}
      </Fab>)
  }

  return (
    <Grid container spacing={4} justify="center">
      <Grid item xs={6}>
        {(qtdElems > 2) ? <ToggleExpandExpandFab/> : null}
        <VerticalTimeline layout={'1-column'}>
          {elems.map((elem, index) => {
            let timelineProps = {
              fixed: false,
              iconStyle: {background: secondaryColorLight},
              contentStyle: {background: '#f2f2f2', color: '#d3d3d3'},
              contentArrowStyle: {borderRight: '7px solid  #ff5500'},
              visibilitySensorProps: {},
              icon: <GearIcon color={whiteColor}/>
            }

            if (elem.key === qtdElems - 1) {
              // arranque
              timelineProps = {
                fixed: true,
                iconStyle: {background: successColor},
                visibilitySensorProps: {},
                icon: <CircleIcon color={whiteColor}/>
              }
              return (<VerticalTimelineElement {...{...timelineProps, ...elem}} />)
            } else {
              if (elem.key === 0) {
                // último
                timelineProps = {
                  fixed: true,
                  contentStyle: {background: fade(secondaryColorDark, 0.3), color: whiteColor},
                  contentArrowStyle: {borderRight: '7px solid  rgba(72,36,0,0.7)'},
                  iconStyle: {background: 'rgb(255,102,0)'},
                  visibilitySensorProps: {},
                  icon: <StarIcon color={whiteColor}/>
                }
              }
            }
            return ((expanded || timelineProps.fixed)
              ? (
                <VerticalTimelineElement key={index}
                                         {...{...timelineProps, ...elem}}
                >
                  <TableEstadoPat estado={elem.estado}/>
                </VerticalTimelineElement>
              )
              : <CircleIcon key={key++} style={{width: '8px', height: '8px'}} color={textHint}/>)
          })
          }
        </VerticalTimeline>
      </Grid>
      <Grid item xs>
        <StateFlowPatTabs pat={pat} reload={reload}/>
      </Grid>
    </Grid>)
}

export default EstadosPat
