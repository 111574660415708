import React from "react"
import { ListRepoGest } from "../../../../components/SharedComponents/ListRepo/ListRepoGest"
import { createImagemProcedimento, deleteImagemProcedimento, readImagensProcedimento } from "../apiPDFsImgsProcedimento"
import { ImageGridList } from "../../../../components/SharedComponents/ListRepo/ListImagens/ImageGridList"
import { ImagemEdit } from "../../../../components/SharedComponents/ListRepo/ListImagens/ImagemEdit"
import { imagemMsgs } from "../../../../components/SharedComponents/ListRepo/ListImagens/imagemMsgs"

function ImgsProcedimento({proc, reload}) {

  /*<div style={{background: '#07F', width: '100%', minHeight: '50px'}}></div>*/
  return (
    <ListRepoGest
      repo={proc}
      repoKey={'img_id'}
      repoItems={proc.images ? proc.images.data : []}
      FKfieldname={'proc_id'}
      createRepoFn={createImagemProcedimento}
      deleteRepoFn={deleteImagemProcedimento}
      readReposFn={readImagensProcedimento}
      ListComponent={ImageGridList}
      EditComponent={ImagemEdit}
      mayEdit={true}
      btAddTitle={imagemMsgs.NEW_LABEL}
      reload={reload}
    />
  )
}

export { ImgsProcedimento }
