import React from "react"
import { readUserPerms } from "../apiUsers"
import Async from "react-async"
import LinearProgress from "@material-ui/core/LinearProgress"
import ErrorMsg from "../../../../components/SharedComponents/Shared/Error/ErrorMsg"
import UserPermsVTabs from "./UserPermsVTabs"


function RenderUserPerms({id}) {
  return (
    <Async promiseFn={readUserPerms} id={id}>
      <Async.Pending><LinearProgress color="secondary"/></Async.Pending>
      <Async.Fulfilled>
        {(data, {setData}) => {
          return (<UserPermsVTabs data={data} setData={setData} userID={id}/>)
        }}
      </Async.Fulfilled>
      <Async.Rejected>{error => <ErrorMsg>{error}</ErrorMsg>}</Async.Rejected>
    </Async>
  )
}

function UserPermsView({userID}) {
  return (<RenderUserPerms id={userID}/>)
}

export default UserPermsView
