import React from "react"
import SvgIcon from "./SvgIcon"

const VisitaPatEndResolvidaIcon = (others) => {
  return (
    <SvgIcon viewBox="0 0 512 512" width="512" height="512" {...others}>
      <g>
        <path
          d="m455.5 353.37h-278.12c-14.08 0-25.54-11.47-25.54-25.58 0-14.1 11.46-25.57 25.54-25.57h105.5l-22.26-30h-83.24c-30.62 0-55.54 24.93-55.54 55.57 0 30.65 24.92 55.58 55.54 55.58h278.12c14.61 0 26.5 11.9 26.5 26.54s-11.89 26.54-26.5 26.54h-305.74c1.613 9.601 1.72 19.768 0 30h305.74c31.15 0 56.5-25.36 56.5-56.54s-25.35-56.54-56.5-56.54z"/>
        <path
          d="m60.505 390.905c-33.362 0-60.505 27.162-60.505 60.548s27.143 60.547 60.505 60.547 60.505-27.162 60.505-60.547-27.143-60.548-60.505-60.548zm0 91.095c-16.82 0-30.505-13.704-30.505-30.547s13.685-30.547 30.505-30.547 30.505 13.704 30.505 30.547-13.685 30.547-30.505 30.547z"/>
        <path
          d="m326.24 129.511c8.273 0 15.004-6.743 15.004-15.031s-6.73-15.031-15.004-15.031c-8.272 0-15.003 6.743-15.003 15.031s6.731 15.031 15.003 15.031z"/>
        <path
          d="m314.99 295.158c6.014 8.106 18.208 8.096 24.18-.118l78.874-108.502c58.09-75.501 4.473-186.538-91.803-186.538-95.816 0-149.336 109.946-92.625 185.456.288.417-3.59-4.83 81.374 109.702zm11.25-225.71c24.815 0 45.004 20.201 45.004 45.031s-20.188 45.031-45.004 45.031c-24.814 0-45.003-20.201-45.003-45.031s20.189-45.031 45.003-45.031z"/>
      </g>
    </SvgIcon>
  )
}

export default VisitaPatEndResolvidaIcon
