import React from "react"
import { allRevs, deletePlano, planosUrl, readPlanosTable } from "./apiPlanos"
import expandColumn from "../../../components/LFTable/ExpandColumn"
import DetailPanel from "./DetailPanel/DetailPanel"
import { errorColor, successColor } from "../../../constants/appTheme"
import CircleIcon from "../../../components/Svg-Icons/CircleIcon"
import LFTable from "../../../components/LFTable/LFTable"
import { useHistory } from "react-router"
import Async from "react-async"
import LinearProgress from "@material-ui/core/LinearProgress"
import ErrorMsg from "../../../components/SharedComponents/Shared/Error/ErrorMsg"
import TabsComponent from "../../../components/SharedComponents/Shared/TabsComponent"
import { getRequest } from "../../../api/api"
import msgsPlanos from "./msgsPlanos"

const planosColumns = {
  Header: () => null,
  id: 'Planos',
  columns: [
    {
      Header: msgsPlanos.TABLE.HEADER.REF_AN,
      accessor: "refAN-rev",
      Cell: ({row}) => {
        return <span>{row.original.refAN + " (" + row.original.rev + ")"}</span>
      },
    },
    {
      Header: msgsPlanos.TABLE.HEADER.TIPO,
      accessor: "tipo",
      Cell: ({row}) => {
        return <span>{row.original.tipoPlano.data.sigla}</span>
      },
      disableSortBy: true
    },
    {
      Header: msgsPlanos.TABLE.HEADER.DESIGNACAO,
      accessor: "designacao",
    },
    {
      Header: msgsPlanos.TABLE.HEADER.ESTADO,
      accessor: "ativo",
      Cell: ({row}) => {
        return <CircleIcon color={!!(row.original.ativo) ? successColor : errorColor}/>
      },
      disableSortBy: true
    },
    {
      Header: msgsPlanos.TABLE.HEADER.PLANOS_ATIVOS,
      accessor: "planos_activ_count",
      disableSortBy: true
    }
  ]
}

const PlanoTabsRender = ({data}) => {
  let planoTabs = []
  Object.keys(data).forEach(function (item, key) {
    planoTabs[key] = {
      label: data[item].rev,
      index: key,
      component: <CustomPlanosList
        readFn={([props]) => readPlanosTable([{com: "tipoPlano, tipoEquip", revision: data[item].rev, ...props}])}/>,
    }
  })
  return (
    <TabsComponent renderTabs={planoTabs}/>
  )
}

function ListPlanos() {
  return (
    <Async promiseFn={allRevs}>
      <Async.Pending><LinearProgress color="secondary"/></Async.Pending>
      <Async.Fulfilled>
        {(data) => <PlanoTabsRender data={data}/>}
      </Async.Fulfilled>
      <Async.Rejected>{error => <ErrorMsg>{error}</ErrorMsg>}</Async.Rejected>
    </Async>
  )
}

function CustomPlanosList({readFn}) {
  const history = useHistory()
  const handlerUpdate = (row) => history.push(getRequest({URLname: planosUrl, id: row.id}).gotoItemPath())

  const renderRowSubComponent = React.useCallback(
    ({row}) => (<DetailPanel rowData={row.original}/>
    ), [])

  const columns = React.useMemo(() => [{...expandColumn}, {...planosColumns}], [])

  return (
    <LFTable
      columns={columns}
      deleteFn={deletePlano}
      showAddButton={false}
      showEditButton={true}
      showDeleteButton={true}
      handlerUpdate={handlerUpdate}
      requestFn={readFn}
      renderRowSubComponent={renderRowSubComponent}
    />
  )
}


export default ListPlanos
