import React from "react"
import Async from "react-async"
import LinearProgress from "@material-ui/core/LinearProgress"
import ErrorMsg from "../../../components/SharedComponents/Shared/Error/ErrorMsg"
import { readUser, updateUser } from "./apiUsers"
import UserEditForm from "./UserEditForm"
import TabsComponent from "../../../components/SharedComponents/Shared/TabsComponent"
import msgsUsers from "./msgsUsers"
import UserLogs from "./UserLogs/UserLogs"
import AccessIcon from "../../../components/Svg-Icons/AccessIcon"
import UserPermsView from "./UserPerms/UserPermsView"
import { useUser } from "../../../context/user-context"


const userTabsGen = ({marcas_tecnico_count, locs_tecnico_count, marcacoes_count, pats_count, pats_nao_concluidas_count}) => {
  return ([
    {
      title: <AccessIcon/>,
      showTab: true,
      component: ({userID}) => <UserPermsView userID={userID}/>,
      hasMany: false,
      qtd: 0
    },
    {
      title: msgsUsers.TABS.LABEL.LOGS,
      showTab: true,
      component: ({userID}) => <UserLogs userID={userID}/>,
      hasMany: false,
      qtd: 0
    }
  ])
}

const UserCustomTabs = ({user, setUser}) => {
  const {u} = useUser()

  let index = 0
  let tabs = [{
    label: user.name,
    index: index++,
    component: <UserEditForm user={user} method={updateUser} setUser={setUser}/>
  }]

  if(u.is.root)
  userTabsGen({...user}).forEach(item => {
    if (item.showTab) {
      tabs.push({
        label: item.title,
        index: index++,
        component: item.component({userID: user.id}),
        hasMany: item.hasMany,
        qtd: item.qtd
      })
    }
  })
  return (<TabsComponent renderTabs={tabs}/>)
}

function UserUpdate({id}) {
  return (
    <Async promiseFn={readUser} id={id}>
      <Async.Pending><LinearProgress color="secondary"/></Async.Pending>
      <Async.Fulfilled>
        {(data, {setData}) => {
          return (<UserCustomTabs user={data} setUser={setData}/>)
        }}
      </Async.Fulfilled>
      <Async.Rejected>{error => <ErrorMsg>{error}</ErrorMsg>}</Async.Rejected>
    </Async>
  )
}

export default UserUpdate
