import { api, apiCUD, apiReadTable, getRequest } from "../../../api/api"
import ListPlanos from "./ListPlanos"
import PlanoView from "./PlanoView"
import msgsPlanos from "./msgsPlanos"
import PlanoIcon from "../../../components/Svg-Icons/PlanoIcon"

const planosUrl = 'planos'
// ----------------------- PLANOS ROUTES -----------------------

const planosListRouterProps = {
  path: getRequest({URLname: planosUrl}).paginatePath(),
  exact: true,
  name: msgsPlanos.LABEL,
  component: ListPlanos,
  Icon: PlanoIcon,
  breadcrumb: () => msgsPlanos.LABEL,
  reqRole: 'R'
}

const planoViewRouterProps = {
  path: getRequest({URLname: planosUrl}).viewPath(),
  name: msgsPlanos.UPDATE_LABEL,
  exact: true,
  component: PlanoView,
  breadcrumb: (props) => !!parseInt(props.match.params.id, 10) ? msgsPlanos.UPDATE_LABEL : msgsPlanos.CREATE_LABEL,
  reqRole: 'R'
}

// ----------------------- PLANOS ROUTES -----------------------

/**
 * Read Planos com paginação para tabela
 * @param query
 * @returns {Promise<*|void>}
 */
async function readPlanosTable([query]) {
  const request = getRequest({URLname: planosUrl, query}).GET()
  return apiReadTable(request)
}

async function allRevs() {
  const request = getRequest({URLname: planosUrl}).GET('all/revisions')
  return apiReadTable(request)
}

/**
 * Read Plano
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
function readPlano({id}) {
  const request = getRequest({URLname: planosUrl, id}).GET()
  return api(request).then(r => r.data.data)
}

/** Rota não está a ser usada **/
function createPlano([data]) {
  const request = getRequest({URLname: planosUrl}).POST()
  return apiCUD(request, data).then(r => r.data)
}

function ativaPlano(id) {
  const request = getRequest({URLname: planosUrl, id}).PUT('ativa')
  return apiCUD(request).then(r => r.data)
}

function desativaPlano(id) {
  const request = getRequest({URLname: planosUrl, id}).PUT('desativa')
  return apiCUD(request).then(r => r.data)
}

function updatePlano([data]) {
  const request = getRequest({URLname: planosUrl, id: data.id}).PUT()
  return apiCUD(request, data).then(r => r.data)
}

async function deletePlano(id) {
  const request = getRequest({URLname: planosUrl, id}).DELETE()
  return apiCUD(request)
}

export {
  planosUrl,
  planosListRouterProps,
  planoViewRouterProps,
  readPlanosTable,
  allRevs,
  readPlano,
  createPlano,
  deletePlano,
  updatePlano,
  ativaPlano,
  desativaPlano
}
