const TransformPag = (query) => {

  if (!query) return ''

  const {pageSize, pageIndex, globalFilter, sortBy, trashed, role, com, revision, type, state, logo, relCom, relComID} = query
  const srtParams = []

  if (pageSize)
    srtParams.push(`perpage=${pageSize}`)
  if (!isNaN(pageIndex))
    srtParams.push(`page=${pageIndex + 1}`)

  if (globalFilter)
    srtParams.push(`filter=${globalFilter}`)
  if (sortBy && sortBy.length)
    srtParams.push(`sort=${sortBy[0].id}:${sortBy[0].desc ? 'desc' : 'asc'}`)
  if (trashed)
    srtParams.push('trashed=1')
  if (role)
    srtParams.push(`role=${role}`)
  if (com)
    srtParams.push(`com=${com}`)
  if (relCom)
    srtParams.push(`relCom=${relCom}`)
  if (relComID)
    srtParams.push(`relComID=${relComID}`)

  if (revision)
    srtParams.push(`revision=${revision}`)

  if (type)
    srtParams.push(`type=${type}`)
  if (state)
    srtParams.push(`state=${state}`)

  if (logo)
    srtParams.push(`logo=${logo}`)

  return '?' + srtParams.join('&')
}

export { TransformPag }
