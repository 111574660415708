import React from "react"
import SvgIcon from "./SvgIcon"

const PecaIcon = (others) => {
  return (
    <SvgIcon viewBox="0 0 64 64" width="512" height="512" {...others}>
      <path d="m59 34h2v18h-2z"/>
      <path d="m61 63h-10v-7h2v5h6v-5h2z"/>
      <path d="m53 30h-2v-11h-3v-2h3c1.103 0 2 .897 2 2z"/>
      <path d="m36 17h8v2h-8z"/>
      <path d="m25.315 17h6.685v2h-6.685z"/>
      <path d="m25.315 9h6.685v2h-6.685z"/>
      <path d="m14 27c-7.168 0-13-5.832-13-13s5.832-13 13-13 13 5.832 13 13-5.832 13-13 13z" fill="#f0bc5e"/>
      <path
        d="m14 23c-3.309 0-6-2.691-6-6 0-.791.147-1.564.438-2.298l3.238-8.205c.413-.909 1.325-1.497 2.324-1.497s1.911.588 2.324 1.497l3.258 8.251c.277.705.418 1.446.418 2.203 0 3.358-2.691 6.049-6 6.049zm-.495-15.693-3.207 8.13c-.198.499-.298 1.025-.298 1.563 0 2.206 1.794 4 4 4s4-1.794 4-4c0-.554-.094-1.048-.279-1.519l-3.237-8.204c-.136-.296-.79-.348-.979.03z"/>
      <path d="m36 9h8v2h-8z"/>
      <path d="m61 30h-2v-14c0-2.757-2.243-5-5-5h-6v-2h6c3.859 0 7 3.141 7 7z"/>
      <g fill="#f0bc5e">
        <path d="m35 20h-2c-.552 0-1-.448-1-1v-10c0-.552.448-1 1-1h2c.552 0 1 .448 1 1v10c0 .552-.448 1-1 1z"/>
        <path d="m47 20h-2c-.552 0-1-.448-1-1v-10c0-.552.448-1 1-1h2c.552 0 1 .448 1 1v10c0 .552-.448 1-1 1z"/>
        <path d="m61 56h-10c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h10c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1z"/>
        <path d="m15 56h-10c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h10c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1z"/>
        <path d="m61 34h-10c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h10c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1z"/>
      </g>
      <path d="m15 52h-2v-5c0-1.103.897-2 2-2h3v2h-3z"/>
      <path d="m7 52h-2v-8c0-3.859 3.141-7 7-7h6v2h-6c-2.757 0-5 2.243-5 5z"/>
      <path
        d="m36 37v-4h1c1.654 0 3-1.346 3-3s-1.346-3-3-3h-1c0-2.206-1.794-4-4-4s-4 1.794-4 4h-1c-1.654 0-3 1.346-3 3s1.346 3 3 3h1v4h-6v2h20v-2zm-4-12c1.103 0 2 .897 2 2h-4c0-1.103.897-2 2-2zm-6 5c0-.552.448-1 1-1h10c.552 0 1 .448 1 1s-.448 1-1 1h-10c-.552 0-1-.448-1-1zm4 3h4v4h-4z"/>
      <path d="m51 34v3h-5v2h5v6h-5v2h5v5h2v-18z"/>
      <path d="m21 48h-2c-.552 0-1-.448-1-1v-10c0-.552.448-1 1-1h2c.552 0 1 .448 1 1v10c0 .552-.448 1-1 1z" fill="#f0bc5e"/>
      <path d="m45 48h-2c-.552 0-1-.448-1-1v-10c0-.552.448-1 1-1h2c.552 0 1 .448 1 1v10c0 .552-.448 1-1 1z" fill="#f0bc5e"/>
      <path d="m22 45h20v2h-20z"/>
      <path d="m57 49h-2v-2h2zm0-4h-2v-2h2zm0-4h-2v-2h2z"/>
      <path d="m35 43h-2v-2h2zm-4 0h-2v-2h2zm-4 0h-2v-2h2z"/>
      <path d="m57 27h-2v-2h2zm0-4h-2v-2h2zm0-4h-2v-2h2z"/>
      <path d="m15 63h-10v-7h2v5h6v-5h2z"/>
    </SvgIcon>
  )
}

export default PecaIcon
