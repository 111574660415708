import React, { createContext, useCallback, useMemo, useState } from "react"
import PlanosAplicaveisSimula from "./PlanosAplicaveisSimula"
import PlanosAplicaveisAssoc from "./PlanosAplicaveisAssoc"
import PlanosAplicaveisList from "./PlanosAplicaveisList"

export const RedirectState = createContext(null)

function PlanosAplicaveis({equip, setEquip}) {
  const [state, setState] = useState({})

  const list = useCallback(() => setState({}), [])
  const simula = useCallback((newState) => setState({...newState, type: 'S'}), [])
  const assoc = useCallback((newState) => setState({...newState, type: 'A'}), [])

  const getState = useMemo(() => ({list, simula, assoc}), [list, simula, assoc])

  return (
    <RedirectState.Provider value={getState}>
      {(state.type === 'S') ?
        <PlanosAplicaveisSimula equipID={equip.id} state={state}/> :
        (state.type === 'A') ?
          <PlanosAplicaveisAssoc state={state} setEquip={setEquip}/> :
          <PlanosAplicaveisList equipID={equip.id}/>
      }
    </RedirectState.Provider>
  )
}

export default PlanosAplicaveis
