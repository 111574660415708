import React from "react"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"

const msgsPlanosAtivados = {

  LABEL: "Planos Ativados",
  MAIS_INFO: "Toda a informação?",

  OPEN_BUTTON: 'Abrir Plano Ativado',

  BT_DESATIVAR: 'Desativar',
  BT_REMOVER: 'Remover',

  STATE: "Estado",
  PLANOS_ATIV_STATES: {
    ATIVOS: 'Ativos',
    CONCLUIDOS: 'Concluídos',
    DESATIVADOS: 'Desativados',
  },
  PA_STATE: {
    ATIVO: 'Ativo?',
    CONCLUIDO: 'Concluído?',
    DESATIVADO: 'Desativado?',
    DATA_ATIV: 'Ativado',
    DATA_ANUL: 'Desativado',
  },
  COUNTS: {
    QTD: 'Qtd.',
    PERIODOS: 'Períodos',
    PATS: 'PAT',
    PATS_AGENDADO: 'PAT agendados',
    PATS_EM_CURSO: 'PAT em curso',
    PATS_VISITA_CONCLUIDA_PAT_NAO_RESOLVIDO: 'PAT c/ visita concluída e PAT não resolvido',
    PATS_ANULADO_IMM: 'PAT anulados (IMM)',
    PATS_ANULADO_CLI: 'PAT anulados (CLI)',
    PATS_RESOLVIDO: 'PAT resolvidos',
    PATS_RELATORIO_FINAL_REGISTADO: 'PAT relatório final registado',
    PATS_CONFERIDO: 'PAT conferidos',
    PATS_ENCERRADO: 'PAT encerrados',
    PATS_FATURACAO_CONFERIDA: 'PAT faturação conferida',
    PECAS_PAT: 'Pecas (nos PAT)',
  },

  CONFIRM_TITLE: (op) => {
    return (op === 1)
      ? (
        <>
          <Typography>Confirma a desativação do plano?</Typography>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
          >
            <Grid item xs={12}>
              <p>Esta operação é irreversível.<br/><br/>
                A desativação de um plano desencadeia a <u>anulação de todos os PAT e ao cancelamento de todas as ações e avisos</u>.
              </p>
              <p>Os Técnicos eventualmente associados aos PAT anulados serão notificados por email.</p>
            </Grid>
          </Grid>
        </>)
      : (
        <>
          <Typography>Confirma a remoção do plano?</Typography>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
          >
            <Grid item xs={12}>
              <p>Esta operação é irreversível.<br/><br/>
                A remoção de um plano desencadeia a <strong>eliminação definitiva de todos os PAT e informações associadas</strong>.
              </p>
              <p>Os Técnicos eventualmente associados aos PAT eliminados serão notificados por email sobre cessação desses PAT.</p>
            </Grid>
          </Grid>
        </>)
  },


  PLANO: "Plano",
  DATA_FIM: "Fim",
  DURACAO: <span style={{minWidth: '50px', display: 'inline-block'}}>Duração<br/>(meses)</span>,
  UPDATE_LABEL: "Editar Plano Ativado",
  BACK_LABEL: "Voltar aos Planos Ativados",
}

export default msgsPlanosAtivados
