import React, { useState } from "react"
import { useAsync } from "react-async"
import { updateMarcasTec } from "./apiMarcasTec"
import Typography from "@material-ui/core/Typography"
import LFListItem from "../../../../components/SharedComponents/Shared/LFListItem"
import msgsMarcasTec from "./msgsMarcasTec"
import Grid from "@material-ui/core/Grid"

function MarcasTecList({idTec, data, setData}) {
  const [marData, setMacData] = useState(data)

  const [selectMarcaID, setSelectMarcaID] = useState(null)

  const {isPending, run} = useAsync({
    deferFn: updateMarcasTec,
    onResolve: () => setData(marData),
    onReject: () => setMacData(data)
  })

  const handleToggle = (id) => () => {
    setSelectMarcaID(id)
    setMacData(marData.map(item => {
      if (item.id === id) {
        run({idMarca: item.id, onoff: !item.checked, idTec: idTec})
        return {...item, checked: !item.checked}
      }
      return item
    }))
  }


  const secondColumnStart = Math.floor(marData.length / 2)
  return (
    <React.Fragment>
      <Typography variant="body1">{msgsMarcasTec.CHECK_MARCAS}</Typography>
      <Grid container spacing={2} justify="center">
        <Grid item xs={6}>
          {marData.slice(0, secondColumnStart).map((item, i) => {
            return (
              <LFListItem
                key={item.id}
                item={{id: item.id, text: item.marca, checked: item.checked}}
                handleToggle={handleToggle(item.id)}
                disabled={isPending}
                loading={isPending && (item.id === selectMarcaID)}/>
            )
          })}
        </Grid>
        <Grid item xs={6}>
          {marData.slice(secondColumnStart).map((item, i) => {
            return (
              <LFListItem
                key={item.id}
                item={{id: item.id, text: item.marca, checked: item.checked}}
                handleToggle={handleToggle(item.id)}
                disabled={isPending}
                loading={isPending && (item.id === selectMarcaID)}/>
            )
          })}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default MarcasTecList
