import React from "react"
import SvgIcon from "./SvgIcon"

const ProcedimentoIcon = (others) => {
  return (
    <SvgIcon viewBox="0 0 64 64" width="512" height="512" {...others}>
      <path d="m55 57a3 3 0 0 1 -3 3h-31v2h31a5.006 5.006 0 0 0 5-5v-19h-2z"/>
      <path
        d="m15 15a3 3 0 0 1 3-3h5.424a4.95 4.95 0 0 0 -.424 2h-4a2 2 0 0 0 -2 2v16h2v-16h4v3a1 1 0 0 0 1 1h22a1 1 0 0 0 1-1v-3h4v6h2v-6a2 2 0 0 0 -2-2h-4a4.95 4.95 0 0 0 -.424-2h5.424a3 3 0 0 1 3 3v5h2v-5a5.006 5.006 0 0 0 -5-5h-7.031a4.952 4.952 0 0 0 -2.969-1h-2v-2a5 5 0 0 0 -10 0v2h-2a4.952 4.952 0 0 0 -2.969 1h-7.031a5.006 5.006 0 0 0 -5 5v17h2zm10-1a3 3 0 0 1 3-3h3a1 1 0 0 0 1-1v-3a3 3 0 0 1 6 0v3a1 1 0 0 0 1 1h3a3 3 0 0 1 3 3v1h-20zm0 3h20v1h-20z"/>
      <path d="m30 58h21a2 2 0 0 0 2-2v-14h-2v6h-5a3 3 0 0 0 -3 3v5h-13zm21-2h-4.586l4.586-4.586zm-6-5a1 1 0 0 1 1-1h3.586l-4.586 4.586z"/>
      <path d="m34 6h2v2h-2z"/>
      <path
        d="m60.87 23.13a5.006 5.006 0 0 0 -7.071 0l-17.677 17.678a1 1 0 0 0 -.274.51l-.648 3.239-1.907 1.907a3 3 0 0 0 4.243 4.243l1.907-1.907 3.239-.648a1 1 0 0 0 .51-.274l17.678-17.678a5.006 5.006 0 0 0 0-7.07zm-9.9 5.656 1.415 1.414-12.021 12.022-1.414-1.414zm-14.848 20.507a1 1 0 0 1 -1.415-1.415l1.414-1.414 1.415 1.415zm3.156-2.5-2.071-2.071.472-2.357 3.956 3.956zm3.914-1.743-1.414-1.414 12.022-12.021 1.415 1.414zm13.436-13.435-4.243-4.243.707-.707 4.243 4.243zm2.828-2.828-.707.707-4.243-4.243.707-.707a3 3 0 1 1 4.243 4.243z"/>
      <path
        d="m23.178 59.406a1 1 0 0 0 1.307-.093l2.828-2.828a1 1 0 0 0 .093-1.307l-1.624-2.166a10.96 10.96 0 0 0 .678-1.639l2.682-.383a1 1 0 0 0 .858-.99v-4a1 1 0 0 0 -.858-.99l-2.682-.383a10.96 10.96 0 0 0 -.678-1.639l1.624-2.166a1 1 0 0 0 -.093-1.307l-2.828-2.828a1 1 0 0 0 -1.307-.093l-2.166 1.624a10.96 10.96 0 0 0 -1.639-.678l-.383-2.682a1 1 0 0 0 -.99-.858h-4a1 1 0 0 0 -.99.858l-.383 2.682a10.96 10.96 0 0 0 -1.639.678l-2.166-1.624a1 1 0 0 0 -1.307.093l-2.828 2.828a1 1 0 0 0 -.093 1.307l1.624 2.166a10.96 10.96 0 0 0 -.678 1.639l-2.682.383a1 1 0 0 0 -.858.99v4a1 1 0 0 0 .858.99l2.682.383a10.96 10.96 0 0 0 .678 1.639l-1.624 2.166a1 1 0 0 0 .093 1.307l2.828 2.828a1 1 0 0 0 1.307.093l2.166-1.624a10.96 10.96 0 0 0 1.639.678l.383 2.682a1 1 0 0 0 .99.858h4a1 1 0 0 0 .99-.858l.383-2.682a10.96 10.96 0 0 0 1.639-.678zm-4.95-2.7a1 1 0 0 0 -.743.828l-.352 2.466h-2.266l-.352-2.463a1 1 0 0 0 -.743-.828 8.892 8.892 0 0 1 -2.356-.975 1 1 0 0 0 -1.111.06l-1.99 1.492-1.6-1.6 1.491-1.986a1 1 0 0 0 .06-1.111 8.892 8.892 0 0 1 -.975-2.356 1 1 0 0 0 -.828-.743l-2.463-.357v-2.266l2.463-.352a1 1 0 0 0 .828-.743 8.892 8.892 0 0 1 .975-2.356 1 1 0 0 0 -.06-1.111l-1.492-1.99 1.6-1.6 1.99 1.492a1 1 0 0 0 1.111.06 8.892 8.892 0 0 1 2.356-.975 1 1 0 0 0 .743-.828l.353-2.464h2.266l.352 2.463a1 1 0 0 0 .743.828 8.892 8.892 0 0 1 2.356.975 1 1 0 0 0 1.111-.06l1.99-1.492 1.6 1.6-1.492 1.99a1 1 0 0 0 -.06 1.111 8.892 8.892 0 0 1 .975 2.356 1 1 0 0 0 .828.743l2.464.353v2.266l-2.463.352a1 1 0 0 0 -.828.743 8.892 8.892 0 0 1 -.975 2.356 1 1 0 0 0 .06 1.111l1.492 1.99-1.6 1.6-1.986-1.491a1 1 0 0 0 -1.111-.06 8.892 8.892 0 0 1 -2.361.975z"/>
      <path d="m16 42a6 6 0 1 0 6 6 6.006 6.006 0 0 0 -6-6zm0 10a4 4 0 1 1 4-4 4 4 0 0 1 -4 4z"/>
      <path d="m15 38h2v2h-2z"/>
      <path d="m8.636 40.636h2v2h-2z" transform="matrix(.707 -.707 .707 .707 -26.619 19.009)"/>
      <path d="m6 47h2v2h-2z"/>
      <path d="m8.636 53.364h2v2h-2z" transform="matrix(.707 -.707 .707 .707 -35.619 22.737)"/>
      <path d="m15 56h2v2h-2z"/>
      <path d="m21.364 53.364h2v2h-2z" transform="matrix(.707 -.707 .707 .707 -31.891 31.737)"/>
      <path d="m24 47h2v2h-2z"/>
      <path d="m21.364 40.636h2v2h-2z" transform="matrix(.707 -.707 .707 .707 -22.891 28.009)"/>
      <path d="m28 26a3.008 3.008 0 0 1 .751.095l.5-1.938a5.059 5.059 0 0 0 -1.251-.157 5 5 0 1 0 4.842 6.249l-1.936-.5a3 3 0 1 1 -2.906-3.749z"/>
      <path d="m26.964 26.5h7.071v2h-7.071z" transform="matrix(.707 -.707 .707 .707 -10.512 29.621)"/>
      <path d="m35 26h2v2h-2z"/>
      <path d="m39 26h4v2h-4z"/>
      <path d="m35 30h6v2h-6z"/>
    </SvgIcon>
  )
}

export default ProcedimentoIcon
