import React from "react"
import Button from "@material-ui/core/Button"
import CustomDialog from "./CustomDialog"
import PropTypes from "prop-types"
import IconButton from "@material-ui/core/IconButton"
import StarIcon from "../../../Svg-Icons/StarIcon"

function EntityOpenDialogIcon({component, Icon, dialogTitle, disabled, classNameProp}) {
  const [open, setOpen] = React.useState(false)
  return (
    <React.Fragment>
      <IconButton
        onClick={() => setOpen(true)}
        disabled={disabled}
        className={classNameProp}>
        {Icon}
      </IconButton>
      <CustomDialog open={open} setOpen={setOpen} title={dialogTitle}>
        {component}
      </CustomDialog>
    </React.Fragment>
  )
}

function EntityOpenDialogButton({component, btTitle, dialogTitle, disabled, fullWidth, style, classNameProp}) {
  const [open, setOpen] = React.useState(false)
  return (
    <React.Fragment>
      <Button disabled={disabled}
              fullWidth={fullWidth}
              variant="outlined"
              color="secondary"
              onClick={() => setOpen(true)}
              style={style}
              className={classNameProp}>
        {btTitle}
      </Button>
      <CustomDialog open={open} setOpen={setOpen} title={dialogTitle}>
        {component}
      </CustomDialog>
    </React.Fragment>
  )
}

EntityOpenDialogIcon.defaultProps = {
  Icon: <StarIcon/>,
  disabled: false,
  classNameProp: '',
}

EntityOpenDialogIcon.propTypes = {
  component: PropTypes.element.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  Icon: PropTypes.element,
  disabled: PropTypes.bool,
  classNameProp: PropTypes.string,
}

EntityOpenDialogButton.defaultProps = {
  style: {},
  classNameProp: '',
  disabled: false,
  fullWidth: false
}

EntityOpenDialogButton.propTypes = {
  component: PropTypes.element.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  btTitle: PropTypes.string.isRequired,
  style: PropTypes.object,
  classNameProp: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
}

export { EntityOpenDialogButton, EntityOpenDialogIcon }
