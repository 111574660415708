import React from "react"
import { AppMsgs } from "../../constants/appMsgs"
import LFInput from "./LFInput"
import PropTypes from "prop-types"

function LFEmailInput(props) {
  return <LFInput
    type="email"
    {...props}
  />
}

LFEmailInput.defaultProps = {
  label: AppMsgs.DEFAULT_VALUES.INPUT.EMAIL_LBL,
  name: "email",
}

LFEmailInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string
}

export { LFEmailInput }
