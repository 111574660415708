import { api, getRequest } from "../../../../api/api"
import { tecnicosUrl } from "../apiTecnicos"


function readMarcacoesTec({id}) {
  // TODO alterar a resposta da api de maneira a devolver as últimas XXX
  const request = getRequest({URLname: tecnicosUrl, id}).GET(`marcacoes?perpage=10000`)
  return api(request).then(r => {
    // console.log('readMarcacoesTec', r.data.data)
    return r.data.data
  })
}


export {
  readMarcacoesTec,
}
