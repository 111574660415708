import React from "react"
import planosAplicaveisMsgs from "../msgsPlanosAplicaveis"
import Grid from "@material-ui/core/Grid"
import { H2Slim } from "../../../components/SharedStyles/SharedStyles"
import { immBgUltraLight, primaryColorDark, secondaryColor } from "../../../constants/appTheme"
import { mapPropsGridPlanoAtivado } from "../../Planos/ListPlanosAtivados/partials/mapPropsGridPlanoAtivado"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { createStyles } from "@material-ui/core"
import TableDiasAntecedencia from "../../../components/SharedComponents/Shared/Tables/TableDiasAntecedencia"
import TableTaxas from "../../../components/SharedComponents/Shared/Tables/TblsAcaoTaxasDescontos/TableTaxas"
import BoolColoredCircleIcon from "../../../components/SharedComponents/Shared/BoolColoredCircleIcon"
import msgsPlanosAtivados from "../../Planos/ListPlanosAtivados/msgsPlanosAtivados"
import TableTaxasCliente from "../../../components/SharedComponents/Shared/Tables/TblsAcaoTaxasDescontos/TableTaxasCliente"
import { Link as RouterLink } from "react-router-dom"
import { getRequest } from "../../../api/api"
import { equipUrl } from "../../Equips/ListEquips/apiEquips"
import EquipamentoIcon from "../../../components/Svg-Icons/EquipamentoIcon"
import IconButton from "@material-ui/core/IconButton"
import { planosUrl } from "../../Planos/ListPlanos/apiPlanos"
import PlanoIcon from "../../../components/Svg-Icons/PlanoIcon"
import TablePeriodicidades from "../../../components/SharedComponents/Shared/Tables/TablePeriodicidades"
import TableDuracao from "../../../components/SharedComponents/Shared/Tables/TableDuracao"
import TableDescontos from "../../../components/SharedComponents/Shared/Tables/TblsAcaoTaxasDescontos/TableDescontos"
import { ShowDateTime } from "../../../components/SharedComponents/Shared/Show/ShowDateTime"
import ExpandPanelPA from "./PeriodosExpandPanel"
import { PADetailPanel } from "../../Planos/ListPlanosAtivados/partials/PADetailPanel"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import Slide from "@material-ui/core/Slide"

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      fontSize: '.9rem',
      marginTop: '1.5rem',
    },
    checkRow: {
      background: immBgUltraLight,
      marginBottom: '1rem',
    },
  })
)

const GridPlanoAtivo = ({simulacao, ...mainProps}) => {
  const classes = useStyles()
  const [checked, setChecked] = React.useState(!!simulacao)

  const {
    state, plano,
    dataInic, dataFim,
    equipcli_id,
    taxas, duracoes,
    periodosPA,
    ...props
  } = mapPropsGridPlanoAtivado(simulacao, mainProps)

  const hasDataFim = !!dataFim

  const handleChange = () => {
    setChecked((prev) => !prev)
  }

  return (
    <Grid container spacing={2} justify="center" className={classes.root}>
      <Grid item xs={simulacao ? 12 : 8} sm={simulacao ? 12 : 10}>
        <H2Slim style={{color: secondaryColor}}>{`${plano.refAN} - ${plano.designacao} (Rev. ${plano.rev}) `}</H2Slim>
      </Grid>
      {(!simulacao)
        ? <>
          <Grid item xs={2} sm={1}>
            <IconButton
              component={RouterLink}
              to={getRequest({URLname: planosUrl, id: plano.id}).gotoItemPath()}
              disabled={!equipcli_id}>
              <PlanoIcon color={primaryColorDark}/>
            </IconButton>
          </Grid>
          <Grid item xs={2} sm={1}>
            <IconButton
              component={RouterLink}
              to={getRequest({URLname: equipUrl, id: equipcli_id}).gotoItemPath()}
              disabled={!equipcli_id}>
              <EquipamentoIcon color={primaryColorDark}/>
            </IconButton>
          </Grid>
        </> : null}
      {/*------------------------------------------------*/}
      <Grid item xs={simulacao ? 12 : 7} sm={simulacao ? 12 : 10}>
        <div>
          <span style={{minWidth: '70px', display: 'inline-block'}}>{`${planosAplicaveisMsgs.EDIT.LABEL.DATA_INIC}: `}</span>
          <span style={{fontWeight: '500'}}>{dataInic}</span>
        </div>
        {(hasDataFim)
          ? (
            <div>
              <span style={{minWidth: '70px', display: 'inline-block'}}>{`${msgsPlanosAtivados.DATA_FIM}: `}</span>
              <span style={{fontWeight: '500'}}>{dataFim}</span>
            </div>)
          : null}
        {(!simulacao)
          ? (
            <div>
              <span style={{minWidth: '70px', display: 'inline-block'}}>{msgsPlanosAtivados.DURACAO}</span>
              {(duracoes.mesesDur > 0)
                ? (
                  <span style={{
                    minWidth: '70px',
                    display: 'inline-block',
                    textAlign: 'center',
                    color: secondaryColor,
                    fontSize: '150%'
                  }}>{duracoes.mesesDur}</span>)
                : (
                  <span style={{
                    minWidth: '70px',
                    display: 'inline-block',
                    textAlign: 'center',
                    color: secondaryColor,
                    fontSize: '150%'
                  }}>&infin;</span>)
              }
            </div>)
          : null}
      </Grid>
      {(!simulacao)
        ? <Grid item xs={5} sm={2} style={{fontSize: '85%'}}>
          <div><span style={{minWidth: '75px', display: 'inline-block'}}>{msgsPlanosAtivados.PA_STATE.ATIVO}</span> <BoolColoredCircleIcon
            flagTF={state.ativ} size={12}/></div>
          <div><span style={{minWidth: '75px', display: 'inline-block'}}>{msgsPlanosAtivados.PA_STATE.CONCLUIDO}</span> <BoolColoredCircleIcon
            flagTF={state.conc} size={12}/></div>
          <div><span style={{minWidth: '75px', display: 'inline-block'}}>{msgsPlanosAtivados.PA_STATE.DESATIVADO}</span> <BoolColoredCircleIcon
            flagTF={state.anul} size={12}/></div>
        </Grid> : null}
      {/*------------------------------------------------*/}
      {(!simulacao)
        ?
        <>
          <Grid item xs={state.anul ? false : 6} sm={state.anul ? 8 : 10}> </Grid>
          <Grid item xs={5} sm={2}>
            <span style={{fontSize: '85%'}}>{msgsPlanosAtivados.PA_STATE.DATA_ATIV}</span>
            <ShowDateTime dateTime={props.ativado_at} offLimit={false}/>
          </Grid>
          {state.anul ? <Grid item xs={5} sm={2}>
            <span style={{fontSize: '85%'}}>{msgsPlanosAtivados.PA_STATE.DATA_ANUL}</span>
            <ShowDateTime dateTime={props.desativado_at} offLimit={true}/>
          </Grid> : null}
        </> : null}
      {/*------------------------------------------------*/}
      <Grid item xs={12} className={classes.checkRow}>
        <FormControlLabel
          control={<Switch checked={checked} onChange={handleChange}/>}
          label={msgsPlanosAtivados.MAIS_INFO}
          labelPlacement="start"
        />
      </Grid>
      {/*------------------------------------------------*/}
      {(!simulacao) &&
      <Slide direction="up" in={checked} mountOnEnter unmountOnExit>
        <Grid item sm={12}>
          <PADetailPanel planoAtivado={mainProps}/>
        </Grid>
      </Slide>}
      {/*------------------------------------------------*/}
      <Slide direction="up" in={checked} mountOnEnter unmountOnExit>
        <Grid item xs={12} sm={12} md={6}>
          {(!!simulacao) ? <TableDiasAntecedencia {...mainProps}/> : null}
          <TableTaxas {...taxas}/>
          <TableTaxasCliente {...taxas}/>
        </Grid>
      </Slide>
      {/*------------------------------------------------*/}
      <Slide direction="up" in={checked} mountOnEnter unmountOnExit>
        <Grid item sm={12} md={6}>
          <TablePeriodicidades {...plano}/>
          <TableDuracao {...duracoes}/>
          <TableDescontos  {...taxas}/>
        </Grid>
      </Slide>
      <Slide direction="up" in={checked} mountOnEnter unmountOnExit>
        <Grid item sm={12}>
          <ExpandPanelPA periodosPA={periodosPA}/>
        </Grid>
      </Slide>
    </Grid>)
}

export { GridPlanoAtivo }
