import React, { useState } from "react"
import patInfoStatesMsgs from "../patInfoStatesMsgs"
import Grid from "@material-ui/core/Grid"
import LeftMarginSpan from "../../../../../components/SharedStyles/LeftMarginSpan"
import VisitaPatEndNotResolvidaIcon from "../../../../../components/Svg-Icons/VisitaPatEndNotResolvidaIcon"
import VisitaPatEndResolvidaIcon from "../../../../../components/Svg-Icons/VisitaPatEndResolvidaIcon"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { LeftRightSwitch } from "../../../../../components/SharedComponents/Shared/LeftRightSwitch/LeftRightSwitch"
import Paper from "@material-ui/core/Paper"
import { ConcluirComResolucao } from "./ConcluirComResolucao"
import { ConcluirComReagendamento } from "./ConcluirComReagendamento"


const useStyles = makeStyles((theme) => ({
  paperBox: {
    margin: '1em auto 0',
    padding: '2em 0 0 0',
    width: '340px',
  },
}))

const InnerMsgComponent = ({patResolvido, withText}) => {

  let text = (!!patResolvido
    ? <LeftMarginSpan style={{fontSize: '80%'}}>{patInfoStatesMsgs.BT_CONC_VISITA_PAT_RESOLVED}</LeftMarginSpan>
    : <LeftMarginSpan style={{fontSize: '80%'}}>{patInfoStatesMsgs.BT_CONC_VISITA_PAT_NOT_RESOLVED}</LeftMarginSpan>)
  text = (!!withText ? text : null)
  return (
    !!patResolvido
      ? (
        <React.Fragment>
          <VisitaPatEndResolvidaIcon style={{width: '30px', height: '30px'}}/>
          {text}
        </React.Fragment>)
      : (
        <React.Fragment>
          <VisitaPatEndNotResolvidaIcon style={{width: '30px', height: '30px'}}/>
          {text}
        </React.Fragment>))
}

const ConcluirPATouVisitaForm = ({pat, visitaID, reload}) => {
  const [patResolvido, setPatResolvido] = useState(true)
  const classes = useStyles()

  const FormConclusao = () => {
    return patResolvido
      ? (<ConcluirComResolucao pat={pat} visitaID={visitaID} reload={reload}/>)
      : (<ConcluirComReagendamento visitaID={visitaID} reload={reload}/>)
  }

  const onSwitchChange = (event, value) => {
    setPatResolvido(value)
  }

  const text = (!!patResolvido
    ? <p style={{fontSize: '80%'}}>{patInfoStatesMsgs.BT_CONC_VISITA_PAT_RESOLVED}</p>
    : <p style={{fontSize: '80%'}}>{patInfoStatesMsgs.BT_CONC_VISITA_PAT_NOT_RESOLVED}</p>)

  return (
    <Grid container spacing={2} justify="center" alignItems="center">
      {/*---------------------------------------------------------------*/}
      <Grid item xs={12}>
        <Paper elevation={3} className={classes.paperBox}>

          <Grid container spacing={2}
                direction="column"
                justify="center"
                alignItems="center">
            <LeftRightSwitch onChild={<InnerMsgComponent patResolvido={true} withText={false}/>}
                             offChild={<InnerMsgComponent patResolvido={false} withText={false}/>}
                             checked={patResolvido} onchangeFn={onSwitchChange}
                             innerClassName={classes.switch}/>
            {text}
          </Grid>

        </Paper>
      </Grid>
      {/*-------------------------- Form -------------------------------------*/}
      <Grid item xs={12}>
        <FormConclusao/>
      </Grid>
    </Grid>
  )
}

const ConcluirVisitaPATForm = ({repo, reload}) => {
  return (
    <ConcluirPATouVisitaForm pat={repo}
                             visitaID={repo.lastEstado && repo.lastEstado.data && repo.lastEstado.data.visita_pat_id}
                             reload={reload}/>
  )
}

const ConcluirVisitaForm = ({repo, reload}) => {
  const pat = (repo && repo.pat && repo.pat.data)
  return (
    <ConcluirPATouVisitaForm pat={pat}
                             visitaID={repo.id}
                             reload={reload}/>
  )
}

export {
  ConcluirVisitaForm,
  ConcluirVisitaPATForm
}
