import React from "react"
import SvgIcon from "./SvgIcon"

const SimpleFlowIcon = (others) => {
  return (
    <SvgIcon width="47px" height="47px" viewBox="0 0 47 47" {...others}>
      <path d="M47,14.955V2.136C47,0.956,46.043,0,44.863,0H32.046c-1.181,0-2.137,0.957-2.137,2.136v5.341
			c-10.436,0-19.204,7.173-21.698,16.841L5.99,23.927c-0.399-0.069-0.803,0.091-1.044,0.416c-0.24,0.325-0.278,0.759-0.095,1.12
			l2.314,4.569C3.109,30.693,0,34.213,0,38.455C0,43.167,3.834,47,8.546,47c4.35,0,7.942-3.27,8.471-7.477h7.551v2.136
			c0,0.405,0.229,0.774,0.591,0.956c0.362,0.18,0.795,0.142,1.12-0.102l3.734-2.802c0.61,4.116,4.158,7.288,8.44,7.288
			C43.166,47,47,43.167,47,38.455c0-4.35-3.27-7.943-7.479-8.471v-7.552h2.138c0.403,0,0.774-0.229,0.954-0.59
			c0.182-0.361,0.144-0.795-0.1-1.119l-2.725-3.631h5.072C46.043,17.091,47,16.134,47,14.955z M8.546,42.727
			c-2.356,0-4.273-1.916-4.273-4.271s1.917-4.273,4.273-4.273s4.272,1.918,4.272,4.273S10.902,42.727,8.546,42.727z M42.729,38.455
			c0,2.355-1.918,4.271-4.273,4.271s-4.271-1.916-4.271-4.271s1.916-4.273,4.271-4.273S42.729,36.1,42.729,38.455z M34.295,21.841
			c0.182,0.362,0.551,0.59,0.955,0.59h2.137v7.551c-3.794,0.478-6.814,3.447-7.373,7.215l-3.736-2.803
			c-0.323-0.242-0.756-0.28-1.118-0.101c-0.361,0.183-0.591,0.551-0.591,0.956v2.136h-7.551c-0.484-3.862-3.553-6.927-7.418-7.405
			c0.003-0.024,0.015-0.045,0.015-0.071c0-0.005,0-0.007,0-0.011l3.233-3.027c0.295-0.276,0.408-0.696,0.294-1.084
			c-0.117-0.389-0.44-0.678-0.839-0.748l-1.983-0.35C12.632,16.024,20.526,9.613,29.91,9.613v5.341c0,1.18,0.956,2.136,2.137,2.136
			h5.073l-2.724,3.632C34.152,21.046,34.113,21.479,34.295,21.841z M42.729,12.818h-8.547V4.273h8.547V12.818z"/>
    </SvgIcon>
  )
}

export default SimpleFlowIcon
