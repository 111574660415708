import { Form } from "../../../../../components/SharedStyles/SharedStyles"
import React from "react"
import patInfoStatesMsgs from "../patInfoStatesMsgs"
import { parseToIntegerPercent, parseToPercent } from "../../../../../constants/taxasConst"
import { useAsync } from "react-async"
import { FormContext, useForm } from "react-hook-form"
import Grid from "@material-ui/core/Grid"
import FormHelperText from "@material-ui/core/FormHelperText"
import Typography from "@material-ui/core/Typography"
import { LFSwitchInput } from "../../../../../components/LFInputs/LFSwitchInput"
import LFTextEditor from "../../../../../components/LFInputs/LFTextEditor"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import LeftMarginSpan from "../../../../../components/SharedStyles/LeftMarginSpan"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { conferirPat } from "../apiInfoStates"
import * as Yup from "yup"
import { betweenNumberValidation } from "../../../../../constants/appValidation"
import LFInput from "../../../../../components/LFInputs/LFInput"
import { PatReportIntroWithPecas } from "../ConcluirVisita/PatReportIntroWithPecas"


const useStyles = makeStyles(theme => ({
  quillEditor: {
    minHeight: '8em'
  },
  pagoSim: {
    fontWeight: 'bold',
    fontSize: '90%',
    color: '#073',
  },
  pagoNao: {
    fontWeight: 'bold',
    fontSize: '90%',
    color: '#a00',
  },
  mutEx: {
    fontSize: '10px',
    color: '#a00',
    marginBottom: '1em',
  },
  formSep: {
    marginTop: '3em',
  },
}))

const validationSchema = Yup.object().shape({
  descontoGlobal: betweenNumberValidation(0, 100, patInfoStatesMsgs.DESCONTO_GLOBAL),
  percDescPecas: betweenNumberValidation(0, 100, patInfoStatesMsgs.DESCONTO_PECAS),
  percDescDesloc: betweenNumberValidation(0, 100, patInfoStatesMsgs.DESCONTO_DESLOC),
  percDescMO: betweenNumberValidation(0, 100, patInfoStatesMsgs.DESCONTO_MO),
})

const defaultValues = (pat) => {

  return {
    aFaturar: pat.isAFaturar || false,
    obsPATConferidoIMM: pat.obsPATConferidoIMM || '',
    descontoGlobal: parseToIntegerPercent(pat.descontoGlobal),
    percDescPecas: parseToIntegerPercent(pat.percDescPecas),
    percDescDesloc: parseToIntegerPercent(pat.percDescDesloc),
    percDescMO: parseToIntegerPercent(pat.percDescMO),
  }
}

const ConferirValoresPAT = ({pat, reload}) => {
  const classes = useStyles()
  const {isPending, run} = useAsync({
    deferFn: conferirPat,
    onResolve: (resp) => {
      reload()
    }
  })

  const methods = useForm({
    validationSchema: validationSchema,
    defaultValues: defaultValues(pat)
  })

  const {handleSubmit, formState, watch, control} = methods

  const watchFaturar = watch('aFaturar')
  const watchDescontoGlobal = watch('descontoGlobal')

  const onSubmit = ({...values}) => {
    const convertValues = {
      ...values, ...{
        pat_id: pat.id,
        aFaturar: values.aFaturar,
        obsPATConferidoIMM: values.obsPATConferidoIMM,
        descontoGlobal: parseToPercent(values.descontoGlobal),
        percDescPecas: parseToPercent(values.percDescPecas),
        percDescDesloc: parseToPercent(values.percDescDesloc),
        percDescMO: parseToPercent(values.percDescMO),
      }
    }
    run({...convertValues})
  }

  return (
    <FormContext {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)} className={classes.formSep}>
        <Grid container spacing={2} justify="flex-start">
          {/*---------------------------------------------------------------*/}
          <Grid item xs={12} sm={6}>
            <FormHelperText>{patInfoStatesMsgs.SWITCH_A_FATURAR_TITLE}</FormHelperText>
            <Typography component="div">
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item className={!watchFaturar ? classes.pagoNao : ''}>{patInfoStatesMsgs.PAGO_NAO}</Grid>
                <Grid item>
                  <LFSwitchInput control={control} name='aFaturar'/>
                </Grid>
                <Grid item className={watchFaturar ? classes.pagoSim : ''}>{patInfoStatesMsgs.PAGO_SIM}</Grid>
              </Grid>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <LFInput label={patInfoStatesMsgs.DESCONTO_GLOBAL} name='descontoGlobal'/>
            {(!!watchDescontoGlobal) && <Typography className={classes.mutEx}>
              {patInfoStatesMsgs.DESCONTO_GLOBAL_MUTEX}
            </Typography>}
          </Grid>
          {/*---------------------------------------------------------------*/}
          <Grid container spacing={2} justify="center">
            <Grid item xs={12} sm={4}>
              <LFInput label={patInfoStatesMsgs.DESCONTO_PECAS} name='percDescPecas'/>
            </Grid>
            <Grid item xs={12} sm={4}>
              <LFInput label={patInfoStatesMsgs.DESCONTO_DESLOC} name='percDescDesloc'/>
            </Grid>
            <Grid item xs={12} sm={4}>
              <LFInput label={patInfoStatesMsgs.DESCONTO_MO} name='percDescMO'/>
            </Grid>
          </Grid>
          {/*---------------------------------------------------------------*/}
          <Grid item xs={12} sm={12}>
            <FormHelperText>{patInfoStatesMsgs.OBS_IMM}</FormHelperText>
            <LFTextEditor name='obsPATConferidoIMM' control={control} className={classes.quillEditor}/>
          </Grid>
          {/*---------------------------------------------------------------*/}
          <Grid item xs={12} sm={12}>
            <Button
              onClick={onSubmit}
              style={{padding: '1rem', fontSize: '0.875rem', lineHeight: '1.2rem'}}
              startIcon={isPending && <CircularProgress color="secondary" size={20}/>}
              type="submit"
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              disabled={isPending || !formState.dirty}>
              <React.Fragment>
                <LeftMarginSpan>{patInfoStatesMsgs.BT_CONFERIR_PAT}</LeftMarginSpan>
              </React.Fragment>
            </Button>
          </Grid>
        </Grid>
      </Form>
    </FormContext>
  )
}

const ConferirPATForm = ({repo: pat, reload}) => {

  return (
    <React.Fragment>
      <PatReportIntroWithPecas pat={pat} isPecasDisable={false} reloadReport={reload}/>

      <ConferirValoresPAT pat={pat} reload={reload}/>
    </React.Fragment>
  )
}

export { ConferirPATForm }
