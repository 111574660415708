import React from "react"
import { useAsync } from "react-async"
import { FormContext, useForm } from "react-hook-form"
import { Form } from "../../components/SharedStyles/SharedStyles"
import Grid from "@material-ui/core/Grid"
import LFInput from "../../components/LFInputs/LFInput"
import LFSubmitButton from "../../components/SharedComponents/Shared/LFButtons/LFSubmitButton"
import * as Yup from "yup"
import { requiredValidation } from "../../constants/appValidation"
import LFImageInput from "../../components/LFInputs/ImageUploader/LFImageInput"
import { NO_IMAGE } from "../../constants/appConst"
import marcasMsgs from "./msgs"

const validationSchema = Yup.object().shape({
  marca: requiredValidation(marcasMsgs.EDIT.LABEL.MARCA),
  logo: requiredValidation(marcasMsgs.EDIT.LABEL.LOGO)
})

const defaultValues = marca => {
  return {
    marca: marca ? marca.marca : '',
    logo: marca ? marca.logo : NO_IMAGE
  }
}

function MarcaForm({marca, method, setMarca}) {
  const {isPending, run} = useAsync({
    deferFn: method,
    onResolve: (newMarca) => {
      setMarca(newMarca)
      reset(newMarca)
    }
    //Reject não é necessário porque foi capturado no apiCUD
  })

  const methods = useForm({
    validationSchema: validationSchema,
    defaultValues: defaultValues(marca)
  })
  const {handleSubmit, formState, control, reset} = methods

  const onSubmit = (values) => {
    run({...marca, ...values})
  }

  return (
    <FormContext {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} justify="center">
          {/*  -------------------------------------- LOGO ----------------------------------------*/}
          <Grid item xs={12}>
            <LFImageInput image={marca ? marca.logo : NO_IMAGE} control={control} name="logo" label={marcasMsgs.EDIT.LABEL.LOGO}/>
          </Grid>
          {/* -------------------------------------- MARCA -----------------------------------------*/}
          <Grid item xs={12}>
            <LFInput label={marcasMsgs.EDIT.LABEL.MARCA} name='marca'/>
          </Grid>
        </Grid>
        <LFSubmitButton isLoading={isPending} dirty={!formState.dirty}>
          {marca ? marcasMsgs.BUTTON.EDIT_MARCA : marcasMsgs.BUTTON.NEW_MARCA}
        </LFSubmitButton>
      </Form>
    </FormContext>
  )
}

export default MarcaForm
