import React from 'react'
import { styled } from "@material-ui/core"
import PropTypes from "prop-types"

const Svg = styled('svg')({
  display: 'inline-block',
  fill: props => props.color,
  height: props => props.size,
  width: props => props.size,
  userSelect: 'none',
})


const SvgIcon = (props) => {
  const {size, color, viewBox, children, ...others} = props

  return (
    <Svg
      {...others}
      size={size}
      color={color}
      viewBox={viewBox}>
      {children}
    </Svg>
  )
}

SvgIcon.defaultProps = {
  size: 20,
  viewBox: '0 0 24 24',
}

SvgIcon.propTypes = {
  size: PropTypes.number,
  viewBow: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]).isRequired
}

export default SvgIcon
