import React, { useContext } from "react"
import ModeloForm from "./ModeloForm"
import { createMarcaModelo } from "./apiMarcaModelos"
import Title from "../../../components/SharedComponents/Shared/Title"
import { RedirectState } from "./Modelos"
import marcaModelosMsgs from "./msgs"

function ModeloCreate({marcaID}) {

  const {list} = useContext(RedirectState)

  const handleBack = () => list()

  return (
    <React.Fragment>
      <Title handleBack={handleBack}>{marcaModelosMsgs.BACK_LABEL}</Title>
      <ModeloForm method={createMarcaModelo} marcaID={marcaID}/>
    </React.Fragment>
  )
}

export default ModeloCreate
