import React, { useEffect, useState } from "react"
//----------------------------------------------------
// Calendar
//----------------------------------------------------
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import listPlugin from '@fullcalendar/list'
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
// YARN!! @fullcalendar/react @fullcalendar/core
//        @fullcalendar/daygrid @fullcalendar/timegrid @fullcalendar/list
//        @fullcalendar/interaction
import "@fullcalendar/core/main.css"
import "@fullcalendar/daygrid/main.css"
import "@fullcalendar/timegrid/main.css"
import "@fullcalendar/list/main.css"
import ptLocale from '@fullcalendar/core/locales/pt'
//----------------------------------------------------
import moment from "moment"
import { format, momentTZ, timezone } from "./momentTZ"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Switch from "@material-ui/core/Switch"
import IconButton from "@material-ui/core/IconButton"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { ShowDateTime } from "../Show/ShowDateTime"
import CheckIcon from "../../../Svg-Icons/CheckIcon"
import PropTypes from "prop-types"
import { secondaryColorLight } from "../../../../constants/appTheme"
import calendarMsgs from "./calendarMsgs"

const parseMarcacao = (marcacao) => {
  const mm = momentTZ(marcacao.dhMarcacao || [])
  const dur = parseInt(marcacao.minutosDurPrevista, 10) || 60
  return {
    id: marcacao.id || 0,
    title: marcacao.obs || '',
    start: mm.toDate(),
    end: moment(mm).add(dur, 'm').toDate(),
    dur: dur,
    editable: false,
  }
}

const createString = ({showMonth, showDay, showWeek, showAgenda}) => {

  const srtParams = []
  if (showMonth)
    srtParams.push(`dayGridMonth`)
  if (showDay)
    srtParams.push(`timeGridDay`)
  if (showWeek)
    srtParams.push(`timeGridWeek`)
  if (showAgenda)
    srtParams.push(`listMonth`)


  return srtParams.join(',')
}


const CalendarComponent = ({
                             calendarItems, handleApprovedDateTime, minTime, maxTime, slotDuration, showHeader,
                             cldrWkds, showCldrWkdsToggle, showMonth, showDay, showWeek, showAgenda,
                             defaultView
                           }) => {

  const calendarComponentRef = React.createRef()
  const [calendarWeekends, setCalendarWeekends] = useState(cldrWkds)
  const [selectedDateTime, setSelectedDateTime] = React.useState(null)
  const [plugins, setPlugins] = React.useState([])
  const [rightHeaderButtons, setRightHeaderButton] = React.useState('')
  useEffect(() => {
    if (handleApprovedDateTime) {
      setPlugins(plugins => plugins.concat(interactionPlugin))
    }
    if (showDay || showWeek) {
      setPlugins(plugins => plugins.concat(timeGridPlugin))
    }

    if (showMonth) {
      setPlugins(plugins => plugins.concat(dayGridPlugin))
    }
    if (showAgenda) {
      setPlugins(plugins => plugins.concat(listPlugin))
    }
    setRightHeaderButton(createString({showMonth, showDay, showWeek, showAgenda}))
  }, [handleApprovedDateTime, showMonth, showDay, showWeek, showAgenda])

  const toggleWeekends = () => {
    setCalendarWeekends(!calendarWeekends)
  }

  const handleSelectedDate = ({dateStr, ...other}) => {
    const mm = moment(dateStr).format(format)
    setSelectedDateTime(mm)
  }
  return (
    <React.Fragment>
      <Grid container direction="row" justify="space-between" alignItems="center" style={{marginBottom: '2em'}}>
        {showCldrWkdsToggle && <Grid item xs>
          <FormControlLabel
            control={
              <Switch
                checked={calendarWeekends}
                onChange={toggleWeekends}
                name="toggleWeekends"
                color="secondary"
              />
            }
            label={calendarMsgs.TOGGLE_WEEKENDS_LBL}
            labelPlacement="start"
          />
        </Grid>}
        <Grid item xs>
          {(!selectedDateTime) ? null : <Box style={{padding: '.5rem'}} display="flex" alignItems="center" flexDirection="row">
            <Box>
              <ShowDateTime dateTime={selectedDateTime}/>
            </Box>
            <Box>
              <IconButton style={{padding: "0px 10px"}}
                // disabled={!selectedDateTime}
                          onClick={() => handleApprovedDateTime(selectedDateTime)}>
                <CheckIcon color={secondaryColorLight}/>
              </IconButton>
            </Box>
          </Box>}
        </Grid>
      </Grid>
      <div style={{marginBottom: '1em'}}>
        {!!plugins.length && <FullCalendar
          locale={ptLocale}
          timeZone={timezone}
          minTime={minTime}
          maxTime={maxTime}
          allDaySlot={false}
          slotDuration={slotDuration}
          slotLabelFormat={{
            hour: 'numeric',
            minute: '2-digit',
          }}
          defaultView={defaultView}
          header={(showHeader) && {
            left: "prev,next today",
            center: "title",
            right: rightHeaderButtons
          }}
          contentHeight="auto"
          plugins={plugins}
          ref={calendarComponentRef}
          weekends={calendarWeekends}
          events={calendarItems}
          dateClick={handleApprovedDateTime && handleSelectedDate}
        />}
      </div>
    </React.Fragment>
  )
}

CalendarComponent.defaultProps = {
  minTime: "8:30",
  maxTime: "21:30",
  slotDuration: "00:30:00",
  handleApprovedDateTime: undefined,
  showHeader: true,
  cldrWkds: false,
  showCldrWkdsToggle: true,
  showMonth: true,
  showDay: true,
  showWeek: true,
  showAgenda: true,
  defaultView: "dayGridDay"
}

CalendarComponent.propTypes = {
  calendarItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
    dur: PropTypes.number,
    editable: PropTypes.bool
  })).isRequired,
  handleApprovedDateTime: PropTypes.func,
  minTime: PropTypes.string,
  maxTime: PropTypes.string,
  slotDuration: PropTypes.string,
  showHeader: PropTypes.bool,
  cldrWkds: PropTypes.bool,
  showCldrWkdsToggle: PropTypes.bool,
  showMonth: PropTypes.bool,
  showDay: PropTypes.bool,
  showWeek: PropTypes.bool,
  showAgenda: PropTypes.bool,
  rightHeaderButtons: PropTypes.string,
  defaultView: PropTypes.string,
}

export {
  CalendarComponent, parseMarcacao
}
