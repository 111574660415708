import { api, apiCUD, apiReadTable, getRequest } from "../../api/api"
import Config from "./Config"
import GearIcon from "../../components/Svg-Icons/GearIcon"
import msgsConfig from "./msgsConfig"
import AllLogs from "./AllLogs"
import ListMenuIcon from "../../components/Svg-Icons/ListMenuIcon"


const configUrl = 'conf'
const alllogsURL = 'alllogs'

// ----------------------- CONFIG ROUTES -----------------------
const configRouterProps = {
  path: getRequest({URLname: configUrl}).paginatePath(),
  exact: true,
  name: msgsConfig.LABEL,
  component: Config,
  Icon: GearIcon,
  breadcrumb: () => msgsConfig.LABEL,
  reqRole: 'R'
}

const alllogsRouterProps = {
  path: getRequest({URLname: alllogsURL}).paginatePath(),
  exact: true,
  name: msgsConfig.ALL_USERS_LOGS,
  component: AllLogs,
  Icon: ListMenuIcon,
  breadcrumb: () => msgsConfig.ALL_USERS_LOGS,
  reqRole: 'R'
}
// ----------------------- CONFIG ROUTES -----------------------
const convertObjConfig = (config) => {
  return {
    app_abrev: config.app.abrev,
    app_name: config.app.name,
    app_version_major: config.app.version.major,
    app_version_minor: config.app.version.minor,
    app_version_revision: config.app.version.revision,
    gpm_horizonteAvisos: config.gpm.horizonteAvisos,
    gpm_txIVA: config.gpm.txIVA,
    gpm_maxMesesAssocPlanoNoPassado: config.gpm.maxMesesAssocPlanoNoPassado || config.maxMesesAssocPlanoNoPassado,
    gpm_txDesloc_CAT: config.gpm.txDesloc.CAT,
    gpm_txDesloc_IMM: config.gpm.txDesloc.IMM,
    gpm_txMO_CAT: config.gpm.txMO.CAT,
    gpm_txMO_IMM: config.gpm.txMO.IMM,
    gpm_DiasAntecedencia_Avisos: config.gpm.DiasAntecedencia.Avisos,
    gpm_DiasAntecedencia_PMP: config.gpm.DiasAntecedencia.PMP,
    gpm_DiasAntecedencia_PA: config.gpm.DiasAntecedencia.PA,
    gpm_refAN_Deslocacao: config.gpm.refAN.Deslocacao,
    gpm_refAN_MaoObra: config.gpm.refAN.MaoObra,
    gpm_refAN_MCE: config.gpm.refAN.MCE,
    gpm_PGB_durCliDomest: config.gpm.PGB.durCliDomest,
    gpm_PGB_durCliInstit: config.gpm.PGB.durCliInstit,
    app_licence_sendLoginEmails: config.app.licence.sendLoginEmails,
    gpm_mailIPDOc: config.gpm.mailIPDoc,
    gpm_patDefaultDurMn: config.gpm.patDefaultDurMn,
    app_licence_isDemoMode: config.app.licence.isDemoMode
  }
}

function readConfig() {
  const request = getRequest({customUrl: configUrl}).GET()
  return api(request).then(r => convertObjConfig(r.data))
}

function updateConfig([data]) {
  const request = getRequest({customUrl: `${configUrl}/gpm`}).PUT('', true)
  return apiCUD(request, data)
}

/**
 * Read Marcas com paginação para tabela
 * @param query
 * @returns {Promise<*|void>}
 */
async function readAllLogsTable([query]) {
  const request = getRequest({URLprefix: '', URLname: alllogsURL, query}).ALL()
  return apiReadTable(request)
}

/**
 * Read Marcas com paginação para tabela
 * @returns {Promise<*|void>}
 */
async function readUserLogsTable(userID, [query]) {
  const request = getRequest({URLprefix: '', URLname: alllogsURL, query}).GET(userID)
  return apiReadTable(request)
}

export {
  configUrl, alllogsURL,
  configRouterProps, alllogsRouterProps,
  readConfig, updateConfig,
  readAllLogsTable, readUserLogsTable
}
