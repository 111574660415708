import React from "react"
import * as Yup from "yup"
import { avalImmValidation, requiredDateValidation, requiredHTMLEditorValidation, requiredValidation } from "../../../../../constants/appValidation"
import patInfoStatesMsgs from "../patInfoStatesMsgs"
import { Form } from "../../../../../components/SharedStyles/SharedStyles"
import Grid from "@material-ui/core/Grid"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { FormContext, useForm } from "react-hook-form"
import { LFFormatDateTime } from "../../../../../constants/appConst"
import { useAsync } from "react-async"
import LFDateTimePickerInput from "../../../../../components/LFInputs/LFDateTimePickerInput"
import FormHelperText from "@material-ui/core/FormHelperText"
import LFTextEditor from "../../../../../components/LFInputs/LFTextEditor"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Typography from "@material-ui/core/Typography"
import LFRatingInput from "../../../../../components/LFInputs/LFRatingInput"
import CircularProgress from "@material-ui/core/CircularProgress"
import Button from "@material-ui/core/Button"
import VisitaPatEndNotResolvidaIcon from "../../../../../components/Svg-Icons/VisitaPatEndNotResolvidaIcon"
import LeftMarginSpan from "../../../../../components/SharedStyles/LeftMarginSpan"
import { remarcarConclusaoVisitaPat } from "../../../VisitasPat/apiVisitaPat"
import LFAssinatura from "../../../../../components/LFInputs/LFAssina/LFAssinatura"
import MomentUtils from "@date-io/moment"
import moment from "moment"
import "moment/locale/pt"

const useStyles = makeStyles(theme => ({
  quillEditor: {
    minHeight: '8em'
  }
}))

const validationSchema = Yup.object().shape({
  dhAgendamentoComCliente: requiredDateValidation(patInfoStatesMsgs.DH_AGENDAMENTO),
  avalVisita: avalImmValidation(1, 5, patInfoStatesMsgs.AVAL_SLIDER_LBL),
  assinaturaCliente: requiredValidation(patInfoStatesMsgs.ASSINATURA_CLI),
  assinaturaTecnico: requiredValidation(patInfoStatesMsgs.ASSINATURA_TEC),
  obsFinal: requiredHTMLEditorValidation(patInfoStatesMsgs.CAUSA_REAGENDAMENTO),
})

const ConcluirComReagendamento = ({visitaID, reload}) => {
  const classes = useStyles()

  const {isPending, run} = useAsync({
    deferFn: remarcarConclusaoVisitaPat,
    onResolve: (resp) => {
      reload()
    }
  })

  const methods = useForm({
    validationSchema: validationSchema,
    defaultValues: {
      dhAgendamentoComCliente: new Date(),
      avalVisita: 3,
      assinaturaCliente: '',
      assinaturaTecnico: '',
      obsFinal: '',
    }
  })

  const {handleSubmit, formState, control} = methods

  const onSubmit = ({...values}) => {
    const convertValues = {
      ...values, ...{
        visita_id: visitaID,
        dhAgendamentoComCliente: values.dhAgendamentoComCliente && LFFormatDateTime(values.dhAgendamentoComCliente),
        avalVisita: values.avalVisita,
        assinaturaCliente: values.assinaturaCliente,
        assinaturaTecnico: values.assinaturaTecnico,
        obsFinal: values.obsFinal,
      }
    }
    run({...convertValues})
  }

  return (
    <FormContext {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} justify="center">
          {/*---------------------------------------------------------------*/}
          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <LFDateTimePickerInput name='dhAgendamentoComCliente'
                                     control={control}
                                     label={patInfoStatesMsgs.DH_AGENDAMENTO}/>
            </MuiPickersUtilsProvider>
          </Grid>
          {/*---------------------------------------------------------------*/}
          <Grid item xs={12} sm={6}>
            <Typography component="legend"
                        style={{paddingBottom: '5px'}}>{patInfoStatesMsgs.AVAL_SLIDER_LBL}</Typography>
            <LFRatingInput name="avalVisita" control={control} initValue={3}/>
          </Grid>
          {/*---------------------------------------------------------------*/}
          <Grid item xs={12}>
            <FormHelperText>{patInfoStatesMsgs.ASSINATURA_CLI}</FormHelperText>
            <LFAssinatura name='assinaturaCliente' control={control} validationError={patInfoStatesMsgs.ERRO_ASSINATURA_CLI}/>
          </Grid>
          {/*---------------------------------------------------------------*/}
          <Grid item xs={12}>
            <FormHelperText>{patInfoStatesMsgs.ASSINATURA_TEC}</FormHelperText>
            <LFAssinatura name='assinaturaTecnico' control={control} validationError={patInfoStatesMsgs.ERRO_ASSINATURA_TEC}/>
          </Grid>
          {/*---------------------------------------------------------------*/}
          <Grid item xs={12}>
            <FormHelperText>{patInfoStatesMsgs.OBS}</FormHelperText>
            <LFTextEditor name='obsFinal' control={control} className={classes.quillEditor}/>
          </Grid>
          {/*---------------------------------------------------------------*/}
          <Grid item xs={12}>
            <Button
              onClick={onSubmit}
              style={{padding: '1rem', fontSize: '0.875rem', lineHeight: '1.2rem'}}
              startIcon={isPending && <CircularProgress color="secondary" size={20}/>}
              type="submit"
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              disabled={isPending || !formState.dirty}>
              <React.Fragment>
                <VisitaPatEndNotResolvidaIcon style={{width: '30px', height: '30px'}}/>
                <LeftMarginSpan>{patInfoStatesMsgs.BT_CONC_VISITA_PAT_NOT_RESOLVED}</LeftMarginSpan>
              </React.Fragment>
            </Button>
          </Grid>
        </Grid>
      </Form>
    </FormContext>
  )
}

export {
  ConcluirComReagendamento,
}
