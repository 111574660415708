import { Controller, ErrorMessage, useFormContext } from "react-hook-form"
import React, { useState } from "react"
import 'react-quill/dist/quill.snow.css'
import makeStyles from "@material-ui/core/styles/makeStyles"
import SignaturePad from 'react-signature-canvas'
import { errorColorDark, primaryColorDark } from "../../../constants/appTheme"
import { base64Validation } from "../../../constants/appValidation"
import InputError from "../../SharedComponents/Shared/Error/InputError"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "../../Svg-Icons/DeleteIcon"

const useStyles = makeStyles(theme => ({
  sigImage: {
    width: '100%',
    height: '100px',
    backgroundColor: '#fff',
    objectFit: 'scale-down',
  },
  sigPad: {
    width: '100%',
    height: '300px',
    border: '1px dotted',
    borderColor: primaryColorDark,
  }
}))


const Assinatura = ({name, validationError}) => {
  const [trimmedDataURL, setTrimmedDataURL] = useState('')
  const [sigPad, setSigPad] = useState({})
  const styles = useStyles()
  const {errors, setValue, setError, clearError} = useFormContext()

  const onClear = () => {
    sigPad.clear()
    setTrimmedDataURL('')
    setValue(name, null)
    clearError(name)
  }

  const OnSignEnd = React.useCallback(_ => {
    const data = sigPad.getTrimmedCanvas().toDataURL('image/png')
    if (base64Validation(data)) {
      clearError(name)
      setTrimmedDataURL(data)
      setValue(name, data)
    } else {
      setError(name, 'base64Validation', (validationError || 'Signature Validation Error'))
    }
  }, [setValue, sigPad, name, clearError, setError, validationError, setTrimmedDataURL])


  return (
    <Grid container spacing={1} justify="center">

      <Grid item xs={2}>
        {trimmedDataURL
          ? (
            <React.Fragment>
              <img className={styles.sigImage}
                   src={trimmedDataURL} alt=""/>
              <IconButton style={{padding: "7px"}}
                          onClick={onClear}>
                <DeleteIcon color={errorColorDark}/>
              </IconButton>
            </React.Fragment>)
          : null}
      </Grid>

      <Grid item xs={10}>
        <SignaturePad canvasProps={{className: styles.sigPad}}
                      ref={(ref) => setSigPad(ref)} onEnd={OnSignEnd}/>
      </Grid>

      <Grid item xs={12}>
        <ErrorMessage errors={errors} name={name} as={<InputError/>}/>
      </Grid>

    </Grid>
  )
}

const LFAssinatura = (props) => {
  const {name, control} = props
  return (
    <Controller
      as={<Assinatura {...props} />}
      name={name}
      control={control}
    />
  )
}

export default LFAssinatura
