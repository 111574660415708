import React from "react"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import { TableFooter } from "@material-ui/core"
import { getValAsFloat, getValAsMoney } from "../../../../../constants/taxasConst"
import PropTypes from "prop-types"
import { StyledTableCell } from "../../../../SharedStyles/SharedStyles"
import makeStyles from "@material-ui/core/styles/makeStyles"

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.error.main,
  }
}))

const msgsTableTaxas = {
  TITLE: {
    TAXAS: "Taxas",
    IMM: "IMM",
    CAT: "CAT",
  },
  LABEL: {
    ATIVACAO: "Ativação",
    PERIODICA: "Periodica",
    DESLOCACAO: "Deslocação",
    MANUTENCAO: "Manutenção",
    AVISOS: "Avisos",
    MO: "Mão de Obra",
    PMCE: "PMCE",
    OBS_DESC: "Antes da aplicação dos descontos indicados na tabela anexa.",
  }
}

const TableTaxas = ({
                      txAtivCAT, txAtivIMM,
                      txPerCAT, txPerIMM,
                      txDeslocCAT, txDeslocIMM,
                      txMOCAT, txMOIMM,
                      txMCECAT, txMCEIMM
                    }) => {
  const classes = useStyles()
  return (
    <TableContainer>
      <Table size="small" aria-label="TableTaxas">
        <TableHead>
          <TableRow>
            <StyledTableCell colSpan={2}>{msgsTableTaxas.TITLE.TAXAS}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key="ativacao">
            <TableCell>{msgsTableTaxas.LABEL.ATIVACAO}</TableCell>
            <TableCell align="right">{getValAsMoney(getValAsFloat(txAtivIMM) + getValAsFloat(txAtivCAT))}</TableCell>
          </TableRow>
          <TableRow key="periodica">
            <TableCell>{msgsTableTaxas.LABEL.PERIODICA}</TableCell>
            <TableCell align="right">{getValAsMoney(getValAsFloat(txPerIMM) + getValAsFloat(txPerCAT))}</TableCell>
          </TableRow>
          <TableRow key="deslocacao">
            <TableCell>{msgsTableTaxas.LABEL.DESLOCACAO}<span className={classes.root}>*</span></TableCell>
            <TableCell align="right">{getValAsMoney(getValAsFloat(txDeslocIMM) + getValAsFloat(txDeslocCAT))}</TableCell>
          </TableRow>
          <TableRow key="maoObra">
            <TableCell>{msgsTableTaxas.LABEL.MO}<span className={classes.root}>*</span></TableCell>
            <TableCell align="right">{getValAsMoney(getValAsFloat(txMOIMM) + getValAsFloat(txMOCAT))}</TableCell>
          </TableRow>
          <TableRow key="pmce">
            <TableCell>{msgsTableTaxas.LABEL.PMCE}</TableCell>
            <TableCell align="right">{getValAsMoney(getValAsFloat(txMCEIMM) + getValAsFloat(txMCECAT))}</TableCell>
          </TableRow>
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan="2" className={classes.root}>* {msgsTableTaxas.LABEL.OBS_DESC}</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}
//
// const TableTaxas = ({
//                       txAtivCAT, txAtivIMM,
//                       txPerCAT, txPerIMM,
//                       txDeslocCAT, txDeslocIMM,
//                       txMOCAT, txMOIMM,
//                       txMCECAT, txMCEIMM
//                     }) => {
//   const classes = useStyles()
//   return (
//     <TableContainer>
//       <Table size="small" aria-label="TableTaxas">
//         <TableHead>
//           <TableRow>
//             <StyledTableCell>{msgsTableTaxas.TITLE.TAXAS}</StyledTableCell>
//             <StyledTableCell align="right">{msgsTableTaxas.TITLE.IMM}</StyledTableCell>
//             <StyledTableCell align="right">{msgsTableTaxas.TITLE.CAT}</StyledTableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           <TableRow key="ativacao">
//             <TableCell>{msgsTableTaxas.LABEL.ATIVACAO}</TableCell>
//             <TableCell align="right">{getValAsMoney(getValAsFloat(txAtivIMM))}</TableCell>
//             <TableCell align="right">{getValAsMoney(getValAsFloat(txAtivCAT))}</TableCell>
//           </TableRow>
//           <TableRow key="periodica">
//             <TableCell>{msgsTableTaxas.LABEL.PERIODICA}</TableCell>
//             <TableCell align="right">{getValAsMoney(getValAsFloat(txPerIMM))}</TableCell>
//             <TableCell align="right">{getValAsMoney(getValAsFloat(txPerCAT))}</TableCell>
//           </TableRow>
//           <TableRow key="deslocacao">
//             <TableCell>{msgsTableTaxas.LABEL.DESLOCACAO}<span className={classes.root}>*</span></TableCell>
//             <TableCell align="right">{getValAsMoney(getValAsFloat(txDeslocIMM))}</TableCell>
//             <TableCell align="right">{getValAsMoney(getValAsFloat(txDeslocCAT))}</TableCell>
//           </TableRow>
//           <TableRow key="maoObra">
//             <TableCell>{msgsTableTaxas.LABEL.MO}<span className={classes.root}>*</span></TableCell>
//             <TableCell align="right">{getValAsMoney(getValAsFloat(txMOIMM))}</TableCell>
//             <TableCell align="right">{getValAsMoney(getValAsFloat(txMOCAT))}</TableCell>
//           </TableRow>
//           <TableRow key="pmce">
//             <TableCell>{msgsTableTaxas.LABEL.PMCE}</TableCell>
//             <TableCell align="right">{getValAsMoney(getValAsFloat(txMCEIMM))}</TableCell>
//             <TableCell align="right">{getValAsMoney(getValAsFloat(txMCECAT))}</TableCell>
//           </TableRow>
//         </TableBody>
//         <TableFooter>
//           <TableRow>
//             <TableCell colSpan="3" className={classes.root}>* {msgsTableTaxas.LABEL.OBS_DESC}</TableCell>
//           </TableRow>
//         </TableFooter>
//       </Table>
//     </TableContainer>
//   )
// }

TableTaxas.defaultValues = {
  txAtivCAT: "N/A",
  txAtivIMM: "N/A",
  txPerCAT: "N/A",
  txPerIMM: "N/A",
  txDeslocCAT: "N/A",
  txDeslocIMM: "N/A",
  txMOCAT: "N/A",
  txMOIMM: "N/A",
  txMCECAT: "N/A",
  txMCEIMM: "N/A"
}

TableTaxas.propTypes = {
  txAtivCAT: PropTypes.string,
  txAtivIMM: PropTypes.string,
  txPerCAT: PropTypes.string,
  txPerIMM: PropTypes.string,
  txDeslocCAT: PropTypes.string,
  txDeslocIMM: PropTypes.string,
  txMOCAT: PropTypes.string,
  txMOIMM: PropTypes.string,
  txMCECAT: PropTypes.string,
  txMCEIMM: PropTypes.string
}

export default TableTaxas
