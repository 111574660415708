import { Controller, ErrorMessage, useFormContext } from "react-hook-form"
import React, { useState } from "react"
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import InputError from "../SharedComponents/Shared/Error/InputError"
/*
* <ReactQuill theme="snow" value={value} onChange={setValue} />
*   <div {...others} />
* </ReactQuill>
*/
const LFTextEditor = ({name, control, ...others}) => {
  const {errors} = useFormContext()
  const [value, setValue] = useState('')
  return (
    <React.Fragment>
      <Controller
        as={
          <ReactQuill theme="snow" value={value} onChange={setValue}/>
        }
        onChange={([content, delta]) => {
          return content
        }}
        name={name}
        id={name}
        control={control}
      />
      <ErrorMessage errors={errors} name={name} as={<InputError/>}/>
    </React.Fragment>
  )
}

export default LFTextEditor
