import React from "react"
import useCallbackStatus from "../../utils/use-callback-status"
import { FormContext, useForm } from "react-hook-form"
import Grid from "@material-ui/core/Grid"
import { LFEmailInput } from "../../components/LFInputs/LFEmailInput"
import { LFPasswordInput } from "../../components/LFInputs/LFPasswordInput"
import LFSubmitButton from "../../components/SharedComponents/Shared/LFButtons/LFSubmitButton"
import { AppMsgs } from "../../constants/appMsgs"
import Link from "@material-ui/core/Link"
import * as Yup from "yup"
import { emailReqValidation, requiredValidation } from "../../constants/appValidation"
import { Form } from "../../components/SharedStyles/SharedStyles"
import msgsLogin from "./msgsLogin"
import { baseURLPDF } from "../../api/api"

const validationSchema = Yup.object().shape({
  email: emailReqValidation(AppMsgs.DEFAULT_VALUES.INPUT.EMAIL_LBL),
  password: requiredValidation(AppMsgs.DEFAULT_VALUES.INPUT.PASS_LBL)
})

function LoginForm({onSubmit}) {
  const {isPending, run} = useCallbackStatus()

  const methods = useForm({
    validationSchema: validationSchema,
    // defaultValues: {email: '____@immersat.pt', password: '--'}
    // defaultValues: {email: 'endereco.mail@gmail.com', password: 'ipat2019'}
  })
  const {handleSubmit} = methods

  const authenticateUser = (values) => {
    run(onSubmit({...values}))
  }

  return (
    <FormContext {...methods}>
      <Form onSubmit={handleSubmit(authenticateUser)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LFEmailInput/>
          </Grid>
          <Grid item xs={12}>
            <LFPasswordInput/>
          </Grid>
        </Grid>
        <LFSubmitButton isLoading={isPending}>
          {AppMsgs.DEFAULT_VALUES.BUTTONS.SIGN_IN_BT}
        </LFSubmitButton>
        <Grid container>
          <Grid item xs>
            <Link href={`${baseURLPDF}/recover/account`} target='_blank' variant="body2" color="secondary">
              {msgsLogin.FORGOT_PASS}
            </Link>
          </Grid>
        </Grid>
      </Form>
    </FormContext>
  )
}

export default LoginForm
