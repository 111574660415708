import React from "react"
import msgsPlanosAtivados from "../msgsPlanosAtivados"
import { getRequest } from "../../../../api/api"
import { Link as RouterLink } from "react-router-dom"
import { planosUrl } from "../../ListPlanos/apiPlanos"
import Box from "@material-ui/core/Box"
import { Badge, StyledTableCell } from "../../../../components/SharedStyles/SharedStyles"
import Fab from "@material-ui/core/Fab"
import { equipUrl } from "../../../Equips/ListEquips/apiEquips"
import Typography from "@material-ui/core/Typography"
import msgsEquips from "../../../Equips/ListEquips/msgsEquips"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import msgsPlanos from "../../ListPlanos/msgsPlanos"


const LocalBadge = ({val}) => (val ? <Badge>{val}</Badge> : null)

const PADetailPanel = ({planoAtivado}) => {
  return (
    <React.Fragment>

      <TableContainer>
        <Table size="small" aria-label="TablePACounts">

          {/* ------------------------------------------------------------------- */}
          <>
            <TableHead>
              <TableRow>
                <TableCell colSpan="2">
                  <Box display="flex" justifyContent="flex-end" alignItems="center" flexDirection="row" p={1} m={1}>
                    <Box p={1}>
                      <Fab component={RouterLink} size="small"
                           to={getRequest({URLname: equipUrl, id: planoAtivado.equipcli_id}).gotoItemPath()} color="secondary"
                           variant="extended" style={{boxShadow: 'none'}}>
                        <Typography variant="body2" style={{paddingLeft: '5px'}}
                                    display={'inline'}>{msgsEquips.OPEN_BUTTON}</Typography>
                      </Fab>
                    </Box>
                    <Box p={1}>
                      <Fab component={RouterLink} size="small"
                           to={getRequest({URLname: planosUrl, id: planoAtivado.plano_id}).gotoItemPath()} color="secondary"
                           variant="extended" style={{boxShadow: 'none'}}>
                        <Typography variant="body2" style={{paddingLeft: '5px'}}
                                    display={'inline'}>{msgsPlanos.OPEN_BUTTON}</Typography>
                      </Fab>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell align="right" colSpan="2">{msgsPlanosAtivados.COUNTS.QTD}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{msgsPlanosAtivados.COUNTS.PERIODOS}</TableCell>
                <TableCell align="right"><LocalBadge val={planoAtivado.periodos_count}/></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{msgsPlanosAtivados.COUNTS.PATS}</TableCell>
                <TableCell align="right"><LocalBadge val={planoAtivado.pats_count}/></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{msgsPlanosAtivados.COUNTS.PATS_AGENDADO}</TableCell>
                <TableCell align="right"><LocalBadge val={planoAtivado.pats_agendado_count}/></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{msgsPlanosAtivados.COUNTS.PATS_EM_CURSO}</TableCell>
                <TableCell align="right"><LocalBadge val={planoAtivado.pats_em_curso_count}/></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{msgsPlanosAtivados.COUNTS.PATS_VISITA_CONCLUIDA_PAT_NAO_RESOLVIDO}</TableCell>
                <TableCell align="right"><LocalBadge val={planoAtivado.pats_visita_concluida_pat_nao_resolvido_count}/></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{msgsPlanosAtivados.COUNTS.PATS_ANULADO_IMM}</TableCell>
                <TableCell align="right"><LocalBadge val={planoAtivado.pats_anulado_imm_count}/></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{msgsPlanosAtivados.COUNTS.PATS_ANULADO_CLI}</TableCell>
                <TableCell align="right"><LocalBadge val={planoAtivado.pats_anulado_cli_count}/></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{msgsPlanosAtivados.COUNTS.PATS_RESOLVIDO}</TableCell>
                <TableCell align="right"><LocalBadge val={planoAtivado.pats_resolvido_count}/></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{msgsPlanosAtivados.COUNTS.PATS_RELATORIO_FINAL_REGISTADO}</TableCell>
                <TableCell align="right"><LocalBadge val={planoAtivado.pats_relatorio_final_registado_count}/></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{msgsPlanosAtivados.COUNTS.PATS_CONFERIDO}</TableCell>
                <TableCell align="right"><LocalBadge val={planoAtivado.pats_pat_conferido_count}/></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{msgsPlanosAtivados.COUNTS.PATS_ENCERRADO}</TableCell>
                <TableCell align="right"><LocalBadge val={planoAtivado.pats_pat_encerrado_count}/></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{msgsPlanosAtivados.COUNTS.PATS_FATURACAO_CONFERIDA}</TableCell>
                <TableCell align="right"><LocalBadge val={planoAtivado.pats_faturacao_conferida_count}/></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{msgsPlanosAtivados.COUNTS.PECAS_PAT}</TableCell>
                <TableCell align="right"><LocalBadge val={planoAtivado.pecas_pat_count}/></TableCell>
              </TableRow>
            </TableBody>
          </>
        </Table>
      </TableContainer>
    </React.Fragment>)
}

export { PADetailPanel }
