import React, { useState } from "react"
import { Redirect } from "react-router"
import { createTecnico, tecnicosUrl } from "./apiTecnicos"
import TecnicoForm from "./TecnicoForm"
import { getRequest } from "../../../api/api"

function TecnicoCreate() {
  const [tecnico, setTecnico] = useState(null)

  if (tecnico)
    return <Redirect to={getRequest({URLname: tecnicosUrl, id: tecnico.id}).gotoItemPath()}/>

  return (
    <React.Fragment>
      <TecnicoForm tecnico={tecnico} method={createTecnico} setTecnico={setTecnico}/>
    </React.Fragment>
  )
}

export default TecnicoCreate
