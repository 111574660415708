import React, { useContext } from "react"
import Title from "../../components/SharedComponents/Shared/Title"
import planosAplicaveisMsgs from "./msgsPlanosAplicaveis"
import { RedirectState } from "./PlanosAplicaveis"
import Grid from "@material-ui/core/Grid"
import AssocPlanoToEquipForm from "./partials/AssocPlanoToEquipForm"
import { assocPlanoToEquip } from "./apiPlanosAplicaveis"
import { GridPlanoAtivo } from "./partials/GridPlanoAtivo"


function PlanoEquipDetail({dataInic, numserie, equipcli_id, setEquip, ...props}) {

  return (
    <Grid container spacing={2} justify="center">

      <GridPlanoAtivo {...props} {...{dataInic, equipcli_id, simulacao: true}} />

      <Grid item sm={12}>
        <AssocPlanoToEquipForm
          method={assocPlanoToEquip}
          plano={props.plano.data}
          numserie={numserie}
          equipID={equipcli_id}
          dataInic={dataInic}
          setEquip={setEquip}
        />
      </Grid>
    </Grid>
  )
}

function PlanosAplicaveisAssoc({state, setEquip}) {

  const {list} = useContext(RedirectState)
  const handleBack = () => list()

  return (
    <React.Fragment>
      <Title handleBack={handleBack}>{planosAplicaveisMsgs.BACK_LABEL}</Title>
      <PlanoEquipDetail {...state.planoAplicavel} setEquip={setEquip}/>
    </React.Fragment>
  )
}

export default PlanosAplicaveisAssoc
