import React from "react"
import Grid from "@material-ui/core/Grid"
import LeftMarginSpan from "../../../SharedStyles/LeftMarginSpan"
import StyledLabel from "../../../SharedStyles/StyledLabel"
import tecnicoMsgs from "../../../../pages/Entidades/Tecnicos/msgs"
import ShowJson from "./ShowJson"

function ShowTecnico({tec}) {
  return (
    <Grid container spacing={2} justify="center">

      <Grid item xs={12} sm={6}>
        <div>
          <StyledLabel>{tecnicoMsgs.LABELS.NOME}</StyledLabel>
          <LeftMarginSpan>{tec.nome}</LeftMarginSpan>
        </div>
      </Grid>

      <Grid item xs={12} sm={6}>
        <ShowJson args={tec}/>
      </Grid>

    </Grid>
  )
}

export default ShowTecnico
