import React, { useContext } from "react"
import Title from "../../Shared/Title"
import { imagemMsgs } from "./imagemMsgs"
import { ImagemForm } from "./ImagemForm"
import { ListRepoState } from "../ListRepoGest"

function ImagemEdit() {

  const {showState, showList, FKfieldname} = useContext(ListRepoState)
  const handleBack = () => showList()

  const backMsg = (showState.createMode)
    ? imagemMsgs.BACK_LABEL_CREATE
    : imagemMsgs.BACK_LABEL_UPDATE

  return (
    <React.Fragment>
      <Title handleBack={handleBack}>{backMsg}</Title>
      <ImagemForm FKfieldname={FKfieldname}/>
    </React.Fragment>
  )
}

export { ImagemEdit }
