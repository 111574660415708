import withStyles from "@material-ui/core/styles/withStyles"
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import CancelIcon from "../../../Svg-Icons/CancelIcon"
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent"
import React from "react"
import Dialog from "@material-ui/core/Dialog"
import PropTypes from "prop-types"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)(props => {
  const {children, classes, onClose, ...other} = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{paddingRight: '40px'}}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CancelIcon/>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const CustomDialog = ({
                        title, children, open, setOpen,
                        onConfirm, onCancel,
                        msgsConfirm, msgsCancel, fullScreen, ...other
                      }) => {
  return (
    <React.Fragment>
      <Dialog fullScreen={fullScreen} maxWidth='lg' onClose={() => setOpen(false)} aria-labelledby="custom-dialog" open={open} {...other}>
        <DialogTitle id="custom-dialog" onClose={() => setOpen(false)}>{title}</DialogTitle>
        <DialogContent dividers>{children}</DialogContent>
        {onConfirm && <DialogActions>
          <Button
            onClick={() => {
              setOpen(false)
              onCancel()
            }}
            color="secondary"> {msgsCancel} </Button>
          <Button
            onClick={() => {
              setOpen(false)
              onConfirm()
            }}
            color="default"> {msgsConfirm} </Button>
        </DialogActions>}
      </Dialog>
    </React.Fragment>
  )
}

CustomDialog.defaultProps = {
  onConfirm: null,
  onCancel: () => {
  },
  msgsConfirm: 'OK',
  msgsCancel: 'Cancelar',
  fullScreen: false
}

CustomDialog.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  children: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  msgsConfirm: PropTypes.string,
  msgsCancel: PropTypes.string,
  fullScreen: PropTypes.bool
}

export default CustomDialog
