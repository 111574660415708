import { useAsync } from "react-async"
import { FormContext, useForm } from "react-hook-form"
import React from "react"
import * as Yup from "yup"
import { requiredValidation, selectValidation } from "../../../constants/appValidation"
import LFInput from "../../../components/LFInputs/LFInput"
import LFSubmitButton from "../../../components/SharedComponents/Shared/LFButtons/LFSubmitButton"
import Grid from "@material-ui/core/Grid"
import { Form } from "../../../components/SharedStyles/SharedStyles"
import { readTiposEquipSelect } from "../../Equips/TiposEquip/apiTiposEquip"
import LFAsyncSelectInput from "../../../components/LFInputs/LFAsyncSelectInput"
import marcaModelosMsgs from "./msgs"
import { readProcedimentosSelect } from "./apiMarcaModelos"


const validationSchema = Yup.object().shape({
  modelo: requiredValidation(marcaModelosMsgs.EDIT.LABEL.MODELO),
  tipoequip_id: selectValidation(marcaModelosMsgs.EDIT.LABEL.TIPO_EQUIP)
})

const defaultValues = modelo => {
  return {
    modelo: modelo ? modelo.modelo : '',
    tipoequip_id: modelo ? {index: modelo.tipoequip_id, title: modelo.tipo_equip_nome} : '',
    procedimento_id: (modelo && modelo.procedimento_id) ? {index: modelo.procedimento_id, title: modelo.procedimento_nome} : ''
  }
}

function ModeloForm({modelo, method, marcaID}) {

  const {isPending, run} = useAsync({
    deferFn: method,
    onResolve: data => modelo ? reset(defaultValues(data.data)) : reset({tipoequip_id: null, procedimento_id: null})
  })

  const methods = useForm({
    validationSchema: validationSchema,
    defaultValues: defaultValues(modelo)
  })

  const {handleSubmit, formState, control, reset} = methods

  const onSubmit = ({...values}) => {
    const convertValues = {
      ...values,
      ...{tipoequip_id: values.tipoequip_id.index, marca_id: marcaID},
      ...{procedimento_id: values.procedimento_id ? values.procedimento_id.index : null} // facultativo
    }
    run({...modelo, ...convertValues})
  }

  return (
    <div>
      <FormContext {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} justify="center">
            {/*-------------------------------------- MODELO ----------------------------------------*/}
            <Grid item xs={4}>
              <LFInput label={marcaModelosMsgs.EDIT.LABEL.MODELO} name='modelo'/>
            </Grid>
            {/*---------------------------------- TIPO EQUIPAMENTO ----------------------------------*/}
            <Grid item xs={4}>
              <LFAsyncSelectInput request={readTiposEquipSelect} name="tipoequip_id" control={control}
                                  label={marcaModelosMsgs.EDIT.LABEL.TIPO_EQUIP}/>
            </Grid>
            {/*---------------------------------- PROCEDIMENTO ----------------------------------*/}
            <Grid item xs={4}>
              <LFAsyncSelectInput request={readProcedimentosSelect} name="procedimento_id" control={control}
                                  label={marcaModelosMsgs.EDIT.LABEL.PROCEDIMENTO}/>
            </Grid>
          </Grid>
          <LFSubmitButton isLoading={isPending} dirty={!formState.dirty}>
            {modelo ? marcaModelosMsgs.BUTTON.EDIT_MODELO : marcaModelosMsgs.BUTTON.NEW_MODELO}
          </LFSubmitButton>
        </Form>
      </FormContext>
    </div>
  )
}

export default ModeloForm
