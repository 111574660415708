import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import MainContainer from "./MainContainer/MainContainer"
import StructureLayout from "./StructureLayout/StructureLayout"
import styled from "@material-ui/core/styles/styled"

const AppLayout = styled('div')({
  display: 'flex'
})

export default function MainLayout() {

  return (
    <AppLayout>
      <CssBaseline/>

      <StructureLayout/>

      <MainContainer/>

    </AppLayout>
  )
}
