import React from "react"
import Grid from "@material-ui/core/Grid"
import Async from "react-async"
import LinearProgress from "@material-ui/core/LinearProgress"
import Title from "../../../components/SharedComponents/Shared/Title"
import ErrorMsg from "../../../components/SharedComponents/Shared/Error/ErrorMsg"
import { useHistory, useParams } from "react-router"
import { getRequest } from "../../../api/api"
import { readVisitaPat } from "./apiVisitaPat"
import { RepoWithTabsRender } from "../../../components/SharedComponents/TabbedCRUDRepo/TabbedCRUDRepo"
import visitaPatMsgs from "./visitaPatMsgs"
import { TableVisitaPat } from "../../../components/SharedComponents/Shared/Tables/TableVisitaPat"
import { patsURLname } from "../apiPats"
import { PatVisitaPat } from "./PatVisitaPat/PatVisitaPat"
import EstadosVisitaPat from "./EstadosVisitaPat_InfoStates/EstadosVisitaPat/EstadosVisitaPat"
import StateFlowVisitaPatTabs from "./EstadosVisitaPat_InfoStates/InfoStates/StateFlowVisitaPatTabs"
import { PDFsVisitaPat } from "./PdfsVisitaPat/PDFsVisitaPat"
import { ImgsVisitaPat } from "./ImagensVisitaPat/ImgsVisitaPat"
import { ObsVisitaPat } from "./ObsVisitaPat/ObsVisitaPat"


function visitasPatParamsCom(asStr = false) {
  const params = [
    {
      rel: 'estados', title: visitaPatMsgs.TAB_ESTADOS, hasMany: true, counterField: 'estados_count', addCom: true,
      component: ({repo, reload}) => <EstadosVisitaPat visita={repo} reload={reload}/>
    },
    {
      rel: '', title: 'PAT', hasMany: false, counterField: '', addCom: false,
      component: ({repo}) => <PatVisitaPat visita={repo}/>
    },
    {
      rel: 'pdfs', title: visitaPatMsgs.TAB_PDFS, hasMany: true, counterField: 'pdfs_count', addCom: true, editable: true,
      component: ({repo, reload}) => <PDFsVisitaPat visita={repo} reload={reload}/>
    },
    {
      rel: 'images', title: visitaPatMsgs.TAB_IMGS, hasMany: true, counterField: 'images_count', addCom: true, editable: true,
      component: ({repo, reload}) => <ImgsVisitaPat visita={repo} reload={reload}/>
    },
    {
      rel: 'obs', title: visitaPatMsgs.TAB_OBS, hasMany: true, counterField: 'obs_count', addCom: true, editable: true,
      component: ({repo, reload}) => <ObsVisitaPat visita={repo} reload={reload}/>
    },
  ]
  if (asStr) {
    //PatVisitaPat
    // let rels = []
    // params.forEach(item => {
    //   if (item.addCom) {
    //     rels.push(item.rel)
    //   }
    // })
    return '' //rels.join(',')
  } else return params
}

function RenderPatView({visita, reload}) {
  const history = useHistory()
  const handleBack = () => history.push(getRequest({URLname: patsURLname, id: visita.pat_id}).gotoItemPath())

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Title handleBack={handleBack}>{visitaPatMsgs.BACK_LABEL}</Title>
      </Grid>
      <Grid item xs={12}>
        <TableVisitaPat data={visita} showLink={false}/>
      </Grid>
      {(!!visita.mayEdit) && <Grid item xs={12}>
        <StateFlowVisitaPatTabs visita={visita} reload={reload}/>
      </Grid>}
    </Grid>
  )
}

function VisitaPatView() {
  // const {user} = useUser()
  // TODO testar permissões de visualização segundo o user.isGest()
  const {id} = useParams()
  const numericID = parseInt(id, 10)

  return (
    <Async promiseFn={readVisitaPat} id={numericID}>
      <Async.Pending><LinearProgress color="secondary"/></Async.Pending>
      <Async.Fulfilled>
        {(visita, {reload}) => {

          return (
            <React.Fragment>
              <RepoWithTabsRender repo={visita}
                                  firstTabTitleFld={'dhAgendamentoComCliente'}
                                  FirstTabComponent={<RenderPatView visita={visita} reload={reload}/>}
                                  paramComFn={visitasPatParamsCom}
                                  reload={reload}/>
            </React.Fragment>
          )
        }}
      </Async.Fulfilled>
      <Async.Rejected>{error => <ErrorMsg>{error}</ErrorMsg>}</Async.Rejected>
    </Async>
  )
}

export { VisitaPatView }
