import React from "react"
import { useFormContext } from "react-hook-form"
import { TextField } from "@material-ui/core"
import PropTypes from "prop-types"

function LFInput(props) {
  const {label, name, type, labelProps, autoComplete, ...otherProps} = props
  const {register, errors} = useFormContext()
  return <TextField
    variant="outlined"
    fullWidth
    name={name}
    label={label}
    autoComplete={autoComplete}
    type={type}
    inputRef={register({})}
    error={!!errors[name]}
    InputProps={otherProps}
    InputLabelProps={labelProps}
    helperText={errors[name] ? errors[name].message : ''}
  />
}

LFInput.defaultProps = {
  label: "Text Field",
  type: "text",
  autoComplete: "off"
}

LFInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  autoComplete: PropTypes.string,
}

export default LFInput
