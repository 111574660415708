import React from "react"
import Async from "react-async"
import Typography from "@material-ui/core/Typography"
import ConfigForm from "./ConfigForm"
import LinearProgress from "@material-ui/core/LinearProgress"
import { alllogsURL, readConfig } from "./apiConfig"
import ErrorMsg from "../../components/SharedComponents/Shared/Error/ErrorMsg"
import Grid from "@material-ui/core/Grid"
import Fab from "@material-ui/core/Fab"
import { Link as RouterLink } from "react-router-dom"
import { getRequest } from "../../api/api"
import ListMenuIcon from "../../components/Svg-Icons/ListMenuIcon"
import { useUser } from "../../context/user-context"
import UserIcon from "../../components/Svg-Icons/UserIcon"

const Config = () => {
  const {u} = useUser()
  return (
    <Async promiseFn={readConfig}>
      <Async.Pending><LinearProgress color="secondary"/></Async.Pending>
      <Async.Fulfilled>
        {(data, {setData}) => (
          <Grid container spacing={2} justify="center">
            <Grid item xs={10}>
              <Typography variant="subtitle1" gutterBottom>
                {data.app_abrev} - {data.app_name} ({data.app_version_major}.{data.app_version_minor}.{data.app_version_revision})
              </Typography>
            </Grid>
            {u.is.root && <Grid item xs={2}>
              <Fab component={RouterLink} size="small"
                   to={getRequest({URLname: alllogsURL}).paginatePath()} color="primary"
                   variant="extended" style={{boxShadow: 'none'}}>
                <UserIcon/> <ListMenuIcon/>
              </Fab>
            </Grid>}
            <Grid item xs={12}>
              <ConfigForm defaultValues={data} setConfig={setData}/>
            </Grid>
          </Grid>
        )}
      </Async.Fulfilled>
      <Async.Rejected>{error => <ErrorMsg>{error}</ErrorMsg>}</Async.Rejected>
    </Async>
  )
}

export default Config
