import React from 'react'

// const tipo_cli_options = [
//   {index: "REV", title: "Revendedor"},
//   {index: "INST", title: "Instalador"},
//   {index: "TEC", title: "Técnico"},
// ]

function SaidasArmazem() {
  return (
    <p>Saídas Armazém</p>
  )
}

export default SaidasArmazem
