import React from "react"
import LFInput from "./LFInput"
import { AppMsgs } from "../../constants/appMsgs"
import PropTypes from "prop-types"

function LFPasswordInput(props) {
  return <LFInput
    type="password"
    {...props}
  />
}

LFPasswordInput.defaultProps = {
  label: AppMsgs.DEFAULT_VALUES.INPUT.PASS_LBL,
  name: "password",
}

LFPasswordInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string
}

export { LFPasswordInput }
