import { api, apiCUD, apiReadTable, getRequest } from "../../api/api"

const planoAtivoUrlname = 'planos-ativados'

async function readPlanoAtivo({id}) {
  const request = getRequest({URLname: planoAtivoUrlname, id}).GET()
  return api(request).then(r => r.data.data)
}

const desativaPlanoAtivo = (id) => {
  const request = getRequest({URLname: planoAtivoUrlname, id: id}).PUT(`desativa`)
  return apiCUD(request).then(r => r.data)
}

const historicoPlanoAtivados = ([query], equipID) => {
  const request = getRequest({
    URLname: planoAtivoUrlname,
    query: {...query, com: 'plano', relCom: 'equipscli', relComID: equipID}
  }).GET()
  return apiReadTable(request)
}


export { planoAtivoUrlname, readPlanoAtivo, desativaPlanoAtivo, historicoPlanoAtivados }
