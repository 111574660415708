import React, { useState } from "react"
import { useAsync } from "react-async"
import { deletePlanoAtivado, desativaPlanoAtivado } from "../apiPlanosAtivados"
import { FormContext, useForm } from "react-hook-form"
import { Form } from "../../../../components/SharedStyles/SharedStyles"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import LeftMarginSpan from "../../../../components/SharedStyles/LeftMarginSpan"
import msgsPlanosAtivados from "../msgsPlanosAtivados"
import { errorColor, primaryColorDark, whiteColor } from "../../../../constants/appTheme"
import CustomDialog from "../../../../components/SharedComponents/Shared/Dialog/CustomDialog"
import { repoMsgs } from "../../../../components/SharedComponents/ListRepo/repoMsgs"
import makeStyles from "@material-ui/core/styles/makeStyles"


const useStyles = makeStyles(theme => ({
  bt: {
    background: primaryColorDark,
    color: whiteColor,
    fontWeight: 'bold',
    padding: '1rem', fontSize: '0.875rem', lineHeight: '1.2rem'
  },
  btRem: {
    color: whiteColor,
    background: errorColor,
    fontWeight: 'bold',
    padding: '1rem', fontSize: '0.875rem', lineHeight: '1.2rem'
  },
}))


const DesativarRemoverPAForm = ({pa, handleBack}) => {
  const classes = useStyles()
  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false)
  // 1 - desativar
  // 2 - remover
  const [op, setOp] = useState(0)

  const {isPending, run} = useAsync({
    deferFn: (confirmedData) => (op === 1 ? desativaPlanoAtivado(confirmedData) : deletePlanoAtivado(confirmedData)),
    onResolve: (resp) => {
      handleBack()
    }
  })

  const methods = useForm({})

  const {handleSubmit} = methods

  const onSubmit = () => {
    setConfirmationDialogVisible(true)
  }

  const handleConfirmedDateTime = () => {
    setConfirmationDialogVisible(false)
    run({id: pa.id, operacao: op})
  }

  const handleCancel = () => {
    // feito no CustomDialog
    // setConfirmationDialogVisible(false)
  }

  return (
    ((!!pa.mayBeDeleted) || (!!pa.mayBeDeactivated)) &&
    <FormContext {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} justify="center">
          {/*---------------------------------------------------------------*/}
          {(!!pa.mayBeDeactivated) &&
          <Grid item xs={(!!pa.mayBeDeleted) ? 6 : 12}>
            <Button
              onClick={() => setOp(1)}
              className={classes.bt}
              startIcon={isPending && <CircularProgress color="secondary" size={20}/>}
              type="submit"
              fullWidth
              size="large"
              variant="contained"
              name={'desat'}
              disabled={isPending}>
              <React.Fragment>
                <LeftMarginSpan>{msgsPlanosAtivados.BT_DESATIVAR}</LeftMarginSpan>
              </React.Fragment>
            </Button>
          </Grid>}
          {/*---------------------------------------------------------------*/}
          {(!!pa.mayBeDeleted) &&
          <Grid item xs={(!!pa.mayBeDeactivated) ? 6 : 12}>
            <Button
              onClick={() => setOp(2)}
              className={classes.btRem}
              startIcon={isPending && <CircularProgress color="secondary" size={20}/>}
              type="submit"
              fullWidth
              size="large"
              variant="contained"
              name={'rem'}
              disabled={isPending}>
              <React.Fragment>
                <LeftMarginSpan>{msgsPlanosAtivados.BT_REMOVER}</LeftMarginSpan>
              </React.Fragment>
            </Button>
          </Grid>}
        </Grid>
      </Form>
      <CustomDialog open={confirmationDialogVisible}
                    setOpen={setConfirmationDialogVisible}
                    title={repoMsgs.CONFIRM_DIALOG_TITLE}
                    onConfirm={() => handleConfirmedDateTime()}
                    onCancel={() => handleCancel()}
                    msgsConfirm={repoMsgs.YES}
                    msgsCancel={repoMsgs.NO}>
        {msgsPlanosAtivados.CONFIRM_TITLE(op)}
      </CustomDialog>
    </FormContext>
  )
}

export { DesativarRemoverPAForm }
