import React from "react"
import { FormContext, useForm } from "react-hook-form"
import { Form } from "../../../components/SharedStyles/SharedStyles"
import Grid from "@material-ui/core/Grid"
import LFInput from "../../../components/LFInputs/LFInput"
import { AppMsgs } from "../../../constants/appMsgs"
import { LFNumberInput } from "../../../components/LFInputs/LFNumberInput"
import LFTextAreaInput from "../../../components/LFInputs/LFTextAreaInput"
import LFSubmitButton from "../../../components/SharedComponents/Shared/LFButtons/LFSubmitButton"
import { useAsync } from "react-async"
import * as Yup from "yup"
import {
  cp3Validation,
  cp4Validation,
  emailValidation,
  nifValidation,
  requiredValidation,
  selectValidation,
  tlfValidation,
  tlmValidation
} from "../../../constants/appValidation"
import LFZipCodeForm from "../../../components/LFComponentsForm/LFZipCodeForm"
import LFContactForm from "../../../components/LFComponentsForm/LFContactForm"
import LFSelect from "../../../components/LFInputs/LFSelect"
import LFAsyncSelectInput from "../../../components/LFInputs/LFAsyncSelectInput"
import { readUserSelect } from "../Users/apiUsers"
import msgsClientes from "./msgsClientes"

const validationSchema = Yup.object().shape({
  nome: requiredValidation(AppMsgs.ENTIDADES.EDIT.LABEL.NOME),
  nif: nifValidation(AppMsgs.ENTIDADES.EDIT.LABEL.NIF),
  cp4: cp4Validation(AppMsgs.ENTIDADES.EDIT.LABEL.CP4),
  cp3: cp3Validation(AppMsgs.ENTIDADES.EDIT.LABEL.CP3),
  email: emailValidation(AppMsgs.ENTIDADES.EDIT.LABEL.EMAIL),
  tlm: tlmValidation(AppMsgs.ENTIDADES.EDIT.LABEL.TLM),
  tlf: tlfValidation(AppMsgs.ENTIDADES.EDIT.LABEL.TLF),
  tipocli: selectValidation(msgsClientes.EDIT.LABEL.TIPO_CLI),
  zipCodeRequired: Yup.bool().when(['cp4', 'cp3'], {
    is: (cp4, cp3) => (!cp4 && cp3) || (cp4 && !cp3),
    then: Yup.bool().required(AppMsgs.ERROR.ZIPCODE_REQUIRED),
    otherwise: Yup.bool()
  })
})

const options = [
  {index: "0", title: "Doméstico"},
  {index: "1", title: "Institucional"},
]

const defaultValues = (cliente) => {
  return {
    nome: cliente ? cliente.nome : '',
    tipocli: cliente ? {index: cliente.tipocli, title: cliente.tipoText} : '',
    user_id: (cliente && cliente.user_id) ? {index: cliente.user_id, title: cliente.username} : "",
    nif: cliente ? cliente.nif : '',
    email: cliente ? cliente.email : '',
    tlm: cliente ? cliente.tlm : '',
    tlf: cliente ? cliente.tlf : '',
    obs: cliente ? cliente.obs : '',
    morada: cliente ? cliente.morada : '',
    cp4: cliente ? cliente.cp4 : '',
    cp3: cliente ? cliente.cp3 : ''
  }
}

function ClienteForm({cliente, method, setCliente}) {

  const {isPending, run} = useAsync({
    deferFn: method,
    onResolve: (newCliente) => {
      setCliente(newCliente)
      reset(defaultValues(newCliente)) // defaultValues pq é necessário converter o tipocli em tipoText antes de o passar para o form
    }
  })

  const methods = useForm({
    validationSchema: validationSchema,
    defaultValues: defaultValues(cliente)
  })

  const {handleSubmit, formState, control, reset} = methods

  const onSubmit = (values) => {
    const convertValues = {...values, tipocli: values.tipocli.index, user_id: values.user_id && values.user_id.index}
    run({...cliente, ...convertValues})
  }

  return (
    <FormContext {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} justify="center">
          {/*------------------------------------------- NOME -------------------------------------------------------*/}
          <Grid item xs={12} sm={8}>
            <LFInput label={AppMsgs.ENTIDADES.EDIT.LABEL.NOME} name='nome'/>
          </Grid>
          <Grid item xs={12} sm={4}>
            <LFAsyncSelectInput request={readUserSelect} control={control} name='user_id'
                                label={AppMsgs.ENTIDADES.EDIT.LABEL.USER}/>
          </Grid>
          {/*-------------------------------------- TIPO_CLI ----------------------------------------*/}
          <Grid item xs={12} sm={4}>
            <LFSelect name="tipocli" control={control} options={options} label={msgsClientes.EDIT.LABEL.TIPO_CLI}/>
          </Grid>
          {/*-------------------------------------------- NIF -------------------------------------------------------*/}
          <Grid item xs={12} sm={8}>
            <LFNumberInput label={AppMsgs.ENTIDADES.EDIT.LABEL.NIF} name='nif'/>
          </Grid>
          {/*------------------------------------------ EMAIL -------------------------------------------------------*/}
          <LFContactForm/>
          {/*------------------------------------- ZIPCODE_REQUIRED MSG ---------------------------------------------*/}
          <LFZipCodeForm/>
          {/*----------------------------------------- OBS -------------------------------------------------------*/}
          <Grid item xs={12} sm={12}>
            <LFTextAreaInput label={AppMsgs.ENTIDADES.EDIT.LABEL.OBS} name='obs'/>
          </Grid>
        </Grid>
        <LFSubmitButton isLoading={isPending} dirty={!formState.dirty}>
          {cliente ? msgsClientes.BUTTON.EDIT_CLIENTE : msgsClientes.BUTTON.NEW_CLIENTE}
        </LFSubmitButton>
      </Form>
    </FormContext>
  )
}

export default ClienteForm
