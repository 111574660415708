import Login from "./Login"
import { api, getRequest } from "../../api/api"
import msgsLogin from "./msgsLogin"
import { toast } from "react-toastify"

const loginUrl = 'login'
// ----------------------- LOGIN ROUTES -----------------------

const loginRouteProps = {
  path: getRequest({URLname: loginUrl}).paginatePath(),
  restrictIfAuth: true,
  exact: true,
  name: msgsLogin,
  component: Login
}

// ----------------------- LOGIN ROUTES -----------------------

function readLogged() {
  const request = getRequest({customUrl: 'user'}).GET()
  return api(request)
}

function loginRequest(newData) {
  const request = getRequest({customUrl: loginUrl}).POST()
  return api(request, {body: newData}).then(r => {
    toast.success(r.message)
    return r
  })
}


export { loginUrl, loginRouteProps, readLogged, loginRequest }
