import { makeStyles } from "@material-ui/core"
import { immBgUltraLight, primaryColorDark } from "../../../../constants/appTheme"

export const entradaArmazemStyles = makeStyles(theme => ({
  formRoot: {
    width: '100%',
  },
  gridRoot: {
    width: '100%',
    margin: theme.spacing(3, 0)
  },
  transpGrid: {
    width: '100%',
    margin: theme.spacing(2, 0)
  },
  submitBt: {
    width: '100%',
  },
  selectBox: {
    width: '100%',
  },
  tipoEquipSelectBox: {
    width: '100%',
    backgroundColor: immBgUltraLight,
  },
  readOnlySelectBox: {
    width: '100%',
    backgroundColor: '#fafafa',
  },
  inputBox: {
    width: '100%',
    color: primaryColorDark,
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  fabClass: {
    width: 25,
    height: 25,
    minHeight: 'unset',
    marginTop: -10,
    marginLeft: -21,
  },
  drawerWrapper: {
    display: 'flex',
    width: 320,
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3, 1, 1),
  },
  titDrawer: {
    color: primaryColorDark,
  },
  optionsGrp: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: "flex-start",
  },
  optionsItem: {
    width: '100%',
    padding: theme.spacing(.2, .2, .2, .5),
    borderBottom: 'solid 1px #ccc'
  },
  optionsItemLabel: {
    fontSize: '.8rem',
  },
  imergasLogo: {
    flexGrow: 1,
    margin: '2em auto',
    cursor: 'pointer', maxWidth: 200, width: '100%',
  },
}))
