import React from "react"
import Async from "react-async"
import LinearProgress from "@material-ui/core/LinearProgress"
import ErrorMsg from "../../../../components/SharedComponents/Shared/Error/ErrorMsg"
import { readMarcacoesTec } from "./apiMarcacoesTec"
import MarcacoesTecList from "./MarcacoesTecList"


function MarcacoesTec({id}) {
  return (
    <Async promiseFn={readMarcacoesTec} id={id}>
      <Async.Pending><LinearProgress color="secondary"/></Async.Pending>
      <Async.Fulfilled>
        {(data, {setData}) => {
          return (
            <React.Fragment>
              <MarcacoesTecList data={data}/>
            </React.Fragment>
          )
        }}
      </Async.Fulfilled>
      <Async.Rejected>{error => <ErrorMsg>{error}</ErrorMsg>}</Async.Rejected>
    </Async>
  )
}

export default MarcacoesTec
