const msgsInstaladores = {
  LABEL: "Instaladores",
  CREATE_LABEL: "Novo Instalador",
  UPDATE_LABEL: "Editar Instalador",
  BACK_LABEL: "Voltar aos Instaladores",
  OPEN_BUTTON: "Abrir Instalador",
  TABLE: {
    ADD_BUTTON: "Novo Instalador",
  },
  BUTTON: {
    EDIT_INSTALADOR: "Editar Instalador",
    NEW_INSTALADOR: "Adicionar Instalador",
  }
}

export default msgsInstaladores
