import React from "react"
import Async from "react-async"
import LinearProgress from "@material-ui/core/LinearProgress"
import ErrorMsg from "../../../components/SharedComponents/Shared/Error/ErrorMsg"
import { useHistory } from "react-router"
import Title from "../../../components/SharedComponents/Shared/Title"
import { pecasURLname, readPeca, updatePeca } from "./apiPecas"
import pecasMsgs from "./msgs"
import { getRequest } from "../../../api/api"
import PecaForm from "./PecaForm"


function PecaUpdate({id}) {
  const history = useHistory()
  const handleBack = () => history.push(getRequest({URLname: pecasURLname}).paginatePath())

  return (
    <React.Fragment>
      <Title handleBack={handleBack}>{pecasMsgs.BACK_LABEL}</Title>
      <Async promiseFn={readPeca} id={id}>
        <Async.Pending><LinearProgress color="secondary"/></Async.Pending>
        <Async.Fulfilled>
          {(data, {setData}) => {
            return (
              <React.Fragment>
                <PecaForm peca={data} method={updatePeca} setPeca={setData}/>
              </React.Fragment>
            )
          }}
        </Async.Fulfilled>
        <Async.Rejected>{error => <ErrorMsg>{error}</ErrorMsg>}</Async.Rejected>
      </Async>
    </React.Fragment>
  )
}

export default PecaUpdate
