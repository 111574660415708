const msgsClientes = {
  LABEL: "Clientes",
  UPDATE_LABEL: "Editar Cliente",
  BACK_LABEL: "Voltar aos Clientes",
  CREATE_LABEL: "Novo Cliente",
  DIALOG_BUTTON: "Ver Cliente",
  OPEN_BUTTON: "Abrir Cliente",
  TAB_LABEL: "Cliente",
  TABLE: {
    ADD_BUTTON: "Novo Cliente",
    HEADER: {
      TIPO_CLI: "Tipo",
      PLANOS_ACTIV_CURSO_COUNT: "Planos Ativos",
      PATS_CURSO_COUNT: "Pats Curso"
    }
  },
  EDIT: {
    LABEL: {
      TIPO_CLI: "Tipo de Cliente",
      ZIP_CODE: "Código Postal"
    }
  },
  BUTTON: {
    EDIT_CLIENTE: "Editar Cliente",
    NEW_CLIENTE: "Adicionar Cliente",
  }
}

export default msgsClientes
