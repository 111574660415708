import { useFormContext } from "react-hook-form"
import InputError from "../SharedComponents/Shared/Error/InputError"
import Grid from "@material-ui/core/Grid"
import { LFEmailInput } from "../LFInputs/LFEmailInput"
import { AppMsgs } from "../../constants/appMsgs"
import { LFNumberInput } from "../LFInputs/LFNumberInput"
import React from "react"
import PropTypes from "prop-types"

const LFContactForm = (props) => {
  const {emailName, emailLabel, tlmName, tlmLabel, tlfName, tlfLabel} = props
  const {errors} = useFormContext()
  return (
    <React.Fragment>
      {/*------------------------------------- CONTACT_REQUIRED MSG ---------------------------------------------*/}
      <InputError>{errors.contactRequired && errors.contactRequired.message}</InputError>
      {/*------------------------------------------ EMAIL -------------------------------------------------------*/}
      <Grid item xs={12} sm={4}>
        <LFEmailInput label={emailLabel} name={emailName}/>
      </Grid>
      {/*-------------------------------------------- TLM -------------------------------------------------------*/}
      <Grid item xs={12} sm={4}>
        <LFNumberInput label={tlmLabel} name={tlmName}/>
      </Grid>
      {/*-------------------------------------------- TLF -------------------------------------------------------*/}
      <Grid item xs={12} sm={4}>
        <LFNumberInput label={tlfLabel} name={tlfName}/>
      </Grid>
    </React.Fragment>
  )
}

LFContactForm.defaultProps = {
  emailName: "email",
  emailLabel: AppMsgs.ENTIDADES.EDIT.LABEL.EMAIL,
  tlmName: "tlm",
  tlmLabel: AppMsgs.ENTIDADES.EDIT.LABEL.TLM,
  tlfName: "tlf",
  tlfLabel: AppMsgs.ENTIDADES.EDIT.LABEL.TLF
}

LFContactForm.propTypes = {
  emailName: PropTypes.string,
  emailLabel: PropTypes.string,
  tlmName: PropTypes.string,
  tlmLabel: PropTypes.string,
  tlfName: PropTypes.string,
  tlfLabel: PropTypes.string
}

export default LFContactForm
