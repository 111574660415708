import React from "react"
import { Controller } from "react-hook-form"
import PropTypes from "prop-types"
import ImageUploader from "./ImageUploader"
import { NO_IMAGE } from "../../../constants/appConst"
import { AppMsgs } from "../../../constants/appMsgs"

function LFImageInput(props) {
  const {image, name, control, label, ...otherProps} = props
  return (
    <Controller
      as={<ImageUploader {...otherProps} image={image} name={name} label={label}/>}
      control={control}
      name={name}
    />
  )
}


LFImageInput.defaultProps = {
  image: NO_IMAGE,
  label: AppMsgs.DEFAULT_VALUES.INPUT.AVATAR
}

LFImageInput.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.string
}

export default LFImageInput
