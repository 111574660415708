import React from "react"
import { ShowDateTime } from "../Show/ShowDateTime"
import StyledBoldSpan from "../../../SharedStyles/StyledBoldSpan"
import { textSecondary, whiteColor } from "../../../../constants/appTheme"
import PropTypes from "prop-types"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { StyledTableCell } from "../../../SharedStyles/SharedStyles"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { TableTecnico } from "./TableTecnico"
import msgsTecnicos from "../../../../pages/Entidades/Tecnicos/msgsTecnicos"
import { EntityOpenDialogButton } from "../Dialog/EntityOpenDialogButton"
import { TableVisitaPat } from "./TableVisitaPat"
import visitaPatMsgs from "../../../../pages/Pats/VisitasPat/visitaPatMsgs"
import { Link as RouterLink } from "react-router-dom"
import { getRequest } from "../../../../api/api"
import Fab from "@material-ui/core/Fab"
import { usersUrl } from "../../../../pages/Entidades/Users/userConst"
import UserIcon from "../../../Svg-Icons/UserIcon"


const localStyles = makeStyles((theme) => ({
  tblRoot: {
    margin: '0',
  },
  tblStateCells: {
    padding: '3px',
    minWidth: '100px'
  },
  bt: {
    minHeight: '35px',
    lineHeight: '15px',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightLight,
  },
  propsStyles: props => ({
    ...props
  }),
}))

const MorphedButton = ({hasMorphed, morphedData, morphEntidade}) => {
  const myClasses = localStyles()

  if (hasMorphed) {
    if (morphEntidade === 'Tecnico') {
      return (<EntityOpenDialogButton component={<TableTecnico data={morphedData} showLink/>}
                                      btTitle={msgsTecnicos.DIALOG_BUTTON}
                                      dialogTitle={morphedData.nome}
                                      fullWidth={true}
                                      classNameProp={myClasses.bt}/>)
    }
    if (morphEntidade === 'VisitaPat') {
      return (<EntityOpenDialogButton component={<TableVisitaPat data={morphedData} showLink/>}
                                      btTitle={visitaPatMsgs.DIALOG_BUTTON}
                                      dialogTitle={morphedData.estadoVisitaDesc}
                                      fullWidth={true}
                                      classNameProp={myClasses.bt}/>)
    }
  }
  return null
}

function TableEstadoPat({estado, styleGrid, styleEstadoDesc, classNameGrid, classNameEstadoDesc}) {
  const myClasses = localStyles()

  const morphEntidade = estado.morphEntidade
  const hasMorphed = !!estado.morphed
  const morphedData = hasMorphed && estado.morphed.data
  const MorphedTableRow = () => {
    if (hasMorphed) {
      if (morphEntidade === 'VisitaPat') {
        return (
          <TableRow key="patCausaType">
            <TableCell className={myClasses.tblStateCells}>
              <ShowDateTime dateTime={estado.dhRegisto}/>
            </TableCell>
            <TableCell className={myClasses.tblStateCells}>
              <ShowDateTime dateTime={morphedData.dhAgendamentoComCliente}/>
            </TableCell>
            <TableCell className={myClasses.tblStateCells}>
              <MorphedButton {...{hasMorphed, morphedData, morphEntidade}} />
            </TableCell>
          </TableRow>
        )
      } else {
        return (
          <TableRow key="patCausaType">
            <TableCell className={myClasses.tblStateCells} colSpan={2}>
              <ShowDateTime dateTime={estado.dhRegisto}/>
            </TableCell>
            <TableCell className={myClasses.tblStateCells}>
              <MorphedButton {...{hasMorphed, morphedData, morphEntidade}} />
            </TableCell>
          </TableRow>
        )
      }
    } else {
      return (
        <TableRow key="patCausaType">
          <TableCell className={myClasses.tblStateCells} colSpan={3}>
            <ShowDateTime dateTime={estado.dhRegisto}/>
          </TableCell>
        </TableRow>
      )
    }
  }

  return (
    <TableContainer>
      {/* ----------------------------------------------------------------- */}
      <Table className={myClasses.tblRoot} size="small" aria-label="TableEstadoPat">
        <TableHead>
          <TableRow>
            <StyledTableCell colSpan="3">
              <StyledBoldSpan style={styleEstadoDesc}
                              className={classNameEstadoDesc}>
                <Fab component={RouterLink} target="_blank" position={'right'}
                     style={{width: '22px', height: '22px', minHeight: '22px', boxShadow: 'none', marginRight: '5px'}}
                     to={getRequest({URLname: usersUrl, id: estado.user_id}).gotoItemPath()} color="secondary">
                  <UserIcon color={whiteColor} style={{width: '16px', height: '16px',}}/>
                </Fab>
                {estado.estadoDesc}
              </StyledBoldSpan>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <MorphedTableRow/>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

TableEstadoPat.defaultProps = {
  styleGrid: {},
  styleEstadoDesc: {fontSize: '80%', color: textSecondary},
  classNameGrid: '',
  classNameEstadoDesc: '',
}

TableEstadoPat.propTypes = {
  estado: PropTypes.object.isRequired,
  styleGrid: PropTypes.object,
  styleEstadoDesc: PropTypes.object,
  classNameGrid: PropTypes.string,
  classNameEstadoDesc: PropTypes.string,
}

export default TableEstadoPat
