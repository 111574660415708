import PhotoIcon from "../../../components/Svg-Icons/PhotoIcon"
import PDFIcon from "../../../components/Svg-Icons/PDFIcon"
import React from "react"
import CommentIcon from "../../../components/Svg-Icons/CommentIcon"

const visitaPatMsgs = {
  DIALOG_BUTTON: "Ver Visita",
  UPDATE_LABEL: "Editar Visita",
  OPEN_BUTTON: "Abrir Visita",

  BACK_LABEL: "Voltar ao PAT",

  REGISTO: "Registo",
  AGENDAMENTO_CLI: "Agendamento (Cliente)",

  VISITA: 'Visita',
  IS_ANULADA: 'Anulada?',
  IS_ENCERRADA: 'Encerrada?',
  IS_PAT_RESOLVIDO: 'PAT resolvido?',

  TECNICO: 'Técnico',
  CHEGADA_TEC: "Chegada",
  SAIDA_TEC: "Saída",
  AVALIACAO_CLI: "Avaliação (Cliente)",

  ASSINATURA: 'Assinaturas',
  ASSINA_CLI: "Cliente",
  ASSINA_TEC: "Técnico",

  OBS_FINAIS: "Observações Finais",

  TAB_ESTADOS: 'Estados',
  TAB_IMGS: <PhotoIcon/>,
  TAB_PDFS: <PDFIcon/>,
  TAB_OBS: <CommentIcon/>,

  CAM_ERROR: "Não foi possível ligar à câmera possivelmente por ter sido negada a permissão de acesso.",
}

export default visitaPatMsgs
