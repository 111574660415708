const marcaModelosMsgs = {
  LABEL: "Modelos",
  EDIT_LABEL: "Editar Modelo",
  NEW_LABEL: "Novo Modelo",
  BACK_LABEL: "Voltar aos Modelos",
  TABLE: {
    HEADER: {
      MODELO: "Modelo",
      TIPOS_EQUIP: "Tipo Equipamento",
      EQUIPAMENTOS_CLI_COUNT: "Equipamentos",
      PROCEDIMENTO: "Procedimento",
    }
  },
  EDIT: {
    LABEL: {
      MODELO: "Nome do Modelo:",
      TIPO_EQUIP: "Tipo de Equipamento:",
      PROCEDIMENTO: "Procedimento:",
    }
  },
  BUTTON: {
    NEW_MODELO: "Adicionar Modelo",
    EDIT_MODELO: "Editar Modelo"
  }
}

export default marcaModelosMsgs
