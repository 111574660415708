import React from "react"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import { Badge, StyledTableCell } from "../../../../components/SharedStyles/SharedStyles"
import { ShowDate } from "../../../../components/SharedComponents/Shared/Show/ShowDateTime"
import patMsgs from "../../patMsgs"
import LeftMarginSpan from "../../../../components/SharedStyles/LeftMarginSpan"
import StyledLabel from "../../../../components/SharedStyles/StyledLabel"
import TableEstadoPat from "../../../../components/SharedComponents/Shared/Tables/TableEstadoPat"
import BoolColoredCircleIcon from "../../../../components/SharedComponents/Shared/BoolColoredCircleIcon"
import { tableStyles } from "../../../../constants/appTheme"

function TablePatMainProps({pat, lastEstado}) {
  const classes = tableStyles()
  return (
    <React.Fragment>
      <TableContainer>
        <Table className={classes.table} size="small" aria-label="TablePatProps">
          {/* ----------------------------------------------------------------- */}
          <>
            <TableHead>
              <TableRow>
                <StyledTableCell colSpan="3">{pat.refCode}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key="patCausaType">
                <TableCell colSpan="3">
                  <Badge>{pat.causaPAT}</Badge>
                  <LeftMarginSpan>{pat.tipoPat}</LeftMarginSpan>
                </TableCell>
              </TableRow>
              <TableRow key="paCliType">
                <TableCell colSpan="3">
                  <Badge props={pat.tipocli}>{pat.tipocliChar}</Badge>
                  <LeftMarginSpan>{pat.tipoText}</LeftMarginSpan>
                </TableCell>
              </TableRow>
              <TableRow key="patDataLimite">
                <TableCell colSpan="2">
                  <StyledLabel>{patMsgs.DATA_LIMITE}</StyledLabel>
                </TableCell>
                <TableCell colSpan="1" style={{textAlign: "right"}}>
                  <ShowDate date={pat.dataLimite} offLimit={pat.offLimit}/>
                </TableCell>
              </TableRow>
            </TableBody>
          </>
          {/* ----------------------------------------------------------------- */}
          {lastEstado && <>
            <TableHead>
              <TableRow>
                <StyledTableCell colSpan="3">{patMsgs.LASTESTADO}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key="patLastEstado">
                <TableCell colSpan="3">
                  <TableEstadoPat estado={lastEstado}/>
                </TableCell>
              </TableRow>
            </TableBody>
          </>}
        </Table>
      </TableContainer>

      <TableContainer>
        <Table className={classes.table} size="small" aria-label="TablePatProps">
          {/* ----------------------------------------------------------------- */}
          <>
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  {patMsgs.ISPAGO}
                  <LeftMarginSpan><BoolColoredCircleIcon flagTF={!!pat.pago} size={12}/></LeftMarginSpan>
                </StyledTableCell>
                <StyledTableCell>
                  {patMsgs.ISFATURADO}
                  <LeftMarginSpan><BoolColoredCircleIcon flagTF={!!pat.dhFaturada} size={12}/></LeftMarginSpan>
                </StyledTableCell>
              </TableRow>
            </TableHead>
          </>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}

export { TablePatMainProps }
