import React from 'react'
import { styled } from "@material-ui/core"
import Badge from "@material-ui/core/Badge/Badge"
import PropTypes from "prop-types"
import { NO_IMAGE } from "../../../../constants/appConst"

const Image = styled('img')({
  width: props => props.size + 'px',
  height: props => props.size + 'px',
  borderRadius: '50%',
})

const UserAvatar = ({alt, imagePath, size, roleCheckSigla}) => {
  return (
    <Badge badgeContent={roleCheckSigla} color="secondary" overlap="circle">
      <Image src={imagePath} alt={alt} size={size}/>
    </Badge>
  )
}

UserAvatar.defaultProps = {
  size: 40,
  alt: 'user-avatar',
  imagePath: NO_IMAGE,
  roleCheckSigla: 'U',
}

UserAvatar.propTypes = {
  size: PropTypes.number,
  alt: PropTypes.string,
  imagePath: PropTypes.string,
  roleCheckSigla: PropTypes.string
}


export { UserAvatar }
