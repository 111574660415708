import { apiCUD, apiReadTable, getRequest } from "../../api/api"
import avisosAlertasMsgs from "./avisosAlertasMsgs"
import NotificationsIcon from "../../components/Svg-Icons/NotificationsIcon"
import { AvisosAlertas } from "./AvisosAlertas"

// const alertasURL = 'alertas'
const avisosPeriodoURL = 'avisos-periodo'
const avisosAlertasURL = 'avisos-alertas'

// ----------------------- AVISOS e ALERTAS ROUTES -----------------------
const avisosAlertasRouterProps = {
  path: getRequest({URLname: avisosAlertasURL}).paginatePath(),
  exact: true,
  name: avisosAlertasMsgs.LABEL,
  component: AvisosAlertas,
  Icon: NotificationsIcon,
  breadcrumb: () => avisosAlertasMsgs.LABEL,
  reqRole: 'R'
}

// ----------------------- AVISOS e ALERTAS  ROUTES -----------------------

// /**
//  * Read Marcas com paginação para tabela
//  * @returns {Promise<*|void>}
//  */
// async function readUserAlertasTable(userID, [query]) {
//   const request = getRequest({URLprefix: '', URLname: alllogsURL, query}).GET(userID)
//   return apiReadTable(request)
// }

/**
 * Read Marcas com paginação para tabela
 * @returns {Promise<*|void>}
 */
async function readAvisosPeriodoTable([query]) {
  const request = getRequest({URLname: avisosPeriodoURL, query}).GET()
  return apiReadTable(request)
}

function updateAvisosPeriodoAsLido(data) {
  const request = getRequest({URLname: avisosPeriodoURL, id: data.id}).PUT('lido')
  return apiCUD(request, data).then(r => r.data)
}

async function updateAvisosPeriodoAsDesligado(id) {
  const request = getRequest({URLname: avisosPeriodoURL, id}).PUT('desligado')
  return apiCUD(request).then(r => r.data)
}

export {
  avisosAlertasRouterProps, avisosAlertasURL, avisosPeriodoURL,
  readAvisosPeriodoTable, updateAvisosPeriodoAsLido, updateAvisosPeriodoAsDesligado
}
