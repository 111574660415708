import React from "react"
import SvgIcon from "./SvgIcon"

const AccessIcon = (others) => {
  return (
    <SvgIcon viewBox="0 0 512 512" width="512" height="512" {...others}>
      <g>
        <circle cx="370" cy="346" r="20"/>
        <path d="M460,362c11.046,0,20-8.954,20-20v-74c0-44.112-35.888-80-80-80h-24.037v-70.534C375.963,52.695,322.131,0,255.963,0
				s-120,52.695-120,117.466V188H112c-44.112,0-80,35.888-80,80v164c0,44.112,35.888,80,80,80h288c44.112,0,80-35.888,80-80
				c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20c0,22.056-17.944,40-40,40H112c-22.056,0-40-17.944-40-40V268
				c0-22.056,17.944-40,40-40h288c22.056,0,40,17.944,40,40v74C440,353.046,448.954,362,460,362z M335.963,188h-160v-70.534
				c0-42.715,35.888-77.466,80-77.466s80,34.751,80,77.466V188z"/>
        <circle cx="219" cy="346" r="20"/>
        <circle cx="144" cy="346" r="20"/>
        <circle cx="294" cy="346" r="20"/>
      </g>
    </SvgIcon>
  )
}

export default AccessIcon
