import React, { useState } from "react"
import { PatReportIntroWithPecas } from "../ConcluirVisita/PatReportIntroWithPecas"
import makeStyles from "@material-ui/core/styles/makeStyles"
import patInfoStatesMsgs from "../patInfoStatesMsgs"
import { useAsync } from "react-async"
import { FormContext, useForm } from "react-hook-form"
import { Form } from "../../../../../components/SharedStyles/SharedStyles"
import Grid from "@material-ui/core/Grid"
import FormHelperText from "@material-ui/core/FormHelperText"
import LFTextEditor from "../../../../../components/LFInputs/LFTextEditor"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import LeftMarginSpan from "../../../../../components/SharedStyles/LeftMarginSpan"
import { fecharPat } from "../apiInfoStates"
import CustomDialog from "../../../../../components/SharedComponents/Shared/Dialog/CustomDialog"
import { repoMsgs } from "../../../../../components/SharedComponents/ListRepo/repoMsgs"

const useStyles = makeStyles(theme => ({
  root: {
    background: 'rgba(0,100,200,.09)',
  },
  formRoot: {
    background: '#FFF',
  },
  textBoxTitle: {
    background: '#333',
    color: '#FFF',
    fontSize: '14px',
    padding: '1em .5em .5em',
  },
  quillEditor: {
    minHeight: '8em',
  },
}))

const FecharPATConfirmationForm = ({pat, reload}) => {
  const classes = useStyles()
  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false)
  const [formData, setFormData] = useState({})

  const {isPending, run} = useAsync({
    deferFn: fecharPat,
    onResolve: (resp) => {
      reload()
    }
  })

  const methods = useForm({
    defaultValues: {
      obsRelatorioPATenviadasCLI: '',
    }
  })

  const {handleSubmit, control} = methods

  const onSubmit = ({...values}) => {
    const convertValues = {
      ...values, ...{
        pat_id: pat.id,
        obsRelatorioPATenviadasCLI: values.obsRelatorioPATenviadasCLI,
      }
    }
    setFormData({...convertValues})
    setConfirmationDialogVisible(true)
  }

  const handleConfirmedDateTime = () => {
    setConfirmationDialogVisible(false)
    run(formData)
  }

  const handleCancel = () => {
    // feito no CustomDialog
    // setConfirmationDialogVisible(false)
  }

  return (
    <FormContext {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} justify="center" className={classes.formRoot}>
          {/*---------------------------------------------------------------*/}
          <Grid item xs={12}>
            <FormHelperText className={classes.textBoxTitle}>{patInfoStatesMsgs.OBS_CLI}</FormHelperText>
            <LFTextEditor name='obsRelatorioPATenviadasCLI' control={control} className={classes.quillEditor}/>
          </Grid>
          {/*---------------------------------------------------------------*/}
          <Grid item xs={12}>
            <Button
              onClick={onSubmit}
              style={{padding: '1rem', fontSize: '0.875rem', lineHeight: '1.2rem'}}
              startIcon={isPending && <CircularProgress color="secondary" size={20}/>}
              type="submit"
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              disabled={isPending}>
              <React.Fragment>
                <LeftMarginSpan>{patInfoStatesMsgs.BT_FECHAR_PAT}</LeftMarginSpan>
              </React.Fragment>
            </Button>
          </Grid>
        </Grid>
      </Form>
      <CustomDialog open={confirmationDialogVisible}
                    setOpen={setConfirmationDialogVisible}
                    title={repoMsgs.CONFIRM_DIALOG_TITLE}
                    onConfirm={() => handleConfirmedDateTime()}
                    onCancel={() => handleCancel()}
                    msgsConfirm={repoMsgs.YES}
                    msgsCancel={repoMsgs.NO}>
        {patInfoStatesMsgs.CONFIRM_PAT_TITLE(pat.refCode)}
      </CustomDialog>
    </FormContext>
  )
}

const FecharPATForm = ({repo, reload}) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <PatReportIntroWithPecas pat={repo} isPecasDisable={true}/>

      <FecharPATConfirmationForm pat={repo} reload={reload}/>
    </div>
  )
}

export { FecharPATForm }
