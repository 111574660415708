const getAcoes = (acoes) => {
  let ac = []

  acoes && acoes.forEach((p) => {
    ac.push({
      acaoPA: {
        causa: p.causa,
        designacao: p.designacao,
        refAN: p.refAN,
        dataLimite: p.dataLimite,
        cat: p.cat,
        imm: p.imm,
        txDeslocCAT: p.txDeslocCAT,
        txDeslocIMM: p.txDeslocIMM,
        txMOCAT: p.txMOCAT,
        txMOIMM: p.txMOIMM,
        percDescPecas: p.percDescPecas,
        percDescDesloc: p.percDescDesloc,
        percDescMO: p.percDescMO,
        tipoPAT: p.tipoPAT,
      }
    })
  })
  return ac
}

const getAvisos = (avisos) => {
  let per = []

  avisos && avisos.forEach((p) => {
    per.push({
      text: p.text,
      dataLimite: p.dataLimite,
    })
  })
  return per
}

const getPeriodosPA = (periodos) => {
  let per = []

  periodos && periodos.forEach((p) => {
    per.push({
      avisos: getAvisos(p.avisos.data),
      acoes: getAcoes(p.acoes.data),
      periodoPA: {
        ord: p.ord,
        dataInic: p.dataInic,
        dataFim: p.dataFim,
      }
    })
  })
  return per
}

const mapPropsGridPlanoAtivado = (simulacao, props) => {
  const {dataInic, dataFim, equipcli_id} = props
  const plano = props.plano.data
  const {
    txAtivCAT, txAtivIMM,
    txPerCAT, txPerIMM,
    txDeslocCAT, txDeslocIMM,
    txMOCAT, txMOIMM,
    txMCECAT, txMCEIMM,
    percDescPecas, percDescDesloc, percDescMO,
  } = (simulacao ? plano : props)
  const {ativo, allAcConcluidas, desativado_at} = props
  const {durCliDomest, durCliInstit} = plano
  const periodosPA = props.periodosPA || getPeriodosPA(props.periodos && props.periodos.data)
  const data = {
    plano,
    dataInic: dataInic,
    dataFim: dataFim || false,
    equipcli_id: equipcli_id,
    taxas: {
      txAtivCAT, txAtivIMM,
      txPerCAT, txPerIMM,
      txDeslocCAT, txDeslocIMM,
      txMOCAT, txMOIMM,
      txMCECAT, txMCEIMM,
      percDescPecas, percDescDesloc, percDescMO,
    },
    duracoes: {durCliDomest, durCliInstit, mesesDur: (props.mesesDur || 0)}
  }
  const state = simulacao ? {} : {ativ: ativo, conc: allAcConcluidas, anul: !!desativado_at}

  return {...props, ...data, periodosPA, state: state}
}

export { mapPropsGridPlanoAtivado }
