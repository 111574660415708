import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import msgTiposPlano from "./msgTiposPlano"
import TableBody from "@material-ui/core/TableBody"
import BoolIconComponent from "../../../components/SharedComponents/Shared/BoolIconComponent"
import CancelIcon from "../../../components/Svg-Icons/CancelIcon"
import { errorColor } from "../../../constants/appTheme"
import React from "react"

const TableTipoPlanoState = ({rowData}) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center">{msgTiposPlano.TABLE.HEADER.PLANO_GARANTIA}</TableCell>
            <TableCell align="center">{msgTiposPlano.TABLE.HEADER.SEM_TERMO}</TableCell>
            <TableCell align="center">{msgTiposPlano.TABLE.HEADER.RENOVAVEL}</TableCell>
            <TableCell align="center">{msgTiposPlano.TABLE.HEADER.DUR_DEP_TC}</TableCell>
            <TableCell align="center">{msgTiposPlano.TABLE.HEADER.DUR_CLI_DOM}</TableCell>
            <TableCell align="center">{msgTiposPlano.TABLE.HEADER.DUR_CLI_INST}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={rowData.id}>
            <TableCell align="center">
              <BoolIconComponent bool={!!rowData.planoDeGarantia}/>
            </TableCell>
            <TableCell align="center">
              <BoolIconComponent bool={!!rowData.semTermo}/>
            </TableCell>
            <TableCell align="center">
              <BoolIconComponent bool={!!rowData.renovavel}/>
            </TableCell>
            <TableCell align="center">
              <BoolIconComponent bool={!!rowData.durDependeTipoCli}/>
            </TableCell>
            <TableCell align="center">{!!rowData.durCliDomest ?
              (rowData.durCliDomest + msgTiposPlano.TABLE.LABEL.MONTHS) :
              <CancelIcon size={15} color={errorColor}/>}
            </TableCell>
            <TableCell align="center">{!!rowData.durCliInstit ?
              (rowData.durCliInstit + msgTiposPlano.TABLE.LABEL.MONTHS) :
              <CancelIcon size={15} color={errorColor}/>}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
export default TableTipoPlanoState
