import Typography from "@material-ui/core/Typography"
import React from "react"
import { ShowDateTime } from "../../Shared/Show/ShowDateTime"

const pdfMsgs = {

  NEW_LABEL: "Associar PDF",
  BACK_LABEL_UPDATE: `Cancelar a edição`, //  usado mas nunca apresentado pois não existe a funcionalidade update em ListRepo
  BACK_LABEL_CREATE: `Cancelar o registo de um novo PDF`,

  CONFIRM_DIALOG_CONTENT: (pdf) => {
    return (
      <React.Fragment>
        <Typography>{pdf.filename}</Typography>
        <ShowDateTime dateTime={pdf.created_at}/>
        <hr/>
        <p>
        </p>
        <Typography> Confirma a remoção deste ficheiro? </Typography>
      </React.Fragment>
    )
  },

  EDIT: {
    LABEL: {
      FILENAME: "Ficheiro",
    }
  },
}

export { pdfMsgs }

