import React from "react"

function ShowJson() {

  let key = 1
  const listItems = []

  Array.from(arguments)
    .forEach((arg) => {
      if (Object.keys(arg).length)
        listItems.push(<pre key={key++}>{JSON.stringify(arg, null, 2)}</pre>)
    })

  return (
    <div>{listItems}</div>
  )
}

export default ShowJson

