import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete"
import { useAsync } from "react-async"
import CircularProgress from "@material-ui/core/CircularProgress"
import TextField from "@material-ui/core/TextField"
import PropTypes from "prop-types"

const LFAsyncSelectInputOptionsOut = ({
                                        request, name, control, label, disabled,
                                        options, setOptions,
                                        booted, setBooted,
                                        ...otherProps
                                      }) => {
  const {errors} = useFormContext()

  const {isPending: loading, run} = useAsync({
    deferFn: request,
    onResolve: (data) => setOptions(data)
  })

  const handleGetData = () => {
    if (!booted) {
      run()
      setBooted(true)
    }
  }

  return (
    <Controller
      as={
        <Autocomplete
          {...otherProps}
          onOpen={handleGetData}
          // onClose={() => setOptions([])}
          getOptionSelected={(option, value) => option.title === value.title}
          getOptionLabel={option => option.title ? option.title : option}
          options={options}
          loading={loading}
          disabled={disabled}
          noOptionsText={'...:: ND ::...'}
          renderInput={params => (
            <TextField
              {...params}
              label={label}
              fullWidth
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="secondary" size={20}/> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              error={!!errors[name]}
              helperText={errors[name] ? errors[name].message : ''}
            />
          )}
        />
      }
      onChange={([event, data]) => {
        return data
      }}
      name={name}
      control={control}
      defaultValue={{index: '', title: ''}}
    />
  )
}

LFAsyncSelectInputOptionsOut.defaultValues = {
  label: 'Async Select'
}

LFAsyncSelectInputOptionsOut.propTypes = {
  request: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.string
}

export default LFAsyncSelectInputOptionsOut
