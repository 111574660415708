import React from "react"
import { AppMsgs } from "../../../constants/appMsgs"
import TabsComponent from "../../../components/SharedComponents/Shared/TabsComponent"
import BaseTableListWithRoutes from "../../../components/SharedComponents/Shared/BaseTableListWithRoutes"
import Link from "@material-ui/core/Link"
import BoolIconComponent from "../../../components/SharedComponents/Shared/BoolIconComponent"
import { deleteUser, readUsersTable } from "./apiUsers"
import msgsUsers from "./msgsUsers"
import { usersUrl } from "./userConst"
import { BadgedAvatarOff, BadgedAvatarOn } from "../../../components/SharedComponents/BadgedAvatar/BadgedAvatar"


const columns = [{
  Header: () => null,
  id: 'users',
  columns: [
    {
      Header: msgsUsers.TABLE.HEADER.AVATAR,
      accessor: "avatar",
      Cell: ({row}) => (
        (!!row.original.logged)
          ? <BadgedAvatarOn src={row.original.avatar} alt={row.original.name}/>
          : <BadgedAvatarOff src={row.original.avatar} alt={row.original.name}/>
      ),
    },
    {
      Header: AppMsgs.ENTIDADES.TABLE.HEADER.NOME,
      accessor: "name"
    },
    {
      Header: AppMsgs.ENTIDADES.TABLE.HEADER.EMAIL,
      accessor: "email",
      Cell: ({row}) => (
        <React.Fragment>
          {row.original.email &&
          <Link href={"mailto:" + row.original.email} color="inherit" variant="body2">{row.original.email}</Link>}
        </React.Fragment>
      )
    },
    {
      Header: msgsUsers.TABLE.HEADER.IS_CONTA_ACESSO_ACTIVA,
      accessor: "isContaAcessoActiva",
      Cell: ({row}) => (
        <BoolIconComponent bool={!!row.original.isContaAcessoActiva}/>
      )
    },
    {
      Header: msgsUsers.TABLE.HEADER.LOGGED,
      accessor: "logged",
      Cell: ({row}) => (
        <BoolIconComponent bool={!!row.original.logged}/>
      )
    },
  ]
}]

/*
  ROLES:
  0,1, 7 - User
  2 - Admin
  3 - Gestor
  4 - Tecnico
  5 - Instalador
  6 - Cliente
 */
/**
 * Label = Title Tab
 * Index = Position
 * Component = Render Component
 * @type {*[]}
 */
const usersList = [
  {
    label: msgsUsers.TABS.LABEL.USER,
    index: 0,
    component: <BaseTableListWithRoutes
      route={usersUrl}
      columns={columns}
      deleteFn={deleteUser}
      readFn={readUsersTable}
      addButtonDesc={msgsUsers.TABLE.ADD_BUTTON}
    />,
  },
  {
    label: msgsUsers.TABS.LABEL.ADMIN,
    index: 1,
    component: <BaseTableListWithRoutes
      route={usersUrl}
      columns={columns}
      deleteFn={deleteUser}
      readFn={([props]) => readUsersTable([{role: 2, ...props}])}
      showAddButton={false}
    />,
  },
  {
    label: msgsUsers.TABS.LABEL.GESTOR,
    index: 2,
    component: <BaseTableListWithRoutes
      route={usersUrl}
      columns={columns}
      deleteFn={deleteUser}
      readFn={([props]) => readUsersTable([{role: 3, ...props}])}
      showAddButton={false}
    />,
  },
  {
    label: msgsUsers.TABS.LABEL.TEC,
    index: 3,
    component: <BaseTableListWithRoutes
      route={usersUrl}
      columns={columns}
      deleteFn={deleteUser}
      readFn={([props]) => readUsersTable([{role: 4, ...props}])}
      showAddButton={false}
    />,
  },
  {
    label: msgsUsers.TABS.LABEL.INST,
    index: 4,
    component: <BaseTableListWithRoutes
      route={usersUrl}
      columns={columns}
      deleteFn={deleteUser}
      readFn={([props]) => readUsersTable([{role: 5, ...props}])}
      showAddButton={false}
    />,
  },
  {
    label: msgsUsers.TABS.LABEL.CLI,
    index: 5,
    component: <BaseTableListWithRoutes
      route={usersUrl}
      columns={columns}
      deleteFn={deleteUser}
      readFn={([props]) => readUsersTable([{role: 6, ...props}])}
      showAddButton={false}
    />,
  },
]

export const Users = () => {
  return (
    <React.Fragment>
      <TabsComponent renderTabs={usersList}/>
    </React.Fragment>
  )
}

export default Users
