import React, { useState } from "react"
import { Redirect } from "react-router"
import InstaladorForm from "./InstaladorForm"
import { createInstalador, instaladoresUrl } from "./apiInstaladores"
import { getRequest } from "../../../api/api"

function InstaladorCreate() {
  const [instalador, setInstalador] = useState(null)


  if (instalador)
    return <Redirect to={getRequest({URLname: instaladoresUrl, id: instalador.id}).gotoItemPath()}/>

  return (
    <React.Fragment>
      <InstaladorForm instalador={instalador} method={createInstalador} setInstalador={setInstalador}/>
    </React.Fragment>
  )
}

export default InstaladorCreate
