import React from "react"
import Async from "react-async"
import LinearProgress from "@material-ui/core/LinearProgress"
import ErrorMsg from "../../../../components/SharedComponents/Shared/Error/ErrorMsg"
import { readLocaisTec } from "./apiLocaisTec"
import LocaisTecLU from "./LocaisTecLU"

function LocaisTec({id}) {
  return (
    <Async promiseFn={readLocaisTec} id={id}>
      <Async.Pending><LinearProgress color="secondary"/></Async.Pending>
      <Async.Fulfilled>
        {(locais, {setData}) => {
          return (
            <React.Fragment>
              <LocaisTecLU locais={locais} setData={setData} idTec={id}/>
            </React.Fragment>
          )
        }}
      </Async.Fulfilled>
      <Async.Rejected>{error => <ErrorMsg>{error}</ErrorMsg>}</Async.Rejected>
    </Async>
  )
}


export default LocaisTec
