import React from "react"
import Grid from "@material-ui/core/Grid"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { immBgUltraLight } from "../../../constants/appTheme"
import { StateFlowPatTabs } from "../EstadosPat_InfoStates/InfoStates/StateFlowPatTabs"
import RenderPATTables from "./Partials/RenderPATTables"
import { EntityOpenDialogButton } from "../../../components/SharedComponents/Shared/Dialog/EntityOpenDialogButton"
import { TableCliente } from "../../../components/SharedComponents/Shared/Tables/TableCliente"
import msgsClientes from "../../Entidades/Clientes/msgsClientes"
import { TableTecnico } from "../../../components/SharedComponents/Shared/Tables/TableTecnico"
import msgsTecnicos from "../../Entidades/Tecnicos/msgsTecnicos"
import { TableEquipamento } from "../../../components/SharedComponents/Shared/Tables/TableEquipamento"
import msgsEquips from "../../Equips/ListEquips/msgsEquips"

const useStyles = makeStyles((theme) => ({
  btRow: {
    padding: '1em 0 .5em',
  },
  anulado: {
    background: immBgUltraLight,
  },
  bail: {},
  bt: {
    minHeight: '60px',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightLight,
  },
}))

function ShowPAT({pat, reload, lastEstado, handleBack}) {
  const classes = useStyles()
  const cliente = (pat.cliente && pat.cliente.data) || false
  const tecnico = (pat.tecnico && pat.tecnico.data) || false
  const equipCli = (pat.equipCli && pat.equipCli.data) || false
  // const patIsAnulado = (!!pat.anulado_at)

  return (
    <>
      <RenderPATTables {...{pat, lastEstado}} />
      {/*{*/}
      {/*  patIsAnulado ? null :*/}
      {/*<Grid container spacing={2} justify="space-between"*/}
      {/*      className={patIsAnulado ? classes.anulado : classes.bail}>*/}

      {/*------------ BTs cliente, Técnico e Equipamento ------------*/}
      <Grid container spacing={2} justify="space-between" className={classes.btRow}>

        {(!!cliente)
          ? <Grid item xs>
            <EntityOpenDialogButton component={<TableCliente data={cliente} showLink/>}
                                    btTitle={msgsClientes.DIALOG_BUTTON}
                                    dialogTitle={cliente.nome}
                                    disabled={!cliente} fullWidth={true}
                                          classNameProp={classes.bt}/>

                </Grid>
                : null}
              {(!!tecnico)
                ? <Grid item xs>
                  <EntityOpenDialogButton component={<TableTecnico data={tecnico} showLink/>}
                                          btTitle={msgsTecnicos.DIALOG_BUTTON}
                                          dialogTitle={tecnico.nome}
                                          disabled={!tecnico} fullWidth={true}
                                          classNameProp={classes.bt}/>
                </Grid>
                : null}
        {(!!equipCli)
          ? <Grid item xs>
            <EntityOpenDialogButton component={<TableEquipamento data={equipCli} showLink/>}
                                    btTitle={msgsEquips.DIALOG_BUTTON}
                                    dialogTitle={equipCli.numserie || '#Ref. - ND#'}
                                    disabled={!equipCli} fullWidth={true}
                                    classNameProp={classes.bt}/>
          </Grid>
          : null}
        {/*</Grid>*/}

        {/*------------ TABs de alteração de estado ------------*/}
        <Grid item xs={12}>
          <StateFlowPatTabs pat={pat} reload={reload} handleBack={handleBack}/>
        </Grid>

        {/*<Grid item xs={12}>*/}
        {/*  <ShowIcons/>*/}
        {/*</Grid>*/}

      </Grid>
      {/*}*/}
    </>
  )
}

export default ShowPAT
