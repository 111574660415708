import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"
import React from "react"
import PropTypes from "prop-types"

const LinkWithIcon = ({icon, href, desc}) => {
  return (
    <Typography component="div">
      {icon}<Link color="inherit" href={href} variant="body2">{desc}</Link>
    </Typography>
  )
}

LinkWithIcon.propTypes = {
  icon: PropTypes.element.isRequired,
  href: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired
}

export default LinkWithIcon
