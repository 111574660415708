import React from "react"
import { useHistory, useParams } from "react-router"
import InstaladorUpdate from "./InstaladorUpdate"
import InstaladorCreate from "./InstaladorCreate"
import Title from "../../../components/SharedComponents/Shared/Title"
import { getRequest } from "../../../api/api"
import { instaladoresUrl } from "./apiInstaladores"
import msgsInstaladores from "./msgsInstaladores"


function InstaladorView() {
  const history = useHistory()
  let {id} = useParams()
  const handleBack = () => history.push(getRequest({URLname: instaladoresUrl}).paginatePath())

  return (
    <React.Fragment>
      <Title handleBack={handleBack}>{msgsInstaladores.BACK_LABEL}</Title>
      {(!!parseInt(id, 10)) ?
        <InstaladorUpdate id={id}/> :
        <InstaladorCreate/>
      }
    </React.Fragment>
  )
}

export default InstaladorView
