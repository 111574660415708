import React, { useContext } from "react"
import Title from "../../../components/SharedComponents/Shared/Title"
import ModeloForm from "./ModeloForm"
import { updateMarcaModelo } from "./apiMarcaModelos"
import { RedirectState } from "./Modelos"
import marcaModelosMsgs from "./msgs"

function ModeloUpdate({marcaID, state}) {

  const {list} = useContext(RedirectState)

  const handleBack = () => list()

  return (
    <React.Fragment>
      <Title handleBack={handleBack}>{marcaModelosMsgs.BACK_LABEL}</Title>
      <ModeloForm modelo={state.data} method={updateMarcaModelo} marcaID={marcaID}/>
    </React.Fragment>
  )
}

export default ModeloUpdate
