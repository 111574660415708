import axios from "axios"
import { getToken } from "./auth-user"
import { toast } from "react-toastify"
import { TransformPag } from "../constants/appTable"

const isLocalMode = 0

const baseURLPDF = (isLocalMode ? `http://isatv5.loc/v1` : `http://satv5.api.immersat.pt/v1`)
const defaultHeaders = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  // 'x-authorization': '.::immersatV3::.',
  'Accept-Language': 'pt',
}

/**
 * Cria instancia axios com os header e baseURL
 * @type {AxiosInstance}
 */
const apiSAT = axios.create({
  baseURL: (isLocalMode ? 'http://isatv5.loc/api/v1' : 'http://satv5.api.immersat.pt/api/v1'),
  headers: defaultHeaders
})

/**
 * Pedida api usando o axios
 * @param appRequest
 * @param body
 * @param customConfig
 * @returns {Promise<AxiosResponse<any>>}
 */
async function api(appRequest, {body, ...customConfig} = {}) {
  const token = getToken()

  if (token)
    apiSAT.defaults.headers.common['Authorization'] = `Bearer ${token}`

  if (body)
    appRequest.data = JSON.stringify(body)

  return apiSAT(appRequest)
    .then(res => res.data)
    .catch(err => {
      const error = handlerErrorResponse(err)
      toast.error(error)
      throw error
    })
}

/**
 * Pedidos CREAT, UPDATE, DELETE
 * @param request
 * @param data
 * @returns {Promise<*>}
 */
async function apiCUD(request, data) {
  return api(request, data && {body: data})
    .then(r => {
      toast.success(r.message)
      return r.data
    })
}

/**
 * Pedido READ para tabelas
 * @param request
 * @returns {Promise<* | void>}
 */
async function apiReadTable(request) {
  return api(request)
    .then(r => r.data)
}

/**
 * Trata erros recebidos da API
 * @param error
 * @returns {string|*}
 */
const handlerErrorResponse = (error) => {
  if (error.response) {

    if (error.response.data.errors) {
      let srtParams = error.response.data.message
      if (error.response.data.errors && error.response.data.errors.length !== 0) {
        Object.values(error.response.data.errors).map((str, key) => {
          return srtParams += '\n' + str
        })
        return srtParams
      }
      return error.response.data.message
    }
    return error.response.data.message
  } else if (error.request) {
    return 'The request was made but no response was received'
  } else {
    return error
  }
}

const RequestTypes = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
}

const getRequest = (params) => {
  const {URLname, URLprefix, id, paginateOFF, query, customUrl, headers} = params
  const APIbase = URLprefix === undefined ? 'gpm' : URLprefix
  const paginate = !paginateOFF && !!query
  const itemID = parseInt(id, 10) || 0
  const urlRequest = customUrl ? customUrl : `${APIbase}/${URLname}`
  const requests = {
    GET: {url: urlRequest, method: RequestTypes.GET},
    POST: {url: urlRequest, method: RequestTypes.POST},
    PUT: {url: urlRequest, method: RequestTypes.PUT},
    DELETE: {url: urlRequest, method: RequestTypes.DELETE},
    PAGINATE: `/${URLname}`,
    VIEW: `/${URLname}/:id`
  }
  const REQUEST = (getRequest) => {
    return {
      headers: headers,
      ...requests.GET,
      url: requests.GET.url + (getRequest ? `/${getRequest}` : '')
    }
  }
  const ALL = (urlTail = '') => {
    return {
      headers: headers,
      ...requests.GET,
      url: requests.GET.url + (urlTail ? `/${urlTail}` : '') + (paginate ? TransformPag(query) : '')
    }
  }
  const GET = (urlTail = '') => {
    return (itemID
      ? {
        headers: headers, ...requests.GET,
        url: requests.GET.url + `/${itemID}` + (urlTail ? `/${urlTail}` : '') + (paginate ? TransformPag(query) : '')
      }
      : ALL(urlTail))
  }
  const POST = (urlTail = '') => {
    return (urlTail ? {
      headers: headers,
      ...requests.POST,
      url: requests.POST.url + `/${urlTail}`
    } : requests.POST)
  }
  const PUT = (urlTail = '', withoutID = false) => {
    return {
      headers: headers,
      ...requests.PUT,
      url: requests.PUT.url + (withoutID ? '' : `/${itemID}`) + (urlTail ? `/${urlTail}` : '') + (paginate ? TransformPag(query) : '')
    }
  }
  const DELETE = (urlTail = '') => {
    return {
      headers: headers,
      ...requests.DELETE,
      url: requests.DELETE.url + `/${itemID}` + (urlTail ? `/${urlTail}` : '')
    }
  }
  const gotoItemPath = () => {
    const regex = /:id/gi
    const str = requests.VIEW
    return str.replace(regex, id)
  }
  const viewPath = (urlTail = '') => {
    return requests.VIEW + (urlTail ? `/${urlTail}` : '')
  }
  const paginatePath = (urlTail = '') => {
    return requests.PAGINATE + (urlTail ? `/${urlTail}` : '')
  }

  return {REQUEST, ALL, GET, POST, PUT, DELETE, gotoItemPath, viewPath, paginatePath}
}

export { api, getRequest, apiCUD, apiReadTable, handlerErrorResponse, baseURLPDF }
