import React from "react"
import { useAsync } from "react-async"
import { FormContext, useForm } from "react-hook-form"
import { Form } from "../../../components/SharedStyles/SharedStyles"
import Grid from "@material-ui/core/Grid"
import LFInput from "../../../components/LFInputs/LFInput"
import LFSubmitButton from "../../../components/SharedComponents/Shared/LFButtons/LFSubmitButton"
import * as Yup from "yup"
import { requiredValidation, selectValidation } from "../../../constants/appValidation"
import LFSelect from "../../../components/LFInputs/LFSelect"
import tiposequipMsgs from "./msgs"
import LFAsyncSelectInput from "../../../components/LFInputs/LFAsyncSelectInput"
import { readProcedimentosSelect } from "../../Marcas/Modelos/apiMarcaModelos"
import marcaModelosMsgs from "../../Marcas/Modelos/msgs"


const validationSchema = Yup.object().shape({
  refAN: selectValidation(tiposequipMsgs.EDIT.LABEL.REF_AN),
  tipo: requiredValidation(tiposequipMsgs.EDIT.LABEL.TIPO),
})

const options = [
  {index: "5A", title: "5A"},
  {index: "5B", title: "5B"},
  {index: "5C", title: "5C"},
  {index: "5D", title: "5D"},
  {index: "5E", title: "5E"},
  {index: "5F", title: "5F"},
  {index: "5G", title: "5G"},
  {index: "5H", title: "5H"},
  {index: "5I", title: "5I"},
  {index: "5J", title: "5J"},
  {index: "5K", title: "5K"},
  {index: "5L", title: "5L"},
]

function TiposEquipForm({data, method}) {

  const {isPending, run} = useAsync(
    {
      deferFn: method,
      onResolve: newData => data ? reset(defaultValues(newData)) : reset({refAN: null, procedimento_id: null})
    })

  const defaultValues = (data) => {
    return {
      refAN: data ? {index: data.refAN, title: data.refAN} : '',
      tipo: data ? data.tipo : '',
      procedimento_id: (data && data.procedimento_id) ? {index: data.procedimento_id, title: data.procedimento_nome} : ''
    }
  }

  const methods = useForm({
    validationSchema: validationSchema,
    defaultValues: defaultValues(data)
  })

  const {handleSubmit, formState, control, reset} = methods

  const onSubmit = ({...values}) => {
    const convertValues = {
      ...values,
      refAN: values.refAN.index,
      ...{procedimento_id: values.procedimento_id ? values.procedimento_id.index : null} // facultativo
    }
    run({...data, ...convertValues})
  }

  return (
    <div>
      <FormContext {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} justify="center">
            {/*-------------------------------------- REF_AN ----------------------------------------*/}
            <Grid item xs={2}>
              <LFSelect name="refAN" control={control} options={options} label={tiposequipMsgs.EDIT.LABEL.REF_AN}/>
            </Grid>
            {/*---------------------------------- NOME TIPO----------------------------------*/}
            <Grid item xs={6}>
              <LFInput label={tiposequipMsgs.EDIT.LABEL.TIPO} name='tipo'/>
            </Grid>
            {/*---------------------------------- PROCEDIMENTO ----------------------------------*/}
            <Grid item xs={4}>
              <LFAsyncSelectInput request={readProcedimentosSelect} name="procedimento_id" control={control}
                                  label={marcaModelosMsgs.EDIT.LABEL.PROCEDIMENTO}/>
            </Grid>
          </Grid>
          <LFSubmitButton isLoading={isPending} dirty={!formState.dirty}>
            {data ? tiposequipMsgs.BUTTON.EDIT_TIPOEQUIP : tiposequipMsgs.BUTTON.EDIT_TIPOEQUIP}
          </LFSubmitButton>
        </Form>
      </FormContext>
    </div>
  )
}


export default TiposEquipForm
