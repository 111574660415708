const msgsUsers = {
  LABEL: "Utilizadores",
  UPDATE_LABEL: "Editar Utilizador",
  CREATE_LABEL: "Adicionar Utilizador",
  BACK_LABEL: "Voltar aos Utilizadores",

  USER_PERMS_LABEL: "Permissões do Utilizador",
  CHECK_PERMS: "Indique as permissões a ativar",

  MENU: {
    PROFILE: "Perfil",
    LOGOUT: "Sair...",
  },
  EDIT: {
    LABEL: {
      MUST_RECEIVE_EMAIL: "Deve receber email a cada login",
      IS_CONTA_ACESSO_ACTIVA: "Acesso à conta",
      NOVA_PASSWORD: "Nova Password",
      SHOW_PASSWORD_FIELD: "Alterar Password",
    }
  },
  TABLE: {
    ADD_BUTTON: "Novo Utilizador",
    HEADER: {
      ROLE: "Role",
      IS_CONTA_ACESSO_ACTIVA: "Acesso Conta",
      LOGGED: "Em Sessão",
      AVATAR: "Avatar",
      PERM_NAME: "Permissão",
    }
  },
  TABS: {
    LABEL: {
      USER: "Utilizadores",
      GESTOR: "Gestores",
      ADMIN: "Administradores",
      TEC: "Técnicos",
      INST: "Instaladores",
      CLI: "Clientes",
      LOGS: "Histórico de ações",
    }
  },
  BUTTON: {
    EDIT_USER: "Editar Utilizador",
    NEW_USER: "Adicionar Utilizador"
  },
  PERM_TABS: {
    armazem: 'Armazém',
    av_periodo: 'Avisos SAT',
    clientes: 'Clientes',
    equipcli: 'Equipamentos',
    gconf: 'Conf. Gerais',
    instaladores: 'Instaladores',
    locs_tec: 'Locais (CAT)',
    marcas: 'Marcas',
    obs_visita_pat: 'Obs. Visitas',
    pats: 'PAT',
    pecas: 'Pecas',
    planos_activ: 'Planos Ativados',
    planos: 'Planos',
    procedimentos: 'Procedimentos',
    tecnicos: 'Técnicos',
    tipos_equip: 'Tipos de Equip.',
    tipos_plano: 'Tipos de Plano',
    users: 'Utilizadores',
    visitas_pat: 'Visitas',
  }
}

export default msgsUsers
