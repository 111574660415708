import React from "react"

const MakeComponent = ({componentsObj, componentKeyName, ...props}) => {
  // existe
  let key = 0
  if (componentsObj && componentKeyName && typeof componentsObj[componentKeyName] !== "undefined") {
    return React.createElement(componentsObj[componentKeyName], {
      key: componentKeyName + key++,
      ...props
    })
  }
  // ooops
  return React.createElement(
    () => <div style={{padding: '1em', margin: '.5em', color: '#C00', background: '#eee', fontSize: '80%'}}>
      O componente <strong
      style={{padding: '5px', color: '#EEE', background: '#C00', display: 'inline-block', margin: '0 5px'}}>{componentKeyName}</strong>
      não está declarado em <i>componentsArray</i>.</div>,
    {key: Math.random() * 100}
  )
}

// const compKeyNamePreConditions = (props, propName, componentName, location) => {
//   componentName = componentName || 'ANONYMOUS'
//   let tail = ''
//   if (props[propName]) {
//     const val = props[propName]
//     if (typeof val === 'string') {
//       tail = ': -- string vazia! -- '
//       return val.length
//     }
//     tail = ': -- deve ser String --'
//   }
//   return new Error(`Invalid prop ${propName} supplied to ${componentName}${tail}`)
// }
//
// const createChainableTypeChecker = (validate) => {
//   function checkType(isRequired, props, propName, componentName, location) {
//     componentName = componentName || 'ANONYMOUS'
//     if (props[propName] == null) {
//       if (isRequired) {
//         return new Error(
//           ("Required `" + propName + "` was not specified in ") +
//           ("`" + componentName + "`.")
//         )
//       }
//       return null
//     } else {
//       return validate(props, propName, componentName, location)
//     }
//   }
//   let chainedCheckType = checkType.bind(null, false);
//   chainedCheckType.isRequired = checkType.bind(null, true);
//
//   return chainedCheckType;
// }
//
// let checkCompKeyName = createChainableTypeChecker(compKeyNamePreConditions)
//
// MakeComponent.propTypes = {
//   componentKeyName: checkCompKeyName.isRequired,
//   componentsArray: PropTypes.arrayOf(PropTypes.shape({
//     keyName: PropTypes.string,
//     component: PropTypes.element
//   })).isRequired,
// }

export { MakeComponent }
