import React, { useState } from "react"
import { useAsync } from "react-async"
import { reenviaPDFaoCli, reenviaPDFaoTec } from "../apiInfoStates"
import { FormContext, useForm } from "react-hook-form"
import { Form } from "../../../../../components/SharedStyles/SharedStyles"
import Grid from "@material-ui/core/Grid"
import patInfoStatesMsgs from "../patInfoStatesMsgs"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import CustomDialog from "../../../../../components/SharedComponents/Shared/Dialog/CustomDialog"
import { repoMsgs } from "../../../../../components/SharedComponents/ListRepo/repoMsgs"
import PDFIcon from "../../../../../components/Svg-Icons/PDFIcon"
import TrendingFlatIcon from "../../../../../components/Svg-Icons/TrendingFlatIcon"
import ClienteIcon from "../../../../../components/Svg-Icons/ClienteIcon"
import UserIcon from "../../../../../components/Svg-Icons/UserIcon"
import BuildIcon from "../../../../../components/Svg-Icons/BuildIcon"
import { baseURLPDF } from "../../../../../api/api"
import PatIcon from "../../../../../components/Svg-Icons/PatIcon"


const ReenviarPDF = ({pat, envioFn, ButtonIcons, enabled, dialogText}) => {
  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false)
  const [formData, setFormData] = useState({})

  const {isPending, run} = useAsync({
    deferFn: envioFn,
    onResolve: (resp) => {
    }
  })

  const methods = useForm({})

  const {handleSubmit} = methods

  const onSubmit = ({...values}) => {
    const convertValues = {
      ...values, ...{
        pat_id: pat.id,
      }
    }
    setFormData({...convertValues})
    setConfirmationDialogVisible(true)
  }

  const handleConfirmedDateTime = () => {
    setConfirmationDialogVisible(false)
    run(formData)
  }

  const handleCancel = () => {
    // feito no CustomDialog
    // setConfirmationDialogVisible(false)
  }

  return (
    <FormContext {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)} style={{padding: 0, margin: 0}}>

        <Button
          onClick={onSubmit}
          style={{padding: '1rem', fontSize: '0.875rem', lineHeight: '1.2rem'}}
          startIcon={isPending && <CircularProgress color="secondary" size={20}/>}
          type="submit"
          fullWidth
          size="large"
          variant="contained"
          color="primary"
          disabled={isPending || !enabled}>
          {ButtonIcons}
        </Button>

      </Form>
      <CustomDialog open={confirmationDialogVisible}
                    setOpen={setConfirmationDialogVisible}
                    title={repoMsgs.CONFIRM_DIALOG_TITLE}
                    onConfirm={() => handleConfirmedDateTime()}
                    onCancel={() => handleCancel()}
                    msgsConfirm={repoMsgs.YES}
                    msgsCancel={repoMsgs.NO}>
        {dialogText}
      </CustomDialog>
    </FormContext>
  )
}

const ReenviarPDFPAT = ({repo}) => {
  // mayReenviarPDFPATaoTecnico
  // mayReenviarPDFPATaoCliente
  const patInfoStates = (repo && repo.infostates) || {}
  return (
    <Grid container spacing={2} justify="center" style={{margin: '1em 0 0'}}>
      <Grid item xs={4}>
        <ReenviarPDF pat={repo}
                     enabled={!!patInfoStates.mayReenviarPDFPATaoTecnico}
                     envioFn={reenviaPDFaoTec}
                     ButtonIcons={
                       <React.Fragment>
                         <PDFIcon/> <TrendingFlatIcon style={{width: '50px'}}/> <BuildIcon/><UserIcon style={{width: '12px', height: '12px'}}/>
                       </React.Fragment>}
                     dialogText={patInfoStatesMsgs.CONFIRM_ENVIO_PDF(repo.refCode, 'tec')}/>
      </Grid>
      <Grid item xs={4}>
        <ReenviarPDF pat={repo}
                     enabled={!!patInfoStates.mayReenviarPDFPATaoCliente}
                     envioFn={reenviaPDFaoCli}
                     ButtonIcons={
                       <React.Fragment>
                         <PDFIcon/> <TrendingFlatIcon style={{width: '50px'}}/> <ClienteIcon/><UserIcon style={{width: '12px', height: '12px'}}/>
                       </React.Fragment>}
                     dialogText={patInfoStatesMsgs.CONFIRM_ENVIO_PDF(repo.refCode, 'cli')}/>
      </Grid>
      <Grid item xs={4}>
        <Button
          target="_blank"
          href={`${baseURLPDF}/gpm/pdf/pat/${repo.id}`}
          style={{padding: '1rem', fontSize: '0.875rem', lineHeight: '1.2rem'}}
          fullWidth
          size="large"
          variant="contained"
          color="primary">
          <PatIcon/> <TrendingFlatIcon style={{width: '50px'}}/> <PDFIcon/>
        </Button>
      </Grid>
    </Grid>
  )
}
export { ReenviarPDFPAT }
