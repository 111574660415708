import React from "react"
import { alllogsURL, readAllLogsTable } from "./apiConfig"
import BaseTableListWithRoutes from "../../components/SharedComponents/Shared/BaseTableListWithRoutes"
import msgsConfig from "./msgsConfig"
import DateTimeCalendarIcon from "../../components/Svg-Icons/DateTimeCalendarIcon"
import { ShowDateTime } from "../../components/SharedComponents/Shared/Show/ShowDateTime"
import UserIcon from "../../components/Svg-Icons/UserIcon"
import BoolColoredCircleIcon from "../../components/SharedComponents/Shared/BoolColoredCircleIcon"
import Typography from "@material-ui/core/Typography"

const columns = [{
  Header: () => null,
  id: 'AllLogs',
  columns: [
    {
      Header: <DateTimeCalendarIcon/>,
      accessor: "created_at",
      Cell: ({row}) => {
        return (
          <div style={{width: '90px'}}>
            <ShowDateTime dateTime={row.original.created_at}/>
          </div>)
      },
      disableSortBy: true,
    },
    {
      Header: msgsConfig.DESCRICAO,
      accessor: "description",
      disableSortBy: true,
    },
    {
      Header: <UserIcon/>,
      accessor: "causer-name",
      Cell: ({row}) => {
        const user = row.original.causer && row.original.causer.data
        return (!!user)
          ? <Typography style={{fontSize: '80%'}}>{user.name}</Typography>
          : null
      },
      disableSortBy: true,
    },
    {
      Header: msgsConfig.ONLINE,
      accessor: "causer-logged",
      Cell: ({row}) => {
        const user = row.original.causer && row.original.causer.data
        return (!!user)
          ? <BoolColoredCircleIcon flagTF={!!user.logged} size={12} style={{width: '90px'}}/>
          : null
      },
      disableSortBy: true,
    },
  ]
}]
const AllLogs = () => {
  return (
    <BaseTableListWithRoutes
      addButtonDesc={'...'}
      route={alllogsURL}
      columns={columns}
      showAddButton={false}
      showEditButton={false}
      showDeleteButton={false}
      deleteFn={() => {
      }}
      readFn={readAllLogsTable}
    />
  )
}

export default AllLogs
