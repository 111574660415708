import React from "react"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { Controller } from "react-hook-form"
import PropTypes from "prop-types"
import Checkbox from "@material-ui/core/Checkbox"


function LFCheckBoxInput({control, name, label, ...otherProps}) {

  return (
    <React.Fragment>
      <Controller
        as={<FormControlLabel
          style={{marginLeft: '0px'}}
          control={<Checkbox value={name}/>}
          labelPlacement="end"
          label={label}
          {...otherProps}
        />}
        type="checkbox"
        name={name}
        control={control}
      />
    </React.Fragment>)
}

LFCheckBoxInput.defaultProps = {
  label: "Switch Field",
}

LFCheckBoxInput.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
}


export { LFCheckBoxInput }
