import React from "react"
import DateTimeCalendarIcon from "../../../../components/Svg-Icons/DateTimeCalendarIcon"
import { ShowDateTime } from "../../../../components/SharedComponents/Shared/Show/ShowDateTime"
import msgsConfig from "../../../Config/msgsConfig"
import BaseTableListWithRoutes from "../../../../components/SharedComponents/Shared/BaseTableListWithRoutes"
import { alllogsURL, readUserLogsTable } from "../../../Config/apiConfig"

const columns = [{
  Header: () => null,
  id: 'UserLogs',
  columns: [
    {
      Header: <DateTimeCalendarIcon/>,
      accessor: "created_at",
      Cell: ({row}) => {
        return (
          <div style={{width: '90px'}}>
            <ShowDateTime dateTime={row.original.created_at}/>
          </div>)
      },
      disableSortBy: true,
    },
    {
      Header: msgsConfig.DESCRICAO,
      accessor: "description",
      disableSortBy: true,
    },
    // {
    //   Header: msgsConfig.ONLINE,
    //   accessor: "causer-logged",
    //   Cell: ({row}) => {
    //     const user = row.original.causer && row.original.causer.data
    //     return (!!user)
    //       ? <BoolColoredCircleIcon flagTF={!!user.logged} size={12} style={{width: '90px'}}/>
    //       : null
    //   },
    //   disableSortBy: true,
    // },
  ]
}]
const UserLogs = ({userID}) => {
  return (
    <BaseTableListWithRoutes
      addButtonDesc={'...'}
      route={alllogsURL}
      columns={columns}
      showAddButton={false}
      showEditButton={false}
      showDeleteButton={false}
      deleteFn={() => {
      }}
      readFn={([props]) => readUserLogsTable(userID, [props])}
    />
  )
}

export default UserLogs
