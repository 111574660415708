const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const AppMsgs = {
  DEFAULT_VALUES: {
    ATIVOS_LABEL: "Ativos",
    HISTORICO_LABEL: "Histórico",
    SWITCH_TITLE: "OPÇÕES",
    BACK_LABEL: "Voltar para a página anterior",
    INPUT: {
      EMAIL_LBL: "Endereço de e-mail",
      PASS_LBL: "Password",
      NUMBER_LBL: "Campo Numero",
      MONEY_LBL: "Campo Dinheiro",
      IMAGE_LBL: "Alterar imagem",
      FILE_LBL: "Escolha um ficheiro",
      AVATAR: "Avatar"
    },
    BUTTONS: {
      SIGN_IN_BT: "Aceder...",
      REDIRECT_INFO: "Ir para ",
      SUBMIT: "Guardar Alterações"
    },
    TABLE: {
      EMPTY_TABLE_MSG: "Não existem dados a listar...",
      SEARCH: "Procurar...",
      ROWS_SELECT: "Linhas por Página",
      FIRST_PAGE: "Primeira página",
      LAST_PAGE: "Última página",
      NEXT_PAGE: "Próxima página",
      PREV_PAGE: "Página Anterior",
      ACTIONS: "Ações",
      ADD_TOOLTIP: "Adicionar",
      EDIT_TOOLTIP: "Editar",
      DELETE_TOOLTIP: "Eliminar",
      CANCEL_TOOLTIP: "Cancelar",
      SAVE_TOOLTIP: "Guardar",
      DELETE_ACTION: "remoção",
      UNDELETE_ACTION: "recuperação",
      CORFIRMATION_ROW: (action) => `Confirma a ${action} do registo?`,
    },
    RATING_LABEL: {
      RATING_1: "1",
      RATING_2: "2",
      RATING_3: "3",
      RATING_4: "4",
      RATING_5: "5",
    }
  },
  ERROR: {
    EMAIL: "Deve indicar um endereço de e-mail válido",
    MAX_LEN: (max, field) =>
      capitalize((field ? `'${field}' ` : "") + `demasiado extenso (max: ${max})`),
    EXACT_LEN: (length, field) =>
      capitalize((field ? `'${field}' ` : "") + `deve ter exatamente ${length} caracteres`),
    MAX_NUMBER: (number, field) =>
      capitalize((field ? `'${field}' ` : "") + `deve ter menor do que ${number}`),
    MIN_NUMBER: (number, field) =>
      capitalize((field ? `'${field}' ` : "") + `deve ter maior do que ${number}`),
    REQ: (field) =>
      capitalize((field ? `'${field}' - ` : "") + `indicação obrigatória`),
    INV: (field) =>
      capitalize((field ? `'${field}' - ` : "") + `indicação inválida`),
    NUMBER: (field) =>
      capitalize((field ? `'${field}' - ` : "") + `deve ser um número`),
    DATE: (field) =>
      capitalize((field ? `'${field}' - ` : "") + `deve ser uma data válida`),
    INVALID_SIZE: "Tamanho inválido (máx. 1.5MB)",
    INVALID_FORMAT: "Formato inválido (png/jpg)",
    INVALID_FILE: "Ficheiro inválido",
    CONTACT_REQUIRED: "Deve ser indicado pelo menos um dado de contacto (email, tlm, tlf)",
    ZIPCODE_REQUIRED: "O CP4 e CP3 devem ser indicados em conjunto"
  },
  ENTIDADES: {
    LABEL: "Entidades",
    EDIT: {
      LABEL: {
        NOME: "Nome",
        EMAIL: "Email",
        NIF: "NIF",
        TLM: "TLM",
        TLF: "TLF",
        CP4: "CP4",
        CP3: "CP3",
        ZIP_CODE: "Código Postal",
        LATITUDE: "Latitude",
        LONGITUDE: "Longitude",
        MORADA: "Morada",
        OBS: "Observações",
        USER: "Utilizador",
        OPEN_BUTTON: "Abrir Utilizador",
      }
    },
    TABLE: {
      HEADER: {
        NOME: "Nome",
        EMAIL: "Email",
        TLM: "TLM",
        TLF: "TLF",
        CONTACTO: "Contacto",
        EQUIP_COUNT: "Equipamentos",
        AVATAR: "Avatar"
      }
    },
  },
}

export { AppMsgs }
