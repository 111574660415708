import React from "react"
import { Link as RouterLink } from 'react-router-dom'
import { AppMsgs } from "../../../../constants/appMsgs"
import Fab from "@material-ui/core/Fab"
import Grid from "@material-ui/core/Grid"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import { StyledTableCell } from "../../../SharedStyles/SharedStyles"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import PropTypes from "prop-types"
import { getRequest } from "../../../../api/api"
import { tableStyles } from "../../../../constants/appTheme"
import { usersUrl } from "../../../../pages/Entidades/Users/userConst"
import Typography from "@material-ui/core/Typography"

function TableUser({data, showLink}) {
  const classes = tableStyles()
  return ((!!data)
    ? (
      <React.Fragment>

        <Grid container spacing={2} justify="center">
          {showLink && <Grid item xs={3}>
            <Fab component={RouterLink} target="_blank" size="small"
                 to={getRequest({URLname: usersUrl, id: data.user_id}).gotoItemPath()} color="secondary"
                 variant="extended" style={{boxShadow: 'none'}}>
              <Typography variant="body2" style={{paddingLeft: '5px', fontSize: '12px'}}
                          display={'inline'}>{AppMsgs.ENTIDADES.EDIT.LABEL.OPEN_BUTTON}</Typography>
            </Fab>
          </Grid>}
          <Grid item xs={9}>
            <img style={{
              width: '100%', height: '140px', maxWidth: '140px',
              display: 'block',
              objectFit: 'scale-down', margin: '0 auto', borderRadius: '10px'
            }} src={data.avatar} alt={data.name}/>
          </Grid>
        </Grid>

        <TableContainer>
          <Table className={classes.table} size="small" aria-label="TableTecnico">
            {/* ---------------------------- NOME & USERNAME -------------------------------------------------- */}
            <>
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan="3">{AppMsgs.ENTIDADES.EDIT.LABEL.NOME}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="nome">
                  <TableCell colSpan="3">{data.name}</TableCell>
                </TableRow>
              </TableBody>
            </>
          </Table>
        </TableContainer>
      </React.Fragment>)
    : null)
}

TableUser.defaultProps = {
  data: null,
  showLink: false
}

TableUser.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    avatar: PropTypes.string,
  }),
  showLink: PropTypes.bool
}

export { TableUser }
