import React from "react"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Collapse from "@material-ui/core/Collapse"
import Divider from "@material-ui/core/Divider"
import List from "@material-ui/core/List"
import LeftMenubarItemComponent from "./LeftMenubarItemComponent"
import ExpandMoreIcon from "../../../Svg-Icons/ExpandMoreIcon"
import ExpandLessIcon from "../../../Svg-Icons/ExpandLessIcon"
import clsx from "clsx"
import leftMenubarStyles from "./LeftMenubarStyles"


const LeftMenubarItem = (props) => {

  const {name, path, Icon, IconText, items = []} = props
  const classes = leftMenubarStyles()
  const isExpandable = items && items.length > 0
  const [open, setOpen] = React.useState(false)

  function handleClick() {
    setOpen(!open)
  }

  const MenuItemRoot = (
    <LeftMenubarItemComponent className={classes.menuItem} path={path} onClick={handleClick}>
      {!!Icon && (
        <ListItemIcon>
          <Icon/>
        </ListItemIcon>
      )}
      {!!IconText && (<span className={classes.textIcon}>{IconText}</span>)}
      <ListItemText className={classes.listItemText} primary={name} inset={!Icon}/>
      {isExpandable && !open && <ExpandMoreIcon/>}
      {isExpandable && open && <ExpandLessIcon/>}
    </LeftMenubarItemComponent>
  )
  const MenuItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Divider/>
      <List component="div" disablePadding className={clsx(isExpandable && classes.expandable)}>
        {items.map((item, index) => (
          <LeftMenubarItem {...item} key={index}/>
        ))}
      </List>
    </Collapse>
  ) : null

  return (
    <React.Fragment>
      {MenuItemRoot}
      {MenuItemChildren}
    </React.Fragment>
  )
}

export default LeftMenubarItem
