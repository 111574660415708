import React from "react"
import Grid from "@material-ui/core/Grid"
import { TableProcedimentoRefCounts } from "./TableProcedimentoRefCounts"
import { StyledTableCell, TableMarginTop } from "../../../../components/SharedStyles/SharedStyles"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import procedimentosMsgs from "../procedimentosMsgs"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import { Link as RouterLink } from "react-router-dom"
import { getRequest } from "../../../../api/api"
import { marcasURLname } from "../../../Marcas/apiMarcas"
import { tiposequipURLname } from "../../TiposEquip/apiTiposEquip"
import { immBgDark } from "../../../../constants/appTheme"
import IconButton from "@material-ui/core/IconButton"
import TrendingFlatIcon from "../../../../components/Svg-Icons/TrendingFlatIcon"

const LinkButton = (props) => (
  <IconButton
    component={RouterLink}
    target="_blank"
    size="small"
    to={getRequest({...props}).gotoItemPath()}
  >
    <TrendingFlatIcon color={immBgDark}/>
  </IconButton>
)

function RefsProcedimento({proc}) {
  const classes = TableMarginTop()
  const tiposEquipamento = (proc.tiposEquipamento && proc.tiposEquipamento.data) || []
  const modelos = (proc.modelos && proc.modelos.data) || []
  let key = 0
  const qtdMod = modelos.length
  const qtdTE = tiposEquipamento.length
  return (
    <Grid container spacing={2} justify="center">

      <Grid item xs={12}>
        <TableProcedimentoRefCounts {...proc} />
      </Grid>

      <Grid item xs={12} sm={qtdMod ? 4 : 12}>
        {!!tiposEquipamento.length && <TableContainer>
          <Table className={classes.table} size="small" aria-label="tiposEquipamentoRefCounts">
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  {procedimentosMsgs.COUNTS.TIPO_EQUIPS}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tiposEquipamento.map(te => (
                <TableRow key={key++}>
                  <TableCell>{te.tipo} - {te.refAN}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>}
      </Grid>

      <Grid item xs={12} sm={qtdTE ? 8 : 12}>
        {!!modelos.length && <TableContainer>
          <Table className={classes.table} size="small" aria-label="modelosRefCounts">
            <TableHead>
              <TableRow>
                <StyledTableCell colSpan="2">
                  {procedimentosMsgs.COUNTS.MODELOS}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {modelos.map(m => (
                <TableRow key={key++}>
                  <TableCell align="left">
                    {m.modelo}
                    <LinkButton URLname={marcasURLname} id={m.marca_id}/>
                  </TableCell>
                  <TableCell>
                    {m.tipo_equip_nome}
                    <LinkButton URLname={tiposequipURLname} id={m.tipoequip_id}/>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>}
      </Grid>
    </Grid>
  )
}

export { RefsProcedimento }
