import React from "react"
import expandColumn from "../../../components/LFTable/ExpandColumn"
import LFTable from "../../../components/LFTable/LFTable"
import { readTiposPlanoTable } from "./apiTiposPlano"
import msgTiposPlano from "./msgTiposPlano"
import TableTipoPlanoState from "./TableTipoPlanoState"
import { parseToPercent } from "../../../constants/taxasConst"

const tiposPlanoColumns = {
  Header: () => null,
  id: 'TiposPlano',
  columns: [
    {Header: msgTiposPlano.TABLE.HEADER.SIGLA, accessor: "sigla"},
    {Header: msgTiposPlano.TABLE.HEADER.TIPO, accessor: "tipo"},
    {
      Header: msgTiposPlano.TABLE.HEADER.PER_DESC_PECAS,
      accessor: "percDescPecas",
      Cell: ({row}) => {
        return <span>{parseToPercent(row.original.percDescPecas, true)}</span>
      },
      disableSortBy: true
    },
    {
      Header: msgTiposPlano.TABLE.HEADER.PER_DESC_DESLOC,
      accessor: "percDescDesloc",
      Cell: ({row}) => {
        return <span>{parseToPercent(row.original.percDescDesloc, true)}</span>
      },
      disableSortBy: true
    },
    {
      Header: msgTiposPlano.TABLE.HEADER.PER_DESC_MO,
      accessor: "percDescMO",
      Cell: ({row}) => {
        return <span>{parseToPercent(row.original.percDescMO, true)}</span>
      },
      disableSortBy: true
    }
  ],
}

const ListTiposPlano = () => {

  const renderRowSubComponent = React.useCallback(
    ({row}) => (<TableTipoPlanoState rowData={row.original}/>
    ), [])

  const columns = React.useMemo(() => [{...expandColumn}, {...tiposPlanoColumns}], [])

  return (
    <>
      <LFTable
        columns={columns}
        renderRowSubComponent={renderRowSubComponent}
        requestFn={readTiposPlanoTable}
      />
    </>
  )
}


export default ListTiposPlano
