import React from "react"
import PropTypes from "prop-types"
import Box from "@material-ui/core/Box"
import StarIcon from "../../../Svg-Icons/StarIcon"
import { primaryColor } from "../../../../constants/appTheme"

const ShowRating = ({qtdStars}) => {

  let splits = []
  for (let i = 1; i <= qtdStars; i++) {
    splits.push(i)
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-start" flexDirection="row" p={1} m={1}
         style={{
           padding: '0 .5rem',
           margin: 0
         }}>
      {
        splits.map((value, index) =>
          <StarIcon key={index} color={primaryColor} style={{width: '22px', height: '22px',}}/>
        )
      }
    </Box>
  )
}

ShowRating.propTypes = {
  qtdStars: PropTypes.number.isRequired,
}
export default ShowRating

