import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { errorColor, successColor } from "../../../../constants/appTheme"

const useStyles = makeStyles((theme) => {
  return ({
    button: {
      margin: theme.spacing(0, 2),
      color: ativo => ativo ? errorColor : successColor,
      border: ativo => `1px solid ${ativo ? errorColor : successColor}`,
    },
  })
})

/**
 * Ativar e Desativar Plano Custom Button
 * @param isLoading
 * @param dirty
 * @param children
 * @param disabled
 * @param ativo
 * @param other
 * @returns {*}
 * @constructor
 */
const ADButton = ({isLoading, dirty, children, disabled, ativo, ...other}) => {
  const classes = useStyles(ativo)
  return (
    <Button
      startIcon={isLoading && <CircularProgress color="secondary" size={20}/>}
      type="submit"
      variant="outlined"
      size="small"
      className={classes.button}
      disabled={isLoading || dirty || disabled}
      {...other}>
      {children}
    </Button>
  )
}

export default ADButton
