import React from 'react'
import PropTypes from "prop-types"
import { errorColor, successColor } from "../../../constants/appTheme"
import CircleIcon from "../../Svg-Icons/CircleIcon"

export default function BoolColoredCircleIcon({flagTF, size, style, ...props}) {
  let sizeStyle = {
    width: `16px`,
    height: `16px`,
  }
  if (size) {
    const squareSize = parseInt(size, 10)
    if (squareSize) {
      sizeStyle = {
        width: `${squareSize}px`,
        height: `${squareSize}px`,
      }
    }
  }
  return (<CircleIcon {...props}
                      style={{...style, ...sizeStyle}}
                      color={(!!flagTF) ? successColor : errorColor}/>)
}

BoolColoredCircleIcon.propTypes = {
  flagTF: PropTypes.bool.isRequired,
  size: PropTypes.number,
}
