import React from "react"
import { marcasURLname, readMarca, updateMarca } from "./apiMarcas"
import MarcaForm from "./MarcaForm"
import Async from "react-async"
import LinearProgress from "@material-ui/core/LinearProgress"
import ErrorMsg from "../../components/SharedComponents/Shared/Error/ErrorMsg"
import { useHistory } from "react-router"
import Title from "../../components/SharedComponents/Shared/Title"
import { getRequest } from "../../api/api"
import marcasMsgs from "./msgs"


function MarcaUpdate({id}) {
  const history = useHistory()
  const handleBack = () => history.push(getRequest({URLname: marcasURLname}).paginatePath())

  return (
    <React.Fragment>
      <Title handleBack={handleBack}>{marcasMsgs.BACK_LABEL}</Title>
      <Async promiseFn={readMarca} id={id}>
        <Async.Pending><LinearProgress color="secondary"/></Async.Pending>
        <Async.Fulfilled>
          {(data, {setData}) => {
            return (
              <React.Fragment>
                <MarcaForm marca={data} method={updateMarca} setMarca={setData}/>
              </React.Fragment>
            )
          }}
        </Async.Fulfilled>
        <Async.Rejected>{error => <ErrorMsg>{error}</ErrorMsg>}</Async.Rejected>
      </Async>
    </React.Fragment>
  )
}

export default MarcaUpdate
