import React from "react"
import { useAsync } from "react-async"
import { FormContext, useForm } from "react-hook-form"
import { Form } from "../../../components/SharedStyles/SharedStyles"
import { AppMsgs } from "../../../constants/appMsgs"
import LFAsyncSelectInput from "../../../components/LFInputs/LFAsyncSelectInput"
import { readTiposEquipSelect } from "../TiposEquip/apiTiposEquip"
import { readMarcasSelect } from "../../Marcas/apiMarcas"
import Grid from "@material-ui/core/Grid"
import { readModelosOfMarcaTipoEquipSelect } from "../../Marcas/Modelos/apiMarcaModelos"
import FormHelperText from "@material-ui/core/FormHelperText"
import { readInstaladoresSelect } from "./apiEquips"
import { LFSwitchInput } from "../../../components/LFInputs/LFSwitchInput"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import LFDatePickerInput from "../../../components/LFInputs/LFDatePickerInput"
import LFZipCodeForm from "../../../components/LFComponentsForm/LFZipCodeForm"
import LFTextAreaInput from "../../../components/LFInputs/LFTextAreaInput"
import LFSubmitButton from "../../../components/SharedComponents/Shared/LFButtons/LFSubmitButton"
import LFInput from "../../../components/LFInputs/LFInput"
import * as Yup from "yup"
import { selectValidation } from "../../../constants/appValidation"
import { LFFormatDateTime } from "../../../constants/appConst"
import msgsClientes from "../../Entidades/Clientes/msgsClientes"
import msgsEquips from "./msgsEquips"
import MomentUtils from "@date-io/moment"
import moment from "moment"
import "moment/locale/pt"
import IconButton from "@material-ui/core/IconButton"
import { Link as RouterLink } from "react-router-dom"
import { getRequest } from "../../../api/api"
import { primaryColorDark } from "../../../constants/appTheme"
import { instaladoresUrl } from "../../Entidades/Instaladores/apiInstaladores"
import InstaladorIcon from "../../../components/Svg-Icons/InstaladorIcon"


const validationSchema = Yup.object().shape({
  // numserie: requiredValidation(msgsEquips.EDIT.LABEL.NUM_SERIE),
  tipoequip_id: selectValidation(msgsClientes.EDIT.LABEL.TIPO_CLI),
  zipCodeRequired: Yup.bool().when(['cp4', 'cp3'], {
    is: (cp4, cp3) => (!cp4 && cp3) || (cp4 && !cp3),
    then: Yup.bool().required(AppMsgs.ERROR.ZIPCODE_REQUIRED),
    otherwise: Yup.bool()
  })
})

// @am  comentado em 29/3/2021 --> já não é usado!!
// const formatNumSerie = ({id, numserie}) => {
//   // Padrão para números de série desconhecidos ( 'IMM-' )
//   // permite que posteriormente se procure equipamentos
//   // em que o número de série real não foi indicado
//   // pesquisando números de série começados por 'IMM-'
//   return numserie || 'IMM-' + ('-' + id).padStart(10, '0')
// }

const defaultValues = (equip) => {
  return {
    tipoequip_id: (equip && equip.tipoequip_id) ? {
      index: equip.tipoequip_id,
      title: equip.tipoequip.data.tipo + ` (` + equip.tipoequip.data.refAN + `)`
    } : null,
    marca_id: (equip && equip.marca_id) ? {
      index: equip.marca_id,
      title: equip.marca.data.marca
    } : null,
    modelo_id: (equip && equip.modelo_id) ? {
      index: equip.modelo_id,
      title: equip.modelo.data.modelo
    } : null,
    instalador_id: (equip && equip.instalador_id) ? {
      index: equip.instalador_id,
      title: equip.instalador.data.nome
    } : null,
    // @rui  retirado em 2/3/2021, por indicação da imm
    //  numserie: (equip && formatNumSerie(equip)) || '',
    numserie: equip?.numserie || '',
    isNovo: equip ? !!equip.isNovo : false,
    dataInstalacao: (equip && !!equip.dataInstalacao) ? new Date(equip.dataInstalacao) : null,
    dataFimGarantia: (equip && !!equip.dataInstalacao) ? new Date(equip.dataFimGarantia) : null,
    isMesmaMoradaCliente: equip ? equip.isMesmaMoradaCliente : false,
    morada: equip ? equip.morada : '',
    cp4: equip ? equip.cp4 : '',
    cp3: equip ? equip.cp3 : '',
    obs: equip ? equip.obs : ''
  }
}

function convertToResponse(values) {
  return {
    ...values,
    dataInstalacao: values.dataInstalacao && LFFormatDateTime(values.dataInstalacao),
    dataFimGarantia: values.dataFimGarantia && LFFormatDateTime(values.dataFimGarantia),
    tipoequip_id: (values.tipoequip_id && values.tipoequip_id.index ? values.tipoequip_id.index : null),
    marca_id: (values.marca_id && values.marca_id.index ? values.marca_id.index : null),
    instalador_id: (values.instalador_id && values.instalador_id.index ? values.instalador_id.index : null),
    modelo_id: (values.modelo_id && values.modelo_id.index ? values.modelo_id.index : null)
  }
}

function EquipForm({equip, cliente, method, setEquip, ...otherProps}) {
  const hasDataInstalacao = equip && !!equip.dataInstalacao
  const hasMorada = !!cliente.cp7_id

  const {isPending, run} = useAsync({
    deferFn: method,
    onResolve: (newEquip) => {
      setEquip(newEquip)
      reset(defaultValues(newEquip))
    }
  })

  const methods = useForm({
    validationSchema: validationSchema,
    defaultValues: defaultValues(equip)
  })

  const {handleSubmit, formState, control, reset, watch} = methods

  const onSubmit = (values) => {
    const convertValues = convertToResponse(values)
    const cliente_id = (equip && equip.cliente_id) ? equip.cliente_id : cliente.id
    run({...equip, cliente_id: cliente_id, ...convertValues})
  }

  const tipoEquipWatch = watch("tipoequip_id")
  const marcaWatch = watch("marca_id")
  const modeloWatch = watch("modelo_id")
  const isMesmaMoradaClienteWatch = watch("isMesmaMoradaCliente")
  const instaladorIDwatch = watch("instalador_id")

  return (
    <FormContext {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)} {...otherProps}>
        <Grid container spacing={2} justify="center">
          {/* ------------------------------------------ TIPO_EQUIP ---------------------------------------------------*/}
          <Grid item xs={12} sm={4}>
            {/* disabled caso exista uma marca selecionada*/}
            <LFAsyncSelectInput
              request={readTiposEquipSelect}
              control={control} name='tipoequip_id'
              label={msgsEquips.EDIT.LABEL.TIPO_EQUIP}
              disabled={!!marcaWatch}/>
            {!!marcaWatch && <FormHelperText>{msgsEquips.EDIT.LABEL.VALIDATION.TIPO_EQUIP_MARCA}</FormHelperText>}
          </Grid>
          {/* ------------------------------------------ MARCA ---------------------------------------------------*/}
          <Grid item xs={12} sm={4}>
            {/* disabled caso não exista um tipoEquipamento selecionado ou exista um modelo selecionado*/}
            <LFAsyncSelectInput
              request={([props]) => readMarcasSelect({idTipoEquip: (tipoEquipWatch ? tipoEquipWatch.index : ''), ...props})}
              control={control} name='marca_id'
              label={msgsEquips.EDIT.LABEL.MARCA}
              disabled={!tipoEquipWatch || !!modeloWatch}/>
            {!tipoEquipWatch &&
            <FormHelperText>{msgsEquips.EDIT.LABEL.VALIDATION.MARCA_TIPO_EQUIP}</FormHelperText>}
            {!!modeloWatch && <FormHelperText>{msgsEquips.EDIT.LABEL.VALIDATION.MARCA_MODELO}</FormHelperText>}
          </Grid>
          {/* -------------------------------------------- MODELO ---------------------------------------------------*/}
          <Grid item xs={12} sm={4}>
            {/* disabled caso não exista uma marca selecionada*/}
            <LFAsyncSelectInput
              request={([props]) => readModelosOfMarcaTipoEquipSelect({
                marcaID: (marcaWatch ? marcaWatch.index : ''),
                teID: (tipoEquipWatch ? tipoEquipWatch.index : ''),
                ...props
              })}
              control={control} name='modelo_id'
              label={msgsEquips.EDIT.LABEL.MODELO}
              disabled={!marcaWatch}/>
            {!marcaWatch && <FormHelperText>{msgsEquips.EDIT.LABEL.VALIDATION.MODELO_MARCA}</FormHelperText>}
          </Grid>
          {/*-------------------------------------- NUM_SERIE -------------------------------------------------------*/}
          <Grid item xs={12} sm={6}>
            <LFInput label={msgsEquips.EDIT.LABEL.NUM_SERIE} name='numserie'/>
          </Grid>
          {/* ------------------------------------------ INSTALADOR -------------------------------------------------*/}
          <Grid item xs={10} sm={5}>
            <LFAsyncSelectInput
              request={readInstaladoresSelect}
              control={control} name='instalador_id'
              label={msgsEquips.EDIT.LABEL.INSTALADOR}/>
          </Grid>
          <Grid item xs={2} sm={1}>
            <IconButton
              component={RouterLink} target="_blank"
              to={instaladorIDwatch
                ? getRequest({URLname: instaladoresUrl, id: instaladorIDwatch.index}).gotoItemPath()
                : getRequest({URLname: instaladoresUrl}).paginatePath()}>
              <InstaladorIcon color={primaryColorDark}/>
            </IconButton>
          </Grid>
          {/*  ------------------------------------- DATA_INSTALACAO ------------------------------------------------*/}
          <Grid item xs={12} sm={hasDataInstalacao ? 4 : 8} style={{display: 'grid', alignItems: 'flex-end', paddingBottom: '20px'}}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <LFDatePickerInput name='dataInstalacao' control={control}
                                 label={msgsEquips.EDIT.LABEL.DATA_INSTALACAO}/>
            </MuiPickersUtilsProvider>
          </Grid>
          {/*  ------------------------------------- DATA_FIM_GARANTIA -----------------------------------------------*/}
          {hasDataInstalacao && <Grid item xs={12} sm={4} style={{display: 'grid', alignItems: 'flex-end', paddingBottom: '20px'}}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <LFDatePickerInput disabled={true} name='dataFimGarantia' control={control}
                                 label={msgsEquips.EDIT.LABEL.DATA_FIMGARANTIA}/>
            </MuiPickersUtilsProvider>
          </Grid>}
          {/*  ------------------------------------- SWITCHS -----------------------------------------------*/}
          <Grid item xs={12} sm={4}>
            <FormHelperText>{AppMsgs.DEFAULT_VALUES.SWITCH_TITLE}</FormHelperText>
            {/*  -------------------------------------- IS_NOVO -----------------------------------------------------*/}
            <Grid item xs={12}>
              <LFSwitchInput control={control} name='isNovo'
                             label={msgsEquips.EDIT.LABEL.IS_NOVO}/>
            </Grid>
            {/*  ---------------------------------- IS_MESMA_MORADA_CLI ---------------------------------------------*/}
            <Grid item xs={12}>
              <LFSwitchInput control={control} disabled={!hasMorada} name='isMesmaMoradaCliente'
                             label={msgsEquips.EDIT.LABEL.IS_MESMA_MORADA_CLI}/>
            </Grid>
          </Grid>
          {/*------------------------------------------- ZIPCODE ----------------------------------------------------*/}
          {!isMesmaMoradaClienteWatch && <LFZipCodeForm/>}
          {/*-------------------------------------------- OBS -------------------------------------------------------*/}
          <Grid item xs={12} sm={12}>
            <LFTextAreaInput label={AppMsgs.ENTIDADES.EDIT.LABEL.OBS} name='obs'/>
          </Grid>
          <LFSubmitButton isLoading={isPending} dirty={!formState.dirty}>
            {equip ? msgsEquips.BUTTON.EDIT_EQUIP : msgsEquips.BUTTON.NEW_EQUIP}
          </LFSubmitButton>
        </Grid>
      </Form>
    </FormContext>
  )
}


export default EquipForm
