import PecaIcon from "../../../components/Svg-Icons/PecaIcon"
import Pecas from "./Pecas"
import { api, apiCUD, apiReadTable, getRequest } from "../../../api/api"
import PecaView from "./PecaView"
import pecasMsgs from "./msgs"

const pecasURLname = 'pecas'

// ----------------------- PEÇAS ROUTES -----------------------
const pecasPaginateRouterProps = {
  path: getRequest({URLname: pecasURLname}).paginatePath(),
  exact: true,
  name: pecasMsgs.LABEL,
  component: Pecas,
  Icon: PecaIcon,
  breadcrumb: () => pecasMsgs.LABEL,
  reqRole: 'R'
}

const pecaViewRouterProps = {
  path: getRequest({URLname: pecasURLname}).viewPath(),
  name: pecasMsgs.UPDATE_LABEL,
  exact: true,
  component: PecaView,
  breadcrumb: (props) => !!parseInt(props.match.params.id, 10) ? pecasMsgs.UPDATE_LABEL : pecasMsgs.CREATE_LABEL,
  reqRole: 'R'
}

// ----------------------- PEÇAS ROUTES -----------------------

/**
 * Read Marcas com paginação para tabela
 * @param query
 * @returns {Promise<*|void>}
 */
async function readPecasTable([query]) {
  const request = getRequest({URLname: pecasURLname, query}).ALL()
  return apiReadTable(request)
}

// async function readPecasSelect() {
//   const request = getRequest({URLname: pecasURLname, query: {pageSize: 10000, pageIndex: 0}}).ALL()
//   return api(request).then(r => r.data.data.map((peca) => {
//     return {index: peca.id, ref: peca.ref, desc: peca.desc, unitPrice: peca.unitPrice}
//   }))
// }

async function deletePeca(id) {
  const request = getRequest({URLname: pecasURLname, id}).DELETE()
  return apiCUD(request)
}

/**
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
function readPeca({id}) {
  const request = getRequest({URLname: pecasURLname, id}).GET()
  return api(request).then(r => r.data.data)
}

function updatePeca([data]) {
  const request = getRequest({URLname: pecasURLname, id: data.id}).PUT()
  return apiCUD(request, data).then(r => r.data)
}

function createPeca([data]) {
  const request = getRequest({URLname: pecasURLname}).POST()
  return apiCUD(request, data).then(r => r.data)
}

export {
  pecasURLname,
  pecasPaginateRouterProps,
  pecaViewRouterProps,
  readPecasTable, // readPecasSelect,
  deletePeca,
  readPeca,
  updatePeca,
  createPeca
}
