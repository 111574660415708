import React from "react"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { TableMarginTop } from "../../../../SharedStyles/SharedStyles"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import { secondaryColor } from "../../../../../constants/appTheme"


function TableAcao({designacao, refAN, dataLimite, causa, tipoPAT}) {
  const classes = TableMarginTop()
  return (
    <TableContainer>
      <Table className={classes.table} size="small" aria-label="TableDescontos">
        <TableHead>
          <TableRow>
            <TableCell colSpan="2" style={{color: secondaryColor, fontSize: '18px'}}>{designacao}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key="refAn">
            <TableCell>Referência</TableCell>
            <TableCell align="right">{refAN}</TableCell>
          </TableRow>
          <TableRow key="dataLimite">
            <TableCell>Data Limite</TableCell>
            <TableCell align="right">{dataLimite}</TableCell>
          </TableRow>
          <TableRow key="causa">
            <TableCell>Causa</TableCell>
            <TableCell align="right">{causa}</TableCell>
          </TableRow>
          <TableRow key="tipoPAT">
            <TableCell>Tipo PAT</TableCell>
            <TableCell align="right">{tipoPAT}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TableAcao
