import React from "react"
import clsx from "clsx"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import Badge from "@material-ui/core/Badge"
import MenuItem from "@material-ui/core/MenuItem"
import { useUser } from "../../../../context/user-context"
import MenuIcon from "../../../Svg-Icons/MenuIcon"
import NotificationsIcon from "../../../Svg-Icons/NotificationsIcon"
import mainToolbarStyles from "./MainToolbarStyles"
import { mainRoutes } from "../../../../constants/appRoutes"
import { useAuth } from "../../../../context/auth-context"
import Routes from "../../../../utils/routes"
import { UserAvatar } from "./UserAvatar"
import Popper from "@material-ui/core/Popper"
import Grow from "@material-ui/core/Grow"
import Paper from "@material-ui/core/Paper"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import MenuList from "@material-ui/core/MenuList"
import LogoutIcon from "../../../Svg-Icons/LogoutIcon"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import UserIcon from "../../../Svg-Icons/UserIcon"
import { Link as RouterLink } from "react-router-dom"
import { getRequest } from "../../../../api/api"
import msgsUsers from "../../../../pages/Entidades/Users/msgsUsers"
import { usersUrl } from "../../../../pages/Entidades/Users/userConst"
import { avisosAlertasURL } from "../../../../pages/AvisosAlertas/apiAvisosAlertas"

const MainToolbar = ({leftNavBarOpenState, openLeftNavBarHandler}) => {
  const classes = mainToolbarStyles()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const {user} = useUser()
  const {logout} = useAuth()

  const openUserMenu = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const closeUserMenu = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  const renderUserMenu = (
    <div>
      <IconButton
        edge="end"
        aria-label="account of current user"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={openUserMenu}
        color="inherit">
        <UserAvatar alt={user.name}
                    size={32}
                    imagePath={user.avatar}
                    roleCheckSigla={user.roleCheck.roleSigla}/>
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal
              placement={'left-start'}>
        {({TransitionProps, placement}) => {
          return (
            <Grow
              {...TransitionProps}
              style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
            >
              <Paper>
                <ClickAwayListener onClickAway={closeUserMenu}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MenuItem
                      component={RouterLink} target="_blank"
                      to={getRequest({URLname: usersUrl, id: user.id}).gotoItemPath()}
                    onClick={closeUserMenu}>
                    <ListItemIcon><UserIcon/></ListItemIcon>
                      <ListItemText primary={msgsUsers.MENU.PROFILE}/>
                    </MenuItem>
                    <MenuItem onClick={logout}>
                      <ListItemIcon><LogoutIcon/></ListItemIcon>
                      <ListItemText primary={msgsUsers.MENU.LOGOUT}/>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )
        }}
      </Popper>
    </div>
  )

  const {u} = useUser()

  return (
    <AppBar position="absolute" className={clsx(classes.appBar, leftNavBarOpenState && classes.appBarShift)}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={openLeftNavBarHandler}
          className={clsx(classes.menuButton, leftNavBarOpenState && classes.menuButtonHidden)}>
          <MenuIcon/>
        </IconButton>
        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
          <Routes routes={mainRoutes} render={'breadcrumb'}/>
        </Typography>
        {u.mayActAs.gest && <IconButton color="inherit"
                                        component={RouterLink}
                                        to={getRequest({URLname: avisosAlertasURL}).paginatePath()}>
          <Badge color="secondary">
            <NotificationsIcon size={20}/>
          </Badge>
        </IconButton>}
        {renderUserMenu}
      </Toolbar>
    </AppBar>
  )
}

export default React.memo(MainToolbar)
