import React from "react"
import Async from "react-async"
import LinearProgress from "@material-ui/core/LinearProgress"
import ErrorMsg from "../../components/SharedComponents/Shared/Error/ErrorMsg"
import { allPatStates, patsURLname, readPatsTable } from "./apiPats"
import TabsComponent from "../../components/SharedComponents/Shared/TabsComponent"
import { useHistory } from "react-router"
import LFTable from "../../components/LFTable/LFTable"
// import { errorColor, successColor } from "../../constants/appTheme"
import { Badge } from "../../components/SharedStyles/SharedStyles"
import BadgeMat from '@material-ui/core/Badge'
import DateTimeCalendarIcon from "../../components/Svg-Icons/DateTimeCalendarIcon"
import patMsgs from "./patMsgs"
import { getRequest } from "../../api/api"
// import CalendarIcon from "../../components/Svg-Icons/CalendarIcon"
import { ShowDateTime } from "../../components/SharedComponents/Shared/Show/ShowDateTime"
import Typography from "@material-ui/core/Typography"
import { AppMsgs } from "../../constants/appMsgs"

// @am  comentado em 29/3/2021 --> já não é usado!!
// const extraColumnsContacto = {
//
// }

export const patsColumns = {
  Header: () => null,
  id: 'PAT',
  columns: [
    {
      Header: patMsgs.TABLE.HEADER.REF_CODE,
      accessor: "refCode",
      disableSortBy: true
    },
    {
      Header: patMsgs.TABLE.HEADER.TIPO,
      accessor: "tipocli",
      Cell: ({row}) => (
        <Badge props={row.original.tipocli}>{row.original.tipocliChar}</Badge>
      ),
      disableSortBy: true
    },
    {
      Header: patMsgs.TABLE.HEADER.TIPO_PAT,
      accessor: "tipoPat",
      Cell: ({row}) => (
        <BadgeMat
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          // variant="dot"
          badgeContent={row.original.causaPAT} color="secondary">
          <span style={{marginLeft: '10px'}}>{row.original.tipoPat}</span>
        </BadgeMat>
      ),
      disableSortBy: true
    },
    // {
    //   Header: () => <CalendarIcon/>,
    //   accessor: "dataLimite",
    //   Cell: ({row}) => {
    //     return <span
    //       style={{color: (row.original.offLimit ? errorColor : successColor)}}>
    //       {row.original.dataLimite}</span>
    //   },
    //   disableSortBy: true
    // },
    {
      Header: () => <span><DateTimeCalendarIcon/> {patMsgs.TABLE.HEADER.DH_CONTACTO_CLI}</span>,
      accessor: "dhContactoCliente",
      Cell: ({row}) => {
        return <ShowDateTime dateTime={row.original.dhContactoCliente}/>
      },
      disableSortBy: true
    },
    {
      Header: () => <span><DateTimeCalendarIcon/> {patMsgs.TABLE.HEADER.DH_VISITA}</span>,
      accessor: "dhvisita",
      Cell: ({row}) => {
        if(!!row.original.visitas.data.length)
          return <ShowDateTime dateTime={row.original.visitas.data[0].dhAgendamentoComCliente}/>
        return <span></span>
      },
      disableSortBy: true
    }
  ]
}

function CustomPatsList({requestFn}) {
  const history = useHistory()
  const handlerUpdate = (row) => history.push(getRequest({URLname: patsURLname, id: row.id}).gotoItemPath())

  return (
    <LFTable
      columns={[patsColumns]}
      showEditButton={true}
      handlerUpdate={handlerUpdate}
      requestFn={requestFn}
    />
  )
}

const PatStatesRender = ({data}) => {
  let tabs = []
  let index = 0
  const qtd = data.length || 0
  data.forEach(item => {
    tabs.push({
      label: item.desc,
      hasMany: true,
      qtd: item.qtd,
      value: item.value,
      index: index++,
      component: <CustomPatsList
        requestFn={([props]) => readPatsTable([{
          state: item.value,
          com: 'visitas',
          ...props
        }])}/>,
    })
  })
  return (qtd > 0)
    ? (<TabsComponent renderTabs={tabs}/>)
    : (<Typography component="h1" variant="h6" color="inherit" noWrap>
      {AppMsgs.DEFAULT_VALUES.TABLE.EMPTY_TABLE_MSG}
    </Typography>)
}

const Pats = () => {
  return (
    <Async promiseFn={allPatStates}>
      <Async.Pending><LinearProgress color="secondary"/></Async.Pending>
      <Async.Fulfilled>
        {(data) => <PatStatesRender data={data}/>}
      </Async.Fulfilled>
      <Async.Rejected>{error => <ErrorMsg>{error}</ErrorMsg>}</Async.Rejected>
    </Async>
  )
}

export default Pats
