import styled from "@material-ui/core/styles/styled"
import { secondaryColor } from "../../../constants/appTheme"
import React from "react"
import Typography from "@material-ui/core/Typography"
import MarcaIcon from "../../../components/Svg-Icons/MarcaIcon"
import BoolIconComponent from "../../../components/SharedComponents/Shared/BoolIconComponent"
import { useHistory } from "react-router"
import LFTable from "../../../components/LFTable/LFTable"
import { getRequest } from "../../../api/api"
import { equipCliUrl, equipUrl } from "./apiEquips"
import msgsEquips from "./msgsEquips"
import expandColumn from "../../../components/LFTable/ExpandColumn"

const IconLetter = styled('span')(() => ({
  fontWeight: '300',
  color: secondaryColor,
  fontSize: '0.875rem',
  paddingInlineEnd: '3px'
}))
const RowText = styled('span')(() => ({
  fontSize: '0.875rem',
}))

const columns = {
  Header: () => null,
  id: 'Equipamentos',
  columns: [
    {
      Header: msgsEquips.TABLE.HEADER.NUM_SERIE,
      accessor: "numserie",
    },
    {
      Header: msgsEquips.TABLE.HEADER.BASE_INFO,
      accessor: "base_info",
      Cell: ({row}) => (
        <React.Fragment>
          {!!(row.original.tipoequip_id) &&
          <Typography component="div">
            <IconLetter>TE</IconLetter>
            <RowText>{row.original.tipo + ` (` + row.original.refAN + `)`}</RowText>
          </Typography>}
          {(row.original.has_marca) &&
          <Typography component="div">
            <MarcaIcon size={12} style={{marginRight: '5px'}} color={secondaryColor}/>
            <RowText>{row.original.marca}</RowText>
          </Typography>}
          {(row.original.has_modelo) &&
          <Typography component="div">
            <IconLetter>M</IconLetter>
            <RowText>{row.original.modelo}</RowText>
          </Typography>}
        </React.Fragment>
      ),
      disableSortBy: true
    },
    {
      Header: msgsEquips.TABLE.HEADER.HAS_PLANO,
      accessor: "hasPlanoActivEmCurso",
      Cell: ({row}) => {

        return (
          <React.Fragment>
            <BoolIconComponent bool={row.original.hasPlanoActivEmCurso}/>
          </React.Fragment>
        )
      },
      disableSortBy: true
    },
    {
      Header: msgsEquips.TABLE.HEADER.HAS_PMCESP,
      accessor: "has_pat_pmcesp",
      Cell: ({row}) => (
        <React.Fragment>
          <BoolIconComponent bool={row.original.has_pat_pmcesp}/>
        </React.Fragment>
      ),
      disableSortBy: true
    },
    {
      Header: msgsEquips.TABLE.HEADER.HAS_PA,
      accessor: "has_pat_pa",
      Cell: ({row}) => (
        <React.Fragment>
          <BoolIconComponent bool={row.original.has_pat_pa}/>
        </React.Fragment>
      ),
      disableSortBy: true
    },
    {
      Header: msgsEquips.TABLE.HEADER.PATS_COUNT,
      accessor: "pats_count",
      disableSortBy: true
    }
  ]
}

const EquipsTableCustom = (props) => {
  const {id, renderRowSubComponent} = props
  const history = useHistory()
  const handlerCreate = () => history.push(getRequest({URLname: equipCliUrl, id: id}).gotoItemPath())
  const handlerUpdate = (row) => history.push(getRequest({URLname: equipUrl, id: row.id}).gotoItemPath())

  const columnsWithExpand = React.useMemo(() => [{...expandColumn}, {...columns}], [])

  return (
    <LFTable
      columns={renderRowSubComponent ? columnsWithExpand : [columns]}
      handlerCreate={handlerCreate}
      handlerUpdate={handlerUpdate}
      {...props}
    />
  )
}

export default EquipsTableCustom
