import React from "react"
import Grid from "@material-ui/core/Grid"
import InputError from "../SharedComponents/Shared/Error/InputError"
import { LFNumberInput } from "../LFInputs/LFNumberInput"
import { AppMsgs } from "../../constants/appMsgs"
import LFInput from "../LFInputs/LFInput"
import { useFormContext } from "react-hook-form"
import PropTypes from "prop-types"

function LFZipCodeForm(props) {
  const {errors} = useFormContext()

  const {cp4Name, cp4Label, cp3Name, cp3Label, moradaName, moradaLabel, disabled} = props

  return (
    <>
      {/*-------------------------------------------- CP4 -------------------------------------------------------*/}
      <Grid item xs={6} sm={2}>
        <LFNumberInput label={cp4Label} name={cp4Name} disabled={disabled}/>
      </Grid>
      {/*------------------------------------------- CP3 --------------------------------------------------------*/}
      <Grid item xs={6} sm={2}>
        <LFNumberInput label={cp3Label} name={cp3Name} disabled={disabled}/>
      </Grid>
      {/*----------------------------------------- MORADA -------------------------------------------------------*/}
      <Grid item xs={12} sm={8}>
        <LFInput label={moradaLabel} name={moradaName} disabled={disabled}/>
      </Grid>
      {/*------------------------------------- ZIPCODE_REQUIRED MSG ---------------------------------------------*/}
      <InputError>{errors.zipCodeRequired && errors.zipCodeRequired.message}</InputError>
    </>)
}

LFZipCodeForm.defaultProps = {
  cp4Name: "cp4",
  cp4Label: AppMsgs.ENTIDADES.EDIT.LABEL.CP4,
  cp3Name: "cp3",
  cp3Label: AppMsgs.ENTIDADES.EDIT.LABEL.CP3,
  moradaName: "morada",
  moradaLabel: AppMsgs.ENTIDADES.EDIT.LABEL.MORADA
}

LFZipCodeForm.propTypes = {
  cp4Name: PropTypes.string,
  cp4Label: PropTypes.string,
  cp3Name: PropTypes.string,
  cp3Label: PropTypes.string,
  moradaName: PropTypes.string,
  moradaLabel: PropTypes.string
}

export default LFZipCodeForm
