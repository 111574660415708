import React from 'react'
import FormGroup from '@material-ui/core/FormGroup'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import PropTypes from "prop-types"

function LeftRightSwitch({name, onChild, offChild, checked, onchangeFn, innerClassName}) {
  const [state, setState] = React.useState(checked)

  const handleChange = (event, value) => {
    setState(value)
    if (!!onchangeFn) onchangeFn(event, value)
  }

  return (
    <FormGroup>
      <Typography component="div" className={innerClassName}>
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item>{offChild}</Grid>
          <Grid item>
            <Switch checked={state} onChange={handleChange} name={name}/>
          </Grid>
          <Grid item>{onChild}</Grid>
        </Grid>
      </Typography>
    </FormGroup>
  )
}

LeftRightSwitch.defaultProps = {
  name: 'boolCheck',
  checked: false,
  onChild: 'on',
  offChild: 'off',
  onchangeFn: null,
  innerClassName: '',
}

LeftRightSwitch.propTypes = {
  name: PropTypes.string,
  checked: PropTypes.bool,
  onChild: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  offChild: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  onchangeFn: PropTypes.func,
  innerClassName: PropTypes.string
}

export { LeftRightSwitch }
