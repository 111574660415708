import React, { useContext } from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import AddIcon from "../../Svg-Icons/AddIcon"
import { secondaryColor } from "../../../constants/appTheme"
import { ListRepoState } from "./ListRepoGest"
import { handleDeleteRepoItem } from "./handleDeleteRepoItem"
import PropTypes from "prop-types"

const ListRepoWrapper = ({ListComponent, btAddTitle}) => {
  const {
    showEdit, showList, repoList, showState, repo, setRepoList, mayEdit, getRepoHrefFn,
    FKfieldname, repoKey, deleteRepoFn, readReposFn
  } = useContext(ListRepoState)
  const handlerShowCreate = () => showEdit(null)

  const handleDeleteFn = (id) => handleDeleteRepoItem({
    repo,
    repoKey,
    FKfieldname,
    setRepoList,
    repoID: id,
    deleteRepoFn,
    readReposFn
  })
  const disable = showState.disable

  return (
    <div style={disable ? {pointerEvents: 'none', opacity: '0.3'} : {}}>
      {mayEdit ? <Box display="flex" justifyContent="flex-end">
        <Button color="secondary" variant="outlined" onClick={handlerShowCreate} disableElevation
                startIcon={<AddIcon color={secondaryColor}/>}>
          {btAddTitle}
        </Button>
      </Box> : null}
      <p></p>
      {/*{()=>listComponent(...{repoList, mayEdit, handleDeleteFn})}*/}
      <ListComponent getRepoHrefFn={getRepoHrefFn}
                     repo={repo}
                     repoList={repoList}
                     showList={showList}
                     mayEdit={mayEdit}
                     handleDeleteFn={handleDeleteFn}/>
    </div>
  )
}

ListRepoWrapper.propTypes = {
  ListComponent: PropTypes.func.isRequired,
}

export { ListRepoWrapper }
