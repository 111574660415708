import PropTypes from "prop-types"

const handleDeleteRepoItem = ({repo, repoKey, FKfieldname, setRepoList, repoID, deleteRepoFn, readReposFn}) => {
  const params = {}
  params[FKfieldname] = repo.id
  params[repoKey] = repoID
  return deleteRepoFn(params)
  //   .then(
  //   _ => {
  //     readReposFn({id: repo.id}).then((imgs) => setRepoList(imgs))
  //   }
  // )
}

// handleSaveImagem.defaultValues = {
// }

handleDeleteRepoItem.propTypes = {
  repo: PropTypes.object.isRequired,
  repoKey: PropTypes.string.isRequired,
  FKfieldname: PropTypes.string.isRequired,
  setRepoList: PropTypes.func.isRequired,
  repoID: PropTypes.number.isRequired,
  deleteRepoFn: PropTypes.func.isRequired,
  readReposFn: PropTypes.func.isRequired,
}

export {
  handleDeleteRepoItem
}
