import React, { useContext } from "react"
import Title from "../../../components/SharedComponents/Shared/Title"
import TiposEquipForm from "./TiposEquipForm"
import { createTipoEquip } from "./apiTiposEquip"
import { RedirectContext } from "./TiposEquip"
import tiposequipMsgs from "./msgs"

function TiposEquipCreate() {
  const {list} = useContext(RedirectContext)

  const handleBack = () => list()

  return (
    <React.Fragment>
      <Title handleBack={handleBack}>{tiposequipMsgs.BACK_LABEL}</Title>
      <TiposEquipForm data={null} method={createTipoEquip} handleBack={handleBack}/>
    </React.Fragment>
  )
}

export default TiposEquipCreate
