import React, { useContext } from "react"
import { obsMsgs } from "./obsMsgs"
import { ListRepoState } from "../../../../../components/SharedComponents/ListRepo/ListRepoGest"
import Title from "../../../../../components/SharedComponents/Shared/Title"
import { OBSForm } from "./OBSForm"

function OBSEdit() {

  const {showState, showList, FKfieldname} = useContext(ListRepoState)
  const handleBack = () => showList()

  const backMsg = (showState.createMode)
    ? obsMsgs.BACK_LABEL_CREATE
    : obsMsgs.BACK_LABEL_UPDATE

  return (
    <React.Fragment>
      <Title handleBack={handleBack}>{backMsg}</Title>
      <OBSForm FKfieldname={FKfieldname}/>
    </React.Fragment>
  )
}

export { OBSEdit }
