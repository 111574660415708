import React from "react"
import TabsComponent from "../../../components/SharedComponents/Shared/TabsComponent"
import msgsPlanosAtivados from "./msgsPlanosAtivados"
import { planosAtivadosUrl, readPlanosAtivadosTable } from "./apiPlanosAtivados"
import { useHistory } from "react-router"
import { getRequest } from "../../../api/api"
import LFTable from "../../../components/LFTable/LFTable"
import { errorColor, primaryColorDark } from "../../../constants/appTheme"
import IconButton from "@material-ui/core/IconButton"
import { Link as RouterLink } from "react-router-dom"
import { planosUrl } from "../ListPlanos/apiPlanos"
import PlanoIcon from "../../../components/Svg-Icons/PlanoIcon"
import leftMenubarStyles from "../../../components/MainLayout/StructureLayout/LeftMenubar/LeftMenubarStyles"
import Box from "@material-ui/core/Box"
import { ShowDate, ShowDateTime } from "../../../components/SharedComponents/Shared/Show/ShowDateTime"
import CalendarIcon from "../../../components/Svg-Icons/CalendarIcon"
import TrendingFlatIcon from "../../../components/Svg-Icons/TrendingFlatIcon"
import BoolColoredCircleIcon from "../../../components/SharedComponents/Shared/BoolColoredCircleIcon"
import LastPageIcon from "../../../components/Svg-Icons/LastPageIcon"
import expandColumn from "../../../components/LFTable/ExpandColumn"
import { PADetailPanel } from "./partials/PADetailPanel"


const planosAtivadosColumns = ({dhAnul}) => {

  let cols = {
    Header: () => null,
    id: 'planosAtivadosColumns',
    columns: [
      {
        Header: msgsPlanosAtivados.PA_STATE.DATA_ATIV,
        accessor: "pa_dh_ativado",
        Cell: ({row}) => {
          const classes = leftMenubarStyles()
          return (
            <Box display="flex" justifyContent="center" alignItems="flex-start" flexDirection="row" p={1} m={1}
                 className={classes.noMarginBox}
                 width={'80px'}>
              <Box p={1} className={classes.noMarginBox}>
                <ShowDateTime dateTime={row.original.ativado_at} offLimit={false}/>
              </Box>
            </Box>
          )
        }
      },
      {
        Header: msgsPlanosAtivados.PLANO,
        accessor: "plano-rev",
        Cell: ({row}) => {
          const classes = leftMenubarStyles()
          const plano = (row.original && row.original.plano && row.original.plano.data) || false
          return plano
            ? (
              <div style={{fontSize: '90%'}}>
                <Box display="flex" justifyContent="flex-start" alignItems="center" flexDirection="row"
                     p={1} m={1} className={classes.noMarginBox}>
                  <Box p={1} alignSelf="flex-start" className={classes.noMarginBox}>
                    <span style={{
                      marginRight: 20,
                      display: 'inline-block',
                      fontWeight: 'bold',
                      fontSize: '90%'
                    }}>{plano.refAN + " (" + plano.rev + ")"}</span>
                    <IconButton
                      size="small"
                      component={RouterLink}
                      to={getRequest({URLname: planosUrl, id: plano.id}).gotoItemPath()}>
                      <PlanoIcon color={primaryColorDark} style={{
                        width: `14px`,
                        height: `14px`,
                      }}/>
                    </IconButton>
                  </Box>
                </Box>
                <span>{plano.designacao}</span>
              </div>)
            : null
        },
        disableSortBy: true
      },
      {
        Header: (
          <React.Fragment>
            <CalendarIcon style={{width: 16, height: 16}}/>
            <TrendingFlatIcon style={{width: 14, height: 14}}/>
            <CalendarIcon style={{width: 16, height: 16}}/>
          </React.Fragment>),
        accessor: "inicio-fim",
        Cell: ({row}) => {
          const classes = leftMenubarStyles()
          return (
            <Box display="flex" justifyContent="flex-start" alignItems="flex-start" flexDirection="column"
                 p={1} m={1} className={classes.noMarginBox}>
              <Box p={1} className={classes.noMarginBox}>
                <Box display="flex" justifyContent="flex-start" alignItems="center" flexDirection="row"
                     p={1} m={1} className={classes.noMarginBox} width={'90px'}>
                  <Box p={1} alignSelf="flex-start" className={classes.noMarginBox} width={'12px'}>
                    <BoolColoredCircleIcon flagTF={true} size={6}/>
                  </Box>
                  <Box p={1} className={classes.noMarginBox}>
                    <ShowDate date={row.original.dataInic}/>
                  </Box>
                </Box>
              </Box>
              <Box p={1} className={classes.noMarginBox}>
                <Box display="flex" justifyContent="flex-start" alignItems="center" flexDirection="row"
                     p={1} m={1} className={classes.noMarginBox} width={'90px'}>
                  <Box p={1} alignSelf="flex-start" className={classes.noMarginBox} width={'12px'}>
                    <LastPageIcon color={errorColor} style={{width: 10, height: 10}}/>
                  </Box>
                  <Box p={1} className={classes.noMarginBox}>
                    <ShowDate date={row.original.dataFim}/>
                  </Box>
                </Box>
              </Box>
            </Box>
          )
        }
      },
      {
        Header: msgsPlanosAtivados.DURACAO,
        accessor: "mesesDur",
        Cell: ({row}) => {
          return row.original.mesesDur
            ? (<span>{row.original.mesesDur}</span>)
            : (<span style={{fontSize: '180%', color: '#999'}}>&infin;</span>)
        },
        disableSortBy: true
      }
    ]
  }
  if (dhAnul) {
    cols.columns.splice(1, 0, {
      Header: msgsPlanosAtivados.PA_STATE.DATA_ANUL,
      accessor: "pa_dh_desativado",
      Cell: ({row}) => {
        const classes = leftMenubarStyles()
        return (
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" p={1} m={1}
               className={classes.noMarginBox}
               width={'80px'}>
            <Box p={1} className={classes.noMarginBox}>
              <ShowDateTime dateTime={row.original.desativado_at} offLimit={true}/>
            </Box>
          </Box>
        )
      }
    })
  }
  return cols
}

function CustomPlanosAtivadosList({readFn, countsRow = false, extraCols = {}}) {
  const history = useHistory()
  const handlerUpdate = (row) => history.push(getRequest({URLname: planosAtivadosUrl, id: row.id}).gotoItemPath())

  const renderRowSubComponent = React.useCallback(
    ({row}) => (countsRow ? <PADetailPanel planoAtivado={row.original}/> : null), [countsRow])
  const cols = planosAtivadosColumns(extraCols)
  const withExpand = countsRow ? [{...expandColumn}, {...cols}] : [{...cols}]
  const tblCols = React.useMemo(() => withExpand, [withExpand])

  return (
    <LFTable
      columns={tblCols}
      deleteFn={null}
      showAddButton={false}
      showEditButton={true}
      showDeleteButton={false}
      handlerUpdate={handlerUpdate}
      requestFn={readFn}
      renderRowSubComponent={renderRowSubComponent}
    />
  )
}

function ListPlanosAtivados({equipID = 0}) {

  const query = equipID
    ? {com: 'plano', relCom: 'equipscli', relComID: equipID}
    : {com: 'plano'}

  const planosAtivadosTabs = [
    {
      label: msgsPlanosAtivados.PLANOS_ATIV_STATES.ATIVOS,
      index: 0,
      component: <CustomPlanosAtivadosList
        readFn={([props]) => readPlanosAtivadosTable([{...query, state: 'ativ', ...props}])}
        countsRow={true}/>,
    }, {
      label: msgsPlanosAtivados.PLANOS_ATIV_STATES.CONCLUIDOS,
      index: 1,
      component: <CustomPlanosAtivadosList
        readFn={([props]) => readPlanosAtivadosTable([{...query, state: 'conc', ...props}])}
        countsRow={true}/>,
    }, {
      label: msgsPlanosAtivados.PLANOS_ATIV_STATES.DESATIVADOS,
      index: 2,
      component: <CustomPlanosAtivadosList
        readFn={([props]) => readPlanosAtivadosTable([{...query, state: 'anul', ...props}])}
        extraCols={{dhAnul: true,}}
        countsRow={true}/>,
    },
  ]
  return (
    <TabsComponent renderTabs={planosAtivadosTabs}/>
  )
}

export {
  ListPlanosAtivados
}
