import React from "react"
import * as Yup from "yup"
import { requiredHTMLEditorValidation } from "../../../../../constants/appValidation"
import patInfoStatesMsgs from "../patInfoStatesMsgs"
import { Form } from "../../../../../components/SharedStyles/SharedStyles"
import Grid from "@material-ui/core/Grid"
import { FormContext, useForm } from "react-hook-form"
import { useAsync } from "react-async"
import FormHelperText from "@material-ui/core/FormHelperText"
import LFTextEditor from "../../../../../components/LFInputs/LFTextEditor"
import makeStyles from "@material-ui/core/styles/makeStyles"
import CircularProgress from "@material-ui/core/CircularProgress"
import Button from "@material-ui/core/Button"
import LeftMarginSpan from "../../../../../components/SharedStyles/LeftMarginSpan"
import "moment/locale/pt"
import { relatarPat } from "../apiInfoStates"

const useStyles = makeStyles(theme => ({
  quillEditor: {
    minHeight: '8em'
  }
}))

const validationSchema = Yup.object().shape({
  relatorioTecnico: requiredHTMLEditorValidation(patInfoStatesMsgs.RELATORIO_TEC),
})

const RelatarPATForm = ({repo, reload}) => {
  const classes = useStyles()

  const {isPending, run} = useAsync({
    deferFn: relatarPat,
    onResolve: (resp) => {
      reload()
    }
  })

  const methods = useForm({
    validationSchema: validationSchema,
    defaultValues: {
      relatorioTecnico: '',
    }
  })

  const {handleSubmit, formState, control} = methods

  const onSubmit = ({...values}) => {
    const convertValues = {
      ...values, ...{
        pat_id: repo.id,
        relatorioTecnico: values.relatorioTecnico,
      }
    }
    run({...convertValues})
  }

  return (
    <FormContext {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} justify="center">

          {/*---------------------------------------------------------------*/}
          <Grid item xs={12}>
            <FormHelperText>{patInfoStatesMsgs.RELATORIO_TEC}</FormHelperText>
            <LFTextEditor name='relatorioTecnico' control={control} className={classes.quillEditor}/>
          </Grid>
          {/*---------------------------------------------------------------*/}
          <Grid item xs={12}>
            <Button
              onClick={onSubmit}
              style={{padding: '1rem', fontSize: '0.875rem', lineHeight: '1.2rem'}}
              startIcon={isPending && <CircularProgress color="secondary" size={20}/>}
              type="submit"
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              disabled={isPending || !formState.dirty}>
              <React.Fragment>
                <LeftMarginSpan>{patInfoStatesMsgs.BT_RELATAR_PAT}</LeftMarginSpan>
              </React.Fragment>
            </Button>
          </Grid>
        </Grid>
      </Form>
    </FormContext>
  )
}

export {
  RelatarPATForm,
}
