import React from "react"
import * as Yup from "yup"
import { avalImmValidation, requiredValidation } from "../../../../../constants/appValidation"
import patInfoStatesMsgs from "../patInfoStatesMsgs"
import { Form } from "../../../../../components/SharedStyles/SharedStyles"
import Grid from "@material-ui/core/Grid"
import { FormContext, useForm } from "react-hook-form"
import { useAsync } from "react-async"
import FormHelperText from "@material-ui/core/FormHelperText"
import LFTextEditor from "../../../../../components/LFInputs/LFTextEditor"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Typography from "@material-ui/core/Typography"
import LFRatingInput from "../../../../../components/LFInputs/LFRatingInput"
import CircularProgress from "@material-ui/core/CircularProgress"
import Button from "@material-ui/core/Button"
import LeftMarginSpan from "../../../../../components/SharedStyles/LeftMarginSpan"
import { LFSwitchInput } from "../../../../../components/LFInputs/LFSwitchInput"
import VisitaPatEndResolvidaIcon from "../../../../../components/Svg-Icons/VisitaPatEndResolvidaIcon"
import LFAssinatura from "../../../../../components/LFInputs/LFAssina/LFAssinatura"
import { resolvidaVisitaPat } from "../../../VisitasPat/apiVisitaPat"
import { extractPatCosts, PatReportIntroWithPecas } from "./PatReportIntroWithPecas"

const useStyles = makeStyles(theme => ({
  quillEditor: {
    minHeight: '8em'
  },
  pagoSim: {
    fontWeight: 'bold',
    fontSize: '90%',
    color: '#073',
  },
  pagoNao: {
    fontWeight: 'bold',
    fontSize: '90%',
    color: '#a00',
  },
  msgNaoPago: {
    color: '#a00',
    minHeight: '60px',
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(32),
    fontWeight: theme.typography.fontWeightLight,
  },
  msgPago: {
    color: '#073',
    minHeight: '60px',
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(32),
    fontWeight: theme.typography.fontWeightLight,
  },
  spanCusto: {
    display: 'block',
  }
}))

const validationSchema = Yup.object().shape({
  avalVisita: avalImmValidation(1, 5, patInfoStatesMsgs.AVAL_SLIDER_LBL),
  assinaturaCliente: requiredValidation(patInfoStatesMsgs.ASSINATURA_CLI),
  assinaturaTecnico: requiredValidation(patInfoStatesMsgs.ASSINATURA_TEC),
})

const ConcluirComResolucaoForm = ({pat, visitaID, reload}) => {
  const classes = useStyles()
  const {isPending, run} = useAsync({
    deferFn: resolvidaVisitaPat,
    onResolve: (resp) => {
      reload()
    }
  })

  const {totalFinalComIVA} = extractPatCosts(pat)

  const methods = useForm({
    validationSchema: validationSchema,
    defaultValues: {
      pago: (totalFinalComIVA === 0),
      avalVisita: 3,
      assinaturaCliente: '',
      assinaturaTecnico: '',
      obsFinal: '',
    }
  })

  const {handleSubmit, formState, watch, control} = methods

  const watchPago = watch('pago')

  const onSubmit = ({...values}) => {
    const convertValues = {
      ...values, ...{
        visita_id: visitaID,
        pago: values.pago,
        avalVisita: values.avalVisita,
        assinaturaCliente: values.assinaturaCliente,
        assinaturaTecnico: values.assinaturaTecnico,
        obsFinal: values.obsFinal,
      }
    }
    run({...convertValues})
  }

  return (
    <FormContext {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} justify="center">
          {/*---------------------------------------------------------------*/}
          <Grid item xs={12} sm={6}>
            <Grid container direction="row" justify="center" alignItems="center">
              <Grid item xs={6}>
                <FormHelperText>{patInfoStatesMsgs.SWITCH_PAGO_TITLE}</FormHelperText>
                <Typography component="div">
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item className={!watchPago ? classes.pagoNao : ''}>{patInfoStatesMsgs.PAGO_NAO}</Grid>
                    <Grid item>
                      <LFSwitchInput control={control} name='pago' disabled={totalFinalComIVA === 0}/>
                    </Grid>
                    <Grid item className={watchPago ? classes.pagoSim : ''}>{patInfoStatesMsgs.PAGO_SIM}</Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={!watchPago ? classes.msgNaoPago : classes.msgPago}>
                  <span>{!watchPago ? patInfoStatesMsgs.PAGO_NAO : ''} {patInfoStatesMsgs.PAGO}</span>
                  <span className={classes.spanCusto}>{pat.totalPAT} €</span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/*---------------------------------------------------------------*/}
          <Grid item xs={12} sm={6}>
            <Typography component="legend"
                        style={{paddingBottom: '5px'}}>{patInfoStatesMsgs.AVAL_SLIDER_LBL}</Typography>
            <LFRatingInput name="avalVisita" control={control} initValue={3}/>
          </Grid>
          {/*---------------------------------------------------------------*/}
          <Grid item xs={12}>
            <FormHelperText>{patInfoStatesMsgs.ASSINATURA_CLI}</FormHelperText>
            <LFAssinatura name='assinaturaCliente' control={control} validationError={patInfoStatesMsgs.ERRO_ASSINATURA_CLI}/>
          </Grid>
          {/*---------------------------------------------------------------*/}
          <Grid item xs={12}>
            <FormHelperText>{patInfoStatesMsgs.ASSINATURA_TEC}</FormHelperText>
            <LFAssinatura name='assinaturaTecnico' control={control} validationError={patInfoStatesMsgs.ERRO_ASSINATURA_TEC}/>
          </Grid>
          {/*---------------------------------------------------------------*/}
          <Grid item xs={12}>
            <FormHelperText>{patInfoStatesMsgs.OBS}</FormHelperText>
            <LFTextEditor name='obsFinal' control={control} className={classes.quillEditor}/>
          </Grid>
          {/*---------------------------------------------------------------*/}
          <Grid item xs={12}>
            <Button
              onClick={onSubmit}
              style={{padding: '1rem', fontSize: '0.875rem', lineHeight: '1.2rem'}}
              startIcon={isPending && <CircularProgress color="secondary" size={20}/>}
              type="submit"
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              disabled={isPending || !formState.dirty}>
              <React.Fragment>
                <VisitaPatEndResolvidaIcon style={{width: '30px', height: '30px'}}/>
                <LeftMarginSpan>{patInfoStatesMsgs.BT_CONC_VISITA_PAT_RESOLVED}</LeftMarginSpan>
              </React.Fragment>
            </Button>
          </Grid>
        </Grid>
      </Form>
    </FormContext>
  )
}

const ConcluirComResolucao = ({pat, visitaID, reload}) => {
  return (
    <React.Fragment>
      <PatReportIntroWithPecas pat={pat} isPecasDisable={true}/>

      <ConcluirComResolucaoForm pat={pat} visitaID={visitaID} reload={reload}/>
    </React.Fragment>
  )
}
export {
  ConcluirComResolucao,
}
