const msgsConfig = {
  LABEL: "Configurações",
  ALL_USERS_LOGS: "Logs",
  EDIT: {
    LABEL: {
      HORIZONTEAVISOS: "Horizonte de visualização dos avisos (meses)",
      MAXMESESASSOCPLANOPASSADO: "Associação, no passados, de planos a equipamentos (nº max de meses)",
      TXIVA: "Taxa IVA",
      TXDESLOCCAT: "Taxa Deslocação CAT",
      TXDESLOCIMM: "Taxa Deslocação IMM",
      TXMOCAT: "Taxa Mão Obra CAT",
      TXMOIMM: "Taxa Mão Obra IMM",
      DIASANTAVISOS: "Dias Antecedência Avisos",
      DIASANTPMP: "Dias Antecedência PMP",
      DIASANTPA: "Dias Antecedência PA",
      REFANDESLOC: "Referência Deslocação",
      REFANMO: "Referência Mão de Obra",
      REFANMCE: "Referência MCE",
      DUR_CLI_DOM: "Duração Cliente Doméstico",
      DUR_CLI_INST: "Duração Cliente Institucional",
      SEND_EMAIL_LOGIN: "Enviar Emails de login",
      AVATAR: "Avatar",
      MAIL_IPDOC: "Email IPDoc",
      PAT_DEFAULT_DUR_MN: "Duração Padrão da PAT (minutos)",
      IS_DEMO_MODE: "Demo Mode (não envia emails)",
    },
  },
  BUTTON: {
    SUBMIT: "Alterar configurações"
  },

  DESCRICAO: 'descrição',
  ONLINE: 'Autenticado?',
}

export default msgsConfig
