import React from "react"
import patInfoStatesMsgs from "../patInfoStatesMsgs"
import Grid from "@material-ui/core/Grid"
import { useAsync } from "react-async"
import { iniciarPat } from "../apiInfoStates"
import PatStartIcon from "../../../../../components/Svg-Icons/PatStartIcon"
import CircularProgress from "@material-ui/core/CircularProgress"
import Button from "@material-ui/core/Button"
import LeftMarginSpan from "../../../../../components/SharedStyles/LeftMarginSpan"


const IniciarPATouVisitaForm = ({visitaID, reload}) => {

  const {isPending, run} = useAsync({
    deferFn: iniciarPat,
    onResolve: (dadosArranque) => {
      reload()
    }
  })

  const onSubmit = () => {
    run({visita_id: visitaID})
  }

  return (visitaID ? (
    <Grid container spacing={2} justify="center">
      {/*---------------------------------------------------------------*/}
      <Grid item xs={12}>
        <Button
          onClick={() => onSubmit()}
          style={{padding: '1rem', fontSize: '0.875rem', lineHeight: '1.2rem'}}
          startIcon={isPending && <CircularProgress color="secondary" size={20}/>}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={isPending}>
          <PatStartIcon style={{width: '30px', height: '30px'}}/>
          <LeftMarginSpan>{patInfoStatesMsgs.BT_INICIAR_PAT}</LeftMarginSpan>
        </Button>
      </Grid>
    </Grid>
  ) : null)
}

const IniciarPATForm = ({repo, reload}) => {
  return (
    <IniciarPATouVisitaForm visitaID={repo.lastEstado && repo.lastEstado.data && repo.lastEstado.data.visita_pat_id}
                            reload={reload}/>
  )
}

const IniciarVisitaForm = ({repo, reload}) => {
  return (
    <IniciarPATouVisitaForm visitaID={repo.id}
                            reload={reload}/>
  )
}

export {
  IniciarPATForm,
  IniciarVisitaForm
}
