import React from "react"
import { AppMsgs } from "../../../constants/appMsgs"
import { deleteEquip, readEquipsTable, undeleteEquip } from "./apiEquips"
import TabsComponent from "../../../components/SharedComponents/Shared/TabsComponent"
import EquipsTableCustom from "./EquipsTableCustom"
import { TableEquipamento } from "../../../components/SharedComponents/Shared/Tables/TableEquipamento"


const equipsTabs = (renderRowSubComponent) => [
  {
    label: AppMsgs.DEFAULT_VALUES.ATIVOS_LABEL,
    index: 0,
    component: <EquipsTableCustom
      requestFn={readEquipsTable}
      deleteFn={deleteEquip}
      showAddButton={false}
      showEditButton={true}
      showDeleteButton={true}
    />
  },
  {
    label: AppMsgs.DEFAULT_VALUES.HISTORICO_LABEL,
    index: 1,
    component: <EquipsTableCustom
      requestFn={([props]) => readEquipsTable([{trashed: true, ...props}])}
      showAddButton={false}
      showEditButton={false}
      showDeleteButton={false}
      renderRowSubComponent={renderRowSubComponent}
      showUndeleteButton={true}
      unDeleteFn={undeleteEquip}
    />,
  }
]

const ListEquips = () => {

  const renderRowSubComponent = React.useCallback(
    ({row}) => {
      return <TableEquipamento data={row.original}/>
    }, [])


  return (
    <React.Fragment>
      <TabsComponent renderTabs={equipsTabs(renderRowSubComponent)}/>
    </React.Fragment>
  )
}

export default ListEquips
