import React from "react"
import { useAsync } from "react-async"
import LFSubmitButton from "../../../components/SharedComponents/Shared/LFButtons/LFSubmitButton"
import { Form, H2Slim } from "../../../components/SharedStyles/SharedStyles"
import Grid from "@material-ui/core/Grid"
import LFInput from "../../../components/LFInputs/LFInput"
import { FormContext, useForm } from "react-hook-form"
import * as Yup from "yup"
import { LFCheckBoxInput } from "../../../components/LFInputs/LFCheckBoxInput"
import { LFMoneyInput } from "../../../components/LFInputs/LFMoneyInput"
import TableTaxasCliente from "../../../components/SharedComponents/Shared/Tables/TblsAcaoTaxasDescontos/TableTaxasCliente"
import LFTextAreaInput from "../../../components/LFInputs/LFTextAreaInput"
import { betweenNumberValidation, numberValidation, refANValidation, requiredValidation } from "../../../constants/appValidation"
import { errorColor, successColor } from "../../../constants/appTheme"
import Box from "@material-ui/core/Box"
import CircleIcon from "../../../components/Svg-Icons/CircleIcon"
import AtivaDesativaPlanoButtons from "./AtivaDesativaPlanoButtons"
import msgsPlanos from "./msgsPlanos"

const validationSchema = Yup.object().shape({
  refAN: refANValidation(4, msgsPlanos.EDIT.LABEL.REFAN),
  designacao: requiredValidation(msgsPlanos.EDIT.LABEL.DESIGNACAO),
  txAtivCAT: numberValidation(0, msgsPlanos.EDIT.LABEL.TXATIVCAT),
  txAtivIMM: numberValidation(0, msgsPlanos.EDIT.LABEL.TXATIVIMM),
  txPerCAT: numberValidation(0, msgsPlanos.EDIT.LABEL.TXPERCCAT),
  txPerIMM: numberValidation(0, msgsPlanos.EDIT.LABEL.TXPERCIMM),
  txDeslocCAT: numberValidation(0, msgsPlanos.EDIT.LABEL.TXDESLOCCAT),
  txDeslocIMM: numberValidation(0, msgsPlanos.EDIT.LABEL.TXDESLOCIMM),
  txMOCAT: numberValidation(0, msgsPlanos.EDIT.LABEL.TXMOCAT),
  txMOIMM: numberValidation(0, msgsPlanos.EDIT.LABEL.TXMOIMM),
  txMCECAT: numberValidation(0, msgsPlanos.EDIT.LABEL.TXPMCECAT),
  txMCEIMM: numberValidation(0, msgsPlanos.EDIT.LABEL.TXPMCEIMM),
  percDescPecas: betweenNumberValidation(0, 100, msgsPlanos.EDIT.LABEL.PER_DESC_PECAS),
  percDescDesloc: betweenNumberValidation(0, 100, msgsPlanos.EDIT.LABEL.PER_DESC_DESLOC),
  percDescMO: betweenNumberValidation(0, 100, msgsPlanos.EDIT.LABEL.PER_DESC_MO),
})

const defaultValues = (plano) => {
  return {
    tipoPlano: plano ? plano.tpSigla + ' - ' + plano.tpTipo : '', // disabled
    teDesc: plano ? plano.teDesc : '', // disabled
    tipo_plano_id: plano ? plano.tipo_plano_id : '', // disabled
    tipoequip_id: plano ? plano.tipoequip_id : '', // disabled
    durCliDomest: plano ? plano.durCliDomest : '', // disabled
    durCliInstit: plano ? plano.durCliInstit : '', // disabled
    geraPA: plano ? plano.geraPA : '', // disabled
    periodPMP: plano ? plano.periodPMP : '', // disabled
    periodPMPDesc: plano ? msgsPlanos.PERIODICIDADES[plano.periodPMP] : '', // disabled
    periodAviso: plano ? plano.periodAviso : '', // disabled
    periodAvisoDesc: plano ? msgsPlanos.PERIODICIDADES[plano.periodAviso] : '', // disabled
    designacao: plano ? plano.designacao : '',
    horasResposta: plano ? plano.horasResposta : '',
    refAN: plano ? plano.refAN : '',
    obs: plano ? plano.obs : '',
    txAtivCAT: plano ? plano.txAtivCAT : '',
    txAtivIMM: plano ? plano.txAtivIMM : '',
    txPerCAT: plano ? plano.txPerCAT : '',
    txPerIMM: plano ? plano.txPerIMM : '',
    txDeslocCAT: plano ? plano.txDeslocCAT : '',
    txDeslocIMM: plano ? plano.txDeslocIMM : '',
    txMOCAT: plano ? plano.txMOCAT : '',
    txMOIMM: plano ? plano.txMOIMM : '',
    txMCECAT: plano ? plano.txMCECAT : '',
    txMCEIMM: plano ? plano.txMCEIMM : '',
    percDescPecas: plano ? plano.percDescPecas * 100 : '',
    percDescDesloc: plano ? plano.percDescDesloc * 100 : '',
    percDescMO: plano ? plano.percDescMO * 100 : '',
  }
}

/**
 * Os campos no PlanoForm são quase todos LFInput porque estão "disabled"
 * se algum dia for para alterar é necessário rever o type do campo
 */
function PlanoForm({plano, method, setPlano}) {


  const {isPending, run} = useAsync({
    deferFn: method,
    onResolve: (newPlano) => {
      setPlano(newPlano)
      reset(defaultValues(newPlano))
    }
  })

  const methods = useForm({
    validationSchema: validationSchema,
    defaultValues: defaultValues(plano) // como existem campos personalizados é necessário o defaultValues
  })

  const {handleSubmit, formState, control, reset} = methods


  const onSubmit = (values) => {
    const convertValues = {
      ...values,
      percDescDesloc: values.percDescDesloc / 100,
      percDescPecas: values.percDescPecas / 100,
      percDescMO: values.percDescMO / 100,
    }
    run({...plano, ...convertValues})
  }

  return (
    <React.Fragment>
      <FormContext {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} justify="center">
            <Grid item xs={12}>
              <H2Slim>{`${plano.refAN} - ${plano.designacao} (Rev. ${plano.rev}) `}</H2Slim>
              <AtivaDesativaPlanoButtons plano={plano} setPlano={setPlano} reset={reset} defaultValues={defaultValues}/>
            </Grid>
            {/*---------------------------------------- tipo_equip --------------------------------------------------*/}
            <Grid item xs={12}>
              <Box component="span">
                {msgsPlanos.ESTADOS.LABEL}: {plano.ativo ?
                <React.Fragment>
                  <CircleIcon color={successColor} size={14}/>
                  <span> {msgsPlanos.ESTADOS.ATIVO}</span>
                </React.Fragment> :
                <React.Fragment>
                  <CircleIcon color={errorColor} size={14}/>
                  <span> {msgsPlanos.ESTADOS.DESATIVO}</span>
                </React.Fragment>
              }
              </Box>
            </Grid>
            {/*---------------------------------------- tipo_equip --------------------------------------------------*/}
            <Grid item xs={12} sm={6}>
              <LFInput label={msgsPlanos.EDIT.LABEL.TIPO_EQUIP} name='teDesc' disabled/>
            </Grid>
            {/*---------------------------------------- tipo_plano --------------------------------------------------*/}
            <Grid item xs={12} sm={6}>
              <LFInput label={msgsPlanos.EDIT.LABEL.TIPO_PLANO} name='tipoPlano' disabled/>
            </Grid>
            {/*---------------------------------------- refAN -------------------------------------------------------*/}
            <Grid item xs={12} sm={4}>
              <LFInput label={msgsPlanos.EDIT.LABEL.REFAN} name='refAN'/>
            </Grid>
            {/*---------------------------------------- designacao --------------------------------------------------*/}
            <Grid item xs={12} sm={8}>
              <LFInput label={msgsPlanos.EDIT.LABEL.DESIGNACAO} name='designacao'/>
            </Grid>
            {/*---------------------------------------- durCliDomest ------------------------------------------------*/}
            <Grid item xs={12} sm={4}>
              <LFInput label={msgsPlanos.EDIT.LABEL.DUR_CLI_DOM} name='durCliDomest' disabled/>
            </Grid>
            {/*---------------------------------------- durCliInstit ------------------------------------------------*/}
            <Grid item xs={12} sm={4}>
              <LFInput label={msgsPlanos.EDIT.LABEL.DUR_CLI_INST} name='durCliInstit' disabled/>
            </Grid>
            {/*---------------------------------------- horasResposta -----------------------------------------------*/}
            <Grid item xs={12} sm={4}>
              <LFInput label={msgsPlanos.EDIT.LABEL.HORAS_RESPOSTA} name='horasResposta'/>
            </Grid>
            {/*---------------------------------------- geraPA --------------------------------------------------*/}
            <Grid item xs={12} sm={3}>
              <LFCheckBoxInput control={control} name='geraPA'
                               label={msgsPlanos.EDIT.LABEL.GERAPA} disabled/>
            </Grid>
            {/*---------------------------------------- periodoPMP --------------------------------------------------*/}
            <Grid item xs={12} sm={4}>
              <LFInput label={msgsPlanos.EDIT.LABEL.PERIOD_PMP} name='periodPMPDesc' disabled/>
            </Grid>
            {/*---------------------------------------- periodoAviso ------------------------------------------------*/}
            <Grid item xs={12} sm={5}>
              <LFInput label={msgsPlanos.EDIT.LABEL.PERIOD_AVISOS} name='periodAvisoDesc' disabled/>
            </Grid>
            {/* ------------------------------------- TAXA ATIVAÇÃO CAT ---------------------------------------*/}
            <Grid item xs={12} sm={6}>
              <LFMoneyInput label={msgsPlanos.EDIT.LABEL.TXATIVCAT} name='txAtivCAT'/>
            </Grid>
            {/*------------------------------------- TAXA ATIVAÇÃO IMM ---------------------------------------*/}
            <Grid item xs={12} sm={6}>
              <LFMoneyInput label={msgsPlanos.EDIT.LABEL.TXATIVIMM} name='txAtivIMM'/>
            </Grid>
            {/* ------------------------------------- TAXA PERC CAT ---------------------------------------*/}
            <Grid item xs={12} sm={6}>
              <LFMoneyInput label={msgsPlanos.EDIT.LABEL.TXPERCCAT} name='txPerCAT'/>
            </Grid>
            {/*------------------------------------- TAXA PERC IMM ---------------------------------------*/}
            <Grid item xs={12} sm={6}>
              <LFMoneyInput label={msgsPlanos.EDIT.LABEL.TXPERCIMM} name='txPerIMM'/>
            </Grid>
            {/* ------------------------------------- TAXA DESLOC CAT ---------------------------------------*/}
            <Grid item xs={12} sm={6}>
              <LFMoneyInput label={msgsPlanos.EDIT.LABEL.TXDESLOCCAT} name='txDeslocCAT'/>
            </Grid>
            {/*------------------------------------- TAXA DESLOC IMM ---------------------------------------*/}
            <Grid item xs={12} sm={6}>
              <LFMoneyInput label={msgsPlanos.EDIT.LABEL.TXDESLOCIMM} name='txDeslocIMM'/>
            </Grid>
            {/* ------------------------------------- TAXA MO CAT ---------------------------------------*/}
            <Grid item xs={12} sm={6}>
              <LFMoneyInput label={msgsPlanos.EDIT.LABEL.TXMOCAT} name='txMOCAT'/>
            </Grid>
            {/*------------------------------------- TAXA MO IMM ---------------------------------------*/}
            <Grid item xs={12} sm={6}>
              <LFMoneyInput label={msgsPlanos.EDIT.LABEL.TXMOIMM} name='txMOIMM'/>
            </Grid>
            {/* ------------------------------------- TAXA PMCE CAT ---------------------------------------*/}
            <Grid item xs={12} sm={6}>
              <LFMoneyInput label={msgsPlanos.EDIT.LABEL.TXPMCECAT} name='txMCECAT'/>
            </Grid>
            {/*------------------------------------- TAXA PMCE IMM ---------------------------------------*/}
            <Grid item xs={12} sm={6}>
              <LFMoneyInput label={msgsPlanos.EDIT.LABEL.TXPMCEIMM} name='txMCEIMM'/>
            </Grid>
            {/*------------------------------------- TAXA CLIENTE ---------------------------------------*/}
            <Grid item xs={12}>
              <TableTaxasCliente {...plano}/>
            </Grid>
            {/*------------------------------------- PERC DESC PECAS ---------------------------------------*/}
            <Grid item xs={12} sm={4}>
              <LFInput label={msgsPlanos.EDIT.LABEL.PER_DESC_PECAS} name='percDescPecas'/>
            </Grid>
            {/* ------------------------------------- PERC DESC DESLOC ---------------------------------------*/}
            <Grid item xs={12} sm={4}>
              <LFInput label={msgsPlanos.EDIT.LABEL.PER_DESC_DESLOC} name='percDescDesloc'/>
            </Grid>
            {/*------------------------------------- PERC DESC MO ---------------------------------------*/}
            <Grid item xs={12} sm={4}>
              <LFInput label={msgsPlanos.EDIT.LABEL.PER_DESC_MO} name='percDescMO'/>
            </Grid>
            {/*----------------------------------------- OBS -------------------------------------------------------*/}
            <Grid item xs={12}>
              <LFTextAreaInput label={msgsPlanos.EDIT.LABEL.OBS} name='obs'/>
            </Grid>
          </Grid>
          <LFSubmitButton isLoading={isPending} dirty={!formState.dirty}>
            {msgsPlanos.BUTTON.EDIT_PLANO}
          </LFSubmitButton>
        </Form>
      </FormContext>
    </React.Fragment>
  )
}

/**
 * Component para alterar estado do plano
 * @param plano
 * @param setPlano
 * @param reset
 * @returns {*}
 * @constructor
 */



export default PlanoForm
