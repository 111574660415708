import React from "react"
import SvgIcon from "./SvgIcon"

const VisitaPatEndNotResolvidaIcon = (others) => {
  return (
    <SvgIcon viewBox="0 0 512 512" width="512" height="512" {...others}>
      <g>
        <path
          d="m371.244 114.479c0-24.831-20.188-45.031-45.004-45.031-24.814 0-45.003 20.201-45.003 45.031s20.188 45.031 45.003 45.031c24.816.001 45.004-20.2 45.004-45.031zm-45.004 15.032c-8.272 0-15.003-6.743-15.003-15.031s6.73-15.031 15.003-15.031 15.004 6.743 15.004 15.031-6.73 15.031-15.004 15.031z"/>
        <path
          d="m455.496 353.367h-278.118c-14.08 0-25.534-11.472-25.534-25.573s11.454-25.573 25.534-25.573h148.862c5.461 0 9.639-2.658 12.93-7.181l78.874-108.502c58.09-75.501 4.473-186.538-91.803-186.538-94.341 0-150.043 107.818-92.324 185.876l64.057 86.345h-120.596c-30.622 0-55.534 24.93-55.534 55.573s24.912 55.573 55.534 55.573h278.118c14.614 0 26.504 11.907 26.504 26.543s-11.89 26.543-26.504 26.543h-336.368c-6.686-26.154-30.433-45.547-58.623-45.547-33.362-.001-60.505 27.161-60.505 60.547s27.143 60.547 60.505 60.547c28.19 0 51.937-19.394 58.623-45.547h336.368c31.156 0 56.504-25.365 56.504-56.543s-25.348-56.543-56.504-56.543zm-197.574-185.506c-42.554-55.972-2.835-137.861 68.319-137.861 71.611 0 111.066 82.727 67.826 138.503-.126.162-67.07 92.261-67.153 92.375-.075-.1-68.915-92.917-68.992-93.017zm-197.417 314.139c-16.82 0-30.505-13.704-30.505-30.547s13.685-30.547 30.505-30.547 30.505 13.704 30.505 30.547-13.685 30.547-30.505 30.547z"/>
      </g>
    </SvgIcon>
  )
}

export default VisitaPatEndNotResolvidaIcon
