import React from "react"
import Async from "react-async"
import LinearProgress from "@material-ui/core/LinearProgress"
import ErrorMsg from "../../components/SharedComponents/Shared/Error/ErrorMsg"
import { useHistory, useParams } from "react-router"
import { readPat } from "./apiPats"
import Title from "../../components/SharedComponents/Shared/Title"
import patMsgs from "./patMsgs"
import TabsComponent from "../../components/SharedComponents/Shared/TabsComponent"
import ShowPAT from "./ShowPat/ShowPAT"
import ShowCliente from "../../components/SharedComponents/Shared/Show/ShowCliente"
import ShowEquipamento from "../../components/SharedComponents/Shared/Show/ShowEquipamento"
import ShowTecnico from "../../components/SharedComponents/Shared/Show/ShowTecnico"
import TableEstadoPat from "../../components/SharedComponents/Shared/Tables/TableEstadoPat"
import { ShowAcaoPeriodo } from "../../components/SharedComponents/Shared/Show/ShowAcaoPeriodo"
import { ShowPlanoActivo } from "../../components/SharedComponents/Shared/Show/ShowPlanoActiv"
import PecasPat from "./PecasPat/PecasPat"
import EstadosPat from "./EstadosPat_InfoStates/EstadosPat/EstadosPat"
import VisitasPat from "./VisitasPat/VisitasPat"
import { AppMsgs } from "../../constants/appMsgs"
import { PatReportIntroWithPecas } from "./EstadosPat_InfoStates/InfoStates/ConcluirVisita/PatReportIntroWithPecas"


function patParamsCom(asStr = false) {
  const params = [
    {
      rel: 'cliente', title: patMsgs.CLIENTE, hasMany: false, counterField: '', addCom: true, forceHideTab: true,
      component: ({pat, rel}) => <ShowCliente cli={pat[rel].data}/>
    },
    {
      rel: 'equipCli', title: patMsgs.EQUICLI, hasMany: false, counterField: '', addCom: true, forceHideTab: true,
      component: ({pat, rel}) => <ShowEquipamento equip={pat[rel].data}/>
    },
    {
      rel: 'tecnico', title: patMsgs.TECNICO, hasMany: false, counterField: '', addCom: true, forceHideTab: true,
      component: ({pat, rel}) => <ShowTecnico tec={pat[rel].data}/>
    },
    // {rel: 'lastVisita', title: patMsgs.LASTVISITA, hasMany: false, counterField: '', addCom:true,
    //   component: ({pat, rel}) => <ShowVisitaPat visita={pat[rel].data}/>},
    {
      rel: 'lastEstado', title: patMsgs.LASTESTADO, hasMany: false, counterField: '', addCom: true, forceHideTab: true,
      component: ({pat, rel}) => <TableEstadoPat estado={pat[rel].data}/>
    },
    {
      rel: 'planoActiv', title: patMsgs.PLANOACTIV, hasMany: false, counterField: '', addCom: true,
      component: ({pat, rel}) => <ShowPlanoActivo planoActiv={pat[rel].data}/>
    },
    {
      // hasMany: true retirado por causa da edição (ao alterar peças na tabela não faz update da contagem do tab)
      rel: 'pecasPat', title: patMsgs.PECASPAT, hasMany: false, counterField: 'pecas_pat_count', addCom: false, editable: true,
      component: ({pat}) => <PecasPat pat={pat} disable={false}/>
    },
    {
      rel: 'visitas', title: patMsgs.VISITAS, hasMany: true, counterField: 'visitas_count', addCom: true,
      component: ({pat, rel}) => <VisitasPat visitas={pat[rel].data}/>
    },
    {
      rel: 'estados', title: patMsgs.ESTADOS, hasMany: true, counterField: 'estados_count', addCom: true,
      component: ({pat, reload}) => <EstadosPat pat={pat} reload={reload}/>
    },
    {
      rel: 'acaoPeriodo', title: patMsgs.ACAOPERIODO, hasMany: false, counterField: '', addCom: true,
      component: ({pat, rel}) => <ShowAcaoPeriodo acao={pat[rel].data}/>
    },
    {
      rel: '', title: patMsgs.PAT_REPORT, hasMany: false, counterField: '', addCom: false,
      component: ({pat}) => <PatReportIntroWithPecas pat={pat} isPecasDisable={true}/>
    }
    //{rel:'firstVisita', title: patMsgs.TABS.firstVisita, hasCounter:false},
  ]
  if (asStr) {
    let rels = []
    params.forEach(item => {
      if (item.addCom) {
        rels.push(item.rel)
        if (item.rel === 'planoActiv') rels.push('plano')
      }
    })
    return rels.join(',')
  } else return params
}

const PatWithTabsRender = ({pat, reload, handleBack}) => {
  let index = 0
  const lastEstado = (pat['lastEstado'] ? pat['lastEstado'].data : null)
  let tabs = [{
    label: pat.refCode,
    hasMany: false,
    qtd: 1,
    index: index++,
    component: <ShowPAT pat={pat} reload={reload} lastEstado={lastEstado} handleBack={handleBack}/>
  }]

  patParamsCom().forEach(item => {

    const editable = !!item.editable
    const itemsCount = (item.counterField && pat[item.counterField]) || 0
    const forceHideTab = !!item.forceHideTab || (item.hasMany && !editable && !itemsCount)
    const showTab = ((!!pat[item.rel] || !item.addCom) && !forceHideTab)

    const qtd = (
      (!showTab)
        ? 0
        : (
          (!item.hasMany)
            ? 1
            : itemsCount
        )
    )

    if (showTab) {
      tabs.push({
        label: item.title,
        hasMany: item.hasMany,
        qtd: qtd,
        index: index++,
        component: item.component({pat: pat, rel: item.rel, reload})
      })
    }

  })
  return (
    <TabsComponent renderTabs={tabs}/>
  )
}

function PatView() {
  let {id} = useParams()

  const history = useHistory()

  /** ATENÇÃO!! não ligar o goBack a components que já tem o goBack irá criar um ciclo entre os dois**/
  const handleBack = () => history.goBack() /*history.push(getRequest({URLname: patsURLname}).paginatePath())*/

  // TODO passar o resultado de patParamsCom(true) a PatWithTabsRender para
  //      que possa ser passado aos componentes abaixo de maneira a poder fazer
  //      o refresh do pat (será necessário usar um useSate)
  return (
    <Async promiseFn={readPat} id={id} com={patParamsCom(true)}>
      <Async.Pending><LinearProgress color="secondary"/></Async.Pending>
      <Async.Fulfilled>
        {(data, {reload}) => {
          return (
            <React.Fragment>
              <Title handleBack={handleBack}>{AppMsgs.DEFAULT_VALUES.BACK_LABEL}</Title>
              <PatWithTabsRender pat={data} reload={reload} handleBack={handleBack}/>
            </React.Fragment>
          )
        }}
      </Async.Fulfilled>
      <Async.Rejected>{error => <ErrorMsg>{error}</ErrorMsg>}</Async.Rejected>
    </Async>
  )
}

export default PatView
