import React from "react"
import Grid from "@material-ui/core/Grid"
import TableAcao from "../Tables/TblsAcaoTaxasDescontos/TableAcao"
import TableTaxas from "../Tables/TblsAcaoTaxasDescontos/TableTaxas"
import TableTaxasCliente from "../Tables/TblsAcaoTaxasDescontos/TableTaxasCliente"
import TableDescontos from "../Tables/TblsAcaoTaxasDescontos/TableDescontos"

function ShowAcaoPeriodo({acao}) {
  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12}>
        <TableAcao {...acao}/>
      </Grid>
      <Grid item xs={12}>
        <TableTaxas {...acao}/>
      </Grid>
      <Grid item xs={12}>
        <TableTaxasCliente {...acao}/>
      </Grid>
      <Grid item xs={12}>
        <TableDescontos {...acao}/>
      </Grid>
    </Grid>
  )
}

export { ShowAcaoPeriodo }
