import { StyledTableCell, TableMarginTop } from "../../../../../components/SharedStyles/SharedStyles"
import { parseToIntegerPercent, parseToMoney } from "../../../../../constants/taxasConst"
import { IVA } from "../../../../../constants/appConst"
import React from "react"
import { ShowAcaoPeriodo } from "../../../../../components/SharedComponents/Shared/Show/ShowAcaoPeriodo"
import Paper from "@material-ui/core/Paper"
import PecasPat from "../../../PecasPat/PecasPat"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import patInfoStatesMsgs from "../patInfoStatesMsgs"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import LeftMarginSpan from "../../../../../components/SharedStyles/LeftMarginSpan"
import StyledBoldSpan from "../../../../../components/SharedStyles/StyledBoldSpan"
import makeStyles from "@material-ui/core/styles/makeStyles"
import BoolColoredCircleIcon from "../../../../../components/SharedComponents/Shared/BoolColoredCircleIcon"
import { ReenviarPDFPAT } from "../ReenviarPDFPAT/ReenviarPDFPAT"


const useStyles = makeStyles(theme => ({
  strikeText: {
    color: '#888',
    textDecoration: 'line-through',
    marginRight: '1em',
    fontSize: '85%',
  },
}))

function extractPatCosts(pat) {
  const descontoGlobal = parseToMoney(pat.descontoGlobal)
  const percDescPecas = (descontoGlobal > 0 ? descontoGlobal : parseToMoney(pat.percDescPecas))
  const percDescDesloc = (descontoGlobal > 0 ? descontoGlobal : parseToMoney(pat.percDescDesloc))
  const percDescMO = (descontoGlobal > 0 ? descontoGlobal : parseToMoney(pat.percDescMO))

  const totalPecas = parseToMoney(pat.totalPecas)
  const txDeslocCLI = parseToMoney(pat.txDeslocCLI) * (1 + IVA)
  const txMOCLI = parseToMoney(pat.txMOCLI) * (1 + IVA)

  const totalFinalComIVA = (totalPecas * (1 - percDescPecas) + txDeslocCLI * (1 - percDescDesloc) + txMOCLI * (1 - percDescMO))
  return {percDescPecas, percDescDesloc, percDescMO, totalPecas, txDeslocCLI, txMOCLI, totalFinalComIVA}
}

const PatReportIntroWithPecas = ({pat, isPecasDisable, reloadReport}) => {
  const classes = TableMarginTop()
  const myClasses = useStyles()
  const {percDescPecas, percDescDesloc, percDescMO, totalPecas, txDeslocCLI, txMOCLI, totalFinalComIVA} = extractPatCosts(pat)

  return (
    <React.Fragment>
      <ReenviarPDFPAT repo={pat}/>
      <ShowAcaoPeriodo acao={{
        designacao: pat.refCode,
        refAN: '--',
        dataLimite: pat.dataLimite,
        causa: pat.causaPAT,
        tipoPAT: pat.tipoPat,
        txAtivCAT: pat.cat,
        txAtivIMM: pat.imm,
        txPerCAT: '--',
        txPerIMM: '--',
        txDeslocCAT: pat.txDeslocCAT,
        txDeslocIMM: pat.txDeslocIMM,
        txMOCAT: pat.txMOCAT,
        txMOIMM: pat.txMOIMM,
        txMCECAT: '--',
        txMCEIMM: '--',
        percDescDesloc: pat.percDescDesloc,
        percDescMO: pat.percDescMO,
        percDescPecas: pat.percDescPecas,
      }}/>

      <Paper elevation={3} style={{padding: '0 .5em 1em', background: '#f9f9f9'}}>
        <PecasPat pat={pat} disable={isPecasDisable} reloadReport={reloadReport}/>
      </Paper>

      <TableContainer>
        <Table className={classes.table} size="small" aria-label="TableTotais">
          <TableHead>
            <TableRow>
              <StyledTableCell colSpan="2">{patInfoStatesMsgs.TBL_TOTAIS}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key="tot_pecas">
              <TableCell>{patInfoStatesMsgs.TOT_PECAS}</TableCell>
              <TableCell align="right">{parseToMoney(pat.totalPecas, true)}</TableCell>
            </TableRow>
            <TableRow key="tot_pat">
              <TableCell>{patInfoStatesMsgs.TOT_PAT}</TableCell>
              <TableCell align="right">{parseToMoney(pat.totalPAT, true)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer>
        <Table className={classes.table} size="small" aria-label="TableComDescontos">
          <TableHead>
            <TableRow>
              <StyledTableCell colSpan="2">{patInfoStatesMsgs.TBL_TOTAIS_COM_DESCONTOS}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key="tot_pecas_com_desc">
              <TableCell>{patInfoStatesMsgs.TOT_PECAS} ({parseToIntegerPercent(percDescPecas, true)})</TableCell>
              <TableCell align="right">
                {(percDescPecas > 0) && <span className={myClasses.strikeText}>{parseToMoney(totalPecas, true)}</span>}
                <LeftMarginSpan>{parseToMoney(totalPecas * (1 - percDescPecas), true)}</LeftMarginSpan>
              </TableCell>
            </TableRow>
            <TableRow key="tot_desloc_com_desc">
              <TableCell>{patInfoStatesMsgs.TOT_DESLOCACAO} ({parseToIntegerPercent(percDescDesloc, true)})</TableCell>
              <TableCell align="right">
                {(percDescDesloc > 0) && <span className={myClasses.strikeText}>{parseToMoney(txDeslocCLI, true)}</span>}
                <LeftMarginSpan>{parseToMoney(txDeslocCLI * (1 - percDescDesloc), true)}</LeftMarginSpan>
              </TableCell>
            </TableRow>
            <TableRow key="tot_no_com_desc">
              <TableCell>{patInfoStatesMsgs.TOT_MO} ({parseToIntegerPercent(percDescMO, true)})</TableCell>
              <TableCell align="right">
                {(percDescMO > 0) && <span className={myClasses.strikeText}>{parseToMoney(txMOCLI, true)}</span>}
                <LeftMarginSpan>{parseToMoney(txMOCLI * (1 - percDescMO), true)}</LeftMarginSpan>
              </TableCell>
            </TableRow>
            <TableRow key="tot_final">
              <TableCell align="right" colSpan="2">
                <LeftMarginSpan>
                  <StyledBoldSpan style={{color: 'rgb(164,85,0)', fontSize: '16px'}}>{parseToMoney(totalFinalComIVA, true)}</StyledBoldSpan>
                </LeftMarginSpan>
              </TableCell>
            </TableRow>

            <TableRow key="table_flags">
              <StyledTableCell align="left">
                {patInfoStatesMsgs.SWITCH_PAGO_TITLE}
                <LeftMarginSpan><BoolColoredCircleIcon flagTF={!!pat.pago} size={14}/></LeftMarginSpan>
              </StyledTableCell>
              <StyledTableCell align="left">
                {patInfoStatesMsgs.SWITCH_A_FATURAR_TITLE}
                <LeftMarginSpan><BoolColoredCircleIcon flagTF={!!pat.isAFaturar} size={14}/></LeftMarginSpan>
              </StyledTableCell>
            </TableRow>


          </TableBody>
        </Table>
      </TableContainer>

    </React.Fragment>
  )
}

export { PatReportIntroWithPecas, extractPatCosts }
