import makeStyles from "@material-ui/core/styles/makeStyles"
import React from "react"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import Typography from "@material-ui/core/Typography"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import GridAvisosAcoes from "../../../components/SharedComponents/Shared/Tables/TblsAcaoTaxasDescontos/GridAvisosAcoes"
import planosAplicaveisMsgs from "../msgsPlanosAplicaveis"
import KeyArrowDownIcon from "../../../components/Svg-Icons/KeyArrowDownIcon"

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    //fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: theme.palette.secondary.main
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}))


function ExpandPanelPA({periodosPA}) {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <div className={classes.root}>
      {periodosPA.map((periodo, key) => {
        const acoes = periodo.acoes
        const avisos = periodo.avisos
        return (
          (!!acoes.length || !!avisos.length) && (<ExpansionPanel TransitionProps={{unmountOnExit: true}} key={key}
                                                                  expanded={expanded === `panel${periodo.periodoPA.ord}`}
                                                                  onChange={handleChange(`panel${periodo.periodoPA.ord}`)}>
            <ExpansionPanelSummary expandIcon={<KeyArrowDownIcon/>}>
              <Typography
                className={classes.heading}>{planosAplicaveisMsgs.PERIODO_LABEL} #{periodo.periodoPA.ord}</Typography>
              <Typography
                className={classes.secondaryHeading}>[{periodo.periodoPA.dataInic} - {periodo.periodoPA.dataFim}]
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <GridAvisosAcoes acoes={acoes} avisos={avisos}/>
            </ExpansionPanelDetails>
          </ExpansionPanel>)
          /*:
            (
              <React.Fragment>
                <Typography
                  className={classes.heading}>{planosAplicaveisMsgs.PERIODO_LABEL} #{periodo.periodoPA.ord}</Typography>
                <Typography
                  className={classes.secondaryHeading}>[{periodo.periodoPA.dataInic} - {periodo.periodoPA.dataFim}]
                </Typography>
              </React.Fragment>
            )*/
        )
      })}
    </div>
  )
}

export default ExpandPanelPA
