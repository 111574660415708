const msgsPlanos = {
  MENU_LABEL: "Planos",
  LABEL: "Planos",
  UPDATE_LABEL: "Editar Plano",
  CREATE_LABEL: "Novo Plano",
  BACK_LABEL: "Voltar aos Planos",
  OPEN_BUTTON: "Abrir Plano",
  TABLE: {
    HEADER: {
      REV: "Revisão",
      REF_AN: "AN/Rev.",
      DESIGNACAO: "Designacao",
      TIPO: "Tipo",
      ESTADO: "Estado",
      PLANOS_ATIVOS: "Planos Ativos"
    },
    ADD_BUTTON: "Adicionar Plano"
  },
  PERIODICIDADES: {
    NA: "Não Aplicar",
    D: "Por defeito (derivado de PMP)",
    M: "Mensal",
    T: "Trimestral",
    S: "Semestral",
    A: "Anual"
  },
  EDIT: {
    LABEL: {
      REFAN: "Referência",
      DESIGNACAO: "Designação",
      TIPO_EQUIP: "Tipo de Equipamento",
      TIPO_PLANO: "Tipo de Plano",
      DUR_CLI_DOM: "Duração Cliente Domestico (meses)",
      DUR_CLI_INST: "Duração Cliente Institucional (meses)",
      HORAS_RESPOSTA: "Horas para Resposta (H)",
      GERAPA: "Gera PAT Arranque",
      PERIOD_PMP: "Períodicidade de Manutenção Programada",
      PERIOD_AVISOS: "Períodicidade de Aviso",
      TXATIVCAT: "Taxa de Ativação CAT",
      TXATIVIMM: "Taxa de Ativação IMM",
      TXDESLOCCAT: "Taxa Deslocação CAT",
      TXDESLOCIMM: "Taxa Deslocação IMM",
      TXPERCCAT: "Taxa periódica CAT",
      TXPERCIMM: "Taxa periódica IMM",
      TXMOCAT: "Taxa Mão Obra CAT",
      TXMOIMM: "Taxa Mão Obra IMM",
      TXPMCECAT: "Taxa PMCE CAT",
      TXPMCEIMM: "Taxa PMCE IMM",
      PER_DESC_PECAS: "% Desc. Peças",
      PER_DESC_DESLOC: "% Desc. Deslocação",
      PER_DESC_MO: "% Desc. Mão Obra",
      OBS: "Observações"
    }
  },
  BUTTON: {
    EDIT_PLANO: "Atualizar Plano"
  },
  ESTADOS: {
    LABEL: "Estado",
    ATIVO: "ATIVO",
    DESATIVO: "DESATIVADO"
  }
}

export default msgsPlanos
