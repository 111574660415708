import { api, apiCUD, apiReadTable, getRequest } from "../../../api/api"
import ClienteIcon from "../../../components/Svg-Icons/ClienteIcon"
import ClienteView from "./ClienteView"
import msgsClientes from "./msgsClientes"
import Clientes from "./Clientes"

const clientesUrl = 'clientes'

// ----------------------- CLIENTES ROUTES -----------------------
const clientesRouteProps = {
  path: getRequest({URLname: clientesUrl}).paginatePath(),
  exact: true,
  name: msgsClientes.LABEL,
  component: Clientes,
  Icon: ClienteIcon,
  breadcrumb: () => msgsClientes.LABEL,
  reqRole: 'R'
}

const clienteViewRouteProps = {
  path: getRequest({URLname: clientesUrl}).viewPath(),
  exact: true,
  name: msgsClientes.UPDATE_LABEL,
  component: ClienteView,
  breadcrumb: (props) => !!parseInt(props.match.params.id, 10) ? msgsClientes.UPDATE_LABEL : msgsClientes.CREATE_LABEL,
  reqRole: 'R'
}

// ----------------------- CLIENTES ROUTES -----------------------

/**
 * Read Clientes com paginação para tabela
 * @param query
 * @returns {Promise<*|void>}
 */
async function readClientesTable([query]) {
  const request = getRequest({URLname: clientesUrl, query}).GET()
  return apiReadTable(request)
}

/**
 * Read Clientes com paginação para tabela
 * @param query
 * @param id
 * @returns {Promise<*|void>}
 */
async function readClienteEquips([query], id) {
  const request = getRequest({
    URLname: clientesUrl, id,
    query: {...query, com: "marca, modelo, tipoequip"}
  }).GET('equipamentos')
  return apiReadTable(request)
}

/**
 * Read Cliente
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
function readCliente({id}) {
  const request = getRequest({URLname: clientesUrl, id}).GET()
  return api(request).then(r => r.data.data)
}

function createCliente([data]) {
  const request = getRequest({URLname: clientesUrl}).POST()
  return apiCUD(request, data).then(r => r.data)
}

function updateCliente([data]) {
  const request = getRequest({URLname: clientesUrl, id: data.id}).PUT()
  return apiCUD(request, data).then(r => r.data)
}

async function deleteCliente(id) {
  const request = getRequest({URLname: clientesUrl, id}).DELETE()
  return apiCUD(request)
}

async function undeleteCliente(id) {
  const request = getRequest({URLname: clientesUrl, id}).PUT('undelete')
  return apiCUD(request)
}

export {
  clientesUrl,
  clientesRouteProps,
  clienteViewRouteProps,
  readClientesTable,
  readClienteEquips,
  readCliente,
  createCliente,
  updateCliente,
  deleteCliente,
  undeleteCliente
}
