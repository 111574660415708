import React from "react"
import Async from "react-async"
import LinearProgress from "@material-ui/core/LinearProgress"
import ErrorMsg from "../../../components/SharedComponents/Shared/Error/ErrorMsg"
import InstaladorForm from "./InstaladorForm"
import { readInstalador, updateInstalador } from "./apiInstaladores"

function InstaladorUpdate({id}) {
  return (
    <Async promiseFn={readInstalador} id={id}>
      <Async.Pending><LinearProgress color="secondary"/></Async.Pending>
      <Async.Fulfilled>
        {(data, {setData}) => {
          return (
            <React.Fragment>
              <InstaladorForm instalador={data} method={updateInstalador} setInstalador={setData}/>
            </React.Fragment>
          )
        }}
      </Async.Fulfilled>
      <Async.Rejected>{error => <ErrorMsg>{error}</ErrorMsg>}</Async.Rejected>
    </Async>
  )
}

export default InstaladorUpdate
