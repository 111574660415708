import React from "react"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import { errorColorDark } from "../../../constants/appTheme"
import armazemMsgs from "../armazemMsgs"
import clsx from "clsx"
import DeleteIcon from "../../../components/Svg-Icons/DeleteIcon"
import IconButton from "@material-ui/core/IconButton"
import armazemINStyles from "./old/armazemINStyles"


function EntradasList({entradas, reload}) {
  const classes = armazemINStyles()

  const onRemRow = () => {

  }

  return (
    <TableContainer>
      <Table size="small" aria-label="tableInArmazem">
        <TableHead>
          <TableRow>
            <TableCell className={clsx(classes.cell, classes.cellHead)}>{armazemMsgs.NUM_SERIE}</TableCell>
            <TableCell className={clsx(classes.cell, classes.cellHead)}>{armazemMsgs.MARCA}</TableCell>
            <TableCell className={clsx(classes.cell, classes.cellHead)}>{armazemMsgs.EQUIP}</TableCell>
            <TableCell className={clsx(classes.cell, classes.cellHead)}>{armazemMsgs.FAT_COMPRA}</TableCell>
            <TableCell className={clsx(classes.cell, classes.cellHead)}>{armazemMsgs.TRANSPORT}</TableCell>
            <TableCell className={clsx(classes.cell, classes.cellHead)}>{armazemMsgs.FAT_FRETE}</TableCell>
            <TableCell className={clsx(classes.cell, classes.cellHead)}>{armazemMsgs.DATA_HORA}</TableCell>
            <TableCell className={clsx(classes.cell, classes.cellHead)}> </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key="tblReg">
            <TableCell className={classes.cell}>...</TableCell>
            <TableCell className={classes.cell}>...</TableCell>
            <TableCell className={classes.cell}>...</TableCell>
            <TableCell className={classes.cell}>...</TableCell>
            <TableCell className={classes.cell}>...</TableCell>
            <TableCell className={classes.cell}>...</TableCell>
            <TableCell className={classes.cell}>...</TableCell>
            <TableCell className={classes.cell}>
              <IconButton style={{padding: "3px"}}
                          onClick={onRemRow}>
                <DeleteIcon color={errorColorDark}/>
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default EntradasList
