import React from "react"
import { useHistory, useParams } from "react-router"
import Title from "../../../components/SharedComponents/Shared/Title"
import { getRequest } from "../../../api/api"
import { planosAtivadosUrl, readPlanoAtivado } from "./apiPlanosAtivados"
import msgsPlanosAtivados from "./msgsPlanosAtivados"
import Grid from "@material-ui/core/Grid"
import Async from "react-async"
import LinearProgress from "@material-ui/core/LinearProgress"
import ErrorMsg from "../../../components/SharedComponents/Shared/Error/ErrorMsg"
import { GridPlanoAtivo } from "../../PlanosAplicaveis/partials/GridPlanoAtivo"
import { DesativarRemoverPAForm } from "./partials/DesativarRemoverPAForm"
import msgsEquips from "../../Equips/ListEquips/msgsEquips"
import TabsComponent from "../../../components/SharedComponents/Shared/TabsComponent"
import PatsPlanoAtivado from "../../Pats/X_PatsPorEntidades/PatsPlanoAtivado"


function PlanoAtivoDetail({handleBack, planoAtivado}) {
  return (
    <Grid container spacing={2} justify="center">

      <GridPlanoAtivo {...planoAtivado} simulacao={false}/>

      <Grid item sm={12}>
        <DesativarRemoverPAForm pa={planoAtivado} handleBack={handleBack}/>
      </Grid>
    </Grid>
  )
}

const PlanoAtivadoCustomTabs = ({planoAtivado, handleBack}) => {

  const plano = planoAtivado.plano && planoAtivado.plano.data
  const paNome = `${plano.refAN} - ${plano.designacao} (Rev. ${plano.rev})`

  let index = 0
  const tabs = [{
    label: paNome,
    index: index++,
    component: <PlanoAtivoDetail planoAtivado={planoAtivado} handleBack={handleBack}/>
  }, {
    label: msgsEquips.TABS.PAT,
    index: index++,
    showTab: true,
    component: <PatsPlanoAtivado paID={planoAtivado.id}/>,
    hasMany: !!planoAtivado.pats_count,
    qtd: parseInt(planoAtivado.pats_count, 10)
  }]

  return (
    <TabsComponent renderTabs={tabs}/>
  )
}

function PlanoAtivadoView() {
  let {id} = useParams()

  const history = useHistory()
  const handleBack = () => history.push(getRequest({URLname: planosAtivadosUrl}).paginatePath())

  return (
    <React.Fragment>
      <Title handleBack={handleBack}>{msgsPlanosAtivados.BACK_LABEL}</Title>
      <Async promiseFn={readPlanoAtivado} id={id}>
        <Async.Pending><LinearProgress color="secondary"/></Async.Pending>
        <Async.Fulfilled>
          {(data) => {
            return (
              <React.Fragment>
                <PlanoAtivadoCustomTabs planoAtivado={data} handleBack={handleBack}/>
              </React.Fragment>
            )
          }}
        </Async.Fulfilled>
        <Async.Rejected>{error => <ErrorMsg>{error}</ErrorMsg>}</Async.Rejected>
      </Async>
    </React.Fragment>
  )
}

export default PlanoAtivadoView
