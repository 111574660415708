import * as Yup from "yup"
import { string } from "yup"
import { AppMsgs } from "./appMsgs"


const _lengthValidation = (length, field) => {
  return Yup.string().length(length, AppMsgs.ERROR.EXACT_LEN(length, field))
}

const _minValidation = (min, field) => {
  return Yup.number().typeError(AppMsgs.ERROR.NUMBER(field)).min(min, AppMsgs.ERROR.MIN_NUMBER(min, field))
}

const numberValidation = (field) => {
  return Yup.number().typeError(AppMsgs.ERROR.NUMBER(field))
}

const _maxValidation = (max, field) => {
  return Yup.number()
    .typeError(AppMsgs.ERROR.NUMBER(field))
    .max(max, AppMsgs.ERROR.MAX_NUMBER(max, field))
}

const _emailValidation = (field) => {
  return Yup.string()
    .email(AppMsgs.ERROR.EMAIL)
    .max(200, AppMsgs.ERROR.MAX_LEN(200, field))
}

const requiredValidation = (field) => {
  return Yup.string().required(AppMsgs.ERROR.REQ(field))
}

const requiredHTMLEditorValidation = (field) => {
  return Yup.mixed().test({
    name: 'not-empty',
    exclusive: true,
    params: {},
    message: AppMsgs.ERROR.REQ(field),
    test: value => (value || '').replace(/(<([^>]+)>)/ig, "") !== '',
  }).required(AppMsgs.ERROR.REQ(field))

}

const requiredDateValidation = (field) => {
  return Yup.date().typeError(AppMsgs.ERROR.DATE(field)).required(AppMsgs.ERROR.REQ(field)).nullable()
}

const emailReqValidation = (field) => {
  return Yup.mixed()
    .concat(requiredValidation(field))
    .concat(_emailValidation())
}

const emailValidation = () => {
  return Yup.mixed()
    .concat(_emailValidation())
}

const refANValidation = (length, field) => {
  return Yup.mixed()
    .concat(requiredValidation(field))
    .concat(_lengthValidation(length, field))
}

const minNumberValidation = (min, field) => {
  return _minValidation(min, field)
}

const betweenNumberValidation = (min, max, field) => {
  return _minValidation(min, field)
    .concat(_maxValidation(max, field))
}

const selectValidation = (field) => {
  return Yup.object({
    index: string(),
    title: string()
  }).typeError(AppMsgs.ERROR.REQ(field))
}


const nifValidation = (field) => {
  return Yup.string().matches(/^[1-9][0-9]{8}$/, {message: AppMsgs.ERROR.INV(field), excludeEmptyString: true})
}
const contactValidation = (email, tlm, tlf) => {
  return Yup.bool().when(['email', 'tlm', 'tlf'], {
    is: (!email && !tlm && !tlf) /*|| (!!email && !!tlm && !!tlf)*/,
    then: Yup.bool().required(AppMsgs.ERROR.CONTACT_REQUIRED),
    otherwise: Yup.bool()
  })
}

const tlfValidation = (field) => {
  return Yup.string().matches(/^2[1-9][0-9]{7}$/, {message: AppMsgs.ERROR.INV(field), excludeEmptyString: true})
}

const tlmValidation = (field) => {
  return Yup.string().matches(/^9[1236][0-9]{7}$/, {message: AppMsgs.ERROR.INV(field), excludeEmptyString: true})
}

const cp4Validation = (field) => {
  return Yup.string().matches(/^[1-9][0-9]{3}$/, {message: AppMsgs.ERROR.INV(field), excludeEmptyString: true})
}

const cp3Validation = (field) => {
  return Yup.string().matches(/^[0-9][0-9]{2}$/, {message: AppMsgs.ERROR.INV(field), excludeEmptyString: true})
}

const avalImmValidation = (min, max, field) => {
  return Yup.number().typeError(AppMsgs.ERROR.NUMBER(field)).min(min, AppMsgs.ERROR.MIN_NUMBER(min, field)).max(max, AppMsgs.ERROR.MAX_NUMBER(max, field))
}

const fileValidation = (file, fileMaxSize, acceptedFileTypesArray) => {
  if (file) {
    const currentFileType = file.type
    const currentFileSize = file.size
    if (currentFileSize > fileMaxSize) {
      return {
        isValid: false,
        msg: AppMsgs.ERROR.INVALID_SIZE
      }
    }
    if (!acceptedFileTypesArray.includes(currentFileType)) {
      return {
        isValid: false,
        msg: AppMsgs.ERROR.INVALID_FORMAT
      }
    }
    return {isValid: true}
  }
  return {
    isValid: false,
    msg: AppMsgs.ERROR.INVALID_FILE
  }
}

const base64Validation = (base64Str, head = 'data:image/png;base64') => {
  try {
    const b64 = base64Str.trim()
    const splited = b64.split(',')
    return b64 !== ""
      && head === splited[0]
      && `${splited[0]},` + btoa(atob(splited[1])) === b64

  } catch (err) {
    return false
  }
}

export {
  minNumberValidation,
  betweenNumberValidation,
  refANValidation,
  emailReqValidation,
  emailValidation,
  requiredValidation,
  selectValidation,
  nifValidation,
  tlfValidation,
  tlmValidation,
  cp4Validation,
  cp3Validation,
  contactValidation,
  avalImmValidation,
  numberValidation,
  requiredDateValidation,
  fileValidation,
  base64Validation,
  requiredHTMLEditorValidation
}
