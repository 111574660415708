import React from 'react'
import List from "@material-ui/core/List"
import { leftMenu } from "../../../../constants/appRoutes"
import LeftMenubarItem from "./LeftMenubarItem"
import styled from "@material-ui/core/styles/styled"


const Nav = styled(List)({
  width: '100%'
})

const LeftMenubar = () => {

  return (
    <Nav component="nav" disablepadding="true">
      {leftMenu.map((item, index) => (
        <LeftMenubarItem {...item} key={index}/>
      ))}
    </Nav>
  )
}

export default LeftMenubar
