import React from "react"
import Async from "react-async"
import LinearProgress from "@material-ui/core/LinearProgress"
import ErrorMsg from "../../../components/SharedComponents/Shared/Error/ErrorMsg"
import { readPecasPatAndAllPecas } from "./apiPecasPats"
import { PecasPatGest } from "./PecasPatGest"
import { useUser } from "../../../context/user-context"

const PecasPat = ({pat, disable, reloadReport}) => {
  const {u} = useUser()

  return (
    <Async promiseFn={readPecasPatAndAllPecas} pat={pat}>
      <Async.Pending><LinearProgress color="secondary"/></Async.Pending>
      <Async.Fulfilled>
        {({pecasPat, allPecas}) => {

          const enabled = !disable
            && ((pat.mayEditTec && u.mayActAs.tec) || (pat.mayEditGest && u.mayActAs.gest))

          return (
            <React.Fragment>
              <PecasPatGest pat={pat} pecas={pecasPat} allPecas={allPecas} disable={!enabled} reloadReport={reloadReport}/>
            </React.Fragment>
          )
        }}
      </Async.Fulfilled>
      <Async.Rejected>{error => <ErrorMsg>{error}</ErrorMsg>}</Async.Rejected>
    </Async>
  )
}

export default PecasPat
