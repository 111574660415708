import React, { useState } from "react"
import { useAsync } from "react-async"
import { FormContext, useForm } from "react-hook-form"
import { Form, LinkButton } from "../../../components/SharedStyles/SharedStyles"
import Grid from "@material-ui/core/Grid"
import LFInput from "../../../components/LFInputs/LFInput"
import { AppMsgs } from "../../../constants/appMsgs"
import * as Yup from "yup"
import { emailReqValidation, requiredValidation } from "../../../constants/appValidation"
import { LFEmailInput } from "../../../components/LFInputs/LFEmailInput"
import { LFSwitchInput } from "../../../components/LFInputs/LFSwitchInput"
import LFImageInput from "../../../components/LFInputs/ImageUploader/LFImageInput"
import { NO_IMAGE } from "../../../constants/appConst"
import FormHelperText from "@material-ui/core/FormHelperText"
import LFSubmitButton from "../../../components/SharedComponents/Shared/LFButtons/LFSubmitButton"
import { LFPasswordInput } from "../../../components/LFInputs/LFPasswordInput"
import msgsUsers from "./msgsUsers"


const validationSchema = Yup.object().shape({
  name: requiredValidation(AppMsgs.ENTIDADES.EDIT.LABEL.NOME),
  email: emailReqValidation(AppMsgs.ENTIDADES.EDIT.LABEL.EMAIL),
  // password: requiredValidation(AppMsgs.DEFAULT_VALUES.INPUT.PASS_LBL)
})


const defaultValues = (user) => {
  return {
    avatar: user ? user.avatar : NO_IMAGE,
    name: user ? user.name : '',
    email: user ? user.email : '',
    mustReceiveMailOnLogin: user ? !!user.mustReceiveMailOnLogin : false,
    isContaAcessoActiva: user ? !!user.isContaAcessoActiva : false
  }
}

function UserEditForm({user, method, setUser}) {
  const [showPassord, setShowPassword] = useState(false)

  const {isPending, run} = useAsync({
    deferFn: method,
    onResolve: (newUser) => {
      setUser(newUser)
      reset(defaultValues(newUser))
    }
  })

  const methods = useForm({
    validationSchema: validationSchema,
    defaultValues: defaultValues(user)
  })

  const {handleSubmit, formState, control, reset} = methods

  const onSubmit = (values) => {
    if (!values.password)
      delete values.password  // retira passord do objecto caso esta esteja vazia

    run({...user, ...values})
  }

  return (
    <FormContext {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} justify="center">
          {/*  ------------------------------------------- AVATAR ---------------------------------------------------*/}
          <Grid item xs={12} sm={6}>
            <LFImageInput image={user ? user.avatar : NO_IMAGE} control={control} name="avatar"/>
          </Grid>
          {/*  ------------------------------------------- SWITCH ---------------------------------------------------*/}
          <Grid item xs={12} sm={6}>
            <FormHelperText>{AppMsgs.DEFAULT_VALUES.SWITCH_TITLE}</FormHelperText>
            {/*  -------------------------------------- CONTA ACESSO ATIVA ------------------------------------------*/}
            <Grid item xs={12}>
              <LFSwitchInput control={control} name='isContaAcessoActiva'
                             label={msgsUsers.EDIT.LABEL.IS_CONTA_ACESSO_ACTIVA}/>
            </Grid>
            {/*  ---------------------------------------- MAIL_ON_LOGIN ---------------------------------------------*/}
            <Grid item xs={12}>
              <LFSwitchInput control={control} name='mustReceiveMailOnLogin'
                             label={msgsUsers.EDIT.LABEL.MUST_RECEIVE_EMAIL}/>
            </Grid>
          </Grid>
          {/*------------------------------------------- NOME -------------------------------------------------------*/}
          <Grid item xs={12} sm={6}>
            <LFInput label={AppMsgs.ENTIDADES.EDIT.LABEL.NOME} name='name'/>
          </Grid>
          {/*------------------------------------------ EMAIL -------------------------------------------------------*/}
          <Grid item xs={12} sm={6}>
            <LFEmailInput label={AppMsgs.ENTIDADES.EDIT.LABEL.EMAIL} name='email'/>
          </Grid>
          {!showPassord &&
          <Grid container alignItems="flex-start" justify="flex-start" direction="row">
            <LinkButton color="primary" onClick={() => setShowPassword(true)}>
              {msgsUsers.EDIT.LABEL.SHOW_PASSWORD_FIELD}
            </LinkButton>
          </Grid>
          }
          {showPassord &&
          <Grid item xs={12}>
            <LFPasswordInput label={msgsUsers.EDIT.LABEL.NOVA_PASSWORD} name='password'/>
          </Grid>}
        </Grid>
        <LFSubmitButton isLoading={isPending} dirty={!formState.dirty}>
          {user ? msgsUsers.BUTTON.EDIT_USER : msgsUsers.BUTTON.NEW_USER}
        </LFSubmitButton>
      </Form>
    </FormContext>
  )
}


export default UserEditForm
