import styled from "@material-ui/core/styles/styled"
import { AppMsgs } from "../../../constants/appMsgs"
import React, { useEffect, useState } from "react"
import { ErrorMessage, useFormContext } from "react-hook-form"
import { useDropzone } from "react-dropzone"
import InputError from "../../SharedComponents/Shared/Error/InputError"
import PropTypes from "prop-types"
import Typography from "@material-ui/core/Typography"
import { fileValidation } from "../../../constants/appValidation"
import { Label, Text } from "../../SharedStyles/SharedStyles"

// Styles

const Image = styled('img')({
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '100%',
  maxWidth: '200px',
  height: '200px',
  objectFit: 'scale-down',
  borderRadius: '10px'
})


// Validation
const imageDefaultMaxSize = 1024 * 1024 * 1.5
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image&/gif'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {
  return item.trim()
})

const ImageUploader = ({image, name, label, maxSize}) => {
  const [preview, setPreview] = useState(image)
  const {register, errors, setValue, setError, clearError} = useFormContext()
  const imgMaxSize = parseInt(maxSize, 10) || imageDefaultMaxSize

  useEffect(() => {
    if (image)
      setPreview(image)
  }, [image])

  const onDrop = React.useCallback(acceptedFile => {
    const imgFile = acceptedFile ? acceptedFile[0] : null
    const fileValid = fileValidation(imgFile, imgMaxSize, acceptedFileTypesArray)
    if (fileValid.isValid) {
      clearError(name)
      const preview = imgFile ? URL.createObjectURL(imgFile) : null
      setPreview(preview)
      const reader = new FileReader()
      reader.readAsDataURL(imgFile)
      reader.onloadend = (evt) => {
        setValue(name, evt.target.result)
      }
    } else {
      setError(name, "notMatch", fileValid.msg)
    }
  }, [setValue, setError, name, imgMaxSize, clearError])

  const {getRootProps, getInputProps} = useDropzone({onDrop})

  return (
    <React.Fragment>
      <Typography component="legend" style={{textAlign: "center", marginBottom: '5px'}}>{label}</Typography>
      <Image src={preview} alt="preview"/>
      <div {...getRootProps()}>
        <input name="image" ref={register} {...getInputProps()} />
        <Text>
          <Label>{AppMsgs.DEFAULT_VALUES.INPUT.IMAGE_LBL}</Label>
        </Text>
        <ErrorMessage errors={errors} name={name} as={<InputError/>}/>
      </div>
    </React.Fragment>
  )
}

ImageUploader.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
}

export default ImageUploader
