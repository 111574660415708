import React, { useEffect, useState } from "react"
import MainToolbar from "./MainToolbar/MainToolbar"
import LeftMenubarContainer from "./LeftMenubar/LeftMenubarContainer"


function StructureLayout() {
  const [leftNavBarOpenState, setLeftNavBarOpenState] = useState(true)
  const [[windowWidth, windowHeight], setWindowSize] = useState([window.innerWidth, window.innerHeight])
  const openLeftNavBar = React.useCallback(() => setLeftNavBarOpenState(true), [])
  const closeLeftNavBar = React.useCallback(() => setLeftNavBarOpenState(false), [])

  useEffect(() => {
    const handleResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (windowWidth <= 960 && leftNavBarOpenState)
      setLeftNavBarOpenState(false)
    if (windowWidth > 1200 && !leftNavBarOpenState)
      setLeftNavBarOpenState(true)
  }, [leftNavBarOpenState, windowWidth, windowHeight])

  return (
    <React.Fragment>
      <MainToolbar leftNavBarOpenState={leftNavBarOpenState}
                   openLeftNavBarHandler={openLeftNavBar}/>

      <LeftMenubarContainer leftNavBarOpenState={leftNavBarOpenState}
                            closeLeftNavBarHandler={closeLeftNavBar}/>
    </React.Fragment>
  )
}

export default StructureLayout
