import React from "react"
import { mainRoutes } from "../../../constants/appRoutes"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import styled from "@material-ui/core/styles/styled"
import { makeStyles } from "@material-ui/core"
import Routes from "../../../utils/routes"


//Styles
const Main = styled('main')({
  flexGrow: 1,
  height: '100vh',
  overflow: 'auto',
})
const ContainerLayout = styled(Container)(({theme}) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}))
const PaperLayout = styled(Paper)(({theme}) => ({
  padding: theme.spacing(2),
  display: 'flex',
  overflow: 'auto',
  flexDirection: 'column',
}))
const styles = makeStyles(theme => ({
  appBarSpacer: theme.mixins.toolbar
}))

const MainContainer = () => {
  const classes = styles()
  return (
    <Main>
      <div className={classes.appBarSpacer}/>
      <ContainerLayout maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PaperLayout>
              <Routes routes={mainRoutes} render={'component'}/>
            </PaperLayout>
          </Grid>
        </Grid>
      </ContainerLayout>
    </Main>
  )
}

export default MainContainer
