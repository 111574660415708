import { apiReadTable, getRequest } from "../../../api/api"
import msgTiposPlano from "./msgTiposPlano"
import ListTiposPlano from "./ListTiposPlano"


const tiposPlanoURL = "tipos-plano"

// ----------------------- PATS ROUTES -----------------------
const tiposPlanoRouteProps = {
  path: getRequest({URLname: tiposPlanoURL}).paginatePath(),
  exact: true,
  name: msgTiposPlano.LABEL,
  component: ListTiposPlano,
  IconText: 'TP',
  breadcrumb: () => msgTiposPlano.LABEL,
  reqRole: 'R'
}

// ----------------------- PATS ROUTES -----------------------

/**
 * Read Tipos Plano com paginação para tabela
 * @param query
 * @returns {Promise<*|void>}
 */
async function readTiposPlanoTable([query]) {
  const request = getRequest({URLname: tiposPlanoURL, query: {...query}}).GET()
  return apiReadTable(request)
}

export { tiposPlanoRouteProps, readTiposPlanoTable }
