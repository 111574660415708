import Home from "./Home"
import msgHome from "./msgsHome"

const homeUrl = '/'

// ----------------------- USERS ROUTES -----------------------
const homeProps = {
  path: homeUrl,
  exact: false,
  name: msgHome.LABEL,
  component: Home
}
// ----------------------- USERS ROUTES -----------------------

export { homeUrl, homeProps }
