import React from "react"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import { PropTypes } from 'prop-types'
import withStyles from "@material-ui/core/styles/withStyles"
import { secondaryColor } from "../../../constants/appTheme"
import { Badge } from "../../SharedStyles/SharedStyles"
import styled from "@material-ui/core/styles/styled"

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: secondaryColor,
  },
})(Tabs)

const AntTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: secondaryColor,
      opacity: 1,
    },
    '&$selected': {
      color: secondaryColor,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: secondaryColor,
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />)

const TabBI = styled(Badge)(() => ({
  widht: '22px',
  height: '22px',
  fontSize: '11px',
  fontWeight: '900',
  position: 'absolute',
  top: '0',
  right: '0'
}))

function TabPanel(props) {
  const {children, value, index, ...other} = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={{paddingTop: '10px'}}>{children}</Box>}
    </Typography>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const TabBadge = ({qtd, label}) => {
  return (
    <React.Fragment>
      <TabBI>{qtd}</TabBI>
      <span style={{fontSize: '0.8rem'}}>{label}</span>
    </React.Fragment>)
}

function TabsComponent(props) {

  const {renderTabs} = props
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <React.Fragment>
      <AppBar position="static" style={{boxShadow: 'none', backgroundColor: 'white'}}>
        <AntTabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs"
        >
          {renderTabs.map((values) => {
            return (<AntTab
              key={values.index}
              label={values.hasMany ? <TabBadge {...values} /> : values.label}
              {...a11yProps(values.index)}
            />)
          })}
        </AntTabs>
      </AppBar>
      <div style={{margin: '.8em 0 0'}}>
      </div>
      {renderTabs.map((values) => {
        return (
          <TabPanel key={values.index} value={value} index={values.index}>
            {values.component}
          </TabPanel>
        )
      })}
    </React.Fragment>
  )
}

TabsComponent.propTypes = {
  renderTabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.element
      ]).isRequired,
      index: PropTypes.number.isRequired,
      component: PropTypes.element.isRequired
    })
  ).isRequired
}

export default TabsComponent
