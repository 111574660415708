import React, { createContext, useCallback, useMemo, useState } from "react"
import ModeloCreate from "./ModeloCreate"
import ModeloList from "./ModeloList"
import ModeloUpdate from "./ModeloUpdate"

export const RedirectState = createContext(null)

function Modelos({id}) {
  const [state, setState] = useState({})

  const list = useCallback(() => setState({}), [])
  const create = useCallback(() => setState({type: 'C'}), [])
  const update = useCallback((newState) => setState({...newState, type: 'U'}), [])

  const getState = useMemo(() => ({list, create, update}), [list, create, update])

  return (
    <RedirectState.Provider value={getState}>
      {(state.type === 'C')
        ? <ModeloCreate marcaID={id}/>
        : (state.type === 'U')
          ? <ModeloUpdate marcaID={id} state={state}/>
          : <ModeloList marcaID={id}/>
      }
    </RedirectState.Provider>
  )
}

export default Modelos
