import React, { useContext } from "react"
import Title from "../../../components/SharedComponents/Shared/Title"
import TiposEquipForm from "./TiposEquipForm"
import { updateTipoEquip } from "./apiTiposEquip"
import { RedirectContext } from "./TiposEquip"
import tiposequipMsgs from "./msgs"

function TiposEquipUpdate({state}) {

  const {list} = useContext(RedirectContext)

  const handleBack = () => list()

  return (
    <React.Fragment>
      <Title handleBack={handleBack}>{tiposequipMsgs.BACK_LABEL}</Title>
      <TiposEquipForm data={state.data} method={updateTipoEquip} handleBack={handleBack}/>
    </React.Fragment>
  )
}

export default TiposEquipUpdate
