import Async from "react-async"
import { readPlanosAplicaveis } from "./apiPlanosAplicaveis"
import LinearProgress from "@material-ui/core/LinearProgress"
import React, { useContext } from "react"
import ErrorMsg from "../../components/SharedComponents/Shared/Error/ErrorMsg"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { StyledTableCell } from "../../components/SharedStyles/SharedStyles"
import planosAplicaveisMsgs from "./msgsPlanosAplicaveis"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import Link from "@material-ui/core/Link"
import { Link as RouterLink } from "react-router-dom"
import Button from "@material-ui/core/Button"
import { baseURLPDF, getRequest } from "../../api/api"
import PDFIcon from "../../components/Svg-Icons/PDFIcon"
import { RedirectState } from "./PlanosAplicaveis"
import SimulaIcon from "../../components/Svg-Icons/SimulaIcon"
import { secondaryColorDark } from "../../constants/appTheme"
import Typography from "@material-ui/core/Typography"
import { AppMsgs } from "../../constants/appMsgs"
import { planosUrl } from "../Planos/ListPlanos/apiPlanos"


function ShowPlanosAplicaveis({planosAplicaveis, equipID}) {
  const {simula} = useContext(RedirectState)

  const handleSimula = (row) => simula({planoID: row.id})

  return (
    <TableContainer>
      <Table aria-label="planosAplicaveis">
        <TableHead>
          <TableRow>
            <StyledTableCell>{planosAplicaveisMsgs.TABLE.HEADER.REF_CODE}</StyledTableCell>
            <StyledTableCell>{planosAplicaveisMsgs.TABLE.HEADER.DESIGNACAO}</StyledTableCell>
            <StyledTableCell>{planosAplicaveisMsgs.TABLE.HEADER.PDF}</StyledTableCell>
            <StyledTableCell>{planosAplicaveisMsgs.TABLE.HEADER.SIMULACAO}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(!!planosAplicaveis.length) ?
            planosAplicaveis.map(row => (
              <TableRow key={row.id}>

                <TableCell>
                  <Link component={RouterLink} target="_blank"
                        to={getRequest({URLname: planosUrl, id: row.id}).gotoItemPath()}>{`${row.refAN}`}</Link>
                </TableCell>
                <TableCell>{`${row.designacao}  (${row.rev})`}</TableCell>
                <TableCell>
                  <Button target="_blank" href={`${baseURLPDF}/gpm/plano/${row.id}/equipamento/${equipID}/pdf`}>
                    <PDFIcon size={25}/>
                  </Button>
                </TableCell>
                <TableCell>
                  <Button onClick={() => handleSimula(row)}>
                    <SimulaIcon size={25} color={secondaryColorDark}/>
                  </Button>
                </TableCell>
              </TableRow>
            )) :
            <TableRow>
              <TableCell colSpan="4">
                <Typography component="h1" variant="h6" color="inherit" noWrap>
                  {AppMsgs.DEFAULT_VALUES.TABLE.EMPTY_TABLE_MSG}
                </Typography>
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function PlanosAplicaveisList({equipID}) {
  return (
    <Async promiseFn={readPlanosAplicaveis} id={equipID}>
      <Async.Pending><LinearProgress color="secondary"/></Async.Pending>
      <Async.Fulfilled>
        {(data, {setData}) => {
          return (<ShowPlanosAplicaveis planosAplicaveis={data} equipID={equipID}/>)
        }}
      </Async.Fulfilled>
      <Async.Rejected>{error => <ErrorMsg>{error}</ErrorMsg>}</Async.Rejected>
    </Async>
  )
}

export default PlanosAplicaveisList
