import React from "react"
import Grid from "@material-ui/core/Grid"
import { TopEstadosPat } from "./TopEstadosPat"


const Dashboard = () => {
  // const {user} = useUser()
  return (
    <Grid container spacing={2}>

      {/*<Grid item xs={12} sm={6}>*/}
      {/*  <p>User</p>*/}
      {/*  <pre>{JSON.stringify(user, null, 2)}</pre>*/}
      {/*</Grid>*/}

      <Grid item xs={12} sm={12}>
        <TopEstadosPat/>
      </Grid>

    </Grid>
  )
}

export default Dashboard
