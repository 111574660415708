import React from "react"
import SvgIcon from "./SvgIcon"

const PatIcon = (others) => {
  return (
    <SvgIcon viewBox="0 0 128 128" width="512" height="512" {...others}>
      <path
        d="M105.958,80.516a6.489,6.489,0,1,0,6.49,6.489A6.5,6.5,0,0,0,105.958,80.516Zm0,9.478a2.989,2.989,0,1,1,2.99-2.989A2.993,2.993,0,0,1,105.958,89.994Z"/>
      <path
        d="M24.834,80.516a6.489,6.489,0,1,0,6.49,6.489A6.5,6.5,0,0,0,24.834,80.516Zm0,9.478a2.989,2.989,0,1,1,2.99-2.989A2.993,2.993,0,0,1,24.834,89.994Z"/>
      <path
        d="M106.25,46.442H87.417V29.255a1.749,1.749,0,0,0-1.75-1.75H1.75A1.749,1.749,0,0,0,0,29.255v57.75a1.749,1.749,0,0,0,1.75,1.75h9.721a13.475,13.475,0,0,0,26.726,0H92.6a13.475,13.475,0,0,0,26.725,0h6.929a1.749,1.749,0,0,0,1.75-1.75V68.192A21.775,21.775,0,0,0,106.25,46.442ZM3.5,31.005H83.917v54.25H38.211A13.658,13.658,0,0,0,24.834,73.516,13.657,13.657,0,0,0,11.458,85.255H3.5ZM24.834,97a9.99,9.99,0,1,1,9.99-9.99A10,10,0,0,1,24.834,97Zm81.124,0a9.99,9.99,0,1,1,9.99-9.99A10,10,0,0,1,105.958,97ZM124.5,85.255h-5.165a13.491,13.491,0,0,0-26.753,0H87.417V49.942H106.25a18.271,18.271,0,0,1,18.25,18.25Z"/>
      <path
        d="M24.834,69.016A17.962,17.962,0,0,1,35.4,72.468a18.393,18.393,0,0,1,5.878,7.249,1.75,1.75,0,0,0,1.6,1.038H77.667a1.749,1.749,0,0,0,1.75-1.75V37.255a1.749,1.749,0,0,0-1.75-1.75H9.75A1.749,1.749,0,0,0,8,37.255V74.286a1.75,1.75,0,0,0,3.087,1.129A17.968,17.968,0,0,1,24.834,69.016ZM39.066,70.93l7.791-7.791a1.749,1.749,0,0,0,.442-1.731,9.2,9.2,0,0,1,2.317-9.1l.526-.526a9.424,9.424,0,0,1,7.078-2.76l-5.991,5.991a1.746,1.746,0,0,0-.441,1.73l1.408,4.8a1.75,1.75,0,0,0,1.186,1.186l4.8,1.409a1.754,1.754,0,0,0,1.73-.442L65.9,57.7a9.482,9.482,0,0,1-2.76,7.078l-.526.526a9.207,9.207,0,0,1-9.1,2.317,1.745,1.745,0,0,0-1.731.441l-8.29,8.29A21.726,21.726,0,0,0,39.066,70.93ZM11.5,39.005H75.917v38.25H47.542l6.018-6.017A12.7,12.7,0,0,0,65.087,67.78l.525-.525a12.99,12.99,0,0,0,2.98-13.692,1.749,1.749,0,0,0-2.877-.626l-7.548,7.547-2.882-.846-.847-2.883,7.547-7.547a1.749,1.749,0,0,0-.626-2.877A12.988,12.988,0,0,0,47.667,49.31l-.525.525a12.686,12.686,0,0,0-3.458,11.527l-7.445,7.446A21.432,21.432,0,0,0,11.5,70.162Z"/>
      <path
        d="M106.25,53.442H92.667a1.75,1.75,0,0,0-1.75,1.75V65.567a1.749,1.749,0,0,0,1.75,1.75h26.316a1.749,1.749,0,0,0,1.714-2.1A14.8,14.8,0,0,0,106.25,53.442ZM94.417,63.817V56.942H106.25a11.3,11.3,0,0,1,10.364,6.875Z"/>
      <path
        d="M30.132,54.081c0-3.434-4.8-8.926-5.764-9.992a1.8,1.8,0,0,0-2.6,0c-.963,1.066-5.764,6.558-5.764,9.992a7.063,7.063,0,1,0,14.125,0Zm-7.063,3.563a3.566,3.566,0,0,1-3.562-3.563c0-1.136,1.677-3.777,3.562-6.12,1.886,2.343,3.563,4.984,3.563,6.12A3.567,3.567,0,0,1,23.069,57.644Z"/>
    </SvgIcon>
  )
}

export default PatIcon
