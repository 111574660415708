import React from "react"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import { StyledTableCell } from "../../../../components/SharedStyles/SharedStyles"
import patMsgs from "../../patMsgs"
import { ShowDateTime } from "../../../../components/SharedComponents/Shared/Show/ShowDateTime"
import Box from "@material-ui/core/Box"
import { tableStyles } from "../../../../constants/appTheme"


function TableInfoPatAnulado({pat}) {
  const classes = tableStyles()
  return (
    <TableContainer>
      <Table className={classes.table} size="small" aria-label="TablePatProps">
        {/* ----------------------------------------------------------------- */}
        <>
          <TableHead>
            <TableRow>
              <StyledTableCell>{patMsgs.ANULADO}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.fadedTableBody}>
            <TableRow key="patInfoAnulado">
              <TableCell className={classes.obsTableCell}>
                <Box className={classes.rootBox}
                     display="flex" alignItems="center" flexDirection="row" p={1} m={1}>
                  <Box width="35%" p={1}><ShowDateTime dateTime={pat.anulado_at}/></Box>
                  <Box className={classes.obsBox}
                       flexGrow={1} p={1}
                       dangerouslySetInnerHTML={{__html: pat.obsAnulacao}}/>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </>
      </Table>
    </TableContainer>
  )
}

export { TableInfoPatAnulado }
