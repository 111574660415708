import React, { useState } from "react"
import { useDropzone } from "react-dropzone"
import { ErrorMessage, useFormContext } from "react-hook-form"
import { fileValidation } from "../../../constants/appValidation"
import InputError from "../../SharedComponents/Shared/Error/InputError"
import Button from "@material-ui/core/Button"
import { AppMsgs } from "../../../constants/appMsgs"
import PropTypes from "prop-types"

// application/pdf
const fileDefaultMaxSize = 5000000 // 5MB
const acceptedFileTypes = 'application/pdf'

const FileUploader = ({name, setFilename}) => {
  const [file, setFile] = useState()
  const {register, errors, setValue, setError, clearError} = useFormContext()
  const onDrop = React.useCallback((acceptedFile) => {
    const acpFile = acceptedFile ? acceptedFile[0] : null
    const fileValid = fileValidation(acpFile, fileDefaultMaxSize, acceptedFileTypes)
    if (fileValid.isValid) {
      clearError(name)
      setFile(acpFile)
      //-----------------------------------
      // base64 PDF file upload
      //-----------------------------------
      if (setFilename) setFilename(acpFile.name)
      const reader = new FileReader()
      reader.readAsDataURL(acpFile)
      reader.onloadend = (evt) => {
        setValue(name, evt.target.result)
      }
      //-----------------------------------
    } else {
      setError(name, "not match", fileValid.msg)
    }

  }, [setValue, clearError, setError, name, setFilename])

  const {getRootProps, getInputProps} = useDropzone({onDrop})

  return (
    <React.Fragment>

      <div {...getRootProps()} /*style={{padding: '9px', border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: '4px'}}*/>
        <input name={name} ref={register} {...getInputProps()} />
        <Button size="small" variant="outlined" color="primary">{AppMsgs.DEFAULT_VALUES.INPUT.FILE_LBL}</Button>
        {file ? <span style={{marginLeft: '10px'}}>{file.name}</span> : null}
        <ErrorMessage errors={errors} name={name} as={<InputError/>}/>
      </div>
    </React.Fragment>
  )
}

FileUploader.defaultProps = {
  setFilename: null
}

FileUploader.propTypes = {
  name: PropTypes.string.isRequired
}

export default FileUploader
