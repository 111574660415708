import React from "react"
import { Redirect, Route, Switch } from "react-router"
import { getRequest } from "../api/api"
import { dashboardUrl } from "../pages/DashBoard/apiDashboard"
import { homeUrl } from "../pages/Home/apiHome"


function Routes({routes, render}) {

  return (
    <Switch>
      {Object.values(routes).map((route, idx) => {
        return route[render] ? (
          <Route
            key={idx}
            path={route.path}
            exact={route.exact}
            name={route.name}
            component={route[render]}
          />
        ) : null
      })}
      {<Redirect from={homeUrl} to={getRequest({URLname: dashboardUrl}).paginatePath()}/>}
    </Switch>
  )
}

export default React.memo(Routes)
