import React, { useContext } from "react"
import * as Yup from "yup"
import { requiredDateValidation } from "../../../../../constants/appValidation"
import patInfoStatesMsgs from "../patInfoStatesMsgs"
import { Form } from "../../../../../components/SharedStyles/SharedStyles"
import Grid from "@material-ui/core/Grid"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import LFSubmitButton from "../../../../../components/SharedComponents/Shared/LFButtons/LFSubmitButton"
import { FormContext, useForm } from "react-hook-form"
import { LFFormatDateTime } from "../../../../../constants/appConst"
import { useAsync } from "react-async"
import { getPatTecClassifier } from "../apiInfoStates"
import { AssocTecAgendaPATState } from "./AssocTecEAgendaPAT"
import LFDateTimePickerInput from "../../../../../components/LFInputs/LFDateTimePickerInput"
import moment from "moment"
import MomentUtils from "@date-io/moment"
import "moment/locale/pt"


const validationSchema = Yup.object().shape({
  dhAgendamento: requiredDateValidation(patInfoStatesMsgs.DH_AGENDAMENTO),
})

const AgendaPATForm = ({pat}) => {

  const {handleAssocTec} = useContext(AssocTecAgendaPATState)

  const {isPending, run} = useAsync({
    deferFn: getPatTecClassifier,
    onResolve: (tecClassifier) => {
      handleAssocTec({tecClassifier: tecClassifier})
    }
  })

  const methods = useForm({
    validationSchema: validationSchema,
    defaultValues: {
      dhAgendamento: new Date()
    }
  })

  const {handleSubmit, control} = methods

  const onSubmit = ({...values}) => {
    const convertValues = {
      ...values, ...{
        pat_id: pat.id,
        dhAgendamento: values.dhAgendamento && LFFormatDateTime(values.dhAgendamento),
      }
    }
    run({...convertValues})
  }

  return (
    <FormContext {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} justify="center">
          {/*---------------------------------- DATA_INIC ----------------------------------*/}
          <Grid item xs={6}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <LFDateTimePickerInput name='dhAgendamento'
                                     control={control}
                                     label={patInfoStatesMsgs.DH_AGENDAMENTO}/>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <LFSubmitButton size="large" noStyle={true} style={{padding: '1rem .5rem', fontSize: '0.85rem', lineHeight: '1.2rem'}}
                            isLoading={isPending}>
              {patInfoStatesMsgs.CHECK_TEC_CLASSIFIER}
            </LFSubmitButton>
          </Grid>
        </Grid>
      </Form>
    </FormContext>
  )
}

export {
  AgendaPATForm,
}





















