import Typography from "@material-ui/core/Typography"
import React from "react"

const imagemMsgs = {

  NEW_LABEL: "Associar imagem",
  BACK_LABEL_UPDATE: `Cancelar a edição`,  //  usado mas nunca apresentado pois não existe a funcionalidade update em ListRepo
  BACK_LABEL_CREATE: `Cancelar o registo de nova imagem`,

  CONFIRM_DIALOG_CONTENT: (img) => {
    return (
      <React.Fragment>
        <img src={img.image} alt={img.text} height={'100px'}/>
        <Typography>{img.text}</Typography>
        <hr/>
        <p>
        </p>
        <Typography> Confirma a remoção desta imagem? </Typography>
      </React.Fragment>
    )
  },

  EDIT: {
    LABEL: {
      IMAGEM: "Imagem",
      TEXT: "Descrição"
    }
  },
}

export { imagemMsgs }

