import React from "react"
import { ShowDateTime } from "../Show/ShowDateTime"
import StyledBoldSpan from "../../../SharedStyles/StyledBoldSpan"
import { textSecondary, whiteColor } from "../../../../constants/appTheme"
import PropTypes from "prop-types"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { StyledTableCell } from "../../../SharedStyles/SharedStyles"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Fab from "@material-ui/core/Fab"
import { Link as RouterLink } from "react-router-dom"
import { getRequest } from "../../../../api/api"
import { usersUrl } from "../../../../pages/Entidades/Users/userConst"
import UserIcon from "../../../Svg-Icons/UserIcon"


const localStyles = makeStyles((theme) => ({
  tblRoot: {
    margin: '0',
  },
  bt: {
    minHeight: '35px',
    lineHeight: '15px',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightLight,
  },
  propsStyles: props => ({
    ...props
  }),
}))

function TableEstadoVisitaPat({estado, styleGrid, styleEstadoDesc, classNameGrid, classNameEstadoDesc}) {
  const myClasses = localStyles()

  return (
    <TableContainer>
      {/* ----------------------------------------------------------------- */}
      <Table className={myClasses.tblRoot} size="small" aria-label="TableEstadoPat">
        <TableHead>
          <TableRow>
            <StyledTableCell>
              <StyledBoldSpan style={styleEstadoDesc}
                              className={classNameEstadoDesc}>
                <Fab component={RouterLink} target="_blank"
                     style={{width: '26px', height: '26px', minHeight: '22px', boxShadow: 'none', marginRight: '5px'}}
                     to={getRequest({URLname: usersUrl, id: estado.user_id}).gotoItemPath()} color="secondary">
                  <UserIcon color={whiteColor} style={{width: '16px', height: '16px',}}/>
                </Fab>
                {estado.estadoDesc}
              </StyledBoldSpan>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key="visitapatDHreg">
            <TableCell>
              <ShowDateTime dateTime={estado.dhRegisto}/>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

TableEstadoVisitaPat.defaultProps = {
  styleGrid: {},
  styleEstadoDesc: {fontSize: '80%', color: textSecondary},
  classNameGrid: '',
  classNameEstadoDesc: '',
}

TableEstadoVisitaPat.propTypes = {
  estado: PropTypes.object.isRequired,
  styleGrid: PropTypes.object,
  styleEstadoDesc: PropTypes.object,
  classNameGrid: PropTypes.string,
  classNameEstadoDesc: PropTypes.string,
}

export default TableEstadoVisitaPat
