import { api, apiCUD, apiReadTable, getRequest } from "../../../api/api"
import msgsPlanosAtivados from "./msgsPlanosAtivados"
import { ListPlanosAtivados } from "./ListPlanosAtivados"
import PlanoAtivadoIcon from "../../../components/Svg-Icons/PlanoAtivadoIcon"
import PlanoAtivadoView from "./PlanoAtivadoView"

const planosAtivadosUrl = 'planos-ativados'

// ----------------------- PLANOS ATIVADOS ROUTES -----------------------

const planosAtivadosListRouterProps = {
  path: getRequest({URLname: planosAtivadosUrl}).paginatePath(),
  exact: true,
  name: msgsPlanosAtivados.LABEL,
  component: ListPlanosAtivados,
  Icon: PlanoAtivadoIcon,
  breadcrumb: () => msgsPlanosAtivados.LABEL,
  reqRole: 'R'
}

const planoAtivadoViewRouterProps = {
  path: getRequest({URLname: planosAtivadosUrl}).viewPath(),
  name: msgsPlanosAtivados.UPDATE_LABEL,
  exact: true,
  component: PlanoAtivadoView,
  breadcrumb: () => msgsPlanosAtivados.UPDATE_LABEL,
  reqRole: 'R'
}

// ----------------------- PLANOS ATIVADOS ROUTES -----------------------

async function readPlanosAtivadosTable([query]) {
  const request = getRequest({URLname: planosAtivadosUrl, query}).GET()
  return apiReadTable(request)
}

function readPlanoAtivado({id}) {
  const request = getRequest({URLname: planosAtivadosUrl, id, query: {com: 'plano, periodos'}}).GET()
  return api(request).then(r => r.data.data)
}

function desativaPlanoAtivado([data]) {
  const request = getRequest({URLname: planosAtivadosUrl, id: data.id}).PUT('desativa')
  return apiCUD(request, data).then(r => r.data)

}

async function deletePlanoAtivado([data]) {
  const request = getRequest({URLname: planosAtivadosUrl, id: data.id}).DELETE()
  return apiCUD(request)
}

export {
  planosAtivadosUrl, planosAtivadosListRouterProps, planoAtivadoViewRouterProps,
  readPlanosAtivadosTable, readPlanoAtivado, deletePlanoAtivado, desativaPlanoAtivado
}
