import React, { createContext, useCallback, useMemo, useState } from "react"
import ListPecasPat from "./ListPecasPat"
import PecaPatEdit from "./PecaPatEdit"
import { createPecaPat, deletePecaPat, readPecasPat, updatePecaPat } from "./apiPecasPats"
import Paper from "@material-ui/core/Paper"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { StyledTableCell } from "../../../components/SharedStyles/SharedStyles"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import { parseToMoney, parseToPercent } from "../../../constants/taxasConst"
import pecasPatMsgs from "./msgs"
import Grid from "@material-ui/core/Grid"
import StyledBoldSpan from "../../../components/SharedStyles/StyledBoldSpan"

const PecasPatState = createContext(null)

const handleSavePecaPat = ({showList, showState, pat, allPecas, setPecasObj, data}) => {

  if (showState.createMode || showState.editMode) {

    let apiCall = createPecaPat
    if (showState.editMode) {
      apiCall = updatePecaPat
    }

    return apiCall(data).then(
      _ => {
        readPecasPat({pat: pat}).then(
          (pecasPat) => {
            setPecasObj({pat: pat, pecas: pecasPat, allPecas: allPecas})
            showList()
          })
      }
    )
  }
}

const handleDeletePecaPat = ({pat, allPecas, setPecasObj, pecaPatID}) => {
  return deletePecaPat({pat_id: pat.id, peca_id: pecaPatID}).then(
    _ => {
      readPecasPat({pat: pat}).then(
        (pecasPat) => setPecasObj({pat: pat, pecas: pecasPat, allPecas: allPecas}))
    }
  )
}

const SomaPecasPat = ({pecas}) => {
  let auxMap = new Map()
  let totalSemIVA = 0
  let totalIVA = 0
  let totalDesconto = 0
  let totalSemDesconto = 0
  let totalComIVA = 0
  let key = 0

  pecas.forEach(item => {
    const txIVA = parseInt(item.percIVA, 10)
    const txDesconto = parseToPercent(item.percDesc)
    const itemQTD = parseInt(item.qtd, 10) || 0
    const itemValBase = parseToMoney(item.unitPrice) * itemQTD
    const itemVal = parseToMoney(item.pvpFinalSemIVA) * itemQTD
    const itemDesconto = itemValBase * txDesconto
    const itemIVAval = itemVal * (txIVA / 100)

    totalSemDesconto += itemValBase
    totalDesconto += itemDesconto
    totalSemIVA += itemVal
    totalIVA += itemIVAval
    totalComIVA += itemVal + itemIVAval

    let newVal = {qtd: 1, tot: itemVal, val: itemIVAval}

    if (auxMap.has(txIVA)) {
      const val = auxMap.get(txIVA)
      newVal = {qtd: val.qtd + 1, tot: val.tot + itemVal, val: val.val + itemIVAval}
    }
    auxMap.set(txIVA, newVal)
    key = 1
  })

  const ivaMap = new Map([...auxMap.entries()].sort())
  if (!key) return null
  else
    return (
      <Paper>

        <Grid container spacing={2} justify="center">
          <Grid item xs={6}>
            <TableContainer>
              <Table size="small" aria-label="TableTaxas">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">{pecasPatMsgs.TABLE.HEADER.IVA}</StyledTableCell>
                    <StyledTableCell align="center">{pecasPatMsgs.TABLE.HEADER.REF_IVA}</StyledTableCell>
                    <StyledTableCell align="right">{pecasPatMsgs.TABLE.HEADER.TOTAL}</StyledTableCell>
                    <StyledTableCell align="right">{pecasPatMsgs.TABLE.HEADER.VALOR}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.from(ivaMap).map(([iva, val]) => (
                    <TableRow key={`txIVA${++key}`}>
                      <TableCell align="center">{parseToPercent(iva, true)}</TableCell>
                      <TableCell align="center">{val.qtd}</TableCell>
                      <TableCell align="right">{parseToMoney(val.tot, true)}</TableCell>
                      <TableCell align="right">{parseToMoney(val.val, true)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={6}>
            <TableContainer>
              <Table size="small" aria-label="TableTotais">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center"> </StyledTableCell>
                    <StyledTableCell align="right">{pecasPatMsgs.TABLE.HEADER.TOTAIS}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="right">{pecasPatMsgs.TABLE.HEADER.TOTAL_SEM_DESC}</TableCell>
                    <TableCell align="right">{parseToMoney(totalSemDesconto, true)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right">{pecasPatMsgs.TABLE.HEADER.DESCONTO}</TableCell>
                    <TableCell align="right">{parseToMoney(totalDesconto, true)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right">{pecasPatMsgs.TABLE.HEADER.TOTAL_SEM_IVA}</TableCell>
                    <TableCell align="right">{parseToMoney(totalSemIVA, true)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right">{pecasPatMsgs.TABLE.HEADER.IVA}</TableCell>
                    <TableCell align="right">{parseToMoney(totalIVA, true)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right"><StyledBoldSpan
                      style={{color: 'rgb(164,85,0)'}}>{pecasPatMsgs.TABLE.HEADER.TOTAL}</StyledBoldSpan></TableCell>
                    <TableCell align="right"><StyledBoldSpan
                      style={{color: 'rgb(164,85,0)'}}>{parseToMoney(totalComIVA, true)}</StyledBoldSpan></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    )
}

function PecasPatGest({pat, pecas, allPecas, disable, reloadReport}) {
  const [showState, setShowState] = useState({disable: false, editMode: false, createMode: false, data: {}})
  const [pecasObj, setPecasObj] = useState({pat, pecas, allPecas})

  // useEffect(() => {
  //   if(!!onUpdatePecasPAT){
  //     onUpdatePecasPAT(pecasObj.pecas.data)
  //   }
  // }, [pecasObj, onUpdatePecasPAT])

  const showList = useCallback(() => {
    setShowState({disable: false, editMode: false, createMode: false, data: {}})
    if (!!reloadReport) reloadReport()
  }, [reloadReport])

  const showEdit = useCallback((itemData) => setShowState({
    disable: true,
    editMode: !!itemData,
    createMode: !itemData,
    data: itemData
  }), [])

  const getState = useMemo(() => ({
    showList, setPecasObj, showEdit, showState, pecasObj, disable
  }), [
    showList, setPecasObj, showEdit, showState, pecasObj, disable
  ])

  return (
    <PecasPatState.Provider value={getState}>
      <p>
        {/*separador de alta tecnologia :) */}
      </p>
      {(showState.editMode || showState.createMode) && <PecaPatEdit/>}
      <p>
        {/*separador de alta tecnologia :) */}
      </p>
      <ListPecasPat disable={showState.disable}/>
      <p>
        {/*separador de alta tecnologia :) */}
      </p>
      <SomaPecasPat pecas={pecasObj.pecas.data}/>
    </PecasPatState.Provider>
  )
}

export {
  PecasPatState,
  handleSavePecaPat,
  handleDeletePecaPat,
  PecasPatGest
}
