import React from "react"
import BaseTableListWithRoutes from "../../../components/SharedComponents/Shared/BaseTableListWithRoutes"
import pecasMsgs from "./msgs"
import { deletePeca, pecasURLname, readPecasTable } from "./apiPecas"


const columns = [{
  Header: () => null,
  id: 'Pecas',
  columns: [
    {
      Header: pecasMsgs.TABLE.HEADER.REF,
      accessor: "ref"
    }, {
      Header: pecasMsgs.TABLE.HEADER.DESC,
      accessor: "desc"
    }, {
      Header: pecasMsgs.TABLE.HEADER.UNIT_PRICE,
      accessor: "unitPrice",
      Cell: ({row}) => {
        return <span>{row.original.unitPrice} €</span>
      },
      disableSortBy: true
    }, {
      Header: pecasMsgs.TABLE.HEADER.PATS_COUNT,
      accessor: "aplicacoes_em_pats_count",
      disableSortBy: true
    }
  ]
}]

function Pecas() {
  return (
    <BaseTableListWithRoutes
      addButtonDesc={pecasMsgs.CREATE_LABEL}
      route={pecasURLname}
      columns={columns}
      deleteFn={deletePeca}
      readFn={readPecasTable}
    />
  )
}

export default Pecas
