const msgTiposPlano = {
  LABEL: "Tipos Plano",
  TABLE: {
    HEADER: {
      SIGLA: "Sigla",
      TIPO: "Tipo",
      PLANO_GARANTIA: "Plano Garantia",
      SEM_TERMO: "Sem Termo",
      RENOVAVEL: "Renovavel",
      DUR_CONF_PLANO: "Dur. Conf. Plano",
      DUR_DEP_TC: "Dur. Dep. Tipo Cliente",
      DUR_CLI_DOM: "Dur. Cliente Domestico",
      DUR_CLI_INST: "Dur. Cliente Institucional",
      PER_DESC_PECAS: "% Desc. Peças",
      PER_DESC_DESLOC: "% Desc. Deslocação",
      PER_DESC_MO: "% Desc. Mão Obra",
    },
    LABEL: {
      MONTHS: ' (meses)'
    }
  }
}

export default msgTiposPlano
