import React, { createContext, useCallback, useMemo, useState } from "react"
import TiposEquipList from "./TiposEquipList"
import TiposEquipUpdate from "./TiposEquipUpdate"
import TiposEquipCreate from "./TiposEquipCreate"

export const RedirectContext = createContext({})

function TiposEquip() {
  const [redirect, setRedirect] = useState({})

  const list = useCallback(() => setRedirect({}), [])
  const create = useCallback(() => setRedirect({type: 'C'}), [])
  const update = useCallback((newState) => setRedirect({...newState, type: 'U'}), [])

  const getState = useMemo(() => ({list, create, update}), [list, create, update])

  return (
    <RedirectContext.Provider value={getState}>
      {
        ((redirect.type === 'C')
          ? <TiposEquipCreate/>
          : ((redirect.type === 'U')
            ? <TiposEquipUpdate state={redirect}/>
            : <TiposEquipList/>))
      }
    </RedirectContext.Provider>
  )
}

export default TiposEquip
