import React from "react"

const tiposequipMsgs = {
  LABEL: "Tipos Equipamentos",
  EDIT_LABEL: "Alterar tipo de equipamento",
  POST_LABEL: "Adicionar tipo de equipamento",
  BACK_LABEL: "Voltar aos Tipos de Equipamento",
  TABLE: {
    ADD_BUTTON: "Novo Tipo Equipamento",
    HEADER: {
      REF_AN: "AN",
      TIPO: "Tipo",
      PLANOS_BASE: () => <div style={{textAlign: 'center', lineHeight: '14px'}}>Planos<br/>Base</div>,
      PLANOS_ATIVOS: () => <div style={{textAlign: 'center', lineHeight: '14px'}}>Planos<br/>Ativos</div>,
      PROCEDIMENTO: "Procedimento",
      MODELOS: "Modelos",
      EQUIPAMENTOS: "Equipamentos"
    }
  },
  EDIT: {
    LABEL: {
      REF_AN: "Referência",
      TIPO: "Nome do Tipo de Equipamento"
    }
  },
  BUTTON: {
    EDIT_TIPOEQUIP: "Guardar Alterações",
    NEW_TIPOEQUIP: "Guardar Alterações"
  }
}

export default tiposequipMsgs
