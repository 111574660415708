import React from "react"
import { deleteInstalador, instaladoresUrl, readInstaladoresTable, undeleteInstalador } from "./apiInstaladores"
import { AppMsgs } from "../../../constants/appMsgs"
import BaseTableListWithRoutes from "../../../components/SharedComponents/Shared/BaseTableListWithRoutes"
import TabsComponent from "../../../components/SharedComponents/Shared/TabsComponent"
import LFTable from "../../../components/LFTable/LFTable"
import LinkWithIcon from "../../../components/SharedComponents/Shared/LinkWithIcon"
import EmailIcon from "../../../components/Svg-Icons/EmailIcon"
import { secondaryColor } from "../../../constants/appTheme"
import SmartPhoneIcon from "../../../components/Svg-Icons/SmartPhoneIcon"
import PhoneIcon from "../../../components/Svg-Icons/PhoneIcon"
import expandColumn from "../../../components/LFTable/ExpandColumn"
import TableInstalador from "../../../components/SharedComponents/Shared/Tables/TableInstalador"
import msgsInstaladores from "./msgsInstaladores"


const columns = {
  Header: () => null,
  id: 'Instaladores',
  columns: [
    {
      Header: AppMsgs.ENTIDADES.TABLE.HEADER.AVATAR,
      accessor: "logo",
      Cell: ({row}) => (
        <>
          <img height={40} src={row.original.logo} alt={row.original.nome}/>
        </>
      ),
    },
    {
      Header: AppMsgs.ENTIDADES.TABLE.HEADER.NOME,
      accessor: "nome"
    },
    {
      Header: AppMsgs.ENTIDADES.TABLE.HEADER.CONTACTO,
      accessor: "contacto",
      Cell: ({row}) => (
        <React.Fragment>
          {row.original.email &&
          <LinkWithIcon icon={<EmailIcon size={12} style={{marginRight: '5px'}} color={secondaryColor}/>}
                        href={"mailto:" + row.original.email}
                        desc={row.original.email}
          />}
          {row.original.tlm &&
          <LinkWithIcon icon={<SmartPhoneIcon size={12} style={{marginRight: '5px'}} color={secondaryColor}/>}
                        href={"tel:" + row.original.tlm}
                        desc={row.original.tlm}/>}
          {row.original.tlf &&
          <LinkWithIcon icon={<PhoneIcon size={12} style={{marginRight: '5px'}} color={secondaryColor}/>}
                        href={"tel:" + row.original.tlf}
                        desc={row.original.tlf}
          />}
        </React.Fragment>
      ),
    },
    {
      Header: AppMsgs.ENTIDADES.TABLE.HEADER.EQUIP_COUNT,
      accessor: "equipamentos_cli_count",
      disableSortBy: true
    }
  ]
}

/**
 * Label = Title Tab
 * Index = Position
 * Component = Render Component
 * @type {function(*, *): *[]}
 */
const instaladorTabs = (renderRowSubComponent, columnsWithExpand) => [
  {
    label: AppMsgs.DEFAULT_VALUES.ATIVOS_LABEL,
    index: 0,
    component: <BaseTableListWithRoutes
      route={instaladoresUrl}
      columns={[columns]}
      deleteFn={deleteInstalador}
      readFn={readInstaladoresTable}
      addButtonDesc={msgsInstaladores.TABLE.ADD_BUTTON}/>,
  },
  {
    label: AppMsgs.DEFAULT_VALUES.HISTORICO_LABEL,
    index: 1,
    component: <LFTable
      columns={columnsWithExpand}
      requestFn={([props]) => readInstaladoresTable([{trashed: true, ...props}])}
      showAddButton={false}
      showEditButton={false}
      showDeleteButton={false}
      showUndeleteButton={true}
      renderRowSubComponent={renderRowSubComponent}
      unDeleteFn={undeleteInstalador}
    />,
  }
]

export const Instaladores = () => {

  const renderRowSubComponent = React.useCallback(
    ({row}) => (<TableInstalador data={row.original}/>
    ), [])

  const columnsWithExpand = React.useMemo(() => [{...expandColumn}, {...columns}], [])

  return (
    <React.Fragment>
      <TabsComponent renderTabs={instaladorTabs(renderRowSubComponent, columnsWithExpand)}/>
    </React.Fragment>

  )
}

export default Instaladores
