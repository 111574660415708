import React from "react"
import Async from "react-async"
import LinearProgress from "@material-ui/core/LinearProgress"
import ErrorMsg from "../../components/SharedComponents/Shared/Error/ErrorMsg"
import { readPlanoAtivo } from "./apiPlanoAtivo"
import PlanoAtivoView from "./PlanoAtivoView"

function PlanoAtivadoTab({planoAtivID, setEquip, equip}) {
  return (
    <React.Fragment>
      <Async promiseFn={readPlanoAtivo} id={planoAtivID}>
        <Async.Pending><LinearProgress color="secondary"/></Async.Pending>
        <Async.Fulfilled>
          {(planoAtivo, {setData: setPlanoAtivo}) => {
            return (
              <React.Fragment>
                <PlanoAtivoView planoAtiv={planoAtivo} setEquip={setEquip} equip={equip} showPAI={true}/>
              </React.Fragment>
            )
          }}
        </Async.Fulfilled>
        <Async.Rejected>{error => <ErrorMsg>{error}</ErrorMsg>}</Async.Rejected>
      </Async>
    </React.Fragment>

  )
}

export default PlanoAtivadoTab
