import React, { useEffect } from "react"
import Grid from "@material-ui/core/Grid"
import Async from "react-async"
import LinearProgress from "@material-ui/core/LinearProgress"
import { readTopEstadosPat } from "./apiDashboard"
import ErrorMsg from "../../components/SharedComponents/Shared/Error/ErrorMsg"
import { EntityOpenDialogButton, EntityOpenDialogIcon } from "../../components/SharedComponents/Shared/Dialog/EntityOpenDialogButton"
import { TableTecnico } from "../../components/SharedComponents/Shared/Tables/TableTecnico"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { primaryColorDark, textDisabled } from "../../constants/appTheme"
import { TableVisitaPat } from "../../components/SharedComponents/Shared/Tables/TableVisitaPat"
import visitaPatMsgs from "../Pats/VisitasPat/visitaPatMsgs"
import { Badge } from "../../components/SharedStyles/SharedStyles"
import { TableUser } from "../../components/SharedComponents/Shared/Tables/TableUser"
import UserIcon from "../../components/Svg-Icons/UserIcon"
import './bounce.css'
import { TableDashboardPat } from "../../components/SharedComponents/Shared/Tables/TableDashboardPat"
import PatIcon from "../../components/Svg-Icons/PatIcon"
import BuildIcon from "../../components/Svg-Icons/BuildIcon"
import LeftMarginSpan from "../../components/SharedStyles/LeftMarginSpan"
import StyledBoldSpan from "../../components/SharedStyles/StyledBoldSpan"

const useStyles = makeStyles((theme) => ({
  iconBt: {
    padding: '.2em',
  },
  action: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightLight,
  },
  causa: {
    fontSize: '11px',
    width: '18px',
    height: '18px',
  },
  bt: {
    minHeight: '25px',
    lineHeight: '14px',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightLight,
  },
}))

const RenderTopEstadoPat = ({estado}) => {
  const classes = useStyles()

  const itemText = (estado.pat && estado.pat.refCode) || ''
  return (
    <Grid container
          spacing={1}
          direction="row"
          justify="space-between"
          alignItems="center">

      <Grid item xs={1} md={1} align="center" className="bounce-in">
        <Badge className={classes.causa}>{estado.causaEstado}</Badge>
      </Grid>
      <Grid item xs={10} md={10} className={`bounce-in ${classes.action}`}>
        <StyledBoldSpan>{itemText}</StyledBoldSpan>
        <LeftMarginSpan>{estado.action}</LeftMarginSpan>
      </Grid>
      <Grid item xs={1} md={1}>
        <EntityOpenDialogIcon component={<TableUser data={estado.user} showLink/>}
                              Icon={<UserIcon color={primaryColorDark} style={{width: '16px', height: '16px',}}/>}
                              dialogTitle={(estado.user && estado.user.name) || ''}
                              disabled={!estado.user}
                              classNameProp={classes.iconBt}/>
      </Grid>

      <Grid item xs={12} sm={12}>
        <Grid container spacing={1}>
          <Grid item xs align="center">
            <EntityOpenDialogIcon component={<TableDashboardPat data={estado.pat} showLink/>}
                                  Icon={<PatIcon
                                    color={estado.pat?primaryColorDark:textDisabled}
                                    style={{width: '16px', height: '16px',}}/>}
                                  dialogTitle={(estado.pat && estado.pat.refCode) || ''}
                                  disabled={!estado.pat}
                                  classNameProp={classes.iconBt}/>
            {/*<EntityOpenDialogButton component={<TableDashboardPat data={estado.pat} showLink/>}*/}
            {/*                        btTitle={patMsgs.DIALOG_BUTTON}*/}
            {/*                        dialogTitle={(estado.pat && estado.pat.refCode) || ''}*/}
            {/*                        disabled={!estado.pat}*/}
            {/*                        fullWidth={true}*/}
            {/*                        classNameProp={classes.bt}/>*/}
          </Grid>
          <Grid item xs align="center">
            <EntityOpenDialogIcon component={<TableTecnico data={estado.tecnico} showLink/>}
                                  Icon={<BuildIcon
                                    color={estado.tecnico?primaryColorDark:textDisabled}
                                    style={{width: '16px', height: '16px',}}/>}
                                  dialogTitle={(estado.tecnico && estado.tecnico.nome) || ''}
                                  disabled={!estado.tecnico}
                                  classNameProp={classes.iconBt}/>
            {/*<EntityOpenDialogButton component={<TableTecnico data={estado.tecnico} showLink/>}*/}
            {/*                        btTitle={msgsTecnicos.DIALOG_BUTTON}*/}
            {/*                        dialogTitle={(estado.tecnico && estado.tecnico.nome) || ''}*/}
            {/*                        disabled={!estado.tecnico}*/}
            {/*                        fullWidth={true}*/}
            {/*                        classNameProp={classes.bt}/>*/}
          </Grid>
          <Grid item xs align="center">
            <EntityOpenDialogButton component={<TableVisitaPat data={estado.visita} showLink/>}
                                    btTitle={visitaPatMsgs.DIALOG_BUTTON}
                                    dialogTitle={(estado.visita && estado.visita.estadoVisitaDesc) || ''}
                                    disabled={!estado.visita}
                                    fullWidth={true}
                                    classNameProp={classes.bt}/>
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  )
}

const RenderListTopEstadosPat = ({estados, reload}) => {

  // TODO criar um contexto para testar se houve alteração dos estados
  useEffect(() => {
    const timer = setInterval(() => {
      reload()
    }, 30 * 1000) // 3 minutos => 3*60*1000
    return () => clearTimeout(timer)
  }, [reload])


  return estados.map((estado, index) => <RenderTopEstadoPat key={index} estado={estado}/>)
}

function TopEstadosPat() {
  return (
    <Async promiseFn={readTopEstadosPat}>
      <Async.Pending><LinearProgress color="secondary"/></Async.Pending>
      <Async.Fulfilled>
        {(data, {reload}) => {
          return (<RenderListTopEstadosPat estados={data} reload={reload}/>)
        }}
      </Async.Fulfilled>
      <Async.Rejected>{error => <ErrorMsg>{error}</ErrorMsg>}</Async.Rejected>
    </Async>
  )
}

export { TopEstadosPat }
