const msgsLocaisTec = {
  TAB_LABEL: "Locais Associados",
  ERRO_LU: "Ocorreu um erro, tente mais tarde",
  UPDATE_BUTTON: "Atualizar locais",
  DISTRITOS: "Distritos",
  CONSELHOS: "Conselhos",
  CP4S: "Área",
  BACK_LABEL: "Voltar à listagem dos locais"
}

export default msgsLocaisTec
