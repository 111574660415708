import React from "react"
import { useAsync } from "react-async"
import LFRenderTable from "./LFRenderTable"
import PropTypes from "prop-types"
import EditIcon from "../Svg-Icons/EditIcon"
import { secondaryColorLight } from "../../constants/appTheme"


function LFTable(props) {
  const {requestFn} = props
  const {data, isPending, isFulfilled, run: requestRun} = useAsync({deferFn: requestFn})

  return (
    <LFRenderTable
      data={data ? data.data : []}
      getDataFn={requestRun}
      isFulfilled={isFulfilled}
      loading={isPending}
      pageCount={data ? data.meta.pagination.total : 0}
      {...props}
    />
  )
}

LFTable.defaultProps = {
  renderRowSubComponent: null,
  showAddButton: false,
  showEditButton: false,
  EditIconComponent: <EditIcon color={secondaryColorLight}/>,
  isEditableFlagRowFieldName: '',
  reverseEditableFlag: false,
  reloadOnEdit: false,
  showDeleteButton: false,
  showUndeleteButton: false,
  unDeleteFn: null,
  deleteFn: null,
  handlerCreate: null,
  handlerUpdate: null
}
LFTable.propTypes = {
  columns: PropTypes.array.isRequired,
  requestFn: PropTypes.func.isRequired,
  renderRowSubComponent: PropTypes.func,
  showAddButton: PropTypes.bool,
  showEditButton: PropTypes.bool,
  EditIconComponent: PropTypes.element,
  isEditableFlagRowFieldName: PropTypes.string,
  reverseEditableFlag: PropTypes.bool,
  reloadOnEdit: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  showUndeleteButton: PropTypes.bool,
  deleteFn: function (props, propName, componentName) {
    if ((props['showDeleteButton'] === true && (props[propName] === undefined || typeof (props[propName]) != 'function'))) {
      return new Error('Please provide a handleDelete function!')
    }
  },
  unDeleteFn: function (props, propName, componentName) {
    if ((props['showUndeleteButton'] === true && (props[propName] === undefined || typeof (props[propName]) != 'function'))) {
      return new Error('Please provide a unDeleteFn function!')
    }
  },
  handlerCreate: function (props, propName, componentName) {
    if ((props['showAddButton'] === true && (props[propName] === undefined || typeof (props[propName]) != 'function'))) {
      return new Error('Please provide a handleDelete function!')
    }
  },
  addButtonDesc: function (props, propName, componentName) {
    if ((props['showAddButton'] === true && (props[propName] === undefined || typeof (props[propName]) != 'string'))) {
      return new Error('Please provide a addButtonDesc!')
    }
  },
  handlerUpdate: function (props, propName, componentName) {
    if ((props['showEditButton'] === true && (props[propName] === undefined || typeof (props[propName]) != 'function'))) {
      return new Error('Please provide a handleDelete function!')
    }
  },
}

export default LFTable
