import React from "react"
import { IVA } from "../../../../../constants/appConst"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import { getValAsFloat, getValAsMoney } from "../../../../../constants/taxasConst"
import PropTypes from "prop-types"
import { StyledTableCell } from "../../../../SharedStyles/SharedStyles"

const getValPMCE = (txMCECAT, txMCEIMM, txDeslocCAT, txDeslocIMM, percDescDesloc, txMOCAT, txMOIMM, percDescMO) => {
  return (getValAsMoney((getValAsFloat(txMCECAT) +
    getValAsFloat(txMCEIMM) +
    (getValAsFloat(txDeslocCAT) + getValAsFloat(txDeslocIMM)) *
    (1 - getValAsFloat(percDescDesloc)) +
    (getValAsFloat(txMOCAT) + getValAsFloat(txMOIMM)) *
    (1 - getValAsFloat(percDescMO))) *
    (1 + IVA)))
}

// const getFormulaPMCE = (txMCECAT, txMCEIMM, txDeslocCAT, txDeslocIMM, percDescDesloc, txMOCAT, txMOIMM, percDescMO) => {
//   return (sprintf(' [ (%s + %s) + (%s + %s) * %s + (%s + %s) * %s] * (1 + IVA)',
//     txMCECAT,
//     txMCEIMM,
//     txDeslocCAT,
//     txDeslocIMM,
//     getValAsPercent(1 - getValAsFloat(percDescDesloc)),
//     txMOCAT,
//     txMOIMM,
//     getValAsPercent(1 - getValAsFloat(percDescMO))
//   ))
// }

const msgsTableTaxasCliente = {
  TITLE: {
    CLI_VALUE: "Valores a suportar pelo cliente (c/ IVA)",
  },
  LABEL: {
    ATIVACAO: "Ativação",
    PMCE: "PMCE",
  }
}

const TableTaxasCliente = ({
                             txMCECAT = 0, txMCEIMM = 0,
                             txAtivCAT, txAtivIMM,
                             txDeslocCAT, txDeslocIMM,
                             percDescDesloc, txMOCAT, txMOIMM, percDescMO
                           }) => {

  const ativacaoValue = getValAsMoney((getValAsFloat(txAtivCAT) + getValAsFloat(txAtivIMM)) * (1 + IVA))
  const pmceValue = getValPMCE(txMCECAT, txMCEIMM, txDeslocCAT, txDeslocIMM, percDescDesloc, txMOCAT, txMOIMM, percDescMO)
  // const pmceFormula = getFormulaPMCE(txMCECAT, txMCEIMM, txDeslocCAT, txDeslocIMM, percDescDesloc, txMOCAT, txMOIMM, percDescMO)

  return (
    <TableContainer>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="right" colSpan="2">{msgsTableTaxasCliente.TITLE.CLI_VALUE}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key="ativacao">
            <TableCell>{msgsTableTaxasCliente.LABEL.ATIVACAO}</TableCell>
            <TableCell align="right">{ativacaoValue}</TableCell>
          </TableRow>
          <TableRow key="pmce">
            <TableCell>{msgsTableTaxasCliente.LABEL.PMCE}</TableCell>
            <TableCell align="right">{pmceValue}</TableCell>
          </TableRow>
        </TableBody>
        {/*<TableFooter>*/}
        {/*  <TableRow key="formula">*/}
        {/*    <TableCell colSpan="3">{pmceFormula}</TableCell>*/}
        {/*  </TableRow>*/}
        {/*</TableFooter>*/}
      </Table>
    </TableContainer>
  )
}

TableTaxasCliente.defaultValues = {
  txMCECAT: "No value",
  txMCEIMM: "No value",
  txAtivCAT: "No value",
  txAtivIMM: "No value",
  txDeslocCAT: "No value",
  txDeslocIMM: "No value",
  percDescDesloc: "No value",
  txMOCAT: "No value",
  txMOIMM: "No value",
  percDescMO: "No value"
}

TableTaxasCliente.propTypes = {
  txMCECAT: PropTypes.string,
  txMCEIMM: PropTypes.string,
  txAtivCAT: PropTypes.string,
  txAtivIMM: PropTypes.string,
  txDeslocCAT: PropTypes.string,
  txDeslocIMM: PropTypes.string,
  percDescDesloc: PropTypes.string,
  txMOCAT: PropTypes.string,
  txMOIMM: PropTypes.string,
  percDescMO: PropTypes.string
}

export default TableTaxasCliente
