const marcasMsgs = {
  LABEL: "Marcas",
  MODELOS_LABEL: "Modelos",
  UPDATE_LABEL: "Editar Marca",
  CREATE_LABEL: "Adicionar Marca",
  BACK_LABEL: "Voltar à lista de Marcas",
  TAB_LABEL: "Marca",
  TABLE: {
    HEADER: {
      MARCA: "Marca",
      LOGO: "Logo",
      MODELOS_COUNT: "Modelos",
      TECNICOS_MARCA_COUNT: "Técnicos",
      EQUIPAMENTOS_CLI_COUNT: "Equipamentos"
    },
    TOOLTIP: {
      ADD_MARCA: "Adicionar Marca",
      EDIT_MARCA: "Editar Marca"
    }
  },
  BUTTON: {
    NEW_MARCA: "Adicionar Marca",
    EDIT_MARCA: "Editar Marca"
  },
  EDIT: {
    LABEL: {
      MARCA: "Nome da marca",
      LOGO: "Imagem da marca"
    }
  }
}

export default marcasMsgs
