import { api, apiCUD, getRequest } from "../../../../api/api"

/**
 * Read Marca
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
function getPatTecClassifier([data]) {
  const request = getRequest({URLname: 'pat_tec_classifier'}).POST(`${data.pat_id}`)
  return apiCUD(request, data).then(r => r.data)
}

//
// // Não pode ser feito em dois pedidos pois ambas devem ser feitas numa única transação
// function assocPatToTec([data]) {
//   let request = getRequest({URLname: 'pats', id: data.pat_id}).PUT(`tec/${data.tec_id}/assoc`)
//   return apiCUD(request, data).then(_ => {
//     request = getRequest({URLname: 'pats', id: data.pat_id}).PUT(`agenda`)
//     return apiCUD(request, data).then(ag => ag.data)
//   })
// }

function assocTecEAgendaPat([data]) {
  let request = getRequest({URLname: 'pats', id: data.pat_id}).PUT(`tec/${data.tec_id}/assoc-agenda`)
  return apiCUD(request, data).then(ag => ag.data)
}

function reagendaPat([data]) {
  const request = getRequest({URLname: 'pats', id: data.pat_id}).PUT(`reagenda`)
  return apiCUD(request, data).then(r => r.data)
}

function iniciarPat([data]) {
  const request = getRequest({URLname: 'visitas', id: data.visita_id}).PUT(`chegadaTec`)
  return apiCUD(request, data).then(r => r.data)
}

function relatarPat([data]) {
  const request = getRequest({URLname: 'pats', id: data.pat_id}).PUT(`relatorio-tec`)
  return apiCUD(request, data).then(r => r.data)
}

function conferirPat([data]) {
  const request = getRequest({URLname: 'pats', id: data.pat_id}).PUT(`confere`)
  return apiCUD(request, data).then(r => r.data)
}

function fecharPat([data]) {
  const request = getRequest({URLname: 'pats', id: data.pat_id}).PUT(`fecha`)
  return apiCUD(request, data).then(r => r.data)
}

function encerrarPat([data]) {
  const request = getRequest({URLname: 'pats', id: data.pat_id}).PUT(`fatura`)
  return apiCUD(request, data).then(r => r.data)
}

function anularPat([data]) {
  const request = getRequest({URLname: 'pats', id: data.pat_id}).PUT(`anula`)
  return apiCUD(request, data).then(r => r.data)
}

async function removerPat([data]) {
  const request = getRequest({URLname: 'pats', id: data.pat_id}).DELETE()
  return apiCUD(request, data).then(r => r.data)
}

function reenviaPDFaoTec([data]) {
  const request = getRequest({URLname: 'pats', id: data.pat_id}).GET(`resendPDFToTec`)
  return api(request, data).then(r => r.data)
}

function reenviaPDFaoCli([data]) {
  const request = getRequest({URLname: 'pats', id: data.pat_id}).GET(`resendPDFToCli`)
  return api(request, data).then(r => r.data)
}

export {
  getPatTecClassifier,
  assocTecEAgendaPat,
  reagendaPat,
  iniciarPat,
  relatarPat,
  anularPat, removerPat,
  conferirPat,
  fecharPat,
  encerrarPat,
  reenviaPDFaoTec,
  reenviaPDFaoCli
}
