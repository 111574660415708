import React, { useContext } from "react"
import Title from "../../Shared/Title"
import { ListRepoState } from "../ListRepoGest"
import { PDFForm } from "./PDFForm"
import { pdfMsgs } from "./pdfMsgs"

function PDFEdit() {

  const {showState, showList, FKfieldname} = useContext(ListRepoState)
  const handleBack = () => showList()

  const backMsg = (showState.createMode)
    ? pdfMsgs.BACK_LABEL_CREATE
    : pdfMsgs.BACK_LABEL_UPDATE

  return (
    <React.Fragment>
      <Title handleBack={handleBack}>{backMsg}</Title>
      <PDFForm FKfieldname={FKfieldname}/>
    </React.Fragment>
  )
}

export { PDFEdit }
