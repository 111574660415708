import { api, apiCUD, apiReadTable, getRequest } from "../../api/api"
import Marcas from "./Marcas"
import MarcaIcon from "../../components/Svg-Icons/MarcaIcon"
import marcasMsgs from "./msgs"
import MarcaView from "./MarcaView"

const marcasURLname = 'marcas'

// --------------  MARCAS   --------------
const marcasPaginateRouterProps = {
  path: getRequest({URLname: marcasURLname}).paginatePath(),
  exact: true,
  name: marcasMsgs.LABEL,
  component: Marcas,
  Icon: MarcaIcon,
  breadcrumb: () => marcasMsgs.LABEL,
  reqRole: 'R'
}

const marcaViewRouterProps = {
  path: getRequest({URLname: marcasURLname}).viewPath(),
  name: marcasMsgs.UPDATE_LABEL,
  exact: true,
  component: MarcaView,
  breadcrumb: (props) => !!parseInt(props.match.params.id, 10) ? marcasMsgs.UPDATE_LABEL : marcasMsgs.CREATE_LABEL,
  reqRole: 'R'
}

// --------------  MARCAS   --------------

/**
 * Read Marcas com paginação para tabela
 * @param query
 * @returns {Promise<*|void>}
 */
async function readMarcasTable([query]) {
  const request = getRequest({URLname: marcasURLname, query}).ALL()
  return apiReadTable(request)
}

/**
 * Read Marcas para o Select
 * @returns {Promise<AxiosResponse<any>>}
 */
async function readMarcasSelect({idTipoEquip}) {
  const request = getRequest({URLname: marcasURLname}).REQUEST(`ofte/${idTipoEquip}?logo=0`)
  return api(request).then(r => r.data.data.map((marca) => ({index: marca.id, title: marca.marca})))
}

/**
 * Read Marca
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
function readMarca({id}) {
  const request = getRequest({URLname: marcasURLname, id}).GET()
  return api(request).then(r => r.data.data)
}

function createMarca([data]) {
  const request = getRequest({URLname: marcasURLname}).POST()
  return apiCUD(request, data).then(r => r.data)
}

function updateMarca([data]) {
  const request = getRequest({URLname: marcasURLname, id: data.id}).PUT()
  return apiCUD(request, data).then(r => r.data)
}

async function deleteMarca(id) {
  const request = getRequest({URLname: marcasURLname, id}).DELETE()
  return apiCUD(request)
}

export {
  marcasURLname,
  marcasPaginateRouterProps, marcaViewRouterProps,
  readMarcasTable, readMarcasSelect, readMarca, createMarca, deleteMarca, updateMarca
}
