import React from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import { Controller, useFormContext } from "react-hook-form"
import PropTypes from "prop-types"

const LFSelect = ({options, name, control, label, autoComplete, ...otherProps}) => {
  const {errors} = useFormContext()
  return (
    <Controller
      as={
        <Autocomplete
          {...otherProps}
          //style={{width: 300}}
          options={options}
          autoComplete={!!autoComplete}
          autoHighlight
          getOptionLabel={option => option.title ? option.title : option}
          renderInput={params => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              fullWidth
              inputProps={{
                ...params.inputProps,
                autoComplete: 'off' // "disabled" // disable autocomplete and autofill
              }}
              error={!!errors[name]}
              helperText={errors[name] ? errors[name].message : ''}
            />
          )}
        />
      }
      onChange={([event, data]) => {
        return data
      }}
      name={name}
      control={control}
    />
  )
}

LFSelect.defaultValues = {
  string: 'Select-Label',
  autoComplete: false,
}

LFSelect.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  autoComplete: PropTypes.bool,
}

export default LFSelect
