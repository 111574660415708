import React from "react"
import procedimentosMsgs from "./procedimentosMsgs"
import { createProcedimento, procedimentosURL, readProcedimento, updateProcedimento } from "./apiProcedimento"
import { ImgsProcedimento } from "./ImgsProc/ImgsProcedimento"
import { PDFsProcedimento } from "./PdfsProc/PDFsProcedimento"
import { RefsProcedimento } from "./RefsProc/RefsProcedimento"
import ProcedimentoForm from "./ProcedimentoForm"
import { TabbedCRUDRepo } from "../../../components/SharedComponents/TabbedCRUDRepo/TabbedCRUDRepo"


function procParamsCom(asStr = false) {
  const params = [
    {
      rel: 'pdfs', title: procedimentosMsgs.TAB_PDFS, hasMany: true, counterField: 'pdfs_count', addCom: true, editable: true,
      component: ({repo, reload}) => <PDFsProcedimento proc={repo} reload={reload}/>
    },
    {
      rel: 'images', title: procedimentosMsgs.TAB_IMGS, hasMany: true, counterField: 'images_count', addCom: true, editable: true,
      component: ({repo, reload}) => <ImgsProcedimento proc={repo} reload={reload}/>
    },
    {
      rel: '', title: procedimentosMsgs.TAB_REFS, hasMany: false, counterField: '', addCom: false,
      component: ({repo}) => <RefsProcedimento proc={repo}/>
    },
  ]
  if (asStr) {
    let rels = ['tiposEquipamento', 'modelos']
    params.forEach(item => {
      if (item.addCom) {
        rels.push(item.rel)
      }
    })
    return rels.join(',')
  } else return params
}

function ProcedimentoView() {
  return (
    <TabbedCRUDRepo
      URLname={procedimentosURL}
      handleBackTitle={procedimentosMsgs.BACK_LABEL}
      readRepoFn={readProcedimento}
      updateRepoFn={updateProcedimento}
      createRepoFn={createProcedimento}
      firstTabTitleFld={'desc'}
      FormComponent={ProcedimentoForm}
      paramComFn={procParamsCom}
    />
  )
}

export default ProcedimentoView
