import React from "react"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import PropTypes from "prop-types"
import procedimentosMsgs from "../procedimentosMsgs"
import { Badge, StyledTableCell, TableMarginTop } from "../../../../components/SharedStyles/SharedStyles"

const TableProcedimentoRefCounts = ({tipos_equipamento_count, modelos_count}) => {
  const classes = TableMarginTop()
  return (
    <TableContainer>
      <Table className={classes.table} size="small" aria-label="TableProcedimentoRefCounts">
        <TableHead>
          <TableRow>
            <StyledTableCell colSpan="2">{procedimentosMsgs.COUNTS.TITLE}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key="te_count">
            <TableCell>{procedimentosMsgs.COUNTS.TIPO_EQUIPS}</TableCell>
            <TableCell align="right">{tipos_equipamento_count ? <Badge>{tipos_equipamento_count}</Badge> : tipos_equipamento_count}</TableCell>
          </TableRow>
          <TableRow key="mod_count">
            <TableCell>{procedimentosMsgs.COUNTS.MODELOS}</TableCell>
            <TableCell align="right">{modelos_count ? <Badge>{modelos_count}</Badge> : modelos_count}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

TableProcedimentoRefCounts.defaultValues = {
  tipos_equipamento_count: 0,
  modelos_count: 0,
}

TableProcedimentoRefCounts.propTypes = {
  tipos_equipamento_count: PropTypes.number,
  modelos_count: PropTypes.number,
}

export { TableProcedimentoRefCounts }
