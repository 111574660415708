import React from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import Badge from "@material-ui/core/Badge"
import Avatar from "@material-ui/core/Avatar"
import { avatarStyles } from "../../../constants/appTheme"


const stylesOn = theme => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
})
const stylesOff = theme => ({
  badge: {
    backgroundColor: '#c00',
    color: '#c00',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      border: '1px solid currentColor',
      content: '""',
    }
  }
})

const BadgedAvatarOn = withStyles(stylesOn)(props => {
  const {src, alt, ...otherProps} = props
  const classes = avatarStyles()
  return (
    <Badge {...otherProps}
           overlap="circle"
           anchorOrigin={{
             vertical: 'bottom',
             horizontal: 'right',
           }}
           variant="dot">
      <Avatar src={src}
              classes={{img: classes.img}}
              alt={alt}/>
    </Badge>)
})

const BadgedAvatarOff = withStyles(stylesOff)(props => {
  const {src, alt, ...otherProps} = props
  const classes = avatarStyles()
  return (
    <Badge {...otherProps}
           overlap="circle"
           anchorOrigin={{
             vertical: 'bottom',
             horizontal: 'right',
           }}
           variant="dot">
      <Avatar src={src}
              classes={{img: classes.img}}
              alt={alt}/>
    </Badge>)
})

export { BadgedAvatarOn, BadgedAvatarOff }
