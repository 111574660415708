import React, { useCallback } from 'react'
import Drawer from '@material-ui/core/Drawer'
import Grid from '@material-ui/core/Grid'
import MainFooter from "../MainFooter/MainFooter"
import Divider from "@material-ui/core/Divider"
import LeftMenubar from "./LeftMenubar"
import ChevronLeftIcon from "../../../Svg-Icons/ChevronLeftIcon"
import IconButton from "@material-ui/core/IconButton"
import clsx from "clsx"
import immLogo from '../../../../img/imm-logo-small.png'
import leftMenubarStyles from "./LeftMenubarStyles"


const LeftMenubarContainer = ({leftNavBarOpenState, closeLeftNavBarHandler}) => {
  const classes = leftMenubarStyles()

  const renderFooter = useCallback(() => {
    return leftNavBarOpenState ? (
      <Grid item xs={12}>
        <MainFooter/>
      </Grid>
    ) : null
  }, [leftNavBarOpenState])

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: leftNavBarOpenState,
        [classes.drawerClose]: !leftNavBarOpenState,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: leftNavBarOpenState,
          [classes.drawerClose]: !leftNavBarOpenState,
        }),
      }}
    >
      <div className={classes.toolbarIcon}>
        <div className={classes.imgHeader}>
          <img src={immLogo} alt="imm header" className={classes.imgHeaderLogo}/>
        </div>
        <IconButton onClick={closeLeftNavBarHandler}>
          <ChevronLeftIcon/>
        </IconButton>
      </div>
      <LeftMenubar/>
      <Divider/>
      {renderFooter()}
    </Drawer>
  )
}

export default React.memo(LeftMenubarContainer)
