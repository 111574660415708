const pecasMsgs = {
  LABEL: "Peças",
  UPDATE_LABEL: "Editar Peça",
  CREATE_LABEL: "Nova Peça",
  USO_PATS_LABEL: "Utilização (PAT)",
  BACK_LABEL: "Voltar à lista de Peças",
  TABLE: {
    HEADER: {
      REF: "Referência",
      DESC: "Descrição",
      UNIT_PRICE: "€/unid.",
      PATS_COUNT: "PATs",
    }
  },
  BUTTON: {
    NEW: "Adicionar",
    EDIT: "Guardar"
  },
  EDIT: {
    LABEL: {
      REF: "Referência:",
      DESC: "Descrição:",
      UNIT_PRICE: "Preço unitário:",
    }
  }
}

export default pecasMsgs
