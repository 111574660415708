import React from "react"
import SvgIcon from "./SvgIcon"

const ClockIcon = (others) => {
  return (
    <SvgIcon height="100%" viewBox="0 0 480 480" width="100%" {...others}>
      <path
        d="m240 0c-132.546875 0-240 107.453125-240 240s107.453125 240 240 240 240-107.453125 240-240c-.148438-132.484375-107.515625-239.851562-240-240zm0 464c-123.710938 0-224-100.289062-224-224s100.289062-224 224-224 224 100.289062 224 224c-.140625 123.652344-100.347656 223.859375-224 224zm0 0"/>
      <path
        d="m240 48c-106.039062 0-192 85.960938-192 192s85.960938 192 192 192 192-85.960938 192-192c-.125-105.988281-86.011719-191.875-192-192zm8 367.800781v-23.800781c0-4.417969-3.582031-8-8-8s-8 3.582031-8 8v23.800781c-90.859375-4.242187-163.558594-76.941406-167.800781-167.800781h23.800781c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8h-23.800781c4.242187-90.859375 76.941406-163.558594 167.800781-167.800781v23.800781c0 4.417969 3.582031 8 8 8s8-3.582031 8-8v-23.800781c90.859375 4.242187 163.558594 76.941406 167.800781 167.800781h-23.800781c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8h23.800781c-4.242187 90.859375-76.941406 163.558594-167.800781 167.800781zm0 0"/>
      <path
        d="m344 232h-81.472656c-3.378906-9.554688-12.394532-15.957031-22.527344-16-3.570312.011719-7.089844.832031-10.296875 2.398438l-.046875-.070313-88-88c-3.140625-3.03125-8.128906-2.988281-11.214844.097656-3.085937 3.085938-3.128906 8.074219-.097656 11.214844l88 88 .070312.046875c-1.570312 3.210938-2.398437 6.738281-2.414062 10.3125-.023438 11.675781 8.378906 21.664062 19.886719 23.644531 11.507812 1.976563 22.761719-4.632812 26.640625-15.644531h81.472656c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8zm-104 16c-4.417969 0-8-3.582031-8-8s3.582031-8 8-8 8 3.582031 8 8-3.582031 8-8 8zm0 0"/>
    </SvgIcon>
  )
}

export default ClockIcon
