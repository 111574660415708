import React from "react"
import { useHistory, useParams } from "react-router"
import TecnicoCreate from "./TecnicoCreate"
import Title from "../../../components/SharedComponents/Shared/Title"
import MarcasTec from "./MarcasTec/MarcasTec"
import LocaisTec from "./LocaisTec/LocaisTec"
import { getRequest } from "../../../api/api"
import { readTecnico, tecnicosUrl, updateTecnico } from "./apiTecnicos"
import msgsTecnicos from "./msgsTecnicos"
import msgsMarcasTec from "./MarcasTec/msgsMarcasTec"
import msgsLocaisTec from "./LocaisTec/msgsLocaisTec"
import MarcacoesTec from "./MarcacoesTec/MarcacoesTec"
import msgsMarcacoesTec from "./MarcacoesTec/msgsMarcacoesTec"
import msgsEquips from "../../Equips/ListEquips/msgsEquips"
import TecnicoForm from "./TecnicoForm"
import TabsComponent from "../../../components/SharedComponents/Shared/TabsComponent"
import PatsTec from "../../Pats/X_PatsPorEntidades/PatsTec"
import Async from "react-async"
import LinearProgress from "@material-ui/core/LinearProgress"
import ErrorMsg from "../../../components/SharedComponents/Shared/Error/ErrorMsg"


const tecTabsGen = ({marcas_tecnico_count, locs_tecnico_count, marcacoes_count, pats_count, pats_nao_concluidas_count}) => {
  return ([
    {
      title: msgsMarcasTec.TAB_LABEL,
      component: ({tecID}) => <MarcasTec id={tecID}/>,
      showTab: true,
      hasMany: !!marcas_tecnico_count,
      qtd: marcas_tecnico_count
    },
    {
      title: msgsLocaisTec.TAB_LABEL,
      component: ({tecID}) => <LocaisTec id={tecID}/>,
      showTab: true,
      hasMany: !!locs_tecnico_count,
      qtd: locs_tecnico_count
    },
    {
      title: msgsMarcacoesTec.TAB_LABEL,
      component: ({tecID}) => <MarcacoesTec id={tecID}/>,
      showTab: true,
      hasMany: !!marcacoes_count,
      qtd: marcacoes_count
    },
    {
      title: msgsEquips.TABS.PAT,
      showTab: true,
      component: ({tecID}) => <PatsTec tecID={tecID}/>,
      hasMany: !!pats_count || !!pats_nao_concluidas_count,
      qtd: pats_count + pats_nao_concluidas_count
    }
  ])
}


const TecCustomTabs = ({tec, setTec}) => {

  let index = 0
  let tabs = [{
    label: tec.nome,
    index: index++,
    component: <TecnicoForm tecnico={tec} method={updateTecnico} setTecnico={setTec}/>
  }]

  tecTabsGen({...tec}).forEach(item => {

    if (item.showTab) {
      tabs.push({
        label: item.title,
        index: index++,
        component: item.component({tecID: tec.id}),
        hasMany: item.hasMany,
        qtd: item.qtd
      })
    }
  })
  return (
    <TabsComponent renderTabs={tabs}/>
  )
}

function TecnicoUpdate({id}) {
  return (
    <Async promiseFn={readTecnico} id={id}>
      <Async.Pending><LinearProgress color="secondary"/></Async.Pending>
      <Async.Fulfilled>
        {(data, {setData}) => {
          return (<TecCustomTabs tec={data} setTec={setData}/>)
        }}
      </Async.Fulfilled>
      <Async.Rejected>{error => <ErrorMsg>{error}</ErrorMsg>}</Async.Rejected>
    </Async>
  )
}

function TecnicoView() {
  let {id} = useParams()

  const history = useHistory()
  const handleBack = () => history.push(getRequest({URLname: tecnicosUrl}).paginatePath())

  return (
    <React.Fragment>
      <Title handleBack={handleBack}>{msgsTecnicos.BACK_LABEL}</Title>
      {(!!parseInt(id, 10))
        ? <TecnicoUpdate id={id}/>
        : <TecnicoCreate/>
      }
    </React.Fragment>
  )
}

export { TecnicoView, TecCustomTabs }
