import React from "react"
import { useHistory } from "react-router"
import LFTable from "../../LFTable/LFTable"
import PropTypes from 'prop-types'
import { getRequest } from "../../../api/api"


function BaseTableListWithRoutes(props) {

  const {
    route, columns, deleteFn, readFn, showAddButton, showEditButton,
    showDeleteButton, addButtonDesc, renderRowSubComponent, ...otherProps
  } = props
  const history = useHistory()
  const handlerCreate = () => history.push(getRequest({URLname: route, id: 0}).gotoItemPath())
  const handlerUpdate = (row) => history.push(getRequest({URLname: route, id: row.id}).gotoItemPath())
  return (
    <LFTable
      {...otherProps}
      columns={columns}
      deleteFn={deleteFn}
      requestFn={readFn}
      showAddButton={showAddButton}
      showEditButton={showEditButton}
      showDeleteButton={showDeleteButton}
      renderRowSubComponent={renderRowSubComponent}
      addButtonDesc={addButtonDesc}
      handlerCreate={handlerCreate}
      handlerUpdate={handlerUpdate}
    />
  )
}

BaseTableListWithRoutes.defaultProps = {
  showAddButton: true,
  showEditButton: true,
  showDeleteButton: true,
  renderRowSubComponent: null
}

BaseTableListWithRoutes.propTypes = {
  route: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  deleteFn: PropTypes.func.isRequired,
  readFn: PropTypes.func.isRequired,
  addButtonDesc: function (props, propName, componentName) {
    if ((props['showAddButton'] === true && (props[propName] === undefined || typeof (props[propName]) != 'string'))) {
      return new Error('Please provide a addButtonDesc!')
    }
  },
  showAddButton: PropTypes.bool,
  showEditButton: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  renderRowSubComponent: PropTypes.func
}

export default BaseTableListWithRoutes
