import React, { useState } from "react"
import { useAsyncDebounce, useExpanded, useGlobalFilter, usePagination, useSortBy, useTable } from "react-table"
import MaUTable from "@material-ui/core/Table/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import ArrowDownIcon from "../Svg-Icons/ArrowDownIcon"
import {
  errorColor,
  errorColorDark,
  errorColorLight,
  secondaryColor,
  secondaryColorLight,
  successColor,
  successColorLight,
  textHint
} from "../../constants/appTheme"
import ArrowUpIcon from "../Svg-Icons/ArrowUpIcon"
import TableBody from "@material-ui/core/TableBody"
import TableFooter from "@material-ui/core/TableFooter"
import TablePagination from "@material-ui/core/TablePagination"
import { AppMsgs } from "../../constants/appMsgs"
import LFTablePagination from "./LFTablePagination"
import LFTableSearchInput from "../LFInputs/LFTableSearchInput"
import ConfirmationRow from "./ConfirmationRow"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "../Svg-Icons/DeleteIcon"
import EditIcon from "../Svg-Icons/EditIcon"
import AddIcon from "../Svg-Icons/AddIcon"
import Box from "@material-ui/core/Box"
import LinearProgress from "@material-ui/core/LinearProgress"
import PropTypes from "prop-types"
import Button from "@material-ui/core/Button"
import UnDeleteIcon from "../Svg-Icons/UnDeleteIcon"
import Typography from "@material-ui/core/Typography"

function LFRenderTable(props) {

  const {
    columns, data, getDataFn, loading, pageCount: controlledPageCount, renderRowSubComponent,
    disable, showPagination, showSearchBox, showAddButton, disabledAddButton, addButtonDesc, handlerCreate,
    showEditButton, EditIconComponent, isEditableFlagRowFieldName, reverseEditableFlag, reloadOnEdit,
    handlerUpdate, showDeleteButton, deleteFn,
    showUndeleteButton, unDeleteFn, isFulfilled
  } = props

  const [deb, setDeb] = useState(0)
  const [confirmationRow, setConfirmationRow] = useState({})
  const [confirmationLoading, setConfirmationLoading] = useState(false)

  function mayBeEdited(row) {
    let may = true
    if (!!isEditableFlagRowFieldName)
      may = (reverseEditableFlag
        ? !row[isEditableFlagRowFieldName]
        : !!row[isEditableFlagRowFieldName])
    return may
  }

  /**
   * onEdit Handler
   */
  const onEditHandle = (repo) => {
    if (!reloadOnEdit) {
      handlerUpdate(repo)
      return true
    }
    setConfirmationLoading(true)

    handlerUpdate(repo)
      .then(() => {
        setConfirmationLoading(false)
        setConfirmationRow({})
        if (pageIndex !== 0)
          gotoPage(0)
        else
          getDataFn({pageSize, pageIndex, sortBy, globalFilter})
      })
      .catch(() => {
        setConfirmationLoading(false)
      })
  }

  const {
    getTableProps, headerGroups, prepareRow, page, pageCount, gotoPage, setPageSize,
    setGlobalFilter, flatColumns,
    state: {pageIndex, pageSize, sortBy, globalFilter},
  } = useTable({
      columns,
      data,
      initialState: {pageIndex: 0},
      manualPagination: true,
      manualSortBy: true,
      manualGlobalFilter: true,
      pageCount: controlledPageCount,
    }, useGlobalFilter, useSortBy, useExpanded, usePagination,
    hooks => {
      (showEditButton || showDeleteButton || showUndeleteButton) &&
      hooks.flatColumns.push(columns => [
        // Column for actions
        ...columns,
        {
          id: 'actions',
          Header: AppMsgs.DEFAULT_VALUES.TABLE.ACTIONS,
          Cell: ({row}) => (
            <div style={{display: "flex"}}>
              {/*--------------------------------------------- EDIT -------------------------------------------------*/}
              {(showEditButton && mayBeEdited(row.original)) && <IconButton style={{padding: "0px 10px"}}
                                                                            disabled={!mayBeEdited(row.original)}
                                                                            onClick={() => onEditHandle(row.original)}>
                {EditIconComponent}</IconButton>}
              {/*------------------------------------------- DELETE -------------------------------------------------*/}
              {showDeleteButton &&
              <IconButton style={{padding: "0px 10px"}}
                          disabled={!row.original.mayBeDeleted}
                          onClick={() => setConfirmationRow({
                            data: row,
                            func: deleteFn,
                            styles: {
                              color: errorColor,
                              background: errorColorLight,
                            },
                            action: AppMsgs.DEFAULT_VALUES.TABLE.DELETE_ACTION
                          })}>
                <DeleteIcon color={row.original.mayBeDeleted ? errorColorDark : textHint}/>
              </IconButton>}
              {/*----------------------------------------- UNDELETE -------------------------------------------------*/}
              {showUndeleteButton &&
              <IconButton style={{padding: "0px 10px"}}
                          onClick={() => setConfirmationRow({
                            data: row,
                            func: unDeleteFn,
                            styles: {
                              color: successColor,
                              background: successColorLight
                            },
                            action: AppMsgs.DEFAULT_VALUES.TABLE.UNDELETE_ACTION
                          })}>
                <UnDeleteIcon color={successColor}/>
              </IconButton>}
            </div>
          ),
        },
        // ...columns,
      ])
    })

  /**
   * Debounce do pedido a api por 300ms
   * @type {*}
   */
  const onFetchDataDebounced = useAsyncDebounce(() => {
    getDataFn({pageSize, pageIndex, sortBy, globalFilter})
  }, deb)

  /**
   * Existem  problemas com esta solução mas terá de ser assim porque
   * o useAsync não está a enviar os dados [data] (isto porque causa do deferFn suponho eu)
   * por isso é necessário usar o useAsyncDebounce mesmo quando não é preciso o debounce.
   * Para resolver isto o useAsync do LFTable teria de vir para dentro deste component e aqui fazer o pedido com o promiseFn,
   * e através do watch estar atento às alterações dos valores (como se fosse um useEffect)
   * @type {*}
   */
  React.useEffect(() => {
    if (globalFilter === '' || globalFilter === undefined || (pageIndex !== 0 && globalFilter !== ''))
      setDeb(0)
    else
      setDeb(300)

    onFetchDataDebounced()
  }, [onFetchDataDebounced, globalFilter, pageIndex, pageSize, sortBy])


  /**
   * Altera numero de rows per page
   * @param e
   */
  function handleChangeRowsPerPage(e) {
    setPageSize(parseInt(e.target.value, 10))
    gotoPage(0)
  }

  /**
   * Confirmation Handler
   */
  function confirmationHandle(actionFn) {
    setConfirmationLoading(true)

    actionFn(confirmationRow.data.original.id)
      .then(() => {
        setConfirmationLoading(false)
        setConfirmationRow({})
        if (pageIndex !== 0)
          gotoPage(0)
        else
          getDataFn({pageSize, pageIndex, sortBy, globalFilter})
      })
      .catch(() => {
        setConfirmationLoading(false)
      })
  }


  return (
    <React.Fragment>
      <Grid container spacing={3} style={disable ? {pointerEvents: 'none', opacity: '0.3'} : {}}>
        {/*------------------------------------------- SEARCH TABLE -------------------------------------------------*/}
        <Grid item xs={12} md={6}>
          {showSearchBox && <LFTableSearchInput
            gotoPage={gotoPage}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />}
        </Grid>
        {/*------------------------------------------- ADD BUTTON ---------------------------------------------------*/}
        <Grid item xs={12} md={6}>
          {showAddButton &&
          <Box display="flex" justifyContent="flex-end">
            {/*<Fab color="primary" size="small" aria-label="add" onClick={handlerCreate}>
              <AddIcon/>
            </Fab>*/}
            <Button color="secondary" variant="outlined" onClick={handlerCreate} disableElevation
                    startIcon={<AddIcon color={disabledAddButton ? textHint : secondaryColor}/>} disabled={disabledAddButton}>
              {addButtonDesc}
            </Button>
          </Box>
          }
        </Grid>
      </Grid>
      <MaUTable {...getTableProps()} style={disable ? {pointerEvents: 'none', opacity: '0.3'} : {}}>
        {/*------------------------------------------- TABLE HEAD ---------------------------------------------------*/}
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <span>
                  {column.render('Header')}
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <ArrowDownIcon color={textHint}/>
                        : <ArrowUpIcon color={textHint}/>
                      : ""}
                  </span>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        {/*------------------------------------------- TABLE BODY ---------------------------------------------------*/}
        <TableBody>
          {!!data.length ?
            page.map((row, i) => {
              prepareRow(row)
              return (
                <React.Fragment {...row.getRowProps()}>
                  <TableRow>
                    {/*---------------------------------- DELETE ROW --------------------------------------------------*/}
                    {(row.id === (confirmationRow && confirmationRow.data && confirmationRow.data.id))
                      ? (
                        <ConfirmationRow
                          row={row}
                          confirmationHandle={() => confirmationHandle(confirmationRow.func)}
                          cancelConfirmationHandle={() => setConfirmationRow({})}
                          confirmationLoading={confirmationLoading}
                          styles={confirmationRow.styles}
                          action={confirmationRow.action}/>
                      )
                      /*-------------------------------- RENDER ROW --------------------------------------------------*/
                      : (row.cells.map(cell => {
                          return (
                            <TableCell {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </TableCell>
                          )
                        })
                      )
                    }
                  </TableRow>
                  {/*-------------------------------- EXPANDED COMP ---------------------------------------------------*/}
                  {row.isExpanded ? (
                    <TableRow>
                      <TableCell colSpan={flatColumns.length}>
                        {renderRowSubComponent({row})}
                      </TableCell>
                    </TableRow>
                  ) : null}
                </React.Fragment>
              )
            })
            /*----------------------------------------- NO DATA  -----------------------------------------------------*/
            : isFulfilled &&
            <TableRow>
              <TableCell colSpan={flatColumns.length}>
                <Typography component="h1" variant="h6" color="inherit" noWrap>
                  {AppMsgs.DEFAULT_VALUES.TABLE.EMPTY_TABLE_MSG}
                </Typography>
              </TableCell>
            </TableRow>
          }
        </TableBody>
        <TableFooter>
          {showPagination && <TableRow>
            {/*--------------------------------------- LOADING  -----------------------------------------------------*/}
            {loading ? (
                <TableCell colSpan={flatColumns.length}>
                  <LinearProgress color="secondary"/>
                </TableCell>
              ) :
              (
                /*------------------------------------ PAGINATION  -------------------------------------------------*/
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  count={pageCount}
                  rowsPerPage={pageSize}
                  labelRowsPerPage={AppMsgs.DEFAULT_VALUES.TABLE.ROWS_SELECT}
                  labelDisplayedRows={({from, to, count}) => `${from}-${to} de ${count}`}
                  page={pageIndex}
                  backIconButtonProps={{'aria-label': 'previous page',}}
                  nextIconButtonProps={{'aria-label': 'next page',}}
                  onChangePage={(e, page) => gotoPage(page)}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={LFTablePagination}
                />)
            }
          </TableRow>}
        </TableFooter>
      </MaUTable>
    </React.Fragment>
  )
}

LFRenderTable.defaultProps = {
  renderRowSubComponent: null,
  showAddButton: false,
  disabledAddButton: false,
  disable: false,
  showPagination: true,
  showSearchBox: true,
  showEditButton: false,
  EditIconComponent: <EditIcon color={secondaryColorLight}/>,
  isEditableFlagRowFieldName: '',
  reverseEditableFlag: false,
  reloadOnEdit: false,
  showDeleteButton: false,
  showUndeleteButton: false,
  undeleteFn: null,
  deleteFn: null,
  handlerCreate: null,
  handlerUpdate: null
}

LFRenderTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  getDataFn: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  pageCount: PropTypes.number.isRequired,
  renderRowSubComponent: PropTypes.func,
  showAddButton: PropTypes.bool,
  EditIconComponent: PropTypes.element,
  isEditableFlagRowFieldName: PropTypes.string,
  reverseEditableFlag: PropTypes.bool,
  reloadOnEdit: PropTypes.bool,
  disabledAddButton: PropTypes.bool,
  showEditButton: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  showUndeleteButton: PropTypes.bool,
  deleteFn: function (props, propName, componentName) {
    if ((props['showDeleteButton'] === true && (props[propName] === undefined || typeof (props[propName]) != 'function'))) {
      return new Error('Please provide a deleteFn function!')
    }
  },
  unDeleteFn: function (props, propName, componentName) {
    if ((props['showUndeleteButton'] === true && (props[propName] === undefined || typeof (props[propName]) != 'function'))) {
      return new Error('Please provide a unDeleteFn function!')
    }
  },
  handlerCreate: function (props, propName, componentName) {
    if ((props['showAddButton'] === true && (props[propName] === undefined || typeof (props[propName]) != 'function'))) {
      return new Error('Please provide a handlerCreate function!')
    }
  },
  addButtonDesc: function (props, propName, componentName) {
    if ((props['showAddButton'] === true && (props[propName] === undefined || typeof (props[propName]) != 'string'))) {
      return new Error('Please provide a addButtonDesc!')
    }
  },
  handlerUpdate: function (props, propName, componentName) {
    if ((props['showEditButton'] === true && (props[propName] === undefined || typeof (props[propName]) != 'function'))) {
      return new Error('Please provide a handlerUpdate function!')
    }
  },
}


export default LFRenderTable
