import React from "react"
import SvgIcon from "./SvgIcon"

const SmartPhoneIcon = (others) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...others}>
      <path d="M0 0h24v24H0z" fill="none"/>
      <path
        d="M17 1.01L7 1c-1.1 0-2 .9-2 2v18c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V3c0-1.1-.9-1.99-2-1.99zM17 19H7V5h10v14z"/>
    </SvgIcon>
  )
}

export default SmartPhoneIcon
