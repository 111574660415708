import React from "react"
import Grid from "@material-ui/core/Grid"
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import '../../../EstadosPat_InfoStates/EstadosPat/timelineStyles.css'
import GearIcon from "../../../../../components/Svg-Icons/GearIcon"
import CircleIcon from "../../../../../components/Svg-Icons/CircleIcon"
import StarIcon from "../../../../../components/Svg-Icons/StarIcon"
import { secondaryColorDark, secondaryColorLight, successColor, whiteColor } from "../../../../../constants/appTheme"
import { fade } from "@material-ui/core"
import StateFlowVisitaPatTabs from "../InfoStates/StateFlowVisitaPatTabs"
import TableEstadoVisitaPat from "../../../../../components/SharedComponents/Shared/Tables/TableEstadoVisitaPat"


const EstadosVisitaPat = ({visita, reload}) => {
  const estados = visita.estados.data

  let key = 0
  let elems = []
  estados.forEach((item) => {
    elems.push({
      key: key++,
      estado: item,
    })
  })
  elems.push({key: key++}) // arranque
  const qtdElems = elems.length

  return (
    <Grid container spacing={4} justify="center">
      <Grid item xs={visita.mayEdit ? 6 : 12}>
        <VerticalTimeline layout={'1-column'}>
          {elems.map((elem, index) => {
            let timelineProps = {
              fixed: false,
              iconStyle: {background: secondaryColorLight},
              contentStyle: {background: '#f2f2f2', color: '#d3d3d3'},
              contentArrowStyle: {borderRight: '7px solid  #ff5500'},
              visibilitySensorProps: {},
              icon: <GearIcon color={whiteColor}/>
            }

            if (elem.key === qtdElems - 1) {
              // arranque
              timelineProps = {
                fixed: true,
                iconStyle: {background: successColor},
                visibilitySensorProps: {},
                icon: <CircleIcon color={whiteColor}/>
              }
              return (<VerticalTimelineElement {...{...timelineProps, ...elem}} />)
            } else {
              if (elem.key === 0) {
                // último
                timelineProps = {
                  fixed: true,
                  contentStyle: {background: fade(secondaryColorDark, 0.3), color: whiteColor},
                  contentArrowStyle: {borderRight: '7px solid  rgba(72,36,0,0.7)'},
                  iconStyle: {background: 'rgb(255,102,0)'},
                  visibilitySensorProps: {},
                  icon: <StarIcon color={whiteColor}/>
                }
              }
            }
            return (
              <VerticalTimelineElement key={index} {...{...timelineProps, ...elem}}>
                <TableEstadoVisitaPat estado={elem.estado}/>
              </VerticalTimelineElement>
            )
          })}
        </VerticalTimeline>
      </Grid>
      {(!!visita.mayEdit) && <Grid item xs>
        <StateFlowVisitaPatTabs visita={visita} reload={reload}/>
      </Grid>}
    </Grid>)
}

export default EstadosVisitaPat
