import React from "react"
import styled from "@material-ui/core/styles/styled"
import PropTypes from "prop-types"

const Text = styled('h3')(({theme}) => ({
  color: theme.palette.error.main,
}))

export default function ErrorMsg({...props}) {
  return (
    <Text>
      {props.children}
    </Text>
  )
}

ErrorMsg.propTypes = {
  children: PropTypes.element.isRequired
}
