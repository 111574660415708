import React from "react"
import Grid from "@material-ui/core/Grid"
import RenderPATTables from "../../ShowPat/Partials/RenderPATTables"
import Fab from "@material-ui/core/Fab"
import { Link as RouterLink } from "react-router-dom"
import { getRequest } from "../../../../api/api"
import Typography from "@material-ui/core/Typography"
import visitaPatMsgs from "../visitaPatMsgs"
import { patsURLname } from "../../apiPats"

function PatVisitaPat({visita}) {
  const pat = visita && visita.pat && visita.pat.data
  return (!pat)   // teste hipocondríaco (caso nunca acontecerá)
    ? <p>#ND</p>  // --> e sua consequência...
    : (
      <Grid container spacing={2} justify="center">
        <Grid item xs={12}>
          <Fab component={RouterLink} // target="_blank"
               size="small"
               to={getRequest({URLname: patsURLname, id: visita.pat_id}).gotoItemPath()}
               color="secondary"
               variant="extended" style={{boxShadow: 'none'}}>
            <Typography variant="body2" style={{paddingLeft: '5px', fontSize: '12px'}}
                        display={'inline'}>{visitaPatMsgs.BACK_LABEL}</Typography>
          </Fab>
        </Grid>
        <Grid item xs={12}>
          <RenderPATTables pat={pat}/>
        </Grid>
      </Grid>)
}

export { PatVisitaPat }
