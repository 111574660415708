import React from "react"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import Checkbox from "@material-ui/core/Checkbox"
import ListItemText from "@material-ui/core/ListItemText"
import { PropTypes } from "prop-types"
import { CircularProgress } from "@material-ui/core"

function LFListItem({item: {id, text, checked}, handleToggle, disabled, loading}) {
  const labelId = `checkbox-list-label-${id}`
  return (
    <React.Fragment>
      <ListItem key={id} disabled={disabled} dense button onClick={() => handleToggle(id)}>
        {
          loading ?
            <div style={{minWidth: '56px'}}>
              <CircularProgress size={20}/>
            </div> :
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={checked}
                disableRipple
                inputProps={{'aria-labelledby': labelId}}
              />
            </ListItemIcon>
        }
        <ListItemText id={labelId} primary={text}/>
      </ListItem>
    </React.Fragment>
  )
}

LFListItem.defaultProps = {
  disabled: false,
  loading: false
}

LFListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
  }),
  handleToggle: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool
}

export default LFListItem
