import React from "react"
import Grid from "@material-ui/core/Grid"
import PropTypes from "prop-types"
import { GridPlanoAtivo } from "../PlanosAplicaveis/partials/GridPlanoAtivo"
import { Link as RouterLink } from "react-router-dom"
import { getRequest } from "../../api/api"
import Typography from "@material-ui/core/Typography"
import Fab from "@material-ui/core/Fab"
import { planosAtivadosUrl } from "../Planos/ListPlanosAtivados/apiPlanosAtivados"
import msgsPlanosAtivados from "../Planos/ListPlanosAtivados/msgsPlanosAtivados"

function PlanoAtivoView({planoAtiv, setEquip, equip, showPAI}) {
  return (
    <Grid container spacing={2} justify="center">
      {showPAI && <Grid item xs={12}>
        {/*<H2Slim>{`${planoAtiv.refAN} - ${planoAtiv.plano.data.designacao} (Rev. ${planoAtiv.plano.data.rev}) `}</H2Slim>*/}
        {/*<DesativarPlanoAtivo planoAtiv={planoAtiv} setEquip={setEquip} equip={equip}/>*/}
        <Fab component={RouterLink} size="small"
             to={getRequest({URLname: planosAtivadosUrl, id: planoAtiv.id}).gotoItemPath()} color="secondary"
             variant="extended" style={{boxShadow: 'none'}}>
          <Typography variant="body2" style={{paddingLeft: '5px'}}
                      display={'inline'}>{msgsPlanosAtivados.OPEN_BUTTON}</Typography>
        </Fab>
        {/*<TablePlanoActivo/>*/}
        <GridPlanoAtivo simulacao={false} {...planoAtiv} />
      </Grid>}
      {/*<Grid item sm={12} md={6}>*/}
      {/*  <TableTaxas {...planoAtiv}/>*/}
      {/*  <TableTaxasCliente {...planoAtiv}/>*/}
      {/*</Grid>*/}
      {/*<Grid item sm={12} md={6}>*/}
      {/*  <TablePeriodicidades {...planoAtiv}/>*/}
      {/*  <TableDescontos  {...planoAtiv}/>*/}
      {/*</Grid>*/}
    </Grid>
  )
}

export default PlanoAtivoView

PlanoAtivoView.defaultProps = {
  setEquip: null,
  equip: null,
  showPAI: false
}

PlanoAtivoView.propTypes = {
  planoAtiv: PropTypes.object.isRequired,
  isHistorico: PropTypes.bool,
  setEquip: PropTypes.func,
  equip: PropTypes.object,
}


