import React, { useState } from "react"
import Async from "react-async"
import { getRequest } from "../../../api/api"
import { allPatStatesByEquipID, patsURLname, readPatsTable } from "../apiPats"
import { useHistory } from "react-router"
import { patsColumns } from "../Pats"
import PatCreate from "../PatCreate"
// import { mergeColumnWithIndex } from "../../../constants/appConst"
import patMsgs from "../patMsgs"
import TabsComponent from "../../../components/SharedComponents/Shared/TabsComponent"
import LinearProgress from "@material-ui/core/LinearProgress"
import ErrorMsg from "../../../components/SharedComponents/Shared/Error/ErrorMsg"
import LFTable from "../../../components/LFTable/LFTable"
import Typography from "@material-ui/core/Typography"
import { AppMsgs } from "../../../constants/appMsgs"

// @am  comentado em 29/3/2021 --> já não é usado!!
// const extraColumns = {
//   Header: patMsgs.TABLE.HEADER.ESTADO,
//   accessor: "estadoPATdesc",
//   disableSortBy: true
// }

function CustomPatsList({handlerCreate, disabledAddButton, requestFn}) {

  // const columns = mergeColumnWithIndex(3, extraColumns, patsColumns)

  const history = useHistory()
  const handlerUpdate = (row) => history.push(getRequest({URLname: patsURLname, id: row.id}).gotoItemPath())

  return (
      <LFTable
        columns={[patsColumns]}
        requestFn={requestFn}
        addButtonDesc={patMsgs.NEW_LABEL}
        showAddButton={true}
        showEditButton={true}
        handlerCreate={handlerCreate}
        handlerUpdate={handlerUpdate}
        disabledAddButton={disabledAddButton}
      />
  )
}

const PatStatesRender = ({data, equipID, handlerCreate, disabledAddButton}) => {
  let tabs = []
  let index = 0
  const qtd = data.length || 0
  data.forEach(item => {
    tabs.push({
      label: item.desc,
      hasMany: true,
      qtd: item.qtd,
      value: item.value,
      index: index++,
      component: <CustomPatsList
        equipID={equipID}
        handlerCreate={handlerCreate}
        disabledAddButton={disabledAddButton}
        requestFn={([props]) => readPatsTable([{
          state: item.value,
          com: 'visitas',
          relCom: 'equipscli',
          relComID: equipID,
          sortBy: [{id: "id", desc: true}],
          ...props,
        }])}/>,
    })
  })
  return (qtd > 0)
    ? (<TabsComponent renderTabs={tabs}/>)
    : (<Typography component="h1" variant="h6" color="inherit" noWrap>
      {AppMsgs.DEFAULT_VALUES.TABLE.EMPTY_TABLE_MSG}
    </Typography>)
}

function PatsEquip({equip}) {
  const [isNewPat, setIsNewPat] = useState(false)

  const handlerCancelEdit = () => {
    setIsNewPat(false)
  }

  return (
    <React.Fragment>
      {
        isNewPat
          ? <PatCreate equip={equip} patType={'PMCE'} onCancelEdit={handlerCancelEdit}/>
          : (
            <Async promiseFn={allPatStatesByEquipID} equipID={equip.id}>
              <Async.Pending><LinearProgress color="secondary"/></Async.Pending>
              <Async.Fulfilled>
                {(data) => <PatStatesRender
                  data={data}
                  equipID={equip.id}
                  handlerCreate={() => setIsNewPat(true)}
                  disabledAddButton={equip.has_pat_pmcesp}/>}
              </Async.Fulfilled>
              <Async.Rejected>{error => <ErrorMsg>{error}</ErrorMsg>}</Async.Rejected>
            </Async>)
      }
    </React.Fragment>
  )
}

// function OLD_PatsEquip({equip}) {
//   const equipID = equip.id
//   const history = useHistory()
//   const [isNewPat, setIsNewPat] = useState(false)
//
//   const handlerUpdate = (row) => history.push(getRequest({URLname: patsURLname, id: row.original.id}).gotoItemPath())
//
//   const columns = mergeColumnWithIndex(3, extraColumns, patsColumns)
//   return (
//     <React.Fragment>
//       {
//         isNewPat
//           ? <PatCreate equip={equip} setIsNewPat={setIsNewPat}/>
//           : <LFTable
//             columns={[columns]}
//             requestFn={(props) => readPatsEquipTable(props, equipID)}
//             addButtonDesc={patMsgs.NEW_LABEL}
//             showAddButton={true}
//             showEditButton={true}
//             handlerCreate={() => setIsNewPat(true)}
//             handlerUpdate={handlerUpdate}
//             disabledAddButton={equip.has_pat_pmcesp || equip.has_pat_pa}
//           />
//       }
//     </React.Fragment>
//   )
// }

export default PatsEquip
