import React, { useContext, useState } from "react"
import { FormContext, useForm } from "react-hook-form"
import Grid from "@material-ui/core/Grid"
import { Form } from "../../../SharedStyles/SharedStyles"
import LFSubmitButton from "../../Shared/LFButtons/LFSubmitButton"
import PropTypes from "prop-types"
import { ListRepoState } from "../ListRepoGest"
import { handleSaveRepoItem } from "../handleSaveRepoItem"
import { repoMsgs } from "../repoMsgs"
import LFFileInput from "../../../LFInputs/FileUploader/LFFileInput"

// const validationSchema = Yup.object().shape({
//   pdf: requiredValidation(imagemMsgs.EDIT.LABEL.IMAGEM)
// })

function PDFForm({FKfieldname}) {
  const [isPending, setIsPending] = useState(false)
  const [filename, setFilename] = useState('')
  const {showList, showState, setRepoList, repo, createRepoFn, readReposFn} = useContext(ListRepoState)
  const method = (data) => handleSaveRepoItem({
    showList: showList,
    showState: showState,
    repo: repo,
    setRepoList: setRepoList,
    data: data,
    createRepoFn: createRepoFn,
    readReposFn: readReposFn,
  }).then(_ => {
    setIsPending(false)
  })

  const methods = useForm({
    // validationSchema: validationSchema,
  })
  const {handleSubmit, formState, control} = methods

  const onSubmit = ({pdf, ...otherValues}) => {

    const convertValues = {
      pdf: pdf,
      filename: filename,
      overwrite: 1,
    }
    convertValues[FKfieldname] = repo.id
    setIsPending(true)
    method({...convertValues})
  }

  return (
    <FormContext {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} justify="center">
          {/*  -------------------------------------- PDF ----------------------------------------*/}
          <Grid item xs={12}>
            <LFFileInput isLoading={isPending} name={'pdf'} control={control} setFilename={setFilename}/>
          </Grid>
        </Grid>
        <LFSubmitButton dirty={!formState.dirty}>
          {repoMsgs.BUTTON.NEW}
        </LFSubmitButton>
      </Form>
    </FormContext>
  )
}

PDFForm.propTypes = {
  FKfieldname: PropTypes.string.isRequired,
}

export { PDFForm }
