import PropTypes from "prop-types"

const handleSaveRepoItem = ({showList, showState, repo, setRepoList, data, createRepoFn, readReposFn}) => {
  if (showState.createMode) {
    return createRepoFn(data).then(_ => {
        readReposFn({id: repo.id}).then(
          (imgs) => {
            setRepoList(imgs)
            showList()
          })
      }
    )
  }
}

// handleSaveImagem.defaultValues = {
// }

handleSaveRepoItem.propTypes = {
  showList: PropTypes.func.isRequired,
  showState: PropTypes.func.isRequired,
  repo: PropTypes.object.isRequired,
  setRepoList: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  createRepoFn: PropTypes.func.isRequired,
  readReposFn: PropTypes.func.isRequired,
}

export {
  handleSaveRepoItem
}
