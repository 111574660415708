import React from "react"
import Rating from "@material-ui/lab/Rating"
import { Controller, ErrorMessage, useFormContext } from "react-hook-form"
import Box from "@material-ui/core/Box"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { AppMsgs } from "../../constants/appMsgs"
import PropTypes from "prop-types"
import InputError from "../SharedComponents/Shared/Error/InputError"

// Descrição para cada nivel da avaliação
const labels = {
  1: AppMsgs.DEFAULT_VALUES.RATING_LABEL.RATING_1,
  2: AppMsgs.DEFAULT_VALUES.RATING_LABEL.RATING_2,
  3: AppMsgs.DEFAULT_VALUES.RATING_LABEL.RATING_3,
  4: AppMsgs.DEFAULT_VALUES.RATING_LABEL.RATING_4,
  5: AppMsgs.DEFAULT_VALUES.RATING_LABEL.RATING_5,
}

// Styles
const useStyles = makeStyles({
  root: {
    width: 200,
    display: 'flex',
    alignItems: 'center',
  },
})


const LFRatingInput = ({name, control, initValue}) => {
  const [value, setValue] = React.useState(initValue)
  const [hover, setHover] = React.useState(-1)

  const {errors} = useFormContext()

  const classes = useStyles()

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Controller
          as={
            <Rating onChangeActive={(event, newHover) => {
              setHover(newHover)
            }}/>
          }
          control={control}
          name={name}
          onChange={([e, newValue]) => {
            setValue(newValue)
            return newValue
          }}
        />
        {value !== null ? <Box ml={2}>{labels[hover !== -1 ? hover : value]}</Box> : null}
      </div>
      <ErrorMessage errors={errors} name={name} as={<InputError/>}/>
    </React.Fragment>
  )
}

LFRatingInput.defaultProp = {
  initValue: 0
}

LFRatingInput.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  initialValue: PropTypes.number
}

export default LFRatingInput
