import React from "react"
import LFInput from "./LFInput"
import PropTypes from "prop-types"

function LFTextAreaInput(props) {
  return <LFInput
    multiline
    {...props}
  />
}

LFTextAreaInput.defaultProps = {
  label: "Text Field",
  name: "textfield",
}

LFTextAreaInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string
}

export default LFTextAreaInput
