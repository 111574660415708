import CheckIcon from "../../Svg-Icons/CheckIcon"
import { errorColor, successColor } from "../../../constants/appTheme"
import CancelIcon from "../../Svg-Icons/CancelIcon"
import React from "react"
import PropTypes from "prop-types"

function BoolIconComponent({bool}) {
  return bool ? <CheckIcon color={successColor}/> : <CancelIcon size={15} color={errorColor}/>
}

BoolIconComponent.propType = {
  bool: PropTypes.bool.isRequired
}

export default BoolIconComponent
